import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import { cellS, headerB, headerS } from "../../../../St/comps/cStyle";
import Grid from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableRightBorder: {
    borderWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
    fontFamily: "Amiri-Regular",
    fontWeight: 800,
    fontSize: 14,
  },
  rowHeader: {
    fontFamily: "Amiri-Regular",
    fontWeight: 800,
    fontSize: 14,
  },
}));


export default function CostCenterRptTable3(props) {
  const { data,color } = props;
  const { t } = useTranslation(["forms"]);

  var columnsSum = [
    { title: "رقم الحساب", field: "col_COST_no" },
    { title: "اسم الحساب", field: "col_COST_name" },
    { title: "رصيد افتتاحي مدين", field: "col_open_dept" },
    { title: " رصيد افتتاحي دائن", field: "col_open_credit" },
    { title: " رمدين", field: "col_dept" },
    { title: " دائن", field: "col_credit" },
    { title: "رصيد نهائي مدين", field: "col_balance_dept" },
    { title: "رصيد نهائي دائن", field: "col_balance_credit" },
  ];
  const classes = useStyles();
  return (
     <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: colors.red[300],
              color: "#000",
              fontWeight: 800,
              fontFamily: "Amiri-Regular",
              fontSize: 22,
            }}
          >
            <TableCell align="center" colSpan={2}></TableCell>

            <TableCell
              className={classes.tableRightBorder}
              align="center"
              colSpan={2}
            >
              {t("prev-balance")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              align="center"
              colSpan={2}
            >
              {" "}
              {t("acc-move")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              align="center"
              colSpan={2}
            >
              {t("acc-balance")}
            </TableCell>
          </TableRow>
          <TableRow
            style={{
              backgroundColor: colors.red[300],
              fontSize: 18,
              fontFamily: "Amiri-Regular",
              color: "#000",
            }}
          >
            <TableCell className={classes.tableRightBorder} align="center">
              {t("account-code")}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {t("account")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              style={{ fontWeight: 800, fontSize: 16 }}
              align="center"
            >
              {" "}
              {t("dept")}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("credit")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              style={{ fontWeight: 600, fontSize: 14 }}
              align="center"
            >
              {" "}
              {t("dept")}{" "}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("credit")}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("dept")}{" "}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("credit")}{" "}
            </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.col_COST_no}>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.col_COST_no}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.col_COST_name}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.col_open_dept?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.col_open_credit?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.col_dept?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.col_credit?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.col_balance_dept?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.col_balance_credit?.toFixed(2) ?? "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
