import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { uri } from "../../../../../help/Api";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveeIcon from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";
import {
  defaultStoreTransferData,
  formatTransfer,
  receiveValidateSchema,
} from "../../../../../services/storeTransfer-servece";

import toast from "react-hot-toast";
import SearchIcon from "@material-ui/icons/Search";
import StoreTransferModal from "../StoreTransfer-search-modal";
import { Fragment } from "react";
import { CircularProgress } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
  },
  appbar2: {
    margin: theme.spacing(0, 1),
    justifyContent: "center",
  },
  buttons: {
    margin: theme.spacing(1),
    marginBottom: 25,
  },
  root: {
    direction: "rtl",
    marginBottom: theme.spacing(1),
    // padding: theme.spacing(1),
    padding: 5,
    // width: '100%',
    // height: '100%',
  },
  appBar: {
    top: "auto",
    right: "0.10%",
    bottom: 3,
    left: "0.5%",
    width: "98.9%",
    backgroundColor: "#0009F",
  },
  buttonss: {
    backgroundColor: "#0009F",
  },
  buttonProgressCAB: {
    color: "#fff",
    marginLeft: 5,
  },
}));

export const getFormatSTransferData = (sTransferData, subData, branches) => {
  let rows = subData.map((sub, i) => {
    return {
      ...sub,
      id: i,
      currency_from: sub.currency,
      item: { id: sub.item, symbol: sub.itemName },
      mUnit: { id: sub.unit, name: sub.unitName },
      val2: sub.val1,
    };
  });
  return {
    ...sTransferData,
    date: new moment(sTransferData.date).format("YYYY-MM-DD"),
    safe_from: {
      id: sTransferData.safe_from,
      name: sTransferData.safe_fromName,
    },
    safe_to: { id: sTransferData.safe_to, name: sTransferData.safe_toName },
    branch: branches.find((b) => b.id == sTransferData.branch),
    rows: rows,
  };
};

export default function Footer(props) {
  const {
    onShow,
    submit,
    safes,
    object,
    submitting,
    update,
    branches,
    formType,
    remove,
  } = props;
  const clasess = useStyles();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  let his = useHistory();

  const handleSubmit = (isPrint = false, data) => {
    let formatedData = formatTransfer({ ...data, Type: 2 });

    if (data.rows?.length > 0) {
      receiveValidateSchema
        .validate(formatedData.safesTransfer, { abortEarly: false })
        .then(async () => {
          if (formType == "edit") {
            await update(formatedData).then((res) => {});
          } else {
            await submit(formatedData).then((res) => {
              if (isPrint && res)
                print({ ...data, no: res.data.no, notes: res.data.notes });
            });
          }
        })
        .catch((err) => {
          toast.error(err.errors[0]);
        });
    } else {
      toast.error("يجب ادخال أصناف في الجدول");
    }
  };

  const onClickRow = async (row) => {
    const type = "edit";
    await axios
      .get(
        uri +
          `StoreTransfer/sTransferSub?transfer_id=${row.id}
          &&branch=${row.branch}`
      )
      .then((res) => {
        const data = getFormatSTransferData(row, res.data, branches);
        onShow(data, type);
        // onShow(newData, type);
        setOpenSearchModal(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("لم يتم جلب البيانات");
      });
  };

  const print = (data) => {
    const info = { ...data, title: "أمر استلام تحويل مخزني", type: 2 };
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/transfer-print" });
    window.location.reload(false);
  };

  const backHome = () => {
    his.push({ pathname: "/" });
    window.location.reload(false);
  };

  return (
    <>
      <Grid item className={clasess.root} container>
        <AppBar size="small" className={clasess.appBar}>
          <Toolbar style={{ direction: "ltr" }}>
            <IconButton edge="start" color="blue" aria-label="open drawer">
              <Button
                style={{ direction: "ltr" }}
                variant="contained"
                color="primary"
                fontSize="large"
                size="medium"
                onClick={backHome}
                startIcon={<CancelIcon />}
              >
                {" "}
                الخروج
              </Button>
            </IconButton>
            <Button
              style={{ color: "#fff" }}
              onClick={() => setOpenSearchModal(true)}
              startIcon={<SearchIcon />}
            >
              {" "}
              بحـث
            </Button>

            <div className={clasess.grow} />
            {formType == "edit" && (
              <Button
                style={{ direction: "ltr" }}
                variant="contained"
                color="primary"
                fontSize="large"
                size="medium"
                onClick={() => remove(object.id)}
                startIcon={<Delete />}
                disabled={submitting}
              >
                حذف
                {submitting && (
                  <CircularProgress
                    size={20}
                    className={clasess.buttonProgressCAB}
                  />
                )}
              </Button>
            )}

            <IconButton color="inherit">
              <Button
                style={{ direction: "ltr" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (formType == "edit") {
                    print(object);
                  } else handleSubmit(true, object);
                }}
                disabled={submitting}
                fontSize="large"
                size="medium"
                startIcon={<PrintIcon />}
              >
                طباعه
                {submitting && (
                  <CircularProgress
                    size={20}
                    className={clasess.buttonProgressCAB}
                  />
                )}
              </Button>{" "}
            </IconButton>
            <IconButton color="inherit">
              <Button
                variant="contained"
                color="primary"
                fontSize="large"
                size="medium"
                onClick={() => handleSubmit(false, object)}
                disabled={submitting}
                startIcon={<SaveeIcon />}
              >
                حفظ
                {submitting && (
                  <CircularProgress
                    size={20}
                    className={clasess.buttonProgressCAB}
                  />
                )}
              </Button>
            </IconButton>
            <Button
              variant="contained"
              color="primary"
              fontSize="large"
              size="medium"
              onClick={() =>
                onShow({
                  ...defaultStoreTransferData,
                  safe_from: safes[0],
                  branch: branches[0],
                })
              }
            >
              جـديد{" "}
            </Button>
          </Toolbar>
        </AppBar>
      </Grid>
      <StoreTransferModal
        {...{
          type: 2,
          open: openSearchModal,
          onClose: () => {
            setOpenSearchModal(false);
          },
          onRowClick: async (data) => {
            await onClickRow(data);
          },
        }}
      />
    </>
  );
}
