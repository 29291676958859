import { makeStyles, withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import { green, purple } from "@material-ui/core/colors";
import { insh } from "../../Context/InshSt";
import { colors } from "@material-ui/core";

// rt
const useStylesTD = makeStyles((theme) => ({
  root: {
    "& ::-webkit-calendar-picker-indicator": {
      color: "red",
    },
  },

  input: {
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      color: "red",
      // opacity: 1,
      // display: 'block',
      // background: ' no-repeat',
      // width: '20px',
      // height: '20px',
      // borderWidth: 'thin',
      // border:'solid 1px red',
      color: "green",
      padding: 0,
      margin: 0,
    },

    "& input[type=date]": {
      color: "green",
    },
  },

  //   '& input[type="date"]::-webkit-calendar-picker-indicator': {
  //     '-webkit-appearance': 'none',
  //     display: 'none',
  //     border:'slid'
  // },
}));

const useStylesTS = makeStyles((theme) => ({
  input: {
    "&::placeholder": {
      // color:'red',
      [theme.breakpoints.only("xs")]: {
        fontSize: 13,
      },
    },
  },
}));

const useStylesTF = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "navy",
      fontSize: "0.8rem",
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: "navy",
      fontSize: "0.7rem",
      lineHeight: "0.5rem",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 8,
      paddingBottom: 7,
      textAlign: "center",
      fontSize: "1rem",
    },
  },

  rootEDS: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "navy",
      fontSize: "0.9rem",
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: "navy",
      fontSize: "0.8rem",
      lineHeight: "0.5rem",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 8,
      paddingBottom: 7,
      textAlign: "center",
      fontSize: "1.2rem",
    },
  },

  rootmt: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "navy",
      fontSize: "0.8rem",
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: "navy",
      fontSize: "0.7rem",
      lineHeight: "0.5rem",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 4,
      paddingBottom: 4,
      textAlign: "center",
      fontSize: "1rem",
      margin: theme.spacing(0),
    },
    margin: theme.spacing(0),
  },

  rootSer: {
    margin: theme.spacing(1),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiOutlinedInput-inputMarginDense": { fontSize: "1rem" },
  },

  rootSerCurr: {
    // margin: theme.spacing(1),
    // padding: theme.spacing(1),

    "& .MuiTextField-root": {
      // margin: theme.spacing(1),
      // padding: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiOutlinedInput-inputMarginDense": { fontSize: "1rem" },
  },

  mtTpric: {
    [theme.breakpoints.only("lg")]: { width: 80 },
    [theme.breakpoints.down("md")]: { width: 100 },
  },
  mtTval1: {
    [theme.breakpoints.only("lg")]: { width: 50 },
    [theme.breakpoints.down("md")]: { width: 60 },
  },
  mtTdisc: {
    [theme.breakpoints.only("lg")]: { width: 50 },
    [theme.breakpoints.down("md")]: { width: 50 },
  },
  tool: {
    marginTop: 7,
    marginBottom: 5,
  },
  mtTnote: {
    [theme.breakpoints.only("lg")]: { width: 100 },
    [theme.breakpoints.down("md")]: { width: 150 },
  },
  roorSs: {
    margin: 3,
  },

  input: {
    color: "#000",
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#000" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#000" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#000" },
    //   '& input[type=number]': { '-moz-appearance': 'textfield' },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  rootI: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "navy",
      fontSize: "0.8rem",
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: "navy",
      fontSize: "0.7rem",
      lineHeight: "0.5rem",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      textAlign: "center",
      fontSize: 16,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 8,
      paddingBottom: 7,

      // [theme.breakpoints.down('sm')]: {
      //   background:'green',
      // },
      [theme.breakpoints.only("xs")]: {
        // background:'brown',
        fontSize: 15,
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
  },

  inputCustm: {
    color: "#000",
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#000" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#000" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#000" },
    //   '& input[type=number]': { '-moz-appearance': 'textfield' },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const useStylesTA = makeStyles((theme) => ({
  container: {},
  textField: {},
  dense: {
    marginTop: theme.spacing(10),
  },
  menu: {
    // width: '100%',
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    // marginTop:theme.spacing(5.7),
    marginTop: theme.spacing(6.5),
  },
  item: {
    // textAlign:'center',
    justifyContent: "center",
  },
}));

const useStylesAuto = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: "green",
      fontSize: "0.7rem",
      // fontSize:12,
    },
  },
  inputRoot: {
    color: "green", // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      /* Default left padding is 6px*/ paddingLeft: 0,
      paddingRight: 0,
      // marginLeft:-5, marginRight:-5,
      textAlign: "center !important",
      marginLeft: -4,
      marginRight: -14,
      fontSize: "0.7rem",
    },
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "green" },
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "green" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "green" },
  },
  popupIndicator: {
    color: "green",
    padding: 0,
    marginRight: -6,
    top: 3,
    fontSize: "1rem",
  },
  option: {
    "&:hover": {
      backgroundColor: "#b1deb199 !important",
      color: "green",
    },
    color: "#005a01",
    justifyContent: "center",
    fontSize: "0.7rem",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  root22: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: "green",
      fontSize: "0.7rem",
      // fontSize:12,
      marginTop: 7,
      marginBottom: 7,
    },
  },
}));

const useStylesLbl = makeStyles((theme) => ({
  clor: { color: "#000" },
  /* for SWich */
  [theme.breakpoints.only("md")]: {
    clrSaleSw: { color: "#000", marginRight: "-29px" },
  },
  clrSaleSw: { color: "#000" },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const PurpleSwitch = withStyles({
  root: {
    // width: 28,
    // height: 32,
    // padding: 0,
    // display: 'flex',
  },
  switchBase: {
    color: purple[300],
    "&$checked": {
      color: purple[500],
    },
    "&$checked + $track": {
      backgroundColor: purple[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const SaleCheckbox = withStyles({
  root: { color: green[400], "&$checked": { color: green[600] } },
  // label:{ color:'red' },
  checked: {},
})((props) => <Checkbox {...props} />);

const PurchCheckbox = withStyles({
  root: { color: "#007878", "&$checked": { color: "#007878" } },
  label: { color: "red" },
  checked: {},
})((props) => <Checkbox {...props} />);

const SafeCheckbox = withStyles({
  root: { color: "navy", "&$checked": { color: "navy" } },
  label: { color: "red" },
  checked: {},
})((props) => <Checkbox {...props} />);

const RetCheckbox = withStyles({
  root: { color: "red", "&$checked": { color: "red" } },
  label: { color: "red" },
  checked: {},
})((props) => <Checkbox {...props} />);

const SaleSwitch = withStyles({
  root: {},
  switchBase: {
    color: "#43a047",
    "&$checked": { color: "#43a047" },
    "&$checked + $track": { backgroundColor: "#43a047" },
  },
  checked: {},
  track: {},
})(Switch);

const PurchSwitch = withStyles({
  switchBase: {
    color: "#007878",
    "&$checked": { color: "#007878" },
    "&$checked + $track": { backgroundColor: "#007878" },
  },
  checked: {},
  track: {},
})(Switch);

const SafeSwitch = withStyles({
  switchBase: {
    color: "navy",
    "&$checked": { color: "navy" },
    "&$checked + $track": { backgroundColor: "navy" },
  },
  checked: {},
  track: {},
})(Switch);

const RetSwitch = withStyles({
  switchBase: {
    color: "red",
    "&$checked": { color: "red" },
    "&$checked + $track": { backgroundColor: "red" },
  },
  checked: {},
  track: {},
})(Switch);

// up
const useStylesHed = makeStyles((theme) => ({
  rootHd: {
    display: "flex",
    textAlign: "center",
  },
  HeaderHd: {
    textAlign: "center",
    marginBottom: "0%",
  },
  rootHeaders:{
    backgroundColor: colors.red[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },
  ChipHdSale: {
    color: "#005a01",
    backgroundColor: "#b1deb1",
    borderRadius: "0px 0px 10px 10px",
  },
  ChipHdCustomer: {
    color: "#FFF",
    backgroundColor: insh.colors.customer,
    borderRadius: "0px 0px 0px 0px",
  },
  ChipHdAccount: {
    color: "#ffffff",
    backgroundColor: "#808080",
    borderRadius: "0px 0px 10px 10px",
  },
  ChipHdPurch: {
    color: "#008080",
    backgroundColor: "#acdada",
    borderRadius: "0px 0px 10px 10px",
  },
  ChipHdSafe: {
    color: "#000080",
    backgroundColor: "#b1b1da",
    borderRadius: "0px 0px 10px 10px",
  },

  ChipHdRet: {
    color: "#ff3e3b",
    backgroundColor: "#fbd2d0",
    borderRadius: "0px 0px 10px 10px",
  },

  ChipHdUser: {
    color: "#FFF",
    backgroundColor: insh.colors.user,
    borderRadius: "0px 0px 0px 0px",
  },
  ckBoxHd: { marginTop: -7 },

  [theme.breakpoints.down("xs")]: {
    ckBoxHd: {
      color: "green",
      marginRight: 0,
      marginTop: -7,
      "& .MuiTypography-body1": {
        fontSize: "0.7rem",
        marginLeft: "-10px",
      },
    },
  },
}));

// up
const useStylesUp = makeStyles((theme) => ({
  AboverootupInv: {
    // border:'solid red',
    width: "100%",
    // padding: theme.spacing(15),
    padding: theme.spacing(0),
    // margin: theme.spacing(5),
  },
  AboverootupSer: {
    // border:'solid red',
    width: "100%",
    padding: theme.spacing(0),
  },
  rootup: {
    // border:'solid',
    padding: theme.spacing(0.5),
    direction: "rtl",
    justifyContent: "center",
    //  alignItems:'center',
    //  boxShadow:'0 1px 6px 0 rgba(32, 33, 36, .28)',
    //  border:'solid 1px rgba(32, 33, 36, .28)',

    //  display: flex,
    // flex-direction: column;
    // justify-content: center;
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary} , 0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    // boxShadow: `1px 0px 5px 0.1px ${insh.colors.primary}, -1px 0px 5px 0.1px ${insh.colors.secndry}`,
    // boxShadow: '5px 10px 20px red inset',

    borderRadius: "8px",

    //  borderRadius:7,
    margin: "0.5% 0% 1% 1.5%",
    backgroundColor: "#fff",
    width: "97%",
    // style={{borderRadius:7, margin:'1% 0% 1% 1.5%', backgroundColor: '#fff', width:'97%'}}
  },
  rootupRest: {
    // border:'solid',
    padding: theme.spacing(0.2),
    direction: "rtl",
    justifyContent: "center",
    borderRadius: "8px",

    //  borderRadius:7,
    margin: "0.5% 0% 1% 1.5%",
    backgroundColor: "#fff",
    width: "97%",
    // style={{borderRadius:7, margin:'1% 0% 1% 1.5%', backgroundColor: '#fff', width:'97%'}}
  },
  rootupRest1: {
    // border:'solid',
    padding: theme.spacing(0.5),
    direction: "rtl",
    justifyContent: "center",
    borderRadius: "8px",

    //  borderRadius:7,
    margin: "0.5% 0% 1% 0.5%",
    backgroundColor: "#fff",
    width: "99%",
    // style={{borderRadius:7, margin:'1% 0% 1% 1.5%', backgroundColor: '#fff', width:'97%'}}
  },
  rootupSer: {
    justifyContent: "center",
    // padding: theme.spacing(2),

    // [theme.breakpoints.up('lg')]: {
    //   background:'orange',
    //   },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(),
    },
    // [theme.breakpoints.down('xs')]: {
    //   background:'brown',
    // },
  },
  appBarup: {
    // position: 'relative',
    top: "auto",
    bottom: 0,
  },
  titleup: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

// Down
const useStylesDw = makeStyles((theme) => ({
  rootdw: {
    padding: theme.spacing(0.5),
    direction: "rtl",
  },
  appBardw: {
    // position: 'relative',
    top: "auto",
    bottom: 0,
  },
  titledw: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  containerDatedw: {
    display: "flex",
    flexWrap: "wrap",
  },
  textFieldDatedw: {
    // border:'solid',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(0),
    // width: 200,
  },
}));

// btom bar

const useStylesBA = makeStyles((theme) => ({
  appBarBASal: { top: "auto", bottom: 0, background: "#319835" },
  appBarBAPurch: { top: "auto", bottom: 0, background: "#008080" },
  appBarBASafe: { top: "auto", bottom: 0, background: "#1a1a8a" },
  appBarBARet: { top: "auto", bottom: 0, background: "#ff3e3b" },
  appBarBACustm: {
    top: "auto",
    bottom: 0,
    // background:'#81007f'
    backgroundImage: "linear-gradient(160deg, #d312d0 , #81007f)",
  },
  appBarBAAccounts: { top: "auto", bottom: 0, background: "#808080" },
  ToolbarBA: {
    [theme.breakpoints.down("xs")]: {
      paddingRight: 1,
    },
  },
  containerBAAccounts: {
    backgroundColor: "#808080",
  },
  continarBAInv: {
    backgroundColor: "#f9fafa",
    // height: '100vh',
  },
  continarBASer: { backgroundColor: "#f9fafa", height: "100vh" },
  continarBAPurch: { backgroundColor: "#00808061" },
  continarBASafe: { backgroundColor: "#0000805e" },

  [theme.breakpoints.only("lg")]: {
    continarBACustm: {
      backgroundColor: "#fafafa",
      padding: 50,
      margin: 100,
    },
  },

  [theme.breakpoints.only("md")]: {
    continarBACustm: {
      backgroundColor: "#fafafa",
      padding: 20,
      margin: 50,
    },
  },

  [theme.breakpoints.only("sm")]: {
    continarBACustm: {
      backgroundColor: "#fafafa",
      padding: 20,
      margin: 20,
    },
  },
  [theme.breakpoints.only("xs")]: {
    continarBACustm: { marginTop: 15 },
  },

  // continarBARet:{ backgroundColor:'#ef53505e', },
  growBA: {
    flexGrow: 1,
  },

  // },
}));

const useStylesFU = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const useStylesBt = makeStyles((theme) => ({
  root: {
    direction: "rtl",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0),
      padding: theme.spacing(1),
    },
  },
  Chip: {
    height: 150,
    width: "100%",
    fontSize: 35,
    borderRadius: 10,
  },
}));

// Accordion
const useStylesAC = makeStyles((theme) => ({
  rootAC: {
    width: "100%",
  },
  headingAC: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeadingAC: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const useStylesIL = makeStyles((theme) => ({
  rootIL: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  imageListIL: {
    width: 500,
    height: 450,
  },
  iconIL: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  titleIL: {
    color: "#fff",
  },
  titleBarIL: {
    background:
      "linear-gradient(to top, rgb(40 47 93 / 70%) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    // 'inear-gradient(to top, #155a65 0%, #1a33584f 70%, rgba(0,0,0,0) 100%) !important;;'
    height: 50,
  },
}));

const useStylesCAB = makeStyles((theme) => ({
  rootCAB: {
    display: "flex",
    alignItems: "center",
  },
  wrapperCAB: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(0.6, 0.5, 0.6, 0.2),

    [theme.breakpoints.up("lg")]: {
      // margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
    position: "relative",
  },
  buttonSuccessCAB: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },

  btnStyR: {
    padding: 12,
  },
  btnStyL: {
    padding: 12,
  },
  fabProgressCAB: {
    // color: green[500],
    color: "#fff",
    position: "absolute",
    top: -4,
    left: -4,
    zIndex: 1,
  },
  buttonProgressCAB: {
    // color: green[500],
    color: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useStylesDSM = makeStyles((theme) => ({
  rootSM: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageListSM: {
    width: 500,
    height: 450,
  },
  iconSM: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

const useStylesDTa = makeStyles((theme) => ({
  rootTa: {
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3),
    },
  },
  dialogTitleTa: {
    // border:'solid 1px red',
    padding: 5,
  },

  closeButtonTa: {},

  dialogContentTa: {
    padding: theme.spacing(0),

    backgroundColor: theme.palette.grey[100],
    //backgroundColor: 'blue',

    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(0), },
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(1), },
    // [theme.breakpoints.up('lg')]: {
    //   padding: theme.spacing(2),
    //  },
  },

  dialogActionsTa: {
    // margin: 0,
    // padding: theme.spacing(0.5),
  },
}));

const useStylesDTaCont = makeStyles((theme) => ({
  rootTaCont: {},
}));

const useStylesInfoTcu = makeStyles((theme) => ({
  gridTable: {
    [theme.breakpoints.up("lg")]: {
      //  padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      // padding: theme.spacing(1),
    },
  },

  gridTableInner: {
    backgroundColor: "#fff",
  },

  gridInfo: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: "1rem",
      // padding: theme.spacing(0),
      border: "solid 1px #009688",
      borderRadius: 10,
      marginLeft: "2px",
      marginRight: "2px",
    },
  },

  noScroll: {
    overflowY: "hidden" /* Hide vertical scrollbar */,
    overflowX: "hidden" /* Hide horizontal scrollbar */,
    scrollbarWidth: "none",
    scrollbarWidth: "thin",
    scrollbarWidth: "auto",
  },
  gridInfoInner: {
    backgroundColor: "#fff",
    padding: theme.spacing(1),

    [theme.breakpoints.up("lg")]: {
      //  margin: theme.spacing(1),
      // marginLeft: theme.spacing(5),
    },
  },

  // className={classesInfo.gridTable}

  // chipcu: {  color: 'primary' },
}));

const useStylesInfoPa = makeStyles((theme) => ({
  gridTable: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
  },

  gridTableInner: {
    backgroundColor: "#fff",
  },

  gridInfo: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
  },

  gridInfoInner: {
    backgroundColor: "#fff",
    padding: theme.spacing(1),

    [theme.breakpoints.up("lg")]: {
      //  margin: theme.spacing(1),
      // marginLeft: theme.spacing(5),
    },
  },

  // className={classesInfo.gridTable}

  // chipcu: {  color: 'primary' },
}));

const useStylesInfoGridItem = makeStyles((theme) => ({
  rootIGI: {
    [theme.breakpoints.only("lg")]: { marginTop: 45 },
    [theme.breakpoints.only("md")]: { marginTop: 20 },
    [theme.breakpoints.only("sm")]: { marginTop: 5 },
    [theme.breakpoints.only("xs")]: { marginTop: -8 },
  },
}));

const useStylesPa = makeStyles((theme) => ({
  rootPa: {
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3),
    },
  },
  dialogTitlePa: {
    border: "solid 1px red",
  },
  closeButtonPa: {},
  dialogContentPa: {
    padding: theme.spacing(0),

    backgroundColor: theme.palette.grey[100],
    //backgroundColor: 'blue',

    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(0), },
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(1), },
    // [theme.breakpoints.up('lg')]: {
    //   padding: theme.spacing(2),
    //  },
  },

  dialogActionsPa: {
    // margin: 0,
    // padding: theme.spacing(0.5),
  },
}));

const useStylesDPM = makeStyles((theme) => ({
  rootPM: {
    direction: "rtl",
    [theme.breakpoints.only("lg")]: {
      // backgroundColor:'#fff'
      paddingTop: theme.spacing(12),
      paddingRight: theme.spacing(30),
      paddingBottom: theme.spacing(12),
      paddingLeft: theme.spacing(30),
    },
  },
  dirLPM: { direction: "rtl" },
  dirRPM: { direction: "ltr" },

  dialogContentPM: {
    background: "#f6f5f4",

    direction: "rtl",

    // padding: theme.spacing(0),

    // backgroundColor: theme.palette.grey[100],
    //backgroundColor: 'blue',

    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(0), },
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(1), },
    [theme.breakpoints.up("lg")]: {
      // padding: theme.spacing(2),
    },
    //  margin: 0,
    [theme.breakpoints.only("xs")]: {
      // marginTop: theme.spacing(3),
      padding: theme.spacing(2),
    },
  },

  diaCPMBasicDa: {
    background: "#fff",

    // padding: theme.spacing(0),

    // backgroundColor: theme.palette.grey[100],
    //backgroundColor: 'blue',

    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(0), },
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(1), },
    [theme.breakpoints.up("lg")]: {
      // padding: theme.spacing(2),
    },
    //  margin: 0,
    [theme.breakpoints.only("xs")]: {
      // marginTop: theme.spacing(3),
      padding: theme.spacing(2),
    },
  },

  gridLeftPM: {
    // background:'#d3d3d3',
    [theme.breakpoints.only("lg")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.only("md")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(1),
    },
  },

  gridLeftInnerPM: {
    backgroundColor: "#fff",
  },

  gridRigthPM: {
    background: "#f5f5dc",

    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },

    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(3),
    },
  },

  checkPM: {
    textAlign: "-webkit-right",
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: theme.spacing(2),
    // },
  },

  innerContinerPM: {
    background: "#fff",
    // border:'solid',
    [theme.breakpoints.only("lg")]: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
      // spacing:theme.spacing(2)
    },

    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(0.8),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      // spacing:theme.spacing(2)
    },
  },

  gridSpacePM: {
    [theme.breakpoints.up("lg")]: {
      // marginBottom: theme.spacing(2),
      paddingRight: theme.spacing(1),
      // spacing:theme.spacing(2)
    },
  },
}));

const useStylesTg = makeStyles((theme) => ({
  root: { display: "flex" },
}));

const useStylesUiElemment = makeStyles((theme) => ({
  rootchip: {
    //   // border:'solid 0.1px',
    // [theme.breakpoints.only('lg')]: {
    //   // margin: '0rem 2rem 0rem 25rem',
    // },
    // [theme.breakpoints.only('md')]: {
    //   // padding: '0.5rem 10rem 0.5rem 10rem',
    // },
    // [theme.breakpoints.only('sm')]: {
    //   // padding: '1rem 4rem 1rem 4rem',
    // },
    // [theme.breakpoints.only('xs')]: {
    //   // padding: '0.2rem 0.5rem 0.2rem 0.5rem',
    // },
    // border:'solid'
    //  padding:'1px'
  },

  chipT: {
    minWidth: "100%",
    border: "0.1rem solid #fff",
    [theme.breakpoints.only("lg")]: {
      padding: "1.8rem 0rem",
      fontSize: 14,
      borderRadius: "0.5rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "1.6rem 0rem",
      fontSize: 13,
      borderRadius: "0.4rem",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "1.5rem 0rem",
      fontSize: 12,
      borderRadius: "0.3rem",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "1.3rem 0rem",
      fontSize: 11,
      borderRadius: "0.2rem",
    },
  },

  chipV: {
    minWidth: "100%",
    border: "0.1rem solid #fff",
    [theme.breakpoints.only("lg")]: {
      padding: "1.2rem 0rem",
      fontSize: 15,
      borderRadius: "0.4rem",
      //  margin:4
    },
    [theme.breakpoints.only("md")]: {
      padding: "1.2rem 0rem",
      fontSize: 12,
      borderRadius: "0.3rem",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "1.3rem 0rem",
      fontSize: 17,
      borderRadius: "0.2rem",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "1.2rem 0rem",
      fontSize: 15,
      borderRadius: "0.1rem",
    },
  },
  contbnt: {
    justifyContent: "center",
  },
  button: {
    // margin: theme.spacing(1),
    color: "#fff",
    backgroundColor: "#008081",
    boxShadow: "none",
    direction: "initial",
    padding: "8px 12px",
    // [theme.breakpoints.only('md')]: {
    //   padding: '8px 12px'
    // },
  },
  buttonB: {
    // margin: theme.spacing(1),
    color: "#fff",
    backgroundColor: "#f18584",
    boxShadow: "none",
    direction: "initial",
    padding: "8px 12px",
    // [theme.breakpoints.only('md')]: {
    //   padding: '8px 12px'
    // },
  },
}));

const useStylesContainers = makeStyles((theme) => ({
  rootup: {
    // // border:'solid 0.1px',
    // // marginTop:'-1rem',
    padding: theme.spacing(1),
    // [theme.breakpoints.only('lg')]: {
    //   padding: theme.spacing(1),
    // },
    // [theme.breakpoints.only('md')]: {
    //   // padding: theme.spacing(0.5),
    // },
  },
  rootdown: {
    padding: theme.spacing(1),

    // // border:'solid 0.1px',
    // // marginTop:'-1rem',
    // [theme.breakpoints.only('lg')]: {
    //   padding: theme.spacing(1),
    // },
    // [theme.breakpoints.only('md')]: {
    //   // padding: theme.spacing(0.5),
    // },
  },
  subUp: {
    // borderRadius:5,
    // margin: theme.spacing(1),
    // padding: theme.spacing(10),
    // [theme.breakpoints.only('xs')]: {
    //   //  marginLeft:'0rem',marginRight:'0rem'
    // },
    padding: "2rem 1rem",
  },
  subDown: {
    // borderRadius:5,
    // margin: theme.spacing(1),
    // [theme.breakpoints.only('lg')]: {
    //   // padding: theme.spacing(1),
    // },
    // [theme.breakpoints.only('md')]: {
    //   // padding: theme.spacing(0.5),
    // },
    // [theme.breakpoints.only('sm')]: {
    //   // padding: theme.spacing(1),
    // },
    // [theme.breakpoints.only('xs')]: {
    //   // padding: theme.spacing(1), marginLeft:'0rem',marginRight:'0rem'
    // },
    // // border:'solid 0.1px',
  },
  subUpR: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
                0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    // alignItems: 'center',
    [theme.breakpoints.only("lg")]: {
      // padding:'2rem 1rem',
    },
    // [theme.breakpoints.only('md')]: {
    //   // padding:'4rem 0.1rem'
    // },
  },
  subUpL: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
                0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    padding: "1rem 1rem",
  },
  subDownR: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
                0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
  },
  subDownL: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
                0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    // padding: theme.spacing(2),
  },
  subUpC: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
              0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    padding: theme.spacing(2),
  },
  subUpCustomer: {
    boxShadow: `0px 0px 1.5px 0.5px #1a1a8a,
              0px 0px 1.5px 0.5px ${insh.colors.customer}`,
    // padding: theme.spacing(2),
  },
  subDownC: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
              0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    padding: "1rem 1rem 0rem 1rem",
  },
  /* B customes */
  subUpRBranch: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
              0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    [theme.breakpoints.only("lg")]: { padding: "5rem 3rem" },
    [theme.breakpoints.only("md")]: { padding: "4rem 1rem" },
    [theme.breakpoints.only("sm")]: { padding: "4rem 4rem" },
  },
  subUpRgroups: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
              0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    [theme.breakpoints.only("lg")]: { padding: "10rem 9rem" },
    [theme.breakpoints.only("md")]: { padding: "10rem 5rem" },
    [theme.breakpoints.only("sm")]: { padding: "4rem 8rem" },
    [theme.breakpoints.only("xs")]: { padding: "2rem 2rem" },
  },
  subUpRSafes: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
              0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    [theme.breakpoints.only("lg")]: { padding: "2rem 8rem" },
    [theme.breakpoints.only("md")]: { padding: "2rem 5rem" },
    [theme.breakpoints.only("sm")]: { padding: "2rem 8rem" },
    [theme.breakpoints.only("xs")]: { padding: "2rem 2rem" },
  },
  subDownRSafes: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
              0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    [theme.breakpoints.only("lg")]: { padding: "2rem 8rem" },
    [theme.breakpoints.only("md")]: { padding: "2rem 5rem" },
    [theme.breakpoints.only("sm")]: { padding: "2rem 8rem" },
    [theme.breakpoints.only("xs")]: { padding: "2rem 2rem" },
  },
  subUpRStocks: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
              0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    [theme.breakpoints.only("lg")]: { padding: "2rem 4rem" },
    [theme.breakpoints.only("md")]: { padding: "2rem 1rem" },
    [theme.breakpoints.only("sm")]: { padding: "2rem 8rem" },
    [theme.breakpoints.only("xs")]: { padding: "2rem 1rem" },
  },
  subDownRStocks: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
              0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    [theme.breakpoints.only("lg")]: { padding: "2rem 8rem" },
    [theme.breakpoints.only("md")]: { padding: "2rem 5rem" },
    [theme.breakpoints.only("sm")]: { padding: "2rem 8rem" },
    [theme.breakpoints.only("xs")]: { padding: "2rem 2rem" },
  },
  subUpRBanks: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
              0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    [theme.breakpoints.only("lg")]: { padding: "5rem 4rem" },
    [theme.breakpoints.only("md")]: { padding: "5rem 1rem" },
    [theme.breakpoints.only("sm")]: { padding: "2rem 8rem" },
    [theme.breakpoints.only("xs")]: { padding: "2rem 2rem" },
  },
  subUpRomlat: {
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
              0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    [theme.breakpoints.only("lg")]: { padding: "10rem 9rem" },
    [theme.breakpoints.only("md")]: { padding: "10rem 5rem" },
    [theme.breakpoints.only("sm")]: { padding: "4rem 8rem" },
    [theme.breakpoints.only("xs")]: { padding: "2rem 2rem" },
  },

  /* E customes */

  appBarup: {
    // position: 'relative',
    top: "auto",
    bottom: 0,
  },
  titleup: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const useStylesSpDial = makeStyles((theme) => ({
  rootsd: {
    // flexGrow: 1,
    position: "relative",
  },
  speedDialsd: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
  upersal: {
    background: "red",
  },
  ico: {
    background: "#fff",
    width: "100%",
    height: "100%",
    borderRadius: 50,
    padding: 7,
    color: "#fff",
    background: insh.colors.secndry,
  },
  clPri: { background: insh.colors.primary },
  clSec: { background: insh.colors.secndry },
  clsal: { color: insh.colors.sal },
  clpur: { color: insh.colors.pur },
  clsaf: { color: insh.colors.saf },
  clacc: { color: insh.colors.acc },
  clcan: { color: "#fff", background: insh.colors.cancl },
}));
//====================================================== start menu btn
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const prnmen = {
  top: "-40px",
};

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStylesSN = makeStyles((theme) => ({
  rootSN: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  snackbarSN: {
    // [theme.breakpoints.up('lg')]: {
    //   bottom: 20,
    //   },
    // [theme.breakpoints.up('md')]: {
    //   bottom: 20,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   bottom: 20,
    // },
    // [theme.breakpoints.down('xs')]: {
    //   bottom: 20,
    // },
    zIndex: 1400,
  },
  snackbarSNKh: {
    // [theme.breakpoints.up('lg')]: {
    //   bottom: 20,
    //   },
    // [theme.breakpoints.up('md')]: {
    //   bottom: 20,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   bottom: 20,
    // },
    // [theme.breakpoints.down('xs')]: {
    //   bottom: 90,
    // },
    bottom: 90,
    zIndex: 1400,
  },
}));

const useStylesBCSM = makeStyles((theme) => ({
  modalbcsm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#dfdfdf91",
  },
  paperbcsm: {
    // backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
}));

const useStylesSetting = makeStyles((theme) => ({
  rootLoad: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  placeholder: {
    height: 40,
  },
  rootSett: {
    flexGrow: 1,
    // width: '100%',
    padding: 20,
    // margin:20,
    textAlign: "center",
    display: "grid",
    // maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.only("xs")]: {
    rootSett: {
      flexGrow: 1,
      // width: '100%',
      padding: 0,
      // margin:20,
      textAlign: "center",
      display: "grid",
      // maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },
  },
  titleSett: {
    display: "flex",
    direction: "rtl",
    background: "#f5f5f5b5",
    color: "#000000ab",
  },
  itemText: {
    color: "#000000ab",
  },
  btn: {
    padding: 12,
  },
}));

export {
  useStylesTF,
  useStylesTA,
  useStylesTD,
  useStylesTS,
  useStylesAuto,
  useStylesUp,
  useStylesHed,
  useStylesDw,
  useStylesBA,
  useStylesBt,
  useStylesFU,
  useStylesAC,
  useStylesIL,
  useStylesCAB,
  useStylesDSM,
  useStylesDTa,
  useStylesDTaCont,
  useStylesInfoTcu,
  useStylesInfoPa,
  useStylesPa,
  useStylesDPM,
  useStylesTg,
  useStylesLbl,
  StyledMenu,
  prnmen,
  StyledMenuItem,
  useStylesSN,
  useStylesBCSM,
  useStylesInfoGridItem,
  useStylesContainers,
  useStylesSpDial,
  useStylesUiElemment,
  SaleCheckbox,
  PurchCheckbox,
  SafeCheckbox,
  RetCheckbox,
  useStylesSetting,
  SaleSwitch,
  PurchSwitch,
  SafeSwitch,
  RetSwitch,
  AntSwitch,
  PurpleSwitch,
};
//====================================================== end menu btn

// // for table dilog

// export con st styRowH ={
//   background: '#718096',
//    height:'40px',
// }

// export con st styTbClH ={
//   // border:'solid',
//   whiteSpace:'nowrap',
//   // borderLeft: 'solid 0.5px  #a0aec0c4',
//   padding: '0px 5px',
//   margin: '0px',
//   width:'0px',
//   color:'#fff',
//   fontSize: '10px',
//   // fontWeight: '600',
// }

// export con st styTbClR ={
//   // border:'solid',
//   whiteSpace:'nowrap',
//   padding: '7px 5px',
//   // borderLeft: 'solid 1px  #a0aec0c4',
//   borderBottom: 'solid 0.1px #a0aec01a',
//   borderTop: 'solid 0px',
//   margin: '0px',
//   width:'0px',
//   // fontWeight: '600',
// }

/* 4 screan  */

// [theme.breakpoints.up('lg')]: {
//   background:'orange',
//   },
// [theme.breakpoints.up('md')]: {
//   background:'red',
// },
// [theme.breakpoints.down('sm')]: {
//   background:'green',
// },
// [theme.breakpoints.down('xs')]: {
//   background:'brown',
// },
