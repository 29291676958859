import React,{useEffect, useState,Fragment} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import axios from 'axios';
import {uri}  from '../../help/Api';
import {insh} from '../../Context/InshData';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
// import WifiIcon from '@material-ui/icons/Wifi';
import BackdWite from '../../services/BackdWite';
import BallotIcon from '@material-ui/icons/Ballot';
import LineStyleIcon from '@material-ui/icons/LineStyle';
// import BluetoothIcon from '@material-ui/icons/Bluetooth';
import Grid from '@material-ui/core/Grid';
// import  Swipeable  from '../../Fronts/Main/Swipeable';
// import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import {useStylesSetting,} from '../../St/comps/UseStyle';

export default function PermisionsApp() {
  const clasStt = useStylesSetting();
  const [showSc, setShowSc] = useState(false);
  const [permissionApp, setPermissionApp] = useState(insh.PermissionApp);


  // const getS =() => { setPermissionApp(insh.PermissionApp) }
  useEffect(() => {
    // getS
   },[]);

  const storgeing = async (info) => {

   const tb = { PermissionApp:true, wait:true,
    /*params*/
    parms:{
      iuserid:info.user_id, 
    },
    autos:{},
  }
  //  console.log('=tb='); console.log(tb);
  
  //  await axios.post('http://localhost:8184/api/'+'Res',tb)
   await axios.post(uri+'Res',tb)
    .then(res => { 
    localStorage.setItem('premiApp', JSON.stringify(res.data.permisApp));
    setPermissionApp(res.data.permisApp)
    setShowSc(res.data.wait.state)
  }).catch(error => { 
    // console.log('nooooo sttorgeing'); 
  })
  
  }

  const updatePrem = async (prop,event) => {
    // setPermissionApp()
   if(prop=='') permissionApp.menuMin=event.target.checked;
   else if(prop=='showCurr') permissionApp.showCurr=event.target.checked;

    await  axios.put(uri+'PermissionApp', permissionApp)
    .then(res => {setShowSc(true); storgeing(res.data) }).catch(error => { })
  }

  return (
    // showSc?<BackdWite/>:
    <Fragment> 
      {/* <div>{showSc+""}</div> */}
       <Container maxWidth="md">
      <Typography component="div" style={{ backgroundColor: '#f5f5f5b5', height: '100vh' }}>
        <Grid container md={12} className={clasStt.rootSett}>

          <List subheader={<ListSubheader className={clasStt.titleSett}>إعدادات الأصناف</ListSubheader>} className={clasStt.rootSett}>
           <ListItem>
              <ListItemIcon> <LineStyleIcon /> </ListItemIcon>
              {permissionApp.showCurr === true
              ?<ListItemText id="switch-list-label-wi" className={clasStt.itemText} secondary="تم التفعيل" primary="طريقة عرض الاصناف" />
              :<ListItemText id="switch-list-label-w" className={clasStt.itemText} secondary="قم بالتشغيل لعرض بشكل صور" primary="طريقة عرض الاصناف" />
              }
              <ListItemSecondaryAction>
                <Switch edge="end" onChange={e=> updatePrem('showCurr',e)} checked={permissionApp.showCurr}
                  inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }} name="curr" />
              </ListItemSecondaryAction>
            </ListItem>
          </List>

          <List subheader={<ListSubheader className={clasStt.titleSett}>إعدادات الواجهه الرئيسية</ListSubheader>} className={clasStt.rootSett}>
            <ListItem>
              <ListItemIcon> <BallotIcon /> </ListItemIcon>
            {permissionApp.menuMin 
              ?<ListItemText id="switch-list-label-wifi" className={clasStt.itemText} secondary="تم التفعيل" primary="طريقة عرض الواجهة الرئيسية" />
              :<ListItemText id="switch-list-label-wifi" className={clasStt.itemText} secondary="قم بالتشغيل للعرض بشكل النظام" primary="طريقة عرض الواجهة الرئيسية" />
            }
                <ListItemSecondaryAction>
                  <Switch edge="end" onChange={e=> updatePrem('menuMin',e)} checked={permissionApp.menuMin }
                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }} name="sett" />
                </ListItemSecondaryAction>
           </ListItem>
          </List>

        </Grid>
      </Typography>
    </Container>
    </Fragment>
  );
}



    //  <ListItem>
    //     <ListItemIcon>
    //       <BluetoothIcon />
    //     </ListItemIcon>
    //     <ListItemText id="switch-list-label-bluetooth" primary="Bluetooth" />
    //     <ListItemSecondaryAction>
    //       <Switch
    //         edge="end"
    //         onChange={handleToggle('bluetooth')}
    //         checked={checked.indexOf('bluetooth') !== -1}
    //         inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
    //       />
    //     </ListItemSecondaryAction>
    //   </ListItem> 
