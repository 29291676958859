/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { NavLink as RouterLink, useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  List,
  ListItem,
  Button,
  colors,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-end",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    // color: colors.red[700],
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;
  const his = useHistory();
  const classes = useStyles();
  const locConn = JSON.parse(localStorage.getItem("infoConn")) || {};
  const inshState = {
    server: locConn.server,
    ipinsh: locConn.ipinsh,
    changip: locConn.changip,
    ip: locConn.ip,
    endDigtip: locConn.endDigtip,
    namedb: locConn.namedb,
    id: locConn.id,
    pass: locConn.pass,
    useUri: locConn.useUri,
    port: locConn.port,
    ftp: locConn.ftp,
    typeConnct: locConn.typeConnct,
    mode: locConn.mode,
    leavle: "signIn",
  };
  const refDatas_out = () => {
    localStorage.removeItem("dataShamel");
    let loc = JSON.parse(localStorage.getItem("premiApp"));

    localStorage.setItem("premiApp", JSON.stringify({ ...loc, id: 0 }));
    localStorage.removeItem("DataRem");
  };

  const singout = () => {
    refDatas_out();
    localStorage.setItem("infoConn", JSON.stringify(inshState));
    his.push({ pathname: "/" });
    window.location.reload(false);
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages
        .filter?.((x) => x.isAllow)
        ?.map((page, i) => (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <Button
              activeClassName={classes.active}
              className={classes.button}
              // component={CustomRouterLink}
              onClick={() => {
                his.push({
                  pathname: `/${page.href}`,
                  state: page?.insh,
                });
              }}
              // to={page.href}
            >
              {page.title}
              <div className={classes.icon}>{page.icon}</div>
            </Button>
          </ListItem>
        ))}

      <ListItem
        button
        style={{ backgroundColor: colors.red[500] }}
        onClick={singout}
      >
        <ListItemIcon>
          {" "}
          <LockOutlinedIcon style={{ color: "#fff" }} />{" "}
        </ListItemIcon>
        <ListItemText>
          <span className="fontZ">{"تسجيل الخروج"}</span>
        </ListItemText>
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
