import React, {useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';
import { useHistory } from 'react-router-dom';
import {useStylesBA  ,useStylesCAB,} from '../../../St/comps/UseStyle';

import { stElemnt } from '../../../St/comps/cStyle';
import toast from 'react-hot-toast';
import {insh as inshst} from '../../../Context/InshSt';
import SearchIcon from '@material-ui/icons/Search';

import DeleteConfirmDialog from '../../../components/DeleteConfirmDialog';
import { payTypes } from '../../../services/global-services';
import CustomerSearchModal from './customer-search-modal';
import { t } from 'i18next';
function getItemFromOptions(id, options){
  if(id && id !=-1)
    return options.filter(g=>g.id==id)[0];
  return '';
}

export default function StocksAppBar(props){
    const {
        data,
        submitting,
        submit,
        update,
        remove,
        object,
        formType,
        onShow,
        defaultValues,
        isDialog,
        type,
    } = props;

    const perm = data?.permisElm;

  let his = useHistory();
  const clasBA = useStylesBA();
  const clasCAB = useStylesCAB();

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false })
  const [openSearchMdl, setOpenSearchMdl] = useState(false);

  const [style, setStyle] = useState(inshst.custm);

  const onRowClick =(row)=>{
    onShow({
      ...row, 
          act: getItemFromOptions(row.act, data?.Acts),
          Cust_inv_type: row?.Cust_inv_type? getItemFromOptions(row.Cust_inv_type, payTypes): payTypes[0],
          salesmen: (row.salesmen && row.salesmen != 0)? getItemFromOptions(row.salesmen, data?.salesmen): '',
          country: getItemFromOptions(row.country, data?.Countries),
          city: getItemFromOptions(row.city, data?.Cities),
          area: getItemFromOptions(row.area, data?.Areas),
          }, "edit");
      // console.log("the data is ",  row.area);

    setOpenSearchMdl(false);
}
  
const onSubmitHandle =(data)=>{
    if(data.name ==''){
      toast.error("ادخل اسم العميل");
    }
    else if(data.act == ''){
      toast.error("قم بتحديد المجموعة");
    }else {
      console.log("the data is ", data);
      const nData ={
        ...data,
        act: data.act?.id,
        country: data.country?.id?? -1,
        city: data.city?.id?? -1,
        area: data.area?.id?? -1,
        salesmen: data.salesmen ? data.salesmen.id: 0,
        Cust_inv_type: data?.Cust_inv_type?.id,
      }
      console.log(nData)
        if(formType=='edit')
            update(nData)
        else
            submit(nData)
    }

}

const backHome = () => { 
  
  
    his.push({ pathname: '/', });
  /*console.log('stooping')*/ }
const back = () => his.goBack(); 


return (
      <React.Fragment>
        <DeleteConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
          />
      <CssBaseline />
      <AppBar position="fixed" color="primary"
        style={{...style.bgImg,...stElemnt.all.appBarBA, direction:'ltr'}}
        >
        <Toolbar className={clasBA.ToolbarBA} variant="dense">
         
         {isDialog ? 
            <Button style={{color:'#fff'}} onClick={props.onClose} startIcon={<CloseIcon />} >{t('forms:close')}</Button>
            :
          <>
            <Hidden smUp={['sm']}>
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={()=>backHome()}>
              <CloseIcon />
            </IconButton>
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={()=>back()}>
              <ReplyIcon />
            </IconButton>
            <IconButton edge="start" color="inherit" aria-label="open drawer" 
                disabled={perm?.Se} 
                onClick={() => setOpenSearchMdl(true)}>
              <SearchIcon />
            </IconButton>
            </Hidden>

            <Hidden only={['xs']}>
              <Button 
                style={{color:'#fff'}}
                onClick={() => setOpenSearchMdl(true)} 
                disabled={perm?.Se} 
                startIcon={<SearchIcon />}> {t('form:search')}</Button>
              <Button style={{color:'#fff'}} onClick={()=>backHome()} startIcon={<CloseIcon />} >{t('forms:exit')}</Button>
              <Button style={{color:'#fff'}} onClick={()=>back()} startIcon={<ReplyIcon />} >{t('forms:back')}</Button>
            </Hidden>

          </>
         } <>
          </>
        
          <div className={clasBA.growBA} />

      
    <Hidden smUp={['sm']}>

        {/* ico left  */}
        <div className={clasCAB.wrapperCAB}>
          <Fab size="medium" color="primary"
          onClick={()=>onSubmitHandle(object)}
          disabled={submitting  || (formType == 'edit'? perm?.Up : perm?.Sa)} 
          style={{...style.iconBtn,...stElemnt.all.iconBtn}}
          >{<SaveIcon />}
          </Fab>
          {submitting && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
        </div>
      
        <div className={clasCAB.wrapperCAB}>
          <Fab size="medium" color="primary" 
          onClick={()=>{
            onShow(defaultValues, 'new');
            toast(t('forms:new-message'))
            }}
            disabled={perm?.Ne}
          style={{...style.iconBtn,...stElemnt.all.iconBtn}} 
          >{<AddIcon />}
          </Fab>
          {submitting && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
        </div>
      </Hidden>
      {formType == 'edit'?
      <div className={clasCAB.wrapperCAB}>
        <Button variant="contained" color="primary" size="large"
          startIcon={<DeleteIcon />}
          disabled={submitting  || perm?.De} 
          style={{...style.iconBtn,...stElemnt.all.iconBtn}}
          onClick={(e)=>
            setConfirmDialog({
                      isOpen: true,
                      onConfirm: () => { remove(object.id); }
                  })
          }
          
          >{t('forms:delete')}</Button>
        {submitting && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
      </div>:null}

      <Hidden only={['xs']}>
      <div className={clasCAB.wrapperCAB}>
        <Button variant="contained" color="primary" size="large"
          style={{...style.iconBtn,...stElemnt.all.iconBtn}}
          disabled={submitting  || (formType == 'edit'? perm?.Up : perm?.Sa)} 
          onClick={()=>onSubmitHandle(object)}
            startIcon={<SaveIcon />}> {formType == 'edit'? t('forms:save-update'): t('save')} </Button>
        {submitting && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
      </div>

      <div className={clasCAB.wrapperCAB}>
        <Button variant="contained" color="primary" size="large"
          style={{...style.iconBtn,...stElemnt.all.iconBtn}}
          disabled={perm?.Ne}
          onClick={()=>{
            onShow(defaultValues, 'new');
            toast.success(t('forms:new-message'))
        }}
            startIcon={<AddIcon />}> {t('forms:new')} </Button>
      </div>

      </Hidden>

        </Toolbar>
      </AppBar>
       <CustomerSearchModal 
          type={type}
          open={openSearchMdl}
          onClose={()=>{setOpenSearchMdl(false)}} 
          onRowClick={onRowClick}/>
    </React.Fragment>

 );
 
}