import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { cellS, headerB } from "../../../St/comps/cStyle";

import { useStylesContainers } from "../../../St/comps/UseStyle";
function getItemFromOptions(id, options) {
  if (id) return options.filter((g) => g.id == id)[0];
  return "";
}
const SalesPersonTable = (props) => {
  const { data, onShow } = props;
  const clsConts = useStylesContainers();
  const columns = [
    { title: "الرقم", field: "id" },
    { title: "اسم المندوب", field: "name" },
    { title: "العمولة", field: "comm" },
    { title: "البريد  ", field: "email" },
    { title: " رقم الهاتف ", field: "tel" },
    { title: " رقم الجوال ", field: "mobile" },
    { title: "ملاحضات", field: "notes" },
  ];

  function onRowClickHandle(data1) {
    onShow(data1, "edit");
  }
  return (
    <Grid item container lg={12} md={12} className={[clsConts.rootdown]}>
      <Grid
        item
        container
        xs={12}
        className={[clsConts.subDown, clsConts.subDownC]}
      >
        <Grid item xs={12}>
          <MaterialTable
            style={{ boxShadow: "none" }}
            title=""
            // variant="outlined"
            options={{
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
              paging: false,

              headerStyle: headerB,
              cellStyle: cellS,
              maxBodyHeight: "65vh",
              minBodyHeight: "65vh",
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h3
                    style={{
                      marginTop: "20vh",
                      border: "solid 0px",
                      boxShadow: "none",
                    }}
                  ></h3>
                ),
              },
            }}
            columns={columns}
            data={data}
            onRowClick={(event, rowData) => {
              onRowClickHandle(rowData);
              event.preventDefault();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SalesPersonTable;
