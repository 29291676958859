import React from "react";
import Typography from "@material-ui/core/Typography";
import Swipeable from "../../../../Fronts/Main/Swipeable";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import CheckboxItem, { useStylesAcc } from "../CheckBoxComponent";

export default function BasicPermissions(props) {
  const { onChange, object, update, sub } = props;
  const formData = [
    { name: "HidePurchPrice", label: "إخفاء سعر الشراء" },
    { name: "NoRepeatItem", label: "عدم تكرار اسم الصنف" },
    {
      name: "show_ItemExpired_Alert",
      label: "عدم عرض التنبيهات للاصناف عند الدخول",
    },
    {
      name: "update_price_first_stock",
      label: "تحديث سعر الشراء والبيع عند ادخال رصيد اول المدة",
    },
    {
      name: "update_price_pursh_bill",
      label: "تحديث سعر الشراء والبيع عند ادخال فاتورة المشتريات",
    },
    {
      name: "update_price_currencies",
      label: "تحديث سعر البيع فقط عند ادخال فاتورة المبيعات ",
    },
    {
      name: "is_saleprice_less_purch",
      label: "عدم السماح بتسجيل سعر البيع اقل من سعر الشراء ",
    },
    {
      name: "show_unit_on_bills",
      label: "اظهار الوحدات الفرعية للصنف في الفواتير",
    },
    { name: "costWithVat", label: "السعر يشمل الضريبة" },
    {
      name: "show_price_on_bills",
      label: "اظهار الاسعار المختلفة للصنف في الفواتير",
    },
    { name: "usePriceBeforVat", label: "استخدام السعر قبل الضريبة" },
    { name: "hide_item_balance", label: "اخفاء رصيد الاصناف" },
    {
      name: "disable_type_sale_price",
      label: "تعطيل التحكم في السعر (تجزئه, موزع, جملة)",
    },
  ];
  const classes = useStylesAcc();

  return (
    <div className={classes.root}>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ background: "#d9dcf078" }}
        >
          <Typography className={classes.heading}>
            إعدادات البيانات الاساسية
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.subTitle}>
          {" "}
          <Typography> الاعدادات الاساسية</Typography>{" "}
        </AccordionDetails>

        <AccordionDetails>
          <Grid item container spacing={1}>
            {formData?.map((item) => {
              // console.log(object);
              return (
                <Grid xs={6}>
                  <CheckboxItem
                    value={object[item.name] ?? null}
                    name={item.name}
                    label={item.label}
                    onChange={onChange}
                  />
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>

        <Divider className={classes.divider} />

        <AccordionDetails className={classes.subTitle}>
          {" "}
          <Typography>
            {" "}
            <Button
              variant="contained"
              color="secondary"
              size="large"
              disabled={props.submitting}
              onClick={update}
            >
              {"حفظ التعديلات"}
            </Button>
          </Typography>{" "}
        </AccordionDetails>
      </Accordion>
      <Swipeable />
    </div>
  );
}
