import React, { useState, useEffect } from "react";

import ReceiptBody from "./ReceiptBody";
import GeneralBody from "./GeneralBody";

const Body = () => {
  const locinf = JSON.parse(localStorage.getItem("iInfos")) || {};
  useEffect(() => {
    console.log("loc is", locinf);
  });
  if (locinf?.temp == 1) return <ReceiptBody />;
  return <GeneralBody />;
};

export default Body;
