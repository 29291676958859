import React from "react";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import { uri } from "../../../help/Api";
import useFormDataCRUD from "../../../components/Hooks/useEDSCURD";
import OmlatAPI from '../../../api/Omlat';
import Omlat from "./omlat";
import useFetchObjectByPost from "../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import { insh } from "../../../Context/InshData";
import BackdropScI from "../../../services/BackdropScI";

export default function OmlatCon(){

    const tb={wait:true, omlat:true,Countries:true,stateOmlat:true, equalOmlat:true, parms:{tree:"sub",},
                 autos:{Countries:true,}
    }
    const url = uri+'Res';


    const {object:data, fetching: fetchinData, fetch:fetchURL} =  useFetchObjectByPost(url, tb);
        useEffect(()=>{
        console.log('omlat is', data )
    })
    const {
        onChange,
        submit,
        update,
        remove,
        submitting, 
        errorMessage,
        onShow,
        onEnd,
        object,
        formType,
        
    } = useFormDataCRUD(
        OmlatAPI.create,
        OmlatAPI.update,
        OmlatAPI.remove,
        undefined,
        insh.omlat,
        null,
        fetchURL,
    );

      if(fetchinData || !data?.auto )
        return <BackdropScI />
    return (
        <Omlat
        {...{
            data,
            fetchinData,
            onChange,
            submit,
            update,
            remove,
            submitting, 
            errorMessage,
            onShow,
            onEnd,
            object,
            formType,
        }}/>
    )
}

   