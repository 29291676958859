import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles, styled } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { selectTypes } from '../../../../services/accMasn-services';
import { useState } from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    chip:{
    paddingLeft:'10px',
    paddingRight: '10px',
    marginLeft: '10px'
  },
}));

const ListItem = styled('li')(({theme}) =>({
  margin: theme.spacing(0.5),
}));

const SelectTypesFilter = (props)=>{
  const { rptParams, setRptParams}  = props;
  const classes = useStyles();
  const {t} = useTranslation(['forms'])

    return (
            <Container
                style={{
                  display:'flex',
                  justifyContent:'center',
                  flexWrap:'wrap',
                  listStyle:'none',
                  padding:'10px',
                  margin:'0px',
                }}
                component="ul" >
                  
              {
                selectTypes.map(x=>{

                  return <ListItem key={x.id}>
                    {
                      x.id ===rptParams.type.id ?
                      <Chip 
                        className={classes.chip} 
                        label={t(x.label)} 
                        icon={<Done />}
                        style={{backgroundColor: '#808080',
                        color:'#fff'}}
                        clickable
                      />:
                      <Chip 
                        className={classes.chip} 
                        label={t(x.label)} 
                        variant='outlined'
                        color={'#000'}
                        onClick={()=>{setRptParams({...rptParams, 
                          type: x})}}
                      />
                    }
                  </ListItem>
                })}
            </Container>
           
    )
}

export default SelectTypesFilter;