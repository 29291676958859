import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { useEffect } from 'react';
import MostSoledChart from './MostSoled-chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    width: '100%',
    height: 200,
    position: 'relative',

    
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const MostSoled = props => {
  const { className, data, fetching, ...rest} = props;


  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardContent>
        <Typography style={{paddingBottom:'5px'}} component="div" variant="h6">
             أكثر المنتجات مبيعا
          </Typography>
        <Divider />
        <div className={classes.chartContainer}>
          {
            (!data || fetching)?
              <CircularProgress />
              :
              <MostSoledChart {...{
                data: data.length>0 ? data: [{}]
              }} />
          }
          
        </div>
      </CardContent>
      <Divider />
    </Card>
  );
};

MostSoled.propTypes = {
  className: PropTypes.string
};

export default MostSoled;
