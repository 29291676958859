import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// import { DroidKufiRegular } from "../../../../=common/vfs_fonts";
import axios from "axios";
import pdfExport from "../../../../../components/invoice-generator/pdfExport";
import { Button, ButtonGroup } from "@material-ui/core";
import { uri } from "../../../../../help/Api";
import { GetA4Setings } from "../../../../../services/settings-services";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// window.pdfMake.vfs["DroidKufi-Regular.ttf"] = DroidKufiRegular;

const InvoiceGenerator = () => {
  const location = useLocation();
  const uuidGen = () =>
    Math.random().toString(36).substring(2) + Date.now().toString(36);

  const randomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min) + min);

  const [defaultSettings] = useState(
    localStorage.getItem("defaultSettings")
      ? JSON.parse(localStorage.getItem("defaultSettings"))
      : {
          imgSrc: "",
          defaultCurrency: "USD",
          taxation: "GST",
          taxationPer: 0,
          discounts: 0,
          shipping: 0,
          invoiceNo: "Invoice No:",
          invoiceFrom: "",
          terms: "",
          footNote: "",
          defaultTemplate: "temp3",
        }
  );

  const blankItem = {
    title: "",
    quantity: 1,
    rate: 1,
    taxationPer: defaultSettings?.taxationPer ?? 0,
    amount: 0,
  };

  const [invoices, setInvoices] = useState(
    localStorage.getItem("invoices")
      ? JSON.parse(localStorage.getItem("invoices"))
      : []
  );

  const [found, setFound] = useState({});
  const [invoice, setInvoice] = useState({
    imgSrc: defaultSettings?.imgSrc ?? "",
    uuid: uuidGen(),
    title: defaultSettings?.invoiceNo ?? "Invoice No:",
    invoiceNo: randomNumber(1, 999999),
    invoiceDate: new Date().toLocaleDateString("en-CA"),
    dueDate:
      defaultSettings?.autoDueDate === true && defaultSettings?.autoDueDays > 0
        ? new Date(
            new Date().setDate(
              new Date().getDate() + defaultSettings?.autoDueDays
            )
          ).toLocaleDateString("en-CA")
        : "",
    invoiceFrom: defaultSettings?.invoiceFrom ?? "",
    invoiceTo: "",
    items: [blankItem],
    discounts: defaultSettings?.discounts ?? 0,
    shipping: defaultSettings?.shipping ?? 0,
    terms: defaultSettings?.terms ?? "",
    footNote: defaultSettings?.footNote ?? "",
    currency: defaultSettings?.defaultCurrency ?? "USD",
    taxation: defaultSettings?.taxation ?? "GST",
  });

  const getSubtotal = (items) =>
    items
      .reduce((sum, item) => Number(sum) + Number(item.amount), 0)
      .toFixed(2);

  const getTotal = (items, discounts, shipping) =>
    (
      getSubtotal(items) -
      Number(discounts || 0) +
      Number(shipping || 0)
    ).toFixed(2);

  const getAmount = (item) => {
    const { quantity, rate, taxationPer } = item;
    let totalAmount = quantity * rate;
    let taxAmount = totalAmount * (taxationPer ?? 0 / 100);
    let grossAmount = (Number(totalAmount) + Number(taxAmount)).toFixed(2);
    item.amount = grossAmount;
    return grossAmount;
  };

  const handleHrefClick = (e, uuid) => {
    e.stopPropagation();
    e.preventDefault();
    const target = e.target;
    if (
      (target === null || target === void 0 ? void 0 : target.tagName) === "A"
    ) {
      setInvoice(invoices.find((s, i) => s.uuid === uuid));
    }
    return true;
  };

  const handleUserInput = (e) => {
    setInvoice((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChange = (i) => (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInvoice((prev) => ({
      ...prev,
      items: prev.items.map((item, index) =>
        i === index ? { ...item, [name]: value } : { ...item }
      ),
    }));
  };

  const addItem = () =>
    setInvoice((prev) => ({ ...prev, items: [...prev.items, blankItem] }));

  const handleRemoveItem = (idx) => () => {
    setInvoice((prev) => ({
      ...prev,
      items: prev.items.filter((s, i) => idx !== i),
    }));
  };

  const handleRemoveInvoice = (idx) => {
    if (window.confirm("Are you sure you wish to delete this invoice?")) {
      setInvoices((item) =>
        item.filter((s, i) => {
          // 👇️ remove object that has index equal to idx
          return idx !== i;
        })
      );
    }
  };

  const handleRemoveImage = () => {
    setInvoice((prev) => ({
      ...prev,
      imgSrc: "",
    }));
  };

  const handleNewInvoice = () => {
    setInvoice((prev) => ({
      ...prev,
      imgSrc: defaultSettings?.imgSrc ?? "",
      uuid: uuidGen(),
      title: defaultSettings?.invoiceNo ?? "Invoice No:",
      invoiceNo: randomNumber(1, 999999),
      invoiceDate: new Date().toLocaleDateString("en-CA"),
      dueDate:
        defaultSettings?.autoDueDate === true &&
        defaultSettings?.autoDueDays > 0
          ? new Date(
              new Date().setDate(
                new Date().getDate() + defaultSettings?.autoDueDays
              )
            ).toLocaleDateString("en-CA")
          : "",
      invoiceFrom: defaultSettings?.invoiceFrom ?? "",
      invoiceTo: "",
      items: [blankItem],
      discounts: defaultSettings?.discounts ?? 0,
      shipping: defaultSettings?.shipping ?? 0,
      terms: defaultSettings?.terms ?? "",
      footNote: defaultSettings?.footNote ?? "",
      currency: defaultSettings?.defaultCurrency ?? "",
      taxation: defaultSettings?.taxation ?? "GST",
    }));
  };

  const saveInvoice = () => {
    let currentUuid = invoice.uuid;
    let findObject = invoices.find((s, i) => s.uuid === currentUuid);
    if (findObject) {
      setInvoices(
        invoices.map((item) =>
          item.uuid === currentUuid
            ? {
                ...item,
                imgSrc: invoice.imgSrc,
                title: invoice.title,
                invoiceNo: invoice.invoiceNo,
                invoiceDate: invoice.invoiceDate,
                dueDate: invoice.dueDate,
                invoiceFrom: invoice.invoiceFrom,
                invoiceTo: invoice.invoiceTo,
                items: invoice.items,
                discounts: invoice.discounts,
                shipping: invoice.shipping,
                terms: invoice.terms,
                footNote: invoice.footNote,
                currency: invoice.currency,
                taxation: invoice.taxation,
              }
            : { ...item }
        )
      );
    } else {
      setInvoices((item) => [invoice, ...item]);
    }
  };

  const onImageChange = (e) => {
    // this / e.target is current target element.
    // stops the browser from redirecting.
    e.stopPropagation();
    e.preventDefault();
    var files = e.target.files;
    var fileCount = files.length;
    var i;
    if (fileCount > 0) {
      for (i = 0; i < fileCount; i = i + 1) {
        var file = files[i];
        // var name = file.name;
        // var size = this.bytesToSize(file.size);
        var reader = new FileReader();
        reader.onload = function (e) {
          setInvoice((prev) => ({
            ...prev,
            imgSrc: e.target.result,
          }));
        };
        reader.readAsDataURL(file);
      }
    }
    return false;
  };

  const downloadInvoice = (obj) => {
    pdfExport.downloadInvoice(obj);
  };

  const getHeaderInfo = async (nextStep) => {
    var data, inv;

    const tb = {
      GetFoundation: true,
      Rpt_Inv: true,
      wait: true,
      parms: {
        lprocid: 29579,
        // lprocid: 18,
        // ibranchid: 8,
        ibranchid: 1,
        iFoundid: 1,
        sProcTypeInvProc: "02",
      },
      autos: {},
    };

    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        console.log("the data is", res.data);
        setFound(res.data.Found);
        data = res.data;
      })
      .catch((error) => {
        console.log("erooooorroro");
      });

    await axios
      .get(uri + `InvForFatora?proc_id=${29579}`)
      .then(async (res) => {
        inv = res.data;
      })
      .catch((error) => {});

    nextStep(data, inv);
  };

  const openInvoice = async (obj) => {
    await getHeaderInfo((data, inv) => {
      const { columns, headerSettings } = GetA4Setings();
      let newObj = {
        ...obj,
        found: data?.Found,
        imgSrc: `data:image/jpeg;base64,${data?.Found?.Logo}`,
        footNote: data?.Found?.BillNotes,
        items: data?.RptInv,
        tot: data?.infoBill,
        terms: inv?.notes,
        locInv: { invN: "فاتورة مبيعات" },
        premiApp: { hidePayType: false },
        inv: inv,
        columns: columns,
        headerSettings: headerSettings,
      };
      console.log("the new data is", newObj);
      pdfExport.openInvoice(newObj);
    });
  };

  const printInvoice = async (obj) => {
    await getHeaderInfo((data, inv) => {
      const { columns, headerSettings } = GetA4Setings();
      let newObj = {
        ...obj,
        found: data?.Found,
        imgSrc: `data:image/jpeg;base64,${data?.Found?.Logo}`,
        footNote: data?.Found?.BillNotes,
        items: data?.RptInv,
        tot: data?.infoBill,
        terms: inv?.notes,
        locInv: { invN: "فاتورة مبيعات" },
        premiApp: { hidePayType: false },
        inv: inv,
        columns: columns,
        headerSettings: headerSettings,
      };
      console.log("the new data is", newObj);
      pdfExport.printInvoice(newObj);
    });
    // pdfExport.printInvoice(obj);
  };

  useEffect(() => {
    if (location?.state?.uuid) {
      setInvoice(invoices.find((s, i) => s.uuid === location?.state?.uuid));
    }
  }, [location?.state?.uuid, invoices]);
  // useEffect(() => {
  //   const lt = async () => {
  //     const setting = JSON.parse(localStorage.getItem("billSTGs"));
  //     console.log("the settings is", setting);
  //   };
  //   lt();
  // }, []);
  useEffect(() => {
    localStorage.setItem("invoices", JSON.stringify(invoices));
  }, [invoices]);

  const {
    imgSrc,
    uuid,
    title,
    invoiceNo,
    invoiceDate,
    dueDate,
    invoiceFrom,
    invoiceTo,
    items,
    discounts,
    shipping,
    terms,
    footNote,
    currency,
    taxation,
  } = invoice;
  return (
    <Fragment>
      <ButtonGroup>
        <Button
          variant="contained"
          color="primary"
          size="large"
          // style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
          disabled={true}
          // startIcon={icondiv}
        >
          {" "}
          {"fdgdfg"}
        </Button>
        <Button onClick={() => downloadInvoice(invoice)}> download</Button>
        <Button onClick={() => openInvoice(invoice)}> open </Button>
        <Button onClick={() => printInvoice(invoice)}> print</Button>
      </ButtonGroup>
    </Fragment>
  );
};

export default InvoiceGenerator;
