import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { useTranslation } from "react-i18next";
import {
  useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
  , useStylesUiElemment,
  useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
const types = [
  { id: 0, name: "حسب سعر شراء الصنف" },
  { id: 1, name: "حسب مخزون اول المدة والمشتريات" },
];

export default function ProfitMarginForm(props) {
  const [type, setType] = useState(types[0]);
  const { t } = useTranslation(["forms"]);

  const clasup = useStylesUp();
  const calsPurch = useStylesAutoPurch();
  const claslbl = useStylesLbl();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();

  const {
    rptParams,
    onChangeData,
    AutoOptions,
    CustomerOptions,
    onChangeAutoData,
    printHandle,
    isPurches,
  } = props;

  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <TextField fullWidth variant="outlined" size="small" id="dateFrom" label="من تاريخ"
            className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
            type="date" format="YYYY-MM-DD"
            name="dateFrom" value={rptParams.dateFrom} onChange={onChangeData('dateFrom')}
          /></Grid>
        {rptParams.selectTime.id == 2 && (
          <Grid item lg={2} md={2} sm={3} xs={6}>
            <TextField fullWidth variant="outlined" size="small" id="timeFrom" label="الوقت "
              className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
              type="time"
              format="HH:mm"
              name="timeFrom" value={rptParams.timeFrom} onChange={onChangeData('timeFrom')}
            /></Grid>
        )}
        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <TextField fullWidth variant="outlined" size="small" id="dateTo" label="إلى تاريخ"
            className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
            type="date" format="YYYY-MM-DD"
            name="dateTo" value={rptParams.dateTo} onChange={onChangeData('dateTo')}
          /></Grid>

        {rptParams.selectTime.id == 2 && (
          <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
            <TextField fullWidth variant="outlined" size="small" id="timeTo" label="الوقت "
              className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
              type="time"
              format="HH:mm"
              name="timeTo" value={rptParams.timeTo} onChange={onChangeData('timeTo')}
            /></Grid>
        )}
        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete classes={isPurches ? calsPurch : clasAo}
            value={rptParams.safe}
            labelPlacement=""
            onChange={(event, newValue) => { onChangeAutoData('safe', newValue) }}
            id="controllable-states-demo" size="small"
            options={AutoOptions.Safes}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            // style={{  }}
            disableClearable
            renderInput={(params) => <TextField {...params} label="المخزن" variant="outlined" size="small"
              InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }} />}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete classes={isPurches ? calsPurch : clasAo}
            value={rptParams.cust}
            labelPlacement=""
            onChange={(event, newValue) => {
              onChangeAutoData("cust", newValue);
            }}
            id="controllable-states-demo" size="small"
            options={CustomerOptions}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            // style={{  }}
            disableClearable
            renderInput={(params) => <TextField {...params}
              label={t("customer")}
              variant="outlined" size="small"
              InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }} />}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete classes={clasAo}
            value={rptParams.stock}
            onChange={(event, newValue) => {
              onChangeAutoData("stock", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Stocks}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("stock")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete classes={clasAo}
            value={type}
            onChange={(event, newValue) => {
              setType(newValue);
              onChangeAutoData("type_cost", newValue?.id);
            }}
            id="combo-box-demo"
            size="small"
            options={types}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("calc-average")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete classes={clasAo}
            value={rptParams.group}
            onChange={(event, newValue) => {
              onChangeAutoData("group", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.groups}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("group")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete classes={clasAo}
            value={rptParams.user}
            onChange={(event, newValue) => {
              onChangeAutoData("user", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Users}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("user")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete classes={clasAo}
            value={rptParams.branch}
            onChange={(event, newValue) => {
              //   setBranch(newValue);
              onChangeAutoData("branch", newValue);

            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Branches}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("branch")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
