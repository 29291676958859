import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ReplyIcon from "@material-ui/icons/Reply";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import CssBaseline from "@material-ui/core/CssBaseline";
import MaterialTable from "material-table";
import { Mtb } from "../../../../../St/comps/vstyle";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import CircularProgress from "@material-ui/core/CircularProgress";
import { sto } from "../../../../../Context/InshData";
import { useState } from "react";
import moment from "moment";
import { colors, LinearProgress, TextField } from "@material-ui/core";
import {
  useStylesHed,
  useStylesBA,
  useStylesUp,
  useStylesTD,
  useStylesTF,
} from "../../../../../St/comps/UseStyle";
import { cellS, headerS, stElemnt } from "../../../../../St/comps/cStyle";
import { Search } from "@material-ui/icons";
import { useMemo } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function ReceiptSearchModal(props) {
  const { open, onClose, isInternal, onRowClick, URL } = props;
  const [id, setId] = useState();

  // const [dateFrom, setDateFrom] = useState(() => {
  //   let today = new Date();
  //   return new moment(today.setDate(today.getDate()))
  //     .add(-6, "month")
  //     .format("YYYY-MM-DD");
  // });
  const [dateFrom, setDateFrom] = useState(() => {
    let today = new Date();
    return new moment(today.setDate(today.getDate() )).format("YYYY-MM-DD");
  });

  // const [dateFrom, setDateFrom] = useState(() => {
  //   let today = new Date();
  //   return new moment(today.setDate(today.getDate()))
  //     .add(-6, "month")
  //     .format("YYYY-MM-DD");
  // });
  const [dateTo, setDateTo] = useState(() => {
    let today = new Date();
    return new moment(today.setDate(today.getDate() + 1)).format("YYYY-MM-DD");
  });

  const {
    data: rows,
    fetching,
    fetchURL,
  } = useFetchURL({
    ...{
      url:
        URL +
        `?branch=${sto.branchi}
        &&id=${id}
        &&dateFrom=${dateFrom}
        &&dateTo=${dateTo}`,
      // dependencyArray:[dateTo, dateFrom]
    },
  });

  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const accName = isInternal ? "acc_name" : "custName";
  const { t } = useTranslation(["forms"]);

  var columns = [
    { title: t("no"), field: "id", headerStyle: headerS, cellStyle: cellS },
    { title: t("date"), field: "date", headerStyle: headerS, cellStyle: cellS },
    {
      title: t("amount"),
      field: "val",
      headerStyle: headerS,
      cellStyle: cellS,
    },
    {
      title: t("delegate"),
      field: "empName",
      headerStyle: headerS,
      cellStyle: cellS,
    },
    {
      title: t("ref-no"),
      field: "check_no",
      headerStyle: headerS,
      cellStyle: cellS,
    },
    {
      title: t("account"),
      field: accName,
      headerStyle: headerS,
      cellStyle: cellS,
    },
    {
      title: t("note"),
      field: "notes",
      headerStyle: headerS,
      cellStyle: cellS,
    },
   
  ];

  const [searchTxt, setSearchTxt] = useState("");

  const filteredRows = useMemo(() => {
    if (!searchTxt) return rows;
    if (rows.length > 0) {
      let list = [];
      list = rows?.filter((obj) =>
        JSON.stringify(obj).toLowerCase().includes(searchTxt.toLowerCase())
      );
      console.log(list);
      return list;
    }
    return [];
  }, [searchTxt, rows]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      {
        <>
          <div className={clasBA.continarBASer}>
            <React.Fragment>
              <Grid item container spacing={0} className={clashd.rootHd}>
                <Grid item xs={12}>
                  <Chip
                    size="small"
                    className={clashd.ChipHdSale}
                    label={"بـحـث فـي السندات"}
                    color="primary"
                  />
                </Grid>
              </Grid>

              <CssBaseline />
              <Container maxWidth="xl" className={clasup.AboverootupSer}>
                {/* S front H */}
                <Grid
                  item
                  container
                  spacing={1}
                  className={[clasup.rootup, clasup.rootupSer]}
                >
                  <Grid item md={2} sm={3} xs={2}>
                    <TextField
                      type="number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="id"
                      label={t("voucher-id")}
                      className={clasTF.rootEDS}
                      style={{
                        backgroundColor: colors.grey[50],
                        marginBlock: 10,
                      }}
                      InputProps={{ className: clasTF.input }}
                      name="id"
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                    />
                  </Grid>

                  <Grid item lg={2} md={2} sm={3} xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="medium"
                      id="dateinvsf"
                      label={t("date-from")}
                      className={clasTF.root}
                      InputProps={{ className: clasTD.input }}
                      type="date"
                      format="YYYY-MM-DD"
                      InputLabelProps={{ shrink: true }}
                      name="dateFrom"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                  </Grid>

                  <Grid item lg={2} md={2} sm={3} xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="medium"
                      id="dateinvsf"
                      label={t("date-to")}
                      className={clasTF.root}
                      InputProps={{ className: clasTD.input }}
                      type="date"
                      format="YYYY-MM-DD"
                      InputLabelProps={{ shrink: true }}
                      name="dateinvsf"
                      value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                    />
                  </Grid>

                  <Grid item lg={2} md={2} sm={2} xs={2}>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      onClick={() => {
                        fetchURL();
                      }}
                    >
                      {t("search")}
                      <Search style={{ paddingInline: 2 }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item container className={clasup.rootup}>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="val"
                      label={t("search")}
                      className={clasTF.rootEDS}
                      style={{
                        backgroundColor: colors.grey[50],
                        marginBlock: 10,
                      }}
                      InputProps={{ className: clasTF.input }}
                      name="val"
                      value={searchTxt}
                      onChange={(e) => setSearchTxt(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    {fetching ? (
                      <LinearProgress />
                    ) : (
                      <MaterialTable
                        style={{ boxShadow: "none" }}
                        //style={{direction:'ltr'}}
                        options={{
                          showTitle: false,
                          toolbar: false,
                          sorting: false,
                          selection: false,
                          // ToolBar Search
                          searchFieldAlignment: "left",
                          searchFieldVariant: "outlined",
                          // ToolBar header
                          showSelectAllCheckbox: false,
                          showTextRowsSelected: false,
                          pageSize: 5,
                          paginationType: "stepped",
                          showFirstLastPageButtons: false,

                          paging: false,

                          maxBodyHeight: Mtb.minmaxBHInvs,
                          minBodyHeight: Mtb.minmaxBHInvs,
                        }}
                        localization={{
                          body: {
                            emptyDataSourceMessage: (
                              <h3 style={{ color: "#000" }}>لا يوجد بعد</h3>
                            ),
                          },
                          toolbar: { searchPlaceholder: "بـحـث" },
                        }}
                        columns={columns}
                        data={filteredRows}
                        onRowClick={async (event, rowData) => {
                          onRowClick(rowData);
                          event.stopPropagation();
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <br /> <br />
              </Container>
            </React.Fragment>
          </div>

          {/* ====================================== end ======================================== */}
          <React.Fragment>
            <CssBaseline />
            <AppBar
              position="fixed"
              color="primary"
              // className={clasBA.appBarBASal}
              style={{ ...stElemnt.all.appBarBA, backgroundColor: "#808080" }}
            >
              <Toolbar variant="dense">
                <Hidden smUp={["sm"]}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onClose}
                  >
                    <ReplyIcon />
                  </IconButton>
                </Hidden>

                <Hidden only={["xs"]}>
                  <Button
                    style={{ color: "#fff" }}
                    onClick={onClose}
                    startIcon={<ReplyIcon />}
                  >
                    {t("back")}
                  </Button>
                </Hidden>

                <div className={clasBA.growBA} />
              </Toolbar>
            </AppBar>
          </React.Fragment>
        </>
      }
    </Dialog>
  );
}
