import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

import Container from "@material-ui/core/Container";
import {
  useStylesContainers,
  useStylesHed,
  useStylesSetting,
} from "../../../St/comps/UseStyle";

import { Toaster } from "react-hot-toast";
import basicDataAPI from "../../../api/basicData";
import useFormDataCRUD from "../../../components/Hooks/useEDSCURD";

import Swipeable from "../../../Fronts/Main/Swipeable";
import { Button } from "@material-ui/core";
import axios from "axios";
import { uri } from "../../../help/Api";
import ElectronicScaleForm from "./electronic-scale-form";

const URL = uri + "ElectronicScale";

function updateDefaults(data) {
  return axios.put(URL, data);
}

function getDfault(collection) {
  const index = collection?.findIndex((c) => c.IS_Default);
  if (index >= 0) {
    return collection[index];
  } else if (index == -1) {
    return collection[0];
  }
  return null;
}
export default function ElectronicScale(props) {
  const { data, fetchURL } = props;
  const classesStt = useStylesSetting();

  const defaultValues = {
    ...data,
    // branch: getDfault(data?.branches),
    // safe: getDfault(data?.safes),
    // stock: getDfault(data?.stocks),
    // unit: getDfault(data?.units),
    // taxGroup: getDfault(data?.taxGroups),
    // omla: getDfault(data?.omlat),
  };

  const { onChange, submit, update, object } = useFormDataCRUD(
    null,
    updateDefaults,
    undefined,
    undefined,
    defaultValues,
    null,
    fetchURL
  );

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();

  const styheader = {
    textAlign: "center",
    backgroundColor: "#fff",
    height: "100vh",
    direction: "rtl",
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Container maxWidth="sm">
        <Toaster />

        <Typography component="div" style={styheader}>
          <Grid item container>
            <Grid item xs={12}>
              <Chip
                size="small"
                className={clashd.ChipHdSale}
                label={"أعدادات الميزان الالكتروني"}
                color="primary"
              />
            </Grid>
            <Grid container xs={12}>
              <Grid
                item
                container
                lg={12}
                md={12}
                sm={12}
                sx={12}
                className={[clsConts.rootup]}
              >
                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  className={[clsConts.subUp, clsConts.subUpC]}
                >
                  <ElectronicScaleForm
                    {...{
                      data,
                      object,
                      updateItems: submit,
                      onChange,
                    }}
                  />
                  <Grid item container className={classesStt.rootSett}>
                    <Button
                      variant="contained"
                      className={classesStt.btn}
                      color="primary"
                      fontSize="large"
                      onClick={() => {
                        update(object);
                      }}
                    >
                      {"حــفـظ الـتـعـديـلات"}
                    </Button>
                    <Swipeable />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      </Container>
    </React.Fragment>
  );
}
