import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { useLocation } from "react-router-dom";
import LoadingPage from "../../../../../services/LoadingPage";
import ListItems from "./ListItems";
import { get } from "@awesome-cordova-plugins/core/decorators/common";

const defaultAutos = {
    Branches: [],
};

export default function ListItemsCon(props) {
    const { loadItems, getItemsData, AutoOptions } = props;
  return (
    <ListItems
      {...{
       loadItems,
        getItemsData,
        AutoOptions,
      }}
    />
  );
}
