import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { Checkbox, colors, Divider, FormControlLabel, FormGroup, Fab, CircularProgress } from "@material-ui/core";
import {
    useStylesTF,
    useStylesTD,
    useStylesContainers,
    useStylesHed,
    useStylesLbl,
    useStylesDPM,
    SaleCheckbox,
} from "../../../../../St/comps/UseStyle";
import { payTypes } from "../../../../../services/global-services";
import { useTranslation } from "react-i18next";
import { Directions } from "@material-ui/icons";
import { useStylesDTa } from "../../../../../St/comps/UseStyle";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from '@material-ui/core'
import SaveIcon from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";

import { useStylesBA, useStylesCAB } from "../../../../../St/comps/UseStyle";

const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '6rem',
        }
    }
}));

export default function PaidWindowForm(props) {
      const { 
        idata, 
        remains,
        setRemains,
        invDisc, 
        payment, 
        changPayment, buttonClassnameSavePMD, toDB, restPM, loading,
        handClosePMD,
        style,
        gets,
        changClick,
        changinvDisc,
        openPMD,
        valueEmployePMD,
        uiElement,
        changChcked,
        transition,
        checked,
        clasAo,
        valueBankPMD,
        changAutoBankPMD,
        changIdata,
        changAutoEmployee,
        valueBankTransPMD,
        changAutoBank2 ,
        typePress,valueType} = props;
      const clasPM = useStylesDPM();
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    const clsConts = useStylesContainers();
    const claslbl = useStylesLbl();
    const { t } = useTranslation(["forms", "translation"]);
    const clasTa = useStylesDTa();
    //   const onChangeData = (prop) => (event) => {
    //     onChange(event, { name: prop, value: event.target.value });
    //   };
    
    const changeRemains=(key, value)=>{
      setRemains({...remains, [key]: value})


    }
    const onsave=(value)=>{
      // setRemains({...remains, networkId: remains?.isNetwork? valueBankPMD?.id:0,
      //    empId:remains?.isEmp? valueEmployePMD?.id: 0});
         remains.empId= remains?.isEmp? valueEmployePMD?.id: 0;
         remains.networkId =remains?.isNetwork? valueBankPMD?.id: 0;
         remains.bankId =remains?.isNetwork? valueBankPMD?.id: 0;

      if (remains.typePress=="save"){
        toDB("save",value);
      }else if (remains.typePress=="print"){
        toDB("print",value);
      }
    };

    const clasBA = useStylesBA();
    const clasCAB = useStylesCAB();
    const styBtn = {
        dis: { color: "#3b773d", background: "#4fa152" },
        undis: { color: "#fff" },
    };
    const [valueGroups, setValueGroups] = useState();
    const classes = useStyles()

    return (
        <div >
        <Dialog
          fullScreen
          className={clasPM.rootPM}
          // maxWidth={"lg"}
          // maxHeight={"lg"}
          open={openPMD}
          // xs={{'& .MuiDialog-paper':{width: '80%', maxHeight:500}}}
         lg={{direction:"rtl", minHeight:600}}
        //   open={true}
          onClose={handClosePMD}
          aria-labelledby="form-dialog-title"
          TransitionComponent={transition}
          key={transition ? transition.name : ""}
        >
          <DialogTitle id="form-dialog-title" className={claslbl.clor}>
            شاشة الدفع
          </DialogTitle>
          <DialogContent className={clasPM.dialogContentPM}>
            <Grid item container style={{direction:"ltr"}}>
              <Grid
                item
                container
                lg={7}
                md={7}
                sm={12}
                xs={12}
                // className={clasPM.gridLeftPM}
                style={{direction:"ltr"}}
              >
                <Grid item container className={clasPM.innerContinerPM}>
                  <Grid item lg={8} md={8} sm={12} xs={8}>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="cashPart"
                      label="المبلغ النقدي"
                      className={clasTF.root}
                      InputProps={{ className: clasTF.input,
                        readOnly: !remains.isCash,
                        
                       }}
                      InputLabelProps={{ shrink: true }}
                      name="cashPart"
                      disabled={!remains.isCash}
                      value={remains.cashPart}
                      placeholder="0"
                      onChange={changIdata("cashPart")}
                    />
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className={clasPM.checkPM}
                  >
                    <FormControlLabel
                      id="isCash"
                      value="isCash"
                      name="isCash"
                      className={claslbl.clor}
                      control={
                        <SaleCheckbox
                          checked={remains.isCash}
                          onChange={(e)=>{
                            changeRemains('isCash', e.target.checked )
                          }}
                          color="primary"
                        />
                      }
                      label="دفع نقدي"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                <Grid item container className={clasPM.innerContinerPM}>
                  <Grid
                    item
                    lg={5}
                    md={12}
                    sm={12}
                    xs={4}
                    className={clasPM.gridSpacePM}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="networkPart"
                      label="مبلغ الشبكة"
                      className={clasTF.root}
                      InputProps={{ className: clasTF.input,
                        readOnly: !remains.isNetwork,
                       }}
                      InputLabelProps={{ shrink: true }}
                      disabled={!remains.isNetwork}
                      name="networkPart"
                      value={remains.networkPart}
                      placeholder="0"
                      onChange={changIdata("networkPart")}
                    />
                  </Grid>
                  
                  <Grid item lg={4} md={5} sm={7} xs={4}>
                    <Autocomplete
                      classes={clasAo}
                      value={valueBankPMD}
                      onChange={(event, newValue) => {
                        changAutoBankPMD(newValue);
                      }}
                      id="controllable-states-dem"
                      size="small"
                      options={gets.Banks}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      style={{}}
                      disableClearable
                      disabled={!remains.isNetwork}
                      renderInput={(params) => (
                        <TextField
                          id="valueBankPMD"
                          {...params}
                          label={""}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clor }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={1}
                    sm={4}
                    xs={4}
                    className={clasPM.checkPM}
                  >
                    <FormControlLabel
                      id="isNetwork"
                      value="isNetwork"
                      name="isNetwork"
                      InputLabelProps={{ shrink: true }}
                      className={claslbl.clor}
                      control={
                        <SaleCheckbox
                        checked={remains.isNetwork}
                        onChange={(e)=>{
                          changeRemains('isNetwork', e.target.checked )
                        }}
                          color="primary"
                        />
                      }
                      label="دفع شبكة"
                      labelPlacement="start"
                    />
                  </Grid>
                  
                </Grid>

                <Grid item container className={clasPM.innerContinerPM}>
                  <Grid item lg={8} md={12} sm={12} xs={8}>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="agelPart"
                      label="مبلغ الآجل"
                      className={clasTF.root}
                      InputProps={{ className: clasTF.input ,
                        readOnly: valueType.id == 1 ? false : true
                      }}
                      disabled={!remains.isAgel}
                      InputLabelProps={{ shrink: true }}
                      name="agelPart"
                      value={remains.agelPart}
                      placeholder="0"
                      onChange={changIdata("agelPart")}
                    />
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    md={1}
                    sm={4}
                    xs={4}
                    className={clasPM.checkPM}
                  >
                    <FormControlLabel
                      id="isAgel"
                      value="isAgel"
                      name="isAgel"
                      className={claslbl.clor}
                       disabled={valueType.id== 2 ? true : false}
                      control={
                        <SaleCheckbox
                          checked={remains.isAgel}
                          onChange={(e)=>{
                            changeRemains('isAgel', e.target.checked )
                          }}
                          color="primary"
                        />
                      }
                      label="المبلغ آجل"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                {/* <Grid item container className={clasPM.innerContinerPM}>
                  <Grid item lg={3} md={12} sm={12} xs={3}>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="bankPart"
                      label="مبلغ الشيك"
                      className={clasTF.root}
                      InputProps={{ className: clasTF.input }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="0"
                      name="bankPart"
                      value={remains.bankPart}
                      onChange={changIdata("bankPart")}
                    />
                  </Grid>

                  <Grid item lg={3} md={5} sm={7} xs={4}>
                    <Autocomplete
                      classes={clasAo}
                      value={valueBankTransPMD}
                      onChange={(event, newValue) => {
                        changAutoBank2(newValue);
                      }}
                      //inputValue={inputValueBranch}
                      // onInputChange={(event, newInputValue) => { setInputValueBranch(newInputValue); }}
                      id="controllable-states-dem"
                      size="small"
                      options={gets.Banks}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      style={{}}
                      disableClearable
                      disabled={uiElement.bankPM2}
                      renderInput={(params) => (
                        <TextField
                          id="valueBankTransPMD"
                          {...params}
                          label={""}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clor }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={12} sm={12} xs={2}>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="check_refno"
                      label="المرجع"
                      className={clasTF.root}
                      InputProps={{ className: clasTF.input }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="0"
                      name="check_refno"
                      value={idata.check_refno}
                      onChange={changIdata("check_refno")}
                    />
                  </Grid>

                  <Grid
                    item
                    lg={3}
                    md={1}
                    sm={4}
                    xs={3}
                    className={clasPM.checkPM}
                  >
                    <FormControlLabel
                      id="bank_partck"
                      value="bank_partck"
                      name="bank_partck"
                      className={claslbl.clor}
                      control={
                        <SaleCheckbox
                          checked={checked.bank_partck}
                          onChange={changChcked}
                          color="primary"
                        />
                      }
                      label="تحويل بنك"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid> */}

                <Grid item container className={clasPM.innerContinerPM}>
                  <Grid item lg={3} md={12} sm={12} xs={4}>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="empPart"
                      label="المبلغ"
                      className={clasTF.root}
                      InputProps={{ className: clasTF.input }}
                      InputLabelProps={{ shrink: true }}
                      disabled={!remains.isEmp}
                      name="empPart"
                      value={remains.empPart}
                      placeholder="0"
                      onChange={changIdata("empPart")}
                    />
                  </Grid>

                  <Grid item lg={3} md={5} sm={7} xs={4}>
                    <Autocomplete
                      classes={clasAo}
                      value={valueEmployePMD}
                      onChange={(event, newValue) => {
                        changAutoEmployee(newValue);
                      }}
                      id="controllable-states-dem"
                      size="small"
                      options={gets.Employees}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      style={{}}
                      disableClearable
                      disabled={!remains.isEmp}
                      renderInput={(params) => (
                        <TextField
                          id="valueEmployePMD"
                          {...params}
                          label={""}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clor }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    lg={6}
                    md={1}
                    sm={4}
                    xs={4}
                    className={clasPM.checkPM}
                  >
                    <FormControlLabel
                      id="isEmp"
                      value="isEmp"
                      name="isEmp"
                      className={claslbl.clor}
                      control={
                        <SaleCheckbox
                          checked={remains.isEmp}
                          onChange={(e)=>{
                            changeRemains('isEmp', e.target.checked )
                          }}
                          color="primary"
                        />
                      }
                      label="على موظف"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                <Grid item container className={clasPM.innerContinerPM}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="allpart"
                      label="إجمالي المبالغ المستحقة"
                      className={clasTF.root}
                      InputProps={{ className: clasTF.input,readOnly:true }}
                     
                      name="allpart"
                     // placeholder="0"
                      value={parseFloat(remains.allpart).toFixed(2)}
                      placeholder="0"
                      InputLabelProps={{ shrink: true }}
                      //onChange={changIdata("empPart")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={5}
                md={5}
                sm={12}
                xs={12}
                className={clasPM.gridRigthPM}
              >
                <Grid item container  className={clasPM.innerContinerPMEDS}>
                  <Grid item lg={6} md={6} sm={6} xs={6} style={{marginBottom:"0.7rem"}}>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="minusPMD"
                      label="إجمالي الخصم مبلغ"
                      className={clasTF.root}
                      InputProps={{ className: clasTF.input ,readOnly:true }}
                      name="minusPMD"
                      // value={idata.minus}
                      // onClick={() => changClick("minus", false)}
                      value={invDisc.minus}
                      // onChange={changinvDisc("minus")}
                      placeholder="0"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6} style={{marginBottom:"0.7rem"}}>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="ratePMD"
                      label="إجمالي الخصم %"
                      className={clasTF.root}
                      InputProps={{ className: clasTF.input }}
                      name="ratePMD"
                      value={invDisc.rate}
                      placeholder="0"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}  style={{marginBottom:"0.7rem"}} className={clasPM.innerContinerPMEDS}>
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    id="netAmount"
                    label="الإجــمالي"
                    className={clasTF.root}
                    InputProps={{ className: clasTF.input }}
                    name="netAmount"
                    value={idata.netAmount}
                  />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}  style={{marginBottom:"0.7rem"}} className={clasPM.innerContinerPMEDS}>
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                   // id="paidupPMDcn"
                    label="المبلغ المدفوع"
                    className={clasTF.root}
                    InputProps={{ className: clasTF.input }}
                    InputLabelProps={{ shrink: true }}
                   // name="paidupPMDcn"
                   // value={payment.paidupPMDcn}
                    //placeholder="0"
                   // onChange={changPayment("paidupPMDcn")}
                  //  disabled={valueType.id== 1}
                    id="paidupPMDcn"
                    name="paidupPMDcn"
                    value={payment.paidupPMDcn}
                    placeholder="0"

                    onChange={changPayment("paidupPMDcn")}
                  />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}  style={{marginBottom:"0.7rem"}} className={clasPM.innerContinerPMEDS}>
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    //id="stayPMDcn"
                    label="البــاقي"
                    className={clasTF.root}
                    InputProps={{ className: clasTF.input }}
                    //name="stayPMDcn"
                    //value={payment.stayPMDcn}
                    id="stayPMDcn"
                    name="stayPMDcn"
                    value={payment.stayPMDcn}
                    placeholder="0"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={clasPM.dirRPM}>
            {/* <Button onClick={to DB} onMouseEnter={restPM} color="primary">
             إتمام الدفع
          </Button> */}
            <div className={clasCAB.wrapperCAB}>
              <Button
                variant="contained"
                color="primary"
                className={buttonClassnameSavePMD}
                disabled={loading.savePMD}
                //onClick={() => toDB(remains.typePress, "e")}
                onClick={() => onsave("e")}
                // onMouseEnter={() => restPM("rest")}
              >
                إتمام الدفع
              </Button>
              {loading.savePMD && (
                <CircularProgress
                  size={24}
                  className={clasCAB.buttonProgressCAB}
                />
              )}
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={() => handClosePMD("cancle")}
              // className={claslbl.bakClr}
              style={{ ...style.bakClr /*...stElemnt.all.bakClr*/ }}
            >
              إلغاء
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}
