import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ScannerIcon from "@material-ui/icons/Scanner";

import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
import { Button, colors, Container, Paper } from '@material-ui/core';
import { useTranslation } from "react-i18next";

export default function MultiCodeForm(props) {
    const { t } = useTranslation(["forms", "translation"]);

    const {
        items,
        multiCodeList,
        setMultiCodeList,
        data,
        setIsNew,
        onSubmit,
        onChangeData,
        AutoOptions,
        onChangeAutoData,
        autoo,
    } = props;

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();

    const onChangeSearch = (event) => {
      //  event.preventDefault();
        onSubmit();
    };
    return (
        <React.Fragment>

            <Grid container style={{ padding: 5 }} spacing={1}>

                <Grid item lg={6} md={6} sm={6} xs={6} >
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="id"
                        label={t("forms:id")}
                        style={{ id: colors.red[50], marginBlock: 5 }}
                        name="curr"
                        value={data.curr}
                        InputProps={{
                            readOnly: true,
                        }}
                        onChange={onChangeData("curr")} /> 
                    
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} >
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="symbol"
                        label={t("forms:item")}
                        style={{ id: colors.red[50], marginBlock: 5 }}
                        name="id"
                        value={items.symbol}
                        InputProps={{
                            readOnly: true,
                        }}
                        // onChange={onChangeData("symbol")}
                    />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} >
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="id"
                        label={t("forms:barcode")}
                        style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
                        name="barcode"
                        value={data.barcode}
                        InputProps={{
                            onKeyDown: (event) => {
                                if (event.key === 'Enter') {
                                    onSubmit(event);
                                }
                            },
                        }}
                        onChange={onChangeData("barcode")} />
                </Grid>

                {/* <Grid item lg={6} md={6} sm={6} xs={6} >
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="unitID"
                        label={t("forms:unit")}
                        style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
                        name="unit"
                        value={unitName}
                        InputProps={{
                            readOnly: true,
                        }}
                       // onChange={onChangeData("unitID")} /> 
                       />
                </Grid> */}

                
                <Grid item lg={6} md={6} sm={6} xs={6} >
                    <Autocomplete
                        value={autoo.unit}
                        labelPlacement=""
                        onChange={(event, newValue) => {
                            onChangeAutoData("unit", newValue)
                        }}
                        id="controllable-states-demo"
                        size="small"
                        style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
                        options={AutoOptions.Units}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("forms:unit")}
                                variant="outlined"
                                size="small"
                            />
                        )}
                    />
                </Grid>
            </Grid>

        </React.Fragment >
    );

}