import React from "react";
import Typography from "@material-ui/core/Typography";
import Swipeable from "../../../Fronts/Main/Swipeable";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import {
  Button,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Paper,
  Slider,
  Switch,
  TextField,
} from "@material-ui/core";

import { Alert, Autocomplete } from "@material-ui/lab";
import {
  FieldsTableNameA4Invoice,
  opacities,
} from "../../../services/settings-services";

import { SketchPicker } from "react-color";
import { Link } from "react-router-dom";

let hed = null;
let heddef = null;

export default function OraxPrint(props) {
  const { onChange, object, handleUpdate, index } = props;

  const handleChange = (prop) => (event) => {
    onChange(null, {
      name: prop,
      value: event.target.value,
    });
  };
  const handleChangeObject = (prop) => (event) => {
    onChange(null, {
      name: prop,
      value: { ...object[prop], text: event.target.value },
    });
  };
  const handleChangeObjectAuto = (prop, newValue) => {
    onChange(null, {
      name: prop,
      value: { ...object[prop], fieldName: newValue },
    });
  };
  const handleChangeVisible = (prop) => (event) => {
    onChange(null, {
      name: prop,
      value: { ...object[prop], visible: event.target.checked },
    });
  };

  return (
    <div>
      <Typography variant={"h4"}>
        خدمة الطباعة الصامتة من اوراكس (windows)
      </Typography>
      <Divider />
      <br />
      <Typography variant={"h5"}>بيانات الجهاز والطابعة</Typography>
      <Grid item container lg={12} md={12} sm={12} xs={12} spacing={4}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="deviceName"
            label="اسم الجهاز"
            // style={{ width: "200px" }}
            type="text"
            name="deviceName"
            value={object?.deviceName}
            onChange={handleChange("deviceName")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="printerName"
            label="اسم الطابعة"
            // style={{ marginTop: "20px" }}
            type="text"
            name="printerName"
            value={object?.printerName}
            onChange={handleChange("printerName")}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        {/* <Grid xs={1}></Grid> */}
        <Grid
          item
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "right" }}
        >
          <Grid item xs={12}>
            <Typography
              // size="large"

              variant={"h4"}
              style={{ marginBottom: "16px" }}
            >
              {" 📗 "}تعليمات الإستخدام
              <Divider />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              size="medium"
              variant={"h5"}
              // style={{ fontSize: "16px", margin: "5px" }}
            >
              {""}
              1- قم بادخال اسم الجهاز واسم الطابعة الافتراضية في الجهة المقابلة
              {""}
              <br />
              2- قم بتحميل خدمة الطباعة{" "}
              <Link to="/orax-print.rar" target="_blank" download>
                (تحميل)
              </Link>
              <br />
              3- قم باستخراج مجلد orax-print ووضهة في القرص (C)
              <br />
              4- قم بتشغيل السيرفس عن طريق تشغيل موجه الاوامر في ويندوز كمسؤول
              قم قم بتنفيذ الأمر التالي:
              <br />
              {"   "}
              <Alert
                icon={false}
                style={{ fontSize: "16px", textAlign: "left" }}
              >
                sc create "PrintIt"
                binPath="C:\orax-print\PrintIt.ServiceHost.exe" start=auto
              </Alert>
              <br />
              للتحقق من أن السيرفس تعمل استخدم الامر التالي
              <br />
              <Alert
                icon={false}
                style={{ fontSize: "16px", textAlign: "left" }}
              >
                sc start PrintIt
              </Alert>
            </Typography>
          </Grid>

          <Divider />
        </Grid>
      </Grid>
      <Swipeable />
    </div>
  );
}
