import React, { useState } from "react";

import { TableIcons as tableIcons } from "../../../../../Elemnts/TableIcons";
import MaterialTable from "material-table";
import { Mtb } from "../../../../../St/comps/vstyle";

import { useEffect } from "react";
import { cellS, headerS } from "../../../../../St/comps/cStyle";
import { sto } from "../../../../../Context/InshData";
import Print from "@material-ui/icons/Print";
import { PictureAsPdfOutlined, ViewAgendaOutlined } from "@material-ui/icons";
// import { PrintInvoiceDirectly } from "../../../components/printSerivce";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    tableRightBorder: {
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 800,
        fontSize: 14,

    },
    tableRowtBorder: {

        border: "solid 0.1px black",
        fontFamily: "Amiri-Regular",
        // fontWeight: 300,
        fontSize: 14,
        padding: 0,
        // height:1,
    },
    rowHeader: {
        fontFamily: "Amiri-Regular",
        fontWeight: 800,
        fontSize: 14,
    },
}));
export default function DayTable(props) {
 
    const { gets } = props;

    const classes = useStyles();


    return (

        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: "#319835",
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                        }}
                    >

                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {" الحركة"}
                        </TableCell>
                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"عدد الفواتير"}
                        </TableCell>
                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"الإجمالي"}
                        </TableCell>

                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"نقدي"}
                        </TableCell>


                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"آجل"}
                        </TableCell>
                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"شبكة"}
                        </TableCell>
                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"بنك"}
                        </TableCell>
                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"ذمم"}
                        </TableCell>
                    </TableRow>

                </TableHead>
                <TableBody   >
                    {gets.map((row, i) => (
                        <TableRow
                            style={{
                                color: "#000",
                                fontWeight: 500,
                                fontFamily: "Amiri-Regular",

                            }}
                            key={i}

                        >
                            <TableCell className={classes.tableRowtBorder} align="center" colSpan={2}
                                >
                                {row.title}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center" colSpan={2}
                               >
                                {row.counts}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center" colSpan={2}
                               >
                                {row.total?.toFixed(2) ?? "-"}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center" colSpan={2}
                                >
                                {row.total_cash?.toFixed(2) ?? "-"}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center" colSpan={2}
                                >
                                {row.total_agel?.toFixed(2) ?? "-"}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center" colSpan={2}
                                >
                                {row.total_network?.toFixed(2) ?? "-"}
                            </TableCell>

                            <TableCell className={classes.tableRowtBorder} align="center" colSpan={2}
                                >
                                {row.total_bank?.toFixed(2) ?? "-"}
                            </TableCell>

                            <TableCell className={classes.tableRowtBorder} align="center" colSpan={2}
                               >
                                {row.total_emp?.toFixed(2) ?? "-"}
                            </TableCell>

                        </TableRow>



                    ))}
                </TableBody>
            </Table>

        </TableContainer>
    );
}
