import React, { useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HomeIcon from "@material-ui/icons/Home";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import { useStylesSpDial } from "../../../St/Reviews/UseStyle";
import ShareIcon from "@material-ui/icons/Share";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import axios from "axios";
import { uri } from "../../../help/Api";
import jsPDF from "jspdf";
import { callAddFont } from "../../amiri-font";
import GridLayout from "react-grid-layout";
import RGL, { WidthProvider } from "react-grid-layout";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";

const ReactGridLayout = WidthProvider(RGL);
const originalLayout = getFromLS("layout") || [];
/**
 * This layout demonstrates how to sync to localstorage.
 */
class LocalStorageLayout extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    cols: 12,
    rowHeight: 30,
    onLayoutChange: function () {},
  };

  constructor(props) {
    super(props);

    this.state = {
      layout: JSON.parse(JSON.stringify(originalLayout)),
    };

    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.resetLayout = this.resetLayout.bind(this);
  }

  resetLayout() {
    this.setState({
      layout: [],
    });
  }

  onLayoutChange(layout) {
    /*eslint no-console: 0*/
    saveToLS("layout", layout);
    this.setState({ layout });
    this.props.onLayoutChange(layout); // updates status display
  }

  render() {
    return (
      <div>
        <button onClick={this.resetLayout}>Reset Layout</button>
        <ReactGridLayout
          {...this.props}
          layout={this.state.layout}
          onLayoutChange={this.onLayoutChange}
        >
          <div
            key="1"
            style={{ backgroundColor: "#00ff00" }}
            data-grid={{ w: 2, h: 3, x: 0, y: 0 }}
          >
            <Header />
          </div>
          <div
            style={{ backgroundColor: "#00ff00" }}
            key="2"
            data-grid={{ w: 2, h: 3, x: 2, y: 0 }}
          >
            <span className="text">
              <Body />
            </span>
          </div>
          <div
            style={{ backgroundColor: "#00ff00" }}
            key="3"
            data-grid={{ w: 2, h: 3, x: 4, y: 0 }}
          >
            <span className="text">
              <Footer />
            </span>
          </div>
          <div
            key="4"
            style={{ border: "2px" }}
            data-grid={{ w: 2, h: 3, x: 6, y: 0 }}
          >
            <span className="text">4</span>
          </div>
          <div key="5" data-grid={{ w: 2, h: 3, x: 8, y: 0 }}>
            <span className="text">5</span>
          </div>
        </ReactGridLayout>
      </div>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-7",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

class ComponentToPrint extends React.Component {
  state = {
    pwca: true,
  };

  constructor() {
    super();
  }

  render() {
    const locInv = JSON.parse(localStorage.getItem("premiApp")) || {};

    const styleCont = {
      // backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
      backgroundColor: "#fff",
    };

    const style = {
      // backgroundColor: 'green',
      width: "80mm",

      backgroundColor: "#fff",
      //backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
    };
    const stylle = {
      // backgroundColor: 'green',
      width: "58mm",
      backgroundColor: "#fff",

      //backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
    };
    const styleTyg = {
      backgroundColor: "#005000",
      color: "#000",
      padding: "0.2mm",
    };

    return (
      <Grid item container justifyContent="center" style={styleCont}>
        <Grid
          item
          style={{
            width: `${locInv.cachirWidth}mm`,
            backgroundColor: "#00ff00",
          }}
        >
          <CssBaseline />
          <Typography component="div" style={styleTyg}>
            <Header />
            <Body />
            <Footer />
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const RevCasherBuilder = () => {
  const componentRef = useRef();
  const reportDownloadRef = useRef(null);
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const clasSp = useStylesSpDial();
  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(true);
  };

  let his = useHistory();

  const back = (path) => {
    path ? his.push({ pathname: "/" }) : his.goBack();
  };

  const actions = [
    {
      icon: (
        <ReactToPrint
          trigger={() => <PrintIcon className={clasSp.ico} />}
          content={() => componentRef.current}
        />
      ),
      name: "طباعـة",
    },
    {
      icon: (
        <ArrowForwardIcon className={clasSp.ico} onClick={() => back(false)} />
      ),
      name: "رجـوع",
    },
  ];
  return (
    <div ref={reportDownloadRef}>
      <LocalStorageLayout />
      {/* <ComponentToPrint ref={componentRef} /> */}
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial}
        hidden={false}
        icon={
          <SpeedDialIcon openIcon={<HomeIcon />} onClick={() => back(true)} />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action, i) => (
          <SpeedDialAction
            key={i}
            disabled={loading}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default RevCasherBuilder;
