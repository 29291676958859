import React from "react";
import Backdrop from "@material-ui/core/Backdrop";

import { makeStyles } from "@material-ui/core/styles";
import HashLoader from "react-spinners/HashLoader";
import {
  colors,
  Container,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { FadeLoader } from "react-spinners";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
    background: "#fff",
  },
}));

export default function LoadingPage() {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        <HashLoader color="#ff0000" />
      </Backdrop>
    </div>
  );
}

export function LoadingRefresh({ open, msg, onClose }) {
  const classes = useStyles();
  return (
    <Dialog
      keepMounted
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"xs"}
      // width={300}
    >
      <DialogContent
        style={{
          textAlign: "center",
          paddingTop: "20px",
          paddingLeft: "45px",
          // border: "solid 1px black",
          height: 100,
        }}
        id="alert-dialog-slide-description"
      >
        <Container justifyContent="center">
          <div>
            <FadeLoader color="#ff0000" height={15} width={5} />
          </div>
        </Container>
      </DialogContent>
      {/* <DialogTitle
        style={{ direction: "rtl", textAlign: "center", fontWeight: "bold" }}
        id="alert-dialog-slide-title"
      >
        {msg}
      </DialogTitle> */}
    </Dialog>
  );
}
