import React, { useEffect } from "react";
import { uri } from "../../help/Api";

import { sto } from "../../Context/InshData";
import useFetchObjectByPost from "../../components/Hooks/useFetchObjectByPost";
import Dashboard from "./Dashboard";
import LoadingPage from "../../services/LoadingPage";
import { Typography } from "@material-ui/core";
import Swipeable from "../../Fronts/Main/Swipeable";

export const POSDashboard = () => {
  const tb = {
    wait: true,
    Branches: true,
    Safes: true,
    // costcenters: true,
    defaultOmla: true,
    stor: sto,
    parms: { safe: -1 },
  };
  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb
  );
  useEffect(() => {
    console.log("hie edrees", AutoOptions);
  });

  if (fetchAll || !AutoOptions?.Branches) return <LoadingPage />;

  return (
    <>
      <Dashboard
        {...{
          Branches: AutoOptions?.Branches ? AutoOptions.Branches : [],
          Safes: AutoOptions?.Safes ?? [],
          curr: AutoOptions?.defaultOmla?.symbol,
        }}
      />
      <br />
    </>
  );
};

const DashboardCon = () => {
  return (
    <>
      <POSDashboard />
      <br />
      <br />
      <br />
      <Typography component="div">
        {" "}
        <Swipeable />{" "}
      </Typography>
    </>
  );
};

export default DashboardCon;
