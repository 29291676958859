import moment from "moment/moment";

export const CostType = [
  { id: 1, label: "by-item-price-purch" },
  { id: 0, label: "by-begin-inventory-purch" },
];

export const SAdefaultValues = {
  currency: { id: -1, name: "الكل" },
  dateFrom: moment(new Date()).format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  safe: { id: -1, name: "الكل" },
  chk_withvat: false,
  type_Cost: CostType[0],
};
