import React from "react";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import StoreExchange from "./StoreExchange";
import { uri } from "../../../../help/Api";
import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import LoadingPage from "../../../../services/LoadingPage";
import { getReFormatingAccount } from "../../../Accounts/BackEnd/receipt-voucher/multi-receipt-voucher/MVCon";
import { accountsURL } from "../../../../api/EReceipt";
import { sto } from "../../../../Context/InshData";

function StoreExchangeView(props) {
  const {
    data: newId,
    fetching: fetchingid,
    fetchURL: fetchId,
  } = useFetchURL({
    ...{
      url: uri + `TwreedSF/newNo?branch=${sto.branchi}&&type=${props.type}`,
    },
  });

  if (!newId || fetchingid) return <LoadingPage />;
  return (
    <StoreExchange
      {...{
        ...props,
        newId: newId,
        fetchId,
      }}
    />
  );
}

export function TSContainer({ type }) {
  const tttb = {
    /*other:true,*/ // out
    wait: true,
    other: true,
    costcenters: true,
    SafesAll: true,
    groups: true,
    omlat: true,
    Currencies: true,
    /*params*/ // out
    parms: {
      tname: "strEx",
      scurrenciestype: "I",
      iempid: sto.empi,
      scurrencieShow: "Fav",
      currType: 2, // out
      currShow: "M",
      igroupid: -1,
      takei: -1,
      // PF: false,
    },
    autos: {
      costcenters: true,
      groups: true,
      omlat: true,
    },
  };

  const url = uri + "Res";
  const {
    object: AutoOptions,
    fetching: fetchinData,
    fetch: fetchURL,
  } = useFetchObjectByPost(url, tttb);
  const {
    data,
    fetching,
    fetchURL: fetchURL1,
  } = useFetchURL({
    ...{
      url: accountsURL,
      mapFunction: getReFormatingAccount,
    },
  });

  if (fetchinData || !AutoOptions?.Safes) return <LoadingPage />;
  return (
    <StoreExchangeView
      {...{
        AutoOptions: { ...AutoOptions, accounts: data } ?? [],
        type: type,
        // accounts: data ?? [],
      }}
    />
  );
}

export default function StoreExchangeCon() {
  return (
    <TSContainer
      {...{
        type: 2,
        // accounts: data ?? [],
      }}
    />
  );
}
