export const cellS = {
  padding: "6.9px 0px",
  textAlign: "-webkit-center",
  margin: "0px",
  whiteSpace: "nowrap",
  width: "0px",
  color: "#000",
  marginLeft: 2,
  marginRight: 2,
};
export const headerS = {
  height: "15px",
  color: "#fff",
  textAlign: "-webkit-center",
  margin: "0px",
  whiteSpace: "nowrap",
  width: "0px",
  backgroundColor: "#f18584",
  padding: "7px 1px",
  marginLeft: 1,
  marginRight: 1,
};
