import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    main:{
      padding: theme.spacing(1),
  
    },
    appbar2:{
      margin: theme.spacing(0,1),
      textAlign: '-webkit-center',
    },
    textField: {
     marginBottom:7,
     textAlign: '-webkit-center',
    },
    textField2: {
      marginTop:5,
      textAlign: '-webkit-center',   
    },
    buttons: {
      margin: theme.spacing(1),
      marginBottom: 25,
      textAlign: '-webkit-center',
      // margin:10,
      // direction:'rtl'
    },
    root: {
      direction: 'rtl',
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
      border: 'solid 1px #009688',
      // width: '100%',
      // height: '100%', 
  
    },
    root22: {
      direction: 'rtl',
      marginBottom:70,
      padding: theme.spacing(1),
      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
      border: 'solid 1px #009688',
      // width: '100%',
      // height: '100%', 
  
    },
    notess: {
      marginRight: 20,
      marginTop: 12,
      margin: theme.spacing(1),
  
    },
    GridButton: {
      margin: theme.spacing(1),
      // direction:'rtl',
  
    },
    table: {
      // boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
      // direction:'rtl',
      direction: 'rtl',
      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
      border: 'solid 1px #009688',
      marginBottom:40,
     // margin: theme.spacing(1),
      padding: theme.spacing(1),
  
  
  
    },
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      top: 'auto',
      // marginRight: 20,
      // marginLeft: 20,
      right:'0.10%',
      // margin:20,
      bottom:3,
      left: '0.5%',
      padding:0,
      width: '98.9%',
      height: '20px',
      backgroundColor: '#808080',
  
    },
    rootCaedL: {
      // maxWidth: 500,
      // maxHeight: 600,
      // boxShadow:'0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
      boxShadow:'none', 
      height: '600px',
      paddingTop: '100px',
      textAlign: 'center'
      // borderRadius: '0.5rem',
    }, 
    logo: {
      // padding: '2rem 0rem',
      // margin: theme.spacing(5),
      // marginTop: theme.spacing(8),
      margin: 'auto',
      width:'100%',
      height:'400px',
      backgroundSize: 'cover',
      // border:'solid red 0.1px'
    },
    buttonss:{
      backgroundColor: '#808080  ',
      textAlign: '-webkit-center',
    },
    grow: {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
    fabButton: {
      position: 'absolute',
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
    buttons2:{
      height: '40px',
      color: '#fff',
      textAlign: '-webkit-center',
      margin: '0px',
      whiteSpace: 'nowrap',
      width: '0px',
      // backgroundColor: '#20369f',
      backgroundColor: '#808080  ',
      //padding: '7px 1px',
      // marginLeft: 1, marginRight: 1,
    }
  
  
  }));