import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Items from "./Items";

export default function ItemsCon(){

    const locat = useLocation(); 
    useEffect(()=>{console.log("the locate is", locat)})
    return <Items locat={locat} isDialog={false} />
}