import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable, { MTableToolbar } from "material-table";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { headerS, cellS } from "./sub-StoreExchange-style";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
export default function SubStoreExchangeTable(props) {
  const { rows, selectRow, deleteRow, loading, object } = props;

  const { t } = useTranslation(["forms"]);

  const nRows = rows.map((r) => {
    return {
      ...r,
      omVal: (object.omla?.exchange * r.price).toFixed(2),
      omTotal: (object.omla?.exchange * r.total).toFixed(2),
    };
  });
  var columns = [
    { title: "id", field: "id", hidden: true },
    { title: t("barcode"), field: "barcode" },
    { title: t("item"), field: "symbol" },
    { title: t("unit"), field: "mUnit.name" },
    { title: t("quanity"), field: "quan" },
    { title: t("price"), field: "price" },
    { title: t("total"), field: "total" },
    { title: t("curr-local"), field: "omVal" },
    { title: "الإجمالي محلي", field: "omTotal" },
  ];

  return (
    <Grid item container>
      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <MaterialTable
            style={{ boxShadow: "none", direction: "rtl" }}
            options={{
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
              paging: false,

              headerStyle: headerS,
              cellStyle: cellS,
              maxBodyHeight: "50vh",
              minBodyHeight: "30vh",
            }}
            localization={{
              // body: { emptyDataSourceMessage: <h3 style={{ color: '#000' }} >لا توجد اصناف</h3>, },
              body: {
                emptyDataSourceMessage: (
                  <h3
                    style={{
                      marginTop: "20vh",
                      border: "solid 0px",
                      boxShadow: "none",
                    }}
                  ></h3>
                ),
              },
              toolbar: { searchPlaceholder: "بحث في الفروع" },
            }}
            onRowClick={(event, rowData) => {
              selectRow(rowData);
              // showData(rowData);
              event.stopPropagation();
            }}
            columns={columns}
            data={nRows}
            icons={"tableIcons"}
            actions={[
              {
                // icon: "Add",
                icon: () => <DeleteIcon style={{ color: "red" }} />,
                iconProps: {
                  style: {
                    justifyContent: "center",
                    fontSize: "60px",
                    color: "red",
                  },
                },
                tooltip: "حذف",

                onClick: (e, rowData) => {
                  deleteRow(rowData.localId);
                },
              },
            ]}
          />
        )}
      </Grid>
    </Grid>
  );
}
