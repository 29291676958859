import React,{Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import CircularProgress from '@material-ui/core/CircularProgress';
import {headerS, cellS, } from './StoreTransferSub-style';
import { Delete as DeleteIcon } from '@material-ui/icons';

export default function StoreTransferSubTable(props){

    const {rows, selectRow, deleteRow, loading} = props;

   var columns = [
    {title: 'id', field:"id", hidden: true,},
    { title: "الباركود", field: "barcode" },
    { title: "اسم الصنف", field: "item.symbol" },
    { title: "الوحدة", field: "mUnit.name" },
    { title: "الكمية", field: "val2" },
    { title: " الكمية المتوفرة", field: "Qtys" },
    ];


    return(
        <Grid  item container>
          <Grid item xs={12}>

          {
            loading ? <CircularProgress/> :
             <MaterialTable style={{ boxShadow: 'none', direction: 'rtl', }}
            options={{
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
              paging: false,

              headerStyle: headerS,
              cellStyle: cellS,
              maxBodyHeight: '50vh',
              minBodyHeight: '30vh',

            }}

            localization={{
              // body: { emptyDataSourceMessage: <h3 style={{ color: '#000' }} >لا توجد اصناف</h3>, },
              body: {
                emptyDataSourceMessage: <h3
                  style={{ marginTop: '20vh', border: 'solid 0px', boxShadow: 'none' }}
                ></h3>
              },
              toolbar: { searchPlaceholder: "بحث في الفروع", },
            }}

            onRowClick={(event, rowData) => {
              selectRow(rowData)
              // showData(rowData);
              event.stopPropagation();
            }}

            columns={columns}
            data={rows}
            icons={"tableIcons"}
            actions={[
              {
                // icon: "Add",
                icon: () => <DeleteIcon style={{color:'red'}} />,
                iconProps: {
                   style: { justifyContent: 'center', fontSize: "60px", color: "red" } 
                  },
                tooltip: "حذف",
                
                onClick: (e,rowData) =>{
                  deleteRow(rowData.id);
                } 
              }
            ]}
          />

          }
          </Grid>
      </Grid>
    )
}