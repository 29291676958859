import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, FormGroup} from '@material-ui/core';
import {useStylesTF,useStylesTD} from '../../../St/comps/UseStyle';
import { useEffect } from 'react';

export default function SafesForm(props){
    const { 
      object,
      data,
      onChange,
      } = props;
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    useEffect(()=>{
      console.log('dad', object)
    })
    const onChangeData = (prop) => (event) =>{

      onChange(event, {name: prop, value: event.target.value});
    } 

    return(
        <React.Fragment>

        <Grid item lg={3} md={3} sm={4} xs={6}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"name"} label={"اسم المخزن"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"name"} 
            value={object.name} 
            onChange={onChangeData('name')}
            />
        </Grid> 
        <Grid item lg={2} md={2} sm={4} xs={6}>
          <Autocomplete // classes={clasAo}
            value={object.branch}
            onChange={(event, newValue) => { 
                onChange(null, {name:'branch', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={data?.auto?.Branches}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            disabled={object.type=='main'}
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"الفرع"} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid> 

        <Grid item lg={2} md={2} sm={4} xs={6}>
          <Autocomplete // classes={clasAo}
            value={object.status}
            onChange={(event, newValue) => { 

                onChange(null, {name:'status', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={data?.auto?.States}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            disabled={object.type=='main'}
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"حالة المخزن"} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid> 
        <Grid item lg={5} md={5} sm={12} xs={12}>
        <Autocomplete multiple // classes={clasAo}
            value={object?.Safe_Emps}
            onChange={(event, newValue) => { 
             const index = object?.Safe_Emps
             .findIndex(x=> x.name == event.target.textContent);
             console.log('event', index)

             if(index==-1)
              onChange(null, {name:'Safe_Emps', value: newValue});
            
            }}
            id="controllable-states-dem" size="small"
            options={data?.auto?.Employees}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            style={{ direction:'ltr' }}  // disabled={}
            //disabled={statu Dis.pricePay}
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} 
            label={'الموظف المسئول'} 
            variant="outlined" size="small" />}
                />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"notes"} label={"ملاحظات"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"notes"} 
            value={object.notes} 
            onChange={onChangeData('notes')}
            />
        </Grid> 
        
        <Grid>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox fullWidth checked={object.IS_Default} 
            onChange={(e)=>onChange(null, {name:'IS_Default', value: e.target.checked}) }
            name="IS_Default" />}
            size="small"
            color="primary"
            fontSize="small"    
            label="المحزن الإفتراضي"
          />
          
        </FormGroup>
        </Grid>
      </React.Fragment>
    )
    
}