import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStylesTF, useStylesLbl } from "../../St/comps/UseStyle";
import { useTranslation } from "react-i18next";

export default function GlobleOmlatForm(props) {
  const { omlat, omla, onExchangeChange, onChange, amount, disabled } = props;
  const { t } = useTranslation(["forms"]);

  // const ommmm = [];
  const clasTF = useStylesTF();
  const claslbl = useStylesLbl();

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Autocomplete
            value={omla}
            onChange={(event, newValue) => {
              onChange(newValue);
            }}
            id="controllable-states-demo"
            size="small"
            options={omlat}
            disabled={disabled ? disabled : false}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.symbol
            }
            // style={{  }}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCostcenter"
                {...params}
                label={t("currency")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="check_no"
            label={t("curr-exchange")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input }}
            disabled={disabled ? disabled : false}
            onChange={(e) => onExchangeChange(e.target.value)}
            name="exchange"
            value={omla?.exchange}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="checkbank"
            label={t("curr-local")}
            className={clasTF.rootEDS}
            // onChange={(e)=>onChange(null, {name:'checkbank', value:e.target.value})}
            name="checkbank"
            value={Number(amount * omla?.exchange)}
            disabled={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
