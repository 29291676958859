import React from "react";
import { uri } from "../../../../help/Api";
import AccountsIndex from "./AccountIndex";
import { useEffect } from "react";
import { insh, sto } from "../../../../Context/InshData";
import dataF from "../../../../Server/dataF.json";
import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";

export function AccountsIndexCont() {
  const tb = {
    wait: true,
    omlat: true,
    PermissionsElemnts: true,

    // params
    parms: {
      branch: sto.branchi,
      iuserid: sto.idi,
      iformid: dataF[74].id,
      // iformidCuCo:dataF[3].id, /* for curr and yashml */
    },

    autos: {},
  };
  const url = uri + "Res";

  const {
    object: data,
    fetching: fetchinData,
    fetch,
  } = useFetchObjectByPost(url, tb);

  return (
    <AccountsIndex
      omlat={data?.omlats}
      prmis={data?.permisElm ?? insh.permiElm}
    />
  );
}
