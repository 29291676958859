

const infoCust = [
    {pCode:123,name:'العميل',Code:0,typeCust:1,typeIndex:1,CustInvType:1,Nature:1},
    {pCode:261,name:'المورد',Code:0,typeCust:2,typeIndex:2,CustInvType:2,Nature:2},
  ];

  
  // const inshCustomer = {
  //   name:null,tax_no:null,mobile:null,cust_DiscValue:0,cust_PresValue:0,
  //     maxdepit:0,IValue:0,national_id:null,car_number:null,car_shace:null,
  //      address:null,area:-1,ity:-1,country:-1,city:-1,email:null,
  //     fax:null,notes:null,tel:null,
  //     //relationShip
  //     CustandSup:null,act:null,res_id:null,
  //     // defult
  //      type:1,
  // }

  const inshCustomer = {
    name:'',tax_no:'',mobile:'',cust_DiscValue:0,cust_PresValue:0,
      maxdepit:0,IValue:0,national_id:'',car_number:'',car_shace:'',
       address:'',area:-1,ity:-1,country:-1,city:-1,email:'',
      fax:'',notes:'',tel:'',
      //relationShip
      CustandSup:'',act:'',res_id:'',
      // defult
      type:null,
  }



  

  export {inshCustomer,infoCust }