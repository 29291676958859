import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import { Card, CardContent, colors, Container, Divider, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import BalanceCard from "../../../../components/BalanceCard";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    root: {
        // height: '80px',
        fontFamily: "Amiri-Regular",
        // borderBottomWidth: "5px",
        // borderBottomStyle: "solid",
        color: "#000",
        paddingTop: "0.5rem"
    },
    rootBodys: {
        fontFamily: "Amiri-Regular",
        backgroundColor: colors.grey[300],
        borderBottomColor: colors.grey[700],
        borderBottomWidth: "5px",
        borderBottomStyle: "solid",
        color: "#000",
        direction: "rtl",
    },
    content: {
        // alignItems: "center",
        //display: "flex",
        fontFamily: "Amiri-Regular",
    },
    title: {
        fontWeight: 500,
        fontFamily: "Amiri-Regular",
    },
}));

function TotalsActivity({ data }) {

    const cls = useRptAF();
    const { t } = useTranslation(["forms"]);
    const totals = Rept.tot;

    const classes = useStyles();
    return (
        <Card className={clsx(classes.root)}
        >
            <Grid item xs={12} container className={classes.rootBodys}

            >
                <CardContent>
                    <Grid container item xs={12} /*className={cls.rootchip}*/ style={{
                        fontFamily: "Amiri-Regular",
                        direction: "rtl"
                    }}>
                        <Grid container item xs={3}  >

                            <Grid item xs={12} container >
                                <Container
                                    style={{
                                        justifyContent: "center",
                                    }}
                                >
                                    <Grid xs={12} style={{ fontFamily: "Amiri-Regular", maxLines: 1 }}>
                                        <div>{"إجمالي عدد السجلات"}</div>
                                    </Grid>
                                    <Divider />
                                    <Grid xs={12} style={{ fontFamily: "Amiri-Regular" }}>
                                        <div> {totals.countRow}</div>
                                    </Grid>
                                </Container>
                            </Grid>
                        </Grid>
                        <Grid container item xs={3}  >

                            <Grid item xs={12} container >
                                <Container
                                    style={{
                                        justifyContent: "center",
                                    }}
                                >
                                    <Grid xs={12} style={{ fontFamily: "Amiri-Regular", maxLines: 1 }}>
                                        <div>{Rept.tot1}</div>
                                    </Grid>
                                    <Divider />
                                    <Grid xs={12} style={{ fontFamily: "Amiri-Regular" }}>
                                        <div> {totals.totSal.toFixed(2)}</div>
                                    </Grid>
                                </Container>
                            </Grid>
                        </Grid>
                        <Grid container item xs={3} >
                            <Container
                                style={{
                                    justifyContent: "center",
                                }}
                            >
                                <Grid container xs={12} style={{ justifyContent: "center", maxLines: 1 }}>

                                    <Grid xs={12} style={{ fontFamily: "Amiri-Regular" }}>
                                        <div>{Rept.tot2}</div>
                                    </Grid>
                                    <Divider />
                                    <Grid xs={12} style={{ fontFamily: "Amiri-Regular" }}>
                                        <div>{totals.totTax.toFixed(2)}</div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>

                        <Grid container item xs={3} >
                            <Container
                                style={{
                                    justifyContent: "center",
                                }}
                            >
                                <Grid container xs={12} style={{ justifyContent: "center" }}>

                                    <Grid xs={12} style={{ fontFamily: "Amiri-Regular", maxLines: 1 }}>
                                        <div>{Rept.tot3}</div>
                                    </Grid>
                                    <Divider />
                                    <Grid xs={12} style={{ fontFamily: "Amiri-Regular" }}>
                                        <div>{totals.totFinalSal.toFixed(2)}</div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>

                    </Grid>
                </CardContent>
            </Grid>
        </Card>

    );
}

export default TotalsActivity;
