 import moment from "moment/moment"

  export const balanceTypes =[
    {id:0, name:'Stocks', label:'الكل', code:0,},
    {id:1, name:'Customers', label:'عملاء', code:123},
    {id:2, name:'Customers', label:'موردين', code:261},
  ]
  export const deptTypes =[
    {id:1, name:'Customers', label:'عملاء', code:123},
    {id:2, name:'Customers', label:'موردين', code:261},
  ]
  export const payTypes =[
    {id:0, name:'الكل'},
    {id:1, name:'مسدد'},
    {id:2, name:'غير مسدد'},
  ]

   export const SAdefaultValues = {
        firstTime: true,
        type: balanceTypes[0],
        dateFrom: moment(new Date()).format('YYYY-MM-DD'),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
        payType: payTypes[0],
        branch:{id:-1, name:'الكل'},
        center:{id:-1, name:'الكل'},
        account: {id: '0', name:''},
        omla:{id:-1, name:'الكل'},
        openBalance:true,
        oldBalance: true,
        

    }