import React from "react";
import { uri } from "../../../help/Api";
import { useEffect } from "react";
import BackdropScI from "../../../services/BackdropScI";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import SubscriptionRenew from "./subscription-renew-settings";

export default function SubscriptionRenewCon() {
  const url = uri + "Foundation";

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url,
    },
  });

  useEffect(() => {
    console.log("data  is", data);
  });

  if (fetching || !data) return <BackdropScI />;
  return (
    <SubscriptionRenew
      {...{
        data,
        fetchURL,
      }}
    />
  );
}
