import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import SalesDetails from "./SalesDetails";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../services/LoadingPage";
const defaultAutos = {
  types: [],
};

export default function SalesDetialsCon() {

  const [branch, setBranch] = useState({ id: -1, name: "الكل" });
  const [valueType, setValueType] =useState({id: -1, name: "الكل"});
  const tb = {
    Customers: true,
    Branches: true,
    types:true,
    stor: { ...sto, branchi: branch.id == -1 ? sto.branchi : branch.id },
    parms: { type: -1 },
  };
  const url = uri + "Auto" + "/custTypeM";
  const { data: CustomerOptions, fetching: fetchCust } = useFetchURL({
    ...{ url, dependencyArray: [] },
  });
  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
    [branch],
    [valueType],
  );

  if (
    fetchCust ||
    fetchAll ||
    CustomerOptions?.length == 0
  )
    return <LoadingPage />;

  return (
    <SalesDetails
      {...{
        AutoOptions: AutoOptions?.types ? AutoOptions : defaultAutos,
        CustomerOptions: CustomerOptions?.length > 0 ? CustomerOptions : [],
        branch,
        setBranch,
        valueType,
        setValueType,
       // valueType_1
       isPurches: false,
      }}
    />
  );
}
