import React,{useState,useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import  Container from '@material-ui/core/Container';
import axios from 'axios';
import {uri,} from '../../../../help/Api';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import  Swipeable  from '../../../../Fronts/Main/Swipeable';
// // import Page from '../../../Page';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  itemW:{
    // display:'flex',
width:'90%'
  },
  table: {
    minWidth: 650,
  },
  allWidth: {
    minWidth: 650,
  },
  checStyl:{
    marginTop: '17px',
    fontSize: '16px',
    padding: '-1px',
  }
}));

const sty ={
  fontSize: '1.4rem',
  marginLeft: '-6px',
  marginRight: '-6px',
}
const styTitle ={
  textAlign: '-webkit-center',
  // backgroundColor:'red'
}





let dateFromMA_G = '2021-01-01';
let dateToMA_G = moment(new Date()).format('YYYY-MM-DD')
// for select
let prmvType=-1
let prmvCustomers=-1
let uriState='All';
let viewPrm = null;


const defoultValues = () => {
dateFromMA_G = '2021-01-01';
dateToMA_G = moment(new Date()).format('YYYY-MM-DD');
prmvType=-1;
prmvCustomers=-1;
uriState='All';
}

var columns = [
  {title: "الرصيد", field: "Rseed", },
  {title: "مدين", field: "mdeen",},
  {title: "دائن", field: "daen",},
  {title: "رقم القيد", field: "id",},
  {title: "تاريخ القيد", field: "date",},
  {title: "البيان", field: "notes",},
  {title: "المرجع", field: "",},
  {title: "عرض القيد", field: "",},
  {title: "الفروع", field: "branch",},
];

const HesabCustAmeel = () => {
  const classes = useStyles();

  let idata = []
  const [data, setData] = React.useState([]);

  let his = useHistory();
  const toPosh = () => {
    const info = {
    // rout:uri+viewPrm,
    columns:columns,
    data:data,
    tot:{
      totTasdeed:totTasdeed.toFixed(2),
      totDaen:totDaen.toFixed(2),
      totMdeen:totMdeen.toFixed(2)
    },
    from:'الموردين',
    title:'كشف حساب الموردين',
    auto:valueCustomer.name,
    fDate:dateFromMA,tDate:dateToMA,ret:'M',
    nDate:moment(new Date()).format('YYYY-MM-DD'),
    ElmntShow:true,
    }
    localStorage.setItem('iInfos',  JSON.stringify(info));
    his.push({ pathname: '/ReviewAaM', });
    window.location.reload(false);
    }

// /All /Naqde /Ajel
  // for plaseholder auto
  const [typeDName] = useState('الكل');
  const [customersDName] = useState('الكل');




  const inshAutoOp = [{id:-1,name:'فارغ'}]
  const inshAutoVa = {id:-1,name:''}

// ================= For Auto ======================   
  //Type
  const [optionsType, setOptionsType] = React.useState([]);
  const [valueType, setValueType] = React.useState({});
  //Customers
  const [optionsCustomers, setOptionsCustomers] = React.useState([]);
  const [valueCustomer, setValueCustomer] = React.useState({});
 
  // to sum
    const [totMdeen, seTtotMdeen] = useState(0); // اجمالي المدين
    const [totDaen, seTtotDaen] = useState(0); // اجمالي الدائن
    const [totTasdeed, seTtotTasdeed] = useState(0); // التسديد 

  let sumTotMdeen=0,sumTotDaen=0;

  const getAllSumTot = async (the_length) => {

    for (let i = 0; i < the_length; i++){
      sumTotMdeen+=idata[i].mdeen;
      sumTotDaen+=idata[i].daen;
    }

    seTtotMdeen(sumTotMdeen); // اجمالي المدين
    seTtotDaen(sumTotDaen); // اجمالي الدائن
    // seTtotTasdeed(sumTotDaen-sumTotMdeen);  // التسديد 
    seTtotTasdeed(sumTotMdeen-sumTotDaen);  // التسديد 

    setData([]);
    setData(idata);
  }

const GetDataWhenChangeDate = async () => {  

  reLoadUri();
  // alert(uri+viewPrm)
  await axios.get(uri+viewPrm).then(res => {

     idata=res.data;
     getAllSumTot(res.data.length);

   }).catch(error => { })
  
  };

  const changAutoCustomers = (newValue) => 
  { setValueCustomer(newValue); if(newValue.id===-1) prmvCustomers=-1; else prmvCustomers=newValue.acc_no; 
    GetDataWhenChangeDate(); 
  }

  const changAutoType = (newValue) => 
  { setValueType(newValue); prmvType=newValue.id; 
    GetDataWhenChangeDate();
  }

  const getAutoType = async (i) => {
    axios.get(uri+'Auto'+'/type')
    .then(res => { setOptionsType(res.data);
      setValueType(res.data[0]); prmvType=res.data[0].id;
     }).catch(error => { })
  }

  const getAutoCustomers = async (i) => {
    axios.get(uri+'Auto'+'/custm?t=2')
    .then(res => { setOptionsCustomers(res.data);
      setValueCustomer(res.data[0]); prmvCustomers=res.data[0].id;
     }).catch(error => { })
  }

  useEffect(() => {  
    const GetData = async () => { 
      // setData([]);

      getAutoType(0);
      getAutoCustomers(0);

   defoultValues();
   reLoadUri();
  //  alert(uri+viewPrm)
   axios.get(uri+viewPrm).then(res => { idata=res.data; getAllSumTot(res.data.length); }).catch(error => { })
  

  };
  GetData();  

  }, []);
    


  // Date T
  // From
  // const [dateFromMA, setDateFromMA] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const [dateFromMA, setDateFromMA] = React.useState('2021-01-01');
  const dateChangeFrom = (e) => {
  let getdateFrom = moment(e.target.value).format('YYYY-MM-DD');
  setDateFromMA(getdateFrom);
   dateFromMA_G = getdateFrom;
   dateToMA_G = dateToMA;

    GetDataWhenChangeDate();
  };
  //To
  const [dateToMA, setDateToMA] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const dateChangeTo = (e) => { 
    let getdateTo = moment(e.target.value).format('YYYY-MM-DD');
    setDateToMA(getdateTo);
    dateFromMA_G = dateFromMA;
    dateToMA_G = getdateTo;

    GetDataWhenChangeDate();
  };


    const reLoadUri = () => {
         viewPrm = `viewCust/All?psdate=${dateFromMA_G}&&pedate=${dateToMA_G}&&pacc_no_diget=${261}&&pacc_no=${prmvCustomers}&&ptype=${prmvType}`;
    }

  return (
    <div
      className={classes.root}
      title="---" >

      <Typography component="div" style={{ backgroundColor: '#eee', direction:'rtl' }} >

      <Container maxWidth={false}>
        <Grid container spacing={3} >
        <Grid container component={Paper} style={{marginTop:10}} spacing={1}>

         <Grid item sm={2} xs={6}>
          <TextField /*type="number"*/ fullWidth variant="outlined" size="small" id="date" 
          label="من تاريخ"
          // className={clasTF.root} InputProps={{ className: clasTF.input }}
          type="date" format="YYYY-MM-DD"
          name="date" value={dateFromMA} onChange={dateChangeFrom}/>
        </Grid>

        <Grid item sm={2} xs={6}>
          <TextField /*type="number"*/ fullWidth variant="outlined" size="small" id="date" 
          label="إلى تاريخ"
          // className={clasTF.root} InputProps={{ className: clasTF.input }}
          type="date" format="YYYY-MM-DD"
          name="date" value={dateToMA} onChange={dateChangeTo}/>
         </Grid>


        <Grid item sm={4} xs={8}>
        <Autocomplete value={valueType}
          onChange={(event, newValue) => { changAutoType(newValue) }}
          id="combo-box-demo" size="small"
          options={optionsType} 
          //getOptionLabel={(option) => option.name}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          style={{  }}
          disableClearable
          renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true}} placeholder={typeDName} label="طريقة الدفع" variant="outlined" />}   
        /></Grid>


<Grid item sm={2} xs={6}> 
        <Autocomplete value={valueCustomer}
          onChange={(event, newValue) => { changAutoCustomers(newValue) }}
          id="combo-box-demo" size="small"
          options={optionsCustomers} 
          //getOptionLabel={(option) => option.name}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          style={{  }}
          disableClearable
          renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true}} placeholder={customersDName} label="المورد" variant="outlined" />}   
        /></Grid>

</Grid>  
          
        </Grid>
       </Container> 
       

<br/>
<br/>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
      
      <TableContainer component={Paper}>
       <Table className={classes.table} size="small" aria-label="a dense table">
         <TableHead>
           <TableRow>

             <TableCell align="center">الرصيد</TableCell>
             <TableCell align="center">مدين</TableCell>
             <TableCell align="center">دائن</TableCell>
             <TableCell align="center">رقم القيد</TableCell>
             <TableCell align="center">تاريخ القيد</TableCell>
             <TableCell align="center">البيان</TableCell>
             <TableCell align="center">المرجع</TableCell>
             <TableCell align="center">عرض القيد</TableCell>
             <TableCell align="center">الفروع</TableCell>

           </TableRow>
         </TableHead>
         <TableBody>
           {data.map((row) => (
             <TableRow key={row.id}>
               <TableCell align="center">{row.Rseed}</TableCell>
               <TableCell align="center">{row.mdeen}</TableCell>
               <TableCell align="center">{row.daen}</TableCell>
               <TableCell align="center">{row.id}</TableCell>
               <TableCell align="center">{row.date}</TableCell>
               <TableCell align="center">{row.notes}</TableCell>
               <TableCell align="center"></TableCell>
               <TableCell align="center"></TableCell>
               <TableCell align="center">{row.branch}</TableCell>
             </TableRow>
           ))}
         </TableBody>
       </Table>
     </TableContainer>

        </Grid>
      </Container>

      <br/>
      <br/>

      <Container maxWidth={false}>
         <Grid container spacing={3}>
         <Grid container component={Paper}>

      <Grid item 
      // className={classes.iitem}
       sm={2} xs={6} className={classes.iitemS} style={styTitle}><CardActionArea><CardContent>
          <Typography variant="h5" color="textSecondary" component="p" style={sty}  >     
           الدين المتبقي 
          </Typography></CardContent></CardActionArea></Grid>

      <Grid item 
      // className={classes.iitem}
       sm={2} xs={6} className={classes.iitemS}> <CardActionArea><CardContent>
        <Typography variant="h5" color="textSecondary" component="p" style={sty} >     
         {totTasdeed.toFixed(2)} 
          </Typography></CardContent></CardActionArea></Grid>

      <Grid item 
      // className={classes.iitem}
       sm={2} xs={12} className={classes.iitemS} style={styTitle}><CardActionArea><CardContent>
          <Typography variant="h5" color="textSecondary" component="p" style={sty}  >     
           تسديد
          </Typography></CardContent></CardActionArea></Grid>

      <Grid item 
      // className={classes.iitem}
       sm={2} xs={6} className={classes.iitemS}> <CardActionArea><CardContent>
          <Typography variant="h5" color="textSecondary" component="p" style={sty} >     
          {totDaen.toFixed(2)} 
          </Typography></CardContent></CardActionArea></Grid>


          <Grid item 
          // className={classes.iitem}
           sm={1} xs={12} className={classes.iitemS} style={styTitle}><CardActionArea><CardContent>
          <Typography variant="h5" color="textSecondary" component="p" style={sty}  >     
           |
          </Typography></CardContent></CardActionArea></Grid>

          <Grid item 
          // className={classes.iitem}
           sm={2} xs={6} className={classes.iitemS}> <CardActionArea><CardContent>
          <Typography variant="h5" color="textSecondary" component="p" style={sty} >     
          {totMdeen.toFixed(2)}
          </Typography></CardContent></CardActionArea></Grid>

         </Grid>  
         </Grid>
         </Container>
 

 <br/>

      </Typography>

  <div style={{textAlign:'center  '}}>
    <Button variant="contained"  color="primary" className={classes.button}
     onClick={toPosh} startIcon={<PrintIcon/>}> معاينة </Button>
  </div>

  <br/>
  <br/>
       <Typography component="div" >
        <Swipeable/>
      </Typography>

     </div>
   );
 };

 export default HesabCustAmeel;

