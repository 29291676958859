import React, { useState, useRef, Component } from "react";
import ReactToPrint from "react-to-print";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeIcon from "@material-ui/icons/Home";
import Grid from "@material-ui/core/Grid";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

import { useStylesSpDial } from "../../../St/Reviews/UseStyle";
import TaxSaleContainer from "./taxSaleContainer";

class ComponentToPrint extends Component {
  constructor() {
    super();
    this.state = {
      // Your initial state
      // loading: true,
    };
  }

  // componentDidMount() {
  //   const locInv = JSON.parse(localStorage.getItem('datainv')) || {};
  //   const tb = {GetFoundation:true,Rpt_Inv:true,
  //     int1:locInv.proc_id,int2:locInv.branch,str1:locInv.ProcTypeInvProc,iFoundid:1,}
  //     const Found = []
  //     const RptInv = []
  //     const totInv = []

  //   api.post('Res',tb).then( res => {
  //     Found.push(res.data.Found);
  //     RptInv.push(res.data.RptInv);
  //     totInv.push(res.data.totInv);
  //   });
  //   this.setState({  Found,RptInv,totInv,loading: false });
  // }

  render() {
    // const theme = createTheme({typography: {
    //   fontFamily: ['Noto Kufi Arabic', 'Tajawal', /* 'Almarai',*/ ].join(','),
    //   fontSize:[15].join(','),
    // },});

    // if (this.state.loading) { return <Loader />; }
    // return (
    //   <div>
    //     <Header data={this.state.Found}/>
    //   </div>
    // );

    //     return (
    //       <MuiThemeProvider theme={theme}>
    //       <CssBaseline />
    //       <Typography component="div" style={style}>

    // {/* <div>{this.state}</div> */}
    //       <Header />
    //       <Body/>
    //       <Footer/>
    //       </Typography>

    //       </MuiThemeProvider>
    //     );

    const style = {
      backgroundColor: "#fff",
      color: "#000",
      paddingTop: "0.2cm",
      paddingBottom: "0.2cm",
    };

    const styleCont = {
      backgroundImage: "linear-gradient(60deg, #4e9e84 , #2688ba)",
    };

    return (
      <Grid item container justifyContent="center" style={styleCont}>
        <Grid item style={{ backgroundColor: "#fff", width: "22cm" }}>
          <CssBaseline />
          <Typography component="div" style={style}>
            <TaxSaleContainer />
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const TaxSalePrint = () => {
  const componentRef = useRef();
  const clasSp = useStylesSpDial();

  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(true);
  };
  let his = useHistory();

  const back = (path) => {
    path ? his.push({ pathname: "/" }) : his.goBack();
  };

  const actions = [
    {
      icon: (
        // <Fab size="small" style={{background:'#fff'}} disabled={true}>
        <ReactToPrint
          trigger={() => <PrintIcon />}
          content={() => componentRef.current}
        />
      ),
      // </Fab>
      name: "طباعـة",
    },
    {
      icon: (
        <ArrowBackIcon className={clasSp.ico} onClick={() => back(false)} />
      ),
      name: "رجـوع",
    },
  ];

  return (
    <div className={clasSp.root}>
      <ComponentToPrint ref={componentRef} />
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial}
        hidden={false}
        icon={
          <SpeedDialIcon openIcon={<HomeIcon />} onClick={() => back(true)} />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action, i) => (
          <SpeedDialAction
            key={i}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default TaxSalePrint;
