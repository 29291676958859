import moment from "moment/moment"
import * as yup from 'yup';
import { sto } from "../Context/InshData";

export const transferValidateSchema = yup.object().shape({
  safe_from: yup.string().required("قم بتحديد المخزن"),
  safe_to: yup.string().required("قم بتحديد المخزن المحول إلية"),
  branch: yup.string().required("قم بتحديد الفرع "),
})

export const receiveValidateSchema = yup.object().shape({
  safe_to: yup.string().required("قم بتحديد المخزن"),
  safe_from: yup.string().required("قم بتحديد المخزن المحول منه"),
  branch: yup.string().required("قم بتحديد الفرع "),
})

export const defaultStoreTransferData ={
    id:1,
    no: '',
    date: new moment(new Date()).format('YYYY-MM-DD'),
    safe_from: {id:'',name:''},
    safe_to: {id:'',name:''},
    center: {id:'', name:''},
    branch: {id:'',name:''},
    Type:1,
    refno:'',
    notes: '',
    transfer_no:'',
    rows:[],
}

export const dfaultfilters={
  group:{id:0, name:''},
  filter:{id:0, name:''},
  safe: -1,
  branch:1,
  all: false,
  empty: true,
}

export const isReceave={
  save:{id:0, name:"yes"}
}

export function getReFormatingGtys(data){

    const newData = data.map((row, i)=> {
            return {...row, id:i,
                 item:{id: row.currency_from, symbol: row.Name_Item}} 
        })
    return newData
}


export function formatTransfer(data){

    var clone = Object.assign({}, data);
    delete clone.rows; 
    const safesTransfer ={...clone, 
        date: new moment(new Date()).format('YYYY-MM-DD'),
        safe_from: data.safe_from.id,
        safe_to: data.safe_to.id,
        center: data.center.id? data.center.id: -1,
        branch: data.branch.id,
    }

    const _Data = data.rows.map(r=>{
        return {currency: r.currency_from,
                unit: r.unit,
                user_emp:sto.empi, 
                value: r.val2,
                val1: r.val2,
                }
    })
    return { safesTransfer: safesTransfer, _Data: _Data }
}