import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import React  from "react";
import { cellS, headerS } from "../../../St/comps/cStyle";

import {useStylesContainers,} from '../../../St/comps/UseStyle';
function getItemFromOptions(id, options){
  if(id)
    return options.filter(g=>g.id==id)[0];
  return '';
}
const OmlatTable =(props)=>{

    const {auto, data:options, omlats, onShow} = props;
  const clsConts = useStylesContainers();
    const columns = [
    {title: "الرقم", field: "id", },
    {title: "اسم العملة", field: "name", },
    {title: "الاسم لاتيني", field: "NameEn", },
    {title: "رمز العمله", field: "symbol", },
    {title: "سعر التحويل", field: "exchange", },
    {title: "الحالة", field: "IS_Active", },
    {title: "العمله الافتراضية", field: "is_main", },
    //{title: "العمله المحلية", field: "IS_Default", },
    {title: "الدوله", field: "Country", },
    {title: "الفكه عربي", field: "FakaName", },
    {title: "الفكه لاتيني", field: "FakaNameEn", },
    {title: "كل 1 =", field: "oneEqualto", },
    {title: "المكافئ", field: "TotalFaka", },
    {title: "الوصف", field: "omla_desc", },
  ];

    function onRowClickHandle(data){
      
      console.log(options.auto);
        onShow({...data, 
          Country: getItemFromOptions(data.Country, options.auto.Countries),
          oneEqualto: getItemFromOptions(data.oneEqualto, options.auto.equalOmlat),
          }, "edit"); 

    }
    return(
        
 <Grid item container lg={12} md={12} className={[clsConts.rootdown,]}>
    <Grid item container xs={12} className={[clsConts.subDown,clsConts.subDownC,]}>
      <Grid item xs={12}>
           <MaterialTable style={{boxShadow:'none'}}

                title=''
              // variant="outlined"
              options={{ 
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
                paging: false,
           
                headerStyle: headerS,
                cellStyle: cellS,
              maxBodyHeight: '65vh',
              minBodyHeight: '65vh',
             }}

             localization={{

              body:{
                emptyDataSourceMessage:<h3 
                style={{ marginTop:'20vh', border:'solid 0px', boxShadow:'none'}}
                ></h3> } }}
              columns={columns}
             data={omlats}
             
             onRowClick={(event, rowData) => {
                onRowClickHandle(rowData);
              // event.stopPropagation();
              event.preventDefault();
            }}/>
      </Grid>    
    </Grid>
    </Grid>
    )
}

export default OmlatTable;