import { makeStyles } from '@material-ui/core/styles';
import { green} from '@material-ui/core/colors';
import { insh } from '../../../Context/InshSt';

import salj from '../../../assets/img/menu/salj.jpg';
import parshj from '../../../assets/img/menu/parshj.jpg';
import safej from '../../../assets/img/menu/safej.jpg';
import custj from '../../../assets/img/menu/custj.jpg';
import accj from '../../../assets/img/menu/accj.jpg';
import dabckj from '../../../assets/img/menu/dabckj.jpg';
import userj from '../../../assets/img/menu/userj.jpg';
import settj from '../../../assets/img/menu/settj.jpg';


 const useStyleC = makeStyles((theme) => ({
    root: {
      // maxWidth: 500,
      maxHeight: 600,
      // boxShadow:'0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
      boxShadow:'none', 
      borderRadius: '0.5rem',
    },
    rootSal: {
      // backgroundImage:
      // backgroundImage: url('../Sales/WA0059.jpg'),
      backgroundImage: `url(${salj})`,
      backgroundPosition: 'center', 
        backgroundSize: 'cover', 
        // backgroundRepeat: 'no-repeat',
        margin: 'auto',
        // height: '55vh', // width: 'calc(20vw * 0.54 - 2%)', // borderRadius: 8,
        // display: 'flex', // marginLeft: '10px', // marginTop: '10px' // maxWidth: 345, // paddingLeft:10,
    },
    rootParsh: { backgroundImage: `url(${parshj})`, backgroundPosition: 'center', backgroundSize: 'cover', },
    rootSafe: { backgroundImage: `url(${safej})`, backgroundPosition: 'center', backgroundSize: 'cover', },
    rootCust: { backgroundImage: `url(${custj})`, backgroundPosition: 'center', backgroundSize: 'cover', },
    rootAcc: { backgroundImage: `url(${accj})`, backgroundPosition: 'center', backgroundSize: 'cover', },
    rootDabck: { backgroundImage: `url(${dabckj})`, backgroundPosition: 'center', backgroundSize: 'cover', },
    rootUser: { backgroundImage: `url(${userj})`, backgroundPosition: 'center', backgroundSize: 'cover', },
    rootSett: { backgroundImage: `url(${settj})`, backgroundPosition: 'center', backgroundSize: 'cover', },
    media: {
      // height: 0,
      // paddingTop: '56.25%', // 16:9,
      // background:'orange',
      textAlign:'center',
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      lineHeight: 0,
    },
    mediaP: {
      display:'flex',
      height: 140,
    },
    iconCard:{
      fontSize: '5.5rem',
    },
    content:{
      // background:'green',
      textAlign:'center',
      boxShadow: '0 0 10px 5px #fff',
      // background: '#ffffffd6',
      paddingBottom: theme.spacing(0.3),
      paddingTop: theme.spacing(0),
    },
    header:{
      padding: theme.spacing(0.3),
      // background:'yellow',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: green[500],
      fontSize:20
    },
    actions:{
    // flexGrow: 1,
    // flex: 1,
      // backgroundColor: yellow[500],
      padding: theme.spacing(0.3),
      textAlign:'center',
      // alignItems:'center',
      display:'inherit'
    },
    slidcontent:{
    // border:'solid',
    padding: theme.spacing(0.3),
  
  
    },
  }));

 const useStyleDC = makeStyles((theme) => ({
  container:{
    // background:'orange',
    [theme.breakpoints.only('xs')]: {
      paddingLeft:'0.1%', paddingRight:'0.1%',
      // background:'orange',
    },
  },
  rootCaedR: {
      // maxWidth: 500,
      //  maxHeight: 600,
      // boxShadow:'0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
      boxShadow:'none', 
      // borderRadius: '0.5rem',
      // border:'solid #fff',
      // margi:10,
    },
    rootCaedL: {
      // maxWidth: 500,
      // maxHeight: 600,
      // boxShadow:'0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
      boxShadow:'none', 
      // borderRadius: '0.5rem',
    }, 
    rootContL:{
      borderRight:'solid 0.1rem',
    },
    rootHeadr:{
      border: 'solid 0.1rem #808080',
      borderRadius:'0.3rem',
      padding: '0.8rem',
      textAlign: 'center',
      margin:'0.4rem 0.2rem',
      [theme.breakpoints.only('xs')]: {
        fontSize:'1rem'
       },
    },

    title:{
      [theme.breakpoints.only('xs')]: {
         fontSize:'1rem'
       },
    },
    rootTabs:{
      // flexGrow: 1,
      // justifyContent:'center',
      // display:'flex',
      // direction:'rtl'
      // display: 'contents',
      // background:'#fbf0df'//
    },
    paper: {
      flexGrow: 1,
      justifyContent:'center',
      display:'flex',
      direction:'rtl',
      margin:'0rem 0.2rem',
      background:'#fbf0df',
      boxShadow:'none',
      padding:'-5rem'
    },
    tabs: {
      // background:'#fbf0df',
    },
    tab: {
      // borderLeft: 'solid 0.1px #808080',
      fontWeight: 'bolder',
      color:'#000'
    },
    rootCard:{
      direction:'rtl',
      borderLeft:'solid 0.1px #dcdcdc',
      borderRight:'solid 0.1px #dcdcdc',
      borderBottom:'solid 0.1px #dcdcdc',
      padding:'0.5rem',
      margin:'0rem 1rem 0rem 0rem',
      // background:'green',
      [theme.breakpoints.only('xs')]: {
      // padding:'0.1rem',
      margin:'0rem',
     },
    },
    subCard:{
      background:'orange',
      borderRadius:'1rem',
    },
    media: {
      textAlign:'center',
      padding: '1.9rem 0rem',
      margin: theme.spacing(0),
      lineHeight: 0,
      [theme.breakpoints.only('xs')]: {
        padding:'1.2rem 0rem',
      },
    },
    mediaL: {
      textAlign:'center',
      padding: '1.9rem 0rem',
      margin: theme.spacing(0),
      lineHeight: 0,
    },
    iconCardL:{
      fontSize: '5.5rem',
    },
    contentL:{
      direction: 'rtl',
      color:'#fff',
      textAlign:'center',
      padding:'1rem 0rem',
      fontSize:'5rem'
    },
    logo: {
      // padding: '2rem 0rem',
      // margin: theme.spacing(5),
      // marginTop: theme.spacing(8),
      width:'100%',
      height:'200px',
      backgroundSize: 'cover',
      // border:'solid red 0.1px'
    },
    area:{
      // color:'#f7e7ee',
      color:'#fff',
    },
    iconCard:{
      fontSize: '5.5rem',
      // color:'#f7e7ee',
    },
    content:{
      direction: 'rtl',
      textAlign:'center',
      padding:'0.9rem 0rem',
      [theme.breakpoints.only('xs')]: {
        padding:'0.6rem 0rem',
      },
    },
    text:{
        //  color:'#f7e7ee',
         color:'#fff',
        [theme.breakpoints.only('md')]: {
          fontSize:'1rem',
        },
        [theme.breakpoints.only('sm')]: {
          fontSize:'0.8rem',
        },
        [theme.breakpoints.only('xs')]: {
          fontSize:'0.7rem',
        },

    },
    button: {
        margin: theme.spacing(1),
        background: 'red',
        color: '#fff',
        borderRadius: '5px',
        // padding: '11px 18px',
    },
  }));

 const useStyleI = makeStyles((theme) => ({
    root: {
      
      color:'#fff',
      boxShadow:' 0px 0px 0px -1px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 1px 0px rgb(0 0 0 / 0%)',
      
    },
  
    container: {
      // background:'orange',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
       },
      // [theme.breakpoints.up('md')]: {
      //   margin: theme.spacing(2),
      //  },
      // [theme.breakpoints.up('lg')]: {
      //   margin: theme.spacing(3), 
      // },
    },
  
    action:{
      display: 'flex',
      borderRadius:4,
      // background:'green',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
       },
      // [theme.breakpoints.up('md')]: {
      //   margin: theme.spacing(2),
      //  },
      // [theme.breakpoints.up('lg')]: {
      //   margin: theme.spacing(3), 
      // },
    },
    content: {
      // flex: '1 0 auto',
      display: 'flex',
      alignItems: 'center',
      // paddin: theme.spacing(0),
      // margin: theme.spacing(0),
      padding: '5px',
      fontSize:15,
      textAlign: '-webkit-center'
    },
    cover: {
      // width: 251,
      // padding: '0px'
    },
    icon:{
      fontSize: '2.3rem',
      color: '#fff',
    },
   
  }));
  
const useStylesSpDial = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // position: 'relative',
    '& .MuiFab-primary': {
      // color: '#fff',
      backgroundColor: 'red'
    },
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    // color:insh.colors.cancl,
  },
  ico:{
    background: '#fff',
    width: '100%',
    height: '100%',
    borderRadius: 50,
    padding: 7,
    // color:insh.colors.primary,
    color:insh.colors.secndry,
  },
  clPri:{color:insh.colors.primary},
  clSec:{color:insh.colors.secndry},
  clsal:{color:insh.colors.sal},
  clpur:{color:insh.colors.pur},
  clsaf:{color:insh.colors.saf},
  clacc:{color:insh.colors.acc},
  clcan:{color:insh.colors.cancl},

}));
  export {useStyleC, useStyleDC, useStyleI,useStylesSpDial};