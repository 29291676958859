import { Grid } from "@material-ui/core";
import { Fragment, useEffect } from "react";
import { getLS } from "../../../utils/useLS";
import FirstTermInvoice from "./first-term-invoice";
import SalesManInvoice from "./salesman-invoice";

export default function InvoiceCon() {
  let locR = getLS("DataRem", {});

  if (locR?.salesmenId != 0) return <SalesManInvoice />;

  return <FirstTermInvoice />;
}
