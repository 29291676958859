import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

export default function DashboardForm(props) {
  const { rptParams, setRptParams, Branches, Safes, onChangeAutoData } = props;

  return (
    <React.Fragment>
      <Grid
        container
        style={{
          padding: 10,
          backgroundColor: "#fff",
          borderBottom: "solid 4px #aaa",
        }}
        spacing={1}
      >
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="datef"
            label="من تاريخ"
            type="date"
            format="YYYY-MM-DD"
            name="dateForm"
            value={rptParams.dateFrom}
            onChange={(e) =>
              setRptParams({ ...rptParams, dateFrom: e.target.value })
            }
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="dateT"
            label="إلى تاريخ"
            type="date"
            format="YYYY-MM-DD"
            name="datet"
            value={rptParams.dateTo}
            onChange={(e) =>
              setRptParams({ ...rptParams, dateTo: e.target.value })
            }
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.branch}
            onChange={(event, newValue) => {
              setRptParams({ ...rptParams, branch: newValue });
              //  onChangeAutoData("branch", newValue)
            }}
            id="combo-box-demo"
            size="small"
            options={Branches}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="الفرع"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
