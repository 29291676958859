import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import {useRptAF} from '../../../../St/Reviews/UseStyle';
import MaterialTable from "material-table";
import {stElemnt} from '../../../../St/Reviews/cStyle';
import { useLocation } from 'react-router-dom';

function BodyA() {
  const cls = useRptAF();
  const loc = useLocation();

useEffect(() => { /*getInfo(); */ }, []);

var columns = [
  { title: "الباركود", field: "barcode" },
  { title: " الصنف", field: "itemName" },
  { title: " الوحدة", field: "mUnit.name" },
  { title: "متوسط التكلفة", field: "avgCost" },
  { title: "الكمية المتوفرة", field: "Qtys" },
  { title: " الكمية الفعلية", field: "val2" },
  { title: "الفرق", field: "diff" },
]
return (
<Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid item xs={12}>
        <MaterialTable style={{boxShadow:'none'}}
            //style={{direction:'ltr'}}
          options={{
            showTitle:false,
            toolbar: false,
            sorting: false,
            selection: false,
            // ToolBar Search
            searchFieldAlignment:"left",
            searchFieldVariant:"outlined",
            // ToolBar header
            showSelectAllCheckbox:false,
            showTextRowsSelected:false, 
            pageSize:5,
            paginationType:"stepped",
            showFirstLastPageButtons:false,
            
            paging:false,
            headerStyle: stElemnt.AF.header,
            cellStyle: stElemnt.AF.cell,
           }}
             
          localization={{ body:{ emptyDataSourceMessage:<h1></h1> ,}, }}
          
              columns={columns}
              data={loc.state.rows}
             />
        </Grid>
      </Grid>

</Grid>
   );

  }

export default BodyA;
