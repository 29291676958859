// Foundation
// id:nameA:nameE:FieldA:FieldE:bsn_no:licence_no:Address:Tel:Mobile:Fax:Email:
// website:Logo:Exchangeval:tax:port:tax_no:BillNotes:BillNotes1:BillNotes2:
// BillNotes3:Disc1_header_ar:Disc2_header_ar:Disc3_header_ar:Disc4_header_ar:
// Disc1_header_en:Disc2_header_en:Disc3_header_en:Disc4_header_en:Disc1_footer_ar:
// Disc2_footer_ar:Disc3_footer_ar:Disc4_footer_ar:Disc1_footer_en:Disc2_footer_en:
// Disc3_footer_en:Disc4_footer_en:MailBox:tax_ats:invval_ats:actv_elec:CustDisc:
// finyear_date1:finyear_date2:showimg:showvat:showdisc:showafterdisc:showtax:
// bank1:acc1:bank2:acc2:bank3:acc3:AddressEn:chkitemquan:msg:offer_type:VAT_Type:
// is_tab3:tab3val:consume_type:img:cnt:img2:contract_no:activity_name:city:branchName:
// sales_man:StartWorkDate:EndWorkDate:CStartWorkDate:CEndWorkDate:firstAlertDate:
// SecondAlertDate:ThirdAlertDate:StopWorkingDate:CStopWorkingDate:is$sys:is$$dessys:
// is$$$dy:server_name:point_name:status_type:computer_type:tax_no_build:
// tax_name_street:tax_name_district:tax_name_city:tax_name_country:tax_zip_code:
// tax_additional_address_no:tax_vat_registration_no:

const elmnts = {
        Foundation:[
          { prop:'nameA',lbl:'الوحدة',tin:0, xs:12,chang:false,auto:[],},
          { prop:'name',lbl:'الوحدة',tin:1, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:2, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:3, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:4, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:5, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:6, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:7, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:8, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:9, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:10, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:11, xs:12,chang:false,auto:[],}, 
          { prop:'name',lbl:'الوحدة',tin:12, xs:12,chang:false,auto:[],}, 
        ],
        Branches:[
          { prop:'name',lbl:'اسم الفرع',tin:0, lg:12, md:12, sm:12, xs:12,chang:false,auto:[],},
          { prop:'Tel',lbl:'تليفون',tin:1, lg:4, md:5, sm:5, xs:5,chang:false,auto:[],}, 
          { prop:'Mobile',lbl:'موبايل',tin:2, lg:5, md:7, sm:7, xs:7,chang:false,auto:[],}, 
          { prop:'Fax',lbl:'فاكس',tin:3, lg:3, md:4, sm:5, xs:4,chang:false,auto:[],}, 
          { prop:'Email',lbl:'البريد الالكتروني',tin:4, lg:12, md:8, sm:7, xs:8,chang:false,auto:[],}, 
          { prop:'Address',lbl:'العنوان',tin:5, lg:12, md:12, sm:12, xs:12,chang:false,auto:[],}, 
          { prop:'notes',lbl:'ملاحظات',tin:6, lg:12, md:12, sm:12, xs:12,chang:false,auto:[],},      
        ],
        Units:[
          { prop:'name',lbl:'الوحدة',tin:0, xs:12,chang:false,
            auto:[], 
          },  
        ],
        Items:[],
        groups:[
          // {prop:'id',lbl:'كود المجموعة',tin:0, xs:12,chang:false,auto:[],},
          {prop:'name',lbl:'مجموعة الصنف',tin:0, xs:12,chang:false,auto:[],},
          {prop:'nameEN',lbl:'مجموعة (EN)',tin:1, xs:12,chang:false,auto:[],},
        ],
        tax_groups:[
          // {prop:'id',lbl:'',tin:0, xs:12,chang:false,auto:[],},
          {prop:'nameAR',lbl:'الاسم عربي',tin:0, xs:12,chang:false,auto:[],},
          {prop:'nameEN',lbl:'الاسم إنجليزي',tin:1, xs:8,chang:false,auto:[],},
          {prop:'value',lbl:'القيمة %',tin:2, xs:4,chang:false,auto:[],},
        ],
        Safes:[
          // {prop:'id',lbl:'رقم المخزن',tin:0, xs:12,chang:false,auto:[],},
          {prop:'name',lbl:'اسم المخزن',tin:0, xs:12,chang:false,
            auto:
            [
              { prop:'branch',lbl:'الفرع',ain:0, xs:7,},
              { prop:'status',lbl:'حالة المخزن',ain:1, xs:5,
                //  check:
                //  [
                //   { prop:'IS_Default',lbl:'المخزن الافتراضي',ain:0, xs:5,},
                //  ],
              },
            ],
          },
          {prop:'notes',lbl:'ملاحظات',tin:1, xs:12,chang:false,auto:[],},
        ],
        Stocks:[
          // {prop:'id',lbl:'رقم الصندوق',tin:0, xs:12,chang:false,auto:[],},
          {prop:'id',lbl:'رقم الحساب',tin:0, xs:4,chang:false,auto:[],},
          {prop:'name',lbl:'اسم الصندوق',tin:1, xs:8,chang:false,
            auto:
            [
              { prop:'branch',lbl:'الفرع',ain:0, xs:7,},
              { prop:'status',lbl:'حالة الصندوق',ain:1, xs:5,
                //  check:
                //  [
                //   { prop:'IS_Default',lbl:'الصندوق الافتراضي',ain:0, xs:5,},
                //  ],
              },
            ],
          },
          {prop:'notes',lbl:'ملاحظات',tin:2, xs:12,chang:false,auto:[],},
        ],
        Banks:[
          {prop:'id',lbl:'رقم البنك',tin:0, xs:5,chang:false,
            auto:
            [
              { prop:'acc_type',lbl:'نوع الحساب',ain:0, xs:7,},
            ]
          },
          {prop:'name',lbl:'اسم البنك',tin:1, xs:8,chang:false,auto:[],},
          {prop:'acc_no',lbl:'رقم الحساب',tin:2, xs:4,chang:true,
            auto:
            [
              { prop:'country',lbl:'الدولة',ain:1, xs:4,
                  // button:
                  // [{ prop:'',lbl:'',ain:0, xs:5,},]
              },
              { prop:'city',lbl:'المدينة',ain:2, xs:4,
                  // button:
                  // [{ prop:'',lbl:'',ain:0, xs:5,},]
              },
              { prop:'area',lbl:'المنطقة',ain:3, xs:4,
                  // button:
                  // [{ prop:'',lbl:'',ain:0, xs:5,},]
              },
            ],
          },
          {prop:'tel',lbl:'تليفون',tin:3, xs:5,chang:false,auto:[],},
          {prop:'mobile',lbl:'جوال',tin:4, xs:7,chang:false,auto:[],},
          {prop:'notes',lbl:'ملاحظات',tin:5, xs:12,chang:false,auto:[],},
        ],
        costcenter:[
          { prop:'Name',lbl:'مركز التكلفة',tin:0, xs:12,chang:false,
            auto:[], 
          },  
        ],
        omlat:[
          { prop:'id',lbl:'رقم العمله',tin:0, xs:5,chang:false,auto:[],}, 
          { prop:'name',lbl:'الاسم عربي',tin:1, xs:5,chang:false,auto:[],}, 
          { prop:'NameEn',lbl:'العمله الاتيني',tin:2,xs:5,chang:false,auto:[],}, 
          { prop:'omla_desc',lbl:'رمز العمله',tin:3,xs:5,chang:false,auto:[],}, 
          { prop:'exchange',lbl:'سعر التحويل',tin:4,xs:5,chang:false,
             auto:
             [
              { prop:'Country',lbl:'الدوله',ain:0, xs:5,}, 
             ],
          },
          { prop:'FakaName',lbl:'اسم الفكه',tin:5, xs:5,chang:false,auto:[],}, 
          { prop:'FakaNameEn',lbl:'الفكه لاتيني',tin:6, xs:5,chang:false,
             auto:
              [
               { prop:'oneEqualto',lbl:'كل 1 = ',ain:1, xs:5,}, 
              ],
          },
          { prop:'ITotalFakaD',lbl:'المكافئ',tin:7, xs:5,chang:false,auto:[],}, 
          { prop:'symbol',lbl:'وصف',tin:8, xs:5,chang:false,
              auto:
              [
                { prop:'IS_Active',lbl:'الحاله',ain:2, xs:5,
                  //  check:
                  //   [
                  //     { prop:'IS_Default',lbl:'العملة الافتراضية',cin:0, xs:5,},
                  //     { prop:'is_main',lbl:'العمله المحلية/الرئيسية',cin:1, xs:5,},
                  //   ]
                },
              ],  
          }, 
        ],
        AccountBooks:[
          { prop:'ID',lbl:'رقم الدفتر',tin:0, xs:5,chang:false,
            auto:[],  
          },  
          { prop:'Name',lbl:'اسم الدفتر',tin:1, xs:7,chang:false,
            auto:[],  
          }, 
        ],
        Acts:[
          { prop:'name',lbl:'اسم المجموعة',tin:0, xs:7,chang:false,
            auto:
            [
            { prop:'type',lbl:'نوع المجوعة',ain:0, xs:5,},
            ]
          },
        ],
        Countries:[
          { prop:'name',lbl:'اسم الدولة',tin:0, xs:6,chang:false,
            auto:[]
          },
          { prop:'nationality',lbl:'اسم الجنسية',tin:1, xs:6,chang:false,
            auto:[]
          },
        ],
        Cities:[
          { prop:'name',lbl:'اسم المدينة',tin:0, xs:7,chang:false,
            auto:
            [
            { prop:'country',lbl:'اسم الدولة',ain:0, xs:5,},
            ]
          },
        ],
        Areas:[
          { prop:'name',lbl:'اسم المنطقة',tin:0, xs:7,chang:false,
            auto:
            [
            // { prop:'kkk',lbl:'اسم الدولة',ain:0, xs:6,},
            { prop:'city',lbl:'اسم المدنية',ain:0, xs:5,},
            ]
          },
        ],
        Managements:[
          { prop:'name',lbl:'إسم الادارة',tin:0, xs:12,chang:false,
            auto:[],
          },  
        ],
        Departments:[
          { prop:'name',lbl:'إسم القسم',tin:0, xs:7,chang:false,
            auto:
            [
              { prop:'manag_id',lbl:'الإدارة',ain:0, xs:5,},
            ]
          },
        ],
        jobs:[
          { prop:'name',lbl:'الوظيفة',tin:0, xs:12,chang:false,
            auto:[], 
          },  
        ],
        CardTypes:[
          { prop:'nameA',lbl:'إسم البطاقة',tin:0, xs:12,chang:false,
            auto:[],  
          },  
        ],  
}

const elmntall = [
  {},
  {},
  {},
  {
    card:
    [
      {},
      {},
      {},
      {},
      {
        grid:
        [
          { prop:'dateFrom',lbl:'من تاريخ',tin:0, lg:12, md:12, sm:12, xs:12,chang:false,auto:[],},
          { prop:'dateTo',lbl:'إلى تاريخ',tin:1, lg:4, md:5, sm:5, xs:5,chang:false,
            auto:
            [
              { prop:'branch',lbl:'الفرع',ain:0, xs:5,},
              { prop:'center',lbl:'مركز التكلفة',ain:1, xs:5,},
              { prop:'accno',lbl:'',ain:2, xs:5,},
            ],
          }, 
        ]  
      },
    ]
  },
]

export {elmnts,elmntall};