import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../../help/Api";
import { Rept, sto } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";

function HeaderA() {
  const cls = useRptAF();
  const iInfo = JSON.parse(localStorage.getItem("iInfos")) || {};
  const [state, setState] = useState({});
  const getInfo = async () => {
    axios
      .get(uri + `Foundation?branch=${sto.branchi}`)
      .then((res) => {
        setState(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid item container className={cls.root}>
      <Grid item xs={5} className={cls.rootsub}>
        <Typography>{state.nameE}</Typography>
        <Typography>{state.FieldE}</Typography>
      </Grid>
      <Grid item xs={2}>
        <ButtonBase>
          <img
            src={`data:image/jpeg;base64,${state.Logo}`}
            className={cls.logo}
            alt={state.nameA}
          />
        </ButtonBase>
      </Grid>
      <Grid item xs={5} className={cls.rootsub}>
        <Typography>{state.nameA}</Typography>
        <Typography>{state.FieldA}</Typography>
      </Grid>

      <Grid item container className={[cls.title, cls.titleS]}>
        <Grid item xs={12}>
          <Typography>
            {" "}
            <h3> {iInfo?.title}</h3>
          </Typography>
        </Grid>
        <Grid item container className={cls.titLin2}>
          <Grid item xs={3}>
            {" "}
            {"رقم الأمر"}
          </Grid>
          {/* <Grid item xs={2}>{'المخزن'}</Grid>
          <Grid item xs={1}> / </Grid> */}
          <Grid item xs={3} className={cls.tlR}>
            {iInfo.no}
          </Grid>
          {/* <Grid item xs={3} className={cls.tlR}>{Rept.auto.safe}</Grid> */}
          <Grid item xs={3}>
            {" التاريخ"}
          </Grid>
          <Grid item xs={3}>
            {iInfo.date}
          </Grid>
        </Grid>

        <Grid item container className={cls.titLin2}>
          <Grid item xs={3}>
            {"المخزن"}
          </Grid>
          <Grid item xs={3}>
            {iInfo.safe_from.name}
          </Grid>
          {/* <Grid item xs={3} >{Rept.auto.user}</Grid> */}
          <Grid item xs={3}>
            {"المخزن المحول إلية"}
          </Grid>
          <Grid item xs={3}>
            {iInfo.safe_to.name}
          </Grid>
        </Grid>

        <Grid item container className={cls.titLin2}>
          <Grid item xs={3}>
            {" البيان"}
          </Grid>
          <Grid item xs={3}>
            {}
          </Grid>
          {/* <Grid item xs={3} >{Rept.auto.user}</Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );

  // return (

  // <Grid item container justifyContent='center' spacing={0}>

  //   <Grid item container className="">

  //   </Grid>

  //   <Grid item container className="">
  //  </Grid>

  //   <Grid item container className="">
  //  </Grid>
  // </Grid>

  // </Grid>
  // );
}
export default HeaderA;
