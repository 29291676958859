import React from "react";
import { useEffect } from "react";
import { insh, sto } from "../../../../../Context/InshData";
import BackdropScI from "../../../../../services/BackdropScI";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { Container, Dialog, DialogContent, Grid } from "@material-ui/core";
import {URL as sandQD } from '../../../../../api/IReceipt';
import {URL as sandSD } from '../../../../../api/IExchange';
import {URL as sandQk } from '../../../../../api/EReceipt';
import {URL as sandSk } from '../../../../../api/EExchange';
import { useMemo } from "react";
import { defaultValues } from "../../../../../services/InernalVoucher-service";
import { IReceiptVoucherLink } from "../internal-receipt-voucher/IRVCon";
import {useStylesDTa, } from '../../../../../St/comps/UseStyle'
import { IExchangeVoucherLink } from "../internal-exchange-voucher/IEVCon";
import { EReceiptVoucherLink } from "../external-receipt-voucher/ERVCon";
import { EExchangeVoucherLink } from "../external-exchange-voucher/EEVCon";

  export const docTypes =[
    {id:1, name:'فاتورة شراء '},
    {id:2, name:'فاتورة بيع'},
    {id:3, name:'مرتجع فاتورة شراء'},
    {id:4, name:'مرتجع فاتورى بيع'},
    {id:5, name:'سند قبض خارجي', isPosible: true, URL:sandQk, },
    {id:6, name:'سند صرف خارجي', isPosible: true, URL:sandSk },
    {id:7, name:'سند قبض داخلي', isPosible: true, URL:sandQD},
    {id:8, name:'سند صرف داخلي', isPosible: true, URL:sandSD},
    {id:9, name:'مخزون اول مدة'},
    {id:10, name:'قيد يومية '},
    {id:11, name:'قيد افتتاحية'},
    {id:12, name:'تبديل مبيعات'},
    {id:13, name:'اقفال ورديات'},
  ]

function GetComponent(props){

  const {docType, data, onClose} = props;

  if(docType == 7)
      return  <IReceiptVoucherLink data={data}  onClose={onClose}/>
  if(docType == 8)
      return  <IExchangeVoucherLink data={data}  onClose={onClose}/>
  if(docType == 6)
      return  <EExchangeVoucherLink data={data}  onClose={onClose}/>
  if(docType == 5)
      return  <EReceiptVoucherLink data={data}  onClose={onClose}/>

  return<></>;
}


export default function DocumetDialog(props){

  const {docOpen, onClose, docType, id} = props;
  const data = docTypes.filter(x=> x.id == docType);
  const clasTa = useStylesDTa();

  const {data: rows, fetching, fetchURL} =useFetchURL({...{
        url: docTypes[docType-1].URL+`?branch=${sto.branchi}
        &&id=${id}
        &&dateFrom=${defaultValues.date}
        &&dateTo=${defaultValues.date}`,
        // dependencyArray:[dateTo, dateFrom]
    }});

    useEffect(()=>{
      console.log("the data is ", props);
      console.log("the data is ", rows);
    })
    if( !fetching && rows)
    return (
      <Dialog fullScreen 
        style={{padding:20}} 
        className={clasTa} open={docOpen} onClose={onClose}>
        <DialogContent >
           <GetComponent {...{docType, data: rows[0], onClose}} />        
        </DialogContent>
      </Dialog>
      // <div></div>
        
    )

  return <></>
}

   