import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";

import { SidebarNav, UpgradePlan } from "./components";
import { inshs, itmes } from "../../../../../Context/Local";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import {
  Assessment,
  AssignmentReturn,
  Category,
  Collections,
  Directions,
  MoveToInbox,
  Receipt,
} from "@material-ui/icons";
import { sto } from "../../../../../Context/InshData";
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 54,
      height: "calc(100% - 54px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: "Dashboard",
      href: "",
      icon: <DashboardIcon />,
    },
    {
      title: "فاتورة مبيعات",
      href: "invoices",
      icon: <LocalMallIcon />,
      insh: inshs.Sales.sal,
      isAllow: sto.used_Nsale_screen,
    },
    {
      title: "مرتجع مبيعات",
      href: "invoices",
      icon: <AssignmentReturn />,
      insh: inshs.Sales.salRet,
      isAllow: sto.used_Rsale_screen,
    },
    {
      title: " شاشة الاصناف",
      href: "products",
      icon: <Collections />,
      insh: itmes,
      isAllow: sto.used_Items_screen,
    },
    {
      title: "تقرير الحركة اليومية",
      href: "ReporteveryDay",
      icon: <Assessment />,
      insh: "",
      isAllow: sto.used_Dialy_screen,
    },
    {
      title: "تقرير كشف الحساب",
      href: "rpted",
      icon: <Assessment />,
      insh: "",
      isAllow: sto.used_Accnt_screen,
    },
    // {
    //   title: "تقرير حركة الاصناف",
    //   href: "products",
    //   icon: <Assessment />,
    //   insh: "",
    //   isAllow: sto.used_itmove_screen,
    // },
    {
      title: " سند قبض خارجي",
      href: "ext-receipt",
      icon: <MoveToInbox />,
      insh: "",
      isAllow: sto.used_Sqabd_screen,
    },
    {
      title: "سند صرف خارجي",
      href: "ext-exchange",
      icon: <Directions />,
      insh: "",
      isAllow: sto.used_SSarf_screen,
    },
    {
      title: "سند قبض داخلي",
      href: "int-receipt",
      icon: <MoveToInbox />,
      insh: "",
      isAllow: sto.used_Sqabd_screen,
    },
    {
      title: "سند صرف داخلي",
      href: "int-exchange",
      icon: <Directions />,
      insh: "",
      isAllow: sto.used_SSarf_screen,
    },
    // {
    //   title: "فاتورة مشتريات",
    //   href: "products",
    //   icon: <ShoppingBasketIcon />,
    //   insh: "",
    // isAllow: sto.used_purch_screen,

    // },
    // {
    //   title: "مرتجع مشتريات",
    //   href: "products",
    //   icon: <Collections />,
    //   insh: "",
    // isAllow: sto.used_Rpurch_screen,

    // },
    {
      title: "فاتورة عرض سعر",
      href: "products",
      icon: <Receipt />,
      insh: inshs.Sales.salViw,

      isAllow: sto.used_Nsale_screen,
    },

    {
      title: "Settings",
      href: "settings",
      icon: <SettingsIcon />,
      isAllow: true,
    },
  ];

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        {/* <Divider className={classes.divider} /> */}
        <SidebarNav className={classes.nav} pages={pages} />

        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
