import React, { useState, useEffect } from 'react';
import useFetchObjectByPost from '../../../../components/Hooks/useFetchObjectByPost';
import { sto } from '../../../../Context/InshData';
import { uri } from '../../../../help/Api';
import MainRBalance from './MainRBalance';
const defaultAutos ={
  Branches:[],
}
export default function MainRBalanceCon() {

      const tb = {
                  wait:true,
                  Branches:true, 
                  costcenters: true,
                  stor:sto,
                  parms:{safe:-1}
            }
        const {object: AutoOptions, fetching:fetchAll} = useFetchObjectByPost(uri+'Rept',tb); 

        // useEffect(()=>{
        //   console.log("the options is", AutoOptions);
        // })
  if( fetchAll || !AutoOptions?.Branches)
    return <></>

  return (
  <MainRBalance {...{
    AutoOptions: AutoOptions?.Branches? AutoOptions: defaultAutos,
  } 
  }/>
  );




};