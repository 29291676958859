import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { NotFound } from "../../views";

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, isAllow, ...rest } = props;
  if (!isAllow)
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Layout>
            <NotFound {...matchProps} />
          </Layout>
        )}
      />
    );

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
