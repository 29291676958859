import React, { useState,useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PrintIcon from '@material-ui/icons/Print';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';
import MaterialTable from "material-table";
import {TableIcons as tableIcons} from "../../../Elemnts/TableIcons";
// import Header from './Header';
// import Footer from './Footer';
import  HeaderC from './Casher/HeaderC';
import  BodyC  from './Casher/BodyC';
import  FooterC from './Casher/FooterC';
import { useHistory } from 'react-router-dom';

// sty
import {useStylesSpDial} from '../../../St/Reviews/UseStyle';
import {stElemnt} from '../../../St/Reviews/cStyle';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import BodyClose from './Casher/BodyClose';

const iInfo = JSON.parse(localStorage.getItem('iInfos')) || {};

class ComponentToPrint extends React.Component {
  state = {} 
  constructor(){ super(); }

  render() {
    return (
    <Container style={stElemnt.Casher.Reviws.continr}> 
      <CssBaseline />
      <Grid lg={3} md={4} sm={7} xs={12}>
        <Typography component="div" style={stElemnt.Casher.Reviws.Typogmain}>
        <HeaderC/>
        <BodyClose/>
        {/* <FooterC/> */}
        </Typography>
      </Grid>
      </Container> 
    );
  }
}


const ReviewEqfalEveryDay = () => {
  const componentRef = useRef();
  const clasSp = useStylesSpDial();

  const [open, setOpen] = useState(true);

  const handleOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(true); };
  let his = useHistory();

  const back = (path) =>
  {
    path ? his.push({ pathname: '/'}) : his.goBack();
  }

  const actions = [
    { icon: <ReactToPrint trigger={() => <PrintIcon /> } content={() => componentRef.current} />, name: 'طباعـة' },
    { icon: <ArrowBackIcon className={clasSp.ico} onClick={()=> back(false)}  />, name: 'رجـوع' },
  ];

  return (
    <div className={clasSp.root}>
      <ComponentToPrint ref={componentRef} />
      <SpeedDial ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial} hidden={false}
        icon={<SpeedDialIcon openIcon={<HomeIcon />} onClick={()=> back(true)} />}
        onClose={handleClose} onOpen={handleOpen} open={open}>
        {actions.map((action,i) => ( <SpeedDialAction key={i}
         icon={action.icon} tooltipTitle={action.name} onClick={handleClose} />))}
      </SpeedDial>
    </div>
  );
};


export default ReviewEqfalEveryDay


