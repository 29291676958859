import React, { useState,useRef,Component, Fragment } from 'react';
import ReactToPrint from 'react-to-print';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PrintIcon from '@material-ui/icons/Print';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import Grid from '@material-ui/core/Grid';
import  HeaderA from './A4/HeaderA';
import  BodyA  from './A4/BodyA';
import  FooterA from './A4/FooterA';
import  HeaderC from './Casher/HeaderC';
import  BodyC  from './Casher/BodyC';
import  FooterC from './Casher/FooterC';
// import Loader from '../Loader';

import {useStylesSpDial} from '../../../St/Reviews/UseStyle';
import {stElemnt} from '../../../St/Reviews/cStyle';



class ComponentToPrint extends Component {

  constructor() { super(); this.state = { } }
  render() {
    const tyeprints = JSON.parse(localStorage.getItem('typPrnt')) || {};

return (
<Fragment>
{tyeprints.tyPrnt == 'A'
 ?<Container maxWidth='xl' style={stElemnt.AF.Reviws.continr}> 
  <CssBaseline />
  <Typography component="div" style={stElemnt.AF.Reviws.Typogmain}>
  <Typography component="div" style={stElemnt.AF.Reviws.Typogsub}>
    <HeaderA/>
    <BodyA/>
    <FooterA/>
    </Typography>
  </Typography>
</Container> 
:<Container style={stElemnt.Casher.Reviws.continr}> 
  <CssBaseline />
    <Grid lg={3} md={4} sm={7} xs={12}>
      <Typography component="div" style={stElemnt.Casher.Reviws.Typogmain}>
      <HeaderC/>
      <BodyC/>
      <FooterC/>
      </Typography>
    </Grid>
  </Container> 
  }
</Fragment>
);

  }
}

const ReviewEveryDay = () => {
  const componentRef = useRef();
  const clasSp = useStylesSpDial();

  const [open, setOpen] = useState(true);
  const handleOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(true); };
  let his = useHistory();

  const back = (path) =>
  {
    path ? his.push({ pathname: '/'}) : his.goBack();


  }

  const actions = [
    { icon: <ReactToPrint trigger={() => <PrintIcon /> } content={() => componentRef.current} />, name: 'طباعـة' },
    { icon: <ArrowBackIcon className={clasSp.ico} onClick={()=> back(false)}  />, name: 'رجـوع' },
  ];

  return (
    <div className={clasSp.root}>
      <ComponentToPrint ref={componentRef} />
      <SpeedDial ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial} hidden={false}
        icon={<SpeedDialIcon openIcon={<HomeIcon />} onClick={()=> back(true)} />}
        onClose={handleClose} onOpen={handleOpen} open={open}>
        {actions.map((action,i) => ( <SpeedDialAction key={i}
         icon={action.icon} tooltipTitle={action.name} onClick={handleClose} />))}
      </SpeedDial>
    </div>
  );
};


export default ReviewEveryDay;


// import React, { useState,useRef,Component } from 'react';
// import ReactToPrint from 'react-to-print';
// import { useHistory } from 'react-router-dom';
// import Typography from '@material-ui/core/Typography';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Container from '@material-ui/core/Container';
// import SpeedDial from '@material-ui/lab/SpeedDial';
// import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
// import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
// import PrintIcon from '@material-ui/icons/Print';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import HomeIcon from '@material-ui/icons/Home';
// import Grid from '@material-ui/core/Grid';

// import  HeaderA from './A4/HeaderA';
// import  BodyA  from './A4/BodyA';
// import  FooterA from './A4/FooterA';

// import {HeaderC} from './Casher/HeaderC';
// import {BodyC} from './Casher/BodyC';
// import {FooterC} from './Casher/FooterC';

// import {useStylesSpDial} from '../../../compsSt/stFatora/UseStyle';

// // import Loader from '../Loader';

// // const location = useLocation();
// // const tyPrnts = JSON.parse(localStorage.getItem('typPrnt')) || {};

// class ComponentToPrint extends Component {
//   constructor(){ super(); 
//     this.state = {} }  
  
//   render() {

//     const style = {
//       backgroundColor: '#fff', height: '100vh' ,
//       color :'black'
//   };

//     return (
//     <Container style={{ backgroundColor: '#fff'}}> 
//       <CssBaseline />
//       <Typography component="div" style={style}>
        
//       <Grid item container>
//       <Typography>
//       {'كيف الحال'}
//       </Typography>
//       </Grid>
      
//       </Typography>
//     </Container>
//     );

//     // return (
//     //   <Container style={{ backgroundColor: '#fff'}}> 
//     //     <CssBaseline />
//     //     <Typography component="div" style={style}>
//     //       <Header />
//     //       <Body/>
//     //       <Footer/>
//     //     </Typography>
//     //   </Container>
//     //   );

//     // return (
//     //   <React.Fragment>
//     //   <CssBaseline />
//     //   {tyPrnts.tyPrnt == 'A'
//     //   ?<Container maxWidth="xl">
//     //     <Typography component="div" style={style}>
//     //      <HeaderA />
//     //      <BodyA />
//     //      <FooterA />
//     //     </Typography>
//     //   </Container>
//     //   :<Container maxWidth="xl">
//     //     <Typography component="div" style={style}>
//     //      <HeaderC />
//     //      <BodyC />
//     //      {/* <FooterC /> */}
//     //     </Typography>
//     //   </Container>
//     //   }
//     // </React.Fragment>
//     // );

//   }
// }

// const ReviewEveryDay = () => {
//   const componentRef = useRef();
//   const clasSp = useStylesSpDial();

//   const [open, setOpen] = useState(true);
//   const handleOpen = () => { setOpen(true); };
//   const handleClose = () => { setOpen(true); };
//   let his = useHistory();

//   const back = (path) => {
//     path ? his.push({ pathname: '/'}) : his.goBack();
//   }

//   const actions = [
//     { icon: <ReactToPrint trigger={() => <PrintIcon /> } content={() => componentRef.current} />, name: 'طباعـة' },
//     { icon: <ArrowBackIcon className={clasSp.ico} onClick={()=> back(false)}  />, name: 'رجـوع' },
//   ];

//   return (
//     <div className={clasSp.root}>
//       <ComponentToPrint ref={componentRef} />
//       <SpeedDial ariaLabel="SpeedDial openIcon example"
//         className={clasSp.speedDial} hidden={false}
//         icon={<SpeedDialIcon openIcon={<HomeIcon />} onClick={()=> back(true)} />}
//         onClose={handleClose} onOpen={handleOpen} open={open}>
//         {actions.map((action,i) => ( <SpeedDialAction key={i}
//          icon={action.icon} tooltipTitle={action.name} onClick={handleClose} />))}
//       </SpeedDial>
//     </div>
//   );
// };


// export default ReviewEveryDay