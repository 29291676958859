import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { colors, Divider } from "@material-ui/core";
import { useStylesTF, useStylesLbl } from "../../../../../St/comps/UseStyle";
import ReceiptVoucherType from "../components/ReceiptVoucher-typeFilter";
import { personTypes } from "../../../../../services/InernalVoucher-service";
import { useTranslation } from "react-i18next";

export default function IReceiptoucherForm(props) {
  const { changAutoCustomers, onChange, AutoOptions, object, formType } = props;
  const clasTF = useStylesTF();
  const claslbl = useStylesLbl();

  const { t } = useTranslation(["forms"]);

  return (
    <React.Fragment>
      <Grid container style={{ padding: 10 }} spacing={2}>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="id"
            label={t("voucher-id")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input, readOnly: true }}
            name="id"
            value={object.id}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="date"
            label={t("date")}
            className={clasTF.rootEDS}
            type="date"
            format="YYYY-MM-DD"
            InputLabelProps={{ shrink: true }}
            name="date"
            value={object.date}
            onChange={(e) =>
              onChange(null, { name: "date", value: e.target.value })
            }
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <Autocomplete
            value={object.emp_person}
            onChange={(event, newValue) => {
              onChange(null, { name: "emp_person", value: newValue });
            }}
            //inputValue={inputValueBranch}
            // onInputChange={(event, newInputValue) => { setInputValueBranch(newInputValue); }}
            id="controllable-states-demo"
            size="small"
            options={personTypes}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}

            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCustomers"
                {...params}
                label={t("type")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="val"
            label={t("amount")}
            className={clasTF.rootEDS}
            style={{ backgroundColor: colors.grey[50] }}
            InputProps={{ className: clasTF.input }}
            name="val"
            value={object.val}
            onChange={(e) =>
              onChange(null, { name: "val", value: e.target.value })
            }
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Autocomplete
            value={object.acc_code}
            onChange={(event, newValue) => {
              changAutoCustomers(newValue);
            }}
            id="controllable-states-demo"
            size="small"
            options={AutoOptions.Customers}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            style={{ backgroundColor: colors.orange[50] }}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCustomers"
                {...params}
                label={t("payment-from-account")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Autocomplete
            value={object.user}
            onChange={(event, newValue) => {
              onChange(null, { name: "user", value: newValue });
            }}
            id="controllable-states-demo"
            size="small"
            options={AutoOptions.Users}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueUsers"
                {...params}
                label={t("received-mr")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Autocomplete
            value={object.center}
            onChange={(event, newValue) => {
              onChange(null, { name: "center", value: newValue });
            }}
            id="controllable-states-demo"
            size="small"
            options={AutoOptions.costcenters}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCostcenter"
                {...params}
                label={t("center")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <ReceiptVoucherType
            {...{
              type: object.type,
              onChange,
            }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          {object.type == "1" ? (
            <Autocomplete
              value={object.stock}
              onChange={(event, newValue) => {
                onChange(null, { name: "stock", value: newValue });
              }}
              id="controllable-states-demoew"
              size="small"
              options={AutoOptions.Stocks}
              style={{ backgroundColor: colors.grey[50] }}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              // style={{  }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valueCostcenter"
                  {...params}
                  label={t("deposited-in-account")}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ className: claslbl.clor }}
                />
              )}
            />
          ) : (
            <Autocomplete
              value={object.bank}
              onChange={(event, newValue) => {
                onChange(null, { name: "bank", value: newValue });
              }}
              id="controllable-states-demo67"
              size="small"
              options={AutoOptions.Banks}
              style={{ backgroundColor: colors.grey[50] }}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              // style={{  }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valueCostcenter"
                  {...params}
                  label={t("deposited-in-account")}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ className: claslbl.clor }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={12}></Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Autocomplete
            value={object.emp}
            onChange={(event, newValue) => {
              onChange(null, { name: "emp", value: newValue });
            }}
            id="controllable-states-demo4"
            size="small"
            options={AutoOptions.Users}
            // getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueSalesmen"
                {...params}
                label={t("delegate")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="notes"
            label={t("note")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input }}
            name="notes"
            value={object.notes}
            onChange={(e) =>
              onChange(null, { name: "notes", value: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
