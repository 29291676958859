import axios from 'axios'
import { uri } from '../help/Api'

export const URL =uri+'Customers';

export function create(data){
    return axios.post(URL, data);
}

export function update(data){
    return axios.put(URL, data);
}
export function remove(id){
    return axios.delete(URL+`?id=${id}`)
}

export default{create, update, remove, URL}