import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import { colors, IconButton } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        minHeight:400

    },
}));

export default function SafesTransferTables(props) {
    const { paramsSubList, 
        rptParams,
        valueAutosSub,
        setValueAutosSub,
        unitsOptions, } = props;


    const stylH = {
        // borderWidth: 1,
        border: "solid 0.6px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 16,
        color: "#fff"
    }
    const stylRo = {
        BorderBottom: "solid 0.5px",
        //borderStyle: "solid",
        borderColor: "gray",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 15,
        color: "#000"
    }

    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    useEffect(() => {
        // console.log("units is", currUnitList)
    }, [paramsSubList]);
    const onRowDelete =async (rowdata) => {
        // setData({...row, id:row.tableData.id.toString()});
        var newData = paramsSubList.filter((obj) => obj.barcode.toString() !== rowdata.barcode.toString());
  
        paramsSubList([...newData]);
    }
    return (
        <TableContainer component={Paper} >
            <Table
                //className={classes.table} 
                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: "#3f51b5" ,
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 17,
                            border: "solid 0.6px"
                        }}
                    >
                        <TableCell  style={stylH} hidden={true} align="center">
                            {"No"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {" الباركود"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الصنف"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الوحدة"}
                        </TableCell>
                        
                        <TableCell style={stylH} align="center">
                            {"الكمية"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الكمية الاجمالية"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الكمية المتوفرة "}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"السعر"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الاجمالي"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"Action"}
                        </TableCell>
                       
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paramsSubList.map((row, i) => (
                        <TableRow key={row.barcode}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                fontWeight: 800,
                                fontFamily: "Amiri-Regular",
                                fontSize: 17,
                                border: "solid 0.6px"
                            }}>
                            <TableCell style={stylRo} hidden={true} align="center">{row.id}</TableCell>
                            <TableCell style={stylRo} align="center">{row.barcode}</TableCell>

                            <TableCell style={stylRo} align="center">{row.symbol}</TableCell>

                            <TableCell style={stylRo} align="center">{row.unitName}</TableCell>
                            <TableCell style={stylRo} align="center" >{row.val1}</TableCell>
                            <TableCell style={stylRo} align="center" >{row.value}</TableCell>
                            <TableCell style={stylRo} align="center" >{row.balance}</TableCell>
                            <TableCell style={stylRo} align="center" >{row.price}</TableCell>
                            <TableCell style={stylRo} align="center" >{row.total}</TableCell>
                            <TableCell style={stylRo} align="center" >
                            <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    style={{ maxHeight: "0.7rem" }}
                                    // onClick={onDelete}

                                onClick={() => {
                                    onRowDelete(row);
                                }}>
                                
                                    <DeleteIcon size={"small"} />
                                </IconButton>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>

            </Table>
            
        </TableContainer>
        
    );
}
