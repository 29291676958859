
// import {locConn}  from '../Context/Locstor';


 const locConn = JSON.parse(window.localStorage.getItem('infoConn')) || {};



  // console.log('== locConn API =='); console.log(locConn);
  const uri = `${locConn.ftp}://${locConn.ip}${locConn.endDigtip}${locConn.port}/api/`;
  // const uri = `${locConn.ftp}://${locConn.ip}${locConn.endDigtip}${locConn.port}/api/`;
  // const useUri = locConn.endDigtip != undefined ? true : false;
  const uriendDigtip = locConn.endDigtip;

  // const uri = 'http://'+locConn.ip+':80/api/'; /*  http */

//  const uri = 'http://localhost:51424/api/'; /* localhost */

// alert('Api uri = ' + useUri);

// alert(uri);

export {uri,uriendDigtip}
