export const getAccountOmlat = (accOMS, omlat) => {
  var oml = omlat?.filter((om) => accOMS?.findIndex((am) => am == om.id) != -1);

  return oml;
};

export const getDefaultOmla = (omlat) => {
  if (!omlat) alert("لا يوجد عملات ");
  else {
    var oml = omlat?.filter((om) => om.IS_Default == true);
    console.log("oml is", oml);
    if (!oml) return omlat[0];
    else return oml[0];
  }
};
