import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { mt } from "../../../../St/comps/cStyle";
import { reFormatDate } from "../../../../utils/utils";
import { insh } from "../../../../Context/InshSt";
import { colors, Container, makeStyles, Table, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function Totals({ data }) {
    const styf = { fontWeight: "1200" };
    const cls = useRptAF();
    const totals = Rept.tot;

    const styleE={
        border: "solid 1px ",
        borderColor: "black",
        textAlign: "center",
        padding: 2,
        fontSize: "0.8rem",
        color: "black",
        fontWeight: "bold",
    };
    const styleTo={
        border: "solid 1px ",
        borderColor: "black",
        textAlign: "center",
        fontSize: "0.9rem",
        fontWeight: "bold",
    };
    return (
        <Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid container lg={12} md={12} className={cls.rootBdydown}>
        <Grid container xs={12} /*className={cls.rootchip}*/>
          <Grid item xs={3} style={styleE}>
           {"رصيد الصندوق للفترة"}
          </Grid>

          <Grid item xs={3} style={styleE}>
           {"رصيد الشبكة"}
          </Grid>
          <Grid item xs={3} style={styleE}>
            {"رصيد البنك"}  
                    </Grid>
          <Grid item xs={3} style={styleE}>
            {"رصيد الآجل"}
          </Grid>
        </Grid>
        <Grid container xs={12} /*className={cls.rootchip}*/>
          <Grid item xs={3} style={styleTo}>
            {totals.totRsedStok.toFixed(2)}
          </Grid>
          <Grid item xs={3}  style={styleTo}>
            {totals.totRsedNetW.toFixed(2)}
          </Grid>
          <Grid item xs={3}  style={styleTo}>
            {totals.totBanks.toFixed(2)}
          </Grid>
          <Grid item xs={3}  style={styleTo}>
            {totals.totAgels.toFixed(2)}
          </Grid>
        
        </Grid>
      </Grid>
      </Grid>
      </Grid>
    );
  }


function BodyDaily({ data, isFullPage, isLast }) {
    const { t } = useTranslation(["forms", "translation"]);
    const cls = useRptAF();
  const cellS = {

    padding: "0px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    border: "solid 0.1px",
    fontSize: "0.8rem",
    width: "150px",
    fontFamily: "Amiri-Regular",
    fontWeight:"bold",
  };

  var columns = [
    { title: "نوع الحركة", field: "title",cellStyle:cellS },
    { title: "عدد الفواتير", field: "counts" },
    { title: "الإجمالي", field: "total" },
    { title: "نقدي", field: "total_cash" },
    { title: "آجل", field: "total_agel" },
    { title: "شبكة", field: "total_network" },
    { title: "بنك", field: "total_bank" },
    { title: "ذمم", field: "total_emp" },
  ];

  const newData = data.map((row, i) => {
    return {
      ...row,
      total: parseFloat(row.total).toFixed(2),
      total_cash: parseFloat(row.total_cash).toFixed(2),
      total_agel: parseFloat(row.total_agel).toFixed(2),
      total_network: parseFloat( row.total_network).toFixed(2),
      total_bank: parseFloat( row.total_bank).toFixed(2),
      total_emp: parseFloat( row.total_emp).toFixed(2),
    };
  });
  return (
    <Grid item container style={{fontFamily: "Amiri-Regular"}}>
      <Grid item container style={{ direction: "rtl",paddingTop:"0.7rem"}}>
        <Grid
          item
          xs={12}
         
        >
          <MaterialTable
            style={{ boxShadow: "none" }}
            // style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              headerStyle: {
                ...stElemnt.AF.header,
                backgroundColor: colors.grey[100],
                fontWeight: "bold",
                paddingTop: "2px",
                paddingBottom:"2px",
                border: "solid 0px",
                border: "solid 0.1px",
               
                
              },
              paging: false,
              cellStyle: {
                ...stElemnt.AF.cell,
                padding: "0px 1px",
                // fontSize: 8,
              },
            
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h5 style={{ color: "GrayText" }}>
                    لا توجد ارصدة بعد في هذا الحساب
                  </h5>
                ),
              },
            }}
            columns={columns}
            data={newData}
          />
          {isLast && <Totals data={data ?? []} />}
        </Grid>
      </Grid>
   
      
    </Grid>
  );
}

export default BodyDaily;
