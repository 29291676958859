import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { mt } from "../../../../St/comps/cStyle";
import { reFormatDate } from "../../../../utils/utils";
import { insh } from "../../../../Context/InshSt";
import TotalsActivity from "./TotalAct";
import { colors, Container, makeStyles, Table, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";



function BodyActivity({ data, isFullPage, isLast }) {
    const { t } = useTranslation(["forms", "translation"]);
    const cls = useRptAF();
  const cellS = {

    padding: "0px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    border: "solid 0.1px",
    fontSize: "0.55rem",
    width: "150px",
    fontFamily: "Amiri-Regular",
    fontWeight:"bold",
  };

  var columns = [
    { title: "المخزن", field: "safe" },
    { title: "نوع العملية", field: "optype" },
    { title: "التاريخ", field: "ddate",
        render: (expense) => reFormatDate(expense.date)
     },
    { title: "رقم الفاتورة", field: "bill_no" },
    { title: "الصنف", field: "item_name" },
    { title: "الكمية", field: "qty" },
    { title: "السعر", field: "price" },
    { title: "الضريبه", field: "tax" },
    { title: "الاجمالي", field: "total" },
    //{ title: "المستخدم", field: "uuser" },
  ];

  const newData = data.map((row, i) => {
    return {
      ...row,
      price: parseFloat(row.price).toFixed(2),
      tax: parseFloat(row.tax).toFixed(2),
      total: parseFloat( row.total).toFixed(2),
    };
  });
  return (
    <Grid item container style={{fontFamily: "Amiri-Regular"}}>
      <Grid item container style={{ direction: "rtl",paddingTop:"0.7rem"}}>
        <Grid
          item
          xs={12}
         
        >
          <MaterialTable
            style={{ boxShadow: "none" }}
            // style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              headerStyle: {
                ...stElemnt.AF.header,
                backgroundColor: colors.grey[100],
                fontWeight: "bold",
                paddingTop: "2px",
                paddingBottom:"2px",
                border: "solid 0px",
                border: "solid 0.1px",
               
                
              },
              paging: false,
              cellStyle: {
                ...stElemnt.AF.cell,
                padding: "0px 1px",
                // fontSize: 8,
              },
            
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h5 style={{ color: "GrayText" }}>
                    لا توجد ارصدة بعد في هذا الحساب
                  </h5>
                ),
              },
            }}
            columns={columns}
            data={newData}
          />
          {isLast && <TotalsActivity data={data ?? []} />}
        </Grid>
      </Grid>
   
      
    </Grid>
  );
}

export default BodyActivity;
