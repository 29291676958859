import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useInvAF, useInvMain } from "../../../St/Reviews/UseStyle";
import { Container, Divider, makeStyles } from "@material-ui/core";
import { txtA, txtE } from "../../../Label/Lbl";
import moment from "moment";
import { useBody, useHeader } from "./style";

function Header(props) {
  const { locInv, sta, found } = props;
  const clasm = useInvMain();
  const clas = useInvAF();
  const calssBody = useBody();
  const classHeader = useHeader();
  // const clasH = useInvHeadrAF();
  const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};

  const secondColor = "#31b835";
  // const secondColor = "#be151f";
  const styf = { fontWeight: "1200" };
  const stysub = { fontWeight: "bold", color: secondColor };
  const styfC = { fontWeight: "500" };
  // useEffect(() => { الجوال : 0535000595
  //   setTimeout(async () => { Phone Number : 0535000595
  //     await getInfo();
  //     // onUnmountComplete();
  //   }, 0);
  // }, []);

  return (
    <Fragment>
      <Grid
        item
        container
        className={classHeader.headerContainer}
        style={{ padding: 5, fontWeight: "bold" }}
      >
        <Grid item xs={5} style={{ textAlign: "center" }}>
          {/* <Typography variant="h6" gutterBottom>
              {info.nameE}
            </Typography> */}
          <div
            key="109"
            style={{ backgroundColor: "#ff0000" }}
            data-grid={{ w: 2, h: 3, x: 0, y: 0 }}
          >
            <div style={styf}>{found.Disc1_header_en}</div>
            <div style={stysub}>{found.Disc2_header_en}</div>
            <div style={styfC}>{found.Disc3_header_en}</div>{" "}
            <div style={styfC}>{found.Disc4_header_en}</div>
          </div>
        </Grid>

        <Grid item xs={2} className={clasm.mincntr}>
          <div>
            <ButtonBase>
              {" "}
              <img
                src={`data:image/jpeg;base64,${found.Logo}`}
                className={clas.Hedlogo}
                alt={found.nameA}
              />{" "}
            </ButtonBase>
          </div>
        </Grid>

        <Grid item xs={5} className={classHeader.rightHeader}>
          {/* <Typography style={styf} variant="h5" gutterBottom>
            {found.nameA}
          </Typography> */}
          <div style={styf}>{found.Disc1_header_ar}</div>{" "}
          <div style={stysub}>{found.Disc2_header_ar}</div>
          <div style={styfC}>{found.Disc3_header_ar}</div>{" "}
          <div style={styfC}>{found.Disc4_header_ar}</div>
        </Grid>
      </Grid>
      <Container>
        <Divider style={{ background: secondColor, height: 1.5 }} />
        <Divider
          style={{ background: secondColor, marginTop: 3, height: 1.5 }}
        />
      </Container>
      <Container>
        <Grid
          container
          item
          // className={clas.Bdyroot}
          xs={12}
          style={{ direction: "rtl", marginTop: "0.2rem" }}
        >
          <Grid xs={3} item container style={{ paddingTop: 10 }}>
            <Grid item xs={6} className={calssBody.txt}>
              التاريخ
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {moment(sta?.tot?.date).format("YYYY/MM/DD")}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={6} item>
            {locInv.ProcTypeInvProc != "03" && (
              <Typography
                className={calssBody.minclr}
                variant="h5"
                gutterBottom
              >
                {/* {info.nameA} */}
                {sta?.tot?.tax_no_cust === ""
                  ? "فاتورة ضريبية مبسطة"
                  : "فاتورة ضريبية"}
              </Typography>
            )}
            <Typography className={calssBody.minclr} variant="h6" gutterBottom>
              {locInv.invN + " "}
              {!premiApp?.hidePayType
                ? sta?.tot?.inv_type_name + " " + sta?.tot.pay_type_name
                : ""}
              {premiApp?.hidePayType && sta?.tot?.inv_type_name == "نقدية"
                ? sta?.tot?.inv_type_name
                : ""}
            </Typography>
          </Grid>
          <Grid
            xs={3}
            item
            container
            style={{
              paddingTop: 10,
              paddingLeft: 10,
              direction: "ltr",
              color: secondColor,
            }}
          >
            <Grid item xs={6}>
              <Typography
                className={calssBody.txt}
                style={{ fontWeight: "bold" }}
              >
                Inv No
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {sta?.tot?.id}
              </Typography>
            </Grid>
            <Grid item xs={6} className={calssBody.txt}>
              Ref No
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {sta?.tot?.refno}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container style={{ marginTop: 15 }}>
            <Grid
              item
              xs={12}
              className={calssBody.txt}
              container
              style={{ fontWeight: "bold" }}
            >
              <Grid item xs={1}>
                {txtA.custmName + "/ "}
              </Grid>
              <Grid item xs={5}>
                {sta?.tot?.cust_name}
              </Grid>
              <Grid item xs={1}>
                {"العنوان " + "/ "}
              </Grid>
              <Grid item xs={5}>
                {sta?.tot?.cust_address}
              </Grid>
            </Grid>
            {/* <Grid item xs={2}></Grid> */}
            <Grid
              item
              xs={12}
              container
              className={calssBody.txt}
              style={{ fontWeight: "bold", paddingTop: 5 }}
            >
              <Grid item xs={2}>
                {txtA.taxNumr + "/ "}
              </Grid>
              <Grid item xs={4}>
                {sta?.tot?.tax_no_cust}
              </Grid>
              <Grid item xs={1}>
                {"الهاتف " + "/ "}
              </Grid>
              <Grid item xs={5}>
                {sta?.tot?.cust_mobile}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}
export default Header;
