import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    useStylesUp, useStylesHed, useStylesAuto,useStylesAutoPurch,useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,useStylesAutoSafe
} from '../../../../St/Reports/UseStyle';
import { typeCosts, typeQties } from "../../../../services/safe-grd-services";

export default function SafesGardForms(props) {
    const {
        AutoOptions,
        rptParams,
        onChangeData,
        onChangeAutoData,
        printHandle,
    } = props;

    const { t } = useTranslation(["forms"]);

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();
    const clasASafe = useStylesAutoSafe();



    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasTD = useStylesTD();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    return (
        <React.Fragment>
            <Grid container style={{ padding: 5 }} spacing={1}>
            <Grid item lg={2} md={2} sm={3} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasASafe}
                        value={rptParams.safe}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('safe', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={AutoOptions.Safes}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="المخزن" variant="outlined" size="small"
                            InputLabelProps={{ className:claslbl.clrSafe } } />}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasASafe}
                        value={rptParams.typeCost}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('typeCost', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={typeCosts}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="طريقة التقييم " variant="outlined" size="small"
                            InputLabelProps={{className:claslbl.clrSafe }} />}
                    />


                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasASafe}
                        value={rptParams.typeQtys}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('typeQtys', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={typeQties}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="فرز حسب" variant="outlined" size="small"
                            InputLabelProps={{className:claslbl.clrSafe }} />}
                    />


                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasASafe}
                        value={rptParams.branch}
                        onChange={(event, newValue) => {
                            onChangeAutoData("branch", newValue);
                        }}
                        id="combo-box-demo"
                        size="small"
                        options={AutoOptions.Branches}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }

                        style={{}}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("branch")}
                                variant="outlined"
                                InputLabelProps={{ className:claslbl.clrSafe } }
                            />
                        )}
                        
                    />
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">نوع الصنف</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={rptParams.isStore}
                    onChange={onChangeData}
                    name="isStore"
                  />
                }
                size="small"
                color="primary"
                fontSize="small"
                label="مخزني"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={rptParams.isRaw}
                    onChange={onChangeData}
                    name="isRaw"
                  />
                }
                label="خام"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={rptParams.isFactory}
                    onChange={onChangeData}
                    name="isFactory"
                  />
                }
                label="مصنعي"
              />
            </FormGroup>
          </FormControl>
        </Grid>
            </Grid>
        </React.Fragment>
    );
}
