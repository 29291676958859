import React from 'react';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
// import CastConnectedIcon from '@material-ui/icons/CastConnected';
import CallIcon from '@material-ui/icons/Call';
import RefreshIcon from '@material-ui/icons/Refresh';

import Paper from '@material-ui/core/Paper';
// import Card from '@material-ui/core/Card';

import Image from '../assets/img/Repay.png'; // Import using relative path

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
   // backgroundColor: theme.palette.secondary.main,
   // width: '55%',
   width: '80%',
   height: '80%',
  },
  AvatarSpan: {
    width: '80%',
    height: '80%',
    textAlign: '-webkit-center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submitContent: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#d9edff',
    color:'red',
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  large: {
  width: theme.spacing(7),
  height: theme.spacing(7),
  width: '85%',
    height: '178px',
},

}));

const styLogo = {
  backgroundImage: `url(${Image})`,
  height: '213px',
  boxShadow:'none',
  width:'75%',
};

function refreshPage() { window.location.reload(false); }


export default function RePay() {
  const classes = useStyles();
  let his = useHistory();

  function reCon() {
    // window.location.reload(false);
    his.replace({ pathname: '/' })
  }

  return (
    <React.Fragment>
    <CssBaseline />
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {/* <Avatar src="../assets/img/cont1.svg" className={classes.avatar} /> */}

        <span className={classes.AvatarSpan}>
        <Paper style={styLogo} >  </Paper>
        </span>
        

<br/>
        <Typography component="h1" variant="h5">
        
        {/* background-color: #ffa25c59;
    color: red; */}

        <Button
        style={{backgroundColor: '#ffa25c59', color:'red',}}
            fullWidth
            disabled
            variant="contained"
            className={classes.submitContent}
            color="secondary"

          >

{`
انتهى العقد الخاص المقدم من شركة أوراكس
      قم بالتواصل مع [الدعم الفني]
 `}

          </Button>

        </Typography>

        <Grid container ex={12} justify="center">
            <Button  
            className={classes.submit}
            color="primary"
            size="medium"
            href="tel:+966552661598"
            startIcon={<CallIcon />}
            >
            {'إضغط للإتصال'}
            </Button>
            </Grid>
            <br/>


            <Grid container ex={12} justify="center">

            <IconButton  variant="contained" color="secondary" onClick={reCon} aria-label="home">
              <RefreshIcon />
            </IconButton>

            </Grid>
  
      </div>
 
    </Container>
    </React.Fragment>
  );
}

