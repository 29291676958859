import React, { useState, useEffect } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import { sto } from "../../../../Context/InshData";
import { useLocation } from "react-router-dom";

function FooterA() {
  const cls = useRptAF();
  const [state, setState] = useState({});
  const loc = useLocation();
  const getInfo = async () => {
    axios
      .get(uri + `Foundation?branch=${sto.branchi}`)
      .then((res) => {
        setState(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid
      item
      container
      className={cls.rootFotr}
      style={{ textAlign: "center", direction: "rtl" }}
    >
      <Grid xs={12}>
        {" "}
        <Typography className={cls.line}></Typography>{" "}
      </Grid>
      <Grid xs={12}>
        {" "}
        <Typography
          className={cls.titleF}
          style={{ fontFamily: "Amiri-Regular" }}
        >
          {state.Address}
        </Typography>{" "}
      </Grid>

      <Grid item container className={cls.subF} xs={12}>
        <Grid xs={1} className={cls.infoF}>
          <Typography style={{ fontFamily: "Amiri-Regular" }}>هاتف:</Typography>
        </Grid>
        <Grid xs={3} className={cls.infoF}>
          <Typography>{state.Tel}</Typography>
        </Grid>
        <Grid xs={1} className={cls.infoF}>
          <Typography style={{ fontFamily: "Amiri-Regular" }}>
            مبايل:
          </Typography>
        </Grid>
        <Grid xs={3} className={cls.infoF}>
          <Typography>{state.Mobile}</Typography>
        </Grid>
        <Grid xs={1} className={cls.infoF}>
          <Typography style={{ fontFamily: "Amiri-Regular" }}>فاكس:</Typography>
        </Grid>
        <Grid xs={3} className={cls.infoF}>
          <Typography>{state.Fax}</Typography>
        </Grid>
      </Grid>
      <Grid item container style={{ direction: "rtl" }}>
        <Grid item xs={3}>
          {"المستخدم"}
        </Grid>
        <Grid item xs={3}>
          {sto.usernames}
        </Grid>
        <Grid item xs={3}>
          {"تاريخ الطباعة"}
        </Grid>
        <Grid item xs={3}>
          {loc?.state?.nDate}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default FooterA;
