import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  colors,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";

import toast, { Toaster } from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";
import { insh, sto } from "../../Context/InshData";
import _ from "lodash";
import axios from "axios";
import { uri } from "../../help/Api";
import { useState } from "react";
import { useStylesTD, useStylesTF } from "../../St/comps/UseStyle";
import { useStyleC } from "../../Fronts/Menues/st/UseStyle";
import { style } from "../../Fronts/Menues/st/cStyle";
import { useEffect } from "react";
import { CloudDownload } from "@material-ui/icons";
import Swipeable from "../../Fronts/Main/Swipeable";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          style={{ height: 30 }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function ConfirmPassword({ onClose, others }) {
  const [password, setPassword] = useState("");

  useEffect(() => {
    console.log("the items is", sto);
  });
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const checkPassword = () => {
    axios
      .get(
        uri +
          `BackupDB/checkPass?type=${2}&&userId=${sto.idi}&&pass=${password}`
      )
      .then((response) => {
        onClose(password);
      })
      .catch((err) => {
        console.log(err);
        toast.error("كلمة المرور غير صحيحة");
      });
  };

  return (
    <Dialog
      open={true}
      // onClose={onClose}
      id="ringtone-menu"
      maxWidth={"xs"}
      {...others}
    >
      <DialogContent
        style={{
          textAlign: "center",
          paddingTop: "20px",
          paddingLeft: "45px",
          // border: "solid 1px black",
          height: 100,
        }}
        id="alert-dialog-slide-description"
      >
        <Container justifyContent="center">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id={"password"}
            label={"كلمة المرور"}
            className={clasTF.rootSale}
            InputProps={{ className: clasTD.input }}
            type="password"
            name={"password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={checkPassword}>
          دخول
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function BackupDataBase(props) {

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = React.useState(0);
  // const [total, setTotal] = React.useState(0);

  const [confirmed, setConfirmed] = useState(false);
  const [password, setPassword] = useState("");
  const classes = useStyleC();
  const dowloadFile = () => {
    setLoading(true);
    axios({
      url: uri + `BackupDB/getDB?userId=${sto?.idi}&&pass=${password}`,
      method: "GET",
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        console.log("the total is", progressEvent);
        const total = parseFloat(
          progressEvent?.total
          // progressEvent.currentTarget?.responseHeaders["Content-Length"]
        );
        console.log("the total is", progressEvent);

        const current = progressEvent.loaded;
        let percentCompleted = Math.floor((current / total) * 100);
        setProgress(percentCompleted);

        console.log("completed", percentCompleted);
      },
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "database" + ".zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (!confirmed)
    return (
      <>
        <Container style={{ direction: "rtl" }}>
          <Toaster />
          <ConfirmPassword
            keepMounted
            onClose={(pass) => {
              setPassword(pass);
              console.log("the password is", pass);
              setConfirmed(true);
            }}
          />
        </Container>
        <Swipeable />
      </>
    );
  return (
    <Container maxWidth="sm" style={{ paddingTop: "100px" }}>
      <Fragment>
        {!loading ? (
          <Card
            className={classes.root}
            style={{ backgroundColor: colors.blueGrey[600] }}
          >
            <CardActionArea onClick={dowloadFile} disabled={loading}>
              <CardMedia className={classes.media} title="">
                {
                  <CloudDownload
                    className={classes.iconCard}
                    style={style.color.clouddone}
                  />
                }
              </CardMedia>
              <CardContent
                // className={classes.content}
                style={{ textAlign: "center" }}
              >
                <Typography
                  variant="h5"
                  style={{ ...style.color.clouddone, ...{ marginBottom: 18 } }}
                >
                  {"اضغط هنا لجلب قاعدة البيانات"}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions
              disableSpacing
              className={classes.actions}
            ></CardActions>
          </Card>
        ) : (
          <Grid
            container
            item
            spacing={4}
            justifyContent="center"
            style={{ marginTop: 10 }}
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </Grid>
        )}
      </Fragment>
      <Swipeable />
    </Container>
  );
}
