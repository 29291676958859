import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { Chip, colors, Divider } from "@material-ui/core";
import {
  useStylesTF,
  useStylesTD,
  useStylesContainers,
  useStylesHed,
  useStylesLbl,
} from "../../../St/comps/UseStyle";
import { payTypes } from "../../../services/global-services";
import { useTranslation } from "react-i18next";

export default function EmpoyeesForm(props) {
  const { object, data, onChange } = props;
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const clsConts = useStylesContainers();
  const claslbl = useStylesLbl();
  const { t } = useTranslation(["forms", "translation"]);

  const onChangeData = (prop) => (event) => {
    onChange(event, { name: prop, value: event.target.value });
  };

  return (
    <Grid container lg={12} md={12}>
      {/* <Chip size="large" className={clashd.ChipHdCustomer} label={"بيانات العميل"} /> */}
      <Grid item container spacing={1} xs={12} className={[clsConts.rootup]}>
        <React.Fragment>
          <Grid item lg={5} md={6} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"name"}
              label={t("اسم الموظف")}
              className={clasTF.rootSale}
              style={{ backgroundColor: colors.blueGrey[50] }}
              InputProps={{ className: clasTF.inputSale }}
              type="text"
              name={"name"}
              value={object.name}
              onChange={onChangeData("name")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"acc_no"}
              label={t("forms:account-code")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              disabled={true}
              name={"acc_no"}
              style={{ backgroundColor: colors.grey[50] }}
              value={object.acc_no}
              onChange={onChangeData("acc_no")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <Autocomplete // classes={clasAo}
              value={object.manag}
              onChange={(event, newValue) => {
                onChange(null, { name: "manag", value: newValue });
              }}
              id="controllable-states-dem"
              size="small"
              options={data?.Managements}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valuePricePay"
                  {...params}
                  label={t("الإدارة")}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <Autocomplete // classes={clasAo}
              value={object.dep}
              onChange={(event, newValue) => {
                onChange(null, { name: "dep", value: newValue });
              }}
              id="controllable-states-dem"
              size="small"
              options={data?.Departments.filter((d) => d.i == object.manag?.id)}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valuePricePay"
                  {...params}
                  label={t("القسم")}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <Autocomplete // classes={clasAo}
              value={object.state}
              onChange={(event, newValue) => {
                onChange(null, { name: "state", value: newValue });
              }}
              id="controllable-states-dem"
              size="small"
              options={[
                { id: 1, name: "نشط" },
                { id: 2, name: "متوقف" },
              ]}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valuePricePay"
                  {...params}
                  label={t("الحالة")}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <Autocomplete // classes={clasAo}
              value={object.job}
              onChange={(event, newValue) => {
                onChange(null, { name: "job", value: newValue });
              }}
              id="controllable-states-dem"
              size="small"
              options={data?.jobs}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valuePricePay"
                  {...params}
                  label={t("الوظيفة")}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>

          <Grid item lg={3} md={3} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"mobile"}
              label={t("forms:mobile-number")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"mobile"}
              value={object.mobile}
              onChange={onChangeData("mobile")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider
              style={{ marginTop: "10px", backgroundColor: "#4a82b9" }}
            ></Divider>
            <Chip
              label=" فروع الموظف"
              color="primary"
              style={{ marginTop: -10, backgroundColor: "#4a82b9" }}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={0} xs={0}></Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Autocomplete
              multiple // classes={clasAo}
              value={object?.branches}
              onChange={(event, newValue) => {
                const index = object?.branches?.findIndex(
                  (x) => x.name == event.target.textContent
                );
                console.log("event", index);

                if (index == -1)
                  onChange(null, { name: "branches", value: newValue });
              }}
              id="controllable-states-dem"
              size="small"
              options={data?.Branches}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              style={{ direction: "ltr", backgroundColor: colors.blueGrey[50] }} // disabled={}
              //disabled={statu Dis.pricePay}
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valuePricePay"
                  {...params}
                  label={"الفروع"}
                  variant="outlined"
                  size="large"
                />
              )}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider style={{ margin: "10px", backgroundColor: "#4a82b9" }} />
          </Grid>

          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"insurance_no"}
              label={t("رقم التأمينات")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTF.inputSale }}
              name={"insurance_no"}
              value={object.insurance_no}
              onChange={onChangeData("insurance_no")}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="datef"
              label="تاريخ الميلاد"
              type="date"
              format="YYYY-MM-DD"
              name="datef"
              value={object.birth_date}
              onChange={onChangeData("birth_date")}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="work_date"
              label="تاريخ التعيين"
              type="date"
              format="YYYY-MM-DD"
              name="work_date"
              value={object.work_date}
              onChange={onChangeData("work_date")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={4}>
            <Autocomplete
              value={object.nationality}
              onChange={(event, newValue) => {
                onChange(null, { name: "nationality", value: newValue });
              }}
              // style={{backgroundColor: colors.grey[50]}}
              id="controllable-states-demo"
              size="small"
              options={data?.Countries}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t("الجنسية")}
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ className: claslbl.clrSale }}
                />
              )}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider style={{ margin: "10px", backgroundColor: "#4a82b9" }} />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tel"}
              label={t("رقم الموبايل")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"tel"}
              value={object.tel}
              onChange={onChangeData("tel")}
            />
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              style={{ backgroundColor: colors.grey[50] }}
              id={"address"}
              label={t("العنوان")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"address"}
              value={object.address}
              onChange={onChangeData("address")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"notes"}
              label={t("forms:note")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"notes"}
              value={object.notes}
              onChange={onChangeData("notes")}
            />
          </Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}
