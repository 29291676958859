import React from "react";
import { useStyles } from "./sub-taswya-style";
import { Box, Grid, LinearProgress, Typography } from "@material-ui/core";
import SubTaswyaForm from "./sub-taswya-form";
import SubTaswyaTable from "./sub-taswya-table";
import { useState } from "react";
import { uri } from "../../../../../help/Api";
import { useEffect } from "react";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { defaultValues } from "../../../../../services/sub-taswya-service";
import {
  dfaultfilters,
  getReFormatingGtys,
} from "../../../../../services/taswya-servece";
import { BarLoader, ScaleLoader } from "react-spinners";
import LoadingPage from "../../../../../services/LoadingPage";

export default function SubTaswya(props) {
  const { onChange, fetching, object } = props;
  const rows = props.object.rows;

  const {
    data,
    fetching: fetchingGtys,
    fetchURL,
  } = useFetchURL({
    ...{
      url:
        uri +
        `TaswyaBasicData/TaswyaGtys?typeQtys=${dfaultfilters.filter.id}
      &&safe=${object.safe?.id}
      &&branch=${dfaultfilters.branch}
      &&group_id=${dfaultfilters.group.id}`,
      mapFunction: getReFormatingGtys,
      dependencyArray: [object.safe],
    },
  });

  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const [currentRow, setCurrentRow] = useState(defaultValues);

  const addRow = (row) => {
    row.id = Date.now();
    onChange(null, { name: "rows", value: [...rows, row] });
    setCurrentRow(defaultValues);
  };

  const deleteRow = (id) => {
    onChange(null, {
      name: "rows",
      value: rows.filter((row) => row.id !== id),
    });
  };

  const selectRow = (row) => {
    setEditing(true);
    setCurrentRow({ ...row });
  };

  const updateRow = (id, updateRow) => {
    setEditing(false);
    onChange(null, {
      name: "rows",
      value: rows.map((row) => (row.id === id ? updateRow : row)),
    });
    setCurrentRow(defaultValues);
  };
  const cancelUpdate = () => {
    setCurrentRow(defaultValues);
    setEditing(false);
  };

  return (
    <container className={classes.main}>
      <Grid item className={classes.root} container>
        {fetchingGtys || !data || fetching ? (
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  paddingTop: 70,
                  textAlign: "center",
                }}
              >
                <Typography>
                  {" "}
                  <ScaleLoader width={6} height={45} color="#0000ff" />{" "}
                </Typography>
                {/* <LoadingPage /> */}
              </Box>
            </Box>
          </Grid>
        ) : (
          <>
            <SubTaswyaForm
              {...{
                addRow,
                editing,
                setEditing,
                currentRow,
                setCurrentRow,
                updateRow,
                cancelUpdate,
                data,
              }}
            />
            <SubTaswyaTable
              {...{
                rows,
                selectRow,
                deleteRow,
                loading: false,
              }}
            />
          </>
        )}
      </Grid>
    </container>
  );
}
