import React from "react";
import MaterialTable from "material-table";
import { useEffect } from "react";
import { Button } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import {cellS,headerS,stElemnt} from '../../../../../St/comps/cStyle'
import { useTranslation } from "react-i18next";

const defaultData ={
    curr: '',
    unit: '',
    unitName: '',
    perc: '',
    purch: '',
    sale:'',
    barcode: '',
    unit_convert: '',
  }

export default function KhamTable(props) {
    const { useState } = React;
    const {t} = useTranslation();
    const {dataList, selectRowHandle} = props;
  
    const headerS = {
        height: "20px",
        color: "#fff",
        textAlign: "-webkit-center",
        margin: "0px",
        whiteSpace: "nowrap",
        width: "0px",
        backgroundColor: "#f18584",
        padding: "15px 0px",
        marginLeft: 1,
        marginRight: 1,
        // display:'none'
      };

    const columns=[
        {title: "item", field: "item", hidden: true},
        // {title: "unit", field: "item", hidden: true},
        {
            title: t('forms:item'),
            field: 'item.symbol',
            headerStyle: { paddingTop:'0px',paddingBottom:'0px', } 
        },
        {
            title: t('forms:unit'),
            field: 'unit.name',
            headerStyle: { paddingTop:'0px',paddingBottom:'0px', } 
        },
        { title: t('forms:quanity'), field: 'quan', 
        headerStyle: { paddingTop:'0px',paddingBottom:'0px',},  cellStyle:{ } },    
        { title: t('forms:price'), field: 'price',  headerStyle: { paddingTop:'0px', paddingBottom:'0px',margin:'0px',}},
    ];

    useEffect(()=>{
      // console.log("units is", dataList)
    },[dataList]);

    return (
      < >
      <MaterialTable
      style={{boxShadow:'none'}}
        title="Editable Preview"
        options={{
            // showTitle: false,
            search: false,
            toolbar: false,
            pageSize: 5,
            tableLayout: 'fixed',
            showTitle:false,
            padding: "dense",
            paging: false,  
            // headerStyle: headerS,
            headerStyle:{...headerS,fontWeight:"bold"},
            cellStyle:{...cellS,fontWeight:"bold"},

            maxBodyHeight: '30vh',
            minBodyHeight: '30vh',

       }}
       
        columns={columns}
        data={dataList}
        onRowClick={(event, rowData) => {
            selectRowHandle(rowData);
            // event.stopPropagation();
          }}
      />
      </>
      
    )
  }
  