export const typeCosts = [
  { id: 0, name: "متوسط التكلفة" },
  { id: 1, name: "اخر سعر شراء" },
  { id: 2, name: "اخر سعر توريد" },
  { id: 3, name: "سعر الشراء من الأصناف" },
];

export const typeQties = [
  { id: 0, name: "الكل" },
  { id: 1, name: "الاصناف ما عدا السالبة" },
  { id: 2, name: "الأصناف ما عدا الصفرية" },
  { id: 3, name: "الاصناف ما عدا السالبة والصفرية" },
  { id: 4, name: "الاصناف السالبة فقط" },
  { id: 5, name: "الاصناف الصفرية فقط" },
  { id: 6, name: "الأصناف السالبة والصفرية فقط" },
];
export const defaultGRDparms = {
  typeCost: typeCosts[0],
  safe: { id: -1, name: "الكل" },
  typeQtys: typeQties[0],
  isStore: true,
  isFactory: false,
  isRaw: false,
};
