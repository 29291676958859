import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  colors,
  CircularProgress,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoneyIcon from "@material-ui/icons/Money";
import { BeatLoader } from "react-spinners";
import { MoneyOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "120px",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
    marginBottom: 20,
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
}));

const Budget = (props) => {
  const {
    className,
    title,
    purchIcon,
    total,
    curr,
    color,
    fetching,
    icon,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item spacing={1}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              {title}
            </Typography>
            <Grid container justifyContent="center">
              <Grid item container justifyContent="center" xs={12}>
                <BeatLoader loading={fetching} color={colors.red[900]} />
                {!fetching && (
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    {" "}
                    {total} <span variant="h6">{curr}</span>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Avatar
              className={classes.avatar}
              style={{ backgroundColor: color ? color : colors.green[600] }}
            >
              {purchIcon ? (
                <MoneyOff className={classes.icon} />
              ) : (
                <MoneyIcon className={classes.icon} />
              )}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
};

export default Budget;
