import React from "react";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import { IconButton } from "@material-ui/core";
import { UnfoldLess, UnfoldMore } from "@material-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const UserPermissionsTree = (props) => {
  const { data, setCheckedKeys, defaultCheckedKeys } = props;
  const [expandAll, setExpandAll] = useState(false);
  const { t } = useTranslation(["forms"]);
  const onCheck = (checkedKeys, info) => {
    const data = info.node;
    console.log("the checked keys is", checkedKeys);
    console.log("the checked keys is", info);
    setCheckedKeys(checkedKeys);
    // onShow(data, "edit");
  };
  const fieldNames = {
    title: "name",
    key: "id",
    children: "children",
  };
  useEffect(() => {}, [expandAll]);

  return (
    <div style={{ margin: "0 20px" }}>
      {/* <IconButton
        variant="contained"
        edge="start"
        color="inherit"
        aria-label="home"
        onClick={() => {
          setExpandAll(true);
        }}
      >
        <UnfoldLess />{" "}
      </IconButton>
      <IconButton
        variant="contained"
        edge="start"
        color="inherit"
        aria-label="home"
        onClick={() => {
          setExpandAll(false);
          console.log(expandAll);
        }}
      >
        <UnfoldMore />{" "}
      </IconButton> */}
      <Tree
        checkable
        selectable={false}
        checkStrictly={false}
        defaultCheckedKeys={defaultCheckedKeys}
        // defaultExpandedKeys={[1, 2, 3, 4]}
        defaultExpandAll={false}
        onCheck={onCheck}
        defaultExpandParent={true}
        className="myCls"
        fieldNames={fieldNames}
        treeData={data}
      />
    </div>
  );
};

export default UserPermissionsTree;
