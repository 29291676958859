import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "rc-tree-select/assets/index.css";
import TreeSelect, { SHOW_PARENT } from "rc-tree-select";
import deepRenameKeys from "deep-rename-keys";
import { useState } from "react";
import { parametersData } from "../../../../services/CostCenterRpt-services";

export function getLeafAccounts(data) {
  var res = [];
  for (let account of data) {
    if (account.hasOwnProperty("children")) {
      var res1 = getLeafAccounts(account.children);
      res.push(...res1);
    } else {
      if (account.node?.props?.Type == 2) {
        res.push(account.node.props?.value.toString());
      }
    }
  }
  return res;
}
export default function CostCenterRptForm(props) {
  const {
    rptParams,
    setRptParams,
    onChangeData,
    AutoOptions,
    accounts,
    onChangeAutoData,
    branch,
    setBranch,
  } = props;

  const { t } = useTranslation(["forms"]);
  const [value, setValue] = useState([]);
  const mData = deepRenameKeys(accounts, (key) => {
    if (key === "Code") return "value";
  });
  // const [treeState, setTreeState] = useState({
  //   value: false,
  // })
  const onSelect = (selectedKeys, info) => {};
  const onChange = (value, ...rest) => {
    console.log(
      "the values",
      getLeafAccounts(rest[1].allCheckedNodes).toString()
    );
    setValue(value);
    setRptParams({
      ...rptParams,
      accounts: getLeafAccounts(rest[1].allCheckedNodes).toString(),
    });
  };
  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={2} md={3} sm={6} xs={6}>
          <Autocomplete
            value={rptParams.center}
            onChange={(event, newValue) => {
              onChangeAutoData("center", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.centers}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("center")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Autocomplete
            multiple // classes={clasAo}
            value={rptParams?.parameters}
            onChange={(event, newValue) => {
              const index = parametersData.findIndex(
                (x) => x.name == event.target.textContent
              );
              console.log("event", newValue);
              setRptParams({ ...rptParams, parameters: newValue });

              if (index == -1)
                setRptParams({ ...rptParams, parameters: newValue });
            }}
            id="controllable-states-dem"
            size="small"
            options={parametersData}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{ direction: "ltr" }} // disabled={}
            //disabled={statu Dis.pricePay}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valuePricePay"
                {...params}
                label={"خيارات التقرير"}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={branch}
            onChange={(event, newValue) => {
              setBranch(newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Branches}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("branch")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="datef"
            label={t("date-from")}
            type="date"
            format="YYYY-MM-DD"
            name="datef"
            value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="dateT"
            label={t("date-to")}
            type="date"
            format="YYYY-MM-DD"
            name="datet"
            value={rptParams.dateTo}
            onChange={onChangeData("dateTo")}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <FormControl>
            <FormLabel id="demo-row-radio-tree-group-label">
              {" "}
              {t("accounts")}{" "}
            </FormLabel>
            <TreeSelect
              // open
              // getPopupContainer={(triggerNode) => triggerNode.parentNode}
              style={{
                marginTop: 5,
                width: 400,
                height: 30,
                // maxHeight: 100,
                direction: "ltr",
              }}
              className="check-select"
              transitionName="rc-tree-select-dropdown-slide-up"
              choiceTransitionName="rc-tree-select-selection__choice-zoom"
              // dropdownStyle={{
              //   direction: "ltr",
              //   maxHeight: 200,
              //   overflow: "auto",
              //   zIndex: 1500,
              // }}
              dropdownPopupAlign={{
                overflow: { adjustY: 0, adjustX: 0 },
                offest: [0, 2],
              }}
              placeholder="قم بتحديد الحسابات"
              showCheckedStrategy={SHOW_PARENT}
              showSearch
              autoClearSearchValue
              maxTagTextLength={10}
              allowClear
              treeLine
              treeCheckable
              // maxTagCount="responsive"
              maxTagCount={10}
              value={value}
              treeData={mData}
              treeNodeFilterProp="value"
              // filterTreeNode={false}
              onChange={onChange}
              onSelect={onSelect}
              maxTagPlaceholder={(valueList) => {
                return `${valueList.length} rest...`;
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
