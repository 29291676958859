import React, { useState, useEffect } from "react";
import { Rept, sto } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import axios from "axios";
import { uri } from "../../../../help/Api";
import { useHeader, useBody } from "../../../Invs/bill-template02/style";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import { useHistory, useLocation } from "react-router-dom";
function HeaderA({ found }) {

  const cls = useRptAF();
  const styf = { fontWeight: "1200" };
  const stysub = { fontWeight: "bold" };
  const styfC = { fontWeight: "500" };
  const [info, setInfo] = useState({});
  const calssBody = useBody();
  const classHeader = useHeader();
  const clasm = useInvMain();
  const clas = useInvAF();
  const getInfo = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
        console.log("the res is ", res.data);
      })
      .catch((error) => { });
  };
  const his = useHistory();
  const loc = useLocation();
  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid item container className={cls.root}>
      <Grid
        item
        container
        className={classHeader.headerContainer}
        style={{ padding: 5, fontWeight: "bold" }}
      >
        <Grid item xs={5} className={classHeader.rightHeaderar}>
          <div style={styf}>{info.Disc1_header_ar}</div>{" "}
          <div style={stysub}>{info.Disc2_header_ar}</div>
          <div style={styfC}>{info.Disc3_header_ar}</div>{" "}
        </Grid>
        
        <Grid item xs={2} className={clasm.mincntr}>
          <div>
            <ButtonBase>
              {" "}
              <img
                src={`data:image/jpeg;base64,${info.Logo}`}
                className={clas.Hedlogo}
                alt={info.nameA}
              />{" "}
            </ButtonBase>
          </div>
        </Grid>
        <Grid item xs={5} className={classHeader.leftHeaderen}>
          <div style={styf}>{info.Disc1_header_en}</div>
          <div style={styf}>{info.Disc2_header_en}</div>
          <div style={styf}>{info.Disc3_header_en}</div>{" "}
        </Grid>
      </Grid>
      <Grid item xs={12} className={[cls.title, cls.titleM]}>
        <div style={styf}>{loc?.state?.title}</div>
      </Grid>

      <Grid item container className={[cls.title, cls.titleS]}>
        <Grid item xs={12} container>
          <Grid item xs={2} style={styf}>
            {loc?.state?.from}
          </Grid>
          <Grid item xs={1}>
            {" "}
            /{" "}
          </Grid>
          <Grid item xs={3} className={cls.tlR} style={styf}>
            {loc?.state?.account?.name}
          </Grid>
        </Grid>

        <Grid item container className={cls.titLin2}>
          <Grid item xs={3}>
            {"من تاريخ"}
          </Grid>
          <Grid item xs={3}>
            {loc?.state?.fDate}
          </Grid>
          <Grid item xs={3}>
            {"إلى تاريخ"}
          </Grid>
          <Grid item xs={3}>
            {loc?.state?.tDate}
          </Grid>
        </Grid>

        <Grid item container className={cls.titLin3}>
          <Grid item xs={3}>
            {"المستخدم"}
          </Grid>
          <Grid item xs={3}>
            {sto.usernames}
          </Grid>
          <Grid item xs={3}>
            {"تاريخ الطباعة"}
          </Grid>
          <Grid item xs={3} >
            {loc?.state?.nDate}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default HeaderA;
