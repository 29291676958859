// const locConn = JSON.parse(localStorage.getItem('infoConn')) || {};
// const locSto = JSON.parse(localStorage.getItem('sto')) || {};
// const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
// const locPremis = JSON.parse(localStorage.getItem('premiApp')) || {};
// const locpermiCuCo = JSON.parse(localStorage.getItem('permiCuCo')) || {};
// const locPremiScr = JSON.parse(localStorage.getItem('premiScr')) || {};
// const locRept = JSON.parse(localStorage.getItem('iInfos')) || {};

// const locConn = JSON.parse(window.localStorage.getItem('infoConn')) || {leavle:'undefined1',useUri:false};
const locConn = JSON.parse(window.localStorage.getItem('infoConn')) || {};
const locSto = JSON.parse(window.localStorage.getItem('sto')) || {};
const locRem = JSON.parse(window.localStorage.getItem('DataRem')) || {};
const locPremiA = JSON.parse(window.localStorage.getItem('premiApp')) || {};
const locpermiCuCo = JSON.parse(window.localStorage.getItem('permiCuCo')) || {};
const locPremiScr = JSON.parse(window.localStorage.getItem('premiScr')) || {};
const locRept = JSON.parse(window.localStorage.getItem('iInfos')) || {};

// const stor = { conn:locConn }

 export {locConn,locSto,locRem,locPremiA,locpermiCuCo,locPremiScr,locRept};
