import React, { useState, useEffect, Fragment } from 'react';
import AccountsIndexTree from './account-index-tree';
import BackdropSc from '../../../../services/BackdropSc';
import { useStyles } from './account-index-style';
import AccountIndexAPI from '../../../../api/AccountsIndex'
import AccountsIndexForm from './account-index-form';
import {  Grid, Paper } from '@material-ui/core';
import AccountsIndexAppBar from './account-index-appBar';
import { Toaster } from 'react-hot-toast';
import useFormDataCRUD from '../../../../components/Hooks/useEDSCURD';
import useFetchURL from '../../../../components/Hooks/useFetchURL';
import { convertToTreeData, initFormData } from '../../../../services/accountIndex-services';
import { uri } from '../../../../help/Api';

export default function AccountsIndex(props) {
    const clasess = useStyles();
    const {omlat, prmis} = props;
    const [newCode, setNewCode]= useState('');

    const url = uri+'accounts-index/getAll';
    const {data, fetching, fetchURL} = useFetchURL({...{url,
      mapFunction: convertToTreeData,
      dependencyArray:[]}});
      
    const {
            onChange,
            submit,
            update,
            remove,
            submitting, 
            errorMessage,
            onShow,
            onEnd,
            object,
            formType,
            
        } = useFormDataCRUD(
            AccountIndexAPI.create,
            AccountIndexAPI.update,
            AccountIndexAPI.remove,
            undefined,
            initFormData,
            undefined,
            fetchURL,
    );


  
  if(fetching)
    return <BackdropSc />

  return (
    <div className={clasess.main}>
      <Toaster />
      <Grid container spacing={2} style={{ 
        direction:'rtl', paddingBottom:'20px'
        }}> 
          <Grid container lg={4} md={5} sm={6} xs={12}>
            <Grid container item lg={12} md={12} className={[clasess.rootup,]}>
              <Grid item spacing={1} xs={12} className={[clasess.upsub, clasess.upsubR,]}>
                 <Paper style={{maxHeight: 600, overflow: 'auto'}}>
                    <AccountsIndexTree {...{data, onShow}}/>
                    
                  </Paper>
              </Grid>
            </Grid>
          </Grid>
      
      <Grid item lg={8} md={7} sm={6} xs={12}>
          <AccountsIndexForm {...{
             newCode, 
             setNewCode,
             omlat,
             data, 
            fetching,
            onChange,
            submit,
            update,
            remove,
            submitting, 
            errorMessage,
            onShow,
            onEnd,
            object,
            formType,
          }}/>
      </Grid>
    </Grid>
    <br />
    <br />
    <AccountsIndexAppBar {...{
            newCode, 
            data, 
            prmis,
            fetching,
            onChange,
            submit,
            update,
            remove,
            submitting, 
            errorMessage,
            onShow,
            onEnd,
            object,
            formType,
    }} />
    </div>
  );

};