import React, { useState, useEffect } from "react";

import {
    Button,
    colors,
    Container,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { BeatLoader } from "react-spinners";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import {
    useStylesSpDial,
    useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { sto } from "../../../../../Context/InshData";
import DailyReportFilter from "../EveryDay/DailyReportFilter";
import { invoiceTypes, salesmanCommTypes, selectDateOrTime, selectTypes } from "../../../../../services/saleAnalysis-services";
import axios from "axios";

import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import toast from "react-hot-toast";
import SalesmanSalesTables from "./SalesmanTable";
import SalesmanSalesForm from "./SalesmanForm";
import { CostType } from "../../../../../services/currencySalesProfit-services";
import { invoicePayTypes } from "../../../../../services/invoice-service";
import SelectTypesFilter from "../CurrencySalesProfit/CurrencySalesProfit-typeFilter";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
    },
    itemW: {
        // display:'flex',
        width: "90%",
    },
    headerTitle: {
        textAlign: "center",
        fontSize: 20,
        width: "100%",
    },
    allWidth: {
        minWidth: 650,
    },
    checStyl: {
        marginTop: "17px",
        fontSize: "16px",
        padding: "-1px",
    },
}));

export default function SalesmanReport(props) {
    // const { AutoOptions, CustomerOptions, isSale, isPurches } = props;
    const { AutoOptions, isPurches } = props;


    let idBranch = AutoOptions.Branches[0].id;
    let nameBranch = AutoOptions.Branches[0].name;
    const sealetedEmpValue = {
        selectType: selectTypes[0],
        selectTime: selectDateOrTime[0],
        dateFrom: moment(new Date()).format('YYYY-MM-DD'),
        timeFrom: moment(new Date()).format('HH:mm'),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
        timeTo: moment(new Date()).format('HH:mm'),
        branch: { id: sto.User_typei == 1 ? -1 : idBranch, name: sto.User_typei == 1 ? "الكل" : nameBranch },
        salesMan: { id: -1, name: "الكل" },
        omla_type: salesmanCommTypes[0],
        type_Cost: CostType[0],
        inv_type: invoiceTypes[0],
        pay_type: invoicePayTypes[0],
        bank: { id: -1, name: "الكل" },
        CommWithProfit: false,
        CommWithVat: true,
    };
    const [rptParams, setRptParams] = React.useState(sealetedEmpValue);
    const { t } = useTranslation(["forms", "translation"]);
    // const his = useHistory();
    const classes = useStyles();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();

    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [sum, setSum] = useState(false);

    const viewHandle = async () => {

        setData([]);
        setFetching(true);
        await axios
            .get(
                uri +
                `RptSales/SalesmanReports?branch=${rptParams.branch?.id}
          &&commWithVat=${rptParams.CommWithVat == true ? 0 : 1}
          &&commWithProfit=${rptParams.CommWithProfit == true ? 0 : 1}
          &&fromDate=${rptParams.dateFrom + ' ' + rptParams.timeFrom}
      &&toDate=${rptParams.dateTo + ' ' + rptParams.timeTo}
  &&date_and_time=${rptParams.selectTime?.name}
  &&salesMan=${rptParams.salesMan?.id}
  &&costType=${rptParams.type_Cost?.id}
  &&commType=${rptParams.omla_type?.id}
  &&pay_Type=${rptParams.pay_type?.id}
  &&inv_type=${rptParams.inv_type?.id}
  &&acc_no=${rptParams.bank?.id}
  &&acc_no2=${rptParams.bank?.id}`
            )
            .then((res) => {
                setData(res.data);
                console.log(" the data is", res.data);
                setFetching(false);
            })
            .catch((err) => {
                setFetching(false);
                console.log(err);
                // toast.err("يوجد خطأ في الجلب");
                setData([]);
            });
    };
    const his = useHistory();


    const printHandle = () => {
        if (data.lenght == 0) {
            alert("لاتوجد بيانات");
        } else {
            const info = {
                data: data?.salesmanSales,
                tot: data?.totals,
                title: isPurches ? "تقرير مشتريات مندوب خلال فترة" : "تقرير مبيعات مندوب خلال فترة",
                // tot1: isPurches ? "إجمالي (المشتريات)" : "إجمالي (المبيعات)",
                // tot2: isPurches ? "إجمالي الضريبة (المشتريات)" : "إجمالي الضريبة (المبيعات)",
                // tot3: isPurches ? "إجمالي النهائي (المشتريات)" : "إجمالي النهائي (المبيعات)",
                fDate:rptParams.dateFrom,
                tDate:rptParams.dateTo,
                nDate: moment(new Date()).format("YYYY-MM-DD"),
                branch: rptParams.branch?.name,
                salesman: rptParams.salesMan?.name,
                paytype:rptParams.pay_type?.name,
                invtype:rptParams.inv_type?.name,
                bank:rptParams.bank?.name,
                omlatype:rptParams.omla_type?.name,
                
            };
            localStorage.setItem("iInfos", JSON.stringify(info));
            his.push({ pathname: "/ReviewSalesman" });
            window.location.reload(true);
        }
    };

    let excel_name = isPurches ? "SalesmanPurchasesReport" : "SalesmanSalesReports";


    const excelHeaders = [
        { label: " نوع الحركة", key: "col_trans_type", },
        { label: "التاريخ", key: "col_date", },
        { label: "رقم الفاتورة", key: "col_bill_no", },
        { label: "المندوب", key: "col_sales_name", },
        { label: "قيمة الفاتورة بعد الخصم قبل الضريبة", key: "col_tot_net", },
        { label: "نسبة العمولة", key: "col_commission", },
        { label: "مبلغ العمولة", key: "col_Amount_collected", },
        { label: "متوصط التكلفة ", key: "col_CostAvg", },
        { label: "مبلغ الضريبة", key: "col_amountOfVat", },
        { label: "الربح", key: "col_profit", },
        { label: "الصافي", key: "col_commNet", },

    ];

    const onChangeData = (propName) => (event) => {
        setRptParams({ ...rptParams, [propName]: event.target.value });
    };
    const onChangeAutoData = (propName, newValue) => {
        setRptParams({ ...rptParams, [propName]: newValue });
    };
    return (
        <div className={classes.root}>
            <Grid container item xs={12}>
                <Typography
                    size="small"
                    className={classes.headerTitle}
                    style={{
                        backgroundColor: isPurches ? colors.indigo[50] : colors.green[50],
                    }}
                // color="primary"
                >
                    {isPurches
                        ? "مشتريات مندوب خلال فترة"
                        : "مبيعات مندوب خلال فترة"}
                </Typography>
            </Grid>
            <Typography component="div" style={{ direction: "rtl" }}>
                <Container
                    component={Paper}
                    maxWidth={false}
                    style={{
                        padding: "10px",
                        borderBottomColor: isPurches ? colors.indigo[300] : colors.green[300],
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                    }}
                >
                    <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent="center">
                        <Grid item lg={4} md={4} sm={4} xs={12} justifyContent="center">

                            <DailyReportFilter {...{ rptParams, setRptParams, isPurches }} />
                        </Grid>
                        <Grid item lg={8} md={8} sm={8} xs={12} justifyContent="center">

                            <SelectTypesFilter {...{ setRptParams, rptParams }} />
                        </Grid>
                    </Grid>
                    <SalesmanSalesForm
                        {...{ AutoOptions, rptParams, onChangeData, onChangeAutoData, isPurches }}
                    />
                    <Grid
                        container
                        justifyContent="center"
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        spacing={1}
                    >

                        <Hidden smUp={["sm"]}>
                            <Grid item>
                                <Button
                                    disabled={fetching}
                                    variant="contained"
                                    startIcon={<ScannerIcon />}
                                    size="large"
                                    style={
                                        (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                                            { borderRadius: 0 })
                                    }
                                    className={[clasui.button]}
                                    onClick={() => viewHandle()}
                                >
                                    {t("forms:show")}
                                </Button>
                            </Grid>
                        </Hidden>
                        <Hidden only={["xs"]}>
                            <Grid item>
                                <Button
                                    disabled={fetching}
                                    variant="contained"
                                    startIcon={<ScannerIcon />}
                                    size="large"
                                    style={
                                        (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                                            { borderRadius: 0 })
                                    }
                                    className={[clasui.button]}
                                    onClick={() => viewHandle()}
                                >
                                    {t("forms:view")}
                                </Button>
                            </Grid>
                        </Hidden>

                        <Hidden smUp={["sm"]}>
                            <Grid item >
                                {data && !fetching && data.salesmanSales && (
                                    <CSVLink
                                        separator={";"}
                                        filename={excel_name}
                                        data={data.salesmanSales}
                                        headers={excelHeaders}
                                    >
                                        {" "}
                                        <Button
                                            disabled={fetching}
                                            variant="contained"
                                            startIcon={<TableChart />}
                                            size="large"
                                            color="primary"
                                            style={{ borderRadius: 0 }}
                                            className={[clasui.button]}
                                        >
                                            {"اكسل"}
                                        </Button>
                                    </CSVLink>
                                )}
                            </Grid>
                        </Hidden>
                        <Hidden only={["xs"]}>
                            <Grid item >
                                {data && !fetching && data.salesmanSales && (
                                    <CSVLink
                                        separator={";"}
                                        filename={excel_name}
                                        data={data.salesmanSales}
                                        headers={excelHeaders}
                                    >
                                        {" "}
                                        <Button
                                            disabled={fetching}
                                            variant="contained"
                                            startIcon={<TableChart />}
                                            size="large"
                                            color="primary"
                                            style={{ borderRadius: 0 }}
                                            className={[clasui.button]}
                                        >
                                            {"تصدير اكسل"}{" "}
                                        </Button>
                                    </CSVLink>
                                )}
                            </Grid>
                        </Hidden>
                        <Grid item>
                            <Button
                                disabled={fetching}
                                variant="contained"
                                startIcon={<PrintIcon />}
                                color="primary"
                                size="large"
                                className={[clasui.button, clasSp.clSec]}
                                style={
                                    (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                                        { borderRadius: 0 })
                                }
                                onClick={printHandle}
                            >
                                {t("forms:print")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                startIcon={<KeyboardReturnSharpIcon />}
                                color="secondary"
                                size="large"
                                className={[clasui.button, clasSp.clcan]}
                                style={
                                    (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                                        { borderRadius: 0 })
                                }
                                onClick={() => his.goBack()}
                            >
                                {t("forms:exit")}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth={true} style={{ padding: "4px" }}>
                    <Grid container spacing={1} style={{ paddingTop: "10px" }}>
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                            <TotalCard
                                {...{
                                    className: isPurches ? classes.purchTotalCard : null,
                                    title: "إجمالي عدد السجلات",
                                    value: data?.totals?.countRow,
                                }}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                            <TotalCard
                                {...{
                                    className: isPurches ? classes.purchTotalCard : null,
                                    title: "إجمالي قيمة الفواتير",
                                    value: data?.totals?.tot_sal.toFixed(2),
                                }}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                            <TotalCard
                                {...{
                                    className: isPurches ? classes.purchTotalCard : null,
                                    title: "إجمالي العمولة",
                                    value: data?.totals?.tot_omla.toFixed(2),
                                }}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={4} xs={6}>
                            <TotalCard
                                {...{
                                    className: isPurches ? classes.purchTotalCard : null,
                                    title: "إجمالي صافي الفواتير",
                                    value: data?.totals?.tot_safi.toFixed(2),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <br />
                <Container
                    component={Paper}
                    maxWidth={false}
                    style={{
                        paddingLeft: "5px",
                        paddingRight: "5px",

                    }}
                >
                    <Grid container justifyContent="center">
                        <Grid item container justifyContent="center" xs={12}>
                            <BeatLoader
                                loading={fetching}
                                color={isPurches ? colors.indigo[300] : colors.green[300]}
                            />
                            {!fetching && (
                                <SalesmanSalesTables
                                    {...{
                                        data: data?.salesmanSales ? data.salesmanSales : [],
                                        isPurches,
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </Typography>
        </div>
    );
}
