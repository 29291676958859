import React,{Fragment} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'

import {Bar, Line } from 'react-chartjs-2';
import { useState } from 'react';
import {monthLabels, nFormatter} from '../../../../services/SalesChartSrvice';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
};

export default function SalesChartView(props){
  const {data: mData} = props;
  const [data, setDate] = useState(()=>{

    return {
      labels:monthLabels, 
      datasets: [
        {
          label: '2023',
          data: mData.map(( i) =>  i.monthOnThisYear),
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: '2022',
          data: monthLabels.map((m, i) => mData[i]?.monthOnLastYear),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
        
      ]
    }
    
  });

    return(
      <Bar options={options} data={data} />
    )
}
