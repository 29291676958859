import React, { useState, useEffect } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import { sto } from "../../../../Context/InshData";
import { useBody,useHeader } from "../../invCompany/style";

function HeaderM({foundData}) {

  // const clasH = useInvHeadrAF();
  const classHeader = useHeader();

  const styf = { fontWeight: "500" };
  const styfC = { fontWeight: "500", color: "#9a3f47" };
 
  const clasm = useInvMain();
  const clas = useInvAF();
  const calssBody = useBody();
  return (
    <Grid
      item
      container
      //className={classHeader.headerContainer}
      justifyContent="center"
      style={{ paddingTop:"0.5rem", fontWeight: "bold",fontFamily: "Amiri-Regular", 
        // Width: "20.45m"
      }}
    >
      <Grid       item
      xs={12}
      // className={clas.Hedroot}
      //className={calssBody.BdymtHeaderTopp} 
      style={{
            border: "solid 1px",
    borderRadius: "7px",
    fontWeight: "500",
    padding:6,
      }}
      >
        <Grid item container >
      <Grid item xs={5} className={clasm.minleft}>
        <div style={styf}>{foundData.Disc1_header_en}</div>
        <div style={styf}>{foundData.Disc2_header_en}</div>
        <div style={styfC}>{foundData.Disc3_header_en}</div>{" "}
        <div style={styfC}>{foundData.Disc4_header_en}</div>
        <div style={styf}>{"Vat number " + foundData.tax_no}</div>
      </Grid>

      <Grid item xs={2} className={clasm.mincntr}>
        <div>
          <ButtonBase>
            {" "}
            <img
              src={`data:image/jpeg;base64,${foundData.Logo}`}
              className={clas.Hedlogo}
              alt={foundData.nameA}
            />{" "}
          </ButtonBase>
        </div>
      </Grid>

      <Grid item xs={5} className={clasm.minrit}>
        <div style={styf}>{foundData.Disc1_header_ar}</div>{" "}
        <div style={styf}>{foundData.Disc2_header_ar}</div>
        <div style={styfC}>{foundData.Disc3_header_ar}</div>{" "}
        <div style={styfC}>{foundData.Disc4_header_ar}</div>
        <div style={styf}>{"الرقم الضريبي " + foundData.tax_no}</div>
      </Grid>
      </Grid>
      </Grid>
    </Grid>
  );
}
export default HeaderM;
