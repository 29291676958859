import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import {insh} from '../Context/InshSt';

const useStyles = makeStyles((theme) => 
({ backdrop: { zIndex: theme.zIndex.drawer + 1, color: insh.colors.secndry,
  // background: '#ff000047',
  // position: 'absolute',
  //   top:0,bottom: 0,left: 0,right: 0,
  //   margin: 'auto',
  //   width: '100px',
  //   height: '100px',
  //   borderRadius:15,

}, }));

export default function BackdWite() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  return (
    <div>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}