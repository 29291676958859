import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { Autocomplete } from "@material-ui/lab";
import { useStyles } from "./reportTax-style";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import moment from "moment";
import {
  CardMedia,
  Hidden,
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import Image from "../../../../assets/img/VAT.svg";
import { useHistory } from "react-router-dom";
import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";

const styles = {
  paperContainer: {
    backgroundImage: `url(${Image})`,
  },
};
const defaultValues = {
  dateFrom: moment(new Date()).startOf("year").format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  branch: {},
  branchId: 0,
  type: "transAll",
};

export default function ReportTax() {
  const clasess = useStyles();
  let his = useHistory();

  const [rptParams, setRptParams] = useState(defaultValues);

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `AccountReports/rptTax?branch=${rptParams.branchId}
    &&dateF=${rptParams.dateFrom}
    &&dateT=${rptParams.dateTo}
    &&type=${rptParams.type}`,
      dependencyArray: [],
    },
  });

  const url = uri + "branch";
  const { data: optionsBranches, fetching: fetchingBranches } = useFetchURL({
    ...{
      url,
      mapFunction: (data) => {
        return [{ id: -1, name: "الكل" }, ...data];
      },
      dependencyArray: [],
    },
  });

  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  useEffect(() => {
    console.log("data is ", data.length > 0 ? data[0] : data);
    if (optionsBranches.length > 0)
      setRptParams({
        ...rptParams,
        branch: optionsBranches[0],
        branchId: optionsBranches[0].id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsBranches]);

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };

  const printHandle = () => {
    const info = {
      ...data[0],
      dateFrom: rptParams.dateFrom,
      dateTo: rptParams.dateTo,
    };

    his.push({ pathname: "/RTP", state: info });

    window.location.reload(false);
  };

  const ContLeft_ = () => {
    return (
      <Grid container item>
        <Grid lg={12} md={12} sm={6} xs={12} item>
          <Card className={clasess.rootCaedL}>
            <CardMedia
              className={clasess.logo}
              style={styles.paperContainer}
            ></CardMedia>
          </Card>
        </Grid>
      </Grid>
    );
  };

  // return <ContLeft_ />
  return (
    <div className={clasess.main}>
      <Grid container item className={clasess.root}>
        <Grid item className={clasess.textField2} lg={7} md={7} sm={12} xs={12}>
          <Grid
            item
            className={clasess.root}
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Grid
              className={clasess.textField}
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
            >
              <TextField
                fullWidth
                variant="outlined"
                id="datefrom"
                label="من التاريخ"
                type="date"
                size="small"
                format="YYYY-MM-DD"
                value={rptParams.dateFrom}
                onChange={onChangeData("dateFrom")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid
              className={clasess.textField}
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
            >
              <TextField
                fullWidth
                variant="outlined"
                id="dateTo"
                label="إلى التاريخ"
                type="date"
                size="small"
                format="YYYY-MM-DD"
                value={rptParams.dateTo}
                onChange={onChangeData("dateTo")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid
              className={clasess.textField}
              item
              lg={3}
              md={3}
              sm={3}
              xs={6}
            >
              <Autocomplete
                value={rptParams.branch}
                onChange={(event, newValue) => {
                  setRptParams({
                    ...rptParams,
                    branch: newValue,
                    branchId: newValue.id,
                  });
                }}
                id="controllable-states-demo"
                size="small"
                options={optionsBranches}
                // loading={fetchingBranches}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="الفرع"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>

            <RadioGroup
              row
              onChange={onChangeData("type")}
              value={rptParams.type}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="transAll"
                control={<Radio />}
                label="الكل"
              />
              <FormControlLabel
                value="WithVat"
                control={<Radio />}
                label="خاضعة للضريبة"
              />
              <FormControlLabel
                value="WithoutVat"
                control={<Radio />}
                label="غير خاضعة للضريبة"
              />
            </RadioGroup>
          </Grid>
          <Grid container lg={12} md={12} sm={12} xs={12} spacing={1}>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                color="primary"
                size="large"
                className={[clasui.button]}
                onClick={() => fetchURL()}
              >
                عـــرض النتيجة{" "}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                onClick={printHandle}
              >
                طباعة{" "}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                onClick={() => {
                  his.push({
                    pathname: "/",
                  });
                }}
              >
                خروج
              </Button>
            </Grid>
          </Grid>
          <Grid className={clasess.root} item container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={clasess.textField2}>
                <b>ضريبة القيمة المضافة على المبيعات</b>
              </Typography>
            </Grid>

            <Grid item className={clasess.buttons2} lg={4} md={4} sm={4} xs={4}>
              <Typography className={clasess.textField2}>
                اجمالي المبيعات
              </Typography>
            </Grid>
            <Grid item className={clasess.buttons2} lg={4} md={4} sm={4} xs={4}>
              <Typography className={clasess.textField2}>
                اجمالي المردودت
              </Typography>
            </Grid>

            <Grid item className={clasess.buttons2} lg={4} md={4} sm={4} xs={4}>
              <Typography className={clasess.textField2}>
                ضريبة القيمة المضافة
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 && data[0].SaleVal.toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 && data[0].RT_SaleeVal.toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 && data[0].SaleTax.toFixed(2)}
              </Typography>
            </Grid>

            <Grid item className={clasess.buttons2} lg={6} md={6} sm={6} xs={6}>
              <Typography className={clasess.textField2}>
                ضريبة المردودت
              </Typography>
            </Grid>

            <Grid item className={clasess.buttons2} lg={6} md={6} sm={6} xs={6}>
              <Typography className={clasess.textField2}>
                الضريبة المستحقة
              </Typography>
            </Grid>

            <Grid
              item
              className={clasess.textField2}
              lg={6}
              md={6}
              sm={6}
              xs={6}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 && data[0].RT_SaleTax.toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={6}
              md={6}
              sm={6}
              xs={6}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 &&
                  (data[0].SaleTax - data[0].RT_SaleTax).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={clasess.root} item container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={clasess.textField2}>
                <b>ضريبة القيمة المضافة على المشتريات</b>
              </Typography>
            </Grid>

            <Grid item className={clasess.buttons2} lg={4} md={4} sm={4} xs={4}>
              <Typography className={clasess.textField2}>
                اجمالي المشتريات
              </Typography>
            </Grid>
            <Grid item className={clasess.buttons2} lg={4} md={4} sm={4} xs={4}>
              <Typography className={clasess.textField2}>
                اجمالي المردودت
              </Typography>
            </Grid>

            <Grid item className={clasess.buttons2} lg={4} md={4} sm={4} xs={4}>
              <Typography className={clasess.textField2}>
                ضريبة المشتريات
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 && data[0].PurchVal.toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 && data[0].RT_PurchVal.toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 && data[0].PurchTax.toFixed(2)}
              </Typography>
            </Grid>

            <Grid item className={clasess.buttons2} lg={6} md={6} sm={6} xs={6}>
              <Typography className={clasess.textField2}>
                ضريبة المردودت
              </Typography>
            </Grid>

            <Grid item className={clasess.buttons2} lg={6} md={6} sm={6} xs={6}>
              <Typography className={clasess.textField2}>
                الضريبة المستحقة
              </Typography>
            </Grid>

            <Grid
              item
              className={clasess.textField2}
              lg={6}
              md={6}
              sm={6}
              xs={6}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 && data[0].RT_PurchTax.toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={6}
              md={6}
              sm={6}
              xs={6}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 &&
                  (data[0].PurchTax - data[0].RT_PurchTax).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>

          <Grid className={clasess.root22} item container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={clasess.textField2}>
                <b>تفاصيل الضريبة</b>
              </Typography>
            </Grid>

            <Grid item className={clasess.buttons2} lg={4} md={4} sm={4} xs={4}>
              <Typography className={clasess.textField2}>
                ضريبة المبيعات
              </Typography>
            </Grid>
            <Grid item className={clasess.buttons2} lg={4} md={4} sm={4} xs={4}>
              <Typography className={clasess.textField2}>
                ضريبة المشتريات
              </Typography>
            </Grid>

            <Grid item className={clasess.buttons2} lg={4} md={4} sm={4} xs={4}>
              <Typography className={clasess.textField2}>
                الضريبة المستحقة
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 &&
                  (data[0].SaleTax - data[0].RT_SaleTax).toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 &&
                  (data[0].PurchTax - data[0].RT_PurchTax).toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              item
              className={clasess.textField2}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Typography className={clasess.textField2}>
                {data.length > 0 &&
                  (
                    data[0].SaleTax +
                    data[0].RT_PurchTax -
                    (data[0].PurchTax + data[0].RT_SaleTax)
                  ).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown={["sm"]}>
          <Grid item lg={5} md={5} style={{ borderColor: "green" }}>
            <ContLeft_ />
          </Grid>
        </Hidden>
      </Grid>

      <div>
        <AppBar
          size="small"
          color="primary"
          className={clasess.appBar}
        ></AppBar>
      </div>
    </div>
  );
}
