import React, { useState, useEffect } from "react";

import { SAdefaultValues } from "../../../services/saleAnalysis-services";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import { useStylesHed, useStylesSpDial } from "../../../St/comps/UseStyle";
import i18next from "i18next";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import InvoiceA4SettingsCon from "./invoice-setting-a4/invoiceA4Con";

import DefalutPrintSet from "./default-print";

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100%',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(10),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  avatar: {
    backgroundColor: colors.grey[800],
    color: "#fff",
    height: 46,
    width: 46,
    marginTop: -5,
  },
  icon: {
    height: 32,
    width: 32,
  },
}));

export function InvoiceSettingView() {
  const classes = useStyles();
  const clashd = useStylesHed();
  const [viewType, setViewType] = useState("a4");

  // useEffect(() => {
  //   console.log("oboboobobbobob", object);
  // });

  return (
    <div
      // className={classes.root}
      style={{
        paddingBottom: "60px",
        direction: i18next.language == "ar" ? "rtl" : "ltr",
      }}
    >
      <Grid
        item
        container
        justifyContent="space-evenly"
        spacing={0}
        className={clashd.rootHd}
        style={
          {
            // paddingTop: "8px",
            // color: colors.teal[900 ],
            // backgroundColor:  colors.grey[400],
          }
        }
      >
        <Grid item>
          <ToggleButtonGroup
            color="primary"
            value={viewType}
            exclusive
            style={{ direction: "ltr", borderColor: "white" }}
            size="large"
            onChange={(event, nextView) => {
              if (nextView != null) setViewType(nextView);
            }}
            ar ia-label="Platform"
          >
            <ToggleButton
              style={{
                backgroundColor: viewType == "default" ? colors.teal[100] : "",
              }}
              value="default"
              aria-label="table"
            >
              <Typography variant={"h5"}>الإعدادات الإفتراضية</Typography>
            </ToggleButton>
            <ToggleButton
              style={{
                backgroundColor: viewType == "a4" ? colors.teal[100] : "",
              }}
              value="a4"
              aria-label="a4"
            >
              <Typography variant={"h5"}>أعدادات فاتورة A4</Typography>
            </ToggleButton>

            <ToggleButton
              style={{
                backgroundColor: viewType == "template" ? colors.teal[100] : "",
              }}
              value="template"
              aria-label="template"
            >
              <Typography variant={"h5"}>القوالب</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Typography
        component="div"
        style={{
          direction: i18next.language == "ar" ? "rtl" : "ltr",
          marginTop: "-2px",
          borderColor: colors.teal[100],
          borderWidth: "10px",
          borderStyle: "solid",
        }}
      >
        {viewType == "a4" ? <InvoiceA4SettingsCon /> : <></>}
        {viewType == "default" && <DefalutPrintSet />}
      </Typography>
    </div>
  );
}

export default function InvoiceSettingsApp() {
  return <InvoiceSettingView />;
}
