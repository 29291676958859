import { Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';


const PrintExample=()=>{
    const componentRef = useRef();    
    // print function 
    const handlePrint = useReactToPrint({
        content: ()=> componentRef.current,
    });

    return(
        <div>
            <div>
                <Typography ref={componentRef} style={{fontSize:50}}> Orax Print example</Typography>
                <button onClick={handlePrint}>print</button>
            </div>
        </div>
    )
}


export default PrintExample;