import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export default function StoreTransferLoader(){

    return (
        <Box sx={{width: '100%'}}>
            <Skeleton />
            <Skeleton  animation="wave"/>
            <Skeleton  animation="wave"/>
            <Skeleton animation={false}/>
            <Skeleton  animation="wave"/>
            <Skeleton animation={false}/>
            <Skeleton  animation="wave"/>
            <Skeleton animation={false}/>
        </Box>
    )
}