import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@material-ui/lab';
import Button from "@material-ui/core/Button";
import EditIcon from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import {uri} from '../../../../../help/Api';

import { ButtonGroup } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import toast, { Toaster } from 'react-hot-toast';

import {useStylesAuto, useStylesLbl,} from '../../../../../St/comps/UseStyle';
import KhamTable from './KhamBody';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { perc } from 'react-grid-layout/build/utils';
import axios from 'axios';

const khamValidateSchema = yup.object().shape({

  itemId: yup.string().required("قم بتحديد kham item"),
  unitId: yup.string().required("قم بتحديد the unit"),
  quan: yup.string().required("حدد quantity "),
  price: yup.string().required("حدد قيمة "),
  // barcode: yup.string().required("لا يوجد باركود للصنف"),
})

const useStyles = makeStyles((theme) => ({
    textField3: {
      width: '100%',
      padding:5,
    },
    textField44: {
     width: '50%',
      marginTop:5,
      marginBottom:-5,
    },
 
 
      textField: { "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color:'navy', fontSize:'0.8rem', },
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": { color:'navy', fontSize:'0.7rem',
       lineHeight: '0.5rem',}, "& .MuiOutlinedInput-inputMarginDense": 
      { paddingLeft:4,paddingRight:4, paddingTop:6,paddingBottom:4, textAlign:'center', fontSize: '1rem' }, margin: 3,},
  
    root: {
      direction: 'rtl',
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      paddingRight: '1px',
      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    },
  
  }));

  const defaultData ={
    id:'',
    price: 1,
    // purch: '',
    khamItem:null,
    itemUnits:[],
    unitValue:{},
    quan:1,
    quan_tot:1,
    // unit_convert: '',
  }


export default function KhamItems(props){

    const { khamItemsList, AutoOptions, dataList, setDataList,items,setItems } = props;
    const {t} = useTranslation();
    const [units, setUnits] = useState([]);
    const [inputs, setInputs]= useState(defaultData);
    const [sub, setSup]= useState([]);


    const selectItem =(item)=>{
      const units=[];
      AutoOptions.Units.map((x) => {
        if (x.id == item.unit) units.push({...x, price: item?.purch_price, perc: 1});
      });
      sub.map((su) => {
        var unit = AutoOptions.Units.find((x) => x.id === su.unit);
        units.push({ id:unit?.id , name: unit?.name?? "", price: su?.purch, perc: su?.perc });
      });

      setInputs({khamItem: item, itemUnits: units});
    }

    const changeSelectseItem =(item)=>{
        onRowClick(item);
        selectItem(item);
      }
      const onRowClick = async (item) => {
        setSup([]);
        await axios
            .get(
                uri +
                `RptSafes/itemUnitOnly?curr_id=${item.id}`
            )
            .then((res) => {
                setSup(res.data?.subUnits);
            })
            .catch((err) => {
                setSup([]);
            });
    };
    const selectRow =(row)=>{
      const units=[];
      AutoOptions.Units.map((x) => {
        if (x.id == row?.item?.unit) units.push({...x, price: row?.item?.purch_price, perc: 0});
      });
      row?.item?.subUnits.map((su) => {
        var unit = AutoOptions.Units.find((x) => x.id === su.unit);
        units.push({ id:unit?.id , name: unit?.name?? "", price: su?.purch , perc: su.perc});
      });

      setInputs({id:row.tableData.id.toString(), khamItem: row?.item, itemUnits: units, unitValue:row?.unit, price: row?.price,
        quan: row?.quan,
      });
    }

 
    useEffect(()=>{
      console.log("the kham list is", khamItemsList)
    }, [])
    const classes = useStyles();
    const [unitValue, setunitValue] = React.useState([]);
    const [submitting, setSubmitting] = useState(false);
    // const [data, setData] = useState(defaultData);
    const [isNew, setIsNew]= useState(true);

    const clasAo = useStylesAuto();
    const claslbl = useStylesLbl();
    const changePurchItems = (prop) => (e) => {
        if(prop == "quan"){
            if(!inputs?.unitValue) toast.error("Select the unit first.")
              else setInputs({...inputs, quan: e.target.value, 
                    price: e.target.value * inputs?.unitValue?.price,
                    quan_tot: e.target.value * inputs?.unitValue?.perc
                
              })
          }
          if (inputs?.quan===null){inputs.quan=1;};
          if (inputs?.price==null){inputs.price=1;};

          if (( e.target.value!=null || e.target.value!=="")&& inputs?.price!=null){
        let newpurch=0.0;
        newpurch =(parseFloat(items.purch_price)+(parseFloat( inputs.price)*parseFloat(inputs.quan))).toFixed(2);
        setItems({ ...items, purch_price: newpurch });
          }
        
      };
    
    const onSubmit= (e)=>{
      e.preventDefault();
      console.log("the inputs is", inputs)
      let data={
        ...inputs,
        item: inputs?.khamItem,
        itemId:inputs?.khamItem?.id,
        unitId: inputs?.unitValue?.id,
        // itemName: inputs?.khamItem?.symbol,
        unit: inputs?.unitValue,
        // unitName: inputs?.unitValue?.name,
        quan: inputs?.quan,
        quan_tot: inputs?.quan * inputs?.unitValue?.perc,
        price: inputs?.price
      }

      khamValidateSchema.validate(data, { abortEarly: false })
      .then(function (valid) {
        // addSubUnitFunc(data);
        if(!isNew){
          var newData = dataList.map((obj)=>{
            if(obj.tableData.id == data.id)
              return data;
            return obj;
          })
          console.log(newData);
          setDataList(newData);
        }
        else
          setDataList([...dataList, data]);

        // clear textBox
        console.log("thelist isk", dataList)
        setInputs(defaultData);
        setunitValue(null);
        setIsNew(true);
        setSubmitting(false);
      }).catch(function (err) {
        // toast.error(err.errors);
        setSubmitting(true);
        console.log(err); 
      })

    }
    const onDelete =()=>{
      var newData = dataList.filter((obj)=> obj.tableData.id != inputs?.id);
      console.log(newData);
      setDataList(newData);
      // clear textBox
      setInputs(defaultData);
      setunitValue(null);
      setIsNew(true);
    }

    const onChangeData = (prop) => (e) =>{
      // setData({ ...data, [prop]: event.target.value })
      
      if(prop == "quan"){
        if(!inputs?.unitValue) toast.error("Select the unit first.")
          else setInputs({...inputs, quan: e.target.value, 
                price: e.target.value * inputs?.unitValue?.price,
                quan_tot: e.target.value * inputs?.unitValue?.perc
            
          })
      }
    } 

    const buttons = [
      <Button key="one"
        style={{color:'#fff', borderRadius:0, borderBottom:'solid 1px white'}}
          onClick={()=>{
            setInputs(defaultData)
            setIsNew(true);
          }}
        >
          <AddIcon />
      </Button>,
      <Button key="two"
        style={{color:'#fff', borderRadius:0, borderBottom:'solid 1px white'}}
        onClick={onSubmit}
      ><SaveIcon />
      </Button>,
      
      <Button key="three"
        hidden={true}
        style={{color: isNew ? '#999': '#fff', borderRadius:0, }}
        disabled={isNew}
        onClick={onDelete}
        >
          <Delete />
      </Button>,
    ];

    return(
        <Grid className={classes.root} container>
          <Toaster />
           <Grid container lg={2} md={2} sm={2} xs={2} 
        style={{ padding:'0px', textAlign: '-webkit-center',    
       }}
        >
          <ButtonGroup
        orientation="vertical"
        aria-label="vertical primary button group"
        style={{marginTop:'-10px', backgroundColor: '#cd5c5c'}}
      >
        {buttons}
      </ButtonGroup>
      </Grid>

        <Grid container style={{border: "2px"}}
        className={"container-disabled"}
        disabled={true} lg={10} md={10} sm={10} xs={10}>
           <Grid  item  lg={5} md={5} sm={5} xs={5}> 
          <Autocomplete classes={clasAo}  
                 value={inputs?.khamItem}
                onChange={(event, newValue) => { 
                    changeSelectseItem(newValue);

                  // setunitValue(newValue);  
                  // setData({...data,
                  //    unit: newValue.id,
                  //    unitName: newValue.name});
                }}
             
                id="combo-box-demo" size="small"
                options={khamItemsList} 
                getOptionLabel={(option) => (typeof option === 'string' ? option : option?.symbol)}
                disableClearable
                renderInput={(params) => <TextField  {...params} defaultValue="null" InputLabelProps={{shrink: true}}  label={t('forms:khame-items')} variant="outlined" />} 
                InputLabelProps={{className:claslbl.clrSale}}
                />
            
        </Grid>
        <Grid  item  lg={5} md={5} sm={5} xs={5}> 
          <Autocomplete classes={clasAo}  
                 value={inputs?.unitValue}
                onChange={(event, newValue) => { 
                  // setunitValue(newValue); 
                  setInputs({...inputs, unitValue: newValue, quan:1, price:1});

                }}
             
                id="combo-box-demo" size="small"
                options={inputs?.itemUnits} 
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                disableClearable
                renderInput={(params) => <TextField  {...params} defaultValue="null" InputLabelProps={{shrink: true}}  label={t('forms:unit')} variant="outlined" />} 
                InputLabelProps={{className:claslbl.clrSale}}
                />
            
        </Grid>

        <Grid item className={classes.textField} lg={3} md={3} sm={3} xs={3}>
        <TextField type="number" fullWidth variant="outlined"
            size="small" id="quan" label={t('forms:quanity')}
            disabled={!inputs?.unitValue}
            name="quan" value={inputs?.quan} InputLabelProps={{ shrink: true }}
            onChange={onChangeData('quan')}
            InputProps={{
                onKeyDown: (event) => {
                    if (event.key === 'Enter') {
                        changePurchItems(event);
                    }
                },                       
            }}
            />
        </Grid>
        <Grid item className={classes.textField} lg={3} md={3} sm={3} xs={3}>
        <TextField type="number" fullWidth variant="outlined"
            size="small" id="price" label={t('forms:price')}
            error={submitting && inputs?.purch===''}
            disabled={true}
            name="price" value={inputs?.price} InputLabelProps={{ shrink: true }}
            // onChange={changePurchItems('price')} 
            />
        </Grid>
        </Grid>
      
      
      <KhamTable 
      {...{
        dataList,
        selectRowHandle:(row)=>{
          // setInputs({...row, id:row.tableData.id.toString()});
          console.log(row);
          // setunitValue({id: row.unit, name: row.unitName,});
          selectRow(row);
          setIsNew(false);
          // setDataList
        }
      }}
      />

      </Grid>
    )
    
}