 import moment from "moment/moment"

  export const balanceTypes =[
    {id:0, name:'Stocks', label:'الكل', code:0,},
    {id:1, name:'Customers', label:'عملاء', code:1},
    {id:2, name:'Customers', label:'موردين', code:2},
  ]


   export const SAdefaultValues = {
        firstTime: true,
        type: balanceTypes[1],
        dateFrom: moment(new Date()).format('YYYY-MM-DD'),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
        branch:{id:-1, name:'الكل'},
        account: {id: '0', name:''},
        omla:{id:-1, name:'الكل'},
        // openBalance:true,
        // oldBalance: true,
        

    }