import React from "react"
import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { useState } from "react";
import { subDefaultValues } from "../../../../../../services/multi-receipt-services";
import { Fragment } from "react";
import MultiFormSub from "./multi-form-sub";
import MultiSubTable from "./multiSub-table";


export default function MultiSub(props){
    const {onChange, object,
        AutoOptions,
    accounts,
    onShow,
    formType,
 } = props;

    const rows = props.object?.rows;


    const [editing, setEditing] = useState(false);
    const [currentRow, setCurrentRow] = useState(subDefaultValues);

    const addRow = row => {
        row.id = Date.now();
        onChange(null, {name:'rows', value:[...rows, row]});
        setCurrentRow(subDefaultValues);
        console.log("th reos is", [...rows, row])
    };

    const deleteRow = id => {
        onChange(null, {name:'rows', value: rows.filter(row => row.id !== id)});
    };

    const selectRow = row => {
        setEditing(true);
        setCurrentRow({...row});
    };

    const updateRow = (id, updateRow) => {
        setEditing(false);
        onChange(null, {name:'rows', value:rows.map(row => (row.id === id ? updateRow : row))});
        setCurrentRow(subDefaultValues);
    };
    const cancelUpdate=()=>{
        setCurrentRow(subDefaultValues);
        setEditing(false);
    }

    return(

        <Fragment>
            <MultiFormSub  {...{
                        ...props,
                        object,
                        accounts: accounts,
                        onShow,
                        formType,
                        onChange, 
                        cancelUpdate,
                        currentRow,
                        addRow,
                        updateRow,
                        setCurrentRow,
                        AutoOptions,
                        editing}}/>
                        <Divider />
                        <br />
            <MultiSubTable
                {...{
                    rows,
                    selectRow,
                    deleteRow,
                    loading: false,
                }}/>
        </Fragment>

    );
}