import React,{useEffect, useState} from 'react';
import BackdropSc from '../../../services/BackdropSc';
import axios from 'axios';
import {uri}  from '../../../help/Api';
import {insh,sto} from '../../../Context/InshData';
import {insh as inshst} from '../../../Context/InshSt';
import {txtA} from '../../../Label/Lbl';
import {getLS}  from '../../../utils/useLS';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import Typography from '@material-ui/core/Typography';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import dataF from '../../../Server/dataF.json';

// cuG
import {useStyleI} from '../st/UseStyle';
// csG
import {style} from '../st/cStyle'

export default function Icons() {
  const classes = useStyleI();
  let his = useHistory();
  const timer = React.useRef();

  const [prmiScr, setPermiScr] = useState({});
  const [showSc, setShowSc] = useState(true);

  const getUPrmi = () => {
    const loc = getLS('premiScr',{});
    setPermiScr(loc);
  }

  const loader = () => 
  { timer.current = window.setTimeout(() => { setShowSc(false); }, 600); }
  useEffect( () => {
    loader();
    getUPrmi();
    return () => { clearTimeout(timer.current); };
  }, []);
  //sa

  const clickInv = (val) => {

      val?his.push({
        pathname: '/Sands',
        state: { 
          sdata:insh.sandQK, stor:{invsType:0},
          setting:{titleHed:txtA.Accounts.sandQK,cusType:1,cusN:txtA.cusSQK,accN:txtA.accSQK,
          priceNM:txtA.namePriceSal,priceTotNM:txtA.nameTotSal, PrTy_InPr:insh.IdataSa.ProcType_InvProc,
          iformid:dataF[47].id,
          routI_R_RS:'SandSQKh_R_RS_', routIS:'Inv_Sub_ToS/', /*routR:'',routRS:'',*/ },
          style:inshst.sal
        },
      })
      :his.push({
        pathname: '/Sands',
        state: { 
          sdata:insh.sandDK, stor:{invsType:1},
          setting:{titleHed:txtA.Accounts.sandSK,cusType:1,cusN:txtA.cusSSK,accN:txtA.accSSK,
          priceNM:txtA.namePriceSal,priceTotNM:txtA.nameTotSal, PrTy_InPr:insh.IdataSa.ProcType_InvProc,
          iformid:dataF[47].id,
          routI_R_RS:'SandSSKh_R_RS_', routIS:'Inv_Sub_ToS/', /*routR:'',routRS:'',*/ },
          style:inshst.sal
        },
      })

    }; // E sa



return (
  showSc?<BackdropSc/>:

  <Card className={classes.root}>
  <Grid container className={classes.container} spacing={1}>

  <Grid item lg={12} md={12} sm={12} xs={12}>
  {/* <div style={{color:'green'}} >{riv.Rw+''}</div> */}
  <CardActionArea disabled={!prmiScr.sandQKh} onClick={() => clickInv(true)} className={classes.action} 
  style={style.backColor.account}>

  {/* <div style={{color:'green'}} >{riv.Rw+''}</div> */}
  <CardContent className={classes.content} >
    {/* <Typography component="h5" variant="h5"> */}
    {/* <Typography variant="subtitle1" color="textSecondary">  */}
    <Typography>سند قبض حارجي</Typography>
    {/* </Typography>  */}
  </CardContent>
  <CardMedia
  className={classes.cover}
  title="">
  <ListAltIcon className={classes.icon} />
  </CardMedia>
  </CardActionArea>
  </Grid>

  <Grid item lg={12} md={12} sm={12} xs={12} >
  <CardActionArea disabled={!prmiScr.sandSKh}
  onClick={() => clickInv(false)}
  className={classes.action} style={style.backColor.account}>

  <CardContent className={classes.content}>
   <Typography>سند صرف خارجي</Typography>
  </CardContent>
  <CardMedia
  className={classes.cover}
  title="">
  <LowPriorityIcon className={classes.icon} />
  </CardMedia>
  </CardActionArea>
  </Grid> 
  </Grid> 

  </Card>

  );
}

