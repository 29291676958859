import React, { Suspense, useEffect } from "react";

import { useState } from "react";

import { Alert, AlertTitle } from "@material-ui/lab";
import { Button, Snackbar } from "@material-ui/core";
import moment from "moment";
import { sto } from "./Context/InshData";
import { uri } from "./help/Api";
import emailjs from '@emailjs/browser';
import axios from "axios";
import { getLS } from "./utils/useLS";

export function isExpired(startDate){
    console.log("bbbb days total is",startDate);
    if(startDate == null)
        return false;
    let sdate = moment(new Date());
    const daysTotal = sdate.diff(startDate,'days');
    console.log("the days total is",daysTotal);

    return daysTotal >= 365;
}

function SubScriptionEndingAlert(props) {
  const {startDate} = props;
  const [showAlert, setShowAlert] = useState(true);
  const [sending, setSending] = useState(true);

  useEffect(() => {
    console.log("the ", startDate);
  });

  
  const sendEmail =async (e)=>{
    e.preventDefault();
    setSending(true);
    const infoCon = getLS("infoConn", {});

    const templateParams={
        to_name:"Orax Company",
        from_name:sto?.found,
        message:`The client has number ${infoCon?.changip}required Renew his subscription. his api is ${uri}`,
    }
    console.log("the sto data is", sto)
    emailjs.send(
        "service_i41dmcc",
        "template_hh092kl",
        templateParams,
        "GroRBwoPYrc1vr7JJ"
    ).then((response)=> {console.log("the response is", response)}, (err)=> {console.log("the error is", err);});


    // send message to whatsapp 
    const whatsFormData ={
        appkey:"42b3c4c8-9319-4b5f-b3ba-7ae69daa8554xr",
        deviceid:"42b3c4c8-9319-4b5f-b3ba-7ae69daa8554xr",
        authkey:"xrlEwtowWRKMHcQDQBBf509STVg1HDCnGrdDzEuEikqmPm41yt",
        to:"+966552661598",
        message:"Hi, oraxsoft \n"+ `The client ${sto?.found } with phone number\n ${infoCon?.changip} need Renew his subscription.\n\n his api is ${uri}`
    }

    await axios.post("https://sender.oraxsoft.net/api/create/message", FormData)
    .then(res=>console.log("response is1", res))
    .catch(req=> console.log("whats app failed sending1", req));

    const mosaFD={...whatsFormData, to:"+966578754639"}
    await axios.post("https://sender.oraxsoft.net/api/create/message", mosaFD)
    .then(res=>console.log("response is 2", res))
    .catch(req=> console.log("whats app failed sending2", req));
    
    setSending(false);
    setShowAlert(false);
  }

  if(isExpired(startDate))
    return (
        <React.Fragment>
            <Snackbar open={showAlert} fullWidth autoHideDuration={60000} 
                anchorOrigin={{ vertical:"top", horizontal:"center" }} 
                    >
                <Alert fullWidth severity={"error"} style={{whiteSpace:"pre-wrap"}}
                     onClose={()=>{
                            setShowAlert(false);
                            setTimeout(() => {
                                setShowAlert(false);                                
                            }, 900000);
                            }}>
                                {/* // "عزيزي المشترك" */}
                            {/* "اشتراكك في خدمة اوراكس كلاود انتهى " */}
                            {/* "للاستمرار في الاستفادى من خدمات اوراكس كلاود يرجى تجديد اشتراكك" */}
                    <AlertTitle>
                        {/* {"Your Subscription to Orax Cloud is Ending"} */}
                        {"اشتراكك في خدمة اوراكس كلاود انتهى "}
                        </AlertTitle>
                    {/* {"Dear Subscriper"} */}
                    {" عزيزي المشترك " }
                    {/* // {"Your subscription to Orax cloud has officially ended on  `"} */}
                    {" اشتراكك في خدمة اوراكس كلاود انتهى "}
                    {moment(startDate).add(1,'years').format('YYYY-MM-DD')}
                    {" للاستمرار في الاستفادى من خدمات اوراكس كلاود يرجى تجديد اشتراكك "}
                    {/* {"contact with us "} */}
                    {"  contact with us:   "+"+966578754639"}
                    {"\n"}
                    <Button onClick={sendEmail} variant="outlined" color="primary" size="small">
                 طلب التجديد
                    </Button>
                </Alert>
            </Snackbar>
        </React.Fragment>
    );

    return <></>
}

export default SubScriptionEndingAlert;
