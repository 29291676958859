import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import SaveeIcon from "@material-ui/icons/Save";

import { useStyles } from "./sub-StoreExchange-style";
import { useEffect } from "react";
import { subTaswyaValidateSchema } from "../../../../../services/sub-taswya-service";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function SubStoreExchangeForm(props) {
  const {
    addRow,
    editing,
    currentRow,
    setCurrentRow,
    updateRow,
    cancelUpdate,
    data,
    ching: fetchingCurr,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation(["forms"]);
  const [submitting, setSubmitting] = useState(false);
  const [currentCurrency, setCurrentCurrncy] = React.useState({});

  const changeCurrentRow = (item) => {
    setCurrentRow({
      ...currentRow,
      ...item,
      mUnit: item?.units[0],
      price: item?.units[0]?.purch,
      quan: 1,
      total: item?.units[0].purch,
      // price_loc: item?.units[0].purch
    });
  };
  const getRow = (barcode) => {
    const result = data?.filter((d) => d.barcode == barcode);
    console.log("the result is", result);
    if (result.length > 0) changeCurrentRow(result[0]);
  };

  const submit = () => {
    if (editing) updateRow(currentRow.localId, currentRow);
    else addRow(currentRow);

    setCurrentCurrncy({});
  };

  return (
    <Grid item spacing={1} container>
      <Grid className={classes.textField} item lg={1} md={2} sm={2} xs={4}>
        <TextField
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          id="stay"
          label={t("barcode")}
          name="currentRow.barcode"
          InputLabelProps={{ shrink: true }}
          error={submitting && currentRow.barcode === ""}
          value={currentRow.barcode}
          onChange={(e) => {
            console.log(currentRow);
            setCurrentRow({ ...currentRow, barcode: e.target.value });
            getRow(e.target.value);
          }}
        />
      </Grid>
      <Grid className={classes.textField} item lg={2} md={3} sm={4} xs={4}>
        <Autocomplete
          loading={fetchingCurr}
          value={currentRow}
          onChange={(event, newValue) => {
            // oncurrnecyChange(newValue);
            changeCurrentRow(newValue);
          }}
          id="controllable-states-demo"
          size="small"
          disableClearable
          options={data || []}
          getOptionLabel={(option) => option?.symbol || " "}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("item")}
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid className={classes.textField} item lg={1} md={1} sm={2} xs={4}>
        <Autocomplete
          value={currentRow.mUnit}
          onChange={(event, newValue) => {
            setCurrentRow({
              ...currentRow,
              unit: { id: newValue.id, name: newValue.name },
            });
          }}
          id="combo-box-demo"
          size="small"
          options={currentRow?.units}
          getOptionLabel={(option) => option.name || " "}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              defaultValue="null"
              InputLabelProps={{ shrink: true }}
              label={t("unit")}
              variant="outlined"
            />
          )}
          // InputLabelProps={{className:claslbl.clrSale}}
        />
      </Grid>
      <Grid className={classes.textField} item lg={1} md={1} sm={2} xs={4}>
        <TextField
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          id="quan"
          label={t("quanity")}
          name="quan"
          value={currentRow?.quan}
          onChange={(e) => {
            setCurrentRow({
              ...currentRow,
              quan: e.target.value,
              total: e.target.value * currentRow?.price,
            });
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      <Grid className={classes.textField} item lg={1} md={2} sm={2} xs={4}>
        <TextField
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          id="stay"
          label={t("price")}
          name="price"
          onChange={(e) => {
            setCurrentRow({
              ...currentRow,
              price: e.target.value,
              total: e.target.value * currentRow?.quan,
            });
          }}
          value={currentRow?.price}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid className={classes.textField} item lg={1} md={2} sm={2} xs={4}>
        <TextField
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          id="total"
          label={t("total")}
          name="total"
          value={currentRow.total}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {/* <Grid className={classes.textField} item lg={2} md={2} sm={2} xs={4}>
        <TextField
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          id="stay"
          disabled={true}
          label={"السعر محلي"}
          name="currentRow.val2"
          value={currentRow.val2}
          onChange={onChangeData}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      <Grid className={classes.textField} item lg={2} md={2} sm={2} xs={4}>
        <TextField
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          id="stay1"
          disabled={true}
          label={"الإجمالي محلي"}
          name="nameE"
          value={currentRow.diff}
          InputLabelProps={{ shrink: true }}
        />
      </Grid> */}
      <Grid className={classes.textField} item lg={1} md={1} sm={1} xs={3}>
        <Button
          className={classes.buttonss}
          style={{ direction: "ltr", backgroundColor: "blue" }}
          variant="contained"
          color="primary"
          fontSize="large"
          size="medium"
          onClick={submit}
          startIcon={<SaveeIcon />}
        >
          {editing ? t("edit") : t("download")}{" "}
        </Button>
      </Grid>
      <Grid className={classes.textField} item lg={1} md={1} sm={2} xs={2}>
        {editing && (
          <Button
            className={classes.buttonss}
            style={{ direction: "ltr", backgroundColor: "blue" }}
            variant="contained"
            color="primary"
            fontSize="large"
            size="medium"
            onClick={() => {
              cancelUpdate();
            }}
          >
            {t("cancel")}{" "}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
