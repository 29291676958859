import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ReplyIcon from "@material-ui/icons/Reply";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import CssBaseline from "@material-ui/core/CssBaseline";
import MaterialTable from "material-table";
import { Mtb } from "../../../St/comps/vstyle";

import { useState } from "react";
import { colors, LinearProgress, TextField } from "@material-ui/core";
import {
  useStylesHed,
  useStylesBA,
  useStylesTF,
  useStylesContainers,
} from "../../../St/comps/UseStyle";
import { cellS, headerB, headerS, stElemnt } from "../../../St/comps/cStyle";
import { useMemo } from "react";
import useFetchObjectByPost from "../../../components/Hooks/useFetchObjectByPost";
import { uri } from "../../../help/Api";
import useFetchURL from "../../../components/Hooks/useFetchURL";

export default function EmpoyeesSearchModal(props) {
  const { open, onClose, onRowClick, type } = props;

  const url = uri + "employee";
  const { data: rows, fetching, fetchURL } = useFetchURL({ ...{ url } });
  useEffect(() => {
    console.log("th efetch", rows);
  });
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clsConts = useStylesContainers();
  var columns = [
    { title: "", field: "_Data.id", hidden: true },
    { title: "الإسم", field: "_Data.name" },
    { title: "رقم الجوال", field: "_Data.mobile" },
    { title: "تاريخ التعيين", field: "_Data.work_date" },
    { title: "رقم الهاتف", field: "_Data.tel" },
  ];

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      {
        <>
          <div className={clasBA.continarBASer}>
            <React.Fragment>
              <Grid item container spacing={0} className={clashd.rootHd}>
                <Grid item xs={12}>
                  <Chip
                    size="small"
                    className={clashd.ChipHdUser}
                    label={"بـحـث فـي " + "الموظفين"}
                    color="primary"
                  />
                </Grid>
              </Grid>

              <CssBaseline />
              <Container
                maxWidth="xl"
                style={{ direction: "rtl" }}
                className={[clsConts.rootdown, clsConts.subUpCustomer]}
              >
                <Grid item container className={[clsConts.rootdown]}>
                  <Grid item md={12} sm={12} xs={12}>
                    {fetching ? (
                      <LinearProgress />
                    ) : (
                      <MaterialTable
                        style={{ boxShadow: "none", direction: "rtl" }}
                        options={{
                          showTitle: false,
                          toolbar: false,
                          sorting: false,
                          selection: false,
                          // ToolBar Search
                          searchFieldAlignment: "left",
                          searchFieldVariant: "outlined",
                          // ToolBar header
                          showSelectAllCheckbox: false,
                          showTextRowsSelected: false,
                          pageSize: 5,
                          paginationType: "stepped",
                          showFirstLastPageButtons: false,
                          headerStyle: {
                            ...headerB,
                            backgroundColor: "#4a82b9",
                          },
                          cellStyle: cellS,
                          paging: false,

                          maxBodyHeight: Mtb.minmaxBHInvs,
                          minBodyHeight: Mtb.minmaxBHInvs,
                        }}
                        localization={{
                          body: {
                            emptyDataSourceMessage: (
                              <h3 style={{ color: "#000" }}>
                                لا يوجد تحويلات بعد
                              </h3>
                            ),
                          },
                          toolbar: { searchPlaceholder: "بـحـث" },
                        }}
                        columns={columns}
                        data={rows}
                        onRowClick={async (event, rowData) => {
                          onRowClick(rowData);
                          event.stopPropagation();
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <br />
                <br />
              </Container>
            </React.Fragment>
          </div>

          {/* ====================================== end ======================================== */}
          <React.Fragment>
            <CssBaseline />
            <AppBar
              position="fixed"
              color="primary"
              // className={clasBA.appBarBASal}
              style={{ ...stElemnt.all.appBarBA, backgroundColor: "#4a82b9" }}
            >
              <Toolbar variant="dense">
                <Hidden smUp={["sm"]}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onClose}
                  >
                    <ReplyIcon />
                  </IconButton>
                </Hidden>

                <Hidden only={["xs"]}>
                  <Button
                    style={{ color: "#fff" }}
                    onClick={onClose}
                    startIcon={<ReplyIcon />}
                  >
                    واجهة الموظفين
                  </Button>
                </Hidden>

                <div className={clasBA.growBA} />
              </Toolbar>
            </AppBar>
          </React.Fragment>
        </>
      }
    </Dialog>
  );
}
