import React, { useState } from "react";

import { TableIcons as tableIcons } from "../../../../../Elemnts/TableIcons";
import MaterialTable from "material-table";
import { Mtb } from "../../../../../St/comps/vstyle";

import { useEffect } from "react";
import { cellS, headerS } from "../../../../../St/comps/cStyle";
import { sto } from "../../../../../Context/InshData";
import Print from "@material-ui/icons/Print";
import { PictureAsPdfOutlined, ViewAgendaOutlined } from "@material-ui/icons";
import { PrintInvoiceDirectly } from "../../components/printSerivce";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 650,
    },
    tableRightBorder: {
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "solid",
      fontFamily: "Amiri-Regular",
      fontWeight: 800,
      fontSize: 14,
      
    },
    tableRowtBorder: {

        border: "solid 0.1px black",
        fontFamily: "Amiri-Regular",
        // fontWeight: 300,
        fontSize: 14,
        padding:0,
        // height:1,
      },
    rowHeader: {
      fontFamily: "Amiri-Regular",
      fontWeight: 800,
      fontSize: 14,
    },
  }));
export default function SearchInvTable(props) {
    const [num, setNum] = useState(true);
    const { data, showChosedInv, stElemnt, setOr, or, setting ,setIsClick,ished} = props;

   
    const classes = useStyles();

  const  onClickPrint=async ( row) => {
        const serializedinv = JSON.stringify({
        
            proc_id: row.proc_id,
            invN: setting.titleHed,
            invNEn: setting.titleHedEn,
            // salesman: valueSalesmen.name,
            backInv: "InvSO",
            branch: sto.branchi,
            ProcTypeInvProc: setting.PrTy_InPr,
        });
        await PrintInvoiceDirectly(
            JSON.parse(serializedinv),
            false,
            () => { }
        );
    }
   const onRowClick=(rowData) => {
    setOr({ ...or, postput: "toPut", PrAC: 0 });
    showChosedInv(false, rowData.proc_id);
    setIsClick({...ished, hed:true});
       // event.stopPropagation();
      };
      const getCell=(i) => {
       setNum(i);
           // event.stopPropagation();
          };
    return (

        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: "#319835",
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                        }}
                    >

                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"الرقم"}
                        </TableCell>
                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {" "}
                            {"التاريخ"}
                        </TableCell>
                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"الوقت"}
                        </TableCell>

                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {setting.cusN == undefined ? "" : setting.cusN}
                        </TableCell>


                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"المبلغ"}
                        </TableCell>

                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"نوع الفاتورة"}
                        </TableCell>
                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"طريقة الدفع"}
                        </TableCell>
                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"المستخدم"}
                        </TableCell>
                        <TableCell
                            className={classes.tableRightBorder}
                            align="center"
                            colSpan={2}
                        >
                            {"Action"}
                        </TableCell>
                    </TableRow>
 
                </TableHead>
                <TableBody   >
                    {data.map((row,i) => (
                        <TableRow   
                        style={{
                            color: "#000",
                            fontWeight: 500,
                            fontFamily: "Amiri-Regular",
                            
                        }}
                         key={i} 

                        > 
                            <TableCell className={classes.tableRowtBorder} align="center"  colSpan={2} 
                             onClick={() => { onRowClick(row); }}>
                                {row.id}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center"  colSpan={2} 
                             onClick={() => { onRowClick(row); }}>
                                {row.date}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center"  colSpan={2}
                             onClick={() => { onRowClick(row); }}>
                                {row.time}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center"  colSpan={2}
                             onClick={() => { onRowClick(row); }}>
                                {row.customer_name}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center"  colSpan={2}
                             onClick={() => { onRowClick(row); }}>
                                {row.net_total?.toFixed(2) ?? "-"}
                            </TableCell>

                            <TableCell className={classes.tableRowtBorder} align="center"  colSpan={2}
                             onClick={() => { onRowClick(row); }}>
                                {row.inv_type == 1 ? "اجل" : "نقدي"}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center"  colSpan={2} 
                             onClick={() => { onRowClick(row); }}>
                                {row.inv_type == 1 ? "" : row.pay_type == 1 ? "كاش" : "شبكة"}
                            </TableCell>
                            <TableCell className={classes.tableRowtBorder} align="center"  colSpan={2} 
                             onClick={() => { onRowClick(row); }}>
                                {row.username}
                            </TableCell>
                            <TableCell  className={classes.tableRowtBorder} align="center"  colSpan={1}
                            >
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    padding="0"
                                    // onChange= {getCell(false)}
                                    onClick={() => {                                  
                                        onClickPrint(row);
                                      }}> 
                                
                                    <Print size={"small"} />
                                </IconButton>
                            </TableCell>
                            
                        </TableRow>
                        

                        
                    ))}
                </TableBody>
            </Table>
          
        </TableContainer>
    );

    //   return (
    //     <MaterialTable
    //       style={{ boxShadow: "none" }}
    //       //style={{direction:'ltr'}}
    //       options={{
    //         showTitle: false,
    //         toolbar: false,
    //         sorting: false,
    //         selection: false,
    //         // ToolBar Search
    //         searchFieldAlignment: "left",
    //         searchFieldVariant: "outlined",
    //         // ToolBar header
    //         showSelectAllCheckbox: false,
    //         showTextRowsSelected: false,
    //         pageSize: 5,
    //         paginationType: "stepped",
    //         showFirstLastPageButtons: false,
    //         actionsColumnIndex: -1,
    //         paging: false,

    //         maxBodyHeight: Mtb.minmaxBHInvs,
    //         minBodyHeight: Mtb.minmaxBHInvs,
    //         // padding: 'dense',
    //         rowStyle: stElemnt.InvsR,
    //         headerStyle: stElemnt.InvsH,
    //       }}
    //       localization={{
    //         body: {
    //           emptyDataSourceMessage: (
    //             <h3 style={{ color: "#000" }}>لا توجد فواتير بعد</h3>
    //           ),
    //         },
    //         toolbar: { searchPlaceholder: "بـحـث" },
    //       }}
    //       columns={columnsInv}
    //       data={data}
    //       icons={tableIcons}
    //       onRowClick={(event, rowData) => {
    //         setOr({ ...or, postput: "toPut", PrAC: 0 });
    //         showChosedInv(false, rowData.proc_id);
    //         event.stopPropagation();
    //       }}
    //       actions={[
    //         {
    //           // icon: "save",
    //           icon: () => <Print />,
    //           // iconProps: {
    //           //   style: {
    //           //     justifyContent: "center",
    //           //     fontSize: "40px",
    //           //     // color: "red",
    //           //   },
    //           // },
    //           tooltip: "طباعة",
    //           onClick: async (event, data) => {
    //             const serializedinv = JSON.stringify({
    //               proc_id: data.proc_id,
    //               invN: setting.titleHed,
    //               invNEn: setting.titleHedEn,
    //               // salesman: valueSalesmen.name,
    //               backInv: "InvSO",
    //               branch: sto.branchi,
    //               ProcTypeInvProc: setting.PrTy_InPr,
    //             });
    //             await PrintInvoiceDirectly(
    //               JSON.parse(serializedinv),
    //               false,
    //               () => {}
    //             );
    //           },
    //         },
    //       ]}
    //     />
    //   );
}
