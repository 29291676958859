import React, {useEffect, useState, Fragment } from 'react';
import CardN from './Ref/CardN';
import Grid from '@material-ui/core/Grid';
    // xs, sm, md, lg, and 
import {useStylesMenusCont} from '../../St/Fronts/UseStyle';


export default function MenusContRef() {
  const clas = useStylesMenusCont();



  return (
    <Fragment>
      <Grid container item spacing={4} justifyContent="center">
      <Grid item lg={3} md={4} sm={4} xs={10}> <CardN/> </Grid>
      </Grid>
    </Fragment>

  );
}
