import React from "react";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import StoreTransfer from "./StoreTransfer";
import { sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";

import StoreTransferLoader from "./StoreTransferSub/StoreTransferSub-loader";
import { useEffect } from "react";
import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";

export default function StoreTransferCon(){

    const {data:newId, fetching:fetchid, fetchURL} = useFetchURL({...{
        url: uri+`StoreTransfer/newNo?branch=${sto.branchi}&&type=${1}`,
    }});
    const tb = {
        wait:false,
        SafesAll: true,
        Branches:true,
        
        stor:sto,
        parms:{}
            }

    const url = uri+'Rept';
    const {object, fetching} = useFetchObjectByPost(url,tb);



    if(fetching || !object?.hasOwnProperty('Safes'))
        return <StoreTransferLoader />

    return (
        <StoreTransfer 
        {...{
            newId,
            branches: object.Branches,
            fetchURL,
            safes: object?.Safes,
            fetchingSafes:false,
        }}/>
    )
}