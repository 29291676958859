import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { colors, Divider } from "@material-ui/core";
import {
  useStylesTF,
  useStylesTD,
  useStylesContainers,
  useStylesHed,
  useStylesLbl,
} from "../../../St/comps/UseStyle";
import { useTranslation } from "react-i18next";

export default function CustomersSubForm(props) {
  const { object, data, onChange } = props;
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const clsConts = useStylesContainers();
  const { t } = useTranslation(["forms", "translation"]);

  useEffect(()=>{
    console.log("the dta is ", object)
  })
  const onChangeData = (prop) => (event) => {
    onChange(event, { name: prop, value: event.target.value });
  };
  return (
    <Grid container lg={12} md={12}>
      {/* <Chip size="large" className={clashd.ChipHdCustomer} label={"بيانات العميل"} /> */}
      <Grid item container spacing={1} xs={12} className={[clsConts.rootup]}>
        <React.Fragment>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tax_no_build"}
              label={t("Home Id")}
              className={clasTF.rootSale}
              style={{ backgroundColor: colors.red[50] }}
              InputProps={{ className: clasTF.inputSale }}
              type="text"
              name={"tax_no_build"}
              value={object?.tax_no_build?? ""}
              onChange={onChangeData("tax_no_build")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tax_name_street"}
              label={t("Streat Name")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"tax_name_street"}
              value={object?.tax_name_street?? ""}
              onChange={onChangeData("tax_name_street")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tax_name_country"}
              label={t("Country Name")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"tax_name_country"}
              value={object.tax_name_country?? ""}
              onChange={onChangeData("tax_name_country")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tax_name_city"}
              label={t("City Name")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"tax_name_city"}
              value={object.tax_name_city?? ""}
              onChange={onChangeData("tax_name_city")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tax_name_district"}
              label={t("District Name")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"tax_name_district"}
              value={object.tax_name_district?? ""}
              onChange={onChangeData("tax_name_district")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tax_zip_code"}
              label={t("Zip Code")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"tax_zip_code"}
              value={object.tax_zip_code?? ""}
              onChange={onChangeData("tax_zip_code")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider style={{ margin: "10px" }} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tax_additional_address_no"}
              label={t("Additional Address No")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"tax_additional_address_no"}
              value={object.tax_additional_address_no?? ""}
              onChange={onChangeData("tax_additional_address_no")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tax_vat_registration_no"}
              label={t(" VatRegistration NO")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"tax_vat_registration_no"}
              value={object.tax_vat_registration_no?? ""}
              onChange={onChangeData("tax_vat_registration_no")}
            />
          </Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}
