import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import Container from '@material-ui/core/Container';
import {useStylesContainers,useStylesHed, useStylesSetting} from '../../../St/comps/UseStyle';

import  { Toaster } from 'react-hot-toast';
import basicDataAPI from '../../../api/basicData';
import useFormDataCRUD from '../../../components/Hooks/useEDSCURD';
import DFSettingsForm from './default-settings-form';

import Swipeable from '../../../Fronts/Main/Swipeable';
import { Button } from '@material-ui/core';
import axios from 'axios';
import { uri } from '../../../help/Api';

const URL = uri+'SysSettings';

  function updateDefaults(data){

    let nData = {
      branch: data?.branch?.id,
      safe: data?.safe?.id,
      stock: data?.stock?.id,
      unit: data?.unit?.id,
      taxGroup: data?.taxGroup?.id,
      omla: data?.omla?.id,
    }
      return axios.put(URL, nData);
  }

  function applyTaxGroup(taxGroupId){

    if(taxGroupId)
      return axios.post(URL, taxGroupId);
  }

function getDfault(collection){
  const index = collection?.findIndex(c=> c.IS_Default);
  if(index >= 0){
    return collection[index];
  }
  else if(index ==-1){
    return collection[0]
  }
  return null
}
export default function DefaultSettings(props){

  const {data, fetchURL} = props;
    const classesStt = useStylesSetting();

  const defaultValues = { 
          branch:getDfault(data?.branches), 
          safe:getDfault(data?.safes), 
          stock :getDfault(data?.stocks), 
          unit :getDfault(data?.units), 
          taxGroup :getDfault(data?.taxGroups), 
          omla :getDfault(data?.omlat), 
        };


      const {
        onChange,
        submit,
        update,
        object,
        
    } = useFormDataCRUD(
        applyTaxGroup,
        updateDefaults,
        undefined,
        undefined,
        defaultValues,
        null,
        fetchURL,
    );


  const clsConts = useStylesContainers();
  const clashd = useStylesHed();


  const styheader = 
  {textAlign:'center', backgroundColor: '#fff', height: '100vh',direction:'rtl' }

return (

<React.Fragment>
    
    <CssBaseline />
    
    <Container maxWidth="sm">
        <Toaster />

    <Typography component="div" style={styheader}>
    <Grid item container> 
        <Grid item xs={12}>
            <Chip size="small" className={clashd.ChipHdSale} label={" الإعدادات الإفتراضية"} color="primary" />
        </Grid>
        <Grid container xs={12} >
            <Grid item container lg={12} md={12} sm={12} sx={12} className={[clsConts.rootup,]}>
            <Grid item container spacing={2} xs={12} className={[clsConts.subUp,clsConts.subUpC,]}>
              <DFSettingsForm {
            ...{
                data,
                object,
                updateItems: submit,
                onChange
            }
        } />
        <Grid item container className={classesStt.rootSett}>
    
          <Button variant="contained" className={classesStt.btn}  
            color="primary" fontSize="large" 
             onClick={()=>{update(object)}}
            >
            {'حــفـظ الـتـعـديـلات'}
            </Button>
            <Swipeable />
    </Grid>
            </Grid>
            </Grid>
            
        </Grid>
    </Grid>
    </Typography>
  </Container>
</React.Fragment>
 );
 
}