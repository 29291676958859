import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),

  },
  textField3: {
    width: '100%',
    margin: 5,

  },
  textField: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color: 'navy', fontSize: '0.8rem', },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: 'navy', fontSize: '0.7rem',
      lineHeight: '0.5rem',

    }
    , "& .MuiOutlinedInput-inputMarginDense":
      { paddingLeft: 1, paddingRight: 1, paddingTop: 10, paddingBottom: 10, textAlign: 'center', fontSize: '1rem' }, marginBottom: 4,marginTop:4,
  },

  root: {
    direction: 'rtl',
    marginBottom: theme.spacing(1),
    // padding: theme.spacing(1),
    boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    border: 'solid 1px #009688',
    padding: 5,
    // width: '100%',
    // height: '100%', 
  },

  buttonss: {
    backgroundColor: '#0009F',
  },
}));


export  const cellS = {
    padding: '6.9px 0px',
    textAlign: '-webkit-center',
    margin: '0px',
    whiteSpace: 'nowrap',
    width: '0px',
    color: '#000',
    marginLeft: 2, marginRight: 2,

  }
export const headerS = {
    height: '15px',
    color: '#fff',
    textAlign: '-webkit-center',
    margin: '0px',
    whiteSpace: 'nowrap',
    width: '0px',
    backgroundColor: '#0009F0',
    padding: '7px 1px',
    marginLeft: 1, marginRight: 1,
  }