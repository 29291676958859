import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import ORestFormSub from "./opening-restrictions-form-sub";
import ORestSubTable from "./opening-restrictions-table-sub";
import { subDefaultValues } from "../../../../../services/restrictionService";
import { getDefaultOmla } from "../../../../../services/omlat-services";
import { getAmountNumber } from "../../../../../utils/FormatNumbers";
export default function ORestSub(props) {
  const { onChange, object, AutoOptions, accounts, onShow, formType } = props;

  const rows = props.object?.rows;

  const [editing, setEditing] = useState(false);
  const def = {
    ...subDefaultValues,
    acc_omlat: AutoOptions.omlats,
    acc_omla: getDefaultOmla(AutoOptions?.omlats),
  };
  const [currentRow, setCurrentRow] = useState(def);

  const addRow = (row) => {
    row.id = Date.now();
    onChange(null, { name: "rows", value: [...rows, row] });
    setCurrentRow(def);
  };

  const deleteRow = (id) => {
    onChange(null, {
      name: "rows",
      value: rows.filter((row) => row.id !== id),
    });
  };

  const selectRow = (row) => {
    setEditing(true);
    setCurrentRow({ ...row });
  };

  const updateRow = (id, updateRow) => {
    updateRow.dept=getAmountNumber(updateRow.dept);
    updateRow.credit=getAmountNumber(updateRow.credit);
 
    setEditing(false);
    onChange(null, {
      name: "rows",
      value: rows.map((row) => (row.id === id ? updateRow : row)),
    });
    setCurrentRow(def);
  };
  const cancelUpdate = () => {
    setCurrentRow(def);
    setEditing(false);
  };

  return (
    <Fragment>
      <ORestFormSub
        {...{
          ...props,
          object,
          accounts,
          onShow,
          formType,
          onChange,
          cancelUpdate,
          currentRow,
          addRow,
          updateRow,
          setCurrentRow,
          AutoOptions,
          editing,
        }}
      />
      <ORestSubTable
        {...{
          rows,
          selectRow,
          deleteRow,
          loading: false,
        }}
      />
    </Fragment>
  );
}
