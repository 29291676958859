import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import { useStylesHed } from "../../../St/comps/UseStyle";
import EmpoyeesForm from "./Empoyees-form";
import { Toaster } from "react-hot-toast";
import EmpoyeesAppBar from "./Empoyees-appBar";
import customerAPI from "../../../api/customer";
import useFormDataCRUD from "../../../components/Hooks/useEDSCURD";
import {
  defaultCustomer,
  defaultEmployee,
  SyncLocalStorage,
} from "../../../services/global-services";
import { Paper } from "@material-ui/core";
import { insh as inshst } from "../../../Context/InshSt";
import GroupIcon from "@material-ui/icons/Group";
import { useStyleC } from "../../../Fronts/Menues/st/UseStyle";
import { style } from "../../../Fronts/Menues/st/cStyle";
import { useTranslation } from "react-i18next";
import { useAPI } from "../../../api/useAPI";

export default function Empoyees(props) {
  const { data, type, fetchURL, isDialog } = props;
  const { createFun, updateFun, removeFun } = useAPI("employee");
  const { t } = useTranslation(["translation", "forms"]);
  const defaultValues = {
    ...defaultEmployee,
    acc_no: props.newAccount,
    type: type,
  };

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    createFun,
    updateFun,
    removeFun,
    undefined,
    defaultValues,
    null,
    fetchURL
  );

  const title = t("تعريف موظف");
  const title1 = t("إضافة موظف");
  const clashd = useStylesHed();
  const classes = useStyleC();
  const styheader = {
    textAlign: "center",
    backgroundColor: "#f1f1fa",
    direction: "rtl",
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Toaster />

      <Typography component="div" style={styheader}>
        <Grid item container style={{ paddingBottom: "50px" }}>
          <Grid item xs={12}>
            <Typography
              variant={"h5"}
              component="h3"
              style={{
                marginBlock: 10,
              }}
            >
              {formType == "edit" ? t("تعديل بيانات موظف") : title1}
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item lg={9} md={8} sm={12}>
              <Container
                component={Paper}
                maxWidth={true}
                style={{
                  // padding: "30px",
                  // paddingRight:'0px',
                  // backgroundColor: colors.[50],
                  paddingTop: "0px",
                  borderColor: "#4a82b9",
                  borderTopWidth: "10px",
                  borderLeftWidth: "0px",
                  borderBottomWidth: "0px",
                  borderRightWidth: "10px",
                  borderStyle: "solid",
                }}
              >
                <Grid container justify="space-between">
                  <Grid item>
                    <Chip
                      size="large"
                      className={clashd.ChipHdUser}
                      label={t("بيانات الموظف الأساسية")}
                    />
                  </Grid>
                  <Grid item>
                    <GroupIcon
                      className={classes.iconCard}
                      style={style.color.user}
                    />
                  </Grid>
                </Grid>

                <EmpoyeesForm
                  {...{
                    ...props,
                    onChange,
                    submit,
                    update,
                    remove,
                    submitting,
                    errorMessage,
                    onShow,
                    onEnd,
                    object,
                    formType,
                  }}
                />
              </Container>
            </Grid>

            {/* <EmpoyeesTable {...{...props, onShow, Empoyees: data?.Empoyees}}/> */}
          </Grid>
        </Grid>
        <EmpoyeesAppBar
          {...{
            ...props,
            onChange,
            defaultValues,
            submit,
            update,
            remove,
            submitting,
            errorMessage,
            onShow,
            onEnd,
            object,
            formType,
          }}
        />
      </Typography>
    </React.Fragment>
  );
}
