import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {uri} from '../../../../help/Api';
import { inshCustomer } from '../../../../Server/Context';
import { insh,sto } from '../../../../Context/InshData';
import {tbReGitData} from '../../../../Context/InshPublic';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ReplyIcon from '@material-ui/icons/Reply';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import AddBoxIcon from '@material-ui/icons/AddBox';
// import Button from '@material-ui/core/Button';
import { useHistory,useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import Fab from '@material-ui/core/Fab';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useStylesCAB,useStylesBA,useStylesSN,useStylesUp,
  useStylesAuto,useStylesLbl,useStylesTF} from '../../../../St/comps/UseStyle';
import {stElemnt} from '../../../../St/comps/cStyle'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomersDialog({ DialogOpened,DialogClose,content }){
  // export default function CustomersDialog(props){
    // alert('cus')
    
  const clasup = useStylesUp();
  const clasAo = useStylesAuto();
  const claslbl = useStylesLbl();
  const clasTF = useStylesTF();
  const clasCAB = useStylesCAB();
  const clasBA = useStylesBA();
  const clasSN = useStylesSN();
  let his = useHistory();

  const inshLoadAndSucc = {save:false}
  const [loading, setLoading] = useState(inshLoadAndSucc);
  const [success, setSuccess] = useState(inshLoadAndSucc);
  const timer = React.useRef();
  const buttonClassnameSave = clsx({
    [clasCAB.buttonSuccessCAB]: success.save,
  });


// F CL Alert All
  const clickDialog = () => {
    // his.push({ pathname: '/' })
    DialogClose(false);

  };

// ================================================ dilog
// const [open, setOpen] = useState(false);
// const [scroll, setScroll] = React.useState('paper');

  /*-- select --*/
    const [valueAct, setValueAct] = useState([]);
    const [valueCountry, setValueCountry] = useState([]);
    const [valueCity, setValueCity] = useState([]);
    const [valueArea, setValueArea] = useState([]);

 const [nextAccno, setnextAccno] = useState(0);
 const [customer, setCustomer] = useState({});
 // const [accIndex, setAccIndex] = useState({});

 //  const [options, setOptions] = useState([]);
 const [gets, setGets] = useState(insh.tbname);
 const location = useLocation();
 const [setting, setSetting] = useState(insh.setting.customer);

 const getData = async (t) => {
  /* table */
  const tb = {
    Acts:true, Areas:true, Cities:true, Countries:true, Customers:true,

    parms:{
      id:t
      },
    autos:{Acts:true, Areas:true, Cities:true, Countries:true,},
  }
  /* parms */
  await axios.post(`${uri}Res`,tb)
  .then(res => {
    setSetting(content.setting)
    setGets(res.data)
    // setGets(res.data)
    // setnextAccno(res.data.tables);
    }).catch(error => { });
  }

 const getCust = async (t) => {
  // await axios.get(`${uri}Customers/type?type=${1}`).then(res => {
    await axios.get(`${uri}Customers/type?type=${t}`).then(res => {
    getData(t);
  setnextAccno(res.data);
  })
 }

 const handLoad = () => {
  setValueAct({});
  setValueArea({});
  setValueCity({});
  setValueCountry({});
  // alert(content.setting.cusType)
  getCust(content.setting.cusType);
  inshCustomer.type=content.setting.cusType;
  setCustomer(inshCustomer);  
 }

  useEffect( () => {
    handLoad();
   },[])

   const ButtonLoading = (type) => {
      if(type==='save') { setSuccess({...success, save:false}); setLoading({...loading, save:true}); }
  };

  const ButtonSuccess = (type) => {
    if(type==='save') {  setSuccess({...success, save:true}); setLoading({...loading, save:false});
    timer.current = window.setTimeout(() => { setSuccess({...success, save:false}); }, 2000); }
  };

  //  const [valueAuto, setValueAuto] = useState([
  //    Act:{},
  //  ],
  //  );
  //  const changAutos = (newValue) => {                          
  //   setValueAuto({...valueAuto, Act:newValue})
  // }

   const changAutoAct = (newValue) => {                          
     setValueAct(newValue); setCustomer({...customer, act:newValue.id})
    //  setAccIndex({...accIndex,Code:nextAccno});
   }

   const changAutoCountry = (newValue) => {
    setValueCountry(newValue); setCustomer({...customer, country:newValue.id})
  }

  const changAutoCity = (newValue) => {
    setValueCity(newValue); setCustomer({...customer, city:newValue.id})
  }

  const changAutoArea = (newValue) => {
    setValueArea(newValue); setCustomer({...customer, area:newValue.id})
  }

  const befourPost1 = async () => {
  //  console.log('---customer---'); console.log(customer);
  }

const befourPost = (e) => {
  if(customer.name === '') 
  showSnack({ vertical:'top', horizontal:'right', severit:'warning', text:'يجب إدخال إسم العميل', time:2000, });
  else if(customer.act === '') 
  showSnack({ vertical:'top', horizontal:'right', severit:'warning', text:'إختر المجموعة', time:2000, });
  else PostToCust('save');
}

 
const storgeing = async () => {

await axios.post(uri+'Res',tbReGitData)
 .then(res => { 
 localStorage.setItem('sto', JSON.stringify(res.data));

}).catch(error => { 
//  console.log('nooooo sttorgeing');
 alert('لم يتم التحديث')

})

//  }, 3000);

}

const afterPost = () => {
  storgeing();
  handLoad();
  clickDialog();
}

let name='';
let type='';
let tax='';

const PostToCust = async (btn) => {
  // await e.preventDefault();  
   await ButtonLoading(btn);
  //  alert(`${uri}Customers`)
  //  console.log(customer);
  //await axios.get(`${uri}Customers`, customer)
  await axios.get(`${uri}Customr/type?type=${valueAct.id}`)
  .then(res => {
    //alert(valueAct)
    res.data.forEach(data => {
      if (data.name == customer.name) {
        name = data.name
      }
      if (data.type == customer.type) {
        type = data.type
      }
      if (data.tax_no == customer.tax_no) {
        tax = data.tax_no
      }

    });
  })
  .catch(error => {  })

  // if ((name != customer.name )  (type != valueAct.id) ) {
  if ((name != customer.name ) ) {
  await axios.post(`${uri}Customers`, customer).then(res => {afterPost(); }).catch(error => {  })
  }
  else
  {
    alert('تأكد من البيانات اذا تم ادخالها مسبقاً')
  }
  //  await axios.post(`${uri}Accounts_Index`, accIndex).then(res => { console.log('res Accou');  }).catch(error => {  })
   await ButtonSuccess(btn); 
  };


const inshSnack = { openSnak: false, vertical: '', horizontal: '', severit:'', text:'', time:null,}
const [snack, setSnack] = React.useState(inshSnack);
const { vertical, horizontal, openSnak } = snack;
const showSnack = (newState) => { setSnack({ openSnak: true, ...newState }); };

const hideSnack = (event, reason) => {setSnack({ ...snack, openSnak: false }); };

const changCust = (e) => {
  // console.log(e.target.name)
  setCustomer({ ...customer, [e.target.name]: e.target.value });
  // if(e.target.name==='name') setAccIndex({...accIndex,AName:e.target.value});
}

// const fhs = () => {
//   console.log(customer)
//   // console.log(valueAuto)
// }

const buttonClassnameSavePMD = clsx({ [clasCAB.buttonSuccessCAB]: success.savePMD, });


return (
  <Dialog fullScreen open={DialogOpened} onClose={clickDialog} TransitionComponent={Transition}>

        <Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnak} autoHideDuration={snack.time}
        onClose={hideSnack} key={vertical + horizontal} className={clasSN.snackbarSN} >
         <Alert onClose={hideSnack} severity={snack.severit}> {snack.text} </Alert> 
       </Snackbar>
       

     <React.Fragment>
       {/* <Button onClick={fhs}>slkdf</Button> */}
       <CssBaseline />
       <AppBar position="fixed" color="primary" className={clasBA.appBarBACustm}
       >
         <Toolbar  className={clasBA.ToolbarBA} variant="dense" >


         <Hidden smUp={['sm']}>
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={clickDialog}>
            <CloseIcon />
          </IconButton>
          {/* <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={() => handOpenDw(TransitionDown)}>
            <SearchIcon />
          </IconButton> */}
          <IconButton edge="start" color="inherit" /*disabled={permis.Ne}*/ aria-label="open drawer" onClick={handLoad}>
            <AddBoxIcon />
          </IconButton>
        </Hidden>

        <Hidden only={['xs']}>
          <Button style={{color:'#fff'}} onClick={clickDialog} startIcon={<ReplyIcon />} >الفاتورة</Button>
          {/* <Button style={{color:'#fff'}} onClick={() => handOpenDw(TransitionDown)} startIcon={<SearchIcon />}>البحث في الفواتير</Button> */}
          <Button style={{color:'#fff'}} onClick={handLoad} startIcon={<AddBoxIcon />}>جديد</Button>
        </Hidden>

           <div className={clasBA.growBA} />

    
     <Hidden smUp={['sm']}>
       <div className={clasCAB.wrapperCAB}>
        {/* <Fab size="medium" color="primary" style={stElemnt.iconBtnSal} className={buttonClassnameSave} onClick={befourPost}>
           {success.save ? <CheckIcon /> : <SaveIcon />}
         </Fab> */}

         <Fab size="medium" color="primary" /*disabled={permis.Sa}*/ 
       style={{background:'#b906b6',...stElemnt.all.iconBtn}} className={buttonClassnameSave} 
         onClick={befourPost}>
          {success.save ? <CheckIcon /> : <SaveIcon />}
        </Fab>

         {loading.save && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
       </div>
      </Hidden>
       <Hidden only={['xs']}>
          <div className={clasCAB.wrapperCAB}>
              <Button variant="contained" color="primary" size="large"
                style={{background:'#b906b6',...stElemnt.all.iconBtn}}
                className={buttonClassnameSavePMD} disabled={loading.save}
                onClick={befourPost} startIcon={<SaveIcon />}>
                حفظ بدون طباعة
              </Button>
              {loading.save && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
            </div>
     </Hidden>

         </Toolbar>
       </AppBar>
     </React.Fragment>



<div className={clasBA.continarBACustm}>
<React.Fragment> 
<CssBaseline />
<Container maxWidth="xl" className={clasup.Aboverootup}> 

        <Grid container spacing={1} className={clasup.rootup}>

        <Grid item lg={3} md={6} sm={6} xs={6}>
        <TextField fullWidth margin="dense" id="name" name="name" label="الإسم" 
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.name} onChange={changCust}/>
        </Grid>

        <Grid item lg={3} md={6} sm={6} xs={6}>
        <TextField fullWidth margin="dense" id="tax_no" name="tax_no" label="الرقم الضريبي" 
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.tax_no} onChange={changCust}/>
        </Grid>

        <Grid item lg={3} md={6} sm={6} xs={6}>
        <TextField fullWidth margin="dense" id="nextAccno" name="nextAccno" label="رقم الحساب في الدليل"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={nextAccno} />
        </Grid>

        <Grid item lg={3} md={6} sm={6} xs={6}>
          <Autocomplete classes={clasAo}
                 value={valueAct}
                // onChange={(event, newValue) => { changAutos(newValue) }}
                onChange={(event, newValue) => { changAutoAct(newValue) }}
                id="controllable-states-demo" size="small"
                options={gets.Acts}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                style={{  }}
                disableClearable
                renderInput={(params) => <TextField fullWidth {...params} label="المجموعة" size="small" 
                variant="outlined" InputLabelProps={{className:claslbl.clrSale}} />}
              />
        </Grid> 

        <Grid item lg={2} md={4} sm={4} xs={6}>
        <TextField fullWidth margin="dense" id="mobile" name="mobile" label="رقم الجوال" 
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.mobile} onChange={changCust}/>
        </Grid>

        <Grid item lg={2} md={4} sm={4} xs={6}>
        <TextField fullWidth margin="dense" id="cust_DiscValue" name="cust_DiscValue" label="مبلغ الخصم"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.cust_DiscValue} onChange={changCust}/>
        </Grid>

        <Grid item lg={2} md={4} sm={4} xs={6}>
        <TextField fullWidth margin="dense" id="cust_PresValue" name="cust_PresValue" label="نسبة الخصم"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.cust_PresValue} onChange={changCust}/>
        </Grid>

        <Grid item lg={2} md={4} sm={4} xs={6}>
        <TextField fullWidth margin="dense" id="maxdepit" name="maxdepit" label="حد الدين"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.maxdepit} onChange={changCust}/>
        </Grid>
        
        <Grid item lg={2} md={4} sm={4} xs={6}>
        <TextField fullWidth margin="dense" id="IValue" name="IValue" label="الرصيد الإفتتاحي"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.IValue} onChange={changCust}/>
        </Grid>

        <Grid item lg={2} md={4} sm={4} xs={6}>
        <TextField fullWidth margin="dense" id="national_id" name="national_id" label="رقم الهوية"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.national_id} onChange={changCust}/>
        </Grid>

        <Grid item lg={2} md={4} sm={6} xs={6}>
        <TextField fullWidth margin="dense" id="tel" name="tel" label="رقم الهاتف 1"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.tel} onChange={changCust}/>
        </Grid>

        <Grid item lg={2} md={4} sm={6} xs={6}>
        <TextField fullWidth margin="dense" id="fax" name="fax" label="رقم الهاتف 1"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.fax} onChange={changCust}/>
        </Grid>

        <Grid item lg={2} md={4} sm={4} xs={4}>
          <Autocomplete classes={clasAo}
                 value={valueCountry}
                onChange={(event, newValue) => { changAutoCountry(newValue) }}
                id="controllable-states-demo" size="small"
                options={gets.Countries}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                style={{  }}
                disableClearable
                renderInput={(params) => <TextField fullWidth {...params} label="الدولة" size="small" 
                variant="outlined" InputLabelProps={{className:claslbl.clrSale}} />}/>
        </Grid>

        <Grid item lg={2} md={4} sm={4} xs={4}>
          <Autocomplete classes={clasAo}
                 value={valueCity}
                onChange={(event, newValue) => { changAutoCity(newValue) }}
                id="controllable-states-demo" size="small"
                options={gets.Cities}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                style={{  }}
                disableClearable
                renderInput={(params) => <TextField fullWidth {...params} label="المدينة" size="small" 
                variant="outlined" InputLabelProps={{className:claslbl.clrSale}} />}/>
        </Grid>

        <Grid item lg={2} md={4} sm={4} xs={4}>
          <Autocomplete classes={clasAo}
                 value={valueArea}
                onChange={(event, newValue) => { changAutoArea(newValue) }}
                id="controllable-states-demo" size="small"
                options={gets.Areas}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                style={{  }}
                disableClearable
                renderInput={(params) => <TextField fullWidth {...params} label="المنطقة" size="small" 
                variant="outlined" InputLabelProps={{className:claslbl.clrSale}} />}/>
        </Grid>

        <Grid item lg={2} md={4} sm={6} xs={6}>
        <TextField fullWidth margin="dense" id="email" name="email" label="البريد الإلكتروني"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.email} onChange={changCust}/>
        </Grid>

        <Grid item lg={2} md={4} sm={6} xs={6}>
        <TextField fullWidth margin="dense" id="address" name="address" label="العنوان"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.address} onChange={changCust}/>
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={6}>
        <TextField fullWidth margin="dense" id="car_number" name="car_number" label="وصف 1"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.car_number} onChange={changCust}/>
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={6}>
        <TextField fullWidth margin="dense" id="car_shace" name="car_shace" label="وصف 2"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.car_shace} onChange={changCust}/>
        </Grid>

        <Grid item lg={4} md={12} sm={12} xs={12}>
        <TextField multiline fullWidth margin="dense" id="notes" name="notes" label="ملاحظات"
        variant="outlined" className={clasTF.rootSale} InputProps={{ className: clasTF.inputSale }}
        value={customer.notes} onChange={changCust}/>
        </Grid>

        </Grid>

  </Container>
  </React.Fragment>
</div>

</Dialog>
);

}
