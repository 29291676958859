import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { uri } from "../../../help/Api";
import { useStylesTD, useStylesTF } from "../../../St/comps/UseStyle";


export default function ConfirmAdmin({ confirm, others }) {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  // useEffect(() => {
  //   console.log("the items is", sto);
  // });
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const checkConfirm = () => {
    axios
      .get(
        uri +
          `UserPermission/getConfirm?username=${username}&&password=${password}`
      )
      .then((response) => {
        confirm(response.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("لا يوجد لديك صلاحية للوصول");
      });
  };

  return (
    <Dialog
      open={true}
      // onClose={onClose}
      id="ringtone-menu"
      maxWidth={"xs"}
      {...others}
    >
      <DialogContent
        style={{
          textAlign: "center",
          paddingTop: "20px",
          paddingLeft: "45px",
          // border: "solid 1px black",
          height: 100,
        }}
        id="alert-dialog-slide-description"
      >
        <Container justifyContent="center">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id={"username"}
            label={"اسم المستخدم"}
            className={clasTF.rootSale}
            InputProps={{ className: clasTD.input }}
            type="text"
            name={"username"}
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </Container>
        <Container justifyContent="center">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id={"password"}
            label={"كلمة المرور"}
            className={clasTF.rootSale}
            InputProps={{ className: clasTD.input }}
            type="password"
            name={"password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={checkConfirm}>
          دخول
        </Button>
      </DialogActions>
    </Dialog>
  );
}
