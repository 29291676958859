import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import { uri } from "../../../../help/Api";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import AccountSearchModal from "../accounts-search-modal";
import { IconButton } from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { subRestrictionValidateSchema } from "./restriction-validate-schema";
import { getAmountNumber } from "../../../../utils/FormatNumbers";

const useStyles = makeStyles((theme) => ({
  textField3: {
    width: "100%",
    padding: 5,
  },
  textField44: {
    width: "50%",
    marginTop: 5,
    marginBottom: -5,
  },

  textField: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "navy",
      fontSize: "0.8rem",
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: "navy",
      fontSize: "0.7rem",
      lineHeight: "0.5rem",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 6,
      paddingBottom: 4,
      textAlign: "center",
      fontSize: "1rem",
    },
  },

  root: {
    direction: "rtl",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
    border: "solid 1px #009688",
  },
}));

const url = uri + "Costcenter";
const defaultData = {
  res_id: "",
  dept: "",
  credit: "",
  acc_no: "",
  acc_name: "",
  notes: "",
  branch: "",
  omla: "",
  omlaval: "",
  center: "-1",
  centerView: "بدون",
  ref_no: "",
  RestDate: new moment(new Date()).format("YYYY-MM-DD"),
  sub_type: "",
};

export default function RestrictionAccountForm(props) {
  const { addSubRestrictionFunc } = props;
  const classes = useStyles();
  const [valueCostcenter, setValueCostcenter] = React.useState([]);
  const [openSearchMdl, setOpenSearchMdl] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState(defaultData);

  const { data: optionsCostcenter, fetching } = useFetchURL({
    ...{
      url,
      mapFunction: (items) => {
        return [{ Name: "بدون", id: -1 }, ...items];
      },
      dependencyArray: [],
    },
  });

  const onSubmit = () => {
    subRestrictionValidateSchema
      .validate(data, { abortEarly: false })
      .then(function (valid) {
        addSubRestrictionFunc({
          ...data,
          dept: getAmountNumber(data.dept),
          credit: getAmountNumber(data.credit),
        });

        // clear textBox
        setData(defaultData);
        setValueCostcenter(optionsCostcenter[0]);
        setSubmitting(false);
      })
      .catch(function (err) {
        toast.error(err.errors[0]);
        setSubmitting(true);
        console.log(err.errors);
      });
  };
  const onChangeData = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };

  return (
    <Grid className={classes.root} item spacing={1} container>
      <Grid item className={classes.textField} lg={2} md={2} sm={2} xs={3}>
        <TextField
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          id="stay"
          label="رقم الحساب"
          error={submitting && data.acc_no === ""}
          name="nameE"
          value={data.acc_no}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item className={classes.textField} lg={2} md={2} sm={2} xs={4}>
        <TextField
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          id="stay"
          label="اسم الحساب"
          name="acc_name"
          value={data.acc_name}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1}>
        <IconButton
          className={classes.textField3}
          color="primary"
          aria-label="upload picture"
          component="label"
          onClick={() => {
            setOpenSearchMdl(true);
          }}
        >
          <Search />
        </IconButton>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={4}>
        <Autocomplete
          classes={classes.textField44}
          value={valueCostcenter}
          onChange={(event, newValue) => {
            setValueCostcenter(newValue);
            setData({
              ...data,
              center: newValue.id,
              sub_type: newValue.id,
              centerView: newValue.Name,
            });
          }}
          id="combo-box-demo"
          size="small"
          options={optionsCostcenter}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.Name
          }
          style={{}}
          disableClearable
          loading={fetching}
          renderInput={(params) => (
            <TextField
              {...params}
              defaultValue="null"
              InputLabelProps={{ shrink: true }}
              label="مركز التكلفة"
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item className={classes.textField} lg={2} md={2} sm={2} xs={4}>
        <TextField
          type="number"
          fullWidth
          variant="outlined"
          size="small"
          id="stay"
          label=" مدين "
          error={submitting && data.dept === ""}
          disabled={data.credit !== ""}
          name="dept"
          value={data.dept}
          InputLabelProps={{ shrink: true }}
          onChange={onChangeData("dept")}
        />
      </Grid>

      <Grid item className={classes.textField} lg={2} md={2} sm={2} xs={4}>
        <TextField
          type="number"
          fullWidth
          variant="outlined"
          size="small"
          id="stay"
          label=" دائن "
          error={submitting && data.credit === ""}
          disabled={data.dept !== ""}
          name="credit"
          value={data.credit}
          InputLabelProps={{ shrink: true }}
          onChange={onChangeData("credit")}
        />
      </Grid>

      <Grid item className={classes.textField} lg={2} md={2} sm={2} xs={4}>
        <TextField
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          id="ref_no"
          label=" رقم المرجع "
          name="ref_no"
          value={data.ref_no}
          InputLabelProps={{ shrink: true }}
          onChange={onChangeData("ref_no")}
        />
      </Grid>
      <Grid className={classes.textField} item lg={1} md={2} sm={2} xs={4}>
        <TextField
          fullWidth
          variant="outlined"
          id="RestDate"
          label="التاريخ"
          type="date"
          size="small"
          format="YYYY-MM-DD"
          value={data.RestDate}
          onChange={onChangeData("RestDate")}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item className={classes.textField} lg={4} md={4} sm={5} xs={5}>
        <TextField
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          id="stay"
          label=" البيان"
          name="notes"
          value={data.notes}
          InputLabelProps={{ shrink: true }}
          onChange={onChangeData("notes")}
        />
      </Grid>

      <Grid item lg={1} md={3} sm={3} xs={3}>
        <Button
          className={classes.textField}
          fullWidth
          variant="outlined"
          color="primary"
          fontSize="large"
          size="medium"
          onClick={onSubmit}
          endIcon={<SaveIcon />}
        >
          حفظ .
        </Button>
      </Grid>

      <AccountSearchModal
        {...{
          open: openSearchMdl,
          handleClose: () => {
            setOpenSearchMdl(false);
          },
          onSelectAccount: (account) => {
            setData({
              ...data,
              acc_no: account.barcode,
              acc_name: account.symbol,
            });

            setOpenSearchMdl(false);
          },
        }}
      />
    </Grid>
  );
}
