import React, { useState, useEffect } from "react";
import {
    Button,
    Chip,
    colors,
    Container,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";

import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
    useStylesHed,
    useStylesSpDial,
    useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { uri } from "../../../../../help/Api";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import ListItemsForm from "./ListItemForm";
import ItemsListTable from "./ListItemsTable";
import { sto } from "../../../../../Context/InshData";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
    },

    headerTitle: {
        // backgroundColor: "#f18584",
        textAlign: "center",
        fontSize: 16,
        width: "100%",
        fontWeight: "bold"
    },

    blCard: {
        backgroundColor: "#fff",
        borderColor: "#f18584",
    },
    root3: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: "#f18584",
        direction: "rtl",
        marginTop: 5,
        padding: 5,
        // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
        boxShadow:
            "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
    },
}));


export default function ListItems(props) {
    const { loadItems,
        getItemsData,
        AutoOptions, } = props;

    const SearchActivityReports = {
        group: { id: -1, name: 'الكل' },
        typeCurr: { id: -1, name: 'الكل' },
        branch: { id: -1, name: "الكل" },
        symbol: '',
        takei: 50,
        currShow: 'C',
        barcode: '',
        curr: { id: -1, name: "الكل" },
    };

    const [rptParams, setRptParams] = React.useState(SearchActivityReports);
    const classes = useStyles();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const { t } = useTranslation(["forms", "translation"]);
    const [style, setStyle] = useState(inshst.custm);

    const [data, setData] = useState(loadItems);
    const [fetching, setFetching] = useState(false);
    const [sum, setSum] = useState(false);
    const viewHandle = async () => {
        setData([]);
        setFetching(true);
        await axios
            .get(
                uri +
                `RptSafes/ItemsSearch?currShow=${rptParams?.currShow}
        &&curr_type=${rptParams.typeCurr?.id}
    &&groupid=${rptParams.group?.id}
    &&take=${rptParams?.takei}
    &&item_code=${rptParams?.barcode}
    &&item_name=${rptParams?.symbol}
    &&emp=${sto.empi}`
            )
            .then((res) => {
                setData(res.data);
                //console.log(" the data is", res.data);
                setFetching(false);
            })
            .catch((err) => {
                setFetching(false);
                //console.log(err);
                setData([]);
            });
    };

    const his = useHistory();
      
    const onChangeDataSearch = (propName) => (event) => {
        setRptParams({ ...rptParams, [propName]: event.target.value });
        viewHandle();
    };

    const onChangeData = (propName) => (event) => {
        setRptParams({ ...rptParams, [propName]: event.target.value });
    };
    const onChangeAutoData = (propName, newValue) => {
        setRptParams({ ...rptParams, [propName]: newValue });
    };
    useEffect(() => {

      }, [loadItems]);
    return (
        <div className={classes.root}>
            {/* <Grid container item xs={12}>
                <Typography
                    size="small"
                    className={classes.headerTitle}
                    color="primary"
                >
                    {"ابحث عن صنف"}
                </Typography>
            </Grid> */}
            <Typography
                component="div"
                style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
            >
                <Container
                    component={Paper}
                    maxWidth={false}
                    style={{
                        padding: "7px",
                        paddingBottom: "0px",
                        borderBottomColor: inshst.colors.cust,
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                    }}
                >

                    <ListItemsForm
                        {...{
                            AutoOptions,
                            rptParams,
                            onChangeData,
                            onChangeAutoData,
                            fetching,
                            clasui,
                            viewHandle,
                            style,
                            his,
                            setRptParams

                        }}
                    />

                </Container>

                <br />
                <Container
                    component={Paper}
                    maxWidth={false}
                    style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",

                    }}
                >
                    <Grid container justifyContent="center">
                        <Grid item container justifyContent="center" xs={12}>
                            <BeatLoader loading={fetching} color={colors.red[50]} />
                            {!fetching && (
                                <ItemsListTable
                                    {...{
                                        data: data ? data : [],
                                        getItemsData
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </Typography>
        </div>
    );
}
