import React, { useState, useRef, Component, Fragment } from "react";
import ReactToPrint , { useReactToPrint }  from "react-to-print";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeIcon from "@material-ui/icons/Home";
import { useStylesSpDial } from "../../../St/Reviews/UseStyle";
import { stElemnt } from "../../../St/Reviews/cStyle";
import jsPDF from "jspdf";
import { callAddFont } from "../../amiri-font";
import ShareIcon from "@material-ui/icons/Share";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Grid } from "@material-ui/core";
import RestrictionContainer from "./RestrictionPrintCon";
class ComponentToPrint extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <Container
        maxWidth="xl"
        style={{ justifyContent: "center", display: "inline-grid" }}
      >
        <CssBaseline />
        <RestrictionContainer />
      </Container>
    );
  }
}

const RevRestriction = () => {
  const componentRef = useRef();
  const reportDownloadRef = useRef();
  const clasSp = useStylesSpDial();

  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(true);
  };
  let his = useHistory();

  const back = (path) => {
    path ? his.push({ pathname: "/" }) : his.goBack();
  };
  const styleCont = {
    backgroundImage: "linear-gradient(60deg, #4e9e84 , #2688ba)",
  };

  const shareData = async () => {
    jsPDF.API.events.push(["addFonts", callAddFont]);
    // var doc = new jsPDF("p", "pt", [1120, 850]);
    var doc = new jsPDF("portrait", "pt", "a4"); 
    //doc.setFont("Amiri-Regular", "normal");
    doc.setFont("Helvetica", "bold");

   

    let mfile;
    await doc.html(reportDownloadRef.current, {
      html2canvas:{
        width:200,
        height:297,
        scale:0.75,
        },
      async callback(doc) {
        var blob = await doc.output("blob");
        mfile = new File([blob], "Restriction.pdf", { type: blob.type });
      },
    });

    if (navigator.share && navigator.canShare({ files: [mfile] })) {
      await navigator.share({
        title: "hello to orax app",
        text: "welcome this is text",
        url: "orax.app/",
        files: [mfile],
       // pageStyle:"a4",
      });
    } else {
      alert("cant share files");
    }
  };

  const handleGeneratePdf = async () => {
    jsPDF.API.events.push(["addFonts", callAddFont]);
    var doc = new jsPDF("portrait", "pt", "a4");
    // var doc = new jsPDF("p", "pt", [1120, 850]);
    // doc.setFont("Amiri-Regular", "normal");
    doc.setFont("Helvetica", "bold");
    // doc.setFontSize(14);
    doc.html(reportDownloadRef.current, {
      html2canvas:{
        width:200,
        height:297,
        scale:0.75,
        },
       // pageStyle:"a4",
      async callback(doc) {
        await doc.save("Restriction");
      },
    });
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    html2canvas:{
      width:200,
      height:297,
      scale:0.75,
      },

  });
  const [childMountCount, setChildMountCount] = useState(0);
  const handleChildMountComplete = () => {
    setChildMountCount((prevCount) => prevCount + 1);
  };


  const actions = [
    {
      icon: (
        // <ReactToPrint
        //   trigger={() => <PrintIcon />}
        //   content={() => componentRef.current}
        // />
        <PrintIcon className={clasSp.ico} onClick={handlePrint} />
      ),
      name: "طباعـة",
    },
    {
      icon: (
        <ArrowDownward className={clasSp.ico} onClick={handleGeneratePdf} />
      ),
      name: "تنزيل",
    },
    {
      icon: <ShareIcon className={clasSp.ico} onClick={shareData} />,
      name: "مشاركة",
    },
    {
      icon: (
        <ArrowBackIcon className={clasSp.ico} onClick={() => back(false)} />
      ),
      name: "رجـوع",
    },
  ];

  return (
    <div className={clasSp.root}>
      <Grid item container justifyContent="center" style={styleCont}>
        <Grid item style={{ backgroundColor: "#fff", width: "21cm"}}>
          <CssBaseline />
          <div
            ref={reportDownloadRef}
            lang={"ar"}
            style={{
              fontFamily: "Amiri-Regular",
              fontStyle: "bold",
              letterSpacing: "normal",
            }}
          >
            <ComponentToPrint
              ref={componentRef}
              onUnmountComplete={handleChildMountComplete}
            />
          </div>
        </Grid>
      </Grid>

      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial}
        hidden={false}
        icon={
          <SpeedDialIcon openIcon={<HomeIcon />} onClick={() => back(true)} />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action, i) => (
          <SpeedDialAction
            key={i}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default RevRestriction;
