import React, { useRef, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  colors,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import { Close, CropFree, Refresh } from "@material-ui/icons";
import icondrawer from "../../../../../assets/img/apple-icon.png";
import { inshs } from "../../../../../Context/Local";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import { useRefresh } from "../../../../../comps/Settings/ReData";
import { LoadingRefresh } from "../../../../../services/LoadingPage";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backgroundColor: colors.grey[200],
    height: "50px",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginRight: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, toggle, onSidebarOpen, isFullscreen, ...rest } = props;
  const classes = useStyles();
  // const [show, toggle] = useToggle(false);
  const [notifications] = useState([]);
  const { fetch, fetching, isError } = useRefresh();
  const his = useHistory();
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={icondrawer} width="30" height="30" />
        </RouterLink>
        <LoadingRefresh
          open={fetching}
          msg={"جاري تحديث البيانات "}
          onclose={() => {}}
        />
        <div className={classes.flexGrow} />
        <IconButton color="red" disabled={fetching} onClick={fetch}>
          <Refresh />
        </IconButton>
        <IconButton
          color="red"
          onClick={() => {
            his.push({
              pathname: `/invoices`,
              state: inshs.Sales.sal,
            });
          }}
        >
          <LocalMallIcon />
        </IconButton>
        <Hidden>
          <IconButton
            color="black"
            onClick={() => {
              toggle();
            }}
          >
            {isFullscreen ? <Close /> : <CropFree />}
          </IconButton>
        </Hidden>
        <Hidden>
          <IconButton color="black" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
