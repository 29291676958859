import axios from "axios";
import { useState, useEffect } from "react";

export default function useFetchObjectByPost(url, tb, dependencyArray) {
  const [object, setObject] = useState({});
  const [fetching, setFetching] = useState(false);

  async function fetch() {
    setFetching(true);

    await axios
      .post(url, tb)
      .then((res) => {
        setObject(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setFetching(false);
  }

  useEffect(() => {
    fetch();
    return () => {};
  }, dependencyArray || []);

  return { object, fetching, fetch };
}
