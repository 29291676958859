import React from 'react';
import useFetchURL from '../../../../components/Hooks/useFetchURL';
import { uri } from '../../../../help/Api';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import CurrencyDetailsRPTForm from './CurrencyDetailsRPT-form';
import toast from 'react-hot-toast';

 const defaultValues = {
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
    branch: {id:-1, name:'الكل'},  
    safe:{id:-1, name:'الكل'},
    curr: {id:-1, name:''},
  }

export default function CurrencyDetailsRPT() {

  let his = useHistory();
const [rptParams, setRptParams] = React.useState(defaultValues);

  const {data, fetching, fetchURL} = useFetchURL({...{
    url: uri+`RptSafes/curr-details?branch=${rptParams.branch.id}
    &&safe=${rptParams.safe.id}
    &&curr_id=${rptParams.curr.id}
    &&dateF=${rptParams.dateFrom}
    &&dateT=${rptParams.dateTo}`,
    dependencyArray:[]
  }});

  const fetchData=()=>{
    if(rptParams.curr.id==-1)
      toast.error('يجب اختيار الصنف');
    else
      fetchURL();
  }
  const onChangeData =(propName)=>(event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };

  const printHandle = ()=>{
    const info ={...data, dateFrom: rptParams.dateFrom,
      dateTo: rptParams.dateTo}
    his.push({ pathname: '/', 
    state:info,});
    window.location.reload(false);
  }

  return (
    <CurrencyDetailsRPTForm 
    {...{
      data:data? data: [],
      onChangeData,
      printHandle,
      rptParams,
      setRptParams,
      fetchData,
      fetching,
    }}/>
  );




};