import React, { Fragment } from 'react';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudIcon from '@material-ui/icons/Cloud';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
// cuG
import {useStyleC} from '../st/UseStyle';
// csG
import {style} from '../st/cStyle'

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} style={{height:100,width:100}} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h5" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


export default function CardN() {
  const classes = useStyleC();
  const dept = () => window.location.reload(false);
  
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress == 100 ? 100 : prevProgress + 10));
    }, 800);
  }, []);

  return (
    <Fragment>
    <Card className={classes.root}>
      <CardActionArea onClick={dept}  disabled={progress != 100}>
      <CardMedia className={classes.media} title="" >
        {
          progress != 100
          ? <CloudIcon className={classes.iconCard} style={style.color.cloudwhit}/>
          : <CloudDoneIcon className={classes.iconCard} style={style.color.clouddone}/>
        }
         
      </CardMedia>
      <CardContent className={classes.content} style={style.color.sal}>
        {progress != 100
        ?<Grid item>
          <Typography variant="h5" style={style.color.cloudwhit}>
          {'لحضة من فضلك'}
         </Typography> 
          <Typography variant="caption" style={style.color.cloudwhit}>
          {'نحن نقوم بتجهيز بعض الإعدادات من أجلك'}
         </Typography> 
        </Grid>
        :<Typography variant="h5" style={{...style.color.clouddone,...{marginBottom:18}}}>
          {'إنقر لبدأ الإستخدام'}
        </Typography>
        }
      </CardContent>
      </CardActionArea>
      <CardActions disableSpacing className={classes.actions}>
      </CardActions>
    </Card>

    <Grid container item spacing={4} justifyContent="center" style={{marginTop:30}}>
     <CircularProgressWithLabel value={progress} />
    </Grid>

    </Fragment>

  );
}

