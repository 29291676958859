import axios from 'axios'
import { uri } from '../help/Api'

export const STORE_Trans_URL =uri+'StoreTransfer';

export function list(){
    return axios.get(STORE_Trans_URL)
}

export function getNewNo(){
    return axios.get(STORE_Trans_URL+'/newNo')
}

export function create(data){
    return axios.post(STORE_Trans_URL, data);
}

export function update(data){
    return axios.put(STORE_Trans_URL, data)
}
export function remove(id){
    return axios.delete(STORE_Trans_URL+`/${id}`)
}

export default{ getNewNo, list, create, update, remove}