import React from "react";
import { uri } from "../../../help/Api";
import useFetchObjectByPost from "../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import BackdropScI from "../../../services/BackdropScI";
import Customers from "./customers";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import {URL} from '../../../api/customer';
import { Dialog } from "@material-ui/core";
import { useState } from "react";
export default function CustomersDialog(props){

    const {open, onClose, type} = props;
    const tb = {wait:true,
        Areas:true,
        Cities:true, Countries:true, 
        salesmen: true,
        ActsCust: true,
        parms:{
             icustmtype: type,

    },
        autos:{Areas:true, Cities:true, Countries:true, salesmens: true,}
    }
    const url = uri+'Res';

    const {object:data, fetching: fetchinData, fetch} =  useFetchObjectByPost(url, tb);
    const {data: newAccount, fetching, fetchURL} = useFetchURL({...{
        url: URL+`/newAccount?typeAccount=${type}`
    }});

    useEffect(()=>{
        console.log("the type is", type)
    })

      if(fetchinData || !data?.ActsCust || !newAccount || fetching)
        return <Dialog fullScreen open={open} onClose={onClose}> </Dialog>
    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <Customers
        {...{
            data:{...data, Acts: data?.ActsCust},
            newAccount,
            fetchinData,
            fetchURL,
            isDialog: true,
            type,
            onClose,
        }}/>
        </Dialog>
        
    )
}

   