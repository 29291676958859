import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../../help/Api";

import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { sto } from "../../../../Context/InshData";
//import {useStylesHed} from '../../../St/comps/UseStyle';
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import { useBody,useHeader } from "../../invCompany/style";
function FooterM(props) {
    const { data, InvForFatora, found } = props;
    const locDinv = JSON.parse(localStorage.getItem("datainv")) || {};
    const clas = useInvAF();
    const classHeader = useHeader();

    const clasm = useInvMain();

    const styf = { fontWeight: "bold" ,fontSize:"0.7rem" ,};
    const styfE = { fontWeight: "bold" ,fontSize:"0.55rem" ,};
    const styfC = { fontSize: "1rem",fontWeight: "bold",fontFamily: "Amiri-Regular",paddingBottom:"0.3rem"};

    const sty = {
        cntr: { textAlign: "center", paddingTop: 10 },
        lft: { textAlign: "left", padingLeft: 5 },
        rit: { textAlign: "right", padingRight: 5 },
        dirR: {
            direction: "rtl",
            color: "black",
        },
        dirL: { direction: "ltr" },
    };

    return (
        <Grid item container 
        //className={classHeader.headerContainer}
       
        style={{ padding: 5, fontWeight: "bold" ,fontFamily: "Amiri-Regular",marginLeft: "0cm"}}
      //  style={{ fontWeight: "bold",fontFamily: "Amiri-Regular",}}
        >
            <Grid
                item
                container
                xs={12}
                // spacing={1}
                style={{ fontWeight: "bold",  }}
            >
                <Grid item  xs={12} container  >
                    
                    <Grid item xs={6}   className={clasm.minleft}> 
                        <div style={styfC}>{found.Disc1_footer_en}</div>
                        <div style={styfE}>{found.Disc2_footer_en}</div>
                        <div style={styfE}>{found.Disc3_footer_en}</div>
                        <div style={styfE}>{found.Disc4_footer_en}</div>

                    </Grid>

                    <Grid item xs={6} className={clasm.minrit}>
                        <div style={styfC}>{found.Disc1_footer_ar}</div>
                        <div style={styf}>{found.Disc2_footer_ar}</div>
                        <div style={styf}>{found.Disc3_footer_ar}</div>
                        <div style={styf}>{found.Disc4_footer_ar}</div>

                    </Grid>
                      <Grid item xs={5} style={{ fontFamily: "Amiri-Regular" ,paddingTop:"0.3rem" }}>
                        {"توقيع المستلم" + "     " + " Receivrs Sign "}
                    </Grid>
                    <Grid item xs={5} style={{ fontFamily: "Amiri-Regular" , paddingTop:"0.3rem"}}>
                        {" "}
                        {"  البائع" + "     " + " Salesmans Sign "}
                    </Grid>
                     
                    <Grid item xs={2} style={{ fontFamily: "Amiri-Regular" , paddingTop:"0.3rem"}}>
                        {sto.usernames}
                    </Grid>
                    {/* <Grid item xs={2}>
                        {InvForFatora?.salesman}
                    </Grid> */}
                    
                 
                </Grid>
            
            </Grid>

        </Grid>

  );
}
export default FooterM;

