import { Chip, colors, Container, Grid, Paper } from "@material-ui/core";
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { CardActionArea, Button } from "@material-ui/core";
import theminable from "../../assets/img/thimanabl.png";
import { green } from "@material-ui/core/colors";
import { useEffect } from "react";
import { useState } from "react";
import { BeatLoader } from "react-spinners";

function ProductCard(props) {
  const { item, onClick, count } = props;
  const [loading, setLoading] = useState(false);
  return (
    <div
      sx={{ maxWidth: 345 }}
      style={{
        padding: `${count == 0 ? "1px" : "0px "}`,
        backgroundColor: "#fff",
        color: "#000",
        direction: "rtl",
        border: `solid ${count == 0 ? "1px #ccc" : "2px " + colors.green[500]}`,
      }}
    >
      <CardActionArea
        onClick={(e) => {
          onClick(item);
          // e.preventDefault();
        }}
      >
        <Grid
          container
          style={{
            position: "absolute",
          }}
          justify="space-between"
        >
          <Grid item>
            {" "}
            <Typography
              style={{
                color: "#fff",
                borderRadius: 10,
                paddingInline: 2,
                backgroundColor: colors.grey[500],
              }}
              //   variant="outlined"
              size="small"
            >
              {item.sale_price}
            </Typography>
          </Grid>
          <Grid item>
            {" "}
            {count > 0 && (
              <Typography
                style={{
                  color: "#fff",
                  borderRadius: 0,
                  paddingInline: 10,
                  fontSize: 22,
                  backgroundColor: colors.green[400],
                }}
              >
                {count}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid xs={12} container justifyContent="center">
          <BeatLoader loading={loading} color={colors.green[300]} />
        </Grid>
        {item.img != null ? (
          <CardMedia
            component="img"
            height="80"
            style={{ padding: 1 }}
            src={`data:image/jpeg;base64,${item.img}`}
            alt="green iguana"
          />
        ) : (
          <CardMedia
            component="img"
            height="80"
            style={{ padding: 2 }}
            image={theminable}
            alt="green iguana"
          />
        )}

        <CardContent
          style={{
            paddingTop: "1px",
            marging: 0,
            padding: 2,
            minHeight: 55,
            maxHeight: 55,
          }}
        >
          <Typography style={{ padding: 1, fontSize: 16, fontWeight: "900" }}>
            {item.symbol}
          </Typography>
          {item.nameEN != "" && (
            <Typography variant="subtitle2" component="div">
              {item.nameEN}
            </Typography>
          )}
        </CardContent>
        {/* <CardActionArea>
          <Button
            style={{
              color: "#fff",
              borderRadius: 0,
              paddingInline: 10,
              fontSize: 12,
              backgroundColor: colors.green[400],
            }}
            onClick={() => onClick(item)}
          >
            {"8"}
          </Button>
        </CardActionArea> */}
      </CardActionArea>
    </div>
  );
}

export default ProductCard;

// const reGet = async (takei, igroupid, sSearch) => {
//     if (sSearch != "" || igroupid != -1 || takei == -2) {
//       takei != -1
//         ? setAuto({ ...auto, group: "", groupSub: "" })
//         : setSearchedCurrShow(sSearch);

//       // alert(igroupid)
//       const tb = {
//         Currencies: true, // wait:true,
//         parms: {
//           scurrenciestype: "I",
//           // currShow:"D",
//           safe: valueSafes.id,
//           currShow: "M",
//           currType: stor.invsType > 2 ? -1 : 2,
//           igroupid: igroupid,
//           takei: -1,
//           sSearch: sSearch,
//         },
//         autos: {},
//       };
//       await axios
//         .post(uri + "Res", tb)
//         .then((res) => {
//           setcuRows(res.data.Currencies);
//         })
//         .catch((error) => {
//           /*console.log('no');*/
//         });
//     }
//   };

// const loadSto = async (cusType) => {
//     const locSto = getLS("sto", {});
//     setGets(locSto);
//     // eds changed
//     setBarcodes_eds(locSto.Currencies.map((c) => c.barcode));
//     /* go Func we need check Condsh */
//     let valCust = cusType == 1 ? locSto.CustomersA[0] : locSto.CustomersM[0];

//     loadIdata(valCust, locSto.types, locSto?.Banks);

//     // const safe = getSafeDefault(locSto.permisApp.user_id, locSto.Users);

//     setValueSafes(locSto.Safes[0]);
//     setValueStock(locSto.Stocks[0]);
//     const defOmla = getDefaultOmla(locSto.omlats);
//     setOmlaValue(defOmla);
//     setOptionsCustomers(cusType == 1 ? locSto.CustomersA : locSto.CustomersM);
//     setValueCustomers(valCust);
//     setCurrencies(locSto.Currencies);

//     setcuRows(
//       locSto.Currencies?.map((c) => {
//         return { ...c, sale_price: c.sale_price * defOmla.exchange };
//       })
//     );
//     setGroups(locSto.groups);
//     setFavGroups(locSto.CurrenciesFav);
//     setCurrenciesToInv(locSto.CurrenciesToInv);
//     const inveTypeIndex = getInveTypeIndex(valCust.Cust_inv_type);

//     setValueType(locSto.types[inveTypeIndex]);
//     setValueTypeFltr(locSto.typesFltr[0]);
//   };
