import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Chip, colors } from "@material-ui/core";
import { Done } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  chip: {
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "10px",
  },
}));

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ChipsSelect = (props) => {
  const { items, selectedId, onSelect } = props;
  const classes = useStyles();
  const [selectId, setSelectId] = useState(selectedId);
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        padding: "10px",
        margin: "0px",
      }}
      component="ul"
    >
      {items.map((x) => {
        return (
          <ListItem key={x.id}>
            {x.id == selectId ? (
              <Chip
                className={classes.chip}
                label={x.label}
                icon={<Done />}
                style={{ backgroundColor: colors.indigo[300], color: "#fff" }}
                clickable
              />
            ) : (
              <Chip
                className={classes.chip}
                label={x.label}
                variant="outlined"
                color={"primary"}
                onClick={(e) => {
                  setSelectId(x.id);
                  onSelect(x);
                }}
              />
            )}
          </ListItem>
        );
      })}
    </Container>
  );
};

export default ChipsSelect;
