import React from "react";
import Typography from "@material-ui/core/Typography";
import Swipeable from "../../../../Fronts/Main/Swipeable";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import {
  Button,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Paper,
  Slider,
  Switch,
  TextField,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import {
  FieldsTableNameA4Invoice,
  opacities,
} from "../../../../services/settings-services";
import { SketchPicker } from "react-color";

let hed = null;
let heddef = null;

export default function PdfSettingsA4(props) {
  const { onChange, object, handleUpdate, index } = props;
  function valuetexthed(value) {
    hed = value;
    return `${value}°C`;
  }

  const handleChange = (prop) => (event) => {
    onChange(null, {
      name: prop,
      value: event.target.value,
    });
  };
  const handleChangeObject = (prop) => (event) => {
    onChange(null, {
      name: prop,
      value: { ...object[prop], text: event.target.value },
    });
  };
  const handleChangeObjectAuto = (prop, newValue) => {
    onChange(null, {
      name: prop,
      value: { ...object[prop], fieldName: newValue },
    });
  };
  const handleChangeVisible = (prop) => (event) => {
    onChange(null, {
      name: prop,
      value: { ...object[prop], visible: event.target.checked },
    });
  };

  return (
    <div>
      {index == 1 ? (
        <>
          <Grid container spacing={6}>
            <Grid item container lg={8} md={6} sm={6} xs={12} spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="watermarkText"
                  label="Watermark Text"
                  // className={classes.textField}
                  name="watermarkText"
                  value={object?.cloudInvoiceSettings?.watermarkText}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    onChange(null, {
                      name: "cloudInvoiceSettings",
                      value: {
                        ...object["cloudInvoiceSettings"],
                        watermarkText: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Autocomplete
                  value={object?.cloudInvoiceSettings?.watermarkOpacity}
                  onChange={(event, newValue) => {
                    onChange(null, {
                      name: "cloudInvoiceSettings",
                      value: {
                        ...object["cloudInvoiceSettings"],
                        watermarkOpacity: newValue,
                      },
                    });
                    // onChangeAutoData("user", newValue);
                    // setGData({ ...gData, user: newValue });
                  }}
                  id="combo-box-demo"
                  size="small"
                  // className={classes.textField}
                  options={opacities}
                  //getOptionLabel={(option) => option.name}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  style={{}}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="الشفافية"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item ex={12} style={{ height: "65px" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={object?.dsplyHeadFootr}
                        onChange={(e) => {
                          onChange(null, {
                            name: "dsplyHeadFootr",
                            value: e.target?.checked,
                          });
                        }}
                        name="gilad"
                      />
                    }
                    label={" إخفاء رأس الفاتورة "}
                  />
                </FormGroup>
                {object?.dsplyHeadFootr && (
                  <FormHelperText>
                    {"يمكنك الأن طباعة الفاتورة على ورقك الرسيمة"}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Accordion expanded={true} style={{ marginBottom: "20px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    // onClick={goStor}
                    style={{ background: "#d9dcf078" }}
                  >
                    <Typography>إرتفاع رأس الفاتورة</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ width: "100%", display: "block" }}>
                    <Typography id="discrete-slider" style={{ width: "100%" }}>
                      رأس الفاتورة
                      <Slider
                        // sasInvHeadr: res.data.sasInvHeadr,
                        // sasInvFootr: res.data.sasInvFootr
                        // heddef=values.sasInvHeadr;
                        // footdef=values.sasInvFootr;
                        defaultValue={heddef}
                        value={object?.sasInvHeadr}
                        onChange={(e, newValue) => {
                          onChange(null, {
                            name: "sasInvHeadr",
                            value: newValue,
                          });
                        }}
                        getAriaValueText={valuetexthed}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={5}
                        marks
                        min={10}
                        max={200}
                      />
                    </Typography>
                    {/* 
      <Typography id="discrete-slider" style={{width:'100%',}}>
        ذيل الفاتورة
        <Slider 
        defaultValue={footdef} 
        // value={values.sasInvFootr}
        getAriaValueText={valuetextfoot}
        aria-labelledby="discrete-slider" valueLabelDisplay="auto"
        step={5} marks min={50} max={150} />
      </Typography> */}
                  </AccordionDetails>
                </Accordion>
                {/* <SettingsPrinter /> */}
              </Grid>
            </Grid>
            {/* <Grid xs={1}></Grid> */}
            <Grid
              item
              lg={4}
              md={6}
              sm={6}
              xs={12}
              style={{ textAlign: "center" }}
            >
              <Typography
                size="medium"
                style={{ fontSize: "16px", margin: "5px" }}
              >
                BackGround Color
              </Typography>
              <Typography>
                <SketchPicker
                  color={object?.colors?.backGroundColor}
                  onChangeComplete={(color) => {
                    onChange(null, {
                      name: "colors",
                      value: { ...object?.colors, backGroundColor: color.hex },
                    });
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Typography variant={"h4"}>
            إعداد أعمدة الجدول في الفاتورة الكبير
          </Typography>
          <Divider />
          <br />
          <Grid item container spacing={1}>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="المنتج عربي"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.colmProductDescA}
                onChange={handleChange("colmProductDescA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="المنتج En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.ProductName?.text}
                onChange={handleChangeObject("ProductName")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={object?.ProductName?.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  handleChangeObjectAuto("ProductName", newValue);
                  // changAutos("group_id", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("ProductName")}
                checked={object?.ProductName?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الوحدة"
                id="outlined-size-small3"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.colmUnitA}
                onChange={handleChange("colmUnitA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="Unit En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.unit?.text}
                onChange={handleChangeObject("unit")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={object?.unit?.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  handleChangeObjectAuto("unit", newValue);

                  // changAutos("group_id", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("unit")}
                checked={object?.unit?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الكمية"
                id="outlined-size-small4"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.colmQtyA}
                onChange={handleChange("colmQtyA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الكمية En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.qty?.text}
                onChange={handleChangeObject("qty")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={object?.qty?.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  handleChangeObjectAuto("qty", newValue);
                  // changAutos("group_id", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("qty")}
                checked={object?.qty?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="السعر"
                id="outlined-size-small5"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.colmUnitPriceA}
                onChange={handleChange("colmUnitPriceA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="السعر En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.price?.text}
                onChange={handleChangeObject("price")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={object?.price?.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  handleChangeObjectAuto("price", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("price")}
                checked={object?.price?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الخصم"
                id="outlined-size-small6"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.colmDiscountA}
                onChange={handleChange("colmDiscountA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الخصم En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.discount?.text}
                onChange={handleChangeObject("discount")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={object?.discount?.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  handleChangeObjectAuto("discount", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("discount")}
                checked={object?.discount?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="قيمة الضريبة"
                id="outlined-size-small8"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.colmVatValueA}
                onChange={handleChange("colmVatValueA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الضريبة En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={object?.tax?.text}
                onChange={handleChangeObject("tax")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={object?.tax?.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  handleChangeObjectAuto("tax", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("tax")}
                checked={object?.tax?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item ex={12} style={{ height: "65px" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={object?.dsplyColmSerialnumb}
                      onChange={(e) => {
                        onChange(null, {
                          name: "dsplyColmSerialnumb",
                          value: e.target?.checked,
                        });
                      }}
                      name="gilad"
                    />
                  }
                  label={"إظهار  عمود الباركود"}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </>
      )}
      <Swipeable />
    </div>
  );
}
