import React,{useEffect, useState} from 'react';
import axios from 'axios';
import {uri}  from '../../../help/Api';
import {sto} from '../../../Context/InshData';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import dataF from '../../../Server/dataF.json';

// cuG
import {useStyleI} from '../st/UseStyle';

let nl=true;
export default function Icons() {
  const classes = useStyleI();

  const [riv, setRiv] = useState({Rw:nl});
  const [rivR, setRivR] = useState({Rw:nl});
  
  useEffect( async() => {
 
    // alert(uri+`UserPermission?id=${sto.idi}&&f=${dataF[46].id}`)
      await axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[46].id}`).then(res => {
        setRiv({...riv, Rw:!res.data.IS_Preview});
      }).catch(error => {  }) 
      await axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[48].id}`).then(res => {
        setRivR({...rivR, Rw:!res.data.IS_Preview});
      }).catch(error => { })
  }, []);


  // function TransitionUp(props) { return <Slide {...props} direction="up" />; }
  // const [transition, setTransition] = React.useState(undefined);

  // const [isOpenSal, setIsOpenSal] = useState(false);
  // const clickOSal = (Transition) => {
  //   setTransition(() => Transition);
  //    setIsOpenSal(!isOpenSal); 
  //   }; // E sa

  // //rsa
  // const [isOpenSalRet, setIsOpenSalRet] = useState(false);
  // const clickOSalRet = (Transition) => {
  //   setTransition(() => Transition);
  //   setIsOpenSalRet(!isOpenSalRet); 
  // }; // E rsa

  return (

<Card className={classes.root}>
<Grid container className={classes.container} spacing={1}>


{/* 
<Grid item lg={12} md={12} sm={12} xs={12} >
<CardActionArea disabled={rivR.Rw} className={classes.action} style={style.backColor.RetSal}>

<CardContent onClick={() => clickOSalRet(TransitionUp)} className={classes.content}>
  مرتجع المبيعات
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <LowPriorityIcon className={classes.icon} />
</CardMedia>
</CardActionArea>
</Grid> */}



{/* <Grid item lg={6} md={12} sm={12} xs={6}>
<CardActionArea className={classes.action}>

<CardContent className={classes.content}>
  -------------
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <ListAltIcon className={classes.icon} />
</CardMedia>
</CardActionArea>
</Grid>

<Grid item lg={6} md={12} sm={12} xs={6}>
<CardActionArea className={classes.action}>

<CardContent className={classes.content}>
 ---------------
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <LowPriorityIcon className={classes.icon} />
</CardMedia>
</CardActionArea>
</Grid>*/}

</Grid> 


{/* <SalesInv_Ret isUpOpened={isOpenSalRet} transitionUp={transition} handCloseUp={() => setIsOpenSalRet(false)} /> */}
    </Card>
  );
}


    // <Card className={classes.root} >
    //   <CardActionArea className={classes.root}>

    //     <CardContent className={classes.content}>
    //       {/* <Typography component="h5" variant="h5">
    //       {/* <Typography variant="subtitle1" color="textSecondary"> */}
    //       مرتجع المبيعات
    //       {/* </Typography> */}
    //     </CardContent>
    //   <CardMedia
    //     className={classes.cover}
    //     title=""
    //   >
    //      <LocalMallIcon style={iconSal} />
    //   </CardMedia>
    //   </CardActionArea>
    // </Card>