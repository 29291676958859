import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import clsx from "clsx";
import { uri } from "../../../help/Api";
import { insh, sto, wsto } from "../../../Context/InshData";
import { autos } from "../../../Context/Local";
import moment from "moment";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ScannerIcon from "@material-ui/icons/Scanner";
import SaveIcon from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import ReplyIcon from "@material-ui/icons/Reply";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory, useLocation } from "react-router-dom";
// import MaterialTable, { MTableToolbar, MTableBodyRow  } from "material-table";
import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
// import tableIcons from "../../../../Elemnts/TableIcons";
import { TableIcons as tableIcons } from "../../../Elemnts/TableIcons";
import Container from "@material-ui/core/Container";
import useBreakpoint from "../../../utils/useBreakpoint";
import {
  useStylesContainers,
  useStylesHed,
  useStylesAuto,
  useStylesTF,
  useStylesTD,
  useStylesBA,
  useStylesLbl,
  useStylesUiElemment,
  useStylesCAB,
  useStylesSpDial,
  useStylesDPM,
} from "../../../St/comps/UseStyle";
// import {} from './UseStyle';
import { cellS, headerS, stElemnt } from "../../../St/comps/cStyle";

//=====================================================================

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function ItemsDialog({ DialogOpened, DialogClose, locats }) {
  // alert('say')

  console.log("locats");
  console.log(locats);
  console.log("locats");
  // const locat = useLocation();
  const point = useBreakpoint();

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const claslbl = useStylesLbl();
  const clasCAB = useStylesCAB();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const clasPM = useStylesDPM();
  // F CL Alert All
  // alert('yy')
  const clickDialog = () => {
    // his.push({ pathname: '/' })
    DialogClose(false);
  };

  // const [dateFromMA, setDateFromMA] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  // const [dateToMA, setDateToMA] = React.useState(moment(new Date()).format('YYYY-MM-DD'));

  // const [optionsBranch, setOptionsBranch] = useState([]);
  // const [valueBranch, setValueBranch] = useState({});

  // const [optionsCenter, setOptionsCenter] = useState([]);
  // const [valueCenter, setValueCenter] = useState({});

  // const [optionsAccno, setOptionsAccno] = useState([]);
  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const inshParm = { itake: 20, symple: "", groupSearch: null };
  const [parm, setParm] = useState(inshParm);

  const timer = React.useRef();

  const [valueAccno, setValueAccno] = useState({});
  const [uiElement, setUiElement] = useState({});

  const insOrders = [
    { id: 1, name: "ترتيب حسب التاريخ" },
    { id: 2, name: "ترتيب حسب رقم المستند" },
  ];
  const [optionsOrder, setOptionsOrder] = useState(insOrders);
  const [valueOrder, setValueOrder] = useState(optionsOrder[0]);
  const [transition, setTransition] = React.useState(undefined);
  const [openCuD, setOpenCuD] = React.useState(false);

  const insReviws = [
    { id: 1, name: "إجمالي" },
    { id: 2, name: "تفصيلي" },
  ];
  const [optionsReviw, setOptionsReviw] = useState(insReviws);
  const [valueReviw, setValueReviw] = useState(optionsReviw[1]);

  const [front, setFront] = useState({ elmnts: [], col: [], title: "" });
  const [setting, setSetting] = useState({
    screen: "",
    tb: { parms: {}, autos: {} },
  });

  const [style, setStyle] = useState({});

  const buttonClassnameNew = clsx({ [clasCAB.buttonSuccessCAB]: success.new });
  const buttonClassnameSave = clsx({
    [clasCAB.buttonSuccessCAB]: success.save,
  });
  const buttonClassnameDelete = clsx({
    [clasCAB.buttonSuccessCAB]: success.delete,
  });

  const [prevent, setPrevent] = useState(insh.prevents);

  /* s PMD */
  const [openPMD, setOpenPMD] = useState(false);

  const handCloseDw = async () => {
    newToI(1);
    await setOpenPMD(false);
  };
  const handOpenDw = (Transition) => {
    setTransition(() => Transition);
    setOpenPMD(true);
  };
  /* e PMD */

  const [items, setItems] = useState(insh.items);
  const [gets, setGets] = useState(insh.tbname);

  const inshValueAuto = {
    group_id: {},
    groupSearch: {},
    branch: {},
    tax_group: {},
    unit: {},
    type: {},
  };
  const [valueAutos, setValueAutos] = useState(inshValueAuto);

  const setDefultAutos = (re, pGets) => {
    // console.log(pGets)
    setValueAutos({
      group_id: pGets.groups[0],
      // groupSearch:pGets.groups[0],
      branch: pGets.Branches[1],
      tax_group: pGets.tax_groups[0],
      unit: pGets.Units[0],
      type: autos.currType[0],
    });
  };

  const reloadGets1 = async (prop) => {
    console.log(parm);
  };

  // const ust_o = (updatedData) => {
  function usto(olddata) {
    //console.log(olddata)
    // const profile = JSON.parse(localStorage.getItem('profile'));
    // Object.keys(updatedData).forEach((key) => {
    //     profile[key] = updatedData[key];
    // });
    // localStorage.setItem('profile', JSON.stringify(profile));
  }

  const reloadGets = async (prop) => {
    const tb = {
      wait: true,
      other: true,
      Currencies: true,

      parms: {
        scurrenciestype: "C",
        tname: "curr",
        takei: parm.itake,
        sSearch: parm.symple,
        group_id: parm.groupSearch,
        iempid: sto.empi,
      },
      autos: {},
    };
    // console.log('tb___'); console.log(tb)
    // await axios.post('http://localhost:8184/api/Res',tb)
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        console.log("res.data");
        console.log(res.data);
        setGets({
          ...gets,
          Currencies: res.data.Currencies,
          other: res.data.other,
        });
        setItems({
          ...insh.items,
          id: res.data.other.lastid,
          barcode: res.data.other.barcode,
          group_id: gets.groups[0].id,
          tax_group: gets.tax_groups[0].id,
          tax: gets.tax_groups[0].i,
          unit: gets.Units[0].id,
        });

        ButtonSuccess(prop, 1000);
      })
      .catch((error) => {
        alert("خطاء في اعادة جلب");
      });
  };

  const loadGets = async (ptb) => {
    const tb = {
      wait: true,
      other: true,
      groups: true,
      Branches: true,
      tax_groups: true,
      Units: true,
      Currencies: true,
      parms: {
        fltrBranch: true,
        iempid: sto.empi,
        scurrenciestype: "C",
        tname: "curr",
        takei: 20,
      },
      autos: { Branches: true, Units: true, tax_groups: true, groups: true },
    };
    //  console.log('__tb__'); console.log(tb)

    // await axios.post(uri+'Repts',tb)
    // await axios.post('http://localhost:8184/api/Res',tb)
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        console.log("res.data");
        console.log(res.data);
        setGets(res.data);
        setItems({
          ...items,
          id: res.data.other.lastid,
          barcode: res.data.other.barcode,
          group_id: res.data.groups[0].id,
          tax_group: res.data.tax_groups[0].id,
          tax: res.data.tax_groups[0].i,
          unit: res.data.Units[0].id,
        });
        setDefultAutos(false, res.data);
      })
      .catch((error) => {
        /*console.log('no Items');*/
      });
  };

  useEffect(() => {
    setUiElement(insh.UiElement);
    setFront(locats.state.front);
    setSetting(locats.state.setting);
    setStyle(locats.state.style);
    loadGets(locats.state.setting);
  }, []);

  const changAutos = (prop, newValue) => {
    // console.log(prop+' - '+newValue.id)
    setValueAutos({ ...valueAutos, [prop]: newValue });
    setItems({
      ...items,
      [prop]: newValue.id,
      tax: prop == "tax_group" ? newValue.i : items.tax,
    });
  };

  const changTField = (prop) => (e) => {
    // console.log(prop+' - '+e.target.value)
    setItems({ ...items, [prop]: e.target.value });
  };

  const changAuSearch = (prop, newValue) => {
    // console.log(prop+' - '+newValue.id)
    setValueAutos({ ...valueAutos, [prop]: newValue });
    setParm({ ...parm, [prop]: newValue.id, itake: 5 });
  };

  const changTFSearch = (prop) => (e) => {
    // console.log(prop+' - '+e.target.value)
    setParm({ ...parm, [prop]: e.target.value, itake: 5 });
  };

  const chekeBreack = () =>
    point == "1" || point == "2" || point == "3"
      ? handOpenDw(TransitionUp)
      : null;

  const actionItem = (item) => {
    console.log("item");
    console.log(item);
    chekeBreack();
    let res = { group_id: {}, branch: {}, tax_group: {}, unit: {}, type: {} };
    gets.groups.map((x) => {
      if (x.group_id == item.group_id) res.group_id = x;
    });
    gets.Branches.map((x) => {
      if (x.id == item.branch) res.branch = x;
    });
    gets.tax_groups.map((x) => {
      if (x.i == item.tax) res.tax_group = x;
    });
    gets.Units.map((x) => {
      if (x.id == item.unit) res.unit = x;
    });
    autos.currType.map((x) => {
      if (x.id == item.type) res.type = x;
    });

    setItems({
      ...items,
      id: item.id,
      barcode: item.barcode,
      nameEN: item.nameEN,
      symbol: item.symbol,
      purch_price: item.purch_price,
      sale_price: item.sale_price,
      min_sale_price: item.min_sale_price,
      Distributor_price: item.Distributor_price,
      jomla_price: item.jomla_price,
      tax: item.tax,

      prody_date: moment(item.prody_date).format("YYYY-MM-DD"),
      expiry_date: moment(item.expiry_date).format("YYYY-MM-DD"),
    });

    console.log("res.tax_group");
    console.log(res);
    console.log("gets.tax_groups");
    console.log(gets.tax_groups);

    setValueAutos({
      ...valueAutos,
      group_id: res.group_id,
      branch: res.branch,
      tax_group: res.tax_group,
      unit: res.unit,
      type: res.type,
    });

    setPrevent(false);

    //  setDefultAutos(true,res);
    setUiElement({ ...uiElement, DeleIcon: false });
    timer.current = window.setTimeout(
      () => setUiElement({ ...uiElement, DeleIcon: true }),
      300
    );
  };

  const handNewItem = () => {
    setDefultAutos(true, gets);
    setItems({
      ...insh.items,
      id: gets.other.lastid,
      barcode: gets.other.barcode,
      unit: gets.Units[0].id,
      group_id: gets.groups[0].id,
      tax_group: gets.tax_groups[0].id,
      tax: gets.tax_groups[0].i,
    });
    setParm(inshParm);
    setUiElement({ ...uiElement, DeleIcon: false });
  };

  const ButtonLoading = (prop) => setLoading({ ...loading, [prop]: true });

  const ButtonWitting = (prop) => {
    reloadGets(prop);
  };

  const ButtonSuccess = (prop, timr) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });
      if (prop != "save") handNewItem();
      setPrevent(true);
    }, timr);
  };

  const handLoads = async (value, stosn) => {
    const nstos = stosn;
    Object.keys(value).forEach((element) => {
      nstos[element] = value[element];
    });
    console.log("value");
    console.log(value);
    console.log("value");
    window.localStorage.setItem("sto", JSON.stringify(nstos));
  };

  const storgeing = async () => {
    // alert(dataF[46].id+' - '+ dataF[3].id)
    // handleClickLoading(true);
    // const tb = {
    //  //other:true,
    //  wait:true,
    //  Currencies:true,
    // //  Permissions:true,PermissionsCurr:true,
    //  groups:true,
    //  View_CurrencieUnit:true,View_CurrenciesToInv:true,ViewR_SAMER_jard_safe:true,
    //  ListUsers:true,
    //  types:true, Safes:true, Stocks:true,Banks:true, Customers:true, salesmen:true,
    //  costcenters:true, Branches:true,Employees:true,
    //  typesFltr:true,
    //  //params
    //  parms:{
    //   // sProcTypeInvProc:"02",
    //   ibranchid:sto.branchi, iempid:sto.empi,
    //   icustmtype:0,
    //   groupsSub:true,
    //   scurrenciestypeD:"D",
    //   scurrenciestypeM:"M",
    //   // iuserid:sto.idi, iformidI:dataF[46].id, iformidCuCo:dataF[3].id,
    //  },
    //  autos:{},
    // }

    //  console.log('=tb='); console.log(tb);
    //  alert(uri+'Res')
    // http://localhost:8184/
    // await axios.post('http://localhost:8184/api/Res',tb)
    // timer.current =  window.setTimeout( async () => {
    // handleClickLoading(true);

    await axios
      .post(uri + "Res", {
        wait: true,
        Currencies: true,
        View_CurrencieUnit: true,
        View_CurrenciesToInv: true,
        parms: {
          scurrenciestype: "I",
          scurrencieShow: "Fav",
          currShow: "D",
          igroupid: -1,
          takei: 50,
          currType: 2,
          sSearch: "",
        },
        autos: {},
      })
      .then((res) => {
        handLoads(res.data, wsto);
      })
      .catch((error) => {
        //  console.log('nooooo sttorgeing');
        alert("لم يتم التحديث");
      });

    //  }, 3000);
  };

  const newToI = (timr) => {
    ButtonLoading("new");
    ButtonSuccess("new", timr);
  };

  const will = () => {
    // alert(parseFloat(items.sale_price) +' <= '+ parseFloat(items.purch_price))
    let ob = { msg: "قم بإضافة ", txt: "" };
    if (items.barcode == "") ob.txt = "الباركود";
    else if (items.symbol == "") ob.txt = "اسم الصنف";
    else if (items.purch_price == "") ob.txt = "سعر الشراء";
    else if (items.sale_price == "") ob.txt = "سعر البيع";
    else if (parseFloat(items.sale_price) < parseFloat(items.purch_price)) {
      ob.txt = "سعر البيع اكبر او يساوي سعر الشراء";
      ob.msg = "يجب أن يكون ";
    }
    ob.msg += ob.txt;
    return ob;
  };

  const add_ToI1 = () => {
    // console.log(valueAutos.group_id)
    // alert(valueAutos.group_id.group_id+'')
    // if(valueAutos.group_id != null)
    // alert('d')
    let action = will();
    console.log(action);
    console.log("items");
    console.log(items);
  };

  const addToBS = () => {
    let action = will();
    if (action.txt == "") {
      ButtonLoading("save");
      // console.log('action'); console.log(action)
      prevent
        ? // axios.post('http://localhost:8184/api/Currencies', items)
          axios
            .post(uri + "Currencies", items)
            .then((res) => {
              if (res.data) {
                alert("رقم الباردكود موجود مسبقا");
                ButtonSuccess("save", 500);
              } else {
                usto(items);
                ButtonWitting("save");
                storgeing();
              }
            })
            .catch((res) => {
              alert("تم إضافة الصنف هذا مسبقا");
              ButtonSuccess("save", 1000);
            })
        : // axios.put('http://localhost:8184/api/Currencies', items)
          axios
            .put(uri + "Currencies", items)
            .then((res) => {
              ButtonWitting("save");
            })
            .catch((res) => {
              alert("لم يتم  تعديل هذا الصنف");
              ButtonSuccess("save", 1000);
            });
    } else {
      alert(action.msg);
    }
  };

  const hRowDelete = (id) => {
    let reResData = [...gets.Currencies];
    reResData = reResData.filter((t) => t.id !== id);
    setGets({ ...gets, Currencies: reResData });
    ButtonSuccess("delete", 1000);
  };

  const deleteToI = async (e, id, screen) => {
    await e.preventDefault();
    ButtonLoading("delete");
    // console.log(screen); console.log(id); console.log(e)
    // axios.delete(`http://localhost:8184/api/BasicData?id=${id}&&tb=${screen}`)
    axios
      .delete(uri + `BasicData?id=${id}&&tb=${screen}`)
      .then((res) => {
        res.data != -1
          ? hRowDelete(id)
          : alert("لا يمكن حذف هذا الصنف لانه مرتبط بعمليات اخرى");
        ButtonSuccess("delete", 500, true);
      })
      .catch((err) => {
        alert("لم يتم الحذف");
        ButtonSuccess("delete", 500);
      });
  };

  const deleteToI1 = async (e) => {
    await e.preventDefault();
    // console.log(idata.proc_id)
    ButtonLoading("delete");
    await axios
      .delete(uri + "?pcid=" + items.id)
      .then((resIsRs) => {
        ButtonSuccess("delete", 1000);
        // console.log('done isrs');
        // setUiElement({...uiElement, DeleIcon:false});
      })
      .catch((resIsRs) => {
        //  console.log('no isrs')
      });
  };

  // ==== b sd
  const [open, setOpen] = React.useState(false);
  // const [hidden, setHidden] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const toSave = () => {};

  const backHome = () => {
    his.push({ pathname: "/" });
  };
  const back = () => his.goBack();

  let his = useHistory();
  const toPosh = (typ) => {};

  const actions = [
    // { icon: <FileCopyIcon />, name: 'Copy' },
    {
      icon: (
        <CloseIcon className={[clasSp.ico, clasSp.clcan]} onClick={backHome} />
      ),
      name: "خـروج",
    },
    {
      icon: <ScannerIcon className={clasSp.ico} onClick={() => toPosh("A")} />,
      name: "",
    },
    {
      icon: <PrintIcon className={clasSp.ico} onClick={() => toPosh("C")} />,
      name: " ",
    },
  ];

  const fahs = () => {
    console.log(items);
    console.log(gets);
  };

  const handCOpenCu = (Transition) => {
    setTransition(() => Transition);
    setOpenCuD(true);
  };

  const styheader = {
    textAlign: "center",
    backgroundColor: "#fff",
    height: "100vh",
    direction: "rtl",
  };

  const frontHeader = () => {
    return (
      <Grid item xs={12}>
        <Chip
          size="small"
          className={clashd.ChipHdSale}
          label={front.title}
          color="primary"
        />
      </Grid>
    );
  };

  const FrontBtnAction = () => {
    return (
      <Fragment>
        {uiElement.DeleIcon === true ? (
          <div className={clasCAB.wrapperCAB}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={buttonClassnameDelete}
              startIcon={<DeleteIcon />}
              style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
              disabled={loading.delete}
              onClick={(e) => deleteToI(e, items.id, setting.screen)}
            >
              حـذف
            </Button>
            {loading.delete && (
              <CircularProgress
                size={24}
                className={clasCAB.buttonProgressCAB}
              />
            )}
          </div>
        ) : null}

        <div className={clasCAB.wrapperCAB}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
            className={buttonClassnameSave}
            disabled={loading.save}
            onClick={addToBS}
            startIcon={<SaveIcon />}
          >
            {" "}
            {"حـفـظ"}{" "}
          </Button>
          {loading.save && (
            <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
          )}
        </div>

        <div className={clasCAB.wrapperCAB}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
            className={buttonClassnameNew}
            disabled={loading.new}
            onClick={() => newToI(1000)}
            startIcon={<AddIcon />}
          >
            {" "}
            {"جـديـد"}{" "}
          </Button>
          {loading.new && (
            <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
          )}
        </div>
      </Fragment>
    );
  };

  const frontLeftUp = () => {
    return (
      <Grid item container lg={12} md={12} className={[clsConts.rootup]}>
        <Grid
          item
          container
          spacing={1}
          xs={12}
          className={[clsConts.subUp, clsConts.subUpL]}
        >
          <Grid
            item
            lg={2}
            md={6}
            sm={4}
            xs={6}
            style={{ alignSelf: "center" }}
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => reloadGets("search")}
              // className={claslbl.bakClr}
              style={{ ...style.bakClr, direction: "ltr" }}
              startIcon={<SearchIcon />}
            >
              بـحـث
            </Button>
          </Grid>

          <Grid item lg={7} md={6} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="td"
              label="بـحـث"
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="search"
              placeholder="عن الصنف او الباركود"
              name="td"
              value={parm.symple}
              onChange={changTFSearch("symple")}
            />
          </Grid>

          {/* <Grid item lg={3} md={6} sm={4} xs={6}>
      <Autocomplete classes={clasAo}
                value={valueAutos.groupSearch} 
                labelPlacement=""
                onChange={(event, newValue) => { changAuSearch('groupSearch',newValue) }}
                id="controllable-states-demo" size="small"
                options={gets.groups}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                disableClearable
                renderInput={(params) => <TextField {...params} label="المجموعة" variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clrSale}} />}
              />
      </Grid>  */}
        </Grid>
      </Grid>
    );
  };

  const frontLeftDown = () => {
    return (
      <Grid item container lg={12} md={12} className={[clsConts.rootdown]}>
        <Grid
          item
          container
          xs={12}
          className={[clsConts.subDown, clsConts.subDownL]}
        >
          <Grid item xs={12}>
            <MaterialTable
              style={{ boxShadow: "none" }}
              title="بحث عن الفواتير"
              // variant="outlined"
              options={{
                pageSize: 7,
                //showTitle:false,
                toolbar: false,
                sorting: false,
                showTitle: false,
                // selection: true,
                // showTextRowsSelected: false,
                // act ionsCellStyle: -1,
                paging: false,
                headerStyle: headerS,
                cellStyle: cellS,
                //tableLayout: 'fixed',
                //  maxBodyHeight: '50vh',
                maxBodyHeight: "70vh",
                minBodyHeight: "70vh",
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <h3
                      style={{
                        marginTop: "20vh",
                        border: "solid 0px",
                        boxShadow: "none",
                      }}
                    ></h3>
                  ),
                },
              }}
              columns={front.col}
              data={gets.Currencies}
              icons={tableIcons}
              onRowClick={(event, rowData) => {
                actionItem(rowData);
                event.stopPropagation();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const frontRightUp = () => {
    return (
      <Grid item container lg={12} md={12} className={[clsConts.rootup]}>
        <Grid
          item
          container
          spacing={1}
          xs={12}
          className={[clsConts.subUp, clsConts.subUpR]}
        >
          <Grid item lg={3} md={3} sm={4} xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="id"
              label="رقم الصنف"
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input, readOnly: true }}
              type="text"
              name="id"
              value={items.id}
              onChange={changTField("id")}
            />
          </Grid>

          <Grid item lg={9} md={9} sm={4} xs={8}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="barcode"
              label="الباركود"
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name="barcode"
              value={items.barcode}
              onChange={changTField("barcode")}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="symbol"
              label="اسم الصنف"
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name="symbol"
              value={items.symbol}
              onChange={changTField("symbol")}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="nameEN"
              label="اسم الصنف (EN)"
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name="nameEN"
              value={items.nameEN}
              onChange={changTField("nameEN")}
            />
          </Grid>

          <Grid
            item
            lg={1}
            md={2}
            sm={1}
            xs={1}
            style={{
              padding: "0px",
              color: "#81007f",
              textAlign: "-webkit-center",
            }}
          >
            <IconButton
              color="inherit"
              style={{
                padding: "10px",
                color: "#81007f",
                border: "solid 0.1px",
                borderRadius: 50,
              }}
              aria-label="open drawer"
              // onClick={handleOpenCust_omer}
            >
              <AddIcon />
            </IconButton>
          </Grid>

          <Grid item lg={5} md={5} sm={4} xs={6}>
            <Autocomplete
              classes={clasAo}
              value={valueAutos.group_id}
              labelPlacement=""
              onChange={(event, newValue) => {
                changAutos("group_id", newValue);
              }}
              id="controllable-states-demo"
              size="small"
              options={gets.groups}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              // style={{  }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="مجموعة الصنف"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ className: claslbl.clrSale }}
                />
              )}
            />
          </Grid>

          <Grid item lg={6} md={5} sm={4} xs={11}>
            <Autocomplete
              classes={clasAo}
              value={valueAutos.branch}
              labelPlacement=""
              onChange={(event, newValue) => {
                changAutos("branch", newValue);
              }}
              id="controllable-states-demo"
              size="small"
              options={gets.Branches}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              // style={{  }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="الفرع"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ className: claslbl.clrSale }}
                />
              )}
            />
          </Grid>

          <Grid item container>
            <Grid item container lg={3} md={4} sm={4} xs={6}>
              <Grid item lg={12} md={12} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="purch_price"
                  label="سعر الشراء"
                  className={clasTF.rootSale}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name="purch_price"
                  value={items.purch_price}
                  onChange={changTField("purch_price")}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="sale_price"
                  label="سعر البيع"
                  className={clasTF.rootSale}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name="sale_price"
                  value={items.sale_price}
                  onChange={changTField("sale_price")}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="min_sale_price"
                  label="اقل سعر بيع"
                  className={clasTF.rootSale}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name="min_sale_price"
                  value={items.min_sale_price}
                  onChange={changTField("min_sale_price")}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="Distributor_price"
                  label="سعر الموزع"
                  className={clasTF.rootSale}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name="Distributor_price"
                  value={items.Distributor_price}
                  onChange={changTField("Distributor_price")}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="jomla_price"
                  label="سعر الجملة"
                  className={clasTF.rootSale}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name="jomla_price"
                  value={items.jomla_price}
                  onChange={changTField("jomla_price")}
                />
              </Grid>
            </Grid>

            <Grid item container lg={3} md={2} sm={4} xs={6}></Grid>

            <Grid item container lg={6} md={6} sm={4} xs={6}>
              <Grid item lg={8} md={8} sm={4} xs={6}>
                <Autocomplete
                  classes={clasAo}
                  value={valueAutos.tax_group}
                  labelPlacement=""
                  onChange={(event, newValue) => {
                    changAutos("tax_group", newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.tax_groups}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  // style={{  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="المجموعة الضريبية"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clrSale }}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="tax"
                  label=" % "
                  className={clasTF.rootSale}
                  InputProps={{ className: clasTD.input, readOnly: true }}
                  type="text"
                  name="tax"
                  value={items.tax}
                  /*onChange={changTField('tax')}*/
                />
              </Grid>

              {/* here ERROR */}
              <Grid item lg={8} md={8} sm={4} xs={6}>
                <Autocomplete
                  classes={clasAo}
                  value={valueAutos.unit}
                  labelPlacement=""
                  onChange={(event, newValue) => {
                    changAutos("unit", newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.Units}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  // style={{  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="الوحدة الإفتراضية"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clrSale }}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={8} md={8} sm={4} xs={6}>
                {/* <Autocomplete classes={clasAo} disabled
                  value={valueAutos.type} 
                  labelPlacement=""
                  onChange={(event, newValue) => { changAutos('type',newValue) }}
                  id="controllable-states-demo" size="small"
                  options={autos.currType}
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                  // style={{  }}
                  disableClearable
                  renderInput={(params) => <TextField {...params} label="نوع الصنف" variant="outlined" size="small" 
                  InputLabelProps={{className:claslbl.clrSale}} />}
                /> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} md={6} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="prody_date"
              label="تاريخ الإنتاج"
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="date"
              format="YYYY-MM-DD"
              name="prody_date"
              value={items.prody_date}
              onChange={changTField("prody_date")}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="expiry_date"
              label="تاريخ الإنتهاء"
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="date"
              format="YYYY-MM-DD"
              name="expiry_date"
              value={items.expiry_date}
              onChange={changTField("expiry_date")}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const frontRightDown = () => {
    return (
      <Grid item container lg={12} md={12} className={[clsConts.rootdown]}>
        <Grid
          item
          container
          xs={12}
          className={[clsConts.subDown, clsConts.subDownR]}
        >
          asdfasf
        </Grid>
      </Grid>
    );
  };

  {
    /* s PMD */
  }
  const frontDialogForm = () => {
    return (
      <div>
        <Dialog
          fullScreen
          className={clasPM.rootPM}
          open={openPMD}
          onClose={handCloseDw}
          aria-labelledby="form-dialog-title"
          TransitionComponent={transition}
          key={transition ? transition.name : ""}
        >
          <DialogTitle id="form-dialog-title" className={claslbl.clor}>
            ادخل البيانات
          </DialogTitle>
          <DialogContent
            className={[clasPM.dialogContentPM, clasPM.diaCPMBasicDa]}
          >
            {frontRightUp()}
          </DialogContent>
          <DialogActions className={clasPM.dirRPM}>
            <div className={clasCAB.wrapperCAB}>
              <Button
                variant="outlined"
                color=""
                size="large"
                style={{ ...stElemnt.all.iconBtn }}
                className={buttonClassnameNew}
                disabled={loading.new}
                onClick={handCloseDw}
                // startIcon={<CloseIcon />}
              >
                {" "}
                {"إلـغـاء"}{" "}
              </Button>
              {loading.new && (
                <CircularProgress
                  size={24}
                  className={clasCAB.buttonProgressCAB}
                />
              )}
            </div>

            <div className={clasBA.growBA} />

            <FrontBtnAction />
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  {
    /* e PMD */
  }

  const frontAppBar = () => {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="primary"
          // className={clasBA.appBarBASal}
          style={{ ...style.bgImg, ...stElemnt.all.appBarBA, direction: "ltr" }}
        >
          <Toolbar className={clasBA.ToolbarBA} variant="dense">
            <Hidden smUp={["sm"]}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={backHome}
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => clickDialog()}
              >
                <ReplyIcon />
              </IconButton>
              {/* <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={() => handOpenDw(TransitionDown)}>
          <SearchIcon />
        </IconButton> */}
              {/* <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handNewInv}>
          <AddBoxIcon />
        </IconButton> */}
            </Hidden>

            <Hidden only={["xs"]}>
              <Button
                style={{ color: "#fff" }}
                onClick={backHome}
                startIcon={<CloseIcon />}
              >
                خروج
              </Button>
              <Button
                style={{ color: "#fff" }}
                onClick={() => clickDialog()}
                startIcon={<ReplyIcon />}
              >
                رجوع
              </Button>
              {/* <Button style={{color:'#fff'}} onClick={newToI} startIcon={<AddBoxIcon />}>جديد</Button> */}
            </Hidden>
            {/* <Button color="primary">Primary</Button> */}

            <Hidden mdUp={["md"]}>
              <Fab
                color="primary"
                aria-label="add"
                style={{ ...style.fabButtonBA, ...stElemnt.all.fabButtonBA }}
                onClick={() => handOpenDw(TransitionUp)}
              >
                <PostAddIcon />{" "}
              </Fab>
            </Hidden>

            <div className={clasBA.growBA} />

            <Hidden smUp={["sm"]}>
              {/* ico left  */}
              {uiElement.DeleIcon === true ? (
                <div className={clasCAB.wrapperCAB}>
                  <Fab
                    size="medium"
                    color="primary" /*disabled={permis.De}*/
                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    className={buttonClassnameDelete}
                    onClick={(e) => deleteToI(e, items.id, setting.screen)}
                  >
                    {success.delete ? <CheckIcon /> : <DeleteIcon />}
                  </Fab>
                  {loading.delete && (
                    <CircularProgress
                      size={56}
                      className={clasCAB.fabProgressCAB}
                    />
                  )}
                </div>
              ) : null}

              {/* <div className={clasCAB.wrapperCAB}>
       <Fab size="medium" color="primary"
        style={{...style.iconBtn,...stElemnt.all.iconBtn}}
        onClick={clickOpenToggle}>
          <PrintIcon />
        </Fab>
      </div> */}

              {/* <div className={clasCAB.wrapperCAB}>
     <Fab size="medium" color="primary" disabled={permis.Sa}
     style={{...style.iconBtn,...stElemnt.all.iconBtn}} className={buttonClassnameSave} 
      //  onClick={toWhere}>
        {success.save ? <CheckIcon /> : <SaveIcon />}
      </Fab>
      {loading.save && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
    </div> */}
            </Hidden>

            <Hidden smDown={["sm"]}>
              {/* 
    {uiElement.DeleIcon === true
    ?<div className={clasCAB.wrapperCAB}>
      <Button variant="contained" color="primary" size="large"
         className={buttonClassnameDelete} startIcon={<DeleteIcon />}
         style={{...style.iconBtn,...stElemnt.all.iconBtn}}
         disabled={loading.delete} onClick={(e)=>deleteToI(e,items.id,setting.screen)}>حـذف</Button>
      {loading.delete && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
    </div>:null} */}

              <FrontBtnAction />
            </Hidden>
            {/* </div> */}

            {/* <Button variant="contained" color="primary" onClick={fahs}>Fhs</Button> */}
            {/* <Button variant="contained" color="primary" onClick={fahsbc}>Fhsbc</Button> */}
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  };

  return (
    <Dialog
      fullScreen
      open={DialogOpened}
      onClose={clickDialog}
      TransitionComponent={Transition}
    >
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="xl">
          <Typography component="div" style={styheader}>
            <Grid item container>
              {/* S front Header */} {frontHeader()} {/* E front Header */}
              {/* S front Dialog */}
              <Hidden mdUp={["md"]}> {frontDialogForm()} </Hidden>
              {/* E front Dialog */}
              {/* S front Right */}
              <Grid container lg={5} md={5}>
                {/* S front up R */}
                <Hidden smDown={["sm"]}> {frontRightUp()} </Hidden>
                {/* E front up R */}

                {/* S front down R */}
                {/* <Hidden smDown={['sm']}> {frontRightDown()} </Hidden> */}
                {/* E front down R */}
              </Grid>
              {/* E front Right */}
              {/* S front Left */}
              <Grid container lg={7} md={7}>
                {/* S front up L */} {frontLeftUp()} {/* E front up L */}
                {/* S front down L */} {frontLeftDown()} {/* E front down L */}
              </Grid>
              {/* E front Left */}
              {/* S front down R */}
              <Hidden xsUp={["xs"]}> {frontRightDown()} </Hidden>
              {/* E front down R */}
            </Grid>
            {/* S front fixed */} {frontAppBar()} {/* E front fixes */}
          </Typography>
        </Container>
      </React.Fragment>
    </Dialog>
  );
}
