import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";

import { useEffect } from "react";
const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 40,
    position: "relative",
  },
  actions: {
    justifyContent: "flex-start",
  },
}));

const DetailsList = (props) => {
  const { className, data, curr, fetching, ...rest } = props;

  const classes = useStyles();

  useEffect(() => {
    console.log("data is ", data);
  }, [data]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader style={{ paddingBottom: 2 }} title="المبالغ المحصلة" />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Grid container justify="space-between">
            <Grid item spacing={1}>
              <Typography color="primary" variant="h5">
                نقدي
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ textAlign: "center", color: "#000000" }}>
                {data ? data.total_cash.toFixed(2) : 0.0} {curr}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.chartContainer}>
          <Grid container justify="space-between">
            <Grid item spacing={1}>
              <Typography color="primary" variant="h5">
                شبكة
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ textAlign: "center", color: "#000000" }}>
                {data ? data.total_network.toFixed(2) : 0.0} {curr}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.chartContainer}>
          <Grid container justify="space-between">
            <Grid item spacing={1}>
              <Typography color="primary" variant="h5">
                بنك
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ textAlign: "center", color: "#000000" }}>
                {data ? data.total_bank.toFixed(2) : 0.0} {curr}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.chartContainer}>
          <Grid container justify="space-between">
            <Grid item spacing={1}>
              <Typography color="primary" variant="h5">
                اجل
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ textAlign: "center", color: "#000000" }}>
                {data ? data.total_agel.toFixed(2) : 0.0} {curr}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </CardContent>
      {/* <CardActions className={classes.chartContainer}>
        <Grid container justify="space-between">
          <Grid item spacing={1}>
            <Typography color="primary" variant="h6">
              المجموع
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="primary">
              {data ? data.total.toFixed(2) : 0.0} {curr}
            </Typography>
          </Grid>
        </Grid>
      </CardActions> */}
    </Card>
  );
};

DetailsList.propTypes = {
  className: PropTypes.string,
};

export default DetailsList;
