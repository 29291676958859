import React, {useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import Container from '@material-ui/core/Container';
import {useStylesContainers,useStylesHed} from '../../../St/comps/UseStyle';
import SafesForm from './safes-form';
import SafesTable from './safes-table';
import  { Toaster } from 'react-hot-toast';
import { insh } from '../../../Context/InshData';
import SafesAppBar from './safes-appBar';
import basicDataAPI from '../../../api/basicData';
import useFormDataCRUD from '../../../components/Hooks/useEDSCURD';

export default function Safes(props){

  const {data, fetchURL} = props;
  const defaultValues = {...insh.Safes, 
          branch: data?.auto?.Branches[0], 
          status: data?.auto?.States[0],
        };

      const {
        onChange,
        submit,
        update,
        remove,
        submitting, 
        errorMessage,
        onShow,
        onEnd,
        object,
        formType,
        
    } = useFormDataCRUD(
        basicDataAPI.create,
        basicDataAPI.update,
        basicDataAPI.removeSafe,
        undefined,
        defaultValues,
        null,
        fetchURL,
    );
    useEffect(()=>{
      console.log('secound item', object);
    })

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();


  const styheader = 
  {textAlign:'center', backgroundColor: '#fff', height: '100vh',direction:'rtl' }



return (

<React.Fragment>
    
    <CssBaseline />
    
    <Container maxWidth="xl">
        <Toaster />

    <Typography component="div" style={styheader}>
    <Grid item container> 
        <Grid item xs={12}>
            <Chip size="small" className={clashd.ChipHdSale} label={" المخـــازن"} color="primary" />
        </Grid>
        <Grid container xs={12}>
            <Grid item container lg={12} md={12} className={[clsConts.rootup,]}>
            <Grid item container spacing={1} xs={12} className={[clsConts.subUp,clsConts.subUpC,]}>
                <SafesForm {...{...props,
                 onChange,
                  submit,
                  update,
                  remove,
                  submitting, 
                  errorMessage,
                  onShow,
                  onEnd,
                  object,
                  formType,
                  }}/>
            </Grid>
            </Grid>
            <SafesTable {...{...props, onShow, safes: props.data?.table?.resData}}/>
        </Grid>
    </Grid>
    <SafesAppBar {...{
      ...props,
      onChange,
      defaultValues,
      submit,
      update,
      remove,
      submitting, 
      errorMessage,
      onShow,
      onEnd,
      object,
      formType,
      }}/>
    </Typography>
  </Container>
</React.Fragment>
 );
 
}