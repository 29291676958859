import React from "react";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import ReMakeStore from "./ReMakeStore";
import { uri } from "../../../../help/Api";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import TaswyaAPI from "../../../../api/Taswya";
import { defaultTaswyaData } from "../../../../services/taswya-servece";
import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import LoadingPage from "../../../../services/LoadingPage";
import { sto } from "../../../../Context/InshData";

function ReMakeStoreItem(props) {
  const { fetchURL, AutoOptions } = props;
  useEffect(() => {
    console.log("the items is", AutoOptions);
  });

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    TaswyaAPI.create,
    TaswyaAPI.update,
    TaswyaAPI.remove,
    undefined,
    { ...defaultTaswyaData, safe: AutoOptions?.Safes[0] },
    null,
    fetchURL
  );

  return (
    <ReMakeStore
      {...{
        newId: AutoOptions?.other?.lastid,
        onChange,
        submit,
        update,
        remove,
        submitting,
        errorMessage,
        onShow,
        onEnd,
        object,
        safes: AutoOptions?.Safes ?? [],
        centers: AutoOptions?.costcenters ?? [],
        groups: AutoOptions?.groups ?? [],
        formType,
      }}
    />
  );
}
export default function ReMakeStoreCon() {
  const tttb = {
    /*other:true,*/ // out
    wait: true,
    other: true,
    costcenters: true,
    SafesAll: true,
    groups: true,
    /*params*/ // out
    parms: {
      iempid: sto.empi,
      tname: "tswya",
    },
    autos: {
      costcenters: true,
      groups: true,
    },
  };

  const url = uri + "Res";

  const {
    object: AutoOptions,
    fetching: fetchinData,
    fetch: fetchURL,
  } = useFetchObjectByPost(url, tttb);

  useEffect(() => {
    console.log("the items is", AutoOptions);
  });

  if (fetchinData || !AutoOptions?.Safes) return <LoadingPage />;
  return (
    <ReMakeStoreItem
      {...{
        AutoOptions,
        fetchURL,
      }}
    />
  );
}
