
import React, { useState,useEffect } from 'react';
import {txtA,txtE} from '../../../Label/Lbl';

import axios from 'axios';
import {uri}  from '../../../help/Api'
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import MaterialTable from "material-table";
import { insh } from '../../../Context/InshData';
// Ust
import {useInvCa,useInvMain} from '../../../St/Reviews/UseStyle';
// csG
// import {stElemnt} from '../../St/Reviews/cStyle'
import Paper from '@material-ui/core/Paper';

const Body = () => {
  const clasm = useInvMain();
  const clas = useInvCa();
  // const clasB = useInvBodyCa();
  const [AE, setAE] = useState(null);

const locInv = JSON.parse(localStorage.getItem('datainv')) || {};
const premiApp = JSON.parse(localStorage.getItem('premiApp')) || {};

const [sta, setSta] = useState({info:insh.RptInv,tot:{},StngIA:{}});
// const [list, setList] = useState([]);
const getInfo = async () => {
  const tb = {
    Rpt_Inv:true, SettInvsApp:true,
    parms:{
      lprocid:locInv.proc_id,ibranchid:locInv.branch,sProcTypeInvProc:locInv.ProcTypeInvProc,
      iFoundid:1,
    },
    autos:{},
  }
  //  console.log('Rpt__tb'); console.log(tb)
  //  alert(`${uri}Res`)
  axios.post(`${uri}Res`,tb)
  .then(res => { 
    // console.log('res.data.RptInv'); console.log(res.data);
    setSta({info:res.data.RptInv,tot:res.data.infoBill,StngIA:res.data.SettInvsApp}); 

  // console.log('res.data.RptInv'); console.log(res.data); 
}).catch(error => { });

  setAE(false);
}

useEffect(() => { getInfo(); }, []);

// stayWCa
const totalsTitleA_ = () => { return (
  <Grid item container className={clas.BdytotTitle}>
  <Grid item xs={6}className={clasm.minlft} >{txtA.totalAmount}</Grid> 
  <Grid item xs={6} className={clasm.minrit}>{txtE.totalAmount}</Grid>
</Grid>
)}

const totalsTitleE_ = () => { return (
  <Grid item container className={clas.BdytotTitle}>
  <Grid item xs={6}className={clasm.minlft} >{txtE.totalAmount}</Grid> 
  <Grid item xs={6} className={clasm.minrit}>{txtA.totalAmount}</Grid>
</Grid>
)}

const totalsContntA_ = () => { return(
<Grid item container className={clas.BdytotSub}>
  <Grid item xs={8} className={clas.BdytotCntr}>
    <Grid item xs={12}>{sta.StngIA.amountWithVatA}</Grid>
    <Grid item xs={12}>{sta.StngIA.amountWithVatE}</Grid>
  </Grid> 
  <Grid item xs={4} className={clas.BdytotCntrVal}>{sta.tot.amountWithVat?.toFixed(2)}</Grid> 

  <Grid item xs={8} className={clas.BdytotCntr}>
    {sta.StngIA.totdiscountA+' '+sta.StngIA.totdiscountE}
  </Grid> 
  <Grid item xs={4} className={clas.BdytotCntrVal}>{sta.tot.discount?.toFixed(2)}</Grid>  

  <Grid item xs={8} className={clas.BdytotCntr}>
    <Grid item xs={12}>{sta.StngIA.amountWithVat2A}</Grid>
    <Grid item xs={12}>{sta.StngIA.amountWithVat2E}</Grid>
  </Grid> 
  <Grid item xs={4} className={clas.BdytotCntrVal}>{sta.tot.amountWithVat?.toFixed(2)}</Grid>  

  <Grid item xs={8} className={clas.BdytotCntr}>
    <Grid item xs={12}>{sta.StngIA.amountOfVatA}</Grid>
    <Grid item xs={12}>{sta.StngIA.amountOfVaEt}</Grid>
  </Grid> 
  <Grid item xs={4} className={clas.BdytotCntrVal}>{sta.tot.amountOfVat?.toFixed(2)}</Grid>  

  <Grid item xs={8} className={clas.BdytotCntr}>
    <Grid item xs={12}>{sta.StngIA.tot_netA}</Grid>
    <Grid item xs={12}>{sta.StngIA.tot_netE}</Grid>
  </Grid> 
  <Grid item xs={4} className={clas.BdytotCntrVal}>{sta.tot.tot_net?.toFixed(2)}</Grid>  
</Grid>
)}

const totalsContntE_ = () => { return(
  <Grid item container className={clas.BdytotSub}>
 
    <Grid item xs={4} className={clas.BdytotCntrVal}>{(sta.tot.tot_net-sta.tot.amountOfVat+sta.tot.discount).toFixed(2)}</Grid> 
    <Grid item xs={8} className={clas.BdytotCntr}>
      <Grid item xs={12}>{txtA.amountWithVat}</Grid>
      <Grid item xs={12}>{txtE.amountWithVat}</Grid>
    </Grid> 
  
    <Grid item xs={4} className={clas.BdytotCntrVal}>{sta.tot?.discount?.toFixed(2)}</Grid>  
    <Grid item xs={8} className={clas.BdytotCntr}>{txtE.totdiscount+' '+txtA.totdiscount}</Grid> 
  
    <Grid item xs={4} className={clas.BdytotCntrVal}>{(sta.tot.tot_net-(sta.tot.amountOfVat))?.toFixed(2)}</Grid>  
    <Grid item xs={8} className={clas.BdytotCntr}>
      <Grid item xs={12}>{txtA.amountWithVat2}</Grid>
      <Grid item xs={12}>{txtE.amountWithVat2}</Grid>
    </Grid> 
  
    <Grid item xs={4} className={clas.BdytotCntrVal}>{sta.tot.amountOfVat?.toFixed(2)}</Grid>  
    <Grid item xs={8} className={clas.BdytotCntr}>
      <Grid item xs={12}>{txtA.amountOfVat}</Grid>
      <Grid item xs={12}>{txtE.amountOfVat}</Grid>
    </Grid> 
  
    <Grid item xs={4} className={clas.BdytotCntrVal}>{sta.tot.tot_net?.toFixed(2)}</Grid>  
    <Grid item xs={8} className={clas.BdytotCntr}>
      <Grid item xs={12}>{txtA.tot_net}</Grid>
      <Grid item xs={12}>{txtE.tot_net}</Grid>
    </Grid> 

  </Grid>
  )}

return (
  <Grid item container className={clas.Bdyroot}>

    <Grid item container className={clas.BdyundrHeaderTF}>
      { locInv.ProcTypeInvProc != "03" && 
        <Grid item xs={12} className={clas.BdytitleHed}>
         {/* <Typography className={[clas.contnt,clasm.minclr]}>{sta.StngIA.titleFatoraA}</Typography> */}
         <Typography className={[clas.contnt,clasm.minclr]}>{sta.tot.tax_no_cust===""?"فاتورة ضريبية مبسطة":"فاتورة ضريبية"}</Typography>
        </Grid>
      }

      <Grid item xs={12} className={[clas.BdytitleHedDw,clas.BdytitleHed]}>
        <Typography className={[clas.contnt,clasm.minclr]}>{locInv.invN+" "+sta.tot.inv_type_name+" "+ sta.tot.pay_type_name}</Typography>
      </Grid>
    </Grid>

    <Grid item container className={clas.Bdycntr}>
      { !sta.StngIA.showColDHd && !premiApp.stayWCa
       ? <Grid item xs={4} className={clas.BdyborBtR}>{txtE.invNumr}</Grid> : <Grid item xs={4}></Grid> }
    <Grid item xs={sta.StngIA.ncol} className={clasm.minborB}><Paper className={clasm.minclr}>{sta.tot.id}</Paper></Grid>
    <Grid item xs={sta.StngIA.ncol} className={clas.BdyborBtL}>{txtA.invNumr}</Grid>
    <Grid item xs={4}></Grid>
    
    <Grid item xs={sta.StngIA.ncol} className={clasm.minborB}><Paper className={clasm.minclr}>{sta.tot?.refno}</Paper></Grid>
    <Grid item xs={sta.StngIA.ncol} className={clas.BdyborBtL}>{'رقم المرجع'}</Grid>
    { !sta.StngIA.showColDHd && !premiApp.stayWCa
       ? <Grid item xs={4} className={clas.BdyborBtR}>{txtE.invDate}</Grid> : null }
    <Grid item xs={sta.StngIA.ncol} className={clasm.minborB}><Paper className={clasm.minclr}>{moment(sta.tot.date).format('YYYY/MM/DD')}</Paper></Grid>
    {!premiApp.stayWCa? <Grid item xs={sta.StngIA.ncol} className={clas.BdyborBtL}>{txtA.invDate}</Grid>: null}

    { !sta.StngIA.showColDHd && !premiApp.stayWCa
       ? <Grid item xs={4} className={clas.BdyborBtR}>{txtE.time}</Grid> : null }
    <Grid item xs={sta.StngIA.ncol} className={clasm.minborB}><Paper className={clasm.minclr}>{moment(sta.tot.date).format('hh:mm A')}</Paper></Grid>
    {!premiApp.stayWCa? <Grid item xs={sta.StngIA.ncol} className={clas.BdyborBtL}>{txtA.time}</Grid>:null}

    { !sta.StngIA.showColDHd && !premiApp.stayWCa
       ? <Grid item xs={4} className={clas.BdyborBtR}>{txtE.custmName}</Grid> : null }
    {!premiApp.stayWCa? <Grid item xs={sta.StngIA.ncol} className={clasm.minborB}><Paper className={clasm.minclr}>{sta.tot.cust_name}</Paper></Grid>: null}
    {!premiApp.stayWCa? <Grid item xs={sta.StngIA.ncol} className={clas.BdyborBtL}>{txtA.custmName}</Grid>: null}

    { !sta.StngIA.showColDHd && !premiApp.stayWCa
       ? <Grid item xs={4} className={clasm.minborR}>{txtE.taxNumr}</Grid> : null }
    {!premiApp.stayWCa? <Grid item xs={sta.StngIA.ncol} ><Paper className={clasm.minclr}>{sta.tot.tax_no_cust}</Paper></Grid>:null}
    {!premiApp.stayWCa? <Grid item xs={sta.StngIA.ncol} className={clasm.minborL}>{txtA.taxNumr}</Grid>:null}
    </Grid>

    <Grid item container className={clas.Bdytabl}>

      <Grid item xs={5}>{txtE.subTotalWithVat}</Grid>
      <Grid item xs={2}>{txtE.discount}</Grid>
      <Grid item xs={2}>{txtE.Quantity}</Grid>
      <Grid item xs={3}>{txtE.unitPrice}</Grid>

      <Grid item xs={5}>{txtA.subTotalWithVat}</Grid>
      <Grid item xs={2}>{txtA.discount}</Grid>
      <Grid item xs={2}>{txtA.Quantity}</Grid>
      <Grid item xs={3}>{txtA.unitPrice}</Grid>

      {/* 
      يتم تضبيطها في اعداد حسب التغغير عربي او E
      <Grid item xs={3}>{txtE.unitPrice}</Grid>
      <Grid item xs={2}>{txtE.Quantity}</Grid>
      <Grid item xs={2}>{txtE.discount}</Grid>
      <Grid item xs={5}>{txtE.subTotalWithVat}</Grid>

      <Grid item xs={3}>{txtA.unitPrice}</Grid>
      <Grid item xs={2}>{txtA.Quantity}</Grid>
      <Grid item xs={2}>{txtA.discount}</Grid>
      <Grid item xs={5}>{txtA.subTotalWithVat}</Grid> */}

    </Grid>

    <Grid item container className={clas.Bdyrows}>
      {sta.info.map((item,i)=>
        <Grid item container key={i}>
          <Grid item className={clas.Bdyrow} xs={5}>{item.tot_net_curr}</Grid>
          <Grid item className={clas.Bdyrow} xs={2}>{item.discount}</Grid>
          <Grid item className={clas.Bdyrow} xs={2}>{item.val1}</Grid>
          <Grid item className={clas.Bdyrow} xs={3}>{item.new_exchange_price}</Grid>
          <Grid item className={clas.BdyrowSy} xs={12}>{item.symbol}</Grid> 
          {/* <Grid item xs={12}>{'--'}</Grid>  */}

          {/* 
           يتم تضبيطها في اعداد حسب التغغير عربي او E
          <Grid item xs={3}>{item.tot_net_curr}</Grid>
          <Grid item xs={2}>{item.val1}</Grid>
          <Grid item xs={2}>{item.discount}</Grid>
          <Grid item xs={5}>{item.new_exchange_price}</Grid>
          <Grid item xs={12}>{item.symbol}</Grid>  */}

        </Grid>
      )}
    </Grid>

    {/* <Grid item container>
    <Grid item xs={12} className={clas.BdymtTabl}>
     <MaterialTable style={{boxShadow:'none'}}
            //style={{direction:'ltr'}}
          options={{ 
            showTitle:false,
            toolbar: false,
            sorting: false,
            selection: false,
            // ToolBar Search
            searchFieldAlignment:"left",
            searchFieldVariant:"outlined",
            // ToolBar header
            showSelectAllCheckbox:false,
            showTextRowsSelected:false, 
            // body
            // rowStyle:(data,i)=>i%2!=0?{background:'#038b0321'}:null,
            // footer
            pageSize:5,
            paginationType:"stepped",
            showFirstLastPageButtons:false,
            
            paging:false,
            headerStyle: stElemnt.AF.header,
            cellStyle: stElemnt.AF.cell,
           }}
           
    
          localization={{   body:{ emptyDataSourceMessage:<h1></h1> ,}, }}
          
          
              columns={columns}
              data={sta.info}

             />
    </Grid>
    </Grid> */}

    {/* <Grid container>
    </Grid> */}

{AE ?totalsTitleA_():totalsTitleE_()}
{AE ?totalsContntA_():totalsContntE_()}
{/* totalsTitleA_()
totalsContntA_()
} */}
    {/* <br/> <br/> <br/> <br/> */}

    {/* <Grid item container className={clas.BdytotSub}>
      <Grid item xs={5} className={clas.BdytotLf}>{1}</Grid> 
      <Grid item xs={5} className={clas.BdytotCntr}>{1}</Grid> 
      <Grid item xs={2} className={clas.BdytotRit}>{1}</Grid>
    </Grid> */}


</Grid>
    );
}
export default Body

