import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  colors,
  Container,
  Divider,
  CardHeader,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '80px',
    fontFamily: "Amiri-Regular",
    backgroundColor: colors.grey[300],
    borderBottomColor: colors.grey[700],
    borderBottomWidth: "5px",
    borderBottomStyle: "solid",
    color: colors.red[900],
  },
  content: {
    alignItems: "center",
    display: "flex",
    fontFamily: "Amiri-Regular",
  },
  title: {
    fontWeight: 700,
    textAlign: "center",
    fontFamily: "Amiri-Regular",
  },
}));

const BalanceCard = (props) => {
  const { className, title, dept, credit, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation(["forms"]);
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <Typography
          className={classes.title}
          // color={colors.indigo[500]}
          // gutterBottom
          variant="body1"
        >
          {title}
        </Typography>

        <Container
          style={{
            // display:'flex',
            // margin:0,
            justifyContent: "center",
          }}
          maxWidth={false}
        >
          <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
            {" "}
            {t("dept") + ": "}
            {dept}{" "}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
            {" "}
            {t("credit") + ": "}
            {credit}{" "}
          </Typography>
        </Container>
      </CardContent>
    </Card>
  );
};

BalanceCard.propTypes = {
  className: PropTypes.string,
};

export default BalanceCard;
