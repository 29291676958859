import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useFetchObjectByPost from '../../../components/Hooks/useFetchObjectByPost';
import useFetchURL from '../../../components/Hooks/useFetchURL';
import { sto } from '../../../Context/InshData';
import { uri } from '../../../help/Api';
import AccountCustRPT from './accountCustRPT';

const defaultAutos ={
  Safes:[],
}

export default function AccountCustRPTCon() {

  const loca = useLocation();

      const tb = {
                  wait:true,
                  Branches:true,
                  costcenters:true,
                  AccountsIndex:true,
                  omlat: true,
                  stor:sto,
                  parms:{filtr: true, pcode: loca.state.pcode, },
            }
        const {object: AutoOptions, fetching:fetchAll} = useFetchObjectByPost(uri+'Rept',tb); 
        useEffect(()=>{
          console.log("loc al ", loca.state)
        })

  if(  fetchAll || !AutoOptions?.center )
    return <></>

  return (
  <AccountCustRPT {...{
    AutoOptions: AutoOptions?.center? AutoOptions: defaultAutos,
    title:loca.state.front,
  } 
  }/>
  );




};