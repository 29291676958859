import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../help/Api";

import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { sto } from "../../../Context/InshData";
//import {useStylesHed} from '../../../St/comps/UseStyle';

function Footer() {
  //const clashd = useStylesHed();
  //  const iInfo = JSON.parse(localStorage.getItem('iInfos')) || {};
  //  const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
  //  const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
  const locDinv = JSON.parse(localStorage.getItem("datainv")) || {};

  const [found, setFound] = useState({});
  const [inv, setInv] = useState({});

  //  const inshInfoInv = {
  //   tot:'الاجمالي', totBefurDisc:'الإجمالي قبل الخصم',
  //   totDisc:'اجمالي الخصم', totAfterDisc:'الإجمالي بعد الخصم',
  //   totBefurAmount:'المجموع قبل الضريبة',totAmount:'إجمالي الضريبة',
  //   tax:'ضريبة القيمة المضافة',shaml:'الإجمالي يشمل القيمة المضافة',
  //   cash:'المدفوع',agel:'المتبقي',
  //   note:'البيان', tell:'رقم الهاتف',phone:'الجوال',
  //  }

  const getFound = async () => {
    axios
      .get(uri + `Foundation?branch=${sto.branchi}`)
      .then((res) => {
        console.log("the foundations is footer", res.data);
        setFound(res.data);
      })
      .catch((error) => {});
  };

  const getInv = async () => {
    axios
      .get(uri + `InvForFatora?proc_id=${locDinv.proc_id}`)
      .then((res) => {
        setInv(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getFound();
    getInv();
  }, []);

  const sty = {
    cntr: { textAlign: "center", paddingTop: 10 },
    lft: { textAlign: "left", padingLeft: 5 },
    rit: { textAlign: "right", padingRight: 5 },
    dirR: {
      direction: "rtl",
      color: "black",
      // position: "relative",
      // style={{ }}
      // position: "fixed",
      // bottom: 0,
      // height:"200px",
      // width: "100%",
    },
    dirL: { direction: "ltr" },
  };
  return (
    <Grid item container style={sty.dirR}>
      <Grid item container style={sty.cntr} spacing={0}>
        <Grid item xs={6} style={{ fontFamily: "Amiri-Regular" }}>
          {inv.notes}
          <br />
          {found.BillNotes}
          <br />
          {"_____"}
          <br />

          {sto.usernames}
        </Grid>
        <br />
        {inv.tax > 0 && (
          <Grid item xs={6}>
            <ButtonBase style={{ border: "solid 1px" }}>
              <img
                src={`data:image/jpeg;base64,${inv.qr_image}`}
                style={{ height: 100, width: 100 }}
                alt={found.nameA}
              />
            </ButtonBase>
          </Grid>
        )}
        {/* <Grid item xs={12} style={{ fontFamily: "Amiri-Regular" }}>
        </Grid>
        <Grid item xs={12}>
          {"_____"}
        </Grid>
        <Grid item xs={12}>
        </Grid> */}
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={1}
        style={{ paddingTop: 20, fontWeight: "bold", textAlign: "center" }}
      >
        <Grid item xs={6} style={{ fontFamily: "Amiri-Regular" }}>
          {" "}
          {"  البائع" + "     " + " Salesmans Sign "}
        </Grid>
        <Grid item xs={6} style={{ fontFamily: "Amiri-Regular" }}>
          {"توفيع المستلم" + "     " + " Receivrs Sign "}
        </Grid>
        <Grid item xs={6}>
          {locDinv?.salesman}
        </Grid>
        <Grid item xs={6}>
          ____________________
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Footer;

// import React, { useState,useEffect } from 'react';
// import axios from 'axios';
// import {uri, }  from '../../../../help/Api';
// import Grid from '@material-ui/core/Grid';
// import { Typography } from '@material-ui/core';

// function Footer(){

//   const [state, setState] = useState({});
//   const getInfo = async () => {
//   axios.get(uri+'Foundation').then( res => { setState(res.data); }).catch(error => { });
// }

// useEffect(() => {
//   getInfo();
// }, []);

//     return (
//     <Grid className="fixed-footer bg">
//       <Grid item container style={{textAlign:'center',direction:'rtl',}}>
//         <Grid lg={3} md={3} sm={3} xs={3} >
//           <Typography className="lbl cb">المستخدم</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المحاسب</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المراجع</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المدير المالي</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={3} md={3} sm={3} xs={3} >
//           <Typography className="lbl cb">المدير العام</Typography> <Typography className="box b cw"></Typography>
//         </Grid>

//         <Grid lg={12} md={12} sm={12} xs={12} >
//           <Typography className="cw b">{state.Address}</Typography>
//         </Grid>

//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">هاتف:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Tel}</Typography></Grid>
//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">مبايل:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Mobile}</Typography></Grid>
//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">فاكس:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Fax}</Typography></Grid>
//       </Grid>
//     </Grid>
//     );
//   }

// export default Footer
