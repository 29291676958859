import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function ProfitMarginTable(props) {
  const { data, title } = props;
  const { t } = useTranslation(["forms"]);
  const classes = useStyles();
  
  const stylH={
    // borderWidth: 1,
    // borderTop: "solid 0.6px",
    // borderColor: "black",
    // borderStyle: "solid",
     fontFamily: "Amiri-Regular",
     fontWeight: 700,
     fontSize: 16,
     color:"#000"
   }
   const stylRo={
     BorderBottom: "solid 0.5px",
     //borderStyle: "solid",
     borderColor: "gray",
     fontFamily: "Amiri-Regular",
     fontWeight: 700,
     fontSize: 14,
     color:"#000"
   }
   
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{ backgroundColor: colors.green[300], color: "#fff" }}
          >
            <TableCell style={stylH} align="center">
              {"No"}
            </TableCell>
            <TableCell style={stylH} align="center">
              {t("item")}
            </TableCell>
            <TableCell style={stylH} align="center">
              {" "}
              {t("quantity-sold")}
            </TableCell>
            <TableCell style={stylH} align="center">
              {t("net-quantity")}
            </TableCell>
            <TableCell style={stylH} align="center">
              {t("net-discount")}
            </TableCell>
            <TableCell style={stylH} align="center">
              {t("net-tax")}
            </TableCell>
            <TableCell style={stylH} align="center">
              {t("net-cost")}
            </TableCell>
            <TableCell style={stylH} align="center">
              {t("net-profit")}
            </TableCell>
            <TableCell  align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,i) => (
            <TableRow key={row.id}
            style={{ padding: "5px 0px",
              textAlign: "-webkit-center",
              margin: "0px",
              whiteSpace: "nowrap",
              color: "#000",
              fontWeight: 800,
              fontFamily: "Amiri-Regular",
              fontSize: 15,
              border: "solid 0.6px"}}
            >
              <TableCell style={stylRo}  align="center">{i+1}</TableCell>
              <TableCell style={stylRo}  align="center">{row.txtType}</TableCell>
              <TableCell style={stylRo}  align="center">{row.qty}</TableCell>
              <TableCell style={stylRo}   align="center">{row.totNet}</TableCell>
              <TableCell  style={stylRo}  align="center">{row.minus}</TableCell>
              <TableCell style={stylRo}  align="center">{row.vat}</TableCell>
              <TableCell  style={stylRo} align="center">{row.cost}</TableCell>
              <TableCell style={stylRo}  align="center">{row.Profit}</TableCell>
              <TableCell  style={stylRo}  align="center">{}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
