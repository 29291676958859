import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonBase,
  Chip,
  colors,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  makeStyles,
  Paper,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import SaveIcon from "@material-ui/icons/Save";
import SketchPicker from "react-color";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";
import { BeatLoader } from "react-spinners";
import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../St/comps/UseStyle";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Autocomplete } from "@material-ui/lab";
import SettingsPrinter from "../SettingsPrinter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    direction: "rtl",
    margin: 10,
  },
  textField: {
    //width:80,
    margin: 10,
    marginRight: 5,
  },
  buttons: {
    marginRight: 20,
    marginLeft: 5,
    marginTop: 10,
    direction: "ltr",
  },
  input: {
    display: "none",
  },
  imageLogo: {
    marginRight: 20,
    marginTop: 5,
    marginBottom: 10,
    textAlign: "center",
  },
  textFields: {
    //width:80,
    marginRight: 20,
  },
  textField3: {
    //width:80,
    direction: "rtl",
    marginBottom: 5,
  },
  GridButton: {
    marginBottom: 40,
  },
}));

let hed = null;
let heddef = null;
function PdfSettings(props) {
  //   const { groupId, values } = props;
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [backGroundColor, setBackGroundColor] = useState();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadmore, setLoadmore] = useState(true);
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  let typeImg = "jpeg";
  const [fileImg, setFileImg] = useState({
    base64URL: "",
    code64: "data:image/" + typeImg + ";base64,",
    code8: "",
    baseURL: false,

    // file:null
  });
  function valuetexthed(value) {
    hed = value;
    data.sasInvHeadr = value;
    return `${value}°C`;
  }
  const changetf = (e) => {
    //  let img8 = decodeURIComponent(escape((e.target.value)));
    // console.log(img8)
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        //  console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };
  const handleFileInputChange = (e) => {
    let file = null; //= data.Logo;

    file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        let n = 23,
          str;
        if (file.type != "image/jpeg") n = 22;

        str = file.base64.substring(n);
        // console.log(file.type);

        setData({
          ...data,
          Logo: str,
          // file
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const updataFoundation = async () => {
    await axios
      .put(uri + "Foundation", data)
      .then((res) => {
        // getFoundation();
        setData({});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return <></>;
}

export default PdfSettings;
