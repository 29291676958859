import React from "react";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import LoadingPage from "../../../../services/LoadingPage";
import CostCenters from "./CostCenters";

export default function CostCentersCon() {
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url: uri + "Costcenter",
    },
  });

  if (!data || fetching) return <LoadingPage />;

  return <CostCenters {...{ data, fetchURL }} />;
}
