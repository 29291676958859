import React from "react";
import MaterialTable from "material-table";
import { useEffect } from "react";
import { Button } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import {cellS,headerS,stElemnt} from '../../../../St/comps/cStyle'
import { useTranslation } from "react-i18next";

const defaultData ={
    curr: '',
    unit: '',
    unitName: '',
    perc: '',
    purch: '',
    sale:'',
    barcode: '',
    unit_convert: '',
  }

export default function SubUnitsTable(props) {
    const { useState } = React;
    const {t} = useTranslation();
    const {currUnitList, selectRowHandle} = props;
    const headerS = {
      height: "20px",
      color: "#fff",
      textAlign: "-webkit-center",
      margin: "0px",
      whiteSpace: "nowrap",
      width: "0px",
      backgroundColor:  "#f18584",
      padding: "15px 0px",
      marginLeft: 1,
      marginRight: 1,
      // display:'none'
    };
  
    const columns=[
        {title: "unit", field: "unit", hidden: true},
        {
            title: t('forms:unit'),
            field: 'unitName',
            headerStyle: { paddingTop:'0px',paddingBottom:'0px', } 
        },
        { title: t('froms:packaging'), field: 'perc', 
        headerStyle: { paddingTop:'0px',paddingBottom:'0px',},},
        { title: t('forms:purch-price'), field: 'purch', 
        headerStyle: { paddingTop:'0px',paddingBottom:'0px',},  cellStyle:{ } },    
        { title: t('forms:sale-price'), field: 'sale',  headerStyle: { paddingTop:'0px', paddingBottom:'0px',margin:'0px',}},
      
      { title: t('forms:barcode'), field: 'barcode', headerStyle: { paddingTop:'0px', 
      paddingBottom:'0px',} },
    ];

    // useEffect(()=>{
    //   // console.log("units is", currUnitList)
    // },[currUnitList]);
    useEffect(()=>{
      // console.log("units is", currUnitList)
    },[currUnitList]);
    return (
      < >
      <MaterialTable
      style={{boxShadow:'none'}}
        title="Editable Preview"
        options={{
            // showTitle: false,
            search: false,
            toolbar: false,
            pageSize: 5,
            tableLayout: 'fixed',
            showTitle:false,
            padding: "dense",
            paging: false,  
            headerStyle: headerS,
            cellStyle: cellS,
            maxBodyHeight: '30vh',
            minBodyHeight: '30vh',

       }}
       
        columns={columns}
        data={currUnitList}
        onRowClick={(event, rowData) => {
            selectRowHandle(rowData);
            // event.stopPropagation();
          }}
      />
      </>
      
    )
  }
  