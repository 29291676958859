import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import BackdropScI from "../../services/BackdropScI";
import { premiA } from "../../Context/InshData";
// import {uri, }  from '../../help/Api';
import { useLocation } from "react-router-dom";
// import {sto,insh} from '../../Context/InshData';
import axios from "axios";
import dataF from "../../Server/dataF.json";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
// import dataF from '../../Server/dataF.json';
import { Apis } from "../../help/Apis";
import MenusContainer from "../Menues/MenusContainer";
import MenusContRef from "../Menues/MenusContRef";
import Swipeable from "./Swipeable";
import { useStylesMain } from "../../St/Fronts/UseStyle";
import { getLS } from "../../utils/useLS";
import POSDashboardCon from "../../comps/Dashboard/POSDashboardCon";

export default function Main(props) {
  const { isFromLgin } = props;
  const clas = useStylesMain();
  const loc = useLocation();
  // const [prmiScr, setPermiScr] = useState(insh.prmiScr);
  const [permiApp, setPermiApp] = useState({});
  const [showSc, setShowSc] = useState(true);
  let pScr = getLS("premiScr", {});
  const [uris, setUris] = useState({ uri: "http://localhost:8184/" });
  const [salePointOnly, setSalePointOnly] = useState(
    pScr?.salePointOnly ?? false
  );
  const getUPrmiOffc = async () => {
    // alert('iformidI= '+dataF[46].id+' , iformidR= '+dataF[48].id+' , iformidCuCo= '+dataF[3].id)
    const tb = {
      PermissionsCuCo: true,
      wait: true,
      // parms: { iuserid:sto_.idi,  iformidCuCo:dataF[3].id },
      parmsArr: [
        { iformid: dataF[46].id } /* 0 sal */,
        { iformid: dataF[48].id } /* 1 salRet */,
        { iformid: dataF[45].id } /* purch */,
        { iformid: dataF[47].id } /* purchRet */,
        { iformid: dataF[49].id } /* safe */,
      ],
      autos: {},
    };

    console.log(tb);
    // await axios.post('http://localhost:8184/api/Res',tb).then(res => {
    await axios
      .post(uris + "Res", tb)
      .then((res) => {
        localStorage.setItem("permiCuCo", JSON.stringify(res.data.permiCuCo));

        setShowSc(res.data.wait.state);
        // alert('ok')
      })
      .catch((error) => {
        alert("قم مبراجعة الدعم الفني لتفعيل خاصية الضريبة");
      });
  };

  const getUPrmi = async (urisp, stos) => {
    const tb = {
      wait: true,
      PermissionsScreen1: true,
      PermissionsPointsOfsales: true,
      parms: {
        iuserid: stos.idi,
        // iuserid:1
      },
      parmsArr: [
        // {
        //   grid:/*sales*/
        //   [
        { iformid: dataF[111].id } /* 0 charts p */,
        { iformid: dataF[47].id } /* 1 sal p */,
        { iformid: dataF[49].id } /* 2 salRet p */,
        { iformid: dataF[46].id } /* 3 purch p */,
        { iformid: dataF[48].id } /* 4 purchRet p */,
        { iformid: dataF[50].id } /* 5 safe p */,
        { iformid: dataF[109].id } /* 6 evrDay eqfDay p */,
        { iformid: dataF[100].id } /* 7 tafseel activ p */,
        { iformid: dataF[88].id } /* 8 safJrd p */,
        { iformid: dataF[38].id } /* 9 custAcc p */,
        { iformid: dataF[76].id } /* 10 acc p */,
        /* BasicData */
        /* T1 */
        { iformid: dataF[0].id } /* 11 found */,
        { iformid: dataF[2].id } /* 12 branch */,
        { iformid: dataF[12].id } /* 13 unit p */,
        { iformid: dataF[3].id } /* 14 item p */,
        { iformid: dataF[102].id } /* 15 itemGroup p */,
        { iformid: dataF[13].id } /* 16 taxGroup */,
        /* T2 */
        { iformid: dataF[2].id } /* 17 safes */,
        { iformid: dataF[5].id } /* 18 stocks */,
        { iformid: dataF[9].id } /* 19 bank */,
        { iformid: dataF[20].id } /* 20 centr */,
        { iformid: dataF[15].id } /* 21 omla */,
        { iformid: dataF[15].id } /* 22 accBook */,
        /* T3 */
        { iformid: dataF[40].id } /* 23 custGroup */,
        { iformid: dataF[43].id } /* 24 salesmen */,
        { iformid: dataF[43].id } /* 25 mokharg */,
        { iformid: dataF[6].id } /* 26 country */,
        { iformid: dataF[7].id } /* 27 city */,
        { iformid: dataF[8].id } /* 28 area */,
        /* T4 */
        { iformid: dataF[41].id } /* 29 mang */,
        { iformid: dataF[42].id } /* 30 dept */,
        { iformid: dataF[43].id } /* 31 job */,
        { iformid: dataF[16].id } /* 32 cardType */,

        { iformid: dataF[59].id } /* 33 salView p */,
        { iformid: dataF[73].id } /* 34 multiQ p */,
        { iformid: dataF[72].id } /* 35 multiS p */,
        { iformid: dataF[66].id } /* 36 sandQKh p */,
        { iformid: dataF[67].id } /* 37 sandSKh p */,
        { iformid: dataF[36].id } /* 38 addCust p */,
        { iformid: dataF[40].id } /* 39 groupCust p */,
        { iformid: dataF[68].id } /* 40 sandQD p */,
        { iformid: dataF[69].id } /* 41 sandSD p */,
        { iformid: dataF[74].id } /* 42 accountTree p */,
        { iformid: dataF[77].id } /* 43 accountRPT p */,
        { iformid: dataF[83].id } /* 44 restInsert p */,
        { iformid: dataF[84].id } /* 45 taxRPT p */,
        { iformid: dataF[39].id } /* 46 custPayTrans p */,
        { iformid: dataF[54].id } /* 47 saleByEmploy p */,
        { iformid: dataF[110].id } /* 48 saleAnalysis p */,
        { iformid: dataF[64].id } /* 49 profitMargin p */,
        { iformid: dataF[53].id } /* 50 saleProfit p */,
        { iformid: "20002" } /* 51 custBalance p */,
      ],
    };
    // console.log('__tb__'); console.log(tb); console.log('__tb__');
    //       await axios.post(`http://localhost:8184/api/Res`,tb).then(res => {
    // urisp = uris.uri+''
    // alert(urisp);
    // await axios.post(uris.uri+'api/'+`Res`,tb).then(res => {

    await axios
      .post(urisp + `Res`, tb)
      .then((res) => {
        console.log("__res__");
        console.log(res.data.permisScr);
        console.log("__res__");

        // setPermiScr(res.data.permisScr);
        window.localStorage.setItem(
          "premiScr",
          JSON.stringify(res.data.permisScr)
        );
        setSalePointOnly(res.data.permisScr?.salePointOnly);
        let loc = getLS("premiApp", {});

        // console.log('premiA');console.log(premiA);
        console.log("loc");
        console.log(loc);
        console.log("Maiiiiiiin");
        // alert('stop')
        setPermiApp(loc);
        setShowSc(res.data.wait.state);
        console.log("__res__");
      })
      .catch((error) => {});

    // setRiv({FChart:arr[0].F, FMD:arr[1].F, FTS:arr[2].F, FJSf:arr[3].F, FCust:arr[4].F});
  };

  useEffect(() => {
    console.log("premia", premiA);
    const urisR = Apis();
    let loc = getLS("DataRem", {});

    setUris(urisR);
    getUPrmi(urisR, loc);
  }, []);

  return showSc ? (
    <BackdropScI />
  ) : (
    <React.Fragment>
      <CssBaseline />
      {premiA.menuMin != undefined && premiA.id != 0 ? (
        <React.Fragment>
          {salePointOnly ? (
            <POSDashboardCon />
          ) : (
            <>
              <Container maxWidth="lg">
                <Typography component="div" className={clas.root}>
                  <MenusContainer />

                  {/* <MenusContRef/> */}
                </Typography>
              </Container>

              <Typography
                component="div"
                style={{
                  marginTop: "2vh",
                  backgroundColor: "#f6f7f9",
                  /*height: '96vh',*/ fontFamily: "Tajawal",
                }}
              >
                {/* {riv && <Dtatistics/>} */}
                <Swipeable />
              </Typography>
            </>
          )}
        </React.Fragment>
      ) : (
        <Container
          maxWidth="lg"
          style={{
            backgroundColor: "#fff",
            height: "100vh",
            paddingTop: "30vh",
          }}
        >
          <MenusContRef />
        </Container>
      )}
    </React.Fragment>
  );
}
