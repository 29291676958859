import * as yup from "yup";

export const subRestrictionValidateSchema = yup.object().shape({
  acc_no: yup.string().required("رقم الحساب غير صحيح"),
  dept: yup.string(),
  credit: yup.string().when("dept", {
    is: "",
    then: yup.string().required("أدخل قيمة المدين او الدائن"),
  }),
});

export const restrictionValidateSchema = yup.object().shape({
  // doc_no2: yup.string().required("doc_no2 not found"),
  total_dept: yup.number().required(),
  // .moreThan(
  //       0.00,
  //       `لا يوجد حسابات`),

  total_credit: yup
    .number()
    .oneOf([yup.ref("total_dept"), null], "القيد غير متوازن!"),
});
