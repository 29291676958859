import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {uri}  from '../../../../help/Api';
import {insh,sto} from '../../../../Context/InshData';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ScannerIcon from '@material-ui/icons/Scanner';
import KeyboardReturnSharpIcon from '@material-ui/icons/KeyboardReturnSharp';
import Chip from '@material-ui/core/Chip';
import PrintIcon from '@material-ui/icons/Print';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
// cuG
import {useStylesUp,useStylesHed,useStylesAuto,useStylesTF,useStylesTD,useStylesLbl
  ,useStylesUiElemment,
  useStylesSpDial} from '../../../../St/Reports/UseStyle';
  
import TrunItemsAvgTable from './TrunItemsAvg-table';
import { Toaster } from 'react-hot-toast';


export default function TrunItemsAvgForm(props){
  const {data, onChangeData, rptParams,
    printHandle,
     setRptParams,
     fetching,
    fetchURL}= props;
     
  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasAo = useStylesAuto();
  const claslbl = useStylesLbl();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const [gets, setGets] = useState(insh.tbname);

  const loadAutos = async () => {
        const tb = {
           wait:true,
          Safes:true, Currencies:true,
          stor:sto,
          parms:{
            safe:-1,  
          }
       }
        await axios.post(uri+'Rept',tb)
            .then(res => {
              setGets(res.data);
          }).catch(error => { /*console.log('no rpt');*/ })
  }

      
    useEffect(async () => {
        await loadAutos();

     }, []);

      const changAutos = (prop, newValue) =>
          {
            setRptParams({...rptParams, [prop]:newValue});
          }

      const toBack = () => { his.push({ pathname: '/', }); };

      let his = useHistory();

const frontDownR = () => {
  return(
        <Grid container lg={12} md={12} className={[clasup.rootdown,]}>
        <Grid container xs={12} className={[clasup.downsub,clasup.downsubR,]}>
  
        </Grid>
        </Grid>
  )
}
return (
  <React.Fragment>
    <Toaster />
  <CssBaseline />
  <Container maxWidth="xl">
    <Typography component="div" 
    style={{textAlign:'center', backgroundColor: '#fff', height: '100vh',direction:'rtl' }}>

 <Grid container> 

    <Grid item xs={12}>
     <Chip size="small" className={clashd.ChipHdSale} label={`معدل الدوران والركود للأصناف`} color="primary" />
    </Grid>

{/* S front Right */} 
<Grid container lg={4} md={5}>
    {/* S front up R */} 
    <Grid container lg={12} md={12} className={[clasup.rootup,]}>
    <Grid container spacing={1} xs={12} className={[clasup.upsub,clasup.upsubL,]}>
      <Grid item lg={6} md={6} sm={4} xs={6}>
      <Autocomplete classes={clasAo} 
                value={rptParams.safe} 
                labelPlacement=""
                onChange={(event, newValue) => { 
                    changAutos('safe', newValue);
                }}
                id="controllable-states-demo" size="small"
                options={gets.Safes}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                disableClearable
                renderInput={(params) => <TextField {...params} label="المخزن" variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clrSale}} />}
              />
      </Grid> 

      <Grid item lg={12} md={12} sm={8} xs={12}>
      <Autocomplete classes={clasAo}
                value={rptParams.curr}
                onChange={(event, newValue) => { changAutos('curr', newValue) }}
                id="controllable-states-demo" size="small"
                options={gets.items}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                disableClearable
                renderInput={(params) => <TextField {...params} label={'الصنف'} variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clrSale}} />}
              />
      </Grid>

      <Hidden>
      <Grid item container spacing={1} className={clasui.contbnt}>
        <Grid item >
        <Button 
        disabled={fetching}
        variant="contained" startIcon={<ScannerIcon />} color="primary" size="large"
          className={[clasui.button,clasSp.clPri]} onClick={()=>{fetchURL()}}>عرض </Button>
        </Grid>

        <Grid item >
        <Button variant="contained" startIcon={<PrintIcon />} color="primary" size="large"
          className={[clasui.button,clasSp.clSec]} 
          disabled={true} onClick={printHandle}>طباعة </Button>
        </Grid>
        <Grid item >
        <Button variant="contained" startIcon={<KeyboardReturnSharpIcon />} color="secondary" size="large"
          className={[clasui.button,clasSp.clcan]} onClick={toBack}>خروج</Button>
        </Grid>
      </Grid>
      </Hidden>

    </Grid></Grid>
        <Hidden smDown={['sm']}>{frontDownR()}</Hidden>
  {/* E front down R */}
</Grid>
<Grid container lg={8} md={7}>
    {/* s front up L */}
    <Grid container lg={12} md={12} className={[clasup.rootup,]}>
    <Grid container xs={12} className={[clasup.upsub,clasup.upsubL,]}>
      <Grid item xs={12}>
        <TrunItemsAvgTable {...{
            data: data
        }}/>
      </Grid>    
    </Grid>
    </Grid>


</Grid>
  <Hidden mdUp={['md']}>{frontDownR()}</Hidden>
</Grid>
    </Typography>
  </Container>

</React.Fragment>
 );
 
}