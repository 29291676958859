import moment from "moment";
import _ from "lodash";

export const parametersData = [
  { id: 1, name: "اصول وخصوم" },
  { id: 2, name: "ارباح وخسائر" },
  { id: 3, name: "رصيد افتتاحي" },
  { id: 4, name: "رصيد سابق" },
  { id: 5, name: "استثناء العمليات النقدية" },
  // { id: 6, name: "جميع العمليات النقدية" },
];

export const defaultParams = {
  dateFrom: moment(new Date()).format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  // orderType: sortTypes[0],
  docType: { id: -1, name: "" },
  branch: { id: -1, name: "الكل" },
  accounts: [],
  omla: { id: -1, name: "" },
  parameters: [...parametersData],
};

export function getLeafAccounts(data) {
  var res = [];
  for (let account of data) {
    if (account.Type === 1) {
      if (account.hasOwnProperty("children")) {
        var res1 = getLeafAccounts(account.children);
        res.push(res1);
      }
    } else if (account.Type == 2) {
      res.push(account);
    }
  }
  return res;
}
