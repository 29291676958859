import React from "react";
import { uri } from "../../../../../help/Api";
import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost"
import { insh, sto } from "../../../../../Context/InshData";
import BackdropScI from "../../../../../services/BackdropScI";
import IExchangeVoucher from "./IExchangeVoucher";
import { autos } from "../../../../../Context/Local";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { accountsURL, requestData } from '../../../../../api/IExchange';
function getReFormatingAccount(data){

    const newData = data.map((row, i)=> {
            return {id: row.id,
                 name:row.symbol,
                 code: row.barcode,
                } 
        })
    return newData
}

function IExchangeVoucherItem(props){

  
    const url = uri+'Res';

    const {object:AutoOptions, fetching: fetchinData, fetch:fetchURL} =  useFetchObjectByPost(url, requestData);
    const {data, fetching, fetchURL: fetchURL1} = useFetchURL({...{
      url: accountsURL,
      mapFunction: getReFormatingAccount,
    }})


      if(fetchinData || !data || fetching || !AutoOptions.Stocks)
        return <BackdropScI />
    return (
      // <div></div>
        <IExchangeVoucher
        {...{
            AutoOptions: AutoOptions?.Employees? {...AutoOptions, Customers: data}: [],
            fetchURL,
            prmis: AutoOptions?.permisElm?? insh.permiElm, 
            ...props,
        }}/>
    )
}

   

export default function IExchangeVoucherCon(){

    return (
      
        <IExchangeVoucherItem
        {...{
          isDialog: false,
            
        }}/>
    )
}

export function IExchangeVoucherLink(props){
  const {data: item, onClose,} = props;

    return (
        <IExchangeVoucherItem
        {...{
            isDialog: true,
            item,
            onClose,
        }}/>
    )
}
