import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import {local} from '../../../Context/Local';
import { useHistory } from 'react-router-dom';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// cuG
import {useStyleC} from '../st/UseStyle';
// csG
import {style} from '../st/cStyle'

import Icons from './Icons';


export default function CardP() {
  const classes = useStyleC();
  const [expanded, setExpanded] = React.useState(false);
  let his = useHistory();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const dept = (i) => {
    // console.log(local[i])
    his.push({
      pathname: '/depts',
      state: { local:local[i], },
    })
  };
  
  return (
    <Card className={classes.rootParsh}>
      {/* <CardHeader className={classes.header}
        avatar={
          <Avatar  aria-label="recipe" className={classes.avatar}>
            1
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        // title="المبيعات"
        subheader="كل ما تحناجه داخل المبيعات"
      /> */}
      <CardActionArea onClick={()=>dept(1)}>
      <CardMedia className={classes.mediaP} />

      <CardContent className={classes.content} style={style.color.parsh} >
        <Typography variant="h5">
       المشتريات
        </Typography>
      </CardContent>
      </CardActionArea>
      <CardActions disableSpacing className={classes.actions}>
        {/* <IconButton aria-label="add to favorites"> <FavoriteIcon /> </IconButton> */}
        {/* <IconButton aria-label="share"> <ShareIcon /></IconButton> */}
        {/* <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton> */}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.slidcontent}>
          {/* <Typography > */}
            {/* كل ما تحتاجه داخل قسم المبيعات */}
          {/* </Typography> */}

          {/* <Typography > */}
          <Icons/> 
          {/* </Typography> */}

        </CardContent>
      </Collapse>
    </Card>
  );
}



