import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import Barcode from "react-barcode/lib/react-barcode";
import { sto } from "../../../../../Context/InshData";

function BarcodeGenerator(props) {
  const { barcode, itemName, price } = props;

  return (
    <div
    
      style={{ textAlign: "center", backgroundColor: "#fff", padding: "10px" }}
    >
      <Typography
        style={{
          padding: "0",
          fontSize: "0.7rem",
          fontWeight: "bold",
          marginBottom: "0.1rem",
          maxWidth: "30mm",
          textAlign:"center"

          // zIndex: 100,
        }}
      >
        {itemName}
      </Typography>
      <Barcode
        id="mybarcode"
        value={barcode}
        background="#fff"
        lineColor="black"
        width="1rem"
        height="20"
        
        format="CODE128"
        displayValue="true"
        font="monospace"
        textAlign="center"
        textPosition="bottom"
        textMargin="3"
        fontSize="14"
        //marginTop="0"
        //marginBottom="0"
      />
      <Typography
        style={{
          direction: "rtl",
          marginTop: "0.2rem",
          // fontSize: "10px",
          fontWeight: "bold",
          textAlign:"center",
          backgroundColor: "transparent",
          padding: "0",
          fontFamily: "Amiri-Regular"
        }}
        // color={"primary"}
      >
        {" "}
        {price} {"رس  "}
      </Typography>
    </div>
  );
}

export function BarcodeGenerator04(props) {
  const { barcode, itemName, price } = props;

  return (
    <div
      style={{ textAlign: "center", backgroundColor: "#fff", padding: "10px" }}
    >
      <Typography
        style={{
          padding: "0",
          fontSize: "11px",
          fontWeight: "bold",
          marginBottom: "-3px",
          maxWidth: "45mm",
          // zIndex: 100,
        }}
      >
        {itemName}
      </Typography>
      <Barcode
        id="mybarcode"
        value={barcode}
        background="#ffffff"
        lineColor="black"
        width="1"
        height="20"
        format="CODE128"
        displayValue="true"
        font="monospace"
        textAlign="center"
        textPosition="bottom"
        textMargin="3"
        fontSize="14"
        marginTop="0"
        marginBottom="0"
      />
      {/* <div style={{ direction: "rtl", textAlign: "center" }}>
        <Grid container xs={12} style={{ fontSize: "9px" }}>
          <Grid item xs={8}>
            {sto?.found}
          </Grid>
          <Grid item xs={4}>
            {" "}
            {price}
            {"رس  "}
          </Grid>
        </Grid>
      </div> */}
      <Typography
        style={{
          direction: "rtl",
          marginTop: "-10px",
          // fontSize: "10px",
          fontWeight: "bold",
          backgroundColor: "transparent",
          padding: "0",
        }}
        // color={"primary"}
      >
        {" "}
        {price} {"رس  "}
      </Typography>
      <Typography
        style={{
          direction: "rtl",
          marginTop: "-4px",
          fontSize: "10px",
          marginBottom: "-5px",
          backgroundColor: "transparent",
          padding: "0",
        }}
        // color={"primary"}
      >
        {" "}
        {sto?.found}
      </Typography>
    </div>
  );
}

export default BarcodeGenerator;
