import React, { Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CastConnectedIcon from '@material-ui/icons/CastConnected';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
// import Image from '../assets/img/loks.png'; // Import using relative path
import Image from '../assets/img/nocon.png'; // Import using relative path
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import jsPDF from 'jspdf';
import { callAddFont } from '../Reviews/amiri-font';
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
   // backgroundColor: theme.palette.secondary.main,
   // width: '55%',
   width: '80%',
   height: '80%',
  },
  AvatarSpan: {
    width: '80%',
    height: '80%',
    textAlign: '-webkit-center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submitContent: {
    margin: theme.spacing(3, 0, 2),
  },
  

  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(2.5,6),
    backgroundColor: '#00ad9f',
    borderRadius:10
  },
  endemam:{
     margin: theme.spacing(5, 0, 0, 0),
    // padding: theme.spacing(2.5,6),
    // backgroundColor: '#00ad9f',
    // borderRadius:10
  },
  large: {
  width: theme.spacing(7),
  height: theme.spacing(7),
  width: '85%',
    height: '178px',
},

}));

const styles = {
  paperContainer: {
      backgroundImage: `url(${Image})`,
      height: '229px',
      boxShadow:'none',
      // width:'194px',
       width: '100%',
      borderRadius: '5px',
      backgroundSize: 'cover',
  },
};

const msgbtnSt ={
  backgroundColor: '#fff',    
  color:'#00ad9f',
  fontWeight: 'bold',
  borderRadius:10
}

export default function ReConnect() {
  const classes = useStyles();
  let his = useHistory();

  function reCon() {
    window.location.reload(false);
   
  }
  const handleGeneratePdf = async() => {
    jsPDF.API.events.push(["addFonts", callAddFont]);
    var doc = new jsPDF();
    doc.setFont("Amiri-Regular", "normal");
    doc.setFontSize(30);
    doc.text("hello to edrees",20, 50 );
    var y = 0;


      const pdf = doc.output('blob');
      const myFile = new File([pdf], "test.pdf", {type:pdf.type})
      const formData = new FormData();
      formData.append('PdfFile', myFile);
      formData.append("PrinterPath","\\ORAXSERVER050\\Microsoft Print to PDF")

      await axios.post('http://localhost:7000/print/from-pdf', formData, {
        headers:{
          "Content-type":'multipart/form-data',
        }}).then(res=>{console.log("the respose  is",)})

      await axios.post("http://localhost:7000/printers/install?printerPath=\\ORAXSERVER050\\Microsoft Print to PDF")
      .then(res=>{console.log("the respose  is",)})
      
  };
  function refreshPage() {
    his.push({ pathname: '/' })
    // reCon()
  }

  const refLocDatasEndmam = () => {
    // alert('hi')
    localStorage.removeItem("dataShamel");
    localStorage.removeItem('DataRem');
    localStorage.removeItem("iBranch");
  }

  const locConn = JSON.parse(localStorage.getItem('infoConn')) || {};

  const inshState = {
    server:locConn.server,
    ipinsh:locConn.ipinsh,
    changip:locConn.changip,
    ip:locConn.ip,
    endDigtip:locConn.endDigtip,
    namedb:locConn.namedb,
    id:locConn.id,
    pass:locConn.pass,
    port:locConn.port,
    ftp:locConn.ftp,
    useUri:locConn.useUri,
    typeConnct:locConn.typeConnct,
    leavle:'endemam',
   };

  const setDBName = async (stateConn) => localStorage.setItem('infoConn', JSON.stringify(stateConn)); 

  const goEndemam = () => {
    setDBName(inshState);
    refLocDatasEndmam();
    // getappfalse();
    refreshPage();
  }

  return (
    <React.Fragment>
      <CssBaseline />
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <span className={classes.AvatarSpan}>
        {/* <Avatar src="./Recon.png" className={classes.avatar} /> */}
        </span>
      <Paper style={styles.paperContainer}>  </Paper>

<br/>
        <Typography component="h1" variant="h5">
        
        <Button
        
        style={msgbtnSt}
            fullWidth
            disabled
            variant="contained"
            className={classes.submitContent}
            color="primary"

          >
{'سرفر النظام مغلق في هذا الوقت ستظهر الواجهة الرئيسية عندما يصبح السرفر مفتوح إنقر على'}
            

            {' "تـحـديـث" '}
            لتحقق من فتح السيرفر
            
          </Button>

        </Typography>

        <Grid container ex={12} justify="center">
            <Button  
            className={classes.submit}
            onClick={handleGeneratePdf}
            color="primary"
            variant="contained"
            size="large"
            startIcon={<CastConnectedIcon />}
            >
            {'تـحـديـث'}
            </Button>
            </Grid>

            <Grid container ex={12} justify="center">
            <Button  
            className={classes.endemam}
            onClick={goEndemam}
            color="secondary"
            size="small"
            startIcon={<ExitToAppOutlinedIcon />}
            >
            {'إعادة إنظام'}
            </Button>
            </Grid>

  
      </div>
 
    </Container>
    </React.Fragment>
  );
}

