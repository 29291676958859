import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colors, Grid } from "@material-ui/core";
import BalanceCard from "../../../../components/BalanceCard";
import TotalCard from "../../../../components/TotalCard";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: colors.grey[300],
    height: "85px",
    // borderColor: colors.grey[700],
    borderColor: colors.grey[700],
    borderWidth: "1px",
    borderStyle: "solid",
    color: colors.red[900],
  },
}));

export default function MainRBalanceTotals(props) {
  const { data, isMoragaa, level } = props;
  const classes = useStyles();
  const { t } = useTranslation(["forms"]);

  useEffect(() => {
    console.log("hello is ", data);
  });
  const nData = level == -1 ? data.filter((d) => d.Acc_IsSub == 2) : data;
  const dptTotal = nData
    .reduce((a, v) => (a = a + v.End_DR_AMT_L), 0)
    .toFixed(2);
  const crdtTotal = nData
    .reduce((a, v) => (a = a + v.End_CR_AMT_L), 0)
    .toFixed(2);

  const total = crdtTotal - dptTotal;

  return (
    <Grid
      container
      spacing={1}
      style={{ paddingTop: "10px", fontFamily: "Amiri-Regular" }}
    >
      <Grid item lg={2} md={3} sm={4} xs={6}>
        <BalanceCard
          {...{
            title: t("initial-balance"),
            dept: nData
              .reduce((a, v) => (a = a + v.val_open_dept), 0)
              .toFixed(2),
            credit: nData
              .reduce((a, v) => (a = a + v.val_open_credit), 0)
              .toFixed(2),
          }}
        />
      </Grid>
      <Grid item lg={2} md={3} sm={4} xs={6}>
        <BalanceCard
          {...{
            title: t("prev-balance"),
            dept: nData.reduce((a, v) => (a = a + v.DR_AMT_L), 0).toFixed(2),
            credit: nData.reduce((a, v) => (a = a + v.CR_AMT_L), 0).toFixed(2),
          }}
        />
      </Grid>
      <Grid item lg={2} md={3} sm={4} xs={6}>
        <BalanceCard
          {...{
            title: t("acc-move"),
            dept: nData.reduce((a, v) => (a = a + v.OB_DR_AMT_L), 0).toFixed(2),
            credit: nData
              .reduce((a, v) => (a = a + v.OB_CR_AMT_L), 0)
              .toFixed(2),
          }}
        />
      </Grid>
      <Grid item lg={2} md={3} sm={4} xs={6}>
        <BalanceCard
          {...{
            title: t("acc-balance"),
            dept: dptTotal,
            credit: crdtTotal,
          }}
        />
      </Grid>
      <Grid item lg={2} md={3} sm={4} xs={6}>
        {isMoragaa ? (
          <TotalCard
            {...{
              className: classes.card,
              title: " ",
              value: total != 0 ? t("trial-mism") : t("trial-is-iden"),
            }}
          />
        ) : (
          <TotalCard
            {...{
              className: classes.card,
              title: t("net-profit"),
              value: total.toFixed(2),
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
