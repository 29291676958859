import 'date-fns';
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {uri} from '../../../help/Api';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment  from '@material-ui/core/InputAdornment';
import Toolbar from '@material-ui/core/Toolbar';
import useTable from "../../../componentsT/useTable";
//componentsT/useTable";

import Controls from "../../../componentsT/controls/Controls";
import { Search } from "@material-ui/icons";
import useFetchURL from '../../../components/Hooks/useFetchURL';

const useStyles = makeStyles((theme) => ({
    
    pageContent: {
      margin: theme.spacing(1),
      // padding: theme.spacing(1)
  },
  searchInput: {
      width: '100%'
  },
  codsntainer: {
    width: '100%'
  },
  
  }));


export default function AccountSearchModal(props){

    const {open, handleClose, onSelectAccount, } =props;

    const classes = useStyles();
    const url = uri+'accounts-index/getForSearch';
    const {data: records, fetching,} = useFetchURL({...{url, dependencyArray:[]}});
    
const headCells = [
    { id: 'barcode', label: 'كود الحساب', minWidth: 170 },
    { id: 'symbol', label: 'اسم الحساب', minWidth: 170 },
    // { id: 'Load', label: 'تنزيل', disableSorting: true },
  ];
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [scroll, setScroll] = React.useState('paper');


  const {
    TblContainer,
    TblHead,
    recordsAfterPagingAndSorting
  } = useTable(records, headCells, filterFn);

  const handleSearch = e => {

    let target = e.target;
    setFilterFn({
        fn: items => {
            if (target.value == "")
                return items;
            else
                return items.filter(
                  x =>   x.barcode.toString().toLowerCase().includes(target.value)  || x.symbol.toLowerCase().includes(target.value) 
                  )
        } })
    
      }
    return(
        <Dialog
        open={open}
        maxWidth={{maxWidth:'100%'}}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        
        >
        <DialogTitle id="scroll-dialog-title">تنزيل كود واسم الحساب</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>

        <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        label="بحث شامل"
                        size="small"
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={(e)=>handleSearch(e)}
                    />
                </Toolbar>

                <TableContainer className={classes.container}>
                <Table size="small"  styles={{height: '250px'}}>
                    <TblHead className={classes.container} >

                    </TblHead>
                    <TableBody className={classes.container} >
                        
                        {recordsAfterPagingAndSorting().map((item,index) =>
                                (<TableRow
                                    hover 
                                    onClick={() => { onSelectAccount(item) }}
                                    //onDoubleClick={() => getrowDataCurrenciesDouble()}
                                key={item.barcode}>
                                    
                                    <TableCell>{item.barcode}</TableCell>
                                    <TableCell>{item.symbol}</TableCell>
                                </TableRow>))}

                    </TableBody>



                    </Table>
                </TableContainer>
            </Paper>

        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
            إلغاء
            </Button>
        </DialogActions>
        </Dialog>

    )
}