import React, { useState,useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PrintIcon from '@material-ui/icons/Print';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import {useStylesSpDial} from '../../../St/Reviews/UseStyle';
import ShareIcon from '@material-ui/icons/Share';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import axios from 'axios';
import {uri}  from '../../../help/Api';
import jsPDF from 'jspdf';
import { callAddFont } from '../../amiri-font';
const api = axios.create({ baseURL:uri });

class ComponentToPrint extends React.Component {
  state = {
    pwca:true,
}

  constructor(){ super();

   }


  render() {
 const locInv = JSON.parse(localStorage.getItem('premiApp')) || {};

 const styleCont = {
  // backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
  backgroundColor: '#fff',
};

const style = {
  // backgroundColor: 'green', 
  width:'80mm',

  backgroundColor: '#fff',
 //backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
};
const stylle = {
  // backgroundColor: 'green', 
  width:'58mm',
  backgroundColor: '#fff',

 //backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
};
    const styleTyg = { 
      backgroundColor: '#fff', 
      color :'#000',
     // paddingTop: '0.2cm',
     // paddingBottom: '0.2cm',

        padding:'0.2mm' 
      };

    return (
      <Grid item container justifyContent='center' style={styleCont}> 
      <Grid item 
      style={{
        width:`${locInv.cachirWidth}mm`,
        backgroundColor:'#fff'
      }}
      > 
      <CssBaseline />
        <Typography component="div" style={styleTyg}>
        <Header/>
        <Body/>
        <Footer/>
       </Typography>
      </Grid> 
      </Grid> 
      
    );
  }
}

const RevCasher = () => {
  const componentRef = useRef();
  const reportDownloadRef = useRef(null);
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const clasSp = useStylesSpDial();
  const [open, setOpen] = useState(true);
  const handleOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(true); };

  let his = useHistory();

  const back = (path) =>
  {
    path ? his.push({ pathname: '/'}) : his.goBack();
  }

  const shareData = async () => {
    jsPDF.API.events.push(["addFonts", callAddFont]);
    setLoading(true);
    var doc =  new jsPDF("l", "pt", "letter");
    doc.setFont("Amiri-Regular", "normal");
    let mfile;
    await doc.html(reportDownloadRef.current, {
      async callback(doc) {
        var blob = await doc.output("blob");
        mfile = new File([blob], "docc.pdf", { type: blob.type });
      }
    });

    if (navigator.share && navigator.canShare({ files: [mfile] })) {
      await navigator.share({
        title: "hello to orax app",
        text: "welcome this is text",
        url: "orax.app/",
        files: [mfile]
      });
    } else {
      alert("cant share files");
    }
        setLoading(false);

  };

  const handleGeneratePdf = () => {
    
      jsPDF.API.events.push(["addFonts", 
      callAddFont]);
    var doc =  new jsPDF("l", "pt", "letter");
    doc.setFont("Amiri-Regular", "normal");
    doc.html(reportDownloadRef.current, {
      async callback(doc) {
        setLoading(true);
        await doc.save("documen`t");
        setLoading(false);
      }
    });
  };

  const actions = [
    { icon: <ReactToPrint trigger={() => <PrintIcon className={clasSp.ico} /> } 
    content={() => componentRef.current} />, name: 'طباعـة' },
    { icon: <ArrowDownward className={clasSp.ico} onClick={handleGeneratePdf} />, name: 'تنزيل' },
    { icon: <ShareIcon className={clasSp.ico} onClick={shareData} />, name: 'مشاركة' },
    { icon: <ArrowForwardIcon className={clasSp.ico} onClick={()=> back(false)} />, name: 'رجـوع' },
  ];
  return (
    <div ref={reportDownloadRef} className={clasSp.root} 
    dir={'rtl'} lang={'ar'} style={{fontFamily: 'Amiri-Regular', 
      fontStyle:"normal", letterSpacing:'normal'}}>
      <ComponentToPrint ref={componentRef} />
      <SpeedDial ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial} hidden={false}
        icon={<SpeedDialIcon openIcon={<HomeIcon />} onClick={()=> back(true)}  />}
        onClose={handleClose} onOpen={handleOpen} open={open}>
        {actions.map((action,i) => ( <SpeedDialAction key={i}
        disabled={loading}
         icon={action.icon} tooltipTitle={action.name} onClick={handleClose} />))}
      </SpeedDial>
    </div>
  );

};


export default RevCasher