import React,{useEffect, useState} from 'react';
import axios from 'axios';
import {uri}  from '../../../help/Api';
import {sto} from '../../../Context/InshData';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Grid from '@material-ui/core/Grid';
import dataF from '../../../Server/dataF.json';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

// cuG
import {useStyleI} from '../st/UseStyle';
// csG
import {style} from '../st/cStyle'

export default function Icons() {
  const classes = useStyleI();
  let his = useHistory();
  const timer = React.useRef();

  const inshRiv={Rw:false};
  const [riv, setRiv] = useState(inshRiv);
  const getUPrmi = async () => {
    const arr = [{v:false}];
    let i=0,m=36;
        await axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[m].id}`).then(r => {
          if(r.data.user_id !== null) arr[i].v=false; else arr[i].v=true;
        }).catch(error => { })
         setRiv({Rw:arr[0].v});
  }

  useEffect( async() => {
   await getUPrmi();
  }, []);


  const clickCust = async (t) => {
    his.push({
      pathname: '/Customers',
      state: {setting:{type:t},},
    })
  }; 
        
  return (

<Card className={classes.root}>
<Grid container className={classes.container} spacing={1}>

<Grid item lg={12} md={12} sm={12} xs={12} >
<CardActionArea disabled={riv.Rw} onClick={() => clickCust(1)} 
className={classes.action} style={style.backColor.customer}>

{/* <div>{riv.Rw+''}</div> */}
<CardContent className={classes.content} >
   {/* <Typography component="h5" variant="h5"> */}
   {/* <Typography variant="subtitle1" color="textSecondary">  */}
  <Typography>إضافة عميل جديد</Typography>
   {/* </Typography>  */}
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <PersonAddIcon className={classes.icon} />

</CardMedia>
</CardActionArea>
</Grid>


<Grid item lg={12} md={12} sm={12} xs={12} >
<CardActionArea disabled={riv.Rw} onClick={() => clickCust(2)} 
className={classes.action} style={style.backColor.customer}>

{/* <div>{riv.Rw+''}</div> */}
<CardContent className={classes.content} >
   {/* <Typography component="h5" variant="h5"> */}
   {/* <Typography variant="subtitle1" color="textSecondary">  */}
  <Typography>إضافة مورد جديد</Typography>
   {/* </Typography>  */}
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <PersonAddIcon className={classes.icon} />

</CardMedia>
</CardActionArea>
</Grid>

{/* بداية المور */}


{/* نهية المور */}


{/* <button onClick={fhs}>
  f h s
</button> */}

{/* <Grid item lg={6} md={12} sm={12} xs={6}>
<CardActionArea className={classes.action}>

<CardContent className={classes.content}>
  -------------
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <ListAltIcon className={classes.icon} />
</CardMedia>
</CardActionArea>
</Grid>

<Grid item lg={6} md={12} sm={12} xs={6}>
<CardActionArea className={classes.action}>

<CardContent className={classes.content}>
 ---------------
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <LowPriorityIcon className={classes.icon} />
</CardMedia>
</CardActionArea>
</Grid>*/}

</Grid> 

    </Card>

  );
}
