import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import Button from "../../../../components/controls/Button";
import { TextField, MenuItem } from "@material-ui/core";
import { secondFiltersOptions } from "../../../../services/taswya-servece";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function TaswyaFiltersDialog(props) {
  const { isOpen, setIsOpen, filters, setFilters, groups, GetByFilters } =
    props;
  const { t } = useTranslation(["forms"]);
  const [localFiler, setLocalFiler] = useState(filters);
  const classes = useStyles();

  return (
    <Dialog open={isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        {t("fetch-title")}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="subtitle2">{t("by-group")}</Typography>
        <Typography>
          <form noValidate autoComplete="off">
            <TextField
              fullWidth
              select
              id="types"
              // style={{...style.bakClr,...{padding:5,fontSize:11,color:'#fff'}}}
              label={
                localFiler.group.name === ""
                  ? "المحموعة"
                  : localFiler.group.name
              }
              value={localFiler.group.name}
              // onChange={(e)=>setFilters({...filters, group: })}
              InputLabelProps={{ shrink: false }}
              // SelectProps={{ MenuProps: { className: clasTA.menu,}, }}
              size="small"
              variant="outlined"
            >
              {groups &&
                groups?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id + ""}
                    onClick={() => {
                      setLocalFiler({ ...localFiler, group: option });
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </form>
        </Typography>
        <br></br>
        <Typography variant="subtitle2">{t("by-filter")}</Typography>
        <Typography variant="h6">
          <form noValidate autoComplete="off">
            <TextField
              fullWidth
              select
              id="types"
              // style={{...style.bakClr,...{padding:5,fontSize:11,color:'#fff'}}}
              label={
                localFiler.filter.name === ""
                  ? "الفلترة"
                  : localFiler.filter.name
              }
              value={localFiler.filter.name}
              // onChange={(e)=>setFilters({...filters, filter: })}
              InputLabelProps={{ shrink: false }}
              // SelectProps={{ MenuProps: { className: clasTA.menu,}, }}
              size="small"
              variant="outlined"
            >
              {secondFiltersOptions?.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id + ""}
                  onClick={() => {
                    setLocalFiler({ ...localFiler, filter: option });
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </form>
        </Typography>
        <br></br>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          text={t("cancel")}
          color="default"
          onClick={() => {
            setIsOpen(false);
          }}
        />
        <Button
          text={t("download")}
          color="primary"
          onClick={() => {
            GetByFilters(localFiler);
            // setFilters(localFiler);
            setIsOpen(false);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
