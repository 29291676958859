import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
  } from "@material-ui/core";
  import axios from "axios";
  import { Fragment, useState } from "react";
  import toast from "react-hot-toast";
  import { uri } from "./help/Api";
  import { useStylesTD, useStylesTF } from "./St/comps/UseStyle";
  
  
  export default function LoginTest() {
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    // useEffect(() => {
    //   console.log("the items is", sto);
    // });
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    const checkConfirm = () => {
      axios
        .post(
          "http://127.0.0.1:8189/api/Account/Register" ,
            {
                Email:username,
                Password: password,
                ConfirmPassword: password,
            }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
          toast.error("لا يوجد لديك صلاحية للوصول");
        });
    };
    const getToken = () => {
      axios
        .post(
          "http://127.0.0.1:8189/token",
          {
            username:'eds@gmail.com',
            Password: 'Eds1Eds2#',
            grant_type: "password",
        }
          
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
          toast.error("لا يوجد لديك صلاحية للوصول");
        });
    };
    const GenerateCSRAndOnboardingCSID = () => {

      const model ={
        Email: "3003306724",
        VatNum: "300696018700003",
        BranchName: "DAMMAM branch",
        TaxPayerName: "Abdulaziz hafeez almahry Est",
        InvoiceTypeCode: "1100",
        Address: "EMAB8344",
        Category: "Contracting",
        IsProductionCsr: "false",
        CRN: "2050209386",
        AdditionalStreetAddress: "2070",
        BuildingNumber: "83449", 
        CityName: "DAMMAM",
        DistrictName: "As Salam Dist",
        StreetName: "no.471",
        PostalCode: "32416",
        OTP: "410408",
      }
        axios
          .post("http://127.0.0.1:8189/api/Zatca/GenerateCSRAndOnboardingCSID",model)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            console.log(err);
            toast.error("لا يوجد لديك صلاحية للوصول");
          });
      };
  
    return (
      <Fragment>
        <Container justifyContent="center">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"username"}
              label={"اسم المستخدم"}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"username"}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </Container>
          <Container justifyContent="center">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"password"}
              label={"كلمة المرور"}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="password"
              name={"password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Container>
 
          <Button variant="contained" onClick={GenerateCSRAndOnboardingCSID}>
            دخول
          </Button>
          <Button variant="contained" onClick={getToken}>
            get token
          </Button>

      </Fragment>
     
    );
  }
  