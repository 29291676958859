import React from "react";
import { useEffect } from "react";

import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";
import AccountingRPT from "./accountingRPT";

const defaultAutos = {
  Safes: [],
};

export default function AccountingRPTCon() {
  const tb = {
    wait: true,
    Branches: true,
    costcenters: true,
    AccountsIndex: true,
    omlat: true,
    stor: sto,
    parms: { safe: -1 },
  };
  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb
  );

  // useEffect(() => {
  //   console.log("the account is", tb.AccountsIndex);
  // });
  if (fetchAll || !AutoOptions?.center) return <></>;

  return (
    <AccountingRPT
      {...{
        AutoOptions: AutoOptions?.center ? AutoOptions : defaultAutos,
      }}
    />
  );
}
