import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../../help/Api";

import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import { useHistory, useLocation } from "react-router-dom";
import { sto } from "../../../../Context/InshData";

function HeaderA() {
  const cls = useRptAF();
  const [state, setState] = useState({});
  const getInfo = async () => {
    axios
      .get(uri + `Foundation?branch=${sto.branchi}`)
      .then((res) => {
        setState(res.data);
      })
      .catch((error) => {});
  };

  const loc = useLocation();
  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid item container className={cls.root}>
      <Grid item xs={5} className={cls.rootsub}>
        <Typography style={{ fontFamily: "Amiri-Regular" }}>
          {state?.nameE}
        </Typography>
        <Typography style={{ fontFamily: "Amiri-Regular" }}>
          {state?.FieldE}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <ButtonBase>
          <img
            src={`data:image/jpeg;base64,${state.Logo}`}
            className={cls.logo}
            alt={state.nameA}
          />
        </ButtonBase>
      </Grid>
      <Grid item xs={5} className={cls.rootsub}>
        <Typography style={{ fontFamily: "Amiri-Regular" }}>
          {state.nameA}
        </Typography>
        <Typography style={{ fontFamily: "Amiri-Regular" }}>
          {state.FieldA}
        </Typography>
      </Grid>

      <Grid item xs={12} className={[cls.title, cls.titleM]}>
        <Typography style={{ fontFamily: "Amiri-Regular" }}>
          {loc?.state?.title}
        </Typography>
      </Grid>

      <Grid item container className={[cls.title, cls.titleS]}>
        <Grid item container className={cls.titLin2}>
          <Grid item xs={3}>
            {"من تاريخ"}
          </Grid>
          <Grid item xs={3}>
            {loc?.state?.dateFrom}
          </Grid>
          <Grid item xs={3}>
            {"إلى تاريخ"}
          </Grid>
          <Grid item xs={3}>
            {loc?.state?.dateTo}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container style={{ direction: "rtl" }}>
        <Grid item xs={3}>
          {"المستخدم"}
        </Grid>
        <Grid item xs={3}>
          {sto.usernames}
        </Grid>
        <Grid item xs={3}>
          {"تاريخ الطباعة"}
        </Grid>
        <Grid item xs={3}>
          {loc?.state?.nDate}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default HeaderA;
