import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import { useStylesHed, useStylesSpDial } from "../../../../St/comps/UseStyle";
import { useTranslation } from "react-i18next";
import LoadingPage from "../../../../services/LoadingPage";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import { useAPI } from "../../../../api/useAPI";
import i18next from "i18next";
import { titleOptions } from "../../../../services/settings-services";
import PdfSettingsA4 from "./invoice-pdf-settings-a4";
import ChipsSelect from "../../../../components/chips-select";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100%',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(10),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  avatar: {
    backgroundColor: colors.grey[800],
    color: "#fff",
    height: 46,
    width: 46,
    marginTop: -5,
  },
  icon: {
    height: 32,
    width: 32,
  },
}));

export function InvoiceSettingA4(props) {
  const { data, fetching, selectedId, setSelectedId, fetchURL } = props;
  const classes = useStyles();
  const clashd = useStylesHed();
  const [viewType, setViewType] = useState("default");
  const { t } = useTranslation(["forms", "translation"]);
  const { updateFun } = useAPI("SettInvsApp");
  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    () => {},
    updateFun,
    () => {},
    undefined,
    { ...data },
    null,
    null,
    "edit"
  );

  // useEffect(() => {
  //   console.log("oboboobobbobob", object);
  // });

  const handleUpdate = () => {
    let _Data = {
      ...object,
      // sasInvHeadr: headerHeight,
      colmProductDescE: JSON.stringify(object.ProductName),
      colmQtyE: JSON.stringify(object.qty),
      colmDiscountE: JSON.stringify(object.discount),
      colmUnitE: JSON.stringify(object.unit),
      colmVatValueE: JSON.stringify(object.tax),
      colmUnitPriceE: JSON.stringify(object.price),
      colors: JSON.stringify(object?.colors),
      cloudInvoiceSettings: JSON.stringify(object?.cloudInvoiceSettings),
    };

    console.log("the data is", _Data);
    update(_Data).then((dd) => {
      fetchURL();
    });
  };
  return (
    <Grid container justifyContent="center" spacing={0}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Container
          component={Paper}
          maxWidth={true}
          style={{
            // padding: "5px",
            borderBottomWidth: "0px",
            borderRightColor: colors.teal[100],
            borderRightWidth: "0px",
            borderRightStyle: "solid",
          }}
        >
          <ChipsSelect
            items={titleOptions}
            selectedId={selectedId}
            onSelect={(x) => {
              setSelectedId(x.id);
              // etsGData({ ...gData, Form_id: x.Form_id, id: x.id });
              // console.log(x);
            }}
          />
        </Container>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Container
          component={Paper}
          maxWidth={true}
          style={{
            padding: "20px",
            border: "0px",
            borderColor: colors.teal[100],
            borderTopWidth: i18next.language == "ar" ? "5px" : "5px",
            borderLeftWidth: i18next.language == "ar" ? "0px" : "5px",
            borderStyle: "solid",
          }}
        >
          <PdfSettingsA4
            {...{
              object,
              onChange,
              handleUpdate,
              index: selectedId,
            }}
          />
        </Container>
      </Grid>
      <Grid item container lg={12} md={12} sm={12} sx={12}>
        <Container
          maxWidth={true}
          style={{
            backgroundColor: colors.grey[200],
            padding: 10,
            // marginBottom: 80,
          }}
        >
          <Grid className={classes.GridButton} item xs={12}>
            <Button
              className={classes.buttons}
              style={{ width: "100%" }}
              variant="contained"
              color="primary"
              fontSize="large"
              size="medium"
              disabled={submitting}
              onClick={handleUpdate}
              // startIcon={<SaveIcon />}
            >
              حـفـظ التعديلات
            </Button>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default function InvoiceA4SettingsCon() {
  const [selectedId, setSelectedId] = useState(1);

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url: uri + `SettInvsApp`,
      mapFunction: (data) => {
        localStorage.setItem("billSTGs", JSON.stringify(data));
        return {
          ...data,
          ProductName: JSON.parse(data.colmProductDescE),
          unit: JSON.parse(data.colmUnitE),
          price: JSON.parse(data.colmUnitPriceE),
          discount: JSON.parse(data.colmDiscountE),
          tax: JSON.parse(data.colmVatValueE),
          qty: JSON.parse(data.colmQtyE),
          colors: JSON.parse(data?.colors),
          cloudInvoiceSettings: JSON.parse(data?.cloudInvoiceSettings),
        };
      },
      dependencyArray: [],
    },
  });
  useEffect(() => {
    console.log("the globel data is", data);
  });
  if (fetching || !data) return <LoadingPage />;
  return (
    <InvoiceSettingA4
      fetching={fetching}
      data={data}
      fetchURL={fetchURL}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
    />
  );
}
