import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import SaleAnalysis from "./saleAnalysis";
import LoadingPage from "../../../../../services/LoadingPage";
const defaultAutos = {
  Safes: [],
};

export default function PurchAnalysisCon() {
  const [branch, setBranch] = useState({ id: -1, name: "الكل" });
  const tb = {
    wait: true,
    Safes: true,
    Customers: true,
    Users: true,
    Stocks: true,
    salesmen: true,
    Branches: true,
    groups: true,
    stor: { ...sto, branchi: branch.id == -1 ? sto.branchi : branch.id },
    parms: { safe: -1 },
  };
  const url = uri + "Auto" + "/custTypeM";
  const { data: CustomerOptions, fetching: fetchCust } = useFetchURL({
    ...{ url, dependencyArray: [] },
  });
  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
    [branch]
  );

  if (
    fetchCust ||
    fetchAll ||
    !AutoOptions?.Safes ||
    CustomerOptions?.length == 0
  )
    return <LoadingPage />;

  return (
    <SaleAnalysis
      {...{
        AutoOptions: AutoOptions?.Safes ? AutoOptions : defaultAutos,
        CustomerOptions: CustomerOptions?.length > 0 ? CustomerOptions : [],
        branch,
        setBranch,
        isPurches: true,
      }}
    />
  );
}
