import { makeStyles } from '@material-ui/core/styles';
import { insh } from '../../Context/InshSt';

const useStylesMain = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      marginTop:'5rem',
      direction:'rtl',
      backgroundColor: '#fafafa',
      padding: theme.spacing(5),
      // marginLeft: theme.spacing(2),
      // padding: theme.spacing(),
      // paddingLeft: theme.spacing(20),
      // background:'#fafafa'
      borderRadius: '2rem',
      // boxShadow: 'rgba(0, 0, 0, 0.12) 0px 4px 16px',
      boxShadow: '0 0 50px rgb(129 0 127 / 8%), 0 26px 25px rgb(0 128 1 / 0.06)',
      // boxShadow: '0px 0px 28px rgba(0, 0, 0, 0.80), 0 -10px 20px -5px #7b272c',
    },

    [theme.breakpoints.only('xs')]: {
      marginTop:'1rem',
      marginBottom:'4rem',
      direction:'rtl',
      backgroundColor: '#fafafa',
      padding: theme.spacing(2),
      // marginLeft: theme.spacing(2),
      // padding: theme.spacing(),
      // paddingLeft: theme.spacing(20),
      // background:'#fafafa'
      borderRadius: '1rem',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 4px 16px',
      // boxShadow: 'rgba(0, 0, 0, 0.12) 0 0 50px',
    },

  },
}));

const useStylesMenusCont = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.only('lg')]: {
      // marginTop:'1rem',
      // direction:'rtl',
      // padding: theme.spacing(2),
      // marginLeft: theme.spacing(2),
      // padding: theme.spacing(),
      // paddingLeft: theme.spacing(20),
      // background:'#fafafa'
      // boxShadow: 'rgba(0, 0, 0, 0.12) 0px 4px 16px'
    },
  },
}));

const useStylesTF = makeStyles((theme) => ({
  root: { "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color:insh.colors.primary, fontSize:'0.9rem', },
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": { color:insh.colors.primary, fontSize:'1rem',
   lineHeight: '2rem',}, "& .MuiOutlinedInput-inputMarginDense": 
  { paddingLeft:4,paddingRight:4, paddingTop:15,paddingBottom:15, textAlign:'center', fontSize: '1.4rem' }},

    input: {
      color: insh.colors.secndry, 
      "& .MuiOutlinedInput-notchedOutline": { borderColor: insh.colors.primary },
      "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: insh.colors.primary },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: insh.colors.primary },
    //   '& input[type=number]': { '-moz-appearance': 'textfield' },
    '& input[type=number]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0 },
    '& input[type=number]::-webkit-inner-spin-button': { '-webkit-appearance': 'none',  margin: 0}
    },

}));

// const useStylesTF = makeStyles((theme) => ({

//   root: { "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color:insh.colors.primary, fontSize:'0.8rem', },
//     "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": { color:insh.colors.primary, fontSize:'0.7rem',
//      lineHeight: '0.5rem',}, "& .MuiOutlinedInput-inputMarginDense": 
//     { paddingLeft:4,paddingRight:4, paddingTop:8,paddingBottom:7, textAlign:'center', fontSize: '1rem' }},

//   input: {
//     '&   input[type="date"]::-webkit-calendar-picker-indicator': {
//        color: 'red',
//       // opacity: 1,
//       // display: 'block',
//      // background: ' no-repeat',
//       // width: '20px',
//       // height: '20px',
//       // borderWidth: 'thin',
//       // border:'solid 1px red',
//       color:insh.colors.primary, padding:0, margin:0
//   },
      
//   '& input[type=date]' :{
//     color: insh.colors.primary
// },
// },

// lable:{
//   color: insh.colors.primary
// }
// //   '& input[type="date"]::-webkit-calendar-picker-indicator': {
// //     '-webkit-appearance': 'none',
// //     display: 'none',
// //     border:'slid'
// // },

// }));

const useStylesBT = makeStyles((theme) => ({
  // root: {

  // },
  link:{
   height:40,
   color:insh.colors.secndry,
   backgroundImage: 'linear-gradient(to right, #4e9e8414 , #2688ba14)',
  }
}));


const useStylesHed = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign:'center'
  },
  logo:{
   height:80,
   width:100,
  },
  title:{
    border: 'solid 2px',
    borderRadius: '5px',
    padding: '0.5rem',
  },
  titleM:{
    margin: '2rem 2rem 0rem 2rem',
  },
  titleS:{
    direction:'rtl',
    margin: '1rem 2rem 1rem 2rem',
  },
  titLin1:{
    margin: '0% 15% 0% 15%',
  },
  titLin2:{
    margin: '1% 5% 0% 5%',
  },
  titLin3:{
    margin: '1% 5% 0% 5%',
  },
  tlR:{
    textAlign:'right'
  },
  body:{
    // margin: '0% 15% 0% 15%',
    // border: 'solid 2px',
    borderRadius: '2px',
    padding: '0.5rem',
    direction:'rtl',
  },
  tblHd:{
    // tblHed
    border: 'solid 2px',
  },
  tbhd:{
    // tblHed
    // border: 'solid 2px',
    // boxShadow:'none',
    // maxWidth:'100%',
    // display:'flex'
  },
  
}));
export { useStylesMain,useStylesMenusCont,useStylesTF,
  useStylesBT,useStylesHed
}

/* 4 screan  */  



// [theme.breakpoints.up('md')]: {
//   background:'red',
// },
// [theme.breakpoints.down('sm')]: {
//   background:insh.colors.primary,
// },
// [theme.breakpoints.down('xs')]: {
//   background:'brown',
// },

