import { makeStyles } from '@material-ui/core/styles';
import { insh } from '../../../../Context/InshSt';

export const useStyles = makeStyles((theme) => ({
    main:{
      padding: theme.spacing(1),
      paddingBottom:'20px'
    },
    appbar2:{
      margin: theme.spacing(0,1),
      justifyContent:'center'
  
    },
    textField: {
     marginBottom:7,
     textAlign: '-webkit-center',
    },
    textField2: {
      marginTop:5,
      textAlign: '-webkit-center',   
    },
    buttons: {
      margin: theme.spacing(1),
      marginBottom: 25,
      textAlign: '-webkit-center',
    },
    root: {
      direction: 'rtl',
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
      border: 'solid 1px #009688',
  
    },
    root22: {
      direction: 'rtl',
      marginBottom:70,
      padding: theme.spacing(1),
      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
      border: 'solid 1px #009688',

    },
    notess: {
      marginRight: 20,
      marginTop: 12,
      margin: theme.spacing(1),
  
    },
    GridButton: {
      margin: theme.spacing(1),
      // direction:'rtl',
  
    },
   
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      top: 'auto',
      bottom:0,
      backgroundColor: '#808080',
    },
    rootCaedL: {
      // maxWidth: 500,
      // maxHeight: 600,
      // boxShadow:'0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
      boxShadow:'none', 
      height: '600px',
      paddingTop: '100px',
      textAlign: 'center'
      // borderRadius: '0.5rem',
    }, 
    logo: {
      // padding: '2rem 0rem',
      // margin: theme.spacing(5),
      // marginTop: theme.spacing(8),
      margin: 'auto',
      width:'100%',
      height:'400px',
      backgroundSize: 'cover',
      // border:'solid red 0.1px'
    },
    buttonss:{
      backgroundColor: '#808080  ',
    },
    grow: {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
    fabButton: {
      position: 'absolute',
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },


    rootup:{
    // border:'solid 0.1px',
    // marginTop:'-1rem',
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(0.5),
    },
  },
  upsub:{
    // border:'solid 0.1px red',
    borderRadius:5,
    padding: theme.spacing(1),
    // margin: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
       marginLeft:'0rem',marginRight:'0rem'
    },
  },
  upsubR:{
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
    0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
    // alignItems: 'center',
  },
  upsubL:{

    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
    0px 0px 1.5px 0.5px ${insh.colors.secndry}`,

    padding: theme.spacing(2),
  },
 
  rootdown:{
    // border:'solid 0.1px',
    // marginTop:'-1rem',
    [theme.breakpoints.only('lg')]: {
      // padding: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
      // padding: theme.spacing(0.5),
    },
    [theme.breakpoints.only('sm')]: {
      marginBottom:'3.5rem',
      // padding: theme.spacing(0.5),
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom:'3.5rem',
      // padding: theme.spacing(0.5),
    },
  },

  downsub:{
    borderRadius:5,
    // margin: theme.spacing(1),
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1), 
      marginLeft:'0rem',marginRight:'0rem',
    },
    // border:'solid 0.1px',
  },
  downsubR:{
    boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
    0px 0px 1.5px 0.5px ${insh.colors.secndry}`,
  },
  
  }));