import React from 'react';
import MaterialTable from "material-table";
import {cellS,headerS,} from '../../../../St/comps/cStyle'

export default function TrunItemsAvgTable(props){

    const {data} = props;

 var columns = [
    {title: "رقم الصنف", field: "currency_from",},
    {title: "الصنف", field: "Name_Item",},
    {title: "المبلغ الوارد", field: "Income",},
    {title: "المبلغ الصادر", field: "Outcome",},
    {title: "الرصيد الان", field: "Balance",},
    {title: "معدل الركود", field: "Avg_rece",},
    {title: "معدل الدوران", field: "Avg_rouran",},
  ];


    return(
        <MaterialTable style={{boxShadow:'none'}}
              options={{ 
              pageSize: 7,
              toolbar: false,
              sorting: false,
              showTitle: false,
              paging: false,
              headerStyle: headerS,
              cellStyle: cellS,
              maxBodyHeight: '70vh',
              minBodyHeight: '70vh',

             }}
       
             localization={{
               
              body:{
                emptyDataSourceMessage:<h3 
                style={{ marginTop:'20vh', border:'solid 0px', boxShadow:'none'}}
                ></h3> } }}
             columns={columns}
             data={data}            
             onRowClick={(event, rowData) => {
              event.stopPropagation();
            }}/>
    )
}