import React, { useState, useEffect } from "react";
import axios from "axios";
import { uri } from "../../help/Api";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import Swipeable from "../../Fronts/Main/Swipeable";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Slider from "@material-ui/core/Slider";
import { useStylesSetting } from "../../St/comps/UseStyle";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FieldsTableNameA4Invoice } from "../../services/settings-services";
import { Description } from "@material-ui/icons";
import LineStyleIcon from "@material-ui/icons/LineStyle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  subTitle: {
    direction: "rtl",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  divider: {
    margin: theme.spacing(1, 4, 2, 4),
  },
}));

let hed = null,
  foot = null;
let heddef = null,
  footdef = null;

export default function SettingsPrinter() {
  const clasStt = useStylesSetting();

  const classes = useStyles();
  const [auth, setAuth] = React.useState(false);
  const [authdis, setAuthdis] = React.useState(false);
  const [authValExclVat, setAuthValExclVat] = React.useState(false);
  const [autclomNam, setAutclomNam] = React.useState(false);
  // for tarweesa
  const [autsaloruser, setAutSaloruser] = React.useState(false);
  const [autdir, setAutDir] = React.useState(false);

  // const open = Boolean(anchorEl);
  const loctabColNam = JSON.parse(localStorage.getItem("tabColNam")) || {};

  // for hed foot
  const [papehedfoot, setPapehedfoot] = React.useState({ hed: "", foot: "" });
  const [papehed, setPapehed] = React.useState();

  // const [state, setState] = useState({ curr: null });

  const [values, setValues] = useState({
    id: 1,
    dsplyColmDiscount: null,
    dsplyColmExclVAT: null,
    dsplyColmSerialnumb: null,
    dsplyHeadFootr: null,
    dsplyHedrSalesOrUser: null,
    dsplyHedrdir: null,
    /* A AF */
    colmNoA: null,
    colmProductDescA: null,
    colmUnitA: null,
    colmQtyA: null,
    colmUnitPriceA: null,
    colmAmountA: null,
    colmDiscountA: null,
    colmValueExclVatA: null,
    colmVatValueA: null,
    colmValueIncludeVatA: null,
    /* E AF */
    colmNoE: null,
    colmProductDescE: null,
    colmUnitE: null,
    colmQtyE: null,
    colmUnitPriceE: null,
    colmAmountE: null,
    colmDiscountE: null,
    colmValueExclVatE: null,
    colmVatValueE: null,
    colmValueIncludeVatE: null,
    /* sum */
    sumcolmDiscount: null,
    sumcolmNetAmount: null,
    sumcolmTax: null,
    sumcolmTotal: null,

    sasInvHeadr: 0,
    sasInvFootr: 0,

    amountWithVatA: null,
    amountWithVatE: null,
    totdiscountA: null,
    totdiscountE: null,
    amountWithVat2A: null,
    amountWithVat2E: null,
    amountOfVatA: null,
    amountOfVatE: null,
    tot_netA: null,
    tot_netE: null,

    showColDHd: null,
    ncol: null,
    cloudInvoiceSettings: null,
    colors: null,
    /* A Casher */
    titleFatoraA: null,
    /* E Casher */
    titleFatoraE: null,
  });

  useEffect(() => {
    axios
      .get(uri + `SettInvsApp`)
      .then((res) => {
        setValues({
          id: 1,
          dsplyColmDiscount: res.data.dsplyColmDiscount,
          dsplyColmExclVAT: res.data.dsplyColmExclVAT,
          dsplyColmSerialnumb: res.data.dsplyColmSerialnumb,
          dsplyHeadFootr: res.data.dsplyHeadFootr,
          dsplyHedrSalesOrUser: res.data.dsplyHedrSalesOrUser,
          dsplyHedrdir: res.data.dsplyHedrdir,

          colmNoA: res.data.colmNoA,
          colmProductDescA: res.data.colmProductDescA,
          colmUnitA: res.data.colmUnitA,
          colmQtyA: res.data.colmQtyA,
          colmUnitPriceA: res.data.colmUnitPriceA,
          colmAmountA: res.data.colmAmountA,
          colmDiscountA: res.data.colmDiscountA,
          colmValueExclVatA: res.data.colmValueExclVatA,
          colmVatValueA: res.data.colmVatValueA,
          colmValueIncludeVatA: res.data.colmValueIncludeVatA,

          colmNoE: res.data.colmNoE,
          colmProductDescE: res.data.colmProductDescE,
          colmUnitE: res.data.colmUnitE,
          colmQtyE: res.data.colmQtyE,
          colmUnitPriceE: res.data.colmUnitPriceE,
          colmAmountE: res.data.colmAmountE,
          colmDiscountE: res.data.colmDiscountE,
          colmValueExclVatE: res.data.colmValueExclVatE,
          colmVatValueE: res.data.colmVatValueE,
          colmValueIncludeVatE: res.data.colmValueIncludeVatE,
          // tot
          sumcolmTax: res.data.sumcolmTax,
          sumcolmDiscount: res.data.sumcolmDiscount,
          sumcolmNetAmount: res.data.sumcolmNetAmount,
          sumcolmTotal: res.data.sumcolmTotal,

          sasInvHeadr: res.data.sasInvHeadr,
          sasInvFootr: res.data.sasInvFootr,

          amountWithVatA: res.data.amountWithVatA,
          amountWithVatE: res.data.amountWithVatE,
          totdiscountA: res.data.totdiscountA,
          totdiscountE: res.data.totdiscountE,
          amountWithVat2A: res.data.amountWithVat2A,
          amountWithVat2E: res.data.amountWithVat2E,
          amountOfVatA: res.data.amountOfVatA,
          amountOfVatE: res.data.amountOfVatE,
          tot_netA: res.data.tot_netA,
          tot_netE: res.data.tot_netE,
          showColDHd: res.data.showColDHd,
          ncol: res.data.ncol,

          titleFatoraA: res.data.titleFatoraA,
          titleFatoraE: res.data.titleFatoraE,
          cloudInvoiceSettings: res.data?.cloudInvoiceSettings,
          colors: res?.data?.colors,
        });
        setObjectComp({
          ProductName: JSON.parse(res.data.colmProductDescE),
          unit: JSON.parse(res.data.colmUnitE),
          price: JSON.parse(res.data.colmUnitPriceE),
          discount: JSON.parse(res.data.colmDiscountE),
          tax: JSON.parse(res.data.colmVatValueE),
          qty: JSON.parse(res.data.colmQtyE),
        });
      })
      .catch((error) => {});

    // alert(heddef+' - '+values.sasInvHeadr)

    const locSett = JSON.parse(localStorage.getItem("Sett")) || {};
    // const locSett = JSON.parse(localStorage.getItem('Sett')) || {};

    setAuth(locSett.paperHedFotr);
    setAuthdis(locSett.aithdis);
    setAuthValExclVat(locSett.aithValExclVat);
    setAutclomNam(locSett.colmNam);

    // for tarwessaa
    const loctarwesa = JSON.parse(localStorage.getItem("tarweesa")) || {};
    setAutSaloruser(loctarwesa.salesOrUser);
    setAutDir(loctarwesa.dir);
  }, []);

  const handleChangeaith = (event) => {
    // setAuth(event.target.checked);
    // shm
    setValues({ ...values, dsplyHeadFootr: event.target.checked });
    values.dsplyHeadFootr = event.target.checked;

    goStor();
  };

  const handleChangeaithDis = (event) => {
    // setAuthdis(event.target.checked);
    setValues({ ...values, dsplyColmDiscount: event.target.checked });
    values.dsplyColmDiscount = event.target.checked;
    goStor();
  };

  const handleChangeaithValExclVat = (event) => {
    // setAuthValExclVat(event.target.checked);

    setValues({ ...values, dsplyColmExclVAT: event.target.checked });
    values.dsplyColmExclVAT = event.target.checked;

    goStor();
  };

  const handleChangecolmName = (event) => {
    // setAutclomNam(event.target.checked);

    setValues({ ...values, dsplyColmSerialnumb: event.target.checked });
    values.dsplyColmSerialnumb = event.target.checked;
    goStor();
  };

  // for Tarwesaa
  const handleChangeSalesOrUser = (event) => {
    // setAutSaloruser(event.target.checked);

    setValues({ ...values, dsplyHedrSalesOrUser: event.target.checked });
    values.dsplyHedrSalesOrUser = event.target.checked;

    goStor();
  };

  const handleChangeDir = (event) => {
    // setAutDir(event.target.checked);
    setValues({ ...values, dsplyHedrdir: event.target.checked });
    values.dsplyHedrdir = event.target.checked;
    goStor();
  };

  const handleChange = (prop) => (event) => {
    if (prop == "showColDHd") {
      if (event.target.checked == true)
        setValues({ ...values, [prop]: event.target.checked, ncol: 6 });
      else setValues({ ...values, [prop]: event.target.checked, ncol: 4 });
    } else setValues({ ...values, [prop]: event.target.value });
  };

  const [objectComp, setObjectComp] = useState({
    ProductName: {
      text: "",
      fieldName: "symbol",
      visible: true,
    },
    unit: {
      text: "",
      fieldName: "unit_name",
      visible: true,
    },
    qty: {
      text: "",
      fieldName: "val1",
      visible: true,
    },
    discount: {
      text: "",
      fieldName: "discount",
      visible: true,
    },
    tax: {
      text: "",
      fieldName: "taxperc",
      visible: true,
    },
    price: {
      text: "",
      fieldName: "new_exchange_price",
      visible: true,
    },
  });

  const handleChangeObject = (prop) => (event) => {
    setObjectComp({
      ...objectComp,
      [prop]: {
        ...objectComp[prop],
        text: event.target.value,
      },
    });
  };

  const handleChangeVisible = (prop) => (event) => {
    setObjectComp({
      ...objectComp,
      [prop]: {
        ...objectComp[prop],
        visible: event.target.checked,
      },
    });
  };

  const goStor = async () => {
    let newValues = {
      ...values,
      colmProductDescE: JSON.stringify(objectComp.ProductName),
      colmQtyE: JSON.stringify(objectComp.qty),
      colmDiscountE: JSON.stringify(objectComp.discount),
      colmUnitE: JSON.stringify(objectComp.unit),
      colmVatValueE: JSON.stringify(objectComp.tax),
      colmUnitPriceE: JSON.stringify(objectComp.price),
    };
    await axios
      .put(uri + "SettInvsApp", newValues)
      .then((res) => {})
      .catch((error) => {});

    const serializ = JSON.stringify({
      paperhed: values.sasInvHeadr,
      paperfoot: values.sasInvFootr,
    });
    localStorage.setItem("faHedFoot", serializ);
    localStorage.setItem("billSTGs", JSON.stringify(values));
  };

  function valuetexthed(value) {
    hed = value;
    values.sasInvHeadr = value;
    return `${value}°C`;
  }

  function valuetextfoot(value) {
    foot = value;
    return `${value}°C`;
  }

  // for column name
  const locfaHedFoot = JSON.parse(localStorage.getItem("faHedFoot")) || {};
  heddef = locfaHedFoot.paperhed;
  footdef = locfaHedFoot.paperfoot;

  return (
    <div className={classes.root}>
      <Grid item container style={{ textAlign: "center", padding: "2%" }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">إعدادات فاتورة A4</FormLabel>
          <Grid item ex={12} style={{ height: "65px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.dsplyHeadFootr}
                    onChange={handleChangeaith}
                    name="gilad"
                  />
                }
                label={
                  values.dsplyHeadFootr
                    ? " إخفاء رأس وذيل الفاتورة قيد التشغيل"
                    : " إخفاء رأس وذيل الفاتورة قيد الإيقاف"
                }
              />
            </FormGroup>
            {values.dsplyHeadFootr && (
              <FormHelperText>
                {"يمكنك الأن طباعة الفاتورة على ورقك الرسيمة"}
              </FormHelperText>
            )}
          </Grid>

          <Grid item ex={12} style={{ height: "65px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.dsplyColmDiscount}
                    onChange={handleChangeaithDis}
                    name="gilad"
                  />
                }
                label={
                  values.dsplyColmDiscount
                    ? "عمود الخصم في الجدول في حالة إخفاء"
                    : "عمود الخصم في الجدول في حالة ظهور"
                }
              />
            </FormGroup>
            {values.dsplyColmDiscount && (
              <FormHelperText>
                {"أصبح عمود الخصم مخفيـًا حالياً"}
              </FormHelperText>
            )}
          </Grid>

          <Grid item ex={12} style={{ height: "65px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.dsplyColmExclVAT}
                    onChange={handleChangeaithValExclVat}
                    name="gilad"
                  />
                }
                label={
                  values.dsplyColmExclVAT
                    ? "عمود القيمة بلا ظريبة في الجدول في حالة إخفاء"
                    : "عمود القيمة بلا ظريبة في الجدول في حالة ظهور"
                }
              />
            </FormGroup>
            {values.dsplyColmExclVAT && (
              <FormHelperText>
                {"أصبح عمود القيمة بلا ظريبة مخفيـًا حالياً"}
              </FormHelperText>
            )}
          </Grid>

          <Grid item ex={12} style={{ height: "65px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.dsplyColmSerialnumb}
                    onChange={handleChangecolmName}
                    name="gilad"
                  />
                }
                label={values.dsplyColmSerialnumb ? "رقم الصنف" : "رقم متسلسل"}
              />
            </FormGroup>
            {values.dsplyColmSerialnumb && (
              <FormHelperText>{"أصبح عمود الصنف معتمد"}</FormHelperText>
            )}
          </Grid>

          <div>اعدادات الترويسة</div>
          <Grid item ex={12} style={{ height: "65px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.dsplyHedrdir}
                    onChange={handleChangeDir}
                    name="gilad"
                  />
                }
                label={"طريقة عرض الترويسة"}
              />
            </FormGroup>
            {values.dsplyHedrdir && (
              <FormHelperText>
                {"اصبح عرض الترويسة من اليسار إلى اليمين"}
              </FormHelperText>
            )}
          </Grid>

          <Grid item ex={12} style={{ height: "65px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.dsplyHedrSalesOrUser}
                    onChange={handleChangeSalesOrUser}
                    name="gilad"
                  />
                }
                label={"عرض إسم المستخدم أو المندوب"}
              />
            </FormGroup>
            {values.dsplyHedrSalesOrUser && (
              <FormHelperText>{"يتم حالياً عرض المندوب"}</FormHelperText>
            )}
          </Grid>
        </FormControl>
      </Grid>

      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={goStor}
        >
          <Typography className={classes.heading}>الترويسة</Typography>
        </AccordionSummary>

        <AccordionDetails>
        <Grid item container spacing={1} >


         </Grid>

<List className={classes.root}>
      <ListItem>
        <ListItemIcon>
          <WifiIcon />
        </ListItemIcon>
        <span style={{display:'block'}}>
        <ListItemText id="switch-list-label-wifi" primary="Wi-Fi" />
        <ListItemText style={{}} secondary="July 20, 2014" />
        </span>
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={handleToggle('wifi')}
            checked={checked.indexOf('wifi') !== -1}
            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
          />

        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BluetoothIcon />
        </ListItemIcon>
        <span style={{display:'block'}}>
        <ListItemText id="switch-list-label-bluetooth" primary="Bluetooth" />
        <ListItemText style={{}} secondary="July 20, 2014" />
        </span>
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={handleToggle('bluetooth')}
            checked={checked.indexOf('bluetooth') !== -1}
            inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>

        </AccordionDetails>


    
      </Accordion>
 */}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={goStor}
          style={{ background: "#d9dcf078" }}
        >
          <Typography className={classes.heading}>فاتورة كاشير</Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.subTitle}>
          {" "}
          <Typography> عنوان الفاتورة </Typography>{" "}
        </AccordionDetails>

        <AccordionDetails>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="عنوان الفاتورة"
                id="titleFatoraA"
                name="titleFatoraA"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.titleFatoraA}
                onChange={handleChange("titleFatoraA")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Title Fatora"
                id="titleFatoraE"
                name="titleFatoraA"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.titleFatoraE}
                onChange={handleChange("titleFatoraE")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>

        <Divider className={classes.divider} />

        <AccordionDetails className={classes.subTitle}>
          {" "}
          <Typography> إجماليات الفاتورة </Typography>{" "}
        </AccordionDetails>

        <AccordionDetails>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="الإجمالي"
                id="amountWithVatA"
                name="amountWithVatA"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.amountWithVatA}
                onChange={handleChange("amountWithVatA")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="مجموع الخصومات"
                id="totdiscountA"
                name="totdiscountA"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.totdiscountA}
                onChange={handleChange("totdiscountA")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="لإجمالي الخاضع للضريبة"
                id="amountWithVat2A"
                name="amountWithVat2A"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.amountWithVat2A}
                onChange={handleChange("amountWithVat2A")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="مجموع ضريبة القيمة المضافة"
                id="amountOfVatA"
                name="amountOfVatA"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.amountOfVatA}
                onChange={handleChange("amountOfVatA")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="إجمالي المبلغ المستحق"
                id="tot_netA"
                name="tot_netA"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.tot_netA}
                onChange={handleChange("tot_netA")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>

        <Divider className={classes.divider} />

        <AccordionDetails>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="Total"
                id="amountWithVatE"
                name="amountWithVatE"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.amountWithVatE}
                onChange={handleChange("amountWithVatE")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Discount"
                id="totdiscountE"
                name="totdiscountE"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.totdiscountE}
                onChange={handleChange("totdiscountE")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Total Taxable Amount"
                id="amountWithVat2E"
                name="amountWithVat2E"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.amountWithVat2E}
                onChange={handleChange("amountWithVat2E")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Total VAT"
                id="amountOfVatE"
                name="amountOfVatE"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.amountOfVatE}
                onChange={handleChange("amountOfVatE")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Total Amount Due"
                id="tot_netE"
                name="tot_netE"
                fullWidth
                defaultValue=""
                variant="outlined"
                size="small"
                value={values.tot_netE}
                onChange={handleChange("tot_netE")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>

        <Divider className={classes.divider} />

        <AccordionDetails>
          <Grid container md={12} className={clasStt.rootSett}>
            <List
              subheader={
                <ListSubheader className={clasStt.titleSett}>
                  ظهور وإخفاء التفاصيل
                </ListSubheader>
              }
              className={clasStt.rootSett}
            >
              <ListItem>
                <ListItemIcon>
                  <LineStyleIcon />
                </ListItemIcon>
                {values.showColDHd === true ? (
                  <ListItemText
                    id="switch-list-label-wi"
                    className={clasStt.itemText}
                    secondary="تم الإخفاء"
                    primary="ظهور وإخفاء عمود اللغة الانجليزية"
                  />
                ) : (
                  <ListItemText
                    id="switch-list-label-w"
                    className={clasStt.itemText}
                    secondary="قم بالتشغيل لإخفاء العمود"
                    primary="ظهور وإخفاء عمود اللغة الانجليزية"
                  />
                )}
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={handleChange("showColDHd")}
                    checked={values.showColDHd}
                    inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
                    name="showColDHd"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Grid>
        </AccordionDetails>

        <Divider className={classes.divider} />
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={goStor}
          style={{ background: "#d9dcf078" }}
        >
          <Typography className={classes.heading}>أسماء الأعمدة</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid item container spacing={1}>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="المنتج عربي"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmProductDescA}
                onChange={handleChange("colmProductDescA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="المنتج En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={objectComp.ProductName.text}
                onChange={handleChangeObject("ProductNameProductName")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={objectComp.ProductName.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  setObjectComp({
                    ...objectComp,
                    ProductName: {
                      ...objectComp.ProductName,
                      fieldName: newValue,
                    },
                  });
                  // changAutos("group_id", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("ProductName")}
                checked={objectComp.ProductName?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الوحدة"
                id="outlined-size-small3"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmUnitA}
                onChange={handleChange("colmUnitA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="Unit En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={objectComp.unit.text}
                onChange={handleChangeObject("unit")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={objectComp.unit?.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  setObjectComp({
                    ...objectComp,
                    unit: {
                      ...objectComp.unit,
                      fieldName: newValue,
                    },
                  });
                  // changAutos("group_id", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("unit")}
                checked={objectComp.unit?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الكمية"
                id="outlined-size-small4"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmQtyA}
                onChange={handleChange("colmQtyA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الكمية En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={objectComp.qty.text}
                onChange={handleChangeObject("qty")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={objectComp.qty?.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  setObjectComp({
                    ...objectComp,
                    qty: {
                      ...objectComp.qty,
                      fieldName: newValue,
                    },
                  });
                  // changAutos("group_id", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("qty")}
                checked={objectComp.qty?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="السعر"
                id="outlined-size-small5"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmUnitPriceA}
                onChange={handleChange("colmUnitPriceA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="السعر En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={objectComp.price.text}
                onChange={handleChangeObject("price")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={objectComp.price.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  setObjectComp({
                    ...objectComp,
                    price: {
                      ...objectComp.price,
                      fieldName: newValue,
                    },
                  });
                  // changAutos("group_id", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("price")}
                checked={objectComp.price?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الخصم"
                id="outlined-size-small6"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmDiscountA}
                onChange={handleChange("colmDiscountA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الخصم En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={objectComp.discount.text}
                onChange={handleChangeObject("discount")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={objectComp.discount.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  setObjectComp({
                    ...objectComp,
                    discount: {
                      ...objectComp.discount,
                      fieldName: newValue,
                    },
                  });
                  // changAutos("group_id", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("discount")}
                checked={objectComp.discount?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="قيمة الضريبة"
                id="outlined-size-small8"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmVatValueA}
                onChange={handleChange("colmVatValueA")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <TextField
                label="الضريبة En"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={objectComp.tax.text}
                onChange={handleChangeObject("tax")}
              />{" "}
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Autocomplete
                value={objectComp.tax.fieldName}
                labelPlacement=""
                onChange={(event, newValue) => {
                  setObjectComp({
                    ...objectComp,
                    tax: {
                      ...objectComp.tax,
                      fieldName: newValue,
                    },
                  });
                  // changAutos("group_id", newValue);
                }}
                id="controllable-states-demo"
                size="small"
                options={FieldsTableNameA4Invoice}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                // style={{  }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"قيمة الحقل"}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ className: claslbl.clrSale }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                edge="end"
                onChange={handleChangeVisible("tax")}
                checked={objectComp.tax?.visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </Grid>
            <Grid item xs={6}>
              {" "}
              <TextField
                label="المبلغ"
                id="outlined-size-small5"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmAmountA}
                onChange={handleChange("colmAmountA")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="القيمة بلا ضريبة"
                id="outlined-size-small7"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmValueExclVatA}
                onChange={handleChange("colmValueExclVatA")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="قيمة الضريبة"
                id="outlined-size-small8"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmVatValueA}
                onChange={handleChange("colmVatValueA")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="القيمة بالضريبة"
                id="outlined-size-small9"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmValueIncludeVatA}
                onChange={handleChange("colmValueIncludeVatA")}
              />{" "}
            </Grid>
            <Grid item xs={6}>
              {" "}
              <TextField
                label="الرقم"
                id="outlined-size-small1"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmNoA}
                onChange={handleChange("colmNoA")}
              />{" "}
            </Grid>
          </Grid>
        </AccordionDetails>

        <Divider className={classes.divider} />

        <AccordionDetails>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              {" "}
              <TextField
                label="No"
                id="outlined-size-small1"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmNoE}
                onChange={handleChange("colmNoE")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              {/* <TextField
                label="Product Description"
                id="outlined-size-small2"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmProductDescE}
                onChange={handleChange("colmProductDescE")}
              />{" "} */}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="Unit"
                id="outlined-size-small3"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmUnitE}
                onChange={handleChange("colmUnitE")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="Qty"
                id="outlined-size-small4"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmQtyE}
                onChange={handleChange("colmQtyE")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="Unit Price"
                id="outlined-size-small5"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmUnitPriceE}
                onChange={handleChange("colmUnitPriceE")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="Amount"
                id="outlined-size-small5"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmAmountE}
                onChange={handleChange("colmAmountE")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="Discount"
                id="outlined-size-small6"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmDiscountE}
                onChange={handleChange("colmDiscountE")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="Value Excl VAT"
                id="outlined-size-small7"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmValueExclVatE}
                onChange={handleChange("colmValueExclVatE")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="VAT Value"
                id="outlined-size-small8"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmVatValueE}
                onChange={handleChange("colmVatValueE")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="Value Include VAT"
                id="outlined-size-small9"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.colmValueIncludeVatE}
                onChange={handleChange("colmValueIncludeVatE")}
              />{" "}
            </Grid>
          </Grid>
        </AccordionDetails>

        <Divider className={classes.divider} />

        <AccordionDetails>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              {" "}
              <TextField
                label="Total المجموع"
                id="outlined-size-small5"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.sumcolmTotal}
                onChange={handleChange("sumcolmTotal")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="Discount الخصم"
                id="outlined-size-small6"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.sumcolmDiscount}
                onChange={handleChange("sumcolmDiscount")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="Tax الضريبة"
                id="outlined-size-small7"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.sumcolmTax}
                onChange={handleChange("sumcolmTax")}
              />{" "}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <TextField
                label="Net Amount الإجمالي"
                id="outlined-size-small8"
                fullWidth
                defaultValue="Small"
                variant="outlined"
                size="small"
                value={values.sumcolmNetAmount}
                onChange={handleChange("sumcolmNetAmount")}
              />{" "}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={true} style={{ marginBottom: "20%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          onClick={goStor}
          style={{ background: "#d9dcf078" }}
        >
          <Typography className={classes.heading}>
            إرتفاع رأس وذيل الفاتورة
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ width: "100%", display: "block" }}>
          <Typography id="discrete-slider" style={{ width: "100%" }}>
            رأس الفاتورة
            <Slider
              // sasInvHeadr: res.data.sasInvHeadr,
              // sasInvFootr: res.data.sasInvFootr
              // heddef=values.sasInvHeadr;
              // footdef=values.sasInvFootr;
              defaultValue={heddef}
              // value={values.sasInvHeadr}
              getAriaValueText={valuetexthed}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={5}
              marks
              min={10}
              max={200}
            />
          </Typography>
          {/* 
      <Typography id="discrete-slider" style={{width:'100%',}}>
        ذيل الفاتورة
        <Slider 
        defaultValue={footdef} 
        // value={values.sasInvFootr}
        getAriaValueText={valuetextfoot}
        aria-labelledby="discrete-slider" valueLabelDisplay="auto"
        step={5} marks min={50} max={150} />
      </Typography> */}
        </AccordionDetails>
        <Typography component="div">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={goStor}
            // disabled={submitting}
            // style={{...style.iconBtn,...stElemnt.all.iconBtn}} //disabled={true}
            // onClick={() => { setChecked({...checked,autoOrStaticck:true}); handleDia(TransitionUp,'fav',true)}}
          >
            {" "}
            {"حفظ التعديلات"}
          </Button>
        </Typography>
      </Accordion>
      <div></div>
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion> */}

      <Swipeable />
    </div>
  );
}
