import "date-fns";
import React, { useEffect, useState } from "react";
import { colors, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  useStylesAuto,
  useStylesLbl,
  useStylesTF,
} from "../../St/comps/UseStyle";
import { useTranslation } from "react-i18next";

export default function AccountSelect(props) {
  const { accounts, onSelect, account } = props;

  const [barcode, setBarcode] = useState(account?.id);
  const { t } = useTranslation(["forms"]);
  useEffect(() => {
    setBarcode(account?.id);
  }, [account]);

  const clasTF = useStylesTF();
  const clasAo = useStylesAuto();
  const claslbl = useStylesLbl();
  const handleSearch = (e) => {
    setBarcode(e.target.value);

    const result = accounts?.filter((x) => x.id == e.target.value);
    if (result.length > 0) {
      onSelect(result[0]);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="accno"
            label={t("account-code")}
            className={clasTF.rootSale}
            style={{ backgroundColor: colors.grey[50] }}
            InputProps={{ className: clasTF.inputSale }}
            name="accno"
            value={barcode}
            InputLabelProps={{ shrink: true }}
            onChange={handleSearch}
          />
        </Grid>

        <Grid item lg={8} md={8} sm={8} xs={8}>
          <Autocomplete
            classes={clasAo}
            value={account}
            labelPlacement=""
            onChange={(event, newValue) => {
              setBarcode(newValue.id);
              onSelect(newValue);
            }}
            id="controllable-states-demo"
            size="small"
            options={accounts}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            style={{ backgroundColor: colors.grey[50] }}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clrSale }}
              />
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
