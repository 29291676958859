import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { insh as inshst } from '../../../Context/InshSt';
import DocumetDialog from '../../Accounts/BackEnd/receipt-voucher/components/DocumentDialog';
import { Button, IconButton } from '@material-ui/core';
import { Pageview, Visibility } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
      table: {
        minWidth: 650,
      },
}));

export default function PaymentTransactionRPTTable(props){

  // const [docOpen ,setDecOpen] = useState(false);
    const {data, fetchDataClick,title} = props;
    const [docData, setDocData]= useState({docType:'', id:-1, docOpen: false});

    const onClose =async(reloaing = false)=>{
      setDocData({...docData, docOpen: false});
      if(reloaing)
        await fetchDataClick();
    }
    const classes = useStyles();
    return(
      <TableContainer component={Paper} >
       <Table className={classes.table} size="small" aria-label="a dense table">
         <TableHead>
           <TableRow style={{backgroundColor: inshst.colors.cust, color:'#fff'}}>
             <TableCell style={{color:'#fff'}} align="center">رقم الحساب </TableCell>
             <TableCell style={{color:'#fff'}} align="center">{title}</TableCell>
             <TableCell style={{color:'#fff'}} align="center">الهاتف</TableCell>
             <TableCell style={{color:'#fff'}} align="center">الجوال </TableCell>
             <TableCell style={{color:'#fff'}} align="center">قيمة اخر سداد </TableCell>
             <TableCell style={{color:'#fff'}} align="center">تاريخ اخر سداد </TableCell>
             <TableCell style={{color:'#fff'}} align="center">نوع المستند </TableCell>
             <TableCell style={{color:'#fff'}} align="center">الرصيد </TableCell>
             <TableCell style={{color:'#fff'}} align="center">الحالة</TableCell>
             <TableCell  align="center">عـــرض</TableCell>
           </TableRow>
         </TableHead>
         <TableBody>
           {data?.map((row) => (
             <TableRow key={row.id}>
               <TableCell align="center">{row?.acc_no}</TableCell>
               <TableCell align="center">{row?.name}</TableCell>
               <TableCell align="center">{row?.tel}</TableCell>
               <TableCell align="center">{row?.mobile}</TableCell>
               <TableCell align="center">{row?.AmtVale?.toFixed(2)}</TableCell>
               <TableCell align="center">{row?.Doc_date}</TableCell>

               <TableCell align="center">{row?.docName}</TableCell>
               <TableCell align="center">{row?.Blance?.toFixed(2)}</TableCell>
               <TableCell align="center">{row?.CaseBlance}</TableCell>
               <TableCell align="center">
                  <IconButton edge="start" color="inherit" aria-label="open drawer" 
                    disabled={row?.Doc_Type < 5 || row?.Doc_Type > 8 } 
                    onClick={()=>{
                      if(row?.Doc_Type >= 5 && row?.Doc_Type <= 8)
                       setDocData({
                        docType:row?.Doc_Type, 
                        id:row?.doc_no, 
                        docOpen: true,
                       })}}
                      >
                    <Visibility style={{color: `${(row?.Doc_Type >= 5 && row?.Doc_Type <= 8)? '#81007f': '#eee'}`}} />
                  </IconButton>
                {/* <Button
                    disabled={row?.Doc_Type < 5 || row?.Doc_Type > 8 } 
                    // style={{backgroundColor: '#81007f',}}
                    // type={"text"}
                    color={"secondary"}
                    onClick={()=>{
                      if(row?.Doc_Type >= 5 && row?.Doc_Type <= 8)
                       setDocData({
                        docType:row?.Doc_Type, 
                        id:row?.doc_no, 
                        docOpen: true,
                       })

                    }}>
                    {'عـــرض'}
                     
                    </Button> */}
                  </TableCell>
             </TableRow>
           ))}
         </TableBody>
       </Table>
       {
        docData.docOpen && 
          <DocumetDialog {...{...docData, onClose}}/>
       }
     </TableContainer>
    )
}