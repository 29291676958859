import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@material-ui/lab';
import {useStylesTF,useStylesTD} from '../../../../St/comps/UseStyle';
import { useEffect } from 'react';
const userType = [
  {id:'1', name:'مدير النظام'},
  {id:'2', name:'مستخدم'}
];

export default function UsersForm(props){
    const { 
      object,
      data,
      onChange,
      onShow
      } = props;
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    useEffect(()=>{
      console.log('dad', object)
    })
    const onChangeData = (prop) => (event) =>{

      onChange(event, {name: prop, value: event.target.value});
    } 

    return(
        <React.Fragment>
          <Grid item lg={2} md={2} sm={4} xs={6}>
            <Autocomplete // classes={clasAo}
              value={object}
              onChange={(event, newValue) => { 
                  onShow({...newValue, 
                    nName: newValue.name, nPassword:''}, props.formType);
              }}
              id="controllable-states-dem" size="small"
              options={data}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              //style={{  }}  // disabled={}
              disabled={object.type=='main'}
              disableClearable
              renderInput={(params) => <TextField id="valuePricePay" {...params} label={"الموظف"} 
              variant="outlined" size="small" 
                    />}
                  />
        </Grid> 
        <Grid item lg={2} md={2} sm={4} xs={6}>
            <Autocomplete // classes={clasAo}
              value={object?.Usertype == 1? userType[0]: userType[1]}
              onChange={(event, newValue) => { 
                  onChange(null, {name:'Usertype', value: newValue.id});
              }}
              id="controllable-states-dem" size="small"
              options={userType}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              //style={{  }}  // disabled={}
              // disabled={object.type=='main'}
              disableClearable
              renderInput={(params) => <TextField id="valuePricePay" {...params} label={"نوع المستخدم"} 
              variant="outlined" size="small" 
                    />}
                  />
        </Grid> 
        <Grid item lg={3} md={3} sm={4} xs={6}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"nName"} label={"اسم المستخدم "}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"nName"} 
            value={object.nName} 
            onChange={(e)=>onChange(null, {name:'nName', value: e.target.value})}
            />
        </Grid> 
       
        <Grid item lg={3} md={3} sm={4} xs={6}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"nPassword"} label={"كلمة المرور"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"nPassword"} 
            value={object.nPassword} 
            onChange={onChangeData('nPassword')}
            />
        </Grid> 
        <Grid item lg={2} md={2} sm={4} xs={6}>
            <Autocomplete // classes={clasAo}
              value={object.safe}
              onChange={(event, newValue) => { 
                  onChange(null, {name:'safe', value: newValue});
              }}
              id="controllable-safe-dem" size="small"
              options={object.SafeEmp}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              //style={{  }}  // disabled={}
              disableClearable
              renderInput={(params) => <TextField id="valuePricePay" {...params} label={"المخزن الإفتراضي"} 
              variant="outlined" size="small" 
                    />}
                  />
        </Grid> 
        <Grid item lg={2} md={2} sm={4} xs={6}>
            <Autocomplete // classes={clasAo}
              value={object.stock}
              onChange={(event, newValue) => { 
                  onChange(null, {name:'stock', value: newValue});
              }}
              id="controllable-safe-dem" size="small"
              options={object.StockEmp}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              //style={{  }}  // disabled={}
              disabled={object.type=='main'}
              disableClearable
              renderInput={(params) => <TextField id="valuePricePay" {...params} label={"الصندوق الإفتراضي"} 
              variant="outlined" size="small" 
                    />}
                  />
        </Grid> 
      </React.Fragment>
    )
    
}