import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

export default function SalesEmpTable(props) {
    const { data, isPurches } = props;


    const stylH = {
        // borderWidth: 1,
        border: "solid 0.6px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 14,
        color: "#fff"
    }
    const stylRo = {
        BorderBottom: "solid 0.5px",
        //borderStyle: "solid",
        borderColor: "gray",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 14,
        color: "#000"
    }

    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    return (
        <TableContainer component={Paper} >
            <Table
                //className={classes.table} 
                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: isPurches ? colors.blue[300] : colors.green[300],
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                            border: "solid 0.6px"
                        }}
                    >
                        <TableCell style={stylH} align="center">
                            {"No"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {" نوع الحركة"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {" التاريخ"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"رقم الفاتورة"}
                        </TableCell>
                       
                        <TableCell style={stylH} align="center">
                            {"الصنف"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الكمية"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"السعر"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الضريبه"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الاجمالي"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, i) => (
                        <TableRow key={row.id}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                fontWeight: 800,
                                fontFamily: "Amiri-Regular",
                                fontSize: 14,
                                border: "solid 0.6px"
                            }}>
                            <TableCell style={stylRo} align="center">{i + 1}</TableCell>
                            <TableCell style={stylRo} align="center">{row.Doc_Name}</TableCell>
                            <TableCell style={stylRo} align="center">{row.date}</TableCell>
                            <TableCell style={stylRo} align="center">{row.id}</TableCell>
                            <TableCell style={stylRo} align="center">{row.symbol}</TableCell>
                            <TableCell style={stylRo} align="center">{row.val}</TableCell>
                            <TableCell style={stylRo} align="center">{row.exchange_price}</TableCell>
                            <TableCell style={stylRo} align="center">{row.taxval}</TableCell>
                            <TableCell style={stylRo} align="center">{row.sum_Price}</TableCell>
                            <TableCell style={stylRo} align="center"></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
