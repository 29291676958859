import { CardActions, CircularProgress, Grid, ImageList, ImageListItem, ImageListItemBar } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { CardActionArea, Button } from '@material-ui/core';
import theminable from '../../../../assets/img/thimanabl.png';
import { green } from "@material-ui/core/colors";
import BackdropSc from "../../../../services/BackdropSc";


function ProductCard(props) {
  const {item, onClick}= props;
  return (
    <Card sx={{ maxWidth: 345, }} color="primary" style={{padding:'1px',
        backgroundColor: green[500],
        color:'#fff',
        direction:'rtl' }}
        onClick={()=>onClick(item.id, item.units)}>
      <CardActionArea>
        {
          item.img!=null ? 
          <CardMedia
          component="img"
          height="100"
          src={`data:image/jpeg;base64,${item.img}`}
          alt="green iguana"
        />:
        <CardMedia
          component="img"
          height="100"
          image={theminable}
          alt="green iguana"
        />
        }
        
        <CardContent style={{paddingTop:'2px',
              minHeight: 55,}}>
          <Typography variant="subtitle2" component="div">
            {item.symbol}
          </Typography>
          <Typography style={{color:'black'}} variant="subtitle2" component="div">
            {item.nameEN}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
const ItemsCard=(props)=>{
 
      const {
        loading,
        BefrowDataCurrencie,
        cuRows,
    } = props;

    return (
         <div style={{padding:'8px'}}>
              <Grid container spacing={1}>
              {
                
                  cuRows.map((item,i) => (
                    <Grid item lg={3} md={3} sm={3} xs={6}>
                        <ProductCard 
                                item={item}
                                onClick={BefrowDataCurrencie}/>
                    </Grid>
                
                ))
              }
                </Grid>
         </div>
    )
}

export default ItemsCard;