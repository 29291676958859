import React from 'react'

// import DateFnsUtils from "@date-io/date-fns";

export default function DatePicker(props) {

    // const { name, label, value, onChange } = props


    // const convertToDefEventPara = (name, value) => ({
    //     target: {
    //         name, value
    //     }
    // })

    return (
        // <MuiPickers UtilsProvider utils={DateFnsUtils}>
        //     <KeyboardDatePicker disableToolbar variant="inline" inputVariant="outlined"
        //         label={label}
        //         format="MMM/dd/yyyy"
        //         name={name}
        //         value={value}
        //         onChange={date =>onChange(convertToDefEventPara(name,date))}

        //     />
        // </MuiPickers UtilsProvider>
        <div>
            
        </div>
    )
}
