import React from "react";
import { uri } from "../../../help/Api";
import { useEffect } from "react";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import { convertToTreeData } from "../../../services/permissions-services";
import LoadingPage from "../../../services/LoadingPage";
import UserPermissions from "./user-permissions";

export default function UsersPermissionsCont() {
  const {
    data: Formsdata,
    fetching,
    fetchURL,
  } = useFetchURL({
    ...{
      url: uri + `UserPermission/getForms`,
      mapFunction: convertToTreeData,
      dependencyArray: [],
    },
  });
  const {
    data,
    fetching: fff,
    fetchURL: furl,
  } = useFetchURL({
    ...{
      url: uri + "Users",
    },
  });

  useEffect(() => {
    console.log("the data is", Formsdata);
  });
  if (fetching || !Formsdata || fff || !data || !data[0])
    return <LoadingPage />;
  return (
    <UserPermissions users={data} data={Formsdata ?? []} defUser={data[0]} />
  );
}
