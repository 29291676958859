import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogNotes({ DialogOpen,DialogClose,content }){
// export default function DialogNotes(props) {
 
  const clickDialog = (state) => {
    DialogClose(state);
  };


  return (
 
    <Dialog
        // open={dialogNots.state}
        open={DialogOpen}
        // onClose={()=>props.clickDialogNots(true,'txt')}
        onClose={()=>clickDialog(false)}

        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'md'}

        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"
        style={{textAlign: 'center'}} >{"رسالة توضيحية"}</DialogTitle>
        <DialogContent style={{textAlign: 'right'}}>
          <DialogContentText id="alert-dialog-slide-description" 
           style={{direction:'rtl'}} >
           {/* {'قم بكتابة الـ ip بشكل صحيح'} */}
           {/* {dialogNots.msg} */}
           {/* {content.sub} */}
           {content.sub}
           
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>clickDialog(false)} color="primary">
          
          {content.txtBtn}  
          </Button>
        </DialogActions>
      </Dialog>
  );

}