import axios from "axios";
import { uri } from "../help/Api";

export function useAPI(subURL) {
  const URL = uri + subURL;
  function list() {
    return axios.get(URL);
  }
  function getNewId() {
    return axios.get(URL + "/newId");
  }
  function createFun(data) {
    return axios.post(URL, data);
  }

  function updateFun(data) {
    // console.log("the data is", data)
    return axios.put(URL, data);
  }
  function removeFun(id) {
    return axios.delete(URL + `/${id}`);
  }

  function GetRow(data){
    return axios.get(URL+`?branch=${data.branch}
        &&id=${data?.id}
        &&gettingType=${data.gettingType}`)
  }

  return { URL, getNewId, list, createFun, updateFun, removeFun, GetRow};
}
