import axios from "axios";
import { useState, useEffect } from "react";

export default function useFetchURL(props){

    const {url} = props;

    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    
    async function fetchURL(){
        setFetching(true);

            await axios.get(url).then(res=>{
                if(props.mapFunction)
                    setData(props.mapFunction(res.data))
                else
                    setData(res.data);

            }).catch(error=>{
                console.log(error);
            })
            setFetching(false);

    }

    useEffect(()=>{
        fetchURL()
        return()=>{}
    }, props.dependencyArray || [])

    return {data, fetching, fetchURL}
}