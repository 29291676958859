import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ReplyIcon from "@material-ui/icons/Reply";
import { uri } from "../../../../help/Api";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import CssBaseline from "@material-ui/core/CssBaseline";

import {
  useStylesHed,
  useStylesBA,
  useStylesUp,
} from "../../../../St/comps/UseStyle";

import MaterialTable from "material-table";
import { Mtb } from "../../../../St/comps/vstyle";

import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { useEffect } from "react";
import { cellS, headerS, stElemnt } from "../../../../St/comps/cStyle";
import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function StoreExchangeSearchModal(props) {
  const { open, onClose, onRowClick, type } = props;
  const { t } = useTranslation("forms");
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url: uri + `TwreedSF?type=${type}`,
      dependencyArray: [open],
    },
  });

  useEffect(() => {
    fetchURL();
  }, []);
  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();

  var columns = [
    { title: " الأمر", field: "id", hidden: true },
    { title: "رقم الأمر", field: "no", headerStyle: headerS, cellStyle: cellS },
    { title: "التاريخ", field: "date", headerStyle: headerS, cellStyle: cellS },
    {
      title: "المخزن ",
      field: "safe_name",
      headerStyle: headerS,
      cellStyle: cellS,
    },
    { title: "البيان", field: "notes", headerStyle: headerS, cellStyle: cellS },
  ];

  // const clasTF = useStylesTF();
  // const clasTD = useStylesTD();
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <div className={clasBA.continarBASer}>
        <React.Fragment>
          <Grid item container spacing={0} className={clashd.rootHd}>
            <Grid item xs={12}>
              <Chip
                size="small"
                className={clashd.ChipHdSale}
                label={"بـحـث  "}
                color="primary"
              />
            </Grid>
          </Grid>

          <CssBaseline />
          <Container maxWidth="xl" className={clasup.AboverootupSer}>
            {/* S front H */}
            <Grid item container className={clasup.rootup}>
              <Grid item md={12} sm={12} xs={12}>
                {fetching ? (
                  <LinearProgress />
                ) : (
                  <MaterialTable
                    style={{ boxShadow: "none" }}
                    //style={{direction:'ltr'}}
                    options={{
                      showTitle: false,
                      toolbar: false,
                      sorting: false,
                      selection: false,
                      // ToolBar Search
                      searchFieldAlignment: "left",
                      searchFieldVariant: "outlined",
                      // ToolBar header
                      showSelectAllCheckbox: false,
                      showTextRowsSelected: false,
                      pageSize: 5,
                      paginationType: "stepped",
                      showFirstLastPageButtons: false,

                      paging: false,

                      maxBodyHeight: Mtb.minmaxBHInvs,
                      minBodyHeight: Mtb.minmaxBHInvs,
                    }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <h3 style={{ color: "#000" }}>لا يوجد بيانات بعد</h3>
                        ),
                      },
                      toolbar: { searchPlaceholder: "بـحـث" },
                    }}
                    columns={columns}
                    data={data}
                    onRowClick={async (event, rowData) => {
                      onRowClick(rowData);
                      event.stopPropagation();
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <br /> <br />
          </Container>
        </React.Fragment>
      </div>

      {/* ====================================== end ======================================== */}
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="primary"
          // className={clasBA.appBarBASal}
          style={{ ...stElemnt.all.appBarBA }}
        >
          <Toolbar variant="dense">
            <Hidden smUp={["sm"]}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={onClose}
              >
                <ReplyIcon />
              </IconButton>
            </Hidden>
            <Hidden only={["xs"]}>
              <Button
                style={{ color: "#fff" }}
                onClick={onClose}
                startIcon={<ReplyIcon />}
              >
                {t("close")}
              </Button>
            </Hidden>

            <div className={clasBA.growBA} />
          </Toolbar>
        </AppBar>
      </React.Fragment>
    </Dialog>
  );
}
