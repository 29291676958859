import React from "react";
import { AppBar, Button, CircularProgress, CssBaseline, Fab,  Hidden, IconButton,  Toolbar, } from '@material-ui/core';
import {  useStylesBA, useStylesCAB, } from "../../../../../St/comps/UseStyle";
import toast, { Toaster } from "react-hot-toast";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import { Fragment } from "react";
import {  PrepareDataWithOmla, ReFormatingData } from "../../../../../services/multi-receipt-services";
import { useState } from "react";
import { stElemnt } from "../../../../../St/comps/cStyle";
import moment from "moment";
import { useHistory } from "react-router-dom";
import MultiSearchModal from "../components/multi-search-modal";
import { useTranslation } from "react-i18next";
import { sto } from "../../../../../Context/InshData";
import PagenationButtons from "../../../../../components/controls/PagenationButons";

export default function MReceiptVoucherAppBar(props) {
const {
        submit,
        isReceipt,
        isDialog,
        onClose,
        prmis,
        update,
        defaultV,
        submitting, 
        onShow,
        object,
        URL,
        formType,
        getRow,
        AutoOptions,
    } = props;
    const {t} = useTranslation(['forms']);
    let his = useHistory();
    const [openSearchMdl, setOpenSearchMdl] = useState(false);
  const clasBA = useStylesBA();
  const clasCAB = useStylesCAB();
    const styBtn = {
    dis:{color:'#3b773d',background:'#4fa152'},
    undis:{color:'#fff'},
    }

    const newEReceipt=()=>{
        onShow(defaultV, 'new');
    }
    const addChangds = () => {
      object.date =
        moment(object.date).format("YYYY-MM-DD") +
        moment(new Date()).format(" h:mm A");
    }
  const onSubmitHandle =async (isPrint)=>{
    addChangds();
    let nData = PrepareDataWithOmla(object, isReceipt);
    console.log("n data is", nData);

    if(object.rows?.length ==0 )
    {
      toast.error("يجب إضافة بيانات الى الجدول")
    }
    else{
      if(formType == "edit"){
          if(isPrint)
              handlePrint(nData, object);
          else
            await update(nData).then((res)=>{
            })
        }
        else{
          await submit(nData).then((res)=>{
            if(isPrint)
                handlePrint(res.data, object);
          });
        }  
    }

    
}

const handlePrint = (rptdata, oldObject) => {
  const info = {
  data:{...rptdata,
  rows: oldObject.rows},
  title: isReceipt? 'سند قبض متعدد' : 'سند صرف متعدد',
  stockName: oldObject?.type =='1' ?oldObject.stock.name: oldObject.bank.name,
  Date:moment(rptdata.date).format('YYYY-MM-DD'),
  ElmntShow:true,
  }
  localStorage.setItem('iInfos',  JSON.stringify(info)); 
  his.push({ pathname: '/MulVoucher',  state: info,});
  window.location.reload(false);
}

const onRowClick =(row)=>{
    let FormatedData = ReFormatingData(row, AutoOptions, isReceipt);
    onShow(FormatedData, 'edit');
    setOpenSearchMdl(false);
}

const onGetRow = (type) => async (e)=>{
  await getRow({branch: sto.branchi,
    id: object.id,
    type:type
  }).then(res=>{
    if(res !=null){
      onRowClick(res?.data);
    }
    console.log("the resposent is ", res)
  });
  
}
  const backHome = () => {
    if(isDialog)
      onClose();
    else{
      his.push({ pathname: '/', });
      window.location.reload(false);
    }
  };

  
  return (
    <React.Fragment>
      <Toaster />
    <CssBaseline />
    <AppBar position="fixed"
    className={clasBA.appBarBASal}
     style={{backgroundColor:'#808080', }}
    >
      <Toolbar  className={clasBA.ToolbarBA}
       variant="dense"
      >
      
      <Hidden smUp={['sm']}>
        <IconButton edge="start" color="inherit" aria-label="backH"
         onClick={backHome}
         >
          <CloseIcon />
        </IconButton>
        <IconButton edge="start" color="inherit" aria-label="searsh" 
        onClick={() => setOpenSearchMdl(true)}
        disabled={prmis?.Se}
        >
          <SearchIcon />
        </IconButton>
        <IconButton edge="start" color="inherit" aria-label="handNew" 
          onClick={newEReceipt} 
        >
          <AddBoxIcon />
        </IconButton>
      </Hidden>
      
      <Hidden only={['xs']}>
        <Button 
        style={styBtn.undis} 
        onClick={backHome} 
        startIcon={<CloseIcon />} >{t('exit')}</Button>
        <Button 
        style={styBtn.undis } 
        disabled={prmis?.Se}
        onClick={() => setOpenSearchMdl(true)} 
        startIcon={<SearchIcon />}>{t('search')}</Button>
        <Button 
        style={styBtn.undis} 
        onClick={newEReceipt} 
        startIcon={<AddBoxIcon />}>{t('new')}</Button>
      </Hidden>
      
        <div className={clasBA.growBA} />
    
  <Hidden smUp={['sm']}>

      
    {/* {object.formType=='edit' &&
        <div className={clasCAB.wrapperCAB}>
      <Fab size="medium" color="primary" disabled
        style={{...stElemnt.all.iconBtn, backgroundColor:'#808080'}} 
        className={clasCAB.buttonSuccessCAB}
        // onClick={deleteTos} 
        >
         <DeleteIcon />
        </Fab>
       
    {submitting && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
    </div>
    } */}

    <div className={clasCAB.wrapperCAB}>
      <Fab size="medium" color="primary"
        style={{...stElemnt.all.iconBtn, backgroundColor:'#808080'}} 
        className={clasCAB.buttonSuccessCAB}
        disabled={submitting || (formType == "new"? (prmis?.Sa || prmis?.Pr): prmis?.Pr)} 
        onClick={()=>{onSubmitHandle(true)}}
        ><PrintIcon />
        </Fab>
        {submitting && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
    </div>

    <div className={clasCAB.wrapperCAB}>
    <Fab size="medium" color="primary" 
        style={{...stElemnt.all.iconBtn, backgroundColor:'#808080'}} 
        className={clasCAB.buttonSuccessCAB}
        disabled={submitting || (formType == "new"? prmis?.Sa: prmis?.Up)}
        onClick={()=>onSubmitHandle(false)}
        > <SaveIcon />
      </Fab>
      
    {submitting && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
    </div>

    </Hidden>
    
    <Hidden only={['xs']}>
        
      
      {formType == "edit" && (
              <>
               <div className={clasCAB.wrapperCAB}>
                <PagenationButtons
                  variant="contained"
                  color="#fff"
                  size="large"
                  className={clasCAB.buttonSuccessCAB}                  
                  style={{
                    ...stElemnt.all.iconBtn,
                    backgroundColor: "#808080",
                  }}
                  disabled={submitting}
                  onClick={onGetRow}

                  />

                {submitting && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
            </div>
              {/* <div className={clasCAB.wrapperCAB}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={clasCAB.buttonSuccessCAB}
                  startIcon={<Delete />}
                  style={{
                    ...stElemnt.all.iconBtn,
                    backgroundColor: "#808080",
                  }}
                  disabled={submitting || prmis.De}
                  onClick={onDeleteHandle}
                >
                  حـذف
                </Button>

                {submitting && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div> */}
             
            </>
            )}

    <Fragment>
      <div className={clasCAB.wrapperCAB}>  
        <Button variant="contained" color="primary" size="large"
           className={clasCAB.buttonSuccessCAB} 
           startIcon={<PrintIcon />}
           style={{...stElemnt.all.iconBtn, backgroundColor:'#808080'}}
           disabled={submitting || (formType == "new"? (prmis?.Sa || prmis?.Pr): prmis?.Pr)} 
           onClick={() => onSubmitHandle(true)}
            // onClick={() => or.postput == 'toPost' ? toPrint('print','e',1) : toPosh(sdata)}
           > { formType == 'edit'? t('print'):t('save-print')}</Button> 
      </div>
      </Fragment>
    <div className={clasCAB.wrapperCAB}>
      <Button variant="contained" color="primary" size="large"
        style={{...stElemnt.all.iconBtn, backgroundColor:'#808080'}}
        className={clasCAB.buttonSuccessCAB} 
        disabled={submitting || (formType == "new"? prmis?.Sa: prmis?.Up)}
        onClick={()=>onSubmitHandle(false)} 
        startIcon={<SaveIcon />}>{ formType == 'edit'? t('save-update'):t('save')}</Button>
      
    </div>

    </Hidden>

      </Toolbar>
    </AppBar>
    <MultiSearchModal 
      URL={URL}
      isReceipt={isReceipt}
      open={openSearchMdl}
      onClose={()=>{setOpenSearchMdl(false)}} 
      onRowClick={onRowClick}/>
  </React.Fragment>
  );




};

