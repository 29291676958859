import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import { colors, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { uri } from "../../../../../help/Api";
import Print from "@material-ui/icons/Print";

const useStyles = makeStyles((theme) => ({
    table: {
        //  minWidth: 650,
    },
}));

export default function MultiCodeTable(props) {
    const { multiCodeList,
        onDelete,
        setIsNew,setMultiCodeList } = props;



    const stylH = {
        // borderWidth: 1,
        border: "solid 0.6px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 14,
        color: "#fff"
    }
    const stylRo = {
        BorderBottom: "solid 0.5px",
        //borderStyle: "solid",
        borderColor: "gray",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 12,
        color: "#000"
    }

    const [fetching, setFetching] = useState(false);
    const [rowsD, setRouwsD] = useState(multiCodeList);

    const [sum, setSum] = useState(false);
    // const onRowClick = async (rowData) => {
    //     setFetching(true);
    //     await axios
    //         .get(
    //             uri +
    //             `RptSafes/itemUnitSrearch?curr_id=${rowData.id}`
    //         )
    //         .then((res) => {
    //             setFetching(false);

    //             getItemsData(rowData,res.data);
    //         })
    //         .catch((err) => {
    //             setFetching(false);
    //         });
    // };
    const onRowDelete =async (rowdata) => {
        // setData({...row, id:row.tableData.id.toString()});
        var newData = multiCodeList.filter((obj) => obj.barcode.toString() !== rowdata.barcode.toString());
  
        setMultiCodeList([...newData]);
    }
    useEffect(() => {
        // console.log("units is", currUnitList)
    }, [multiCodeList]);

    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    return (
        <TableContainer component={Paper} >
            <Table
                //className={classes.table} 
                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: "#f18584",
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                            border: "solid 0.6px"
                        }}
                    >
                    
                        <TableCell style={stylH} align="center">
                            {"الباركود"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"Print"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"delete"}
                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {multiCodeList.map((row) => (
                        <TableRow key={row.barcode}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                fontWeight: 800,
                                fontFamily: "Amiri-Regular",
                                fontSize: 15,
                                border: "solid 0.6px"
                            }}>
                            <TableCell style={stylRo} align="right"  >{row.barcode}</TableCell>
                            <TableCell align="center" >
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    style={{ maxHeight: "0.7rem" }}
                                    onClick={onDelete}>
                                    <Print size={"small"} />
                                </IconButton>
                            </TableCell>
                            <TableCell align="center" >
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    style={{ maxHeight: "0.7rem" }}
                                    // onClick={onDelete}

                                onClick={() => {
                                    onRowDelete(row);
                                }}>
                                
                                    <DeleteIcon size={"small"} />
                                </IconButton>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
