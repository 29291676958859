import React, { useState,useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PrintIcon from '@material-ui/icons/Print';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import {useStylesSpDial} from '../../../../St/Reviews/UseStyle';

import axios from 'axios';
import {uri}  from '../../../../help/Api';
const api = axios.create({ baseURL:uri });

class ComponentToPrint extends React.Component {
  state = {  }

  constructor(){ super();
// const locInv = JSON.parse(localStorage.getItem('datainv')) || {};

// const tb = {GetFoundation:true,Rpt_Inv:true,
//   int1:locInv.proc_id,int2:locInv.branch,str1:locInv.ProcTypeInvProc,iFoundid:1,}
//   api.post('Res',tb).then( res => { console.log('res.data caaasher');
 // console.log(res.data) });
   }

  render() {
  const styleCont = { backgroundColor: 'red', width:'58mm',

};
    const styleTyg = { backgroundColor: '#FFF',padding:'1mm' };
    
    return (
      <Grid item container justifyContent='center'> 
      <Grid item style={styleCont}> 
      {/* <CssBaseline /> */}
        <Typography component="div" style={styleTyg}>
        <Header/>
        <Body/>
        <Footer/>
       </Typography>
      </Grid> 
      </Grid> 
    );
  }
}

const RevSandC = () => {
  const componentRef = useRef();

  const clasSp = useStylesSpDial();
  const [open, setOpen] = useState(true);
  const handleOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(true); };

  let his = useHistory();

  const back = (path) =>
  {
    path ? his.push({ pathname: '/'}) : his.goBack();

// his.push({ pathname: '/',
// // state: {idata:insh.IdataSaO,
// //   setting:{ 
// //titleHed:txtA.invName.sale,cusT:1,cusN:txtA.cusNA},
// // },
// });

  }

//   const back = () => his.push({ pathname: '/',

//   // state: {idata:insh.IdataSaO,
//   //   setting:{ 
//   //titleHed:txtA.invName.sale,cusT:1,cusN:txtA.cusNA},
//   // },
// });
  const actions = [
    { icon: <ReactToPrint trigger={() => <PrintIcon className={clasSp.ico} /> } 
    content={() => componentRef.current} />, name: 'طباعـة' },
    
    { icon: <ArrowForwardIcon className={clasSp.ico} onClick={()=> back(false)} />, name: 'رجـوع' },
  ];
  return (
    <div className={clasSp.root}>
      <ComponentToPrint ref={componentRef} />
      <SpeedDial ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial} hidden={false}
        icon={<SpeedDialIcon openIcon={<HomeIcon />} onClick={()=> back(true)}  />}
        onClose={handleClose} onOpen={handleOpen} open={open}>
        {actions.map((action,i) => ( <SpeedDialAction key={i}
         icon={action.icon} tooltipTitle={action.name} onClick={handleClose} />))}
      </SpeedDial>
    </div>
  );

};


export default RevSandC