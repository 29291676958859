import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Slide } from '@material-ui/core';
import Items from './Items';

// let prm = {Ne:nl,Sa:nl,De:nl,Se:nl,Pr:nl,Up:nl}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function ItemsDialog({ DialogOpened,DialogClose,locats }){

    const clickDialog = () => {
      DialogClose(false);
    };

return (
  <Dialog fullScreen open={DialogOpened} onClose={clickDialog} TransitionComponent={Transition}>
   <Items locat={locats} isDialog={true} DialogClose={DialogClose}/>
  </Dialog>

 );
 
}