import React, { useState, useEffect } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useInvAF, useInvMain } from "../../../St/Reviews/UseStyle";
import { sto } from "../../../Context/InshData";

function Header() {
  const clasm = useInvMain();
  const clas = useInvAF();
  // const clasH = useInvHeadrAF();

  const [info, setInfo] = useState({});
  const getInfo = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
      })
      .catch((error) => {});
  };
  const styf = { fontWeight: "500" };
  const styfC = { fontWeight: "500", color: "#9a3f47" };
  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid
      item
      container
      className={clas.Hedroot}
      style={{
        padding: 5,
        fontWeight: "bold",
        // position: "fixed",
        // top: "0mm",
        // // width: "100%",
        // height: "110px",
      }}
    >
      <Grid item xs={5} className={clasm.minleft}>
        <div style={styf}>{info.Disc1_header_en}</div>
        <div style={styf}>{info.Disc2_header_en}</div>
        <div style={styfC}>{info.Disc3_header_en}</div>{" "}
        <div style={styfC}>{info.Disc4_header_en}</div>
        <div style={styf}>{"Vat number " + info.tax_no}</div>
      </Grid>

      <Grid item xs={2} className={clasm.mincntr}>
        <div>
          <ButtonBase>
            {" "}
            <img
              src={`data:image/jpeg;base64,${info.Logo}`}
              className={clas.Hedlogo}
              alt={info.nameA}
            />{" "}
          </ButtonBase>
        </div>
      </Grid>

      <Grid item xs={5} className={clasm.minrit}>
        <div style={styf}>{info.Disc1_header_ar}</div>{" "}
        <div style={styf}>{info.Disc2_header_ar}</div>
        <div style={styfC}>{info.Disc3_header_ar}</div>{" "}
        <div style={styfC}>{info.Disc4_header_ar}</div>
        <div style={styf}>{"الرقم الضريبي " + info.tax_no}</div>
      </Grid>
    </Grid>
  );
}
export default Header;
