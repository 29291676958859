import React, { useState, useEffect } from "react";

import {
  Button,
  CardActionArea,
  CardContent,
  Chip,
  colors,
  Container,
  CssBaseline,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import SafeGrdForm from "./SafeGrd-form";
import SafeGrdTable from "./SafeGrd-table";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import TotalCard from "../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import { defaultGRDparms,typeQties,typeCosts } from "../../../../services/safe-grd-services";
import { sto } from "../../../../Context/InshData";
import axios from "axios";
import deepRenameKeys from "deep-rename-keys";
import { CSVLink } from "react-csv";
import { TableChart } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },

  root3: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    direction: "rtl",
    marginTop: 5,
    padding: 5,
    // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
  },
  headerTitle: {
    backgroundColor: "#fff",
    padding: 5,
    textAlign: "center",
    fontSize: 24,
    width: "100%",
  },
}));

export default function SafeGrd(props) {
  const { AutoOptions } = props;
  let idSafe=AutoOptions.Safes[0].id;
  let nameSafe=AutoOptions.Safes[0].name;

   const defaultGRDparmsEmp = {
    typeCost: typeCosts[0],
    safe: { id: sto.User_typei==1? -1: idSafe, name: sto.User_typei==1? "الكل":nameSafe },
    typeQtys: typeQties[0],
    isStore: true,
    isFactory: false,
    isRaw: false,
  };
  const [rptParams, setRptParams] = React.useState(defaultGRDparmsEmp);
 
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);
  const [data, setData] = useState();
  const [fetching, setFetching] = useState(false);

  
  //     },
  //     dependencyArray: [],
  //   },
  // });

  var columns = [
    { title: "رقم الباركود", field: "barcode" },
    { title: "الصنف", field: "Name_Item" },
    { title: "الرصيد", field: "Balance" },
    { title: rptParams.typeCost.name, field: "cost" },
    { title: "إجمالي التكلفة", field: "sumCost_item" },
    { title: "إجمالي الوارد", field: "Income" },
    { title: "إجمالي المباع", field: "Outcome" },
  ];
  const excelHeaders = deepRenameKeys(columns, (key) => {
    if (key === "field") return "key";
    if(key === "title") return "label";  
  });

  useEffect(() => {
   // getempsimpleparametrs();
    console.log("rabi eshrah li sadri ", excelHeaders);
    // console.log("dfdsf is ", rptParams);
  });
 
  const his = useHistory();

  const printHandle = () => {
    const info = {
      columns: columns,
      // // rout:viewPrms,
      title: "كشف جرد المخازن",
      data: data,
      auto: {
        safe: rptParams.safe.name,
        user: sto.usernames,
        crossTaqeem: rptParams.typeCost.name,
      },
      tot: {
        totSale: data
          ?.reduce((a, v) => (a = a + Number(v.Outcome)), 0)
          ?.toFixed(2),
        totTaqlfa: data
          ?.reduce((a, v) => (a = a + Number(v.sumCost_item)), 0)
          ?.toFixed(2),
        totval: data?.length,
      },
      // nDate: moment(new Date()).format("YYYY-MM-DD"),
      ElmntShow: false,
    };
    //console.log(info)
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/ReviewJard" });
    window.location.reload(false);
  };

  const handleView = async () => {
    setFetching(true);
    await axios
      .get(
        uri +
          `RptSafes/safe-grd?typeCost=${rptParams.typeCost.id}
  &&safe=${rptParams.safe.id}
  &&branch=${-1}
  &&typeQtys=${rptParams.typeQtys.id}
  &&isStore=${rptParams.isStore}
  &&isFactory=${rptParams.isFactory}
  &&isRaw=${rptParams.isRaw}`
      )
      .then((res) => {
        setData(
          res.data?.map((itm) => {
            const cost =
              itm.Balance != 0
                ? (itm?.sumCost_item / itm.Balance).toFixed(2)
                : 0;
            return {
              ...itm,
              cost: cost,
              sumCost_item: itm?.sumCost_item?.toFixed(2),
            };
          })
        );
      })
      .catch((err) => {
        console.log("error ", err);
      });
    setFetching(false);
  };
  const onChangeData = (event) => {
    setRptParams({ ...rptParams, [event.target.name]: event.target.checked });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <Grid container item xs={12}>
          <Typography
            size="small"
            className={classes.headerTitle}
            color="primary"
          >
            كشف جرد المخازن
          </Typography>
        </Grid>
        <Typography component="div" style={{ direction: "rtl" }}>
          <Container
            component={Paper}
            maxWidth={false}
            style={{
              padding: "10px",
              paddingBottom: "0px",
              borderBottomColor: colors.indigo[300],
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
            }}
          >
            <SafeGrdForm
              {...{
                AutoOptions,
                rptParams,
                onChangeData,
                onChangeAutoData,
                printHandle,
              }}
            />
            <Grid
              container
              justifyContent="center"
              lg={12}
              md={12}
              sm={12}
              xs={12}
              spacing={1}
            >
              <Grid item>
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<ScannerIcon />}
                  size="large"
                  style={{ ...inshst.sal.iconBtnRpt, borderRadius: 0 }}
                  className={[clasui.button]}
                  onClick={() => handleView()}
                >
                  {t("forms:view")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<PrintIcon />}
                  color="primary"
                  size="large"
                  className={[clasui.button, clasSp.clSec]}
                  style={{ ...inshst.sal.iconBtnRpt, borderRadius: 0 }}
                  onClick={printHandle}
                >
                  {t("forms:print")}
                </Button>
              </Grid>
              <Grid item>
              {data && !fetching &&(
                <CSVLink
                  separator={";"}
                  data={data}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    color="primary"
                    size="large"
                    className={[clasui.button, clasSp.clSec]}
                  >
                    {"تصدير اكسل"}{" "}
                  </Button>
                </CSVLink>
              )}
            </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<KeyboardReturnSharpIcon />}
                  color="secondary"
                  size="large"
                  className={[clasui.button, clasSp.clcan]}
                  style={{ ...inshst.sal.iconBtnRpt, borderRadius: 0 }}
                  onClick={() => {
                    his.push({ pathname: "/" });
                  }}
                >
                  {t("forms:exit")}
                </Button>
              </Grid>
            </Grid>
          </Container>

          <Container
            component={Paper}
            maxWidth={false}
            style={{
              padding: "10px",
            }}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <BeatLoader loading={fetching} color={colors.indigo[300]} />
                {!fetching && (
                  <SafeGrdTable
                    {...{
                      data: data ? data : [],
                      columns: columns,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        </Typography>
        <Grid className={classes.root3} item container>
          <Grid item lg={2} md={2} sm={2} xs={7}>
            <CardActionArea>
              <CardContent>
                <Typography color="textSecondary" component="p">
                  عدد الأصناف
                </Typography>
              </CardContent>
            </CardActionArea>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={5}
            className={classes.iitemS}
            // style={styTitle}
          >
            <CardActionArea>
              <CardContent>
                <Typography color="textSecondary" component="p">
                  {data?.length}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Grid>

          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={7}
            className={classes.iitemS}
            // style={styTitle}
          >
            <CardActionArea>
              <CardContent>
                <Typography color="textSecondary" component="p">
                  إجمالي التكلفة
                </Typography>
              </CardContent>
            </CardActionArea>
          </Grid>

          <Grid item lg={2} md={2} sm={2} xs={5} className={classes.iitemS}>
            {" "}
            <CardActionArea>
              <CardContent>
                <Typography color="textSecondary" component="p">
                  {!fetching &&
                    data &&
                    data
                      ?.reduce((a, v) => (a = a + Number(v.sumCost_item)), 0)
                      ?.toFixed(2)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Grid>

          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={7}
            className={classes.iitemS}
            // style={styTitle}
          >
            <CardActionArea>
              <CardContent>
                <Typography color="textSecondary" component="p">
                  الإجمالي البيع
                </Typography>
              </CardContent>
            </CardActionArea>
          </Grid>

          <Grid item lg={2} md={2} sm={2} xs={5} className={classes.iitemS}>
            {" "}
            <CardActionArea>
              <CardContent>
                <Typography color="textSecondary" component="p">
                  {!fetching &&
                    data &&
                    data
                      ?.reduce((a, v) => (a = a + Number(v.Outcome)), 0)
                      ?.toFixed(2)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
