import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { useTranslation } from "react-i18next";

export default function DRestForm(props) {
  const { onChange, object, AutoOptions } = props;
  const { t } = useTranslation(["forms"]);

  return (
    <React.Fragment>
      <Grid item spacing={2} style={{ padding: 10 }} container>
        <Grid item lg={2} md={2} sm={2} xs={5}>
          <Autocomplete
            value={object?.branch}
            onChange={(event, newValue) => {
              onChange(null, { name: "branch", value: newValue });
            }}
            id="branchValue"
            size="small"
            disableClearable={true}
            options={AutoOptions?.Branches ?? []}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="الفرع"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={object?.book_id}
            onChange={(event, newValue) => {
              onChange(null, { name: "book_id", value: newValue });
            }}
            id="controllable-states-demo"
            size="small"
            disableClearable={true}
            options={AutoOptions?.AccountBooks}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="نوع الدفتر"
                variant="outlined"
                size="small"
                fontSize="small"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={3}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label="قيد يدوي"
            name="nameE"
            value={object.rest_type}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              onChange(null, { name: "notes", value: e.target.value })
            }
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label="رقم القيد "
            name="doc_no2"
            value={object?.doc_no2}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            id="date"
            label="التاريخ"
            type="date"
            size="small"
            format="YYYY-MM-DD"
            value={object?.date}
            onChange={(e) =>
              onChange(null, { name: "date", value: e.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item lg={5} md={5} sm={5} xs={8}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label="بيان القيد "
            name="notes"
            value={object?.notes}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              onChange(null, { name: "notes", value: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
