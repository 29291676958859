import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useFetchObjectByPost from '../../../components/Hooks/useFetchObjectByPost';
import useFetchURL from '../../../components/Hooks/useFetchURL';
import { sto } from '../../../Context/InshData';
import { uri } from '../../../help/Api';
import BalanceCustRPT from './balanceCustRPT';

const defaultAutos ={
  Safes:[],
}

export default function BalanceCustRPTCon() {

  const loca = useLocation();

      const tb1 = {
                  wait:true,
                  Branches:true,
                  costcenters:true,
                  AccountsIndex:true,
                  omlat: true,
                  stor:sto,
                  parms:{filtr: true, pcode: 0, },
            }
        const {object: AutoOptions, fetching:fetchAll} = useFetchObjectByPost(uri+'Rept',tb1); 
        
        useEffect(()=>{
          console.log("loc al ", AutoOptions)
        })

  if(  fetchAll || !AutoOptions?.center )
    return <></>

  return (
  <BalanceCustRPT {...{
     AutoOptions: AutoOptions?.Branches? {...AutoOptions, 
      Accindex: AutoOptions.Accindex?.filter(ac => ac.id.toString().toLowerCase().startsWith('123') || ac.id.toString().toLowerCase().startsWith('261') || ac.id == -1)}
      : defaultAutos,
    title:"عميل",
  } 
  }/>
  );




};