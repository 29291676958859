import axios from 'axios';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { insh, sto } from '../../../../Context/InshData';
import { inshF } from '../../../../Context/InshFront';
import { locSto } from '../../../../Context/Locstor';
import { uri } from '../../../../help/Api';
import dataF from '../../../../Server/dataF.json';
// f d
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';
import SearchIcon from '@material-ui/icons/Search';

import CssBaseline from '@material-ui/core/CssBaseline';
// mt
// f e
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// i
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
// import ReceiptIcon from '@material-ui/icons/Receipt';
import ScannerIcon from '@material-ui/icons/Scanner';
import TransformIcon from '@material-ui/icons/Transform';
// t
// import MaterialTable from "material-table";
import MaterialTable from "material-table";
// import SearchBar from "material-ui-search-bar";
import { TableIcons as tableIcons } from "../../../../Elemnts/TableIcons";

// ------------------------------------------------ btn load
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
// ------------------------------------------------ btn load
// o com
import CustomersDialog from '../../../Customers/BackEnd/Tab1/CustomersDialog';
// Container
import Container from '@material-ui/core/Container';
// cuG
import Hidden from '@material-ui/core/Hidden';
import {
  StyledMenu, StyledMenuItem, useStylesAuto, useStylesBA,
  useStylesCAB, useStylesDTaCont, useStylesHed, useStylesInfoTcu, useStylesLbl, useStylesSN, useStylesTD, useStylesTF, useStylesUp
} from '../../../../St/comps/UseStyle';
// cuL
// import {} from './UseStyle';
// csG
import { cellS, headerS, stElemnt } from '../../../../St/comps/cStyle';
import { Mtb } from '../../../../St/comps/vstyle';
  
 // csL
// import {} from '../../FrontEnd/cStyle';
import BackdropScI from '../../../../services/BackdropScI';
import "../../../../St/comps/csStyle.css";

import toast, { Toaster } from 'react-hot-toast';


function Alert(props) { return <MuiAlert elevation={6} variant="filled" {...props} />; }

let LetvalueCurrUnits=null;

// export default function SalesInvO({ CWVtxt }){
  export default function InternalSand(){
    
  let reDate = moment(new Date()).format('YYYY-MM-DD');
  const [showSc, setShowSc] = useState(true);
  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clasCAB = useStylesCAB();
  const clasSN = useStylesSN();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const claslbl = useStylesLbl();

  const [gets, setGets] = useState(insh.tbname);
  const [sdata, setSData] = useState(insh.sandQK); //is data in ta
  // is data in ta

  // const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};

  /* Anemation */
const [transition, setTransition] = React.useState(undefined);
// function TransitionLeft(props) { return <Slide {...props} direction="left" />; }
function TransitionRight(props) { return <Slide {...props} direction="right" />; }
function TransitionDown(props) { return <Slide {...props} direction="down" />; }



  const [isOpenCustomer, setIsOpenCustomer] = useState(false);

  const backHome = () => 
  { 
    his.push({ pathname: '/', }); 
    window.location.reload(false);
};



 // s DDown
  const [openDw, setOpenDw] = useState(false);

  const handOpenDw = (Transition) => {
     setTransition(() => Transition); 
     getInvs(dateFrom,dateTo,stor.invsType); 
    };
    
  const handCloseDw = () => { setOpenDw(false); };

 const [subInfo, setSubInfo] = useState(insh.InfoSub);

/* s PMD */
const [openPMD, setOpenPMD] = useState(false)
const handleClickOpenPMD = (Transition) => {
  setTransition(() => Transition); 
  setOpenPMD(true);
}
/* e PMD */

/* s BCSD */


 const [lbl, setLbl] = React.useState(insh.Lbl)


const [switchState, setSwitchState] = React.useState(insh.SwitchState)

const [or, setOr] = useState(insh.Or);  

const [permiElm, setPermiElm] = useState(insh.permiElm);


const [uiElement, setUiElement] = useState({});

  // >>>>> datas
  const [currencies, setCurrencies] = useState([]);
  const [invsShow, setInvsShow] = useState([]);
  const [invsShowFltr, setInvsShowFltr] = useState([]);

const [infoSand, setInfoSand] = useState({id:'',InvId_Return:''}); 


const [isdata, setIsData] = useState([]); //is data in ta 

const location = useLocation();

const [stor, setStor] = useState({}); //is data in ta


const [groups, setGroups] = useState([]);
const [groupsSub, setGroupsSub] = useState([]);


  // Auto

  const [optionsCustomers, setOptionsCustomers] = useState(insh.Auto);
  const [valueCustomers, setValueCustomers] = useState({id:'',name:''});
  // const [valueNewCustomers, setValueNewCustomers] = React.useState(null);
  //Salesmen
  const [optionsSalesmen, setOptionsSalesmen] = useState([]); 
  const [valueSalesmen, setValueSalesmen] = useState(insh.Auto);
  const [optionsEmploy, setOptionsEmploy] = useState([]); 
  const [valueEmploy, setValueEmploy] = useState(insh.Auto);
  const [optionsPayment, setOptionsPayment] = useState([]); 
  const [valuePayment, setValuePayment] = useState(insh.Auto);
  const [optionsAccounts, setOptionsAccounts] = useState([]); 
  const [valueAccounts, setValueAccounts] = useState(insh.Auto);
  const [optionsAccountsIndex, setOptionsAccountsIndex] = useState([]); 
  const [valueAccountsIndex, setValueAccountsIndex] = useState(insh.Auto);
  const [optionsCheckStatus, setOptionsCheckStatus] = useState([]); 
  const [valueCheckStatus, setValueCheckStatus] = useState({});
  
  
  // const [optionsCostcenter, setOptionsCostcenter] = useState(insh.Auto);
  const [valueCostcenter, setValueCostcenter] = useState(insh.Auto);
  const [currenciesToInv, setCurrenciesToInv] = useState([]);
  const [currUnitsAll, setCurrUnitsAll] = useState([]);
  const [optionsType, setOptionsType] = useState([
    {id:1, name:'موظف'},
    {id:2, name:'جهة'}
  ]);
  const [valueType, setValueType] = useState(optionsType[0])
  //PricePay
  const [optionsPricePay, setOptionsPricePay] = useState(insh.AutoOp);
  const [valuePricePay, setValuePricePay] = useState(insh.Auto);

const getAutoPricePays = (i) => {
  setOptionsPricePay(insh.optionPricePay); setValuePricePay(insh.optionPricePay[i]); 
  }

const addChangds = () => {

 sdata.date=moment(sdata.date).format('YYYY-MM-DD') + moment(new Date()).format(' h:mm A')

}


const getInvs = async (from , to , type) => {
  // alert(from +' - '+ to +' - '+ type )
  const tb ={branch:sdata.branch,dateF:from, dateT:to}
  const tb1 = { SandQD:true, parms:{...tb, tname:type} }
  const tb2 = { SandD:true, parms:{...tb, tname:type} }

  const endURL = stor.invsType == 1? 'SandSD':'SandQD'; 
  await axios.get(uri+endURL)
  .then(res => {

     setInvsShow(res.data); 
     setInvsShowFltr(res.data); 
     setOpenDw(true);
    })
  .catch(error => { })
}


const reLoadLastInv = async ()=>{
  const tb1 = { other:true, parms:{branch:sto.branchi,tname:"sandqd" }, autos:{}, }
  const tb2 = { other:true, parms:{branch:sto.branchi,tname:"sandsd" }, autos:{}, }

  await axios.post(uri+'Res',stor.invsType == 1 ? tb2 : tb1)
  .then(res => { setInfoSand({...infoSand, id:res.data.other.lastid}); }).catch(error => { })
}

const loadSto = async (cusType) => {

  await axios.get(uri+'accounts-index/getForSearch').then(res=>{
    let accs= res.data.map(r=>{
      return {code: r.barcode, name: r.symbol,}
    })
    console.log("acc", accs);
    setOptionsAccountsIndex(accs);
    // setValueAccountsIndex(accs[0]);
  }).catch(err=>console.log(err))

  setGets(locSto)

  /* go Func we need check Condsh */

  setSData(location.state.sdata);
  console.log("load values", locSto)
  const Customers = locSto.CustomersA.concat(locSto.CustomersM)  

  setOptionsCustomers(Customers);
  // setValueCustomers(locSto.CustomersA[0]);
  setOptionsSalesmen(locSto.Employees);
  setValueSalesmen(locSto.Employees[0]);
  setOptionsEmploy(locSto.Employees);
  setOptionsPayment(insh.autos.payment);
  setValuePayment(insh.autos.payment[0]);
  setOptionsAccounts(locSto.Stocks);
  setValueAccounts(locSto.Stocks[0]);

  setOptionsCheckStatus(insh.autos.CheckStatus);
  setValueCheckStatus({id:'',name:''});
  setCurrencies(locSto.Currencies);
  // alert(locSto.Currencies.length)
  setcuRows(locSto.Currencies);
  setGroups(locSto.groups);
  setGroupsSub(locSto.groupsSub);
  setCurrUnitsAll(locSto.AutoCurrUnits);
  setCurrenciesToInv(locSto.CurrenciesToInv);

 }

const loadGet = async (branch,type,pTpI,empid,formid) => {
  const tb = {
    other:true, wait:true,
    parms:{
      // tname: type == 1 ? 'sandd':'sandqd',
      tname: (stor.invsType==0)?'sandqd':'sandsd',
      branch:branch,
    },
    autos:{},
    
 }

await axios.post(uri+'Res',tb)
  .then(res => {
    console.log('res.data.other.lastid'); console.log(res.data);
    setInfoSand({...infoSand, id:res.data.other.lastid});
    
    setShowSc(res.data.wait.state);
}).catch(error => { /*console.log('no');*/ })

setUiElement(insh.UiElement);
// const [uiElement, setUiElement] = useState(insh.UiElement);
}

const reloadGet = async () => {
  const tb = {
    CustomersWTypes:true, wait:true,
    parms:{ 

      icustmtype:location.state.setting.cusType,
      tname: (stor.invsType==0)?'sandqd':'sandsd',
    },
    autos:{},
 }

await axios.post(uri+'Res',tb)
  .then(res => { 
    setOptionsCustomers(res.data.Customers);

    setValueCustomers(res.data.Customers[0]);
}).catch(error => { /*console.log('no');*/ })

}

const [setting, setSetting] = useState({});
const [style, setStyle] = useState({});


const loadInsh = async () => {
  setSetting(location.state.setting);
  setStor(location.state.stor);
  loadGet(
    location.state.sdata.branch,
    location.state.stor.invsType,
    location.state.setting.PrTy_InPr,
    location.state.sdata.create_user,
    location.state.setting.iformid,
    );
  loadSto(location.state.setting.cusType);
  setStyle(location.state.style)
}

/* ---------------------------- */
useEffect(() => { loadInsh(); }, []);
/* ---------------------------- */

const toDoSdata = (prop,value) => { setSData({...sdata,[prop]:value}); }


  const changAutoSalesMen = (newValue) => { setValueSalesmen(newValue); toDoSdata('sales_emp',newValue.id); }
  const changAutoُEmploy = (newValue) => { 
    setValueEmploy(newValue); toDoSdata('emp_id',newValue.id); }
  
  const changAutoCostcenter = (newValue) => { setValueCostcenter(newValue); toDoSdata('center',newValue.id); }
  const changAutoPayment = (newValue) => { 
    setValuePayment(newValue);
    if(newValue.id != '1') 
    { 
      setOptionsAccounts(locSto.Banks); 
      setValueAccounts(locSto.Banks[0]); 
      setUiElement({...uiElement,ELMNTS:false});
      // alert(locSto.Banks[0].id)
      setSData({...sdata,safe_bank_id:locSto.Banks[0].id,type:newValue.id,check_state:0})
    } 
    else 
    { 
      // alert(locSto.Stocks[0].id)

      setUiElement({...uiElement,ELMNTS:true}); 
      setOptionsAccounts(locSto.Stocks); 
      setValueAccounts(locSto.Stocks[0]);
      setSData({...sdata,safe_bank_id:locSto.Stocks[0].id,type:newValue.id,
        check_no:'',checkbank:'',check_date:reDate,check_state:null})
      setValueCheckStatus({id:'',name:''})
    }

 }
  const changAutoAccount = (newValue) => { setValueAccounts(newValue); toDoSdata('safe_bank_id',newValue.id); }
  const changAutoCheckStatus = (newValue) => { setValueCheckStatus(newValue); toDoSdata('check_state',newValue.id); } 

 const [cuRows, setcuRows] = useState({});
  const [checked, setChecked] = useState(insh.Checked);


const handNewInv = () => {
  // alert('hand New Inv');
reLoadLastInv();
setIsData([]);
setSData(location.state.sdata);
setValueCustomers({id:'',name:''}); //getAutoCustomers(0);
setValueEmploy({id:'',name:''});
setValueSalesmen(locSto.Employees[0]);
setValueCostcenter(insh.Auto);
setSData(location.state.sdata);
setPayment(insh.Payment)
setOptionsAccounts(locSto.Stocks);
setValueAccounts(locSto.Stocks[0])
setValueAccountsIndex({});
setValuePayment(insh.autos.payment[0]);
setValueCheckStatus({id:'',name:''})
setUiElement(insh.UiElement);
setLbl(insh.Lbl);

setSwitchState(insh.SwitchState);
setChecked({...checked,
  costWithVat:true,
  discMinusck:false, discRateck:false,
  networkck:false,cashck:true,
  network_partck:false,cash_partck:false,agel_partck:false,bank_partck:false,emp_partck:false,
  // autoOrStaticck:false,
});
setOr(insh.Or);
setPermiElm({...permiElm,SaUp:permiElm.Sa})
}


var columnsInv = [
  {title: "الرقم", field: "id", headerStyle: headerS,cellStyle: cellS,},
  {title: "التاريخ", field: "date",headerStyle: headerS,cellStyle: cellS,},
  {title: "المبلغ", field: "val",headerStyle: headerS,cellStyle: cellS,},
  {title: "مندوب المبيعات", field: "empName",headerStyle: headerS,cellStyle: cellS,},
  {title: "المرجع", field: "check_no",headerStyle: headerS,cellStyle: cellS,},
  {title: "الحساب", field: "custName",headerStyle: headerS,cellStyle: cellS,},
  {title: "البيان", field: "notes",headerStyle: headerS,cellStyle: cellS,},
]


const allow = () => {
    const svalue=stor.invsType == 1? sdata.emp_id: sdata.cust_id;
  let inf={txt:'',state:true}
  if(sdata.val == '') {inf.txt='ادخل القيمة'; inf.state=false}
  else if(svalue == '')
      {inf.txt='يجب إختيار المستخدم'; inf.state=false}
  else if(sdata.acc_code == '') {inf.txt='يجب إختيار الحساب'; inf.state=false}
  return inf
}

// func m t
const befPushTos = async () => {

addChangds();
}

// const to Print = async(e,hist) => { }

let his = useHistory();

const toPrint = async(type,e,hist) => {
  // alert('toPrint')
  let inf = allow();
  // alert(inf.txt+' - '+inf.state+' | '+sdata.cust_id)
  if(inf.state) {or.PrAC=hist; await toWhere(type,e);}
  else handOSnack({ vertical:'bottom', horizontal:'center', severit:'warning', text:inf.txt, time:2000, });

};


const toPosh = (rptdata) => {
  // alert('to Posh  '+ valueCustomers.name)

  const info = {
  columns:columnsInv,
  // rout:rpt.parms,
  data: rptdata,
  tot: gets.tot,
  custN: setting.cusN,
  from: stor.invsType == 1 ? 'الصرف' : 'القبض',
  // from:'الحساب',
  title:stor.invsType == 1 ? 'سند صرف' :'سند قبض',
  auto: { empName:valueSalesmen.name,center:valueCostcenter.name,custName:valueCustomers.name},
  fDate: dateFrom,tDate:dateTo,
  Date: moment(rptdata.date).format('YYYY-MM-DD'),
  ElmntShow:true,
  }
  localStorage.setItem('iInfos',  JSON.stringify(info)); 
  his.push({ pathname: '/SC', });
  window.location.reload(false);
}

const PushTos = async (t) => {
  befPushTos();
  let Sa_or_Par_est_id=null;


 
 await ButtonLoading(t);
//  alert(uri+setting.routI_R_RS)
const tb1 = {
  sandQD: sdata,
  stor:stor,
  parms:{
    iuserid:sdata.id,
    iformidCuCo:dataF[3].id,
  },
}

const tb2 = {
  sandSD: sdata,
  stor:stor,
  parms:{
    iuserid:sdata.id,
    iformidCuCo:dataF[3].id,
  },
}
  console.log("tb1", tb2)

  if(or.postput == 'toPost')
  {
    await axios.post(uri+setting.routI_R_RS, stor.invsType == 1 ? tb2 : tb1)
    .then(resI => { 
      
    if(or.PrAC != 0) toPosh(resI.data); 

  ButtonSuccess(t,true); 

}).catch(resI => { 
  console.log(resI);
  handOSnack({ vertical:'bottom', horizontal:'center', severit:'warning', text:'لم تتم الاضافة', time:2000, });
  ButtonSuccess(t,false); 
})

  }
  else if(or.postput == 'toPut')
  {
     await axios.put(uri+setting.routI_R_RS, stor.invsType == 1 ? tb2 : tb1)
      .then(resI => { 
      if(or.PrAC != 0) toPosh(resI.data); 

          ButtonSuccess(t,true); 
      }).catch(resI => { 
        console.log(resI);
        handOSnack({ vertical:'bottom', horizontal:'center', severit:'warning', text:'لم تتم الاضافة', time:2000, });
        ButtonSuccess(t,false); 
      })
  }

}



const deleteTos = async (e) => {
  await e.preventDefault();  
 
  ButtonLoading('delete');
  // alert(setting.routI_R_RS)
  await axios.delete(uri+setting.routI_R_RS+'?id='+parseInt(sdata.id))
  .then(resIsRs => { ButtonSuccess('delete',true); 

}).catch(resIsRs => { 
  // alert('in catch')
  ButtonSuccess('delete',false); 
  //  console.log('no isrs')
   })
}

// ------------------------------------------------- btn load
const [loading, setLoading] = useState(insh.LoadAndSucc);
const [success, setSuccess] = useState(insh.LoadAndSucc);
const timer = React.useRef();

const buttonClassnameSave = clsx({
  [clasCAB.buttonSuccessCAB]: success.save,
});

const buttonClassnameDelete = clsx({
  [clasCAB.buttonSuccessCAB]: success.delete,
});

const buttonClassnamePrint = clsx({
  [clasCAB.buttonSuccessCAB]: success.print,
});
 useEffect(() => {
// alert('load Insh useE ffect')

  return () => {
// alert('load Insh useEf fect in return')

    clearTimeout(timer.current);
  };
}, []);

const ButtonLoading = (prop) => {

       setLoading({...loading, [prop]:true}); 

};
const ButtonSuccess = (prop,state) => {

  timer.current = window.setTimeout(() => {

    setLoading({...loading, [prop]:false}); 
    setSuccess({...success, [prop]:true}); 

    if(prop == 'delete' || prop == 'save' || prop == 'savePMD') {handNewInv();}

    timer.current = window.setTimeout(() => {
      setSuccess({...success, [prop]:false}); }, 500);

    }, 2000); 
};
// ------------------------------------ btn load


const toDB = async (type,e) => {
  let inf = allow();

  if(inf.state) await PushTos(type);
  else handOSnack({ vertical:'bottom', horizontal:'center', severit:'warning', text:inf.txt, time:2000, });
  // else alert(inf.txt);
}

const toWhere = (type,e) => {
  // alert('toWhere')
  if( isdata.length == 0)
  {
    if(checked.paymentDiloagck == true)
    { handleClickOpenPMD(TransitionRight); }
    else toDB(type,e);
  }
  else
  {
    // handOSnack({ vertical: 'top', horizontal: 'center' }); 
     handOSnack({ vertical:'top', horizontal:'right', severit:'warning', text:'يجب تنزيل اصناف', time:2000, });
  }

}


    // الدفع المبقي
    const [payment, setPayment] = useState(insh.Payment);

    const pinAutosValue = async (Tbl,id) => {

      if(Tbl==='Customer') optionsSalesmen.map(x => x.id==id && setValueCustomers(x))
      else if(Tbl==='Employ') optionsEmploy.map(x => x.id==id && setValueEmploy(x))
      else if(Tbl==='costcenters') 
      gets.costcenters.map((x,i) => { if(x.id==id) setValueCostcenter(x); else setValueCostcenter({id:'',name:''}); })
      else if(Tbl==='salesmen') optionsSalesmen.map(x => x.id==id && setValueSalesmen(x))
      else if(Tbl==='type') optionsPayment.map(x => x.id == id && setValuePayment(x))
      else if(Tbl==='accIndex') optionsAccountsIndex.map(x=> x.code== id && setValueAccountsIndex(x) )
     
      }
      
    const showChosedInv = async (val) => {

        console.log('val'); console.log(val);

      setSData(val);
      setInfoSand({...infoSand,id:val.id})
      if(stor.invsType == 0)
        pinAutosValue('Customer',val.cust_id);
      else 
        pinAutosValue('Employ',val.emp_id);
      pinAutosValue('costcenters',val.center);
      pinAutosValue('salesmen',val.sales_emp);
      pinAutosValue('type',val.type);
      pinAutosValue('accIndex', val.acc_code);
      if(val.type != 1)
      { 
        setUiElement({...uiElement,ELMNTS:false, DeleIcon: true});
        optionsCheckStatus.map(i => setValueCheckStatus(i.id == val.check_state && i))
        setOptionsAccounts(locSto.Banks);
        locSto.Banks.map(x => x.id==val.safe_bank_id && setValueAccounts(x))
      } 
      else 
      { 
        setUiElement({...uiElement,ELMNTS:true, DeleIcon:true}); 
        setOptionsAccounts(locSto.Stocks);
        locSto.Stocks.map(i => i.id==val.safe_bank_id && setValueAccounts(i))
        setValueCheckStatus({id:'',name:''});
      }
      // setPermiElm({...permiElm, De:true})

      handCloseDw();
};

const changSdata = (prop) => (event) => toDoSdata(prop, event.target.value);

/* s toggle btn */
const [toggle, setToggle] = React.useState(null);

  const clickOpenToggle = (event) => {

    if(isdata.length == 0)
    {
       setToggle(event.currentTarget); 
    }
    else
    { 
      // handOSnack({ vertical: 'top', horizontal: 'center' }); 
       handOSnack({ vertical:'top', horizontal:'right', severit:'warning', text:'يجب تنزيل اصناف أو إختيار فاتورة', time:2000, }); 
    }
  };

  const clickCloseToggle = () => {
    setToggle(null);
  };
/* e toggle btn */


// ------------------------------------ s date
const [dateFrom, setDateFrom] = useState(new moment(new Date()).format('YYYY-MM-DD'));
const [dateTo, setDateTo] = useState(new moment(new Date()).format('YYYY-MM-DD'));

  const handleDateFrom = (date) => {
    setDateFrom(date.target.value);
    getInvs(date.target.value, dateTo, stor.invsType)
  };

  const handleDateTo = (date) => {
    setDateTo(date.target.value);
    getInvs(dateFrom, date.target.value, stor.invsType)
  };

// ------------------------------------ e date


// ------------------------------------ s Snack

  /* 'error' 'warning' 'info' 'success'
     'top' 'bottom' 'center' 'left' 'right' */
  const [snack, setSnack] = React.useState(insh.Snack);

  const { vertical, horizontal, openSnak } = snack;

  const elmntBtn = (title,icondiv) => {
    return(
      <Button variant="contained" color="blue" size="large"
      style={{...style.iconBtn,...stElemnt.all.iconBtn}} disabled={true}
      startIcon={icondiv}> {title}
    </Button>
    )
  }

  const elmntIcon = (icondiv,dis) => {
    return(
      <Fab size="medium" color="primary" disabled={dis} 
      style={{...style.iconBtn,...stElemnt.all.iconBtn}}>
       {icondiv}
     </Fab>
    )
  }

const styBtn = {
  dis:{color:'#3b773d',background:'#808080'},
  undis:{color:'#fff'},
}
const handOSnack = (newState) => { setSnack({ openSnak: true, ...newState }); };
  // const handOSnackPMD = (newState) => { setSnackPMD({ openSnak: true, ...newState }); };

const handCSnack = (event, reason) => {
    // if (reason === 'clickaway') { return; }
    setSnack({ ...snack, openSnak: false });
  };

const frontSnack = () => { return(
    <div className={clasSN.rootSN}>
       <Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnak} autoHideDuration={snack.time}
        onClose={handCSnack} key={vertical + horizontal} 
         className={clasSN.snackbarSNKh}>
           
         <Alert onClose={handCSnack} severity={snack.severit}> {snack.text} </Alert> 
       </Snackbar>
</div>
  )}

  const frontAppBar = () => {return(
    <React.Fragment>
      <Toaster />
    <CssBaseline />
    <AppBar position="fixed" color="primary" 
    // className={clasBA.appBarBASal}
     style={{...style.bgImg,...stElemnt.all.appBarBA}}
    >
      <Toolbar  className={clasBA.ToolbarBA}
       variant="dense"
      >
      
      <Hidden smUp={['sm']}>
        <IconButton edge="start" color="inherit" aria-label="backH" onClick={backHome}>
          <CloseIcon />
        </IconButton>
        <IconButton edge="start" color="inherit" aria-label="searsh" disabled={permiElm.Se} onClick={() => handOpenDw(TransitionDown)}>
          <SearchIcon />
        </IconButton>
        <IconButton edge="start" color="inherit" disabled={permiElm.Ne} aria-label="handNew" onClick={handNewInv}>
          <AddBoxIcon />
        </IconButton>
      </Hidden>
      
      <Hidden only={['xs']}>
        <Button style={styBtn.undis} onClick={backHome} startIcon={<CloseIcon />} >خروج</Button>
        <Button style={permiElm.Se ? styBtn.dis : styBtn.undis} disabled={permiElm.Se}
        onClick={() => handOpenDw(TransitionDown)} startIcon={<SearchIcon />}>البحث في السندات</Button>
        <Button style={permiElm.Ne ? styBtn.dis : styBtn.undis} disabled={permiElm.Ne} 
        onClick={handNewInv} startIcon={<AddBoxIcon />}>جديد</Button>
      </Hidden>
      
        <div className={clasBA.growBA} />

    <StyledMenu
      id="customized-menu"
      anchorEl={toggle}
      keepMounted
      open={Boolean(toggle)}
      onClose={clickCloseToggle}
       style={stElemnt.itemHigh}
    >
      {/* <StyledMenuItem  onClick={() => toPosh('print','e',1)}> */}
      <StyledMenuItem  onClick={() => or.postput == 'toPost' ? toPrint('print','e',1) : toPosh(sdata)}>
        <ListItemIcon>
          <PrintIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="معاينة كاشير" />
      </StyledMenuItem>
    </StyledMenu>
    
  <Hidden smUp={['sm']}>

      {/* ico left  */}
    {uiElement.DeleIcon === true
    ?<div className={clasCAB.wrapperCAB}>
      {
        !permiElm.De
        ?<Fab size="medium" color="primary"
        style={{...style.iconBtn,...stElemnt.all.iconBtn,
         backgroundColor:'#808080' }} className={buttonClassnameDelete}
        onClick={deleteTos} >
        
        {success.delete ? <CheckIcon /> : <DeleteIcon />}
        </Fab>
        :<Fragment>{elmntIcon(<DeleteIcon/>,true)}</Fragment>
      }
    {loading.delete && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
    </div>:null}

    <div className={clasCAB.wrapperCAB}>
      {
        !permiElm.Prv && !permiElm.SaUp
        ?<Fab size="medium" color="primary"
        style={{...style.iconBtn,...stElemnt.all.iconBtn,
        backgroundColor:'#808080'}}
        onClick={clickOpenToggle}><PrintIcon />
        </Fab>
        :<Fragment>{elmntIcon(<PrintIcon/>,true)}</Fragment>
      }
    </div>

    <div className={clasCAB.wrapperCAB}>
    {
      !permiElm.SaUp
      ?<Fab size="medium" color="primary" disabled={loading.save}
        style={{...style.iconBtn,...stElemnt.all.iconBtn,
        backgroundColor:'#808080'}} className={buttonClassnameSave} 
        onClick={()=>toWhere('save','e')}> {success.save ? <CheckIcon /> : <SaveIcon />}
      </Fab>
      :<Fragment>{elmntIcon(<SaveIcon/>,true)}</Fragment>
    }
    {loading.save && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
    </div>
    
    { stor.invsType == 2 && or.postput != 'toPost'
      ?<div className={clasCAB.wrapperCAB}>
      <Fab size="medium" color="primary"
        style={{...style.iconBtn,...stElemnt.all.iconBtn,
        backgroundColor:'#808080'}}
        onClick={()=>alert('قم التواصل مع الدعم')}> <TransformIcon /> 
      </Fab>
    </div>:null }
    </Hidden>
    
    <Hidden only={['xs']}>
    {uiElement.DeleIcon === true
    ?<Fragment>
      <div className={clasCAB.wrapperCAB}>
      { 
        !permiElm.De
        ?<Button variant="contained" color="primary" size="large" 
        className={buttonClassnameDelete} startIcon={<DeleteIcon />}
        style={{...style.iconBtn,...stElemnt.all.iconBtn,
        backgroundColor:'#808080'}}
        /*={loading.delete}*/ onClick={deleteTos}>حـذف</Button>
        :<Fragment>{elmntBtn('حـذف',<DeleteIcon />)}</Fragment>
      }
      {loading.delete && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
     </div>
    </Fragment>
    :null}
    

    {
      !permiElm.Prv && !permiElm.SaUp
      ?<Fragment>
      <div className={clasCAB.wrapperCAB}>  
        <Button variant="contained" size="large" color="primary"
           className={buttonClassnamePrint} startIcon={<PrintIcon />}
           style={{...style.iconBtn,...stElemnt.all.iconBtn, 
            backgroundColor:'#808080'}}
           disabled={loading.print} 
          //  onClick={() => toPosh('print','e',1)}
            onClick={() => or.postput == 'toPost' ? toPrint('print','e',1) : toPosh(sdata)}
           >معاينة كاشير</Button>
        {loading.print && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
      </div>
      </Fragment>
      :<Fragment>
        {elmntBtn('معاينة كبير',<ScannerIcon />)}
        {elmntBtn('معاينة كاشير',<PrintIcon />)}
      </Fragment>
    }

    <div className={clasCAB.wrapperCAB}>
      {
        !permiElm.SaUp
        ?<Button variant="contained" color="primary" size="large"
        style={{...style.iconBtn,...stElemnt.all.iconBtn,
          backgroundColor:'#808080'}}
        className={buttonClassnameSave} 
        disabled={loading.save}
        onClick={()=>toWhere('save','e')} startIcon={<SaveIcon />}>
          {or.postput == 'toPost'?'حفظ بدون طباعة':'حفظ التعديلات'}
        
        </Button>
        :<Fragment>{elmntBtn('حفظ بدون طباعة',<SaveIcon />)}</Fragment>
      }
      {loading.save && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
    </div>

    </Hidden>

 {/* <Button variant="contained" color="primary" onClick={fhs}>fhs</Button> */}

      </Toolbar>
    </AppBar>
  </React.Fragment>
  )}

const frontUp = () => {return(
<div className={clasBA.continarBAInv}>
<React.Fragment>
<Grid item container spacing={0} className={clashd.rootHd}>
  <Grid item xs={12}>
  <Chip size="small" className={clashd.ChipHdAccount} label={setting.titleHed} color="primary" />
  </Grid>
</Grid>
       
<CssBaseline />
    <Container maxWidth="xl" className={clasup.AboverootupInv}>
            
    {/* S front up */}    
    <Grid item container spacing={1} className={clasup.rootup}>


    <Grid item lg={1} md={2} sm={5} xs={4}>
    <TextField type="text" fullWidth variant="outlined" size="small" id="id" label="رقم السند"
    className={clasTF.root} InputProps={{ className: clasTF.input, readOnly:true }}
    name="id" value={infoSand.id}  />
    </Grid>

    <Grid item lg={2} md={2} sm={3} xs={4}>
        <TextField fullWidth variant="outlined" size="small" id="date" label="التاريخ"
        className={clasTF.root} InputProps={{ className: clasTD.input }}
        type="date" format="YYYY-MM-DD"  InputLabelProps={{shrink: true}}
        name="date" value={sdata.date} onChange={changSdata('date')}
        />
    </Grid>

    <Grid item lg={2} md={2} sm={stor.invsType == 1 ? 3 : 4} xs={stor.invsType == 1 ? 5 : 4}>
    <TextField type="number" fullWidth variant="outlined" size="small" id="val" label="المبلغ"
    className={clasTF.root} InputProps={{ className: clasTF.input }}
    name="val" value={sdata.val} onChange={changSdata('val')} />
    </Grid>

    <Grid item lg={2} md={2} sm={stor.invsType == 1 ? 4 : 4} xs={stor.invsType == 1 ? 8 : 5}>
    <Autocomplete classes={clasAo} 
                value={valueAccountsIndex}
                onChange={(event, newValue) => { 
                  setValueAccountsIndex(newValue) 
                  toDoSdata('acc_code', newValue.code);
                }}
                //inputValue={inputValueBranch}
                // onInputChange={(event, newInputValue) => { setInputValueBranch(newInputValue); }}
                id="controllable-states-demo" size="small"
                options={optionsAccountsIndex || []}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                
                disableClearable
                renderInput={(params) => <TextField 
                id="valueCustomers"
                {...params} label={setting.cusN} variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clor}} />}
            />
    </Grid> 

    <Grid item lg={1} md={2} sm={stor.invsType == 1 ? 2 : 3} xs={stor.invsType == 1 ? 4 : 3}>
    <Autocomplete classes={clasAo}
                value={valueCostcenter}
                onChange={(event, newValue) => { changAutoCostcenter(newValue) }}
                //inputValue={inputValueBranch}
                // onInputChange={(event, newInputValue) => { setInputValueBranch(newInputValue); }}
                id="controllable-states-demo" size="small"
                options={gets.costcenters}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                disableClearable
                renderInput={(params) => <TextField id="valueCostcenter" {...params} label={'مركز التكلفة'} variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clor}} />}
            />
    </Grid> 
        <Grid item lg={1} md={1} sm={ 4} xs={5}>
          <Autocomplete classes={clasAo} 
                value={valueType}
                onChange={(event, newValue) => { 
                  setValueType(newValue);
                  setSData({...sdata, emp_person:newValue.id});
                }}
                //inputValue={inputValueBranch}
                // onInputChange={(event, newInputValue) => { setInputValueBranch(newInputValue); }}
                id="controllable-states-demo" size="small"
                options={optionsType}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}

                disableClearable
                renderInput={(params) => <TextField 
                id="valueCustomers"
                {...params} label={' الجهة '} variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clor}} />}
            />
    </Grid> 
    {
    stor.invsType == 1
    ?<Grid item lg={2} md={2} sm={3} xs={7}>
     <Autocomplete classes={clasAo} 
                value={valueEmploy}
                onChange={(event, newValue) => { changAutoُEmploy(newValue) }}
                //inputValue={inputValueBranch}
                // onInputChange={(event, newInputValue) => { setInputValueBranch(newInputValue); }}
                id="controllable-states-demo" size="small"
                options={optionsEmploy}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                
                disableClearable
                renderInput={(params) => <TextField 
                id="valueCustomers"
                {...params} label={'المستخدم'} variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clor}} />}
            />
     </Grid> 
    :<Grid item lg={2} md={2} sm={stor.invsType == 1 ? 4 : 4} xs={stor.invsType == 1 ? 8 : 5}>
    <Autocomplete classes={clasAo} 
                value={valueCustomers}
                onChange={(event, newValue) => { 
                  setValueCustomers(newValue);

                  setSData({...sdata, cust_id:newValue.id});
                }}
                //inputValue={inputValueBranch}
                // onInputChange={(event, newInputValue) => { setInputValueBranch(newInputValue); }}
                id="controllable-states-demo" size="small"
                options={optionsSalesmen}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                
                disableClearable
                renderInput={(params) => <TextField 
                id="valueCustomers"
                {...params} label={'استلمت انا السيد'} variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clor}} />}
            />
    </Grid> 
  }

    
    </Grid>
    {/* E front up */}

    {/* S front down */}
  <Grid item container spacing={1} style={{marginTop:40}} className={clasup.rootup}>

  <Grid item container spacing={1}>

    <Grid item lg={2} md={2} sm={3} xs={4}>
    <Autocomplete classes={clasAo}
                value={valueSalesmen}
                onChange={(event, newValue) => { changAutoSalesMen(newValue) }}
                id="controllable-states-demo" size="small"
                options={optionsSalesmen}
                // getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                disableClearable
                renderInput={(params) => <TextField id="valueSalesmen" {...params} label="المندوب" variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clor}} />}
            />
    </Grid> 

    <Grid item lg={2} md={2} sm={2} xs={3}>
    <Autocomplete classes={clasAo}
                value={valuePayment}
                onChange={(event, newValue) => { changAutoPayment(newValue) }}
                //inputValue={inputValueBranch}
                // onInputChange={(event, newInputValue) => { setInputValueBranch(newInputValue); }}
                id="controllable-states-demo" size="small"
                options={optionsPayment}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                disableClearable
                renderInput={(params) => <TextField id="valueCostcenter" {...params} label={'طريقة الدفع'} variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clor}} />}
            />
    </Grid> 

    <Grid item lg={3} md={3} sm={3} xs={5}>
    <Autocomplete classes={clasAo}
                value={valueAccounts}
                onChange={(event, newValue) => { changAutoAccount(newValue) }}
                id="controllable-states-demo" size="small"
                options={optionsAccounts}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                disableClearable
                renderInput={(params) => <TextField id="valueCostcenter" {...params} label={setting.accN} variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clor}} />}
            />
    </Grid>

    <Grid item lg={5} md={5} sm={4} xs={12}>
  <TextField fullWidth variant="outlined" size="small" id="notes"  label="البيان"
  className={clasTF.root} InputProps={{ className: clasTF.input }}
  name="notes" value={sdata.notes} onChange={changSdata('notes')}  />
    </Grid>

  </Grid>

{/* ------------------== down ------------------== */}

  <Grid item container spacing={1} style={{marginTop:30,justifyContent:'center'}}>

    <Grid item lg={2} md={2} sm={3} xs={6}>
    <TextField type="number" fullWidth variant="outlined" size="small" id="check_no" label="رقم الشيك"
    className={clasTF.root} InputProps={{ className: clasTF.input }}
    onChange={changSdata('check_no')}
    name="check_no" value={sdata.check_no} disabled={uiElement.ELMNTS} />
    </Grid>

    <Grid item lg={2} md={2} sm={3} xs={5}>
        { uiElement.ELMNTS 
        ?
        <TextField fullWidth variant="outlined" disabled size="small" id="check_date" label="تاريخ إستحقاق (شيك)"
        className={clasTF.root} InputProps={{ className: clasTD.input }}
        name="check_date" format="MM/DD/YYYY" value={insh.sandQK.check_date}  InputLabelProps={{shrink: true}} />
        :<TextField fullWidth variant="outlined" size="small" id="check_date" label="تاريخ إستحقاق (شيك)"
        className={clasTF.root} InputProps={{ className: clasTD.input }}
        type="date" format="YYYY-MM-DD"  InputLabelProps={{shrink: true}}
        name="check_date" value={sdata.check_date} onChange={changSdata('check_date')}
        />
        }
    </Grid>

    <Grid item lg={2} md={2} sm={2} xs={4}>
    <Autocomplete classes={clasAo} disabled={uiElement.ELMNTS}
                value={valueCheckStatus}
                onChange={(event, newValue) => { changAutoCheckStatus(newValue) }}
                //inputValue={inputValueBranch}
                // onInputChange={(event, newInputValue) => { setInputValueBranch(newInputValue); }}
                id="controllable-states-demo" size="small"
                options={optionsCheckStatus}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                disableClearable
                renderInput={(params) => <TextField id="valueCostcenter" {...params} label='حالة الشيك' variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clor}} />}
            />
    </Grid> 

    <Grid item lg={5} md={2} sm={4} xs={7}>
    <TextField type="text" fullWidth variant="outlined" size="small" id="checkbank" label="الشيك مسحوب على بنك"
    className={clasTF.root} InputProps={{ className: clasTF.input }}
    onChange={changSdata('checkbank')}
    name="checkbank" value={sdata.checkbank} disabled={uiElement.ELMNTS} />
    </Grid>

  </Grid>

    </Grid>
    {/* S front down */}
    </Container>
 </React.Fragment>
 </div>
  )}

const frontInvs = () => {return(
    <Dialog fullScreen open={openDw} onClose={handCloseDw}
TransitionComponent={transition}
key={transition ? transition.name : ''}

>
     
 {/* ------------------------------------------------ start ------------------------------------ */}


<div className={clasBA.continarBASer}>
<React.Fragment>
<Grid item container spacing={0} className={clashd.rootHd}>
  <Grid item xs={12}>
  <Chip size="small" className={clashd.ChipHdSale} label={'بـحـث فـي الـسـنـدات'} color="primary" />
  </Grid>
</Grid>
       
      <CssBaseline />
<Container maxWidth="xl" className={clasup.AboverootupSer} >
        
  {/* S front H */}    
<Grid item container spacing={1} className={[clasup.rootup, clasup.rootupSer]}>

 
<Grid item lg={2} md={2} sm={3} xs={6}>
<TextField fullWidth variant="outlined" size="small" id="dateinvsf" label="مـن تـاريـخ"
className={clasTF.root} InputProps={{ className: clasTD.input }}
type="date" format="YYYY-MM-DD"  InputLabelProps={{shrink: true}}
name="dateinvsf" value={dateFrom} onChange={handleDateFrom}
/></Grid>

<Grid item lg={2} md={2} sm={3} xs={6}>
<TextField fullWidth variant="outlined" size="small" id="dateinvsf" label="إلـى تـاريـخ" 
className={clasTF.root} InputProps={{ className: clasTD.input }}
type="date" format="YYYY-MM-DD"  InputLabelProps={{shrink: true}}
name="dateinvsf" value={dateTo} onChange={handleDateTo}
/></Grid>

{/* <Grid item lg={3} md={4} sm={4} xs={12}>
<TextField fullWidth variant="outlined" size="small" id="searchInvsx`" label="بـحـث" 
className={clasTF.root} InputProps={{ className: clasTF.input }}  placeholder="بـحـث" 
type="search"  InputLabelProps={{shrink: true}}
name="searchInvs" value={searchedInvShow} onChange={invsShowSearch}
/></Grid> */}

  </Grid>
  {/* E front H */}

  {/* S front B */}
  <Grid item container className={clasup.rootup}>

    {/* S MatTableOld */}
 
  <Grid item  md={12} sm={12} xs={12} >
            <MaterialTable style={{boxShadow:'none'}}
            //style={{direction:'ltr'}}
          options={{ 
            showTitle:false,
            toolbar: false,
            sorting: false,
            selection: false,
            // ToolBar Search
            searchFieldAlignment:"left",
            searchFieldVariant:"outlined",
            // ToolBar header
            showSelectAllCheckbox:false,
            showTextRowsSelected:false, 
            // body
            // rowStyle:(data,i)=>i%2!=0?{background:'#f9fafa',top:30}:null,
            // footer
            pageSize:5,
            paginationType:"stepped",
            showFirstLastPageButtons:false,
            
            paging:false,
            // showFirstLastPageButtons
            // showPagination={false}
            maxBodyHeight: Mtb.minmaxBHInvs,
            minBodyHeight: Mtb.minmaxBHInvs,
            // padding: 'dense',
            rowStyle: stElemnt.InvsR,
            headerStyle: stElemnt.InvsH,
           }}
           
           
          //  showPaginationBottom={false}
        
          // actions={[{ icon: () => <Delete />, tooltip: "حذف الأصناف", onClick: hRowDelete },]}

          localization={{
            body:{ emptyDataSourceMessage:<h3 style={{color:'#000',}} >لا توجد سندات بعد</h3> ,},
            toolbar: { searchPlaceholder: "بـحـث", },
          }}
          
          columns={columnsInv}
          data={invsShow}
          icons={tableIcons}
          
          onRowClick={(event, rowData) => {
           // Get your id from rowData and use with link.
           // window.open(`mysite.com/product/${rowData.id}`, "_blank")
           // console.log(rowData)

           // uiElement.DeleIcon=true;
           setOr({...or, postput:'toPut',PrAC:0 })
          //  alert(rowData.proc_id)
           showChosedInv(rowData);
           event.stopPropagation();
         }}

             />
            </Grid> 

     {/* E MatTableNew */}

  </Grid>
  {/* E front B */}

  <br/> <br/>

      </Container>
    </React.Fragment>
 </div>


 {/* ------------------------------------------------------== end ------------------------------------------------------------ */}
     <React.Fragment>
     <CssBaseline />
     <AppBar position="fixed" color="primary"
      // className={clasBA.appBarBASal}
       style={{...style.bgImg,...stElemnt.all.appBarBA}}
     >
       <Toolbar variant="dense">
         
        <Hidden smUp={['sm']}>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handCloseDw}>
          <CloseIcon />
        </IconButton>
      </Hidden>

      <Hidden only={['xs']}>
        <Button style={{color:'#fff'}} onClick={handCloseDw} startIcon={<ReplyIcon />}>السندات</Button>
      </Hidden>

         <div className={clasBA.growBA} />

       </Toolbar>
     </AppBar>
   </React.Fragment>

</Dialog>
  )}

  return (
   showSc?<BackdropScI/>:
    <div>

  {frontSnack()}
  {frontAppBar()}
{/* e Fixed */}

{/* s UP */}
  {frontUp()}
  {/* <Divider /> */}
{/* e UP */}    

{/* s DW */}
  {frontInvs()}
{/* e DW */}

 <CustomersDialog 
    DialogOpened={isOpenCustomer} 
    DialogClose={() => {
            setIsOpenCustomer(false); 
            reloadGet();
         }}
         
         content={{setting:location.state.setting,}}
         />
  </div>
 );
}
