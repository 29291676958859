import React from 'react'
import { Button, makeStyles } from "@material-ui/core";
import { ButtonGroup } from '@material-ui/core';
import { ArrowBack, ArrowBackIos, ArrowForwardIos, ArrowForwardIosOutlined, FirstPage, LastPage, NavigateNext } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none'
    }
}))

export default function PagenationButtons(props) {
    
    const {  color,
         variant,
         onFirst,
         onNext,
         onPrevious,
         onLast,
         onClick,
          ...other } = props
    const classes = useStyles();
    const buttons = [
        <Button key="first"
          style={{color:color, borderRadius:0, borderBottom:'solid 1px white'}}
            onClick={onClick("first")}
            disabled={props?.disabled}
          >
            <FirstPage />
        </Button>,
        
        <Button key="previous"
        //   hidden={true}
          style={{color: color, borderRadius:0, borderBottom:'solid 1px white'}}
          disabled={props?.disabled}

        //   disabled={isNew}
          onClick={onClick("previous")}
          >
            <ArrowBackIos />
        </Button>,
        <Button key="next"
          style={{color: color, borderRadius:0, borderBottom:'solid 1px white'}}
          disabled={props?.disabled}
          onClick={onClick("next")}
         ><ArrowForwardIos />
        </Button>,

        <Button key="last"
         hidden={true}
         style={{color: color, borderRadius:0, borderBottom:'solid 1px white'}}
         disabled={props?.disabled}
         onClick={onClick("last")}
         >
           <LastPage />
       </Button>,
      ];
    return (
        <ButtonGroup
            orientation="horizontal"
            aria-label="horizontal primary button group"
            // style={{marginTop:'-10px', backgroundColor: '#cd5c5c'}}
            
            other
        >
            {buttons}
      </ButtonGroup>
    )
}