import React from "react";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import { IconButton } from "@material-ui/core";
import { UnfoldLess, UnfoldMore } from "@material-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AccountsIndexTree = (props) => {
  const { data, onShow } = props;
  const [expandAll, setExpandAll] = useState(false);
  const { t } = useTranslation(["forms"]);
  const onSelect = (selectedKeys, info) => {
    const data = info.node;
    onShow(data, "edit");
  };
  const fieldNames = {
    title: "label",
    key: "Code",
    children: "children",
  };
  useEffect(() => {}, [expandAll]);

  return (
    <div style={{ margin: "0 20px" }}>
      <IconButton
        variant="contained"
        edge="start"
        color="inherit"
        aria-label="home"
        onClick={() => {
          setExpandAll(true);
        }}
      >
        <UnfoldLess />{" "}
      </IconButton>
      <IconButton
        variant="contained"
        edge="start"
        color="inherit"
        aria-label="home"
        onClick={() => {
          setExpandAll(false);
          console.log(expandAll);
        }}
      >
        <UnfoldMore />{" "}
      </IconButton>
      <h2>{t("accounts")}</h2>
      {expandAll ? (
        <div>
          <Tree
            // defaultExpandedKeys={[1, 2, 3, 4]}
            defaultExpandAll={true}
            defaultExpandParent={true}
            className="myCls"
            fieldNames={fieldNames}
            treeData={data}
            onSelect={onSelect}
          />
        </div>
      ) : (
        <Tree
          defaultExpandedKeys={[1, 2, 3, 4]}
          defaultExpandAll={false}
          className="myCls"
          fieldNames={fieldNames}
          treeData={data}
          onSelect={onSelect}
        />
      )}
    </div>
  );
};

export default AccountsIndexTree;
