import React from 'react';
import clsx from 'clsx';
import {local} from '../../../Context/Local';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActionArea from '@material-ui/core/CardActionArea';

// cuG
import {useStyleC} from '../st/UseStyle';
// csG
import {style} from '../st/cStyle'

import Icons from './Icons';
import {useTranslation} from 'react-i18next';


export default function CardP() {
  const {t} = useTranslation();
  const classes = useStyleC();
  let his = useHistory();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => { setExpanded(!expanded); };

  const dept = (i) => {
    // console.log(local[i])
    his.push({
      pathname: '/depts',
      state: { local:local[i], },
    })
  };

  // const getUPrmi = async () => {
  //   const tb = { PermissionsScreen:true, wait:true,
  //     parms: { iuserid:sto.idi, iformidI:dataF[46].id,iformidR:dataF[48].id,},
  //     autos:{}, }

  //   console.log(tb)
  //   // await axios.post('http://localhost:8184/api/'+'Res',tb).then(res => {
  //   await axios.post(uri+'Res',tb).then(res => {
  //     console.log('data'); console.log(res.data);
  //     setPermiScr(res.data.permisScr);
  //     setShowSc(res.data.wait.state);
  //   }).catch(error => { })
  // }

  
//   useEffect( () => {
//     getUPrmi();
//  }, []);

  return (
    <Card className={classes.rootSal} >
      <CardActionArea onClick={()=>dept(0)} >
      <CardMedia className={classes.mediaP} />
      <CardContent className={classes.content} style={style.color.sal}>
        <Typography  variant="h5">
       {t('sales-title')}
        </Typography>
      </CardContent>
      </CardActionArea>
      <CardActions disableSpacing className={classes.actions}>
        {/* <IconButton 
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton> */}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.slidcontent}>
          {/* <Typography > */}
            {/* كل ما تحتاجه داخل قسم المبيعات */}
          {/* </Typography> */}

          {/* <Typography > */}
          <Icons/> 
          {/* </Typography> */}
        </CardContent>
      </Collapse>
    </Card>
  );
}

