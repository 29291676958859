import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import {
  useStylesTF,
  useStylesTD,
  useStylesSetting,
} from "../../../St/comps/UseStyle";
import { useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import Swipeable from "../../../Fronts/Main/Swipeable";
import axios from "axios";
import toast from "react-hot-toast";
import { uri } from "../../../help/Api";

export default function ElectronicScaleForm(props) {
  const { object, data, onChange } = props;

  const clasTF = useStylesTF();

  const [submiting, setSubmiting] = useState(false);

  const applyTaxGroup = async (taxGroupId) => {
    console.log(taxGroupId);
    const data = { id: taxGroupId };
    setSubmiting(true);
    await axios
      .post(uri + "SysSettings", data)
      .then((res) => {
        toast.success("تم تطبيق الضريبة الافتراضية على جميع الاصناف");
      })
      .catch((err) => {
        toast.error("لم يتم التطبيق حدثت مشكلة");
        console.log(err);
      });

    setSubmiting(false);
  };

  const onChangeData = (propName) => (e) => {
    onChange(null, { name: [propName], value: e.target.value });
  };
  const classesStt = useStylesSetting();

  return (
    <React.Fragment>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="id"
            label="كود"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="كود"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="id"
            value={object.id}
            onChange={onChangeData("id")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="searchInvsx`"
            label="اسم التصميم"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="اسم التصميم"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="mizan_desc"
            value={object.mizan_desc}
            onChange={onChangeData("mizan_desc")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="mizan_start_with`"
            label="الرقم الذي يبدأ فية الباركود"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="الرقم الذي يبدأ فية الباركود"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="mizan_start_with"
            value={object.mizan_start_with}
            onChange={onChangeData("mizan_start_with")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="mizan_start_lenght`"
            label="طول رقم البداية"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="طول رقم البداية"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="mizan_start_lenght"
            value={object.mizan_start_lenght}
            onChange={onChangeData("mizan_start_lenght")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="barcode_start_from`"
            label="بداية باركود الصنف"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="بداية باركود الصنف"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="barcode_start_from"
            value={object.barcode_start_from}
            onChange={onChangeData("barcode_start_from")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="barcode_start_lenght`"
            label="طول باركود الصنف"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="طول باركود الصنف"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="barcode_start_lenght"
            value={object.barcode_start_lenght}
            onChange={onChangeData("barcode_start_lenght")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="weight_start_from`"
            label="بداية باركود الوزن"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="بداية باركود الوزن"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="weight_start_from"
            value={object.weight_start_from}
            onChange={onChangeData("weight_start_from")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="weight_start_lenght`"
            label="طول باركود الوزن"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="طول باركود الوزن"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="weight_start_lenght"
            value={object.weight_start_lenght}
            onChange={onChangeData("weight_start_lenght")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="mizan_end_with`"
            label="الرقم الذي ينتهي فية المزان"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder=""
            type="text"
            InputLabelProps={{ shrink: true }}
            name="mizan_end_with"
            value={object.mizan_end_with}
            onChange={onChangeData("mizan_end_with")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="mizan_end_lenght`"
            label="طول الرقم الذي ينتهي"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="طول الرقم الذي ينتهي"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="mizan_end_lenght"
            value={object.mizan_end_lenght}
            onChange={onChangeData("mizan_end_lenght")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="total_barcode_lenght`"
            label="إجمالي طول الباركود"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder=""
            type="text"
            InputLabelProps={{ shrink: true }}
            name="total_barcode_lenght"
            value={object.total_barcode_lenght}
            onChange={onChangeData("total_barcode_lenght")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={object?.use_weight_price == 1}
                  onChange={(e) =>
                    onChange(null, {
                      name: "use_weight_price",
                      value: e.target.checked ? 1 : 0,
                    })
                  }
                  name="use_weight_price"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label="استخدام الوزن كسعر"
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Grid container item lg={12} md={12} xs={12}></Grid>
    </React.Fragment>
  );
}
