import React from "react";
import {
  AppBar,
  Button,
  CircularProgress,
  CssBaseline,
  Fab,
  Hidden,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import { useStylesBA, useStylesCAB } from "../../../../St/comps/UseStyle";
import toast, { Toaster } from "react-hot-toast";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import { useState } from "react";
import { stElemnt } from "../../../../St/comps/cStyle";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { restrictionValidateSchema } from "./restriction-validate-schema";
import {
  PrepareData,
  PrepareDataDaily,
  ReFormatingData,
  ReFormatingDataDaily,
} from "../../../../services/restrictionService";
import RestrictionSearchModal from "./restriction-search-modal";
import moment from "moment";
import { remove } from "../../../../api/customer";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteConfirmDialog from "../../../../components/DeleteConfirmDialog";
import { uri } from "../../../../help/Api";
import axios from "axios";
import { sto,insh } from "../../../../Context/InshData";
import PagenationButtons from "../../../../components/controls/PagenationButons";
export default function DRestrAppBar(props) {
  const {
    submit,
    isDialog,
    onClose,
    prmis,
    update,
    defaultV,
    submitting,
    onShow,
    object,
    URL,
    formType,
    getRow,
    AutoOptions,
  } = props;
  const { t } = useTranslation(["forms"]);
  let his = useHistory();
  const [openSearchMdl, setOpenSearchMdl] = useState(false);

  const clasBA = useStylesBA();
  const clasCAB = useStylesCAB();
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  const styBtn = {
    dis: { color: "#3b773d", background: "#4fa152" },
    undis: { color: "#fff" },
  };

  const newObject = () => {
    onShow(defaultV, "new");
  };
  const addChangds = () => {
    object.date =
      moment(object.date).format("YYYY-MM-DD") +
      moment(new Date()).format(" h:mm A");
  }
  const submiteRestriction = (type) => async (e) => {
    addChangds();
    e.preventDefault();
    if (object.rows?.length == 0) {
      toast.error("لا يوجد حسابات في القيد");
    } else {
      let model = PrepareDataDaily(object);
      console.log("the model is", model);
      await restrictionValidateSchema
        .validate(model?.restriction, { abortEarly: false })
        .then(async function (valid) {
          if (formType == "edit") {
            await update(model).then((res) => {
              if (type == "print") print();
            });
          } else {
            await submit(model).then((res) => {
              if (type == "print") print();
            });
          }
        })
        .catch(function (err) {
          toast.error(err.errors[0]);
        });
    }
  };
  const print = () => {
    
    const info = {
      data: object.rows,
      tot: null,
      type: 2,
      accBook: "قيود يومية",
      doc_no2: object.doc_no2,
      notes: object.notes,
      total_dept: object.total_dept,
      total_credit: object.total_credit,
      nDate: moment(object.date).format("YYYY-MM-DD"),
      tDate: "00:00:00",
    };

    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/RP" });
    window.location.reload(false);
  };
  const onRowClick = (row) => {
    console.log("data row", row);
    let FormatedData = ReFormatingDataDaily(row, AutoOptions);
    console.log("data is", FormatedData);
    onShow(FormatedData, "edit");
    setOpenSearchMdl(false);
  };
  const onGetRow = (gtype) => async (e)=>{
    await getRow({branch: sto.branchi,
      id: object.id,
      gettingType:"10,"+gtype
    }).then(res=>{
      if(res !=null){
        onRowClick(res?.data);
      }
      // console.log("the resposent is ", res)
    });
  }

  const backHome = () => {
    if (isDialog) onClose();
    else {
      his.push({ pathname: "/" });
      window.location.reload(false);
    }
  };
  const reopen = () => {
    his.push({ pathname: "/restInsert" });
    window.location.reload(false);
  };
  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const ButtonSuccess = (prop, state) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });
      if (
        prop == "delete" 
      ) {
        // if (state) newObject();
        if (state) newObject();
        reopen();

      }
  
      timer.current = window.setTimeout(() => {
        setSuccess({ ...success, [prop]: false });
      }, 100);
    }, 1000);
  };
  const timer = React.useRef();
  const ButtonLoading = (prop) => {
    setLoading({ ...loading, [prop]: true });
  };
  const deleteTos = async (e) => {

    await e.preventDefault();
    setConfirmDialog({...confirmDialog, isOpen: false});
    ButtonLoading("delete");

    await axios.delete(uri+`RestrictionInsert?id=${object.id}`).then(res=>{
      toast.success("Restriction has deleted");
      ButtonSuccess("delete", true);

    }).catch(err=>{
      console.log("eror", err)
      ButtonSuccess("delete", false);
      toast.error("not delete ")
    })
  };

  return (
    <React.Fragment>
      <Toaster />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clasBA.appBarBASal}
        style={{ backgroundColor: "#808080" }}
      >
        <Toolbar className={clasBA.ToolbarBA} variant="dense">
          <Hidden smUp={["sm"]}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="backH"
              onClick={backHome}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="searsh"
              onClick={() => setOpenSearchMdl(true)}
              disabled={prmis?.Se}
            >
              <SearchIcon />
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="handNew"
              onClick={newObject}
            >
              <AddBoxIcon />
            </IconButton>
          </Hidden>

          <Hidden only={["xs"]}>
            <Button
              style={styBtn.undis}
              onClick={backHome}
              startIcon={<CloseIcon />}
            >
              {t("exit")}
            </Button>
            <Button
              style={styBtn.undis}
              disabled={prmis?.Se}
              onClick={() => setOpenSearchMdl(true)}
              startIcon={<SearchIcon />}
            >
              {t("search")}
            </Button>
            <Button
              style={styBtn.undis}
              onClick={newObject}
              startIcon={<AddBoxIcon />}
            >
              {t("new")}
            </Button>
          </Hidden>

          <div className={clasBA.growBA} />
          <Hidden smUp={["sm"]}>
            <div className={clasCAB.wrapperCAB}>
              <Fab
                size="medium"
                color="primary"
                style={{ ...stElemnt.all.iconBtn, backgroundColor: "#808080" }}
                className={clasCAB.buttonSuccessCAB}
                disabled={
                  submitting || (formType == "new" ? prmis?.Sa : prmis?.Up)
                }
                onClick={submiteRestriction("save")}
              >
                {" "}
                <SaveIcon />
              </Fab>

              {submitting && (
                <CircularProgress
                  size={56}
                  className={clasCAB.fabProgressCAB}
                />
              )}
            </div>
          </Hidden>


          <Hidden only={["xm"]}>
          {formType == "edit" && (
                
                <div className={clasCAB.wrapperCAB}>
                 <PagenationButtons
                   variant="contained"
                   color="#fff"
                   size="large"
                   className={clasCAB.buttonSuccessCAB}
                   style={{
                     ...stElemnt.all.iconBtn,
                     backgroundColor: "#808080",
                   }}
                   disabled={submitting}
                   onClick={onGetRow}
 
                   />
 
                 {submitting && (
                   <CircularProgress
                     size={24}
                     className={clasCAB.buttonProgressCAB}
                   />
                 )}
             </div>)}
            {
            formType=='edit' && sto.User_typei==1 &&
            <div className={clasCAB.wrapperCAB}>
            <Button variant="contained" color="primary" size="large" 
                className={clasCAB.buttonSuccessCAB} 
                startIcon={<DeleteIcon/>}
                style={{...stElemnt.all.iconBtn, backgroundColor:'#808080'}}
                disabled={submitting}
                onClick={(e)=>{
                  setConfirmDialog({
                    isOpen: true,
                    onConfirm: () => {
                      deleteTos(e);

                    },
                  })
                }}
                >حـذف</Button>
 <DeleteConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
            {submitting && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
            </div>
        }
            <div className={clasCAB.wrapperCAB}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={clasCAB.buttonSuccessCAB}
                startIcon={<PrintIcon />}
                style={{
                  ...stElemnt.all.iconBtn,
                  backgroundColor: "#808080",
                }}
                disabled={
                  submitting ||
                  (formType == "new" ? prmis?.Sa || prmis?.Pr : prmis?.Pr)
                }
                onClick={submiteRestriction("print")}
                // onClick={() => or.postput == 'toPost' ? toPrint('print','e',1) : toPosh(sdata)}
              >
                {" "}
                {formType == "edit" ? t("print") : t("save-print")}
              </Button>
              {submitting && (
                <CircularProgress
                  size={24}
                  className={clasCAB.buttonProgressCAB}
                />
              )}
            </div>
            <div className={clasCAB.wrapperCAB}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ ...stElemnt.all.iconBtn, backgroundColor: "#808080" }}
                className={clasCAB.buttonSuccessCAB}
                disabled={
                  submitting || (formType == "new" ? prmis?.Sa : prmis?.Up)
                }
                onClick={submiteRestriction("save")}
                startIcon={<SaveIcon />}
              >
                {formType == "edit" ? t("save-update") : t("save")}
              </Button>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
      <RestrictionSearchModal
        {...{
          open: openSearchMdl,
          type: 10,
          onClose: () => {
            setOpenSearchMdl(false);
          },
          onRowClick: onRowClick,
        }}
      />
    </React.Fragment>
  );
}
