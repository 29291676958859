import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
// import Button from '../../../../componentsT/controls/Button';
import { Button, Checkbox, Container, FormControlLabel, IconButton } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useContext } from 'react';
import GlobalContext from '../../../../Context/GlobalContext';
import { object } from 'yup';
import { SaleCheckbox } from '../../../../St/Reports/UseStyle';
import { useStylesLbl } from '../../../../St/Reports/UseStyle';
import { useStylesDPM } from '../../../../St/comps/UseStyle';
const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),

  },

  textField3: {
    // margin: 10,
    // margin: theme.spacing(1),
    width: '100%',
    marginBottom: 5,
    marginTop: 5
  },
  textField: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color: 'navy', fontSize: '0.8rem', },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: 'navy', fontSize: '0.7rem',
      lineHeight: '0.5rem',

    }
    , "& .MuiOutlinedInput-inputMarginDense":
      { paddingLeft: 1, paddingRight: 1, paddingTop: 10, paddingBottom: 10, textAlign: 'center', fontSize: '1rem' },
  },

  root: {
    direction: 'rtl',
    marginBottom: theme.spacing(1),
    boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    border: 'solid 1px #009688',
    padding: 5,
  },
}));


export default function StoreTransferForm(props) {
  const { object: data, newId,
    formType, safes, branches } = props;
  const clasess = useStyles();

  const { costCenters, groups } = useContext(GlobalContext);
  const [isEstelam, setIsEstelam] = useState(false);

  const onChangeData = (prop) => (event) => {

    props.onChange(event, { name: prop, value: event.target.value });
    // setData({ ...data, [prop]: event.target.value })
  }
  const claslbl = useStylesLbl();
  const clasPM = useStylesDPM();

  return (

    <container className={clasess.main}>
      <Grid item className={clasess.root} container spacing={1} lg={12} md={12} sm={12} xs={12}>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <TextField type="text" fullWidth variant="outlined"
            size="small" id="stay" label="الرقم "
            name="no" value={formType == 'edit' ? data.no : newId} InputLabelProps={{ shrink: true }}
            onChange={onChangeData('no')}
            disabled={true}
          />
        </Grid>

        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <form noValidate>
            <TextField fullWidth variant="outlined"
              id="date"
              label="التاريخ"
              type="date"
              size="small"
              value={data.date}
              onChange={onChangeData('date')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <TextField type="text" fullWidth variant="outlined"
            size="small" id="refno" label="رقم المرجع"
            name="refno" value={data.refno}
            onChange={onChangeData('refno')} InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={data.safe_from}
            onChange={(event, newValue) => {
              // props.onChange(newValue, {name:'safe_from', value: newValue})
              props.onShow({
                ...data, safe_from: newValue,
                rows: []
              })
              console.log("object is", data)

            }}

            id="controllable-states-1" size="small"
            options={safes}
            disableClearable
            getOptionLabel={(option) => option ? option.name : ''}
            renderInput={(params) => <TextField  {...params} label="  المخزن " variant="outlined" size="small" />}
          />
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={data.safe_to}
            onChange={(event, newValue) => {
              props.onChange(newValue, { name: 'safe_to', value: newValue })
            }}

            id="combo-box-demo1" size="small"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}

            options={safes}
            disableClearable

            renderInput={(params) => <TextField  {...params} label="  المخزن المحول إلية " variant="outlined" size="small" />}
          />
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={data.branch}
            onChange={(event, newValue) => {
              props.onChange(newValue, { name: 'branch', value: newValue })
            }}
            id="combo-box-demo1" size="small"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            options={branches}
            disableClearable
            renderInput={(params) => <TextField  {...params} label="الفرع" variant="outlined" size="small" />}
          />
        </Grid>

        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={data.center}
            onChange={(event, newValue) => {
              props.onChange(newValue, { name: 'center', value: newValue })
            }}
            id="controllable-states-2" size="small"
            options={costCenters || []}
            getOptionLabel={(Option) => Option ? Option.name : ''}

            renderInput={(params) => <TextField  {...params} label=" مركز الكلفة" variant="outlined" size="small" />}
          />
        </Grid>
        <Grid className={clasess.textField} item lg={3} md={3} sm={3} xs={8}>
          <TextField type="text" fullWidth variant="outlined"
            size="small" id="stay" label=" البيان"
            name="notes" value={data.notes} InputLabelProps={{ shrink: true }}
            onChange={onChangeData('notes')}
          />
        </Grid>
        {/* <Grid
          item
          lg={4}
          md={4}
          sm={4}
          xs={4}
          className={clasPM.checkPM}

          style={{ direction: 'rtl' }}
        >
          <FormControlLabel
            id="isEstelam"
            value="isEstelam"
            name="isEstelam"
            className={claslbl.clor}

            control={
              <Checkbox
                checked={isEstelam}
                onChange={(e) => {
                  changeRemains('estelam',e);
                  // changeIsPay(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Auto Estelam"
            labelPlacement="end"
          />
        </Grid> */}
      </Grid>
    </container>
  );


};