import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles, styled } from '@material-ui/core/styles';

import i18next, { use } from 'i18next';
import { useEffect } from 'react';
import { Done } from '@material-ui/icons';
import { Chip } from '@material-ui/core';

export const languageOptions = [{
  key:1, value: 'العربية', lang: 'ar',
},
{
  key: 2 , value: 'English', lang: 'en-US',
}]


const useStyles = makeStyles((theme) => ({
    chip:{
    paddingLeft:'10px',
    paddingRight: '10px',
    marginLeft: '10px'
  },
}));

const ListItem = styled('li')(({theme}) =>({
  margin: theme.spacing(0.5),
}));

const SelectLanguage = (props)=>{
  const classes = useStyles();
  const [lang, setLang]=useState(i18next.language);
  useEffect(()=>{
    console.log(' language is', i18next.language);
  },[i18next.language])
    return (
            <Container
                style={{
                  display:'flex',
                  justifyContent:'center',
                  flexWrap:'wrap',
                  listStyle:'none',
                  padding:'5px',
                  margin:'0px',
                  direction: 'rtl',
                }}
                component="ul" >
              {
                
                languageOptions.map(lg=>{
                  // return <></>
                  return <ListItem key={lg.key}>
                    {
                      lg.lang ===lang ?
                      <Chip 
                        className={classes.chip} 
                        label={lg.value} 
                        icon={<Done style={{color:'#fff'}}/>}
                        style={{backgroundColor: '#ff0000',
                        color:'#ffffff'}}
                        clickable
                      />:
                      <Chip 
                        className={classes.chip} 
                        label={lg.value} 
                        variant='outlined'
                        color={'default'}
                        onClick={()=>{setLang(lg.lang); i18next.changeLanguage(lg.lang)}}
                      />
                    }
                  </ListItem>
                })}
            </Container>
           
    )
}

export default SelectLanguage;