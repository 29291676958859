import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ReplyIcon from '@material-ui/icons/Reply';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialTable from "material-table";
import {Mtb} from '../../../St/comps/vstyle';

import { useState } from 'react';
import moment from 'moment';
import { colors, LinearProgress, TextField } from '@material-ui/core';
import { useStylesHed, useStylesBA, useStylesUp,useStylesTD, useStylesTF, useStylesContainers } from '../../../St/comps/UseStyle';
import { cellS, headerB, headerS, stElemnt } from '../../../St/comps/cStyle';
import { Search } from '@material-ui/icons';
import { useMemo } from 'react';
import useFetchObjectByPost from '../../../components/Hooks/useFetchObjectByPost';
import { uri } from '../../../help/Api';


export default function CustomerSearchModal(props){

    const {
        open, 
        onClose,
        onRowClick,
        type,
    } = props;
    
    const tb = {wait:true,
        CustomersWTypes:true, 
        parms:{
        id:type,
        icustmtype:type,
    },
    }
    const url = uri+'Res';

    const {object:rows, fetching, fetch:fetchURL} =  useFetchObjectByPost(url, tb);


    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasTF = useStylesTF();
    const clsConts = useStylesContainers();
    var columns = [
      {title: "", field: "id", hidden : true},
      {title: "الإسم", field: "name", },
      {title: "الرقم الضريبي", field: "tax_no", },
      {title: "رقم الهاتف", field: "tel", },
      {title: "رقم الجوال", field: "mobile", },
      {title: "الدولة", field: "country", },
      {title: "المدينة", field: "city", },
      {title: "المنطقة", field: "area", },
      {title: "رقم الحساب في الدليل", field: "acc_no", },
      {title: "رقم الهوية", field: "national_id", },
      {title: "ملاحطلات", field: "notes", },
    ];


    const [searchTxt, setSearchTxt] = useState("");
    const title = type ==1?"العملاء ":"الموردين";
    const filteredRows = useMemo(()=>{
      if(!searchTxt)
       return rows?.Customers;
      if(rows?.Customers?.length > 0){
        let list = [];
        list = rows?.Customers?.filter((obj)=> JSON.stringify(obj).toLowerCase().includes(searchTxt.toLowerCase()))
        console.log(list);
        return list;
      }
      return [];
    }, [searchTxt, rows]);


   return ( 
   <Dialog fullScreen open={open} onClose={onClose}>
        {  
            <>
            <div className={clasBA.continarBASer}>
            <React.Fragment>
            <Grid item container spacing={0} className={clashd.rootHd}>
            
              <Grid item xs={12}>
              <Chip size="small" className={clashd.ChipHdCustomer} label={'بـحـث فـي ' +title} color="primary" />
              </Grid>
              
            </Grid>

            <CssBaseline />
            <Container maxWidth="xl" style={{direction:'rtl'}} className={[clsConts.rootdown, clsConts.subUpCustomer,]} >
                    
              <Grid item container className={[clsConts.rootdown, ]}>
                <Grid item md={12} sm={12} xs={12}>
                    <TextField type="text" fullWidth variant="outlined" size="small" id="val" label="بـحث" 
                        className={clasTF.rootEDS}
                        style={{backgroundColor: colors.grey[50], marginBlock: 10}}
                        InputProps={{ className: clasTF.input }}
                        name="val" 
                        value={searchTxt}
                        onChange={(e)=>setSearchTxt(e.target.value)} 
                        />
                </Grid>
              <Grid item  md={12} sm={12} xs={12} >
                {fetching? <LinearProgress />:
                        <MaterialTable style={{boxShadow:'none', direction:'rtl'}}
                      options={{ 
                        showTitle:false,
                        toolbar: false,
                        sorting: false,
                        selection: false,
                        // ToolBar Search
                        searchFieldAlignment:"left",
                        searchFieldVariant:"outlined",
                        // ToolBar header
                        showSelectAllCheckbox:false,
                        showTextRowsSelected:false, 
                        pageSize:5,
                        paginationType:"stepped",
                        showFirstLastPageButtons:false,
                         headerStyle: {...headerB, backgroundColor:'#b906b6'},
                        cellStyle: cellS,
                        paging:false,
            
                        maxBodyHeight: Mtb.minmaxBHInvs,
                        minBodyHeight: Mtb.minmaxBHInvs,
                       }}
                      localization={{
                        body:{ emptyDataSourceMessage:<h3 style={{color:'#000',}} >لا يوجد تحويلات بعد</h3> ,},
                        toolbar: { searchPlaceholder: "بـحـث", },
                      }}
                      
                      columns={columns}
                      data={filteredRows}
                      onRowClick={async (event, rowData) => {  
                        onRowClick(rowData);
                       event.stopPropagation();
                     }}
            
                         />
                }
              </Grid> 
            
              </Grid>
                <br/> 
                <br/>
            
                  </Container>
                </React.Fragment>
             </div>
            
            
             {/* ====================================== end ======================================== */}
                 <React.Fragment>
                 <CssBaseline />
                 <AppBar position="fixed" color="primary"
                  // className={clasBA.appBarBASal}
                   style={{...stElemnt.all.appBarBA, backgroundColor:'#b906b6'}}
                 >
                   <Toolbar variant="dense">
                     
                    <Hidden smUp={['sm']}>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={onClose}>
                      <ReplyIcon />
                    </IconButton>
                  </Hidden>
            
                  <Hidden only={['xs']}>
                    <Button style={{color:'#fff'}} onClick={onClose} startIcon={<ReplyIcon />}>واجهة {title}</Button>
                  </Hidden>
            
                     <div className={clasBA.growBA} />
            
                   </Toolbar>
                 </AppBar>
               </React.Fragment>
                </>
        }   
    </Dialog>
    )
}