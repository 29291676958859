import React, { useState, useEffect } from "react";

import { SAdefaultValues } from "../../../../services/saleAnalysis-services";
import {
  Button,
  CardActionArea,
  CardContent,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import CostCenterRptForm from "./CostCenterRpt-form";
import CostCenterRptTable from "./CostCenterRpt-table";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import TotalCard from "../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { defaultParams } from "../../../../services/CostCenterRpt-services";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import CostCenterRptTable3 from "./CostCenterRpt-table3";
import BalanceCard from "../../../../components/BalanceCard";
import { Label, TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(2),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.amber[50],
    color: "#000",
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },
  root3: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: colors.red[300],
    direction: "rtl",
    marginTop: 5,
    padding: 5,
    // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
  },
}));

const paramsContain = (params, id) => {
  const index = params.findIndex((x) => x.id == id);
  return index != -1;
};
export default function CostCenterRpt(props) {
  const { AutoOptions, accounts, branch, setBranch } = props;
  let from_date=moment(new Date()).subtract().format("YYYY")+"-01-01";
  const defaultP = {
    ...defaultParams,
    dateFrom:from_date,
    center: AutoOptions.centers[0],
  };
  const [rptParams, setRptParams] = React.useState(defaultP);
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const { t } = useTranslation(["forms", "translation"]);
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [sum, setSum] = useState(false);
  const [centerName, setcenterName] = useState("");


  const viewHandle = async () => {
    setSum(false)
    setFetching(true);
    await axios
      .get(
        uri +
        `AccountReports/centerBalance?acc_code=${rptParams.accounts}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&branch=${rptParams.branch?.id}
    &&center=${rptParams.center?.id}
    &&openBalance=${paramsContain(rptParams?.parameters, 3)}
    &&oldBalance=${paramsContain(rptParams?.parameters, 4)}
    &&orderType=${-1}
    &&docType=${-1}
    &&omla=${rptParams?.omla.id}
    &&restSubType=${paramsContain(rptParams?.parameters, 5)}
    &&arbah=${paramsContain(rptParams?.parameters, 1)}
    &&mezania=${paramsContain(rptParams?.parameters, 2)}`
      )
      .then((res) => {
        setData(res.data);
        console.log(" the data is", res.data);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
        toast.err("يوجد خطأ في الجلب");
        setData([]);
      });
  };



  const viewSumHandle = async () => {
    setSum(true)
    setData([]);
    setFetching(true);
    await axios
      .get(
        uri +
        `AccountReports/centerBalanceSum?acc_code=${rptParams.accounts}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&branch=${rptParams.branch?.id}
    &&center=${rptParams.center?.id}
    &&openBalance=${paramsContain(rptParams?.parameters, 3)}
    &&oldBalance=${paramsContain(rptParams?.parameters, 4)}
    &&orderType=${-1}
    &&docType=${-1}
    &&omla=${rptParams?.omla.id}
    &&restSubType=${paramsContain(rptParams?.parameters, 5)}
    &&arbah=${paramsContain(rptParams?.parameters, 1)}
    &&mezania=${paramsContain(rptParams?.parameters, 2)}`
      )
      .then((res) => {
        setData(res.data);
        console.log(" the data is", res.data);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
        toast.err("يوجد خطأ في الجلب");
        setData([]);
      });
  };

  let cost_name = rptParams.center + ""
  const his = useHistory();
  useEffect(() => {
    // console.log(" the data is", data);
  });

  const printHandle = () => {
    const info = {
      data: data?.CostCenterRpt,
      tot: data?.totals,
      from: "الحساب" + "/" + cost_name,
      title: "حركة حساب خلال فترة",
      auto: {},
      account: rptParams.account,
      fDate: rptParams.dateFrom,
      tDate: rptParams.dateTo,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      ElmntShow: true,
      //totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
    };
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/RevCostRpt" });
    window.location.reload(false);
  };

  const printHandle3 = () => {
    
    if (!sum) {
      const info = {
        data: data?.costBalance,
        tot: data?.totals,
        from: 'مركز تكلفة',
        title: 'تقرير تحليل مركز التكلفة',
        auto: {},
        account: rptParams.accounts,
        fDate: rptParams.dateFrom, tDate: rptParams.dateTo,
        nDate: moment(new Date()).format('YYYY-MM-DD'),
        ElmntShow: true,
      }
      localStorage.setItem('iInfos', JSON.stringify(info));
      his.push({ pathname: '/ReviewAccount', });
      window.location.reload(false);

    } else {
      const info = {

        data: data?.costBalance,
        tot: data?.totals,
        from: 'مركز تكلفة',
        title: 'تقرير اجمالي مركز التكلفة',
        auto: {},
        kind_print: "2",
        account: rptParams.center,
        fDate: rptParams.dateFrom, tDate: rptParams.dateTo,
        nDate: moment(new Date()).format('YYYY-MM-DD'),
        ElmntShow: true,
        centerName:rptParams.center.name,
        //totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
      };
      localStorage.setItem("iInfos", JSON.stringify(info));
      his.push({ pathname: "/ReviewCostCenter" });
      window.location.reload(false);

    }
  }

  var excelHeaders = [
    { Label: "رقم الحساب", key: "col_COST_no", },
    { Label: "اسم الحساب", key: "col_COST_name", },
    { Label: "رصيد سابق مدين", key: "col_open_dept", },
    { Label: " رصيد سابق دائن", key: "col_open_credit", },
    { Label: " حركة مدين", key: "col_dept", },
    { Label: " حركة دائن", key: "col_credit", },
    { Label: "رصيد نهائي مدين", key: "col_balance_dept", },
    { Label: "رصيد نهائي دائن", key: "col_balance_credit", },
  ];
  var excelHeadersD = [
    { Label: "الرصيد", key: "balance" },
    { Label: "مدين", key: "dept" },
    { Label: "دائن", key: "credit" },
    { Label: "رقم المستند", key: "doc_no" },
    { Label: "تاريخ المستند", key: "date" },
    { Label: "رقم المرجع", key: "id" },
    { Label: "البيان", key: "notes" },
  ];
  let excel_name_d="CostCenterReports"
  let excel_name_sum="CostCenterSumReports"




  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };

  const setSumFooter = () => {
    // setSum(false);
    return (
      <div className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Grid container
              spacing={1}
              style={{
                paddingTop: "10px", fontFamily:
                  "Amiri-Regular", direction: i18next.language == "ar" ? "rtl" : "ltr"
              }}>
              <Grid item lg={2} md={3} sm={4} xs={6}>
                <BalanceCard
                  {...{
                    title: t("prev-balance"),
                    dept: data?.totals?.opetToltal_dept.toFixed(2),
                    credit: data?.totals?.opetToltal_credit.toFixed(2),
                  }}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={6}>
                <BalanceCard
                  {...{
                    title: t("acc-move"),
                    dept: data?.totals?.tot_dept.toFixed(2),
                    credit: data?.totals?.tot_credit.toFixed(2),
                  }}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={6}>
                <BalanceCard
                  {...{
                    title: t("acc-balance"),
                    dept: data?.totals?.end_TotalDept.toFixed(2),
                    credit: data?.totals?.end_TotalCredit.toFixed(2),
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </div>
    );
  }

  const setfooter = () => {

    return (
      <Grid className={classes.root3} item container>
        <Grid item lg={2} md={2} sm={2} xs={5}>
          {" "}
          <CardActionArea>
            <CardContent>
              <Typography color="textSecondary" component="p">
              </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>
        <Grid
          item
          lg={1}
          md={1}
          sm={1}
          xs={5}

        // style={styTitle}
        >
          <CardActionArea>
            <CardContent>
              <Typography color="textSecondary" component="p">
                {data?.totals?.totDept.toFixed(2)}
              </Typography>

              <Typography color="textSecondary" component="p">
                {"   "}
              </Typography>
              <Typography color="textSecondary" component="p">
                {"   "}
                {data?.totals?.totCredit - data?.totals?.totDept.toFixed(2)}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={5}>
          <CardActionArea>
            <CardContent>
              <Typography color="textSecondary" component="p">
                {/* {data.length} */}
                {data?.totals?.totCredit.toFixed(2)}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={7}>
          <CardActionArea>
            <CardContent>
              <Typography component="p">إجمالي الفترة المحددة </Typography>
              <Typography component="p">رصيد الفترة المحددة </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={5}>
          {" "}
          <CardActionArea>
            <CardContent>
              <Typography color="textSecondary" component="p">

              </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>

        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={7}

        // style={styTitle}
        >
          <CardActionArea>
            <CardContent>
              <Typography color="textSecondary" component="p"></Typography>
            </CardContent>
          </CardActionArea>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {t("تقرير تحليل مراكز التكلفة")}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: colors.red[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          {/* <SelectTypesFilter {...{ rptParams, setRptParams }} /> */}
          <CostCenterRptForm
            {...{
              AutoOptions,
              accounts,
              rptParams,
              setRptParams,
              onChangeData,
              onChangeAutoData,
              printHandle3,
              branch,
              setBranch,
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...inshst.bascData.all.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={() => viewHandle()}
              >
                {t("forms:t-detailed")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...inshst.bascData.all.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={() => viewSumHandle()}
              >
                {t("forms:t-total")}
              </Button>
            </Grid>
         
          <Grid item >
            {!sum 
            
            ?
           (  data?.costBalance && !fetching &&  data?.costBalance && (
              <CSVLink
                separator={";"}
                filename ={excel_name_d}
                data={ data?.costBalance}
                headers={excelHeadersD}
              >
                {" "}
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<TableChart />}
                  size="large"
                  style={{ ...inshst.bascData.all.iconBtn, borderRadius: 0 }}
                  className={[clasui.button]}
                >
                  {"تصدير اكسل"}{" "}
                </Button>
              </CSVLink>
            ))

            :
           (  data?.costBalance && !fetching &&  data?.costBalance && (
              <CSVLink
                separator={";"}
                filename ={excel_name_sum}
                data={ data?.costBalance}
                headers={excelHeaders}
              >
                {" "}
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<TableChart />}
                  size="large"
                  style={{ ...inshst.bascData.all.iconBtn, borderRadius: 0 }}
                  className={[clasui.button]}
                >
                  {"تصدير اكسل"}{" "}
                </Button>
              </CSVLink>
            ))
            }
            </Grid>
    

            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={{ ...inshst.bascData.all.iconBtn, borderRadius: 0 }}
                onClick={printHandle3}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={{ ...inshst.bascData.all.iconBtn, borderRadius: 0 }}
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.green[300]} />
            </Grid>
          </Grid>
          {!sum
            ? !fetching && (
              <CostCenterRptTable
                {...{
                  data: data?.costBalance ? data.costBalance : [],
                }}
              />
            )
            : !fetching && (
              <CostCenterRptTable3
                {...{
                  data: data?.costBalance ? data.costBalance : [],
                }}
              />
            )}
        </Container>
      </Typography>
      {!sum
        ? !fetching && <div>{setfooter()}</div>
        :
        !fetching && <div>{setSumFooter()}</div>

      }
    </div>
  );
}
