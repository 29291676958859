import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStylesTF, useStylesLbl } from "../../../../../St/comps/UseStyle";
import { insh } from "../../../../../Context/InshData";
import { useTranslation } from "react-i18next";

export default function ExternalFormSub(props) {
  const { object, onChange } = props;

  const clasTF = useStylesTF();
  const claslbl = useStylesLbl();

  const { t } = useTranslation(["forms"]);
  return (
    <React.Fragment>
      <Grid container style={{ padding: 10 }} spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="check_no"
            label={t("check-no")}
            className={object.type == "1" ? null : clasTF.rootEDS}
            onChange={(e) =>
              onChange(null, { name: "check_no", value: e.target.value })
            }
            name="check_no"
            value={object.check_no}
            disabled={object.type == "1"}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="check_date"
            label={t("check-date")}
            className={object.type == "1" ? null : clasTF.rootEDS}
            type="date"
            format="YYYY-MM-DD"
            InputLabelProps={{ shrink: true }}
            name="check_date"
            value={object.check_date}
            onChange={(e) =>
              onChange(null, { name: "check_date", value: e.target.value })
            }
            disabled={object.type == "1"}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
            disabled={object.type == "1"}
            value={object.check_state}
            onChange={(event, newValue) => {
              onChange(null, { name: "check_state", value: newValue });
            }}
            id="controllable-states-demo"
            size="small"
            options={insh.autos.CheckStatus}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCostcenter"
                {...params}
                label={t("check-state")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="checkbank"
            label={t("check-drawn")}
            className={object.type == "1" ? null : clasTF.rootEDS}
            onChange={(e) =>
              onChange(null, { name: "checkbank", value: e.target.value })
            }
            name="checkbank"
            value={object.checkbank}
            disabled={object.type == "1"}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
