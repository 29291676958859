import React, { useEffect, useState } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { mt } from "../../../../St/comps/cStyle";
import { reFormatDate } from "../../../../utils/utils";
import { insh } from "../../../../Context/InshSt";
import { colors, Container, makeStyles, Table, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useRptCa, useRptMain } from '../../../../St/Reviews/UseStyle';
export function Totals({ data }) {
    const styf = { fontWeight: "1200" };
    const cls = useRptAF();
    const totals = Rept.tot;
    const { t } = useTranslation(["forms"]);

    const styleE = {
        border: "solid 1px ",
        borderColor: "black",
        textAlign: "center",
        paddingTop: "0.1rem",
        paddingBottom: "0.1rem",
        fontSize: "0.8rem",
        color: "black",
        fontWeight: "bold",
    };
    const styleTo = {
        border: "solid 1px ",
        borderColor: "black",
        textAlign: "center",
        fontSize: "0.8rem",
        fontWeight: "bold",
    };
    return (
        <Grid item container >
            <Grid item container style={{ fontFamily: "Amiri-Regular", marginBottom: "0.5rem", marginTop: "0.7rem" }}>
                <Grid item xs={7} style={styleE}>
                    {'إجمالي المبيعات - المرتجع'}
                </Grid>
                <Grid item xs={5} style={styleE}>
                    {Rept.tot.totSal?.toFixed(2)}
                </Grid>
                <Grid item xs={7} style={styleE}>
                    {'إجمالي الآجل'}
                </Grid>
                <Grid item xs={5} style={styleE}>
                    {Rept.tot.totAjel?.toFixed(2)}
                </Grid>
                <Grid item xs={7} style={styleE}>
                    {'صافي نقدي'}
                </Grid>
                <Grid item xs={5} style={styleE}>
                    {Rept.tot.totNetAmount?.toFixed(2)}
                </Grid>
                <Grid item xs={7} style={styleE}>
                {'صافي شبكة + بنك'}                </Grid>
                <Grid item xs={5} style={styleE}>
                    {Rept.tot.totNetAndBank?.toFixed(2)}
                </Grid>
                <Grid item xs={7} style={styleE}>
                    {' صافي '}{t("paid")}
                </Grid>
                <Grid item xs={5} style={styleE}>
                    {(parseFloat(Rept.tot.totNetAmount) + parseFloat(Rept.tot.totNetAmount)).toFixed(2)}
                </Grid>
            </Grid>
        </Grid>
    );
}



function BodyClose() {
    const { t } = useTranslation(["forms", "translation"]);
    const cls = useRptAF();
    const clas = useRptCa();
    const [data, setData] = useState(Rept.data);

    const cellS = {

        padding: "0px 0.1px",
        textAlign: "-webkit-center",
        margin: "0px",
        whiteSpace: "normal",
        border: "solid 0.1px",
        fontSize: "0.8rem",
        width: "250px",
        textAlign:"right",
        paddingRight:"0.7rem",
        fontFamily: "Amiri-Regular",
        fontWeight: "bold",
        paddingTop:"0.1rem",
        paddingBottom:"0.1rem"
    };

    var columns = [
        { title: "", field: "name", headerStyle: stElemnt.EvDay.header, cellStyle: cellS, },
        { title: "", field: "TotalFloat", headerStyle: stElemnt.EvDay.header, },
    ];

    const newData = data.map((row, i) => {
        return {
            ...row,
            TotalFloat: parseFloat(row.TotalFloat).toFixed(2),
        };
    });
    return (
        <Grid item container style={{ fontFamily: "Amiri-Regular" }}>
            <Grid item container style={{ direction: "rtl", paddingTop: "0.7rem" }}>

                <Grid item container className={clas.Bdycntr}>
                    <Grid item container style={{ justifyContent: 'center', }}>
                        <Grid item xs={6} >{'من تاريخ'}</Grid>
                        <Grid item xs={6} >{Rept.fDate}</Grid>
                        <Grid item xs={6} >{'إلى تاريخ'}</Grid>
                        <Grid item xs={6} >{Rept.tDate}</Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}

                >
                    <MaterialTable
                        style={{ boxShadow: "none" }}
                        // style={{direction:'ltr'}}
                        options={{
                            showTitle: false,
                            toolbar: false,
                            sorting: false,
                            selection: false,
                            // ToolBar Search
                            searchFieldAlignment: "left",
                            searchFieldVariant: "outlined",
                            // ToolBar header
                            showSelectAllCheckbox: false,
                            showTextRowsSelected: false,
                            pageSize: 5,


                            paginationType: "stepped",
                            showFirstLastPageButtons: false,
                            paging: false,
                            headerStyle: stElemnt.EvDay.header,

                            cellStyle: {
                                ...stElemnt.AF.cell,
                                padding: "0px 1px",
                                // fontSize: 8,
                            },

                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: (
                                    <h5 style={{ color: "GrayText" }}>
                                        لا توجد ارصدة بعد في هذا الحساب
                                    </h5>
                                ),
                            },
                        }}
                        columns={columns}
                        data={newData}
                    />
                    {<Totals data={data ?? []} />}

                </Grid>
            </Grid>


        </Grid>
    );
}

export default BodyClose;
