import {useState, useEffect} from 'react';
import throttle from 'lodash/throttle'

const device = (w) => {
  if(w < 320) return '1'; else if(w >= 320 && w < 720 ) return '2';
  else if(w >= 720 && w < 1024) return '3'; else if(w >= 1024) return '4';
};

const useBreakpoint = () => {
  const [lodash, setLodash] = useState(() => device(window.innerWidth));
  useEffect(() => {
    const innerWidth = throttle(function() { setLodash(device(window.innerWidth))}, 200); 
    window.addEventListener('resize', innerWidth);
    return () => window.removeEventListener('resize', innerWidth);
  }, []);

  return lodash;
}
export default useBreakpoint;