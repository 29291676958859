import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../../Label/Lbl";

import axios from "axios";
import { uri } from "../../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import MaterialTable from "material-table";
import n2words from "n2words";
import { insh } from "../../../../Context/InshData";
// Ust
import { useInvCa, useInvMain } from "../../../../St/Reviews/UseStyle";
// csG
// import {stElemnt} from '../../St/Reviews/cStyle'
import Paper from "@material-ui/core/Paper";

const Body = () => {
  const clasm = useInvMain();
  const clas = useInvCa();
  // const clasB = useInvBodyCa();
  const [AE, setAE] = useState(null);

  const locinf = JSON.parse(localStorage.getItem("iInfos")) || {};
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};

  const [sta, setSta] = useState({ info: insh.RptInv, tot: {}, StngIA: {} });
  const [list, setList] = useState([]);
  const getInfo = async () => {
    const tb = {
      Rpt_Inv: true,
      SettInvsApp: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
        iFoundid: 1,
      },
      autos: {},
    };
    //  console.log('Rpt__tb'); console.log(tb)
    //  alert(`${uri}Res`)
    axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        console.log("res.data.RptInv");
        console.log(res.data);
        setSta({
          info: res.data.RptInv,
          tot: res.data.infoBill,
          StngIA: res.data.SettInvsApp,
        });

        // console.log('res.data.RptInv'); console.log(res.data);
      })
      .catch((error) => {});

    setAE(false);
  };

  useEffect(() => {
    getInfo();
  }, []);

  const totalsTitleA_ = () => {
    return (
      <Grid item container className={clas.BdytotTitle}>
        {/* <Grid item xs={6}className={clasm.minlft} >{txtA.totalAmount}</Grid>  */}
        {/* <Grid item xs={6} className={clasm.minrit}>{txtE.totalAmount}</Grid> */}
      </Grid>
    );
  };

  const totalsTitleE_ = () => {
    return (
      // <Grid item container className={clas.BdytotTitle}>
      <Grid item container>
        {/* <Grid item xs={6}className={clasm.minlft} >{txtE.totalAmount}</Grid>  */}
        {/* <Grid item xs={6} className={clasm.minrit}>{txtA.totalAmount}</Grid> */}
      </Grid>
    );
  };

  const totalsContntA_ = () => {
    return (
      <Grid item container className={clas.BdytotSub}>
        <Grid item xs={8} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {sta.StngIA.amountWithVatA}
          </Grid>
          <Grid item xs={12}>
            {sta.StngIA.amountWithVatE}
          </Grid>
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntrVal}>
          {sta.tot.amountWithVat}
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntr}>
          {sta.StngIA.totdiscountA + " " + sta.StngIA.totdiscountE}
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntrVal}>
          {sta.tot.discount}
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {sta.StngIA.amountWithVat2A}
          </Grid>
          <Grid item xs={12}>
            {sta.StngIA.amountWithVat2E}
          </Grid>
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntrVal}>
          {sta.tot.amountWithVat}
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {sta.StngIA.amountOfVatA}
          </Grid>
          <Grid item xs={12}>
            {sta.StngIA.amountOfVaEt}
          </Grid>
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntrVal}>
          {sta.tot.amountOfVat}
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {sta.StngIA.tot_netA}
          </Grid>
          <Grid item xs={12}>
            {sta.StngIA.tot_netE}
          </Grid>
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntrVal}>
          {sta.tot.tot_net}
        </Grid>
      </Grid>
    );
  };

  const totalsContntE_ = () => {
    return (
      <Grid item container className={clas.BdytotSub}>
        <Grid item xs={8} className={clas.BdytotCntrVal}>
          {locinf.auto.empName}
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {txtA.rpt[0].d[0]}
          </Grid>
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntrVal}>
          {"--------"}
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntr}>
          {txtA.rpt[0].d[1]}
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntrVal}>
          {"--------"}
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {txtA.rpt[0].d[2]}
          </Grid>
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntrVal}>
          {"--------"}
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {txtA.rpt[0].d[3]}
          </Grid>
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntrVal}>
          {"--------"}
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {txtA.rpt[0].d[4]}
          </Grid>
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntrVal}>
          {"--------"}
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {txtA.rpt[0].d[5]}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item container className={clas.Bdyroot}>
      <Grid item container className={clas.BdyundrHeaderTF}>
        <Grid item xs={12} className={[clas.BdytitleHedDw, clas.BdytitleHed]}>
          <Typography className={[clas.contnt, clasm.minclr]}>
            {locinf.title}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container className={clas.Bdycntr}>
        {!sta.StngIA.showColDHd ? (
          <Grid item xs={4} className={clas.BdyborBtR}>
            {txtA.rpt[1].t[0]}
          </Grid>
        ) : null}
        <Grid item xs={sta.StngIA.ncol} className={clasm.minborB}>
          <Paper className={clasm.minclr}>{locinf.data.id}</Paper>
        </Grid>
        <Grid
          item
          xs={sta.StngIA.ncol}
          className={clas.BdyborBtL}
          style={{ fontSize: 9 }}
        >
          {txtA.rpt[0].t[0] + locinf.from}
        </Grid>

        {!sta.StngIA.showColDHd ? (
          <Grid item xs={4} className={clas.BdyborBtR}>
            {txtA.rpt[1].t[1]}
          </Grid>
        ) : null}
        <Grid item xs={sta.StngIA.ncol} className={clasm.minborB}>
          <Paper className={clasm.minclr}>{locinf.Date}</Paper>
        </Grid>
        <Grid item xs={sta.StngIA.ncol} className={clas.BdyborBtL}>
          {txtA.rpt[0].t[1]}
        </Grid>

        {!sta.StngIA.showColDHd ? (
          <Grid item xs={4} className={clas.BdyborBtR}>
            {txtA.rpt[1].t[2]}
          </Grid>
        ) : null}
        <Grid item xs={sta.StngIA.ncol} className={clasm.minborB}>
          <Paper className={clasm.minclr}>{locinf.data.ref_no}</Paper>
        </Grid>
        <Grid item xs={sta.StngIA.ncol} className={clas.BdyborBtL}>
          {txtA.rpt[0].t[2]}
        </Grid>

        {!sta.StngIA.showColDHd ? (
          <Grid item xs={4} className={clas.BdyborBtR}>
            {txtA.rpt[1].t[3]}
          </Grid>
        ) : null}
        <Grid item xs={sta.StngIA.ncol} className={clasm.minborB}>
          <Paper className={clasm.minclr}>{locinf.auto.center}</Paper>
        </Grid>
        <Grid item xs={sta.StngIA.ncol} className={clas.BdyborBtL}>
          {txtA.rpt[0].t[3]}
        </Grid>
      </Grid>

      <Grid item container className={clas.Bdycntr} style={{ marginTop: 10 }}>
        <Grid item xs={5}>
          <Paper className={clasm.minclr}>{locinf.auto.custName}</Paper>
        </Grid>
        <Grid item xs={2}>
          {":"}
        </Grid>
        <Grid item xs={5}>
          {locinf.custN}
        </Grid>

        <Grid item xs={5}>
          <Paper className={clasm.minclr}>{"SR " + locinf.data.val}</Paper>
        </Grid>
        <Grid item xs={2}>
          {":"}
        </Grid>
        <Grid item xs={5}>
          {txtA.rpt[0].i[1]}
        </Grid>

        <Grid item xs={12} style={{ color: "#ff0000" }}>
          {txtA.rpt[0].i[2]}
        </Grid>
      </Grid>

      <Grid item container className={clas.BdytablSnd}>
        <Grid item xs={5} className={clas.bG}>
          {txtA.rpt[1].c[3]}
        </Grid>
        <Grid item xs={2} className={clas.bG}>
          {txtA.rpt[1].c[2]}
        </Grid>
        <Grid item xs={2} className={clas.bG}>
          {txtA.rpt[1].c[1]}
        </Grid>
        <Grid item xs={2} className={clas.bG}>
          {txtA.rpt[1].c[0]}
        </Grid>

        <Grid item xs={5} className={clas.bG}>
          {txtA.rpt[0].c[3]}
        </Grid>
        <Grid item xs={2} className={clas.bG}>
          {txtA.rpt[0].c[2]}
        </Grid>
        <Grid item xs={2} className={clas.bG}>
          {txtA.rpt[0].c[1]}
        </Grid>
        <Grid item xs={2} className={clas.bG}>
          {txtA.rpt[0].c[0]}
        </Grid>
      </Grid>

      <Grid item container className={clas.Browskh}>
        {/* {sta.info.map((item,i)=> */}
        {/* <Grid item container key={i}> */}
        <Grid item className={clas.bG} xs={2}>
          {locinf.data.val}
        </Grid>
        <Grid item className={clas.bG} xs={2}>
          {locinf.data.check_no}
        </Grid>
        <Grid item className={clas.bG} xs={2}>
          {locinf.Date}
        </Grid>
        <Grid item className={clas.bG} xs={5}>
          {locinf.auto.custName}
        </Grid>
        {/* <Grid item className={clas.BdyrowSy} xs={12}>{'item.symbol'}</Grid>  */}
        {/* <Grid item xs={12}>{'--'}</Grid>  */}

        {/* 
           يتم تضبيطها في اعداد حسب التغغير عربي او E
          <Grid item xs={3}>{item.tot_net_curr}</Grid>
          <Grid item xs={2}>{item.val1}</Grid>
          <Grid item xs={2}>{item.discount}</Grid>
          <Grid item xs={5}>{item.new_exchange_price}</Grid>
          <Grid item xs={12}>{item.symbol}</Grid>  */}

        {/* </Grid> */}
        {/* )} */}
      </Grid>

      <Grid item container className={clas.Bdycntr} style={{ marginTop: 10 }}>
        {/* <Chip style={styChipVTotText} label={'فقط '+n2words(Math.ceil(this.state.underheader.cash_part * 100)/100, {lang: 'ar'})+ ''} clickable color="secondary" deleteIcon={<DoneIcon />}/> */}

        <Grid item xs={7}>
          <Paper className={clasm.minclr}>
            {/* {''+n2words(Math.ceil(locinf.data.val * 100)/100, {lang: 'ar'})+ ''} */}
            {n2words(locinf.data.val, { lang: "ar" }) + " ريال"}
          </Paper>
        </Grid>
        <Grid item xs={1}>
          {":"}
        </Grid>
        <Grid item xs={4}>
          {"القيمة بالحروف"}
        </Grid>

        <Grid item xs={7}>
          <Paper className={clasm.minclr}>{locinf.data.notes}</Paper>
        </Grid>
        <Grid item xs={1}>
          {":"}
        </Grid>
        <Grid item xs={4}>
          {"البيان"}
        </Grid>
      </Grid>

      {AE ? totalsTitleA_() : totalsTitleE_()}
      <Grid item container style={{ marginTop: 10 }}></Grid>
      {AE ? totalsContntA_() : totalsContntE_()}
      {/* totalsTitleA_()
totalsContntA_()
} */}
      {/* <br/> <br/> <br/> <br/> */}

      {/* <Grid item container className={clas.BdytotSub}>
      <Grid item xs={5} className={clas.BdytotLf}>{1}</Grid> 
      <Grid item xs={5} className={clas.BdytotCntr}>{1}</Grid> 
      <Grid item xs={2} className={clas.BdytotRit}>{1}</Grid>
    </Grid> */}

      {/* <Grid item container>
    <Grid item xs={12} className={clas.BdymtTabl}>
     <MaterialTable style={{boxShadow:'none'}}
            //style={{direction:'ltr'}}
          options={{ 
            showTitle:false,
            toolbar: false,
            sorting: false,
            selection: false,
            // ToolBar Search
            searchFieldAlignment:"left",
            searchFieldVariant:"outlined",
            // ToolBar header
            showSelectAllCheckbox:false,
            showTextRowsSelected:false, 
            // body
            // rowStyle:(data,i)=>i%2!=0?{background:'#038b0321'}:null,
            // footer
            pageSize:5,
            paginationType:"stepped",
            showFirstLastPageButtons:false,
            
            paging:false,
            headerStyle: stElemnt.AF.header,
            cellStyle: stElemnt.AF.cell,
           }}
           
    
          localization={{   body:{ emptyDataSourceMessage:<h1></h1> ,}, }}
          
          
              columns={columns}
              data={sta.info}

             />
    </Grid>
    </Grid> */}

      {/* <Grid container>
    </Grid> */}
    </Grid>
  );
};
export default Body;
