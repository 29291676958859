import React, { useState, useEffect } from 'react';

import useFetchObjectByPost from '../../../../../components/Hooks/useFetchObjectByPost';
import useFetchURL from '../../../../../components/Hooks/useFetchURL';
import { sto } from '../../../../../Context/InshData';
import { uri } from '../../../../../help/Api';
import ProfitMargin from './ProfitMargin';
import LoadingPage from '../../../../../services/LoadingPage';
const defaultAutos ={
  Safes:[],
}

export default function ProfitMarginCon() {

      const tb = {
                  wait:true,
                  Safes:true, 
                  Customers: true,
                  Users:true,
                  Stocks: true,
                  salesmen: true,
                  groups:true,
                  stor:sto,
                  Branches: true,
                  parms:{safe:-1},
            }
       const url =uri+'Auto'+'/custTypeM';
       const {data: CustomerOptions , fetching:fetchCust} = useFetchURL({...{url, dependencyArray:[]}})
        const {object: AutoOptions, fetching:fetchAll} = useFetchObjectByPost(uri+'Rept',tb); 

  if(fetchCust || fetchAll || !AutoOptions?.Safes || CustomerOptions?.length ==0 )
    return <LoadingPage />

  return (
  <ProfitMargin {...{
    AutoOptions: AutoOptions?.Safes? AutoOptions: defaultAutos,
    CustomerOptions: CustomerOptions?.length>0 ? CustomerOptions: [],
    isPurches: false,

  } 
  }/>
  );




};