import axios from "axios";
import { uri } from "../help/Api";

export const TASWYA_URL = uri + "Taswya";

export function list() {
  return axios.get(TASWYA_URL);
}

export function getNewId() {
  return axios.get(TASWYA_URL + "/newId");
}

export function create(data) {
  return axios.post(TASWYA_URL, data);
}

export function update(data) {
  return axios.put(TASWYA_URL, data);
}
export function remove(id) {
  return axios.delete(TASWYA_URL + `/${id}`);
}

export default { getNewId, list, getNewId, create, update, remove };
