import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../services/LoadingPage";
import ItemsMove from "./ItemsMove";
const defaultAutos = {
  Safes: [],
};

export default function ItemsMoveCon() {
  const tb = {
    wait: true,
    Safes: true,
    Currencies: true,
    Branches: true,
    stor: sto,
    parms: {
    safe:-1,
    item:-1,
    },
  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
  );

  if (
    fetchAll ||
    !AutoOptions?.Safes 
  )
    return <LoadingPage />;

  return (
    <ItemsMove
      {...{
        AutoOptions: AutoOptions?.Safes ? AutoOptions : defaultAutos,
      }}
    />
  );
}
