import { NavigateNext } from "@material-ui/icons";
import axios from "axios";
import jsPDF from "jspdf";
import moment from "moment";
import pdfExport from "../../../../components/invoice-generator/pdfExport";
import { sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";
import { callAddFont } from "../../../../Reviews/amiri-font";
import { GetA4Setings } from "../../../../services/settings-services";

export const handleDirectPrintTemp2 = async (locInv, count, next) => {
  let printers = [];
  jsPDF.API.events.push(["addFonts", callAddFont]);
  const lngth = count * 11 + 190;
  const lnth_kitchen = count * 11 + 40;
  var doc = new jsPDF("p", "mm", [100, lngth]);

  var doc_kitchen = new jsPDF("l", "mm", [100, lnth_kitchen]);

  doc.setFont("Amiri-Regular", "normal");
  doc.setFontSize(11);
  doc_kitchen.setFont("Amiri-Regular", "normal");
  doc_kitchen.setFontSize(11);
  var y = 0;
  const tb = {
    Rpt_Inv: true,
    GetFoundation: true,
    SettInvsApp: true,

    parms: {
      lprocid: locInv.proc_id,
      ibranchid: locInv.branch,
      sProcTypeInvProc: locInv.ProcTypeInvProc,
      iFoundid: 1,
      withPrinters: true,
      branch: sto.branchi,
    },
    autos: {},
  };

  // console.log("athe isda", );
  await axios
    .post(`${uri}Res`, tb)
    .then(async (res) => {
      console.log("res.data.RptInv");
      console.log(res.data);
      const info = res.data.RptInv;
      const tot = res.data.infoBill;
      const foundInfo = res.data.Found;
      printers = res.data?.printers?.map((p) => p[0]);
      console.log(printers);
      const imgData = `data:image/jpeg;base64,${foundInfo.Logo}`;
      doc.addImage(imgData, "JPEG", 22, y, 55, 35);
      y += 40;
      doc.text(foundInfo.nameA, 50, y, null, null, "center");
      y += 5;
      doc.text("Phone No:" + foundInfo.Mobile, 50, y, null, null, "center");
      y += 5;
      doc.text("VAT# " + foundInfo.tax_no, 50, y, null, null, "center");
      y += 5;
      doc.text(
        tot.tax_no_cust === ""
          ? "فاتورة ضريبية مبسطة"
          : "فاتورة ضريبية" + foundInfo.tax_no,
        50,
        y,
        null,
        null,
        "center"
      );
      y += 5;
      // const height = info.length * 11 + 50;
      // doc.addPage([100, height], "p");
      // console.log(":the data is", info);
      // y = 0;
      doc.text(
        locInv.invN + " " + tot.inv_type_name + " ",
        50,
        y,
        null,
        null,
        "center"
      );
      y += 10;
      doc.setFontSize(16);
      doc.text(tot.id.toString(), 50, y, null, null, "center");
      y += 7;
      doc.setFontSize(9);
      doc.text(
        moment(tot.date).format("h:mm A YYYY/MM/DD").toString(),
        95,
        y,
        null,
        null,
        "right"
      );

      y += 3;
      doc.rect(5, y, 90, count * 10 + 10);

      doc.text("اسم الصنف", 85, y + 5, null, null, "right");

      doc.text("الكمية ", 45, y + 5, null, null, "center");
      doc.text("السعر ", 32, y + 5, null, null, "center");
      doc.text("الإجمالي ", 15, y + 5, null, null, "center");
      doc.line(50, y, 50, y + count * 10 + 10);
      doc.line(40, y, 40, y + count * 10 + 10);
      doc.line(25, y, 25, y + count * 10 + 10);
      //   y += 7;
      y += 5;
      info?.map((item, i) => {
        doc.line(5, y + 5, 95, y + 5);
        y += 10;

        doc.text(item.symbol.toString(), 94, y, null, null, "right");
        doc.text(item.val1.toString(), 45, y, null, null, "center");
        doc.text(
          item.new_exchange_price?.toString(),
          30,
          y,
          null,
          null,
          "center"
        );
        // y += 4;
        doc.text(item.tot_net_curr.toString(), 15, y, null, null, "center");
      });
      //   doc.line(5, y, 95, y);
      doc.setFontSize(12);
      var subTotal = (tot.tot_net - tot.amountOfVat + tot.discount).toFixed(2);
      y += 10;
      doc.text("الاجمالي قبل الضريبة", 90, y, null, null, "right");
      doc.text(
        (tot.tot_net - tot.amountOfVat + tot.discount)?.toFixed(2).toString(),
        10,
        y
      );
      y += 8;
      doc.text("مجموع الخصومات", 90, y, null, null, "right");
      doc.text(tot.discount?.toFixed(2).toString(), 10, y);
      y += 8;
      doc.text("الاجمالي الخاضع للضريبة", 90, y, null, null, "right");
      doc.text((tot.tot_net - tot.amountOfVat)?.toFixed(2).toString(), 10, y);

      var amVat = Number(tot.amountOfVat).toFixed(2);
      y += 8;
      doc.text("VAT  ضريبة القيمة المضافة", 90, y, null, null, "right");
      doc.text(amVat.toString(), 10, y);
      y += 5;
      doc.line(5, y, 95, y);
      y += 6;
      doc.text(tot.tot_net.toString(), 10, y);
      doc.text("Orand Total الإجمالي", 90, y, null, null, "right");

      y += 10;
      doc.setFontSize(11);
      doc.text(foundInfo.Address.toString(), 50, y, null, null, "center");

      // Kitchen data
      doc_kitchen.setFontSize(12);
      doc_kitchen.text("رقم الفاتورة: ", 95, 10, null, null, "right");
      doc_kitchen.text(tot.id.toString(), 50, 10, null, null, "center");
      doc_kitchen.text(
        moment(tot.date).format("h:mm A YYYY/MM/DD").toString(),
        95,
        17,
        null,
        null,
        "right"
      );
      doc_kitchen.rect(5, 22, 90, count * 10 + 10);

      doc_kitchen.text("اسم الصنف", 85, 27, null, null, "right");
      doc_kitchen.text("الكمية ", 15, 27, null, null, "center");
      doc_kitchen.line(20, 22, 20, 22 + count * 10 + 10);

      var y1 = 27;
      //   y += 7;
      info?.map((item, i) => {
        doc_kitchen.line(5, y1 + 5, 95, y1 + 5);
        y1 += 10;

        doc_kitchen.text(item.symbol.toString(), 94, y1, null, null, "right");

        // y += 4;
        doc_kitchen.text(item.val1.toString(), 15, y1, null, null, "center");
      });
    })
    .catch((error) => {});

  await axios
    .get(uri + `InvForFatora?proc_id=${locInv.proc_id}`)
    .then(async (res) => {
      y += 5;
      const imgF = `data:image/jpeg;base64,${res.data.qr_image}`;
      doc.addImage(imgF, "JPEG", 30, y, 40, 40);
      next();
    })
    .catch((error) => {
      //   setGenerating(false);
    });
  const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};
  const pdf = doc.output("blob");
  const myFile = new File([pdf], "test.pdf", { type: pdf.type });
  const formData = new FormData();
  formData.append("PdfFile", pdf);
  formData.append(
    "PrinterPath",
    "\\\\" + premiApp.deviceName + "\\" + premiApp.printerName
  );
  await doc.save("restaurant invoice");

  await axios
    .post("http://localhost:7000/print/from-pdf", formData, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log("pdf printer successfully");
    })
    .catch((err) => {
      console.log("error", err);
    });

  await Promise.all(
    printers?.map(async (pr) => {
      const pdf_kitchen = doc_kitchen.output("blob");
      const formData_kitchen = new FormData();
      formData_kitchen.append("PdfFile", pdf_kitchen);
      formData_kitchen.append(
        "PrinterPath",
        "\\\\" + premiApp.deviceName + "\\" + pr
      );
      await axios
        .post("http://localhost:7000/print/from-pdf", formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("pdf printer successfully");
        })
        .catch((err) => {
          console.log("error", err);
        });
      await doc_kitchen.save("kitchen inv");
    })
  );
};

const printInvoice = async (locInv, nextStep) => {
  var data, inv;

  const tb = {
    Rpt_Inv: true,
    GetFoundation: true,
    // SettInvsApp: true,
    parms: {
      lprocid: locInv.proc_id,
      ibranchid: locInv.branch,
      sProcTypeInvProc: locInv.ProcTypeInvProc,
      iFoundid: 1,
      branch: sto.branchi,
    },
    autos: {},
  };

  await axios
    .post(uri + "Res", tb)
    .then((res) => {
      console.log("the data is", res.data);
      data = res.data;
    })
    .catch((error) => {
      console.log("erooooorroro");
    });

  await axios
    .get(uri + `InvForFatora?proc_id=${locInv.proc_id}`)
    .then(async (res) => {
      inv = res.data;
    })
    .catch((error) => {});

  nextStep(data, inv);
};

export const PrintInvoiceDirectly = async (locInv, newWindow = true, next) => {
  const invoice = {
    uuid: 44,
    title: "Invoice No:",
    invoiceNo: 66,
    invoiceDate: new Date().toLocaleDateString("en-CA"),
    dueDate: "",
    invoiceFrom: "",
    invoiceTo: "",
    discounts: 0,
    shipping: 0,
    currency: "USD",
    taxation: "GST",
  };
  await printInvoice(locInv, async (data, inv) => {
    const a4Settings = GetA4Setings();
    let newObj = {
      ...invoice,
      ...a4Settings,
      found: data?.Found,
      imgSrc: `data:image/jpeg;base64,${data?.Found?.Logo}`,
      footNote: data?.Found?.BillNotes,
      items: data?.RptInv,
      tot: data?.infoBill,
      terms: inv?.notes,
      locInv: { invN: "فاتورة مبيعات" },
      premiApp: { hidePayType: false },
      inv: inv,
    };
    console.log("the new data is", newObj);
    pdfExport.printInvoice(newObj, newWindow);
  })
    .then()
    .catch((res) => {
      next();
    });
};
