import React from "react";
import { uri } from "../../../help/Api";
import { useEffect } from "react";
import BackdropScI from "../../../services/BackdropScI";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import DFSettingsForm from "./default-settings-form";
import DefaultSettings from "./default-settings";
// import Safes from "./safes";

export default function DefaultSettingsCon(){

    const url = uri+'SysSettings';

    const {data, fetching, fetchURL} =  useFetchURL({...{
        url,
    }});

    useEffect(()=>{
        console.log('data  is', data )
    })

      if(fetching || !data )
        return <BackdropScI />
    return (
        <DefaultSettings {
            ...{
                data,
                fetchURL,
            }
        } />
    )
}

   