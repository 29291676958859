import React from "react";
import { uri } from "../../../help/Api";
import useFetchObjectByPost from "../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import SalesPerson from "./SalesPerson";
import LoadingPage from "../../../services/LoadingPage";

export default function SalesPersonCon() {
  const tb = {
    wait: true,

    salesmen: true,
    parms: { tree: "sub" },
    autos: {},
  };
  const url = uri + "Res";

  const {
    object: data,
    fetching: fetchinData,
    fetch: fetchURL,
  } = useFetchObjectByPost(url, tb);

  useEffect(() => {
    console.log("data  is", data);
  });

  if (fetchinData || !data?.table?.resData) return <LoadingPage />;
  return (
    <SalesPerson
      {...{
        data: data?.table?.resData ?? [],
        fetchinData,
        fetchURL,
      }}
    />
  );
}
