import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { uri } from "../../../help/Api";
import Swipeable from "../../../Fronts/Main/Swipeable";
import TemplateGrid from "./templateGride";
import PdfSettings from "./pdfSettings";
import PrintCasher from "../PrintCasher";
import Settings from "./settings/settings";
import InvoiceGenerator from "../../Invs/BackEnd/components/invoice-generator/invoice-generator";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // direction: "rtl",
    margin: 10,
  },
  textField: {
    //width:80,
    margin: 10,
    marginRight: 5,
  },
  buttons: {
    marginRight: 20,
    marginLeft: 5,
    marginTop: 10,
    direction: "ltr",
  },
  input: {
    display: "none",
  },
  imageLogo: {
    marginRight: 20,
    marginTop: 5,
    marginBottom: 10,
    textAlign: "center",
  },
  textFields: {
    //width:80,
    marginRight: 20,
  },
  textField3: {
    //width:80,
    direction: "rtl",
    marginBottom: 5,
  },
  GridButton: {
    marginBottom: 40,
  },
}));

export default function InvoiceSettings() {
  const clasess = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [data, setData] = useState({
    id: null,
    nameA: "",
    nameE: "",
    FieldA: "",
    FieldE: "",
    bsn_no: "",
    licence_no: "",
    Address: "",
    Tel: "",
    Mobile: "",
    Fax: "",
    Email: "",
    website: "",
    Logo: "",
    footer_img: "",
    Exchangeval: null,
    tax: null,
    port: "",
    tax_no: "",
    BillNotes: "",
    BillNotes1: "",
    BillNotes2: "",
    BillNotes3: "",
    Disc1_header_ar: "",
    Disc2_header_ar: "",
    Disc3_header_ar: "",
    Disc4_header_ar: "",
    Disc1_header_en: "",
    Disc2_header_en: "",
    Disc3_header_en: "",
    Disc4_header_en: "",
    Disc1_footer_ar: "",
    Disc2_footer_ar: "",
    Disc3_footer_ar: "",
    Disc4_footer_ar: "",
    Disc1_footer_en: "",
    Disc2_footer_en: "",
    Disc3_footer_en: "",
    Disc4_footer_en: "",
    MailBox: "",
    tax_ats: null,
    invval_ats: "",
    actv_elec: null,
    CustDisc: "",
    finyear_date1: null,
    finyear_date2: null,
    showimg: null,
    showvat: null,
    showdisc: null,
    showafterdisc: null,
    showtax: null,
    bank1: "",
    acc1: "",
    bank2: "",
    acc2: "",
    bank3: "",
    acc3: "",
    AddressEn: "",
    chkitemquan: null,
    msg: "",
    offer_type: null,
    VAT_Type: null,
    is_tab3: null,
    tab3val: null,
    consume_type: null,
    img: "",
    cnt: "",
    img2: "",
    contract_no: "",
    activity_name: "",
    city: "",
    branchName: "",
    sales_man: "",
    StartWorkDate: "",
    EndWorkDate: "",
    CStartWorkDate: "",
    CEndWorkDate: "",
    firstAlertDate: "",
    SecondAlertDate: "",
    ThirdAlertDate: "",
    StopWorkingDate: "",
    CStopWorkingDate: "",
    is$sys: "",
    is$$dessys: "",
    is$$$dy: "",
    server_name: "",
    point_name: "",
    status_type: "",
    computer_type: "",
    tax_no_build: "",
    tax_name_street: "",
    tax_name_district: "",
    tax_name_city: "",
    tax_name_country: "",
    tax_zip_code: "",
    tax_additional_address_no: "",
    tax_vat_registration_no: "",
  });

  let typeImg = "jpeg";
  const [fileImg, setFileImg] = useState({
    base64URL: "",
    code64: "data:image/" + typeImg + ";base64,",
    code8: "",
    baseURL: false,

    // file:null
  });
  const [fileFooterImg, setFileFooterImg] = useState({
    base64URL: "",
    code64: "data:image/" + typeImg + ";base64,",
    code8: "",
    baseURL: false,

    // file:null
  });

  const changetf = (e) => {
    //  let img8 = decodeURIComponent(escape((e.target.value)));
    // console.log(img8)
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getFoundation = async () => {
    await axios
      .get(uri + "Foundation")
      .then((res) => {
        setData(res.data);
        console.log("foundation is", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFoundation();
  }, []);

  const showData = (rowData) => {
    setData(rowData);
  };

  const updataFoundation = async () => {
    await axios
      .put(uri + "Foundation", data)
      .then((res) => {
        getFoundation();
        setData({});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sty = {
    marginLeft: "150px",
    marginRight: "150px",
    marginBottom: "6px",
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        //  console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    let file = null; //= data.Logo;

    file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        let n = 23,
          str;
        if (file.type != "image/jpeg") n = 22;

        str = file.base64.substring(n);
        // console.log(file.type);

        setData({
          ...data,
          Logo: str,
          // file
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleFooterFileInputChange = (e) => {
    let file = null; //= data.Logo;

    file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        let n = 23,
          str;
        if (file.type != "image/jpeg") n = 22;

        str = file.base64.substring(n);
        // console.log(file.type);

        setData({
          ...data,
          footer_img: str,
          // file
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box p={3}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }

  // TabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.any.isRequired,
  //   value: PropTypes.any.isRequired,
  // };

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }

  return (
    <div className={clasess.root} style={{ margin: 10 }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="secondary tabs example"
        >
          <Tab label="الاعدادات الافتراضية" {...a11yProps(0)} />
          <Tab label="إعدادات فاتورة A4" {...a11yProps(1)} />
          <Tab label="القوالب" {...a11yProps(2)} />
          <Tab label="invoice generator" {...a11yProps(3)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <b>الاعدادات الافتراضية</b>
        {/* <PrintCasher /> */}
        <Settings />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <b>إعدادات فاتورة A4</b>
        <PdfSettings />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <b>قوالب فاتورة كبير</b>
        <TemplateGrid />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <b>invoice </b>
        <InvoiceGenerator />
      </TabPanel>
    </div>
  );
}
