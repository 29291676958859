import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import { uri } from "../../../help/Api";
import { useHistory } from "react-router-dom";
import { useStylesHed, useStylesSpDial } from "../../../St/comps/UseStyle";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import LoadingPage from "../../../services/LoadingPage";
import {
  getCheckKeys,
  prepareUserPerms,
} from "../../../services/permissions-services";
import { useAPI } from "../../../api/useAPI";
import UserForm from "../../Settings/user-permissions/user-form";
import UserPermissionsTree from "./UserPermissions-tree";
import Swipeable from "../../../Fronts/Main/Swipeable";
import useFormDataCRUD from "../../../components/Hooks/useEDSCURD";
import toast, { Toaster } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: "#fff",
    padding: 5,
    textAlign: "center",
    fontSize: 24,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));

export function UserPermissionSub(props) {
  const {
    users,
    defaultCheckedKeys,
    fetching,
    Formsdata,
    user,
    setUser,
    fetchURL,
  } = props;
  const classes = useStyles();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);
  const { createFun } = useAPI("UserPermission");
  const [checkedKeys, setCheckedKeys] = useState(defaultCheckedKeys);
  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    createFun,
    () => {},
    () => {},
    undefined,
    { ...defaultCheckedKeys },
    null,
    fetchURL,
    "edit"
    // fetchURL
  );
  useEffect(() => {
    // console.log("oboboobobbobob");
    setCheckedKeys(defaultCheckedKeys);
  }, [defaultCheckedKeys]);
  const his = useHistory();
  const handleUpdate = async () => {
    const nData = prepareUserPerms(Formsdata, checkedKeys, user.user.id);
    console.log("thes data after", nData);
    let model = {
      _Data: nData,
    };
    await submit(model)
      .then((res) => {
        toast.success("تم تعديل الصلاحيات ");
      })
      .catch((err) => {
        console.log(err);
        toast.err("there is error");
      });
  };
  // if (fetching || !defaultCheckedKeys) return <LoadingPage />;
  return (
    <div className={classes.root}>
      <Toaster />
      <Typography component="div" style={{ direction: "rtl" }}>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: colors.indigo[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <Grid container item xs={12}>
            <Typography
              size="small"
              className={classes.headerTitle}
              color="primary"
            >
              صلاحيات المستخدمين
            </Typography>
          </Grid>
          <UserForm users={users} gData={user} setGData={setUser} />
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.red[300]} />
            </Grid>
          </Grid>
          <Container
            style={{
              display: "flex",
              justifyContent: "right",
              flexWrap: "wrap",
              listStyle: "none",
              padding: "30px",
              margin: "0px",
              // backgroundColor: colors.grey[50],
            }}
            component={Paper}
          >
            {!fetching && defaultCheckedKeys && (
              <UserPermissionsTree
                data={Formsdata ?? []}
                defaultCheckedKeys={defaultCheckedKeys}
                checkedKeys={checkedKeys}
                setCheckedKeys={setCheckedKeys}
              />
            )}
            <Grid
              container
              justifyContent="center"
              // style={{ marginBottom: 80 }}
            >
              <Grid item container justifyContent="center" xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  style={{ width: "100%" }}
                  disabled={submitting}
                  onClick={handleUpdate}
                >
                  {"حفظ التعديلات"}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>

        <br />
      </Typography>
      <Swipeable />
    </div>
  );
}

export default function UserPermissions(props) {
  // const [gData, setGData] = useState(props.gData);
  const [user, setUser] = useState({ user: props.defUser });

  const {
    data: defaultCheckedKeys,
    fetching,
    fetchURL,
  } = useFetchURL({
    ...{
      url: uri + `UserPermission/getByUserId?userId=${user?.user?.id}`,
      mapFunction: getCheckKeys,
      dependencyArray: [user],
    },
  });

  useEffect(() => {
    console.log("the globel data is", defaultCheckedKeys);
  });

  return (
    <UserPermissionSub
      users={props.users}
      fetching={fetching}
      // data={data}
      defaultCheckedKeys={defaultCheckedKeys}
      Formsdata={props.data}
      user={user}
      setUser={setUser}
      fetchURL={fetchURL}
    />
  );
}
