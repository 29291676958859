import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStylesTF, useStylesTD } from "../../../../St/comps/UseStyle";

export default function CostCentersForm(props) {
  const { object, onChange } = props;
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const onChangeData = (prop) => (event) => {
    onChange(event, { name: prop, value: event.target.value });
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"Name"}
          label={" اسم المركز  "}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"Name"}
          value={object?.Name}
          onChange={onChangeData("Name")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"NameEN"}
          label={"اسم المركز (EN)"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"NameEN"}
          value={object?.NameEN}
          onChange={onChangeData("NameEN")}
        />
      </Grid>
    </React.Fragment>
  );
}
