  export const getBase64 = file => {
    return new Promise(resolve => {
        
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result;
       // console.log(baseURL);
        resolve(baseURL);
      };
     // console.log(fileInfo);
    });
  };

 