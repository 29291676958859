import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../../Label/Lbl";
import BackdropScI from "../../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import Paper from "@material-ui/core/Paper";
import { getTotalAmount } from "../../../../utils/utils";
import { useBody } from "../../invCompany/style";
import ButtonBase from "@material-ui/core/ButtonBase";
const UnderHeaderT = (props) => {

  const{found,inv,sta}=props;
  const clasm = useInvMain();
  const clas = useInvAF();
  //const [inv, setInv] = useState({});
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};
  const [showSc, setShowSc] = useState(true);
  const calssBody = useBody();

  const styf = { fontWeight: "500" };
  const styfC = { fontWeight: "500", color: "#9a3f47" };
  return (
    <Grid
      item
      container
      style={{ fontWeight: "bold" ,fontFamily: "Amiri-Regular",
        marginLeft: "0cm",marginRight:"0rem",paddingTop:"0.5rem"}}

    >
      <Grid item xs={12}>

        <Grid item container >
          {/* <Grid item xs={1}></Grid> */}
          <Grid item container style={{ direction: "rtl" }}>
            {/* <Grid item xs={1}  className={clas.Bdyroot}></Grid> */}
            <Grid item xs={3} style={{fontFamily: "Amiri-Regular"}}

            >
              <Grid item container className={clasm.mincntr}>
                <Grid item xs={12} >

                  
                  {inv.tax > 0 && (
                    <Grid item xs={12}>
                      <ButtonBase style={{ border: "solid 1px" }}>
                        <img
                          src={`data:image/jpeg;base64,${inv.qr_image}`}
                          style={{ height: 100, width: 100 }}
                        // alt={found.nameA}
                        />
                      </ButtonBase>
                    </Grid>
                  )}
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={9} 

            >
              <Grid item container className={clasm.mincntr}>
              <Grid item container style={{paddingBottom:"0.3rem"}}>
                <Grid item xs={6} className={clas.BdytitleHedQ2}>
                  {sta.tot.tax_no_cust === ""
                    ? "فاتورة ضريبية مبسطة" + " " + "Simplified Tax Invoice"
                    : "فاتورة ضريبية" + " " + "Tax Invoice"}
                </Grid>
                <Grid item xs={6} className={clas.BdytitleHedQ2}>
                  {locInv.invN +
                    " " +
                    sta.tot.inv_type_name +
                    " " +
                    sta.tot.pay_type_name}
                </Grid>
                </Grid>
                <Grid item container style={{paddingTop:"0.5rem"}}>
                <Grid item xs={3} style={{
                  borderBottom: "solid 0.1px",
                  borderRight: "solid 0.1px",
                    borderTop:"solid 0.1px",
                    textAlign:"right",
                          paddingRight:"0.6rem"
                }}>
                  {txtA.invNumr}
                </Grid>
                <Grid item xs={6} style={{
                      borderBottom: "solid 0.1px",
                      borderTop: "solid 0.1px",
                    
                }}>
                  <Paper className={clasm.minclr}>{sta.tot.id}</Paper>
                </Grid>
                <Grid item xs={3} style={{
                          borderBottom: "solid 0.1px",
                          borderTop: "solid 0.1px",
                          borderLeft: "solid 0.1px", 
                          textAlign:"left",
                          paddingLeft:"0.6rem"
                }}>
                  {txtE.invNumr}
                </Grid>

                <Grid item xs={3} className={clas.BdyborBtR}  style={{  textAlign:"right",
                          paddingRight:"0.6rem"}}>
                  {txtA.invDate}
                </Grid>
                <Grid item xs={6} className={clasm.minborB}>
                  <Paper className={clasm.minclr}>
                    {moment(sta.tot.date).format("YYYY/MM/DD")}
                  </Paper>
                </Grid>
                <Grid item xs={3} className={clas.BdyborBtL} style={{  textAlign:"left",
                          paddingLeft:"0.6rem"}}>
                  {txtE.invDate}
                </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={1}></Grid> */}

          </Grid>
          {/* <Grid item xs={1}></Grid> */}
        </Grid>
      </Grid>

    </Grid>
  );
};

export default UnderHeaderT;
