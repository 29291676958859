import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AccountSelect from "../../../../components/controls/accounts-select";
import SelectTypesFilter from "./accountingRPT-typeFilter";
import { docTypes, sortTypes } from "./accountingRPT-services";
import { useTranslation } from "react-i18next";

export default function AccountingRPTConForm(props) {
  const { t } = useTranslation(["forms"]);

  const {
    rptParams,
    setRptParams,
    onChangeData,
    AutoOptions,
    onChangeAutoData,
  } = props;

  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={4} md={6} sm={10} xs={12}>
          <AccountSelect
            accounts={AutoOptions.Accindex}
            account={rptParams.account}
            onSelect={(newValue) => {
              onChangeAutoData("account", newValue);
            }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.branch}
            onChange={(event, newValue) => {
              onChangeAutoData("branch", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Branches}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("branch")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.center}
            onChange={(event, newValue) => {
              onChangeAutoData("center", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.center}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("center")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="datef"
            label={t("date-from")}
            type="date"
            format="YYYY-MM-DD"
            name="datef"
            value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="dateT"
            label={t("date-to")}
            type="date"
            format="YYYY-MM-DD"
            name="datet"
            value={rptParams.dateTo}
            onChange={onChangeData("dateTo")}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.orderType}
            onChange={(event, newValue) => {
              onChangeAutoData("orderType", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={sortTypes}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("order-by")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.docType}
            onChange={(event, newValue) => {
              onChangeAutoData("docType", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={docTypes}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("doc-type")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.omla}
            onChange={(event, newValue) => {
              onChangeAutoData("omla", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions?.Omlat}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("omla")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={4} sm={6} sx={12}>
          <SelectTypesFilter {...{ rptParams, setRptParams }} />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.openBalance}
                  onChange={(e) =>
                    onChangeAutoData("openBalance", e.target.checked)
                  }
                  name="chkVat"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label={t("initial-balance")}
            />
          </FormGroup>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.oldBalance}
                  onChange={(e) =>
                    onChangeAutoData("oldBalance", e.target.checked)
                  }
                  name="chkVat"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label={t("prev-balance")}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
