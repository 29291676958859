import React from "react";
import { uri } from "../../../help/Api";
import { useEffect } from "react";
import BackdropScI from "../../../services/BackdropScI";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import ElectronicScale from "./electronic-scale-settings";
// import Safes from "./safes";

export default function ElectronicScaleCon() {
  const url = uri + "ElectronicScale";

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url,
    },
  });

  useEffect(() => {
    console.log("data  is", data);
  });

  if (fetching || !data) return <BackdropScI />;
  return (
    <ElectronicScale
      {...{
        data,
        fetchURL,
      }}
    />
  );
}
