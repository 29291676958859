import React, { useState, useEffect } from "react";
import { uri } from "../../../../help/Api";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import StoreExchangeForm from "./StoreExchange-form";
import SubStoreExchange from "./StoreExchange-sub/sub-StoreExchange";
import { Toaster } from "react-hot-toast";
import Footer from "./footer";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import { getDefaultOmla } from "../../../../services/omlat-services";
import { twreedDefault } from "../../../../services/twreed-servece";
import { useAPI } from "../../../../api/useAPI";
import { Chip, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
    direction: "rtl",
  },
  chip: {
    paddingLeft: "10px",
    marginLeft: "10px",
  },
}));

export default function StoreExchange(props) {
  const clasess = useStyles();
  const { fetchURL, AutoOptions, newId, fetchId, type } = props;
  const { Safes } = AutoOptions;
  const { t } = useTranslation(["forms"]);
  const { createFun, updateFun, removeFun } = useAPI("TwreedSF");
  const defOmla = getDefaultOmla(AutoOptions.omlats);
  const defaultData = {
    ...twreedDefault,
    no: newId,
    omla: defOmla,
    safe: Safes[0],
    Type: type,
  };
  const [fetching, setFetching] = useState(false);
  let his = useHistory();

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    createFun,
    updateFun,
    removeFun,
    undefined,
    defaultData,
    null,
    fetchId
  );

  const backHome = () => {
    his.push({ pathname: "/" });
    window.location.reload(false);
  };

  return (
    <div className={clasess.main}>
      <Toaster position="top-center" reverseOrder={false} />
      <Grid container>
        <Grid container item xs={12} justifyContent="center">
          <Chip
            size="small"
            // className={clashd.ChipHdSafe}
            label={type == 1 ? t("twreed-title") : t("srf-title")}
            color="primary"
          />
        </Grid>
      </Grid>
      <StoreExchangeForm
        {...{
          ...AutoOptions,
          onChange,
          submit,
          update,
          remove,
          submitting,
          errorMessage,
          onShow,
          onEnd,
          object,
          formType,
        }}
      />
      <SubStoreExchange
        {...{
          ...AutoOptions,
          onChange,
          submit,
          update,
          remove,
          submitting,
          errorMessage,
          onShow,
          onEnd,
          object,
          formType,
          fetching,
        }}
      />
      <Footer
        {...{
          AutoOptions,
          type,
          onChange,
          submit,
          update,
          remove,
          submitting,
          errorMessage,
          onShow,
          onEnd,
          object,
          formType,
          defaultData,
        }}
      />
    </div>
  );
}
