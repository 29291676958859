import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

const PrivateRoute = (props) => {
  const { component: Component, isAllowed, ...rest } = props;

  if (!isAllowed)
    return (
      <Redirect to="/not-found" />
      // <Route
      //   {...rest}
      //   render={(matchProps) => (
      //     <>
      //       <NotFound {...matchProps} />
      //       <Swipeable />
      //     </>
      //   )}
      // />
    );

  return (
    <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string,
  isAllow: PropTypes.string,
};

export default PrivateRoute;
