import React from "react";
import { uri } from "../../../help/Api";
import { useEffect } from "react";
import BackdropScI from "../../../services/BackdropScI";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import ZatcaSettings from "./zatca-settings";
import LoadingPage from "../../../services/LoadingPage";
// import Safes from "./safes";

export default function ZatcaSettingsCon() {
  const url = uri + "Zatca/GetCertificateSettings";

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url,
    },
  });

  useEffect(() => {
    console.log("data  is", data);
  });

  if (fetching || !data) return <LoadingPage />;
  return (
    <ZatcaSettings
      {...{
        data,
        fetchURL,
      }}
    />
  );
}
