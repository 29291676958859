import React,{Fragment} from 'react';
import MaterialTable,  { MTableToolbar } from "material-table";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'

import {Bar, Line } from 'react-chartjs-2';
import {faker} from '@faker-js/faker';
import { useEffect } from 'react';
import { useState } from 'react';
import {getLatestSevenDayDate} from '../../../../services/SalesChartSrvice';
import {options} from './chart'
import moment from 'moment';
import { colors } from '@material-ui/core';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
)

  


export default function LatestSalesChart(props){

  const {data: mData} = props;
  const labels = getLatestSevenDayDate();
  const [data, setDate] = useState({
    labels, 
      datasets: [
    {
      fill:true,
      // label: 'البميعات اخر 7 ايام',
      data: labels.map((m, i) =>  mData[6-i]?.total),
      // backgroundColor: colors.indigo[500],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ]
  });

    return(
      <Line options={options} data={data} />
    )
}
