import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles, styled } from '@material-ui/core/styles';
import { Chip, colors } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { sortTypes } from '../../Accounts/BackEnd/AccountingRPT/accountingRPT-services';
import { insh as inshst } from '../../../Context/InshSt';

const useStyles = makeStyles((theme) => ({
    chip:{
    paddingLeft:'10px',
    paddingRight: '10px',
    marginLeft: '10px'
  },
}));

const ListItem = styled('li')(({theme}) =>({
  margin: theme.spacing(0.5),
}));

const SelectTypesFilter = (props)=>{
  const { rptParams, setRptParams}  = props;
  const classes = useStyles();

    return (
            <Container
                style={{
                  display:'flex',
                  justifyContent:'center',
                  flexWrap:'wrap',
                  listStyle:'none',
                  padding:'5px',
                  margin:'0px',
                }}
                component="ul" >
              {
                sortTypes.map(x=>{

                  return <ListItem key={x.id}>
                    {
                      x.id ===rptParams.orderType.id ?
                      <Chip 
                        className={classes.chip} 
                        label={x.name} 
                        icon={<Done style={{color:'#fff'}}/>}
                        style={{backgroundColor: inshst.colors.cust,
                        color:'#ffffff'}}
                        clickable
                      />:
                      <Chip 
                        className={classes.chip} 
                        label={x.name} 
                        variant='outlined'
                        color={'default'}
                        onClick={()=>{setRptParams({...rptParams, 
                          orderType: x})}}
                      />
                    }
                  </ListItem>
                })}
            </Container>
           
    )
}

export default SelectTypesFilter;