import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";
import CostCenterRpt from "./CostCenterRpt";
import LoadingPage from "../../../../services/LoadingPage";
import { convertToTreeData } from "../../../../services/accountIndex-services";
const defaultAutos = {
  Safes: [],
};

export default function CostCenterRptCon() {
  const [branch, setBranch] = useState({ id: -1, name: "الكل" });
  const tb = {
    wait: true,
    omlat: true,
    Branches: true,
    costcenters: true,
    stor: { ...sto, branchi: branch.id == -1 ? sto.branchi : branch.id },
    parms: { safe: -1 },
  };
  const url = uri + "accounts-index/getAll";
  const {
    data: accounts,
    fetching,
    fetchURL,
  } = useFetchURL({
    ...{ url, mapFunction: convertToTreeData, dependencyArray: [] },
  });

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
    [branch]
  );


  if (fetching || fetchAll || !AutoOptions?.center || accounts?.length == 0)
    return <LoadingPage />;

  return (
    <CostCenterRpt
      {...{
        AutoOptions: AutoOptions?.center
          ? {
              ...AutoOptions,
              centers: AutoOptions?.center.filter((x) => x.id != -1),
            }
          : defaultAutos,
        accounts: accounts?.length > 0 ? accounts : [],
        branch,
        setBranch,
      }}
    />
  );
}
