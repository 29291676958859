import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import StoreTransferForm from "./StoreTransfer-form";
import StoreTransferSub from "./StoreTransferSub/StoreTransferSub";
import { Toaster } from "react-hot-toast";
import StoreTransferAPI from "../../../../api/StoreTransfer";
import {
  defaultStoreTransferData,
  dfaultfilters,
} from "../../../../services/storeTransfer-servece";
import Footer from "./footer";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import StoreTransferLoader from "./StoreTransferSub/StoreTransferSub-loader";
import { Chip, Grid } from "@material-ui/core";
import { useStylesHed } from "../../../../St/comps/UseStyle";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
    direction: "rtl",
  },
  chip: {
    paddingLeft: "10px",
    marginLeft: "10px",
  },
}));

export default function StoreTransfer(props) {
  const clasess = useStyles();
  const { fetchURL, fetchingSafes, safes, branches: br } = props;
  const branches = br.filter((b) => b.id != -1);
  const [filters, setFilters] = useState(dfaultfilters);
  const clashd = useStylesHed();

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    StoreTransferAPI.create,
    StoreTransferAPI.update,
    StoreTransferAPI.remove,
    undefined,
    { ...defaultStoreTransferData, safe_from: safes[0], branch: branches[0] },
    fetchURL,
    fetchURL
  );

  let his = useHistory();
  const backHome = () => {
    his.push({ pathname: "/" });
    window.location.reload(false);
  };

  return (
    <div className={clasess.main}>
      <Toaster position="top-center" reverseOrder={false} />
      <Grid container>
        <Grid container item xs={12} justifyContent="center">
          <Chip
            size="small"
            className={clashd.ChipHdSafe}
            label={`امر تحويل مخزني`}
            color="primary"
          />
        </Grid>
      </Grid>
      {fetchingSafes ? (
        <StoreTransferLoader />
      ) : (
        <StoreTransferForm
          {...{
            ...props,
            branches,
            onChange,
            submit,
            update,
            remove,
            submitting,
            errorMessage,
            onShow,
            onEnd,
            object,
            formType,
            filters,
            setFilters,
          }}
        />
      )}

      <StoreTransferSub
        {...{
          ...props,
          branches,
          onChange,
          submit,
          update,
          remove,
          submitting,
          errorMessage,
          onShow,
          onEnd,
          object,
          formType,
        }}
      />
      <Footer
        {...{
          ...props,
          branches,
          onChange,
          submit,
          update,
          remove,
          submitting,
          errorMessage,
          onShow,
          onEnd,
          object,
          formType,
        }}
      />
    </div>
  );
}
