import { colors, Container, Grid, Paper, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
export default function UserForm(props) {
  const { users, gData, setGData } = props;
  //   const [gData, setGData] = useSharedData();
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        padding: "10px",
        margin: "0px",
        backgroundColor: colors.grey[50],
      }}
      component={Paper}
    >
      <Grid item lg={3} md={3} sm={4} xs={6}>
        <Autocomplete
          value={gData.user}
          onChange={(event, newValue) => {
            // onChangeAutoData("user", newValue);
            setGData({ ...gData, user: newValue });
          }}
          id="combo-box-demo"
          size="small"
          options={users}
          //getOptionLabel={(option) => option.name}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.username
          }
          style={{}}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              label="المستخدم"
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Container>
  );
}
