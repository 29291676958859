import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
  , useStylesUiElemment,
  useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
export default function CurrencySalesProfitForm(props) {
  const { rptParams, onChangeData, AutoOptions, onChangeAutoData, isPurches } = props;

  const { t } = useTranslation(["forms"]);
  const calsPurch = useStylesAutoPurch();
  const claslbl = useStylesLbl();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();

  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <TextField fullWidth variant="outlined" size="small" id="dateFrom" label="من تاريخ"
            className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
            type="date" format="YYYY-MM-DD"
            name="dateFrom" value={rptParams.dateFrom} onChange={onChangeData('dateFrom')}
          /></Grid>
        {rptParams.selectTime.id == 2 && (
          <Grid item lg={2} md={2} sm={3} xs={6}>
            <TextField fullWidth variant="outlined" size="small" id="timeFrom" label="الوقت "
              className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
              type="time"
              format="HH:mm"
              name="timeFrom" value={rptParams.timeFrom} onChange={onChangeData('timeFrom')}
            /></Grid>
        )}
        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <TextField fullWidth variant="outlined" size="small" id="dateTo" label="إلى تاريخ"
            className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
            type="date" format="YYYY-MM-DD"
            name="dateTo" value={rptParams.dateTo} onChange={onChangeData('dateTo')}
          /></Grid>

        {rptParams.selectTime.id == 2 && (
          <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
            <TextField fullWidth variant="outlined" size="small" id="timeTo" label="الوقت "
              className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
              type="time"
              format="HH:mm"
              name="timeTo" value={rptParams.timeTo} onChange={onChangeData('timeTo')}
            /></Grid>
        )}

        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}
            value={rptParams.curr}
            onChange={(event, newValue) => {
              onChangeAutoData("curr", newValue);
              // console.log(newValue);
            }}
            id="controllable-states-demo"
            size="small"
            options={AutoOptions.items}
            getOptionLabel={(option) =>
              typeof option === "string"
                ? option
                : option?.i?.toString()
            }
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={"باركود"}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
              />
            )}
          />

        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete classes={isPurches ? calsPurch : clasAo}
            value={rptParams.curr}
            labelPlacement=""
            onChange={(event, newValue) => { onChangeAutoData('curr', newValue) }}
            id="controllable-states-demo" size="small"
            options={AutoOptions.items}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            // style={{  }}
            disableClearable
            renderInput={(params) => <TextField {...params} label="الصنف" variant="outlined" size="small"
              InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }} />}
          />


        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete classes={isPurches ? calsPurch : clasAo}
            value={rptParams.safe}
            labelPlacement=""
            onChange={(event, newValue) => { onChangeAutoData('safe', newValue) }}
            id="controllable-states-demo" size="small"
            options={AutoOptions.Safes}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            // style={{  }}
            disableClearable
            renderInput={(params) => <TextField {...params} label="المخزن" variant="outlined" size="small"
              InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }} />}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <Autocomplete classes={isPurches ? calsPurch : clasAo}
            value={rptParams.branch}
            onChange={(event, newValue) => {
              //   setBranch(newValue);
              onChangeAutoData("branch", newValue);

            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Branches}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }

            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("branch")}
                variant="outlined"
                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
              />
            )}

          />
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.chk_withvat}
                  onChange={(e) =>
                    onChangeAutoData("chk_withvat", e.target.checked)
                  }
                  name="chkVat"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label={t("withtax")}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
