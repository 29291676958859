import React, { useState } from "react";
import { colors, Grid, Hidden, Typography } from "@material-ui/core";
import Barcode from "react-barcode/lib/react-barcode";
import { sto } from "../../../../../Context/InshData";

function BarcodeGeneratormusa(props) {
    const { barcode, itemName, price ,maqas} = props;

    const width = 2;
    const hight = 30;

    return (
        <div justifyContent="center"   style={{
            // minWidth: '50mm',
            // maxWidth: "50mm",
            minWidth: `${maqas.wisthPaper}mm`,
            maxWidth:  `${maqas.wisthPaper}mm`,
            minHeight: '25mm',
            textAlign: "center",
            backgroundColor: "#fff"
        }}>
            <div
                style={{
                    minWidth: '50mm',
                    // maxWidth: "50mm",
                    // height: '25mm',
                    // border:'0.5px solid #000'
                    paddingRight: '1mm',
                    paddingLeft: '1mm',
                    padding: '1mm',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                    // textAlign:'center',
                    backgroundColor: '#fff',

                }}
            >
                <Barcode value={barcode} width={width} height={hight} />



                {/* <div style={{ direction: "rtl", textAlign: "center" }}>
        <Grid container xs={12} style={{ fontSize: "9px" }}>
          <Grid item xs={8}  style={{ fontSize: "9px" }}>
            {itemName}
          </Grid>
          <Grid item xs={4}  style={{ fontSize: "9px" }}>
            {" "}
            {price}
            {"رس  "}
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          style={{
            fontSize: "8px",
          }}
        >
          <Grid item xs={6}  style={{ fontSize: "9px" }}>
            {prody_date}
          </Grid>
          <Grid item xs={6}  style={{ fontSize: "9px" }}>
            {" "}
            {expiry_date}
          </Grid>
        </Grid>
      </div> */}

            </div>
            <div style={{ direction: "rtl", textAlign: "center" ,fontWeight:"500",maxLines:1,fontSize:"0.8rem"}}> {itemName}</div>
            <div style={{ direction: "rtl", textAlign: "center" ,fontWeight:"bold",maxLines:1}}>{price}{""}{"رس  "}</div>
        </div>
    );
}

export default BarcodeGeneratormusa;
