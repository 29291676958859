import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import { Card, CardContent, colors, Container, Divider, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import BalanceCard from "../../../../components/BalanceCard";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    root: {
        // height: '80px',
        fontFamily: "Amiri-Regular",
        // borderBottomWidth: "5px",
        // borderBottomStyle: "solid",
        color: "#000",
        paddingTop: "0.5rem"
    },
    rootBodys: {
        fontFamily: "Amiri-Regular",
        backgroundColor: colors.grey[300],
        borderBottomColor: colors.grey[700],
        borderBottomWidth: "5px",
        borderBottomStyle: "solid",
        color: "#000",
        direction:"rtl",
    },
    content: {
        alignItems: "center",
        //display: "flex",
        fontFamily: "Amiri-Regular",
    },
    title: {
        fontWeight: 700,
        textAlign: "center",
        fontFamily: "Amiri-Regular",
    },
}));

function TotalsS({ data }) {

    const cls = useRptAF();
    const { t } = useTranslation(["forms"]);
    const totals = Rept.tot;

    const classes = useStyles();
    return (
        <Card className={clsx(classes.root)}
        >
            <Grid item container className={classes.rootBodys}
            
           >
                <CardContent className={classes.content}>
                    <Grid container xs={12} /*className={cls.rootchip}*/ style={{
                        fontFamily: "Amiri-Regular",
                        direction: "rtl"
                    }}>
                        <Grid container xs={2}  maxWidth={false} style={{ fontWeight: "bold" }}>
                          
                            <Grid xs={12}  maxWidth={false} style={{ fontWeight: "bold" }}>
                                <Container
                                    style={{
                                        justifyContent: "center",
                                    }}
                                    maxWidth={false}
                                >
                                    <Typography variant="body2"  style={{ fontFamily: "Amiri-Regular",maxLines:1 }}>
                                        {"إجمالي مبيعات"}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {totals.tot_sales.toFixed(2)}
                                    </Typography>
                                </Container>
                            </Grid>
                        </Grid>
                        <Grid container xs={2}  maxWidth={false}>
                            
                            <Grid xs={12} style={{ fontWeight: "bold" }}>
                                <Container
                                    style={{
                                        justifyContent: "center",
                                    }}
                                    maxWidth={false}
                                >
                                    <Typography variant="body2" style={{ fontFamily: "Amiri-Regular",maxLines:1 }}>
                                        {"إجمالي مرتجع"}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {totals.tot_return.toFixed(2)}
                                    </Typography>
                                </Container>
                            </Grid>
                        </Grid>

                        <Grid container xs={2}  maxWidth={false}>
                            
                            <Grid xs={12}  maxWidth={false} style={{ fontWeight: "bold" }}>
                                <Container
                                    style={{
                                        justifyContent: "center",
                                    }}
                                    maxWidth={false}
                                >
                                    <Typography variant="body2" style={{ fontFamily: "Amiri-Regular",maxLines:1 }}>
                                        {"قبل الضريبة"}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {totals.betfore_vat.toFixed(2)}
                                    </Typography>
                                </Container>
                            </Grid>

                        </Grid>

                        <Grid container xs={2}  maxWidth={false}>
                            
                            <Grid xs={12}  maxWidth={false} style={{ fontWeight: "bold" }}>
                                <Container
                                    style={{
                                        justifyContent: "center",
                                    }}
                                    maxWidth={false}
                                >
                                    <Typography variant="body2" style={{ fontFamily: "Amiri-Regular" ,maxLines:1}}>
                                        {" الضريبة"}
                                        {/* {"الضريبة"} */}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {totals.vatTotal.toFixed(2)}
                                    </Typography>
                                </Container>
                            </Grid>

                        </Grid>

                        <Grid container xs={2}  maxWidth={false} >
                            
                            <Grid xs={12}  maxWidth={false} style={{ fontWeight: "bold" }}>
                                <Container
                                    style={{
                                        justifyContent: "center",
                                    }}
                                    maxWidth={false}
                                >
                                    <Typography variant="body2" style={{ fontFamily: "Amiri-Regular" }}>
                                        {"شامل الضريبة"}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" ,maxLines:1}}>
                                        {totals.netAmount.toFixed(2)}
                                    </Typography>
                                </Container>
                            </Grid>

                        </Grid>

                        <Grid container xs={2}  maxWidth={false} >
                            
                            <Grid xs={12}  maxWidth={false} style={{ fontWeight: "bold" }}>
                                <Container
                                    style={{
                                        justifyContent: "center",
                                    }}
                                    maxWidth={false}
                                >
                                    <Typography variant="body2" style={{ fontFamily: "Amiri-Regular" }}>
                                        {/* {"إجمالي الصافي"} */}
                                        {" الصافي"}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {totals.netAmount.toFixed(2)}
                                    </Typography>
                                </Container>
                            </Grid>

                        </Grid>
                    </Grid>
                </CardContent>
            </Grid>
        </Card>

    );
}

export default TotalsS;
