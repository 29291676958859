import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  colors,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";

import toast, { Toaster } from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";
import { insh } from "../../Context/InshData";
import _ from "lodash";
import axios from "axios";
import { uri } from "../../help/Api";
import { useState } from "react";
import { LoadingRefresh } from "../../services/LoadingPage";
import { useStylesTD, useStylesTF } from "../../St/comps/UseStyle";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

function ConfirmPassword({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const checkPassword = () => {
    axios
      .get(uri + `BackupDB/checkPass?type=${1}&&userId=${2}&&pass=${password}`)
      .then((response) => {
        onClose(password);
      })
      .catch((err) => {
        console.log(err);
        toast.error("كلمة المرور غير صحيحة");
      });
  };
  return (
    <Dialog
      keepMounted
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"xs"}
      // width={300}
    >
      <DialogContent
        style={{
          textAlign: "center",
          paddingTop: "20px",
          paddingLeft: "45px",
          // border: "solid 1px black",
          height: 100,
        }}
        id="alert-dialog-slide-description"
      >
        <Container justifyContent="center">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id={"password"}
            label={"كلمة المرور"}
            className={clasTF.rootSale}
            InputProps={{ className: clasTD.input }}
            type="password"
            name={"password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={checkPassword}>
          دخول
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default function BackupAllDataBase(props) {
  const his = useHistory();

  const { color } = props;
  const data = _.values(insh.cloud);
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [password, setPassword] = useState("");
  const classes = useStyles();
  const dowloadFile = (path) => {
    setLoading(true);
    axios({
      url: "https://" + path + "/api/" + `BackupDB/getOraxDB?pass=${password}`,
      // url: uri + `BackupDB/getOraxDB?pass=${password}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", path + ".zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (!confirmed)
    return (
      <Container style={{ direction: "rtl" }}>
        <Toaster />
        <ConfirmPassword
          onClose={(pass) => {
            setPassword(pass);
            console.log("the password is", pass);
            setConfirmed(true);
          }}
        />
      </Container>
    );
  return (
    <Container style={{ direction: "rtl" }}>
      <LoadingRefresh
        open={loading}
        onClose={(pass) => {
          setLoading(false);
        }}
      />
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow
              style={{
                backgroundColor: color ? color : colors.grey[500],
                color: "#fff",
              }}
            >
              <TableCell style={{ color: "#fff" }} align="center">
                القاعدة
              </TableCell>

              <TableCell style={{ color: "#fff" }} align="center">
                تحميل
              </TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">{row?.serv}</TableCell>

                <TableCell align="center" style={{ color: "#00f" }}>
                  <Button
                    variant="contained"
                    onClick={() => dowloadFile(row?.serv)}
                  >
                    تحميل
                  </Button>
                  {/* {row?.type > 0 && row?.type < 13 ? (
                  <Button
                    onClick={() => {
                      //  his.push({
                      //     pathname: `/int-receipt`,
                      //     state:'edrees'
                      //   });
                    }}
                  >
                    {docTypes[row?.type]?.name}
                  </Button>
                ) : null} */}
                </TableCell>

                <TableCell align="center"></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
