import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { uri } from "../../help/Api";

export function useBackupDB() {
  const [fetching, setFetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetch = async () => {
    setFetching(true);
    setIsError(false);
    await axios
      .get(uri + "BackupDB")
      .then(async (res) => {
        console.log(res.data);
        toast.success("Backup of Database Has Been Done Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Error Occured While Creating Backup of Database Error Code"
        );
        setIsError(true);
      });
    setFetching(false);
  };

  return { fetch, fetching, isError };
}
