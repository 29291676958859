import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { colors } from "@material-ui/core";
import { useStylesTF, useStylesLbl } from "../../../../St/comps/UseStyle";

import { useTranslation } from "react-i18next";

import { restrictStates } from "../../../../services/restrictionService";

export default function ORestForm(props) {
  const { onChange, object } = props;
  const clasTF = useStylesTF();
  const claslbl = useStylesLbl();
  const { t } = useTranslation(["forms"]);

  return (
    <React.Fragment>
      <Grid container style={{ padding: 10 }} spacing={2}>
        <Grid item lg={2} md={2} sm={2} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="id"
            label={t("no")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input, readOnly: true }}
            name="id"
            value={object?.doc_no2}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="date"
            label={t("date")}
            className={clasTF.rootEDS}
            type="date"
            format="YYYY-MM-DD"
            InputLabelProps={{ shrink: true }}
            name="date"
            value={object.date}
            onChange={(e) =>
              onChange(null, { name: "date", value: e.target.value })
            }
          />
        </Grid>
        {/* <Grid item lg={5} md={5} sm={6} xs={6}>
          <Autocomplete
            value={object.center}
            onChange={(event, newValue) => {
              onChange(null, { name: "center", value: newValue });
            }}
            id="controllable-states-demo"
            size="small"
            options={AutoOptions.costcenters}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCostcenter"
                {...params}
                label={t("center")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid> */}
        {/* <Grid item lg={6} md={6} sm={6} xs={6}>
          <Autocomplete
            value={object.salesmen}
            onChange={(event, newValue) => {
              onChange(null, { name: "salesmen", value: newValue });
            }}
            id="controllable-states-demo4"
            size="small"
            options={AutoOptions.salesmen}
            // getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueSalesmen"
                {...params}
                label={t("delegate")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid> */}

        <Grid item lg={2} md={2} sm={2} sx={4}>
          <Autocomplete
            value={object.restState}
            onChange={(event, newValue) => {
              // changAutoStocks(newValue);
            }}
            id="controllable-states-demoew"
            size="small"
            options={restrictStates}
            style={{ backgroundColor: colors.blueGrey[50] }}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.label
            }
            // style={{  }}

            disableClearable
            renderInput={(params) => (
              <TextField
                id="restState"
                {...params}
                label={"حالة القيد"}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="notes"
            label={t("note")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input }}
            name="notes"
            value={object.notes}
            onChange={(e) =>
              onChange(null, { name: "notes", value: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
