import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { uri } from "../../../../help/Api";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveeIcon from "@material-ui/icons/Save";

import { useHistory, useLocation } from "react-router-dom";

import toast from "react-hot-toast";
import SearchIcon from "@material-ui/icons/Search";
import StoreExchangeSearchModal from "./StoreExchange-search-modal";
import { CircularProgress } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import {
  formatTwreed,
  twreedValidateSchema,
} from "../../../../services/twreed-servece";
import _ from "lodash";
import moment from "moment/moment";
import DeleteConfirmDialog from "../../../../components/DeleteConfirmDialog";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
  },
  appbar2: {
    margin: theme.spacing(0, 1),
    justifyContent: "center",
  },
  buttons: {
    margin: theme.spacing(1),
    marginBottom: 25,
  },
  root: {
    direction: "rtl",
    marginBottom: theme.spacing(1),
    // padding: theme.spacing(1),
    padding: 5,
    // width: '100%',
    // height: '100%',
  },
  appBar: {
    top: "auto",
    right: "0.10%",
    bottom: 3,
    left: "0.5%",
    width: "98.9%",
    backgroundColor: "#0009F",
  },
  buttonss: {
    backgroundColor: "blue",
  },
  buttonProgressCAB: {
    color: "#fff",
    marginLeft: 5,
  },
}));

const getFormatTWreedData = (AutoOptions, data, subData) => {
  let rows = subData.map((sub, i) => {
    const item = _.find(AutoOptions.Currencies, ["id", sub.item]);
    // console.log("item is", item);
    return {
      ...sub,
      ...item,
      localId: i,
      mUnit: _.find(item.units, ["id", sub.unit]),
      total: sub?.sum,
    };
  });
  return {
    ...data,
    acc: _.find(AutoOptions.accounts, ["id", data.acc]),
    omla: _.find(AutoOptions.omlats, ["id", data.omla]),
    center:
      data.center != -1
        ? _.find(AutoOptions.costcenters, ["id", data.center])
        : { id: -1, name: "" },
    safe: { id: data.safe, name: data.safe_name },

    date: moment(data.date).format("YYYY-MM-DD"),
    rows: rows,
  };
};

export default function Footer(props) {
  const {
    onShow,
    submit,
    object,
    submitting,
    update,
    formType,
    remove,
    defaultData,
    AutoOptions,
    type,
  } = props;
  const { t } = useTranslation(["forms"]);
  const clasess = useStyles();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  let his = useHistory();

  const handleSubmit = (isPrint = false, data) => {
    let formatedData = formatTwreed(data);

    console.log("formatedData Data", formatedData);
    if (data.rows?.length > 0) {
      twreedValidateSchema
        .validate(formatedData.twreed, { abortEarly: false })
        .then(async () => {
          if (formType == "edit") {
            await update(formatedData).then((res) => {
              if (isPrint && res) print(data);
            });
          } else {
            await submit(formatedData).then((res) => {
              if (isPrint && res)
                print({ ...data, no: res.data.no, notes: res.data.notes });
            });
          }
        })
        .catch((err) => {
          toast.error(err.errors[0]);
        });
    } else {
      toast.error("يجب ادخال أصناف في الجدول");
    }
  };
  const onClickRow = async (row) => {
    // const tswyaData = getFormatTaswyaData(row);

    await axios
      .get(uri + `TwreedSF/TwreedSFSub?id=${row?.id}`)
      .then((res) => {
        // console.log("new Data", res.data);

        const data = getFormatTWreedData(AutoOptions, row, res.data);

        onShow(data, "edit");
        // onShow(newData, type);
        setOpenSearchModal(false);

        console.log("new Data", data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("لم يتم جلب البيانات");
      });
  };

  const print = (data) => {
    const info = { ...data };

    his.push({ pathname: "/TASP", state: info });

    window.location.reload(false);
  };

  const backHome = () => {
    his.push({ pathname: "/" });
    window.location.reload(false);
  };

  return (
    <>
      <DeleteConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Grid item className={clasess.root} container>
        <AppBar size="small" className={clasess.appBar}>
          <Toolbar style={{ direction: "ltr" }}>
            <IconButton edge="start" color="blue" aria-label="open drawer">
              <Button
                className={clasess.buttonss}
                style={{ direction: "ltr", backgroundColor: "blue" }}
                variant="contained"
                color="primary"
                fontSize="large"
                size="medium"
                onClick={backHome}
                startIcon={<CancelIcon />}
              >
                {" "}
                {t("exit")}
              </Button>
            </IconButton>
            <Button
              style={{ color: "#fff" }}
              onClick={() => setOpenSearchModal(true)}
              startIcon={<SearchIcon />}
            >
              {" "}
              {t("search")}
            </Button>

            <div className={clasess.grow} />
            {formType == "edit" && (
              <Button
                style={{ direction: "ltr" }}
                variant="contained"
                color="primary"
                fontSize="large"
                size="medium"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    onConfirm: () => {
                      remove(object.id);
                    },
                  });
                }}
                startIcon={<Delete />}
                disabled={submitting}
              >
                {t("delete")}
                {submitting && (
                  <CircularProgress
                    size={20}
                    className={clasess.buttonProgressCAB}
                  />
                )}
              </Button>
            )}

            {/* <IconButton color="inherit">
              <Button
                style={{ direction: "ltr" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSubmit(true, object);
                }}
                disabled={submitting}
                fontSize="large"
                size="medium"
                startIcon={<PrintIcon />}
              >
                {t("print")}
                {submitting && (
                  <CircularProgress
                    size={20}
                    className={clasess.buttonProgressCAB}
                  />
                )}
              </Button>{" "}
            </IconButton> */}
            <IconButton color="inherit">
              <Button
                variant="contained"
                color="primary"
                fontSize="large"
                size="medium"
                onClick={() => handleSubmit(false, object)}
                disabled={submitting}
                startIcon={<SaveeIcon />}
              >
                {t("save")}
                {submitting && (
                  <CircularProgress
                    size={20}
                    className={clasess.buttonProgressCAB}
                  />
                )}
              </Button>
            </IconButton>
            <Button
              variant="contained"
              color="primary"
              fontSize="large"
              size="medium"
              onClick={() => onShow(defaultData)}
            >
              {t("new")}{" "}
            </Button>
          </Toolbar>
        </AppBar>
      </Grid>
      <StoreExchangeSearchModal
        {...{
          open: openSearchModal,
          type: type,
          onClose: () => {
            setOpenSearchModal(false);
          },
          onRowClick: async (data) => {
            await onClickRow(data);
          },
        }}
      />
    </>
  );
}
