import React, { useState, useEffect } from "react";
import {
    Button,
    Chip,
    colors,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";

import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
    useStylesDPM,
    useStylesHed,
    useStylesSpDial,
    useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { uri } from "../../../../../help/Api";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";

import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";

import MultiCodeForm from "./MultiCodeForm";
import * as yup from 'yup';
import Reply from "@material-ui/icons/Reply";
import Save from "@material-ui/icons/Save";
import toast from "react-hot-toast";
import MultiCodeTable from "./MultiCodeTable";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
    },

    headerTitle: {
        // backgroundColor: "#f18584",
        textAlign: "center",
        fontSize: 16,
        width: "100%",
        fontWeight: "bold"
    },

    blCard: {
        backgroundColor: "#fff",
        borderColor: "#f18584",
    },
    root3: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: "#f18584",
        direction: "rtl",
        marginTop: 5,
        padding: 5,
        // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
        boxShadow:
            "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
    },
}));


export default function MultiCode(props) {
    const {  items, multiCodeList, setMultiCodeList, openMulti,setOpenMulti ,transitionsmu,handCloseMulti,multiCodeValue,AutoOptions } = props;

    const multiValue = {
        // symbol:"",
        barcode:"",
        curr:items.id,
        unitID:AutoOptions.Units[0].id,

      };
      const multiValueauto = {
        // symbol:"",
       
        unit:{id:AutoOptions.Units[0].id,name:AutoOptions.Units[0].name},

      };
    const classes = useStyles();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const { t } = useTranslation(["forms", "translation"]);
    const [style, setStyle] = useState(inshst.custm);

    const [data, setData] = useState(multiValue);
    const [autoo, setAutoo] = useState(multiValueauto);

    const [fetching, setFetching] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const unitValidateSchema = yup.object().shape({
        barcode: yup.string().required("لا يوجد باركود للصنف"),
      })
    const onSubmit = (e) => {
        e.preventDefault();

        unitValidateSchema.validate(data, { abortEarly: false })
            .then(function (valid) {
                setMultiCodeList([...multiCodeList, data]);
                setData(multiValue);
                setIsNew(true);
                setSubmitting(false);
            }).catch(function (err) {
                toast.error(err.errors[0]);
                setSubmitting(true);
                console.log(err.errors);
            })
       

    }
    const onDelete = (rowdata) => {
        var newData = multiCodeList.filter((obj) => obj.tableData.id !== rowdata.id);
        // let dataDelete = [...multiCodeList];

        // rowdata.forEach((rd) => {
        //   dataDelete = dataDelete.filter((t) => t.tableData.barcode !== rd.tableData.barcode);
        // });
    
        // setIsData([...dataDelete]);
        // var newData = multiCodeList.filter((obj) => obj.barcode != rowdata.barcode);

        // console.log(newData);
        setMultiCodeList([...newData]);
        // clear textBox
        setIsNew(true);
    }
    const clasPM = useStylesDPM();

    const his = useHistory();
    const onChangeData = (propName) => (event) => {
        setData({ ...data, [propName]: event.target.value });
    };

    const onChangeAutoData = (prop, newValue) => {
        // console.log(prop+' - '+newValue.id)
        setAutoo({
            ...autoo,
            [prop]: newValue,
          });
        setData({
          ...data,
          [prop]: newValue,
          unitID: prop == "unit" ? newValue.id : 1,
        });
      };
    
    return (

        <Dialog
        fullScreen
        className={clasPM.rootPM}

        open={openMulti}
        // xs={{'& .MuiDialog-paper':{width: '80%', maxHeight:500}}}
       lg={{direction:"rtl", minHeight:600}}
      //   open={true}
        onClose={handCloseMulti}
        aria-labelledby="form-dialog-title"
        TransitionComponent={transitionsmu}
        key={transitionsmu ? transitionsmu.name : ""}
      >
        <DialogTitle id="form-dialog-title" className={classes.headerTitle}>
        {"Insert Barcode"}
        </DialogTitle>
        <DialogContent >
        <div className={classes.root}>
            {/* <Grid container item xs={12}>
                <Typography
                    size="small"
                    className={classes.headerTitle}
                    color="primary"
                >
                    {"Insert Barcode"}
                </Typography>
            </Grid> */}
            <Typography
                component="div"
                style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
            >
                <Container
                    component={Paper}
                    maxWidth={false}
                    style={{
                        padding: "7px",
                        paddingBottom: "0px",
                        borderBottomColor: inshst.colors.cust,
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                    }}
                >

                    <MultiCodeForm
                        {...{
                            items,
                            multiCodeList,
                            setMultiCodeList,
                            data,
                            setIsNew,
                            onSubmit,
                            onChangeData,
                            AutoOptions,
                            onChangeAutoData,
                            autoo,
                        }}
                    />
           <Grid
                            container
                            justifyContent="center"
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            spacing={1}
                        >
  
                            <Grid item>
                                    <Button
                                        disabled={fetching}
                                        variant="contained"
                                        startIcon={<Save />}
                                        size="large"
                                        style={
                                            ( inshst.sal.iconBtn,
                                                { borderRadius: 0 })
                                        }
                                        className={[clasui.button]}
                                        // onClick={() => onSubmit()}
                                        onClick={onSubmit}

                                    >
                                        {t("forms:save")}
                                    </Button>
                                </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    // startIcon={<KeyboardReturnSharpIcon />}
                                    startIcon={<Reply />}
                                    color="secondary"
                                    size="large"
                                    aria-label="open drawer"
                                    className={[clasui.button, clasSp.clcan]}
                                    style={
                                        ( inshst.sal.iconBtn,
                                            { borderRadius: 0 })
                                    }
                                    onClick={() => handCloseMulti("cancle")}

                                    // onClick={() => transitionsmu()}
                                >
                                    {/* {t("forms:exit")} */}
                                    {"return"}
                                </Button>
                            </Grid>
                        </Grid>
                </Container>

                <br />
                <Container
                    component={Paper}
                    maxWidth={false}
                    style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",

                    }}
                >
                    <Grid container justifyContent="center">
                        <Grid item container justifyContent="center" xs={12}>

                            <MultiCodeTable
                                {...{
                                    multiCodeList,
                                    onDelete,
                                    setIsNew,
                                    setMultiCodeList,
                                }}
                            />
                           
                        </Grid>
                    </Grid>
                </Container>
            </Typography>
        </div>
        </DialogContent>

      </Dialog>

    );
}
