import React from "react";
import { uri } from "../../../help/Api";
import useFetchObjectByPost from "../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import BackdropScI from "../../../services/BackdropScI";
import Empoyees from "./Empoyees";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import { URL } from "../../../api/customer";
import { useLocation } from "react-router-dom";
import { sto } from "../../../Context/InshData";
import LoadingPage from "../../../services/LoadingPage";
import { useApi } from "@material-ui/data-grid";
export default function EmpoyeesCon() {
  const location = useLocation();
  const type = 1;
  const tb = {
    wait: true,
    Countries: true,
    // PermissionsElemnts: true,
    Employees: true,
    Branches: true,
    Departments: true,
    jobs: true,
    Managements: true,
    // params
    parms: {
      branch: sto.branchi,
      iuserid: sto.idi,
      // iformid: 20001,
      // iformidCuCo:dataF[3].id, /* for curr and yashml */
    },

    autos: {
      Countries: true,
      Employees: true,
      Branches: true,
      Departments: true,
      Managements: true,
      jobs: true,
    },
  };
  const url = uri + "Res";

  const {
    object: data,
    fetching: fetchinData,
    fetch,
  } = useFetchObjectByPost(url, tb);
  const {
    data: newAccount,
    fetching,
    fetchURL,
  } = useFetchURL({
    ...{
      url: uri + "employee/newAccount",
    },
  });

  useEffect(() => {
    console.log("the data is", data);
  });
  if (fetchinData || !data?.Departments || !newAccount || fetching)
    return <LoadingPage />;
  return (
    <Empoyees
      {...{
        data: { ...data },
        newAccount,
        fetchinData,
        fetchURL,
        isDialog: false,
      }}
    />
  );
}
