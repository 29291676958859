import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function DeptPeriodTable(props) {
  const { data, rptParams} = props;

  let per1=rptParams.firstPeriod+"";
  let per2=rptParams.secondPeriod+"";
  let per3=rptParams.thirdPeriod+"";
  let per4=rptParams.fourthPeriod+"";
  let per5=">"+rptParams.fourthPeriod+"";


  const stylH={
   // borderWidth: 1,
    border: "solid 0.6px",
    borderColor: "black",
    borderStyle: "solid",
    fontFamily: "Amiri-Regular",
    fontWeight: 700,
    fontSize: 14,
    color:"#fff"
  }
  const stylRo={
    BorderBottom: "solid 0.5px",
    //borderStyle: "solid",
    borderColor: "gray",
    fontFamily: "Amiri-Regular",
    fontWeight: 700,
    fontSize: 12,
    color:"#000"
  }
  
  const { t } = useTranslation(["forms"]);
  const classes = useStyles();
  return (
    <TableContainer component={Paper} >
      <Table 
      //className={classes.table} 
      size="small" aria-label="a dense table" >
        <TableHead>
          <TableRow
            style={{
              backgroundColor:"#81007f",
              color: "#000",
              fontWeight: 800,
              fontFamily: "Amiri-Regular",
              fontSize: 15,
              border: "solid 0.6px"
            }}
          >
            <TableCell style={stylH} align="center">
              {t("tno")}
            </TableCell>
            <TableCell style={stylH} align="center">
            {rptParams.type.id==2 ? "رقم المورد" : "رقم العميل"}
            </TableCell>
             <TableCell style={stylH} align="center">
             {rptParams.type.id==2 ? "اسم المورد" : "اسم العميل"}
             </TableCell>
            <TableCell style={stylH} align="center">
              {"إجمالي الرصيد"}
            </TableCell>
            <TableCell style={stylH} align="center">
              {"الفترة الأولى" }{" "}{"0"+"-"+per1}
            </TableCell>
            <TableCell style={stylH} align="center">
            {"الفترة الثانية"}{" "}{per1+"-"+per2}
            </TableCell>
            <TableCell style={stylH} align="center">
            {"الفترة الثالثة"}{" "}{per2+"-"+per3}
            </TableCell>
            <TableCell style={stylH} align="center">
            {"الفترة الرابعة"}{" "}{per3+"-"+per4}
            </TableCell>
            <TableCell style={stylH} align="center">
            {"الفترة الخامسة"}{" "}{per5}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,i) => (
            <TableRow key={row.cust_ID}
            style={{ padding: "5px 0px",
                textAlign: "-webkit-center",
                margin: "0px",
                whiteSpace: "nowrap",
                color: "#000",
                fontWeight: 800,
                fontFamily: "Amiri-Regular",
                fontSize: 15,
                border: "solid 0.6px"}}>
              <TableCell   style={stylRo} align="center">{i+1}</TableCell>
              <TableCell   style={stylRo} align="center">{row.cust_ID}</TableCell>
              <TableCell   style={stylRo} align="right">{row.Customer_Name}</TableCell>
              <TableCell  style={stylRo} align="center" >{row.totalMony}</TableCell>
              <TableCell  style={stylRo} align="center" >{row.FirstPeriod?.toFixed(2)}</TableCell>
              <TableCell  style={stylRo} align="center" >{row.SecondPeriod?.toFixed(2)}</TableCell>
              <TableCell  style={stylRo} align="center" >{row.ThirdPeriod?.toFixed(2)}</TableCell>
              <TableCell  style={stylRo} align="center" >{row.FourthPeriod?.toFixed(2)}</TableCell>
              <TableCell  style={stylRo} align="center" >{row.FivethPeriod?.toFixed(2)}</TableCell>


            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
