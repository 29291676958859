import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@material-ui/lab';
import Button from "@material-ui/core/Button";
import EditIcon from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import {uri} from '../../../../../help/Api';

import { ButtonGroup } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import toast, { Toaster } from 'react-hot-toast';

import {useStylesAuto, useStylesLbl,} from '../../../../../St/comps/UseStyle';
import KhamTable from './items_kham_table';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const unitValidateSchema = yup.object().shape({

  unit: yup.string().required("قم بتحديد الوحدة"),
  perc: yup.string().required("قم بتحديد العبوة"),
  purch: yup.string().required("حدد قيمة الشراء"),
  sale: yup.string().required("حدد قيمة البيع"),
  barcode: yup.string().required("لا يوجد باركود للصنف"),
})

const useStyles = makeStyles((theme) => ({
    textField3: {
      width: '100%',
      padding:5,
    },
    textField44: {
     width: '50%',
      marginTop:5,
      marginBottom:-5,
    },
 
 
      textField: { "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color:'navy', fontSize:'0.8rem', },
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": { color:'navy', fontSize:'0.7rem',
       lineHeight: '0.5rem',}, "& .MuiOutlinedInput-inputMarginDense": 
      { paddingLeft:4,paddingRight:4, paddingTop:6,paddingBottom:4, textAlign:'center', fontSize: '1rem' }, margin: 3,},
  
    root: {
      direction: 'rtl',
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      paddingRight: '1px',
      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    },
  
  }));

  const defaultData ={
    id:'',
    curr: '',
    unit: '',
    unitName: '',
    perc: '',
    purch: '',
    sale:'',
    barcode: '',
    unit_convert: '',
  }


export default function KhamItems(props){

    const {addSubUnitFunc, units, currUnitList, setCurrUnitList } = props;
    const {t} = useTranslation();

    const classes = useStyles();
    const [valueUnit, setValueUnit] = React.useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [data, setData] = useState(defaultData);
    const [isNew, setIsNew]= useState(true);

    const clasAo = useStylesAuto();
    const claslbl = useStylesLbl();

    const onSubmit= (e)=>{
      e.preventDefault();
      unitValidateSchema.validate(data, { abortEarly: false })
      .then(function (valid) {
        // addSubUnitFunc(data);
        if(!isNew){
          var newData = currUnitList.map((obj)=>{
            if(obj.tableData.id == data.id)
              return data;
            return obj;
          })
          console.log(newData);
          setCurrUnitList(newData);
        }
        else
          setCurrUnitList([...currUnitList, data]);

        // clear textBox
        setData(defaultData);
        setValueUnit(null);
        setIsNew(true);
        setSubmitting(false);
      }).catch(function (err) {
        toast.error(err.errors[0]);
        setSubmitting(true);
        console.log(err.errors); 
      })

    }
    const onDelete =()=>{
      var newData = currUnitList.filter((obj)=> obj.tableData.id != data.id);
      console.log(newData);
      setCurrUnitList(newData);
      // clear textBox
      setData(defaultData);
      setValueUnit(null);
      setIsNew(true);
    }

    const onChangeData = (prop) => (event) =>{
      setData({ ...data, [prop]: event.target.value })
    } 

    const buttons = [
      <Button key="one"
        style={{color:'#fff', borderRadius:0, borderBottom:'solid 1px white'}}
          onClick={()=>{
            setData(defaultData)
            setIsNew(true);
          }}
        >
          <AddIcon />
      </Button>,
      <Button key="two"
        style={{color:'#fff', borderRadius:0, borderBottom:'solid 1px white'}}
        onClick={onSubmit}
      ><SaveIcon />
      </Button>,
      
      <Button key="three"
        hidden={true}
        style={{color: isNew ? '#999': '#fff', borderRadius:0, }}
        disabled={isNew}
        onClick={onDelete}
        >
          <Delete />
      </Button>,
    ];

    return(
        <Grid className={classes.root} container>
          <Toaster />
           <Grid container lg={2} md={2} sm={2} xs={2} 
        style={{ padding:'0px', textAlign: '-webkit-center',    
       }}
        >
          <ButtonGroup
        orientation="vertical"
        aria-label="vertical primary button group"
        style={{marginTop:'-10px', backgroundColor: '#cd5c5c'}}
      >
        {buttons}
      </ButtonGroup>
      </Grid>

        <Grid container style={{border: "2px"}}
        className={"container-disabled"}
        disabled={true} lg={10} md={10} sm={10} xs={10}>
        <Grid  item  lg={5} md={5} sm={5} xs={5}> 
          <Autocomplete classes={clasAo}  
                 value={valueUnit}
                onChange={(event, newValue) => { 
                  setValueUnit(newValue);  
                  setData({...data,
                     unit: newValue.id,
                     unitName: newValue.name});
                }}
             
                id="combo-box-demo" size="small"
                options={units} 
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                disableClearable
                renderInput={(params) => <TextField  {...params} defaultValue="null" InputLabelProps={{shrink: true}}  label={t('forms:unit')} variant="outlined" />} 
                InputLabelProps={{className:claslbl.clrSale}}
                />
            
        </Grid>

        <Grid item className={classes.textField} lg={3} md={3} sm={3} xs={3}>
        <TextField type="number" fullWidth variant="outlined"
            size="small" id="purch" label={t('forms:purch-price')}
            error={submitting && data.purch===''}
            name="purch" value={data.purch} InputLabelProps={{ shrink: true }}
            onChange={onChangeData('purch')} />
        </Grid>

        
        <Grid item className={classes.textField} lg={3} md={3} sm={3} xs={3}>
        <TextField type="number" fullWidth variant="outlined"
            size="small" id="stay" label={t('forms:sale-price')}
            error={submitting && data.sale ===''}
            name="sale" value={data.sale} InputLabelProps={{ shrink: true }}
            onChange={onChangeData('sale')} />
        </Grid>
        <Grid item className={classes.textField} lg={5} md={5} sm={5} xs={5}>
        <TextField type="text" fullWidth variant="outlined"
            size="small" id="stay" label={t('forms:barcode')}
            name="barcode" value={data.barcode} InputLabelProps={{ shrink: true }}
            onChange={onChangeData('barcode')} />
        </Grid>
        <Grid item className={classes.textField} lg={3} md={3} sm={3} xs={3}>
        <TextField type="text" fullWidth variant="outlined"
            size="small" id="perc" label={t('forms:packaging')}
            name="perc" value={data.perc} InputLabelProps={{ shrink: true }}
            onChange={onChangeData('perc')} />
        </Grid>
        </Grid>
      
      
      <KhamTable 
      {...{
        currUnitList,
        selectRowHandle:(row)=>{
          setData({...row, id:row.tableData.id.toString()});
          console.log(row);
          setValueUnit({id: row.unit, name: row.unitName,});
          setIsNew(false);
          // setCurrUnitList
        }
      }}
      />

      </Grid>
    )
    
}