
  export const style = {
    color:{
          whi:{
            color:'#fff',
          },
          sal:{
            color:'#008001',
          },
          parsh:{
            color:'#008081',
          },
          safe:{
            color:'#484198',
          },
          customer:{
            color:'#81007f',
          },
          account:{
            color:'#898989',
          },
          bascData:{
            color:'#d06161',
          },
          user:{
            color:'#4a82b9',
          },
          setting:{
            color:'#5dbf9e',
          },  
          cloudwhit:{color:'#3a97c4'},
          clouddone:{color:'#7ec55b'},

    },

    backColor:{
      sal :{
        background:'#008001',
       },
       RetSal:{
        background:'#ef5350'
       },
       parsh :{
        background:'#008081',
       },
       RetParsh:{
        background:'#ef5350'
       },
       safe :{
        background:'#484198',
       },
       customer :{
        background:'#81007f',
       },
       account:{
        background:'#898989',
      },
      bascData:{
        background:'#d06161',
      },
      user:{
        background:'#4a82b9',
      },
      setting:{
        background:'#5dbf9e',
      },
    }

    
   }
  
  //  export const color = {
  //   sal:{
  //     color:'#008081',
  //   },
  //   parsh:{
  //     color:'#008081',
  //   },
  //  }

  //  color: green[500],

  // color: '#008081',
  // color: '#008081',