import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import useFetchURL from '../../../../components/Hooks/useFetchURL';
import { uri } from '../../../../help/Api';
import { useEffect } from 'react';
import SalesChartView from './SalesChart-view';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    // height: 260,
    position: 'relative'
  },
}));

const YearSales = props => {
  const { className, data, fetching, ...rest } = props;
  const classes = useStyles();


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardContent>
          <Typography style={{paddingBottom:'5px'}} component="div" variant="h6">
             المبيعات الشهرية
          </Typography>
        <Divider />
        <div className={classes.chartContainer}>
          {
            (!data || fetching)?
              <CircularProgress />
              :
              <SalesChartView {...{
                data: data? data: [],    
              }} />
          }
          
        </div>
      </CardContent>
    </Card>
  );
};

YearSales.propTypes = {
  className: PropTypes.string
};

export default YearSales;
