import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";

function FooterA({ found }) {
  const cls = useRptAF();
  const styf = { fontWeight: "1200" };
  const stysub = { fontWeight: "bold" };
  const styfC = { fontWeight: "500" };
  return (
    <Grid
      item
      container
      className={cls.rootFotr}
      style={{
        textAlign: "center",
        direction: "rtl",
        // top: 'auto',
        // bottom: 10,
        // marginBottom: "1rem",
        // background: '#fff',
        // position:'fixed'
        // marginTop:'1rem',
        // border:'solid'
      }}
    >
      <Grid xs={12}>
        {" "}
        <Typography className={cls.line}></Typography>{" "}
      </Grid>
      <Grid xs={12}>
        {" "}
        <div style={styf}>{found.Address}</div>
        <Typography className={cls.titleF}>{found.Address}</Typography>{" "}
      </Grid>

      <Grid item container className={cls.subF} xs={12}>
        <Grid xs={1} className={cls.infoF}>
          
          {/* <Typography>هاتف:</Typography> */}
          <div style={styf}>{"هاتف:"}</div>

        </Grid>
        <Grid xs={3} className={cls.infoF}>
          {/* <Typography>{found.Tel}</Typography> */}
          <div style={styf}>{found.Tel}</div>

        </Grid>
        <Grid xs={1} className={cls.infoF}>
        <div style={styf}>{"مبايل:"}</div>

          {/* <Typography>مبايل:</Typography> */}
        </Grid>
        <Grid xs={3} className={cls.infoF}>
          {/* <Typography>{found.Mobile}</Typography> */}
          <div style={styf}>{found.Mobile}</div>

        </Grid>
        <Grid xs={1} className={cls.infoF}>
          {/* <Typography>فاكس:</Typography> */}
          <div style={styf}>{"فاكس:"}</div>

        </Grid>
        <Grid xs={3} className={cls.infoF}>
          {/* <Typography>{found.Fax}</Typography> */}
          <div style={styf}>{found.Fax}</div>

        </Grid>
      </Grid>
    </Grid>
  );
}
export default FooterA;
