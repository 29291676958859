import React, { useState, useEffect } from "react";

import { SAdefaultValues, selectDateOrTime } from "../../../../../services/saleAnalysis-services";
import {
    Button,
    Chip,
    colors,
    Container,
    Dialog,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
    useStylesHed,
    useStylesSpDial,
    useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
// import { SearchActivityReports } from "../../../../../services/accMasn-services";
import moment from "moment";
import axios from "axios";
import { sto } from "../../../../../Context/InshData";
import SearchInvTable from "./SearchInvTable";
import SearchInvForm from "./SearchInvForm";
import DailyReportFilter from "../../../../Sales/BackEnd/Tab1Rout/EveryDay/DailyReportFilter";
import ReplyIcon from "@material-ui/icons/Reply";

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
    },
    itemW: {
        // display:'flex',
        width: "90%",
    },
    headerTitle: {
        backgroundColor: colors.green[50],
        textAlign: "center",
        fontSize: 20,
        width: "100%",
    },

    allWidth: {
        minWidth: 650,
    },
    checStyl: {
        marginTop: "17px",
        fontSize: "16px",
        padding: "-1px",
    },
    purchTotalCard: {
        backgroundColor: colors.blue[50],
        borderColor: colors.blue[300],
    },
}));

export default function SearchInvModel(props) {
    const { AutoOptions,
        openDw,
        handCloseDw,
        transition,
        showChosedInv,
        invsShow,
        stElemnt,
        style,
        gets,
        setOr,
        or,
        optionsCustomers,
        PcIdIvPc,
        setting,
        setIsClick,
        ished
    } = props;
    let isPurches = false;

    if (setting.PrTy_InPr == "01" || setting.PrTy_InPr == "11" || setting.PrTy_InPr == "22") {
        isPurches = true;
    } else {
        isPurches = false;
    }
    let idUser = AutoOptions.Users[0].id;
    let nameUser = AutoOptions.Users[0].name;
    let idBranch = AutoOptions.Branches[0].id;
    let nameBranch = AutoOptions.Branches[0].name;


    const initialSearchObject = {
        dateFrom: moment(new Date().setDate(new Date().getDate() - 1)).format("YYYY-MM-DD"),
        timeFrom: moment(new Date()).startOf("day").format("HH:mm"),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
        timeTo: moment(new Date()).format('HH:mm'),
        user: { id: sto.User_typei == 1 ? -1 : idUser, name: sto.User_typei == 1 ? 'الكل' : nameUser },
        branch: { id: sto.User_typei == 1 ? -1 : idBranch, name: sto.User_typei == 1 ? 'الكل' : nameBranch },
        selectTime: selectDateOrTime[0],
        sales_man: { id: -1, name: "الكل" },
        cust: { id: -1, name: "الكل" },
        date_time: "false",
        PcIdIvPc: "",
        refno: "",
        bill_no: "",
        isAdmin: sto.User_typei == 1,
        bank: { id: -1, name: "الكل" },
        cust_mobile: "",
        inv_type: { id: -1, name: "الكل" },
        pay_type: { id: -1, name: "الكل" },
        trans_no: -1,
        center: { id: -1, name: "الكل" },
    };
    const [customers, setCustomers] = useState(() => {
        return [{ id: -1, name: "الكل" }, ...optionsCustomers];
    });

    const [rptParams, setRptParams] = React.useState(initialSearchObject);
    const classes = useStyles();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const { t } = useTranslation(["forms", "translation"]);

    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const viewHandle = async () => {

        setData([]);
        setFetching(true);
        await axios
            .get(
                uri +
                `RptSales/InvSearch?PcIdIvPc=${PcIdIvPc}
        &&branch=${rptParams.branch?.id}
        &&invNo=${rptParams.bill_no}
        &&dateFrom=${rptParams.dateFrom + ' ' + rptParams.timeFrom}
        &&dateTo=${rptParams.dateTo + ' ' + rptParams.timeTo}
        &&refNo=${rptParams?.refno}
        &&trans_no=${rptParams?.trans_no}
        &&inv_type=${rptParams.inv_type?.id}
        &&pay_type=${rptParams.pay_type?.id}
        &&cust_id=${rptParams.cust?.id}
        &&cust_name=${rptParams.cust.name}
        &&cust_mobile=${rptParams?.cust_mobile}
        &&center=${rptParams.center?.id}
        &&sales_man=${rptParams.sales_man?.id}
        &&user=${rptParams.user?.id}
        &&bank=${rptParams.bank?.id}
        &&date_time=${rptParams.selectTime.name}`
            )
            .then((res) => {
                setData(res.data);
                console.log(" the data is", res.data);
                setFetching(false);
            })
            .catch((err) => {
                setFetching(false);
                console.log(err);
                // toast.err("يوجد خطأ في الجلب");
                setData([]);
            });
    };
    const his = useHistory();


    let excel_name = "Invoices";
    const excelHeaders = [
        { label: "الرقم", key: "id" },
        { label: "التاريخ", key: "date" },
        { label: "الوقت", key: "time" },
        { label: (isPurches ? "المورد" : "العميل"), key: "customer_name" },
        { label: "المبلغ", key: "net_total" },
        {
            label: "نوع الفاتورة", key: "inv_type",
            render: (data) => (data.invData.inv_type == 1 ? "اجل" : "نقدي"),
        },

        {
            label: "طريقة الدفع", key: "pay_type",
            render: (data) =>
                data.inv_type == 1 ? "" : data.pay_type == 1 ? "كاش" : "شبكة",
        },
        { label: "المستخدم", key: "username" },
        // {title: "التقاصيل", field: "",},
    ];
    useEffect(() => {
        console.log("the data is", rptParams);
    });
    const changTField = (prop) => (e) => {
        setRptParams({ ...rptParams, [prop]: e.target.value });

        // prop=='dateFrom' ? rptParams.dateFrom=e.target.value : rptParams.dateTo=e.target.value
    };
    const onChangeData = (propName) => (event) => {
        setRptParams({ ...rptParams, [propName]: event.target.value });
    };
    const onChangeAutoData = (propName, newValue) => {
        setRptParams({ ...rptParams, [propName]: newValue });
    };
    return (
        <Dialog
            fullScreen
            open={openDw}
            onClose={handCloseDw}
            TransitionComponent={transition}
            key={transition ? transition.name : ""}
        >
            <div className={classes.root}>
                <Grid container item xs={12}>
                    <Typography
                        size="small"
                        className={classes.headerTitle}
                        color="primary"
                    >
                        {" البـحـث فـي الـفـواتـيـر "}
                    </Typography>
                </Grid>
                <Typography
                    component="div"
                    style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
                >
                    <Container
                        component={Paper}
                        maxWidth={false}
                        style={{
                            padding: "10px",
                            paddingBottom: "0px",
                            borderBottomColor: isPurches ? colors.blue[300] : colors.green[300],
                            borderBottomWidth: "1px",
                            borderBottomStyle: "solid",
                        }}
                    >
                        <DailyReportFilter {...{ rptParams, setRptParams, isPurches }} />
                        <SearchInvForm
                            {...{
                                AutoOptions,
                                rptParams,
                                onChangeData,
                                onChangeAutoData,
                                isPurches,
                                fetching,
                                gets,
                                customers,
                                changTField,
                            }}
                        />
                        <Grid
                            container
                            justifyContent="center"
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            spacing={1}
                        >
                            <Hidden smUp={["sm"]}>
                                <Grid item>
                                    <Button
                                        disabled={fetching}
                                        variant="contained"
                                        startIcon={<ScannerIcon />}
                                        size="large"
                                        style={
                                            (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                                                { borderRadius: 0 })
                                        }
                                        className={[clasui.button]}
                                        onClick={() => viewHandle()}
                                    >
                                        {t("forms:show")}
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Hidden only={["xs"]}>
                                <Grid item>
                                    <Button
                                        disabled={fetching}
                                        variant="contained"
                                        startIcon={<ScannerIcon />}
                                        size="large"
                                        style={
                                            (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                                                { borderRadius: 0 })
                                        }
                                        className={[clasui.button]}
                                        onClick={() => viewHandle()}
                                    >
                                        {t("forms:view")}
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Hidden smUp={["sm"]}>

                                <Grid item >
                                    {data.invData && !fetching && (
                                        <CSVLink
                                            separator={";"}
                                            filename={excel_name}
                                            data={data.invData}
                                            headers={excelHeaders}
                                        >
                                            {" "}
                                            <Button
                                                disabled={fetching}
                                                variant="contained"
                                                startIcon={<TableChart />}
                                                size="large"
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                className={[clasui.button]}
                                            >
                                                {"اكسل"}
                                            </Button>
                                        </CSVLink>
                                    )}
                                </Grid>
                            </Hidden>
                            <Hidden only={["xs"]}>
                                <Grid item >
                                    {data.invData && !fetching && (
                                        <CSVLink
                                            separator={";"}
                                            filename={excel_name}
                                            data={data.invData}
                                            headers={excelHeaders}
                                        >
                                            {" "}
                                            <Button
                                                disabled={fetching}
                                                variant="contained"
                                                startIcon={<TableChart />}
                                                size="large"
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                className={[clasui.button]}
                                            >
                                                {"تصدير اكسل"}{" "}
                                            </Button>
                                        </CSVLink>
                                    )}
                                </Grid>
                            </Hidden>
                            {/* <Grid item>
                            <Button
                                disabled={fetching}
                                variant="contained"
                                startIcon={<PrintIcon />}
                                color="primary"
                                size="large"
                                className={[clasui.button, clasSp.clSec]}
                                style={
                                    (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                                        { borderRadius: 0 })
                                }
                                onClick={printHandle}
                            >
                                {t("forms:print")}
                            </Button>
                        </Grid> */}
                            <Grid item>
                                <Button
                                    variant="contained"
                                    // startIcon={<KeyboardReturnSharpIcon />}
                                    startIcon={<ReplyIcon />}
                                    color="secondary"
                                    size="large"
                                    aria-label="open drawer"
                                    className={[clasui.button, clasSp.clcan]}
                                    style={
                                        (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                                            { borderRadius: 0 })
                                    }
                                    onClick={() => handCloseDw()}
                                >
                                    {/* {t("forms:exit")} */}
                                    {"الفاتورة"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth={true} style={{ padding: "4px" }}>
                        <Grid container spacing={1} style={{ paddingTop: "10px" }}>
                            <Grid item lg={2} md={2} sm={4} xs={6}>
                                <TotalCard
                                    {...{
                                        className: isPurches ? classes.purchTotalCard : null,
                                        title: " عدد السجلات",
                                        value: data?.totals?.count_row,
                                    }}
                                />
                            </Grid>
                            <Grid item lg={2} md={2} sm={4} xs={6}>
                                <TotalCard
                                    {...{
                                        className: isPurches ? classes.purchTotalCard : null,
                                        title: "الإجمالي: ",
                                        value: data?.totals?.tot_net.toFixed(2),
                                        // value: (data?.reduce((a, v) => (a = a + v.net_total), 0).toFixed(2)),
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </Container>
                    <br />
                    <Container
                        component={Paper}
                        maxWidth={false}
                        style={{
                            paddingRight: "5px",
                            paddingLeft: "5px",
                        }}
                    >
                        <Grid container justifyContent="center">
                            <Grid item container justifyContent="center" xs={12}>
                                <BeatLoader loading={fetching} color={colors.green[300]} />
                                {!fetching && (
                                    <SearchInvTable
                                        {...{
                                            //  data: data ? data : [],
                                            data: data?.invData ? data.invData : [],
                                            isPurches, showChosedInv, setOr, or, setting, setIsClick, ished
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Typography>
            </div>
        </Dialog>
    );
}
