import { ButtonBase, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import { getBase64 } from "../../../../services/ImageServices";
import Image from '../../../../assets/img/VAT.svg';
import { useEffect } from "react";

const styles = {
  paperContainer: {
      backgroundImage: `url(${Image})`,
  }
};
const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  itemImage: {
    marginRight: 20,
    marginTop: 5,
    marginBottom:10,
    textAlign:'center'
  },

}));


const ItemsImage=(props)=>{

    const {items, setItems} = props;
    const classes = useStyles();
    const [imgUpld, setImgUpld]= useState(false);
      const [fileImg, setFileImg] = useState({
                base64URL: '',
                code64: 'data:image/'+'jpeg'+';base64,',
                code8: '',
                baseURL: false,
            })

     const handleFileInputChange = e => {
    
        let  file = null;
        file = e.target.files[0];
        console.log(imgUpld);
        getBase64(file)
        .then(result => {
            file["base64"] = result;
            let n = 23,str;
            if(file.type != 'image/jpeg') n = 22;
        
            str = file.base64.substring(n);
            setItems({
            ...items,
            img: str,
            });
            setImgUpld(true);
        
        })
        .catch(err => {
            console.log(err);
        });

  };

    return(
         <div className={classes.itemImage} container>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple name="img" //value={data.img.toString()}
                type="file"
                onChange={handleFileInputChange}
              />
              <label htmlFor="contained-button-file">
                <ButtonBase style={{ border: 'solid 1px' }} variant="contained" color="primary" component="span" >
                  {
                    !items.img?
                     <lable style={{ textAlign:'center', height: 100, width: 100 }}>
                        'تحميل صورة للمنتج'
                    </lable>:
                    <img src={` ${fileImg.baseURL ? fileImg.code8 : fileImg.code64}
                      ${items.img}
                      `
                      } style={{ height: 100, width: 100 }} alt={items.nameA} />
                    }
                   
                  
                </ButtonBase>
              </label>
            </div>
    )

}

export default ItemsImage;