import React, { useState, useEffect } from "react";
import axios from "axios";
import { uri } from "../../help/Api";
import { tbReGitData } from "../../Context/InshPublic";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import Swipeable from "../../Fronts/Main/Swipeable";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStylesSetting } from "../../St/comps/UseStyle";
import DialogNotes from "../../utils/DialogNotes";
import toast from "react-hot-toast";
import { RefreshBillSettings } from "../../services/settings-services";

export function useRefresh() {
  const [fetching, setFetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetch = async () => {
    setFetching(true);
    setIsError(false);
    await axios
      .post(uri + "Res", tbReGitData)
      .then(async (res) => {
        console.log(res.data);
        await localStorage.setItem("sto", JSON.stringify(res.data));
        toast.success("تم التحديث بنجاح");
      })
      .catch((error) => {
        console.log(error);
        toast.error("لم يتم التحديث");
        setIsError(true);
      });
    await RefreshBillSettings();
    setFetching(false);
  };

  return { fetch, fetching, isError };
}

export default function ReData() {
  const classesStt = useStylesSetting();
  const timer = React.useRef();
  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const [loading, setLoading] = useState(false);
  const clickLoading = (state) => setLoading(state);

  const [dNoteState, setDNoteState] = useState(false);
  const [dNote, setDNote] = useState({ title: "", sub: "", txtBtn: "" });
  const clickDialogNots = (title, sub, txtBtn) => {
    setDNoteState(!dNoteState);
    setDNote({ title: title, sub: sub, txtBtn: txtBtn });
  };

  const storgeing = async () => {
    clickLoading(true);

    timer.current = window.setTimeout(async () => {
      await axios
        .post(uri + "Res", tbReGitData)
        .then((res) => {
          console.log(res.data);
          localStorage.setItem("sto", JSON.stringify(res.data));
          //  alert('تم التحديث بنجاح')
          clickLoading(false);
          //  handleOpenCustomer()
          clickDialogNots("", "تم التحديث بنجاح", "تـمـام");
        })
        .catch((error) => {
          console.log("nooooo sttorgeing");
          alert("لم يتم التحديث");
          clickLoading(false);
        });
    }, 3000);
  };

  return (
    <React.Fragment>
      <div className={classesStt.rootLoad}>
        <div className={classesStt.placeholder}>
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? "800ms" : "0ms",
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        </div>
      </div>

      <CssBaseline />
      <Container maxWidth="lg">
        <Typography
          component="div"
          style={{
            backgroundColor: "#f5f5f5b5",
            height: "100vh",
            textAlign: "center",
          }}
        >
          <Grid item container className={classesStt.rootSett}>
            <Button
              variant="contained"
              className={classesStt.btn}
              color="primary"
              fontSize="large"
              onClick={storgeing}
            >
              {/* {'إضغط لأضافة البيانات في حالة عدم إتصال'} */}
              {"تـطـبـيـق الـتـحـديـثـات"}
            </Button>
            <Swipeable />
          </Grid>

          <DialogNotes
            DialogOpen={dNoteState}
            DialogClose={() => setDNoteState(false)}
            content={{
              title: dNote.title,
              sub: dNote.sub,
              txtBtn: dNote.txtBtn,
            }}
          />
        </Typography>
      </Container>
    </React.Fragment>
  );

  /*
    const tb = {
     //other:true,
     wait:true,
     
     Currencies:true, 
    //  Permissions:true,PermissionsCurr:true,
     groups:true,
    //  groupsSub:true,
     View_CurrencieUnit:true,View_CurrenciesToInv:true,ViewR_SAMER_jard_safe:true,
     ListUsers:true,
     types:true, Safes:true, Stocks:true,Banks:true, Customers:true, salesmen:true,
     costcenters:true, Branches:true,Employees:true,
   
     typesFltr:true,
    
    
     parms:{
      // sProcTypeInvProc:"02",
      ibranchid:sto.branchi, iempid:sto.empi,
      icustmtype:0,
      groupsSub:true,
      // scurrenciestype:"D",
      // iuserid:sto.idi, iformidI:dataF[46].id, iformidC:dataF[3].id,
     },
     autos:{},

   }
   */

  //  const tb = {
  //   /*other:true,*/
  //   wait:true,

  //   Currencies:true,
  //   /* Permissions:true,PermissionsCurr:true, */
  //   groups:true,
  //   View_CurrencieUnit:true,
  //   View_CurrenciesToInv:true,
  //   // View_CurrenciesChose:true,
  //   ViewR_SAMER_jard_safe:true, // rex Error
  //   Users:true,

  //   types:true,
  //   Safes:true,
  //   Stocks:true, // Error
  //   Banks:true,
  //   Customers:true,
  //   salesmen:true,
  //   costcenters:true,
  //   Branches:true,
  //   Employees:true,
  //   typesFltr:true,
  //   PermissionApp:true,
  //   /*params*/
  //   parms:{
  //     // sProcTypeInvProc:"02",
  //     ibranchid:sto.branchi, iempid:sto.empi,
  //     iuserid:sto.idi,
  //     groupsSub:true,
  //     currShow:"D",
  //     // currType:2,
  //     takei:50,
  //     sSearch:"",
  //     // icustmtype:0,
  //     // scurrenciestypeD:"D",
  //     // scurrenciestypeM:"M",
  //     // iformidI:dataF[46].id,
  //     // iformidCuCo:dataF[3].id,
  //   },
  //   autos:{
  //     Users:true,
  //     Branches:true,
  //     Safes:true,
  //     Stocks:true,
  //     Banks:true,
  //     Employees:true,
  //     salesmens:true,
  //     costcenters:true,
  //     groups:true,
  //   }
  // }
}
