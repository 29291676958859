import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Card, CardContent, Typography } from "@material-ui/core";
import { useState } from "react";
import axios from "axios";
import { useEffectOnce } from "react-use";
import { uri } from "../../../help/Api";
import { sto } from "../../../Context/InshData";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    minHeight: "fit-content",
    alignmentBaseline: "center",
    padding: 10,
    height: "100%",
  },
  avatar: {
    width: 110,
    height: 100,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [info, setInfo] = useState({});
  const getInfo = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
        console.log("the info is", res.data?.Found);
      })
      .catch((error) => {});
  };

  useEffectOnce(() => {
    getInfo();
    // getInfoMemo();
  }, []);
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {/* <divContent> */}
      <Avatar
        alt="Person"
        className={classes.avatar}
        // component={RouterLink}
        src={`data:image/jpeg;base64,${info.Logo}`}
        to="/settings"
      />
      {/* <img
        alt="Person"
        className={classes.avatar}
        // component={RouterLink}
        src={`data:image/jpeg;base64,${info.Logo}`}
        to="/settings"
      /> */}
      <Typography className={classes.name} variant="h5">
        {info.nameA}
      </Typography>
      <Typography variant="body1">{sto.branchNames}</Typography>
      <Typography variant="body1">{sto.usernames}</Typography>
      {/* </divContent> */}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
