import moment from "moment/moment";
import { locRem, locSto } from "../Context/Locstor";
import * as yup from "yup";
import { insh } from "../Context/InshData";
import { getAccountOmlat } from "./omlat-services";

export const personTypes = [
  { id: 1, name: "موظف" },
  { id: 2, name: "جهة" },
];
export const defaultValues = {
  id: "",
  date: moment(new Date()).format("YYYY-MM-DD"),
  emp_person: personTypes[0],
  emp_id: "",
  val: "",
  acc_code: "",
  type: "1",
  safe_bank_id: locSto.Stocks != undefined ? locSto.Stocks[0].id : -1,
  sales_emp: locSto.Employees != undefined ? locSto.Employees[0].id : -1,
  check_no: "",
  check_date: moment(new Date()).format("YYYY-MM-DD"),
  checkbank: "",
  check_state: null,
  notes: "",
  end_Dept: "0.0",
  rest_id: "",
  branch: locRem.branchi,
  center: { id: "-1", name: "" },
  ref_no: "",
  emp: null,
  bank: null,
  stock: null,
};

export const EReceiptValidateSchema = yup.object().shape({
  val: yup.string().required("يجب ادخال المبلغ"),
  cust_id: yup.string().required("يجب اختيار العميل"),
  val1: yup.string().required("يجب ادخال مبلغ الاستلام المقابل"),
  tot_val: yup.number().required(""),
  tot_val1: yup
    .number()
    .oneOf([yup.ref("tot_val"), null], "يجب ان يكون المبلغ المحلي متساوي"),
});
export const ExchangeValidateSchema = yup.object().shape({
  val: yup.string().required("يجب ادخال المبلغ"),
  acc_code: yup.string().required("يجب اختيار الحساب "),
});

export function PrepareData(data) {
  let nData = {
    ...data,
    emp_person: data.emp_person?.id,
    emp_id: data.user.id,
    acc_code: data?.acc_code ? data.acc_code?.code : "",
    sales_emp: data.emp ? data.emp.id : "",
    center: data.center.id,
    safe_bank_id: data.type == "1" ? data.stock.id : data.bank.id,
    check_state: data?.check_state ? data.check_state.id : "",
  };

  return nData;
}

function GetItemFromOptions(id, options) {
  const index = options?.findIndex((op) => op.id == id);
  return options[index];
}

export function ReFormatingData(data, AutoOptions) {
  let emp = "",
    bank = "",
    stock = "",
    check_state = "";
  emp = GetItemFromOptions(data.sales_emp, AutoOptions.Users);

  if (data.type == "1") {
    stock = GetItemFromOptions(data.safe_bank_id, AutoOptions.Stocks);
    bank = AutoOptions.Banks[0];
    // cust_omlat = {}
  } else {
    bank = GetItemFromOptions(data.safe_bank_id, AutoOptions.Banks);
    stock = AutoOptions.Stocks[0];
    check_state = data.check_state
      ? GetItemFromOptions(data.check_state, insh.autos.CheckStatus)
      : "";
  }

  const acc_index = AutoOptions.Customers?.findIndex(
    (op) => op.code == data.acc_code
  );

  // when account is customer get error becaouse not found
  let nData = {
    ...data,
    acc_code: AutoOptions.Customers[acc_index],
    emp_person: GetItemFromOptions(data.emp_person, personTypes),
    center:
      data.center === -1
        ? defaultValues.center
        : GetItemFromOptions(data.center, AutoOptions.costcenters),
    stock: stock,
    date: moment(data.date).format("YYYY-MM-DD"),
    check_date: moment(data.check_date).format("YYYY-MM-DD"),
    emp: emp,
    bank: bank,
    check_state: check_state,
    user: data.emp_id ? GetItemFromOptions(data.emp_id, AutoOptions.Users) : "",
  };

  return nData;
}

// export function ReFormatingData(data, AutoOptions){
//       let emp = '', bank='', stock='', check_state='';
//       let customer = GetItemFromOptions(data.cust_id, AutoOptions.Customers);
//       let cust_omla = GetItemFromOptions(data.omla, AutoOptions.omlats);
//       let omla1 =GetItemFromOptions(data.omla1, AutoOptions.omlats);
//       emp= GetItemFromOptions(data.sales_emp, AutoOptions.Users);

//       const stock_omla = {...omla1, exchange: data.omlaval1};
//       if(data.type =='1'){
//             stock = GetItemFromOptions(data.safe_bank_id, AutoOptions.Stocks);
//             bank = AutoOptions.Banks[0];
//             // cust_omlat = {}
//       }
//       else{
//             bank = GetItemFromOptions(data.safe_bank_id, AutoOptions.Banks);
//             stock = AutoOptions.Stocks[0];
//             check_state= data.check_state? GetItemFromOptions(data.check_state, insh.autos.CheckStatus):'';

//       }

//       let nData ={
//             ...data,
//             customer: {id:data.cust_id, name: data.custName},
//             cust_omlat: getAccountOmlat(customer.acc_omlat, AutoOptions.omlats),
//             cust_omla: {...cust_omla, exchange: data?.omlaval},
//             stock_omlat: [],
//             stock_omla: stock_omla,
//             bank_omlat: [],
//             bank_omla: stock_omla,
//             center: data.center === -1? defaultValues.center :
//             GetItemFromOptions(data.center, AutoOptions.costcenters),
//             stock: stock,
//             emp: emp,
//             bank: bank,
//             check_state: check_state,
//             user: data.emp_id? GetItemFromOptions(data.emp_id, AutoOptions.Users) : ''
//       }

//       return nData;

// }

export const getEndDept = (end_dept) => {
  const text = end_dept < 0 ? "    دائن" : "     مدين";
  return Math.abs(end_dept).toFixed() + text;
};
