
import React, { useState,useEffect } from 'react';
import {txtA,txtE} from '../../../../Label/Lbl';
import {Rept}  from '../../../../Context/InshData';
import axios from 'axios';
import {uri}  from '../../../../help/Api'
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MaterialTable from "material-table";
import { insh } from '../../../../Context/InshData';
// Ust
import {useRptCa,useRptMain} from '../../../../St/Reviews/UseStyle';
// csG
import {stElemnt} from '../../../../St/Reviews/cStyle';
import Paper from '@material-ui/core/Paper';

const BodyC = () => {
  const clasm = useRptMain();
  const clas = useRptCa();
  // const clasB = useInvBodyCa();
  const [AE, setAE] = useState(null);

const locInv = JSON.parse(localStorage.getItem('datainv')) || {};

const [sta, setSta] = useState({info:insh.RptInv,tot:{},StngIA:{}});
// const [list, setList] = useState([]);
const getInfo = async () => {
  const tb = {
    Rpt_Inv:true, SettInvsApp:true,
    parms:{
      lprocid:locInv.proc_id,ibranchid:locInv.branch,sProcTypeInvProc:locInv.ProcTypeInvProc,
      iFoundid:1,
    },
    autos:{},
  }
   console.log('Rpt__tb')
   console.log(tb)
  //  alert(`${uri}Res`)
  axios.post(`${uri}Res`,tb)
  .then(res => { 
    console.log('res.data.RptInv'); console.log(res.data);
    setSta({info:res.data.RptInv,tot:res.data.infoBill,StngIA:res.data.SettInvsApp}); 

  // console.log('res.data.RptInv'); console.log(res.data); 
}).catch(error => { });

  setAE(false);
}

useEffect(() => { getInfo(); }, []);

return (
  <Grid item container className={clas.Bdyroot}  style={{direction:'rtl'}}>

    <Grid item container className={clas.BdyundrHeader}>
    <Grid item xs={2}></Grid>
      <Grid item xs={8} className={clas.BdytitleHed}>
        <Typography className={clasm.minclr}>{Rept.title}</Typography>
      </Grid>
    <Grid item xs={2}></Grid>
    </Grid>

    <Grid item container className={clas.Bdycntr}>
      <Grid item container style={{justifyContent: 'center'}}>
        <Grid item xs={4}>{'الصندوق'}</Grid>
        <Grid item xs={1}> / </Grid>
        <Grid item xs={4} >{'iInfo.stock'}</Grid>
        <Grid item xs={4}>{'المخزن'}</Grid>
        <Grid item xs={1}> / </Grid>
        <Grid item xs={4} >{'iInfo.safe'}</Grid>
      </Grid>

      <Grid item container style={{justifyContent: 'center',marginTop:'0.5rem',marginBottom:'0.5rem'}}>
        <Grid item xs={4} >{'المستخدم'}</Grid>
        <Grid item xs={4} >{'iInfo.user'}</Grid>
      </Grid>

      <Grid item container style={{justifyContent: 'center',}}>
          <Grid item xs={6} >{'من تاريخ'}</Grid>
          <Grid item xs={6} >{'iInfo.fDate'}</Grid>
          <Grid item xs={6} >{'إلى تاريخ'}</Grid>
          <Grid item xs={6} >{'iInfo.tDate'}</Grid>
      </Grid>

      <Grid item container style={{justifyContent: 'center',}}>
          <Grid item xs={4} >{'المجموعة الرئيسية/'}</Grid>
          <Grid item xs={1} >{'21'}</Grid>
          <Grid item xs={4} >{'المجموعة الفرعية/'}</Grid>
          <Grid item xs={1} >{'5'}</Grid>
      </Grid>
    </Grid>
    
     <Grid item container>
    <Grid item xs={12} className={clas.BdymtTabl}>
     <MaterialTable style={{boxShadow:'none'}}
            //style={{direction:'ltr'}}
          options={{ 
            showTitle:false,
            toolbar: false,
            sorting: false,
            selection: false,
            // ToolBar Search
            searchFieldAlignment:"left",
            searchFieldVariant:"outlined",
            // ToolBar header
            showSelectAllCheckbox:false,
            showTextRowsSelected:false, 
            // body
            // rowStyle:(data,i)=>i%2!=0?{background:'#038b0321'}:null,
            // footer
            pageSize:5,
            paginationType:"stepped",
            showFirstLastPageButtons:false,
            
            paging:false,
            headerStyle: stElemnt.EvDay.header,
            cellStyle: stElemnt.EvDay.cell,
           }}
          localization={{   body:{ emptyDataSourceMessage:<h1></h1> ,}, }}
              columns={Rept.columns}
              data={Rept.data}

             />
    </Grid>
    </Grid> 
    
</Grid>
    );
}
export default BodyC;

