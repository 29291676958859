import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import ActivityReports from "./ActivityReports";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../services/LoadingPage";
const defaultAutos = {
  Safes: [],
};

export default function ActivitySalesReportsCon() {
  const tb = {
    wait: true,
    Safes: true,
    Currencies: true,
    Branches: true,
    stor: sto,
    parms: {
    safe:-1,
    item:-1,
    },
  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
  );

  if (
    fetchAll ||
    !AutoOptions?.Safes 
  )
    return <LoadingPage />;

  return (
    <ActivityReports
      {...{
        AutoOptions: AutoOptions?.Safes ? AutoOptions : defaultAutos,
        isPurches: false,
      }}
    />
  );
}
