

export function Apis() 
{
    // let reet = JSON.parse(window.localStorage.getItem('infoConn')) || {ip:'', endDigtip:'', port:':', ftp:''};
    // let reet = JSON.parse(window.localStorage.getItem('infoConn')) || {ip:'', endDigtip:'', port:':', ftp:''};
    let ret = JSON.parse(window.localStorage.getItem('infoConn')) || {};
    return `${ret.ftp}://${ret.ip}${ret.endDigtip}${ret.port}/api/`;
    // return 'http://localhost:8184/api/';
  
    // return `${loc.ftp}://${loc.ip}${loc.endDigtip}${loc.port}/api/`;
}

// export {Apis};