import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../../help/Api";
import { Rept, sto } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import { useHeader, useBody } from "../../../Invs/bill-template02/style";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
function HeaderA({ found }) {
  const cls = useRptAF();
  const iInfo = JSON.parse(localStorage.getItem("iInfos")) || {};

  
  const styf = { fontWeight: "1200" };
  const stysub = { fontWeight: "bold" };
  const styfC = { fontWeight: "500" };
  const styTitle = { fontWeight: "1500" };

  const [info, setInfo] = useState({});
  const calssBody = useBody();
  const classHeader = useHeader();
  const clasm = useInvMain();
  const clas = useInvAF();
  const getInfo = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
        console.log("the res is ", res.data);
      })
      .catch((error) => { });
  };
  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid item container className={cls.root}>
      <Grid item xs={5} className={classHeader.rightHeaderar}>
          <div style={styf}>{info.Disc1_header_ar}</div>{" "}
          <div style={stysub}>{info.Disc2_header_ar}</div>
          <div style={styfC}>{info.Disc3_header_ar}</div>{" "}
          <div style={styfC}>{info.Disc4_header_ar}</div>{" "}
        </Grid>

      <Grid item xs={2}>
        <ButtonBase>
          <img
            src={`data:image/jpeg;base64,${found.Logo}`}
            className={cls.logo}
            alt={found.nameA}
          />
        </ButtonBase>
      </Grid>
      <Grid item xs={5} className={classHeader.leftHeaderen}>
          <div style={styf}>{info.Disc1_header_en}</div>
          <div style={stysub}>{info.Disc2_header_en}</div>
          <div style={styfC}>{info.Disc3_header_en}</div>{" "}
          <div style={styfC}>{info.Disc4_header_en}</div>{" "}
        </Grid>

        <Grid item xs={12} className={classHeader.centerHeader}>
          <div style={styTitle}>{iInfo.type == 2 ? "تقرير القيود اليومية" : "القيود الإفتتاحية"}
          </div>{" "}
        </Grid>
      <Grid item container className={[cls.title, cls.titleS]}>
        <Grid item xs={12}>
 
        </Grid>
        <Grid item container className={cls.titLin2}>
          <Grid item xs={3}>
            {" "}
            {"نوع الدفتر"}
          </Grid>
          {/* <Grid item xs={2}>{'المخزن'}</Grid>
          <Grid item xs={1}> / </Grid> */}
          <Grid item xs={3} className={cls.tlR}>
            {iInfo.accBook}
          </Grid>
          {/* <Grid item xs={3} className={cls.tlR}>{Rept.auto.safe}</Grid> */}
          <Grid item xs={3}>
            {"تاريخ القيد"}
          </Grid>
          <Grid item xs={3}>
            {iInfo.nDate}
          </Grid>
        </Grid>

        <Grid item container className={cls.titLin2}>
          <Grid item xs={3}>
            {"رقم القيد"}
          </Grid>
          <Grid item xs={3}>
            {iInfo.doc_no2}
          </Grid>
          {/* <Grid item xs={3} >{Rept.auto.user}</Grid> */}
          <Grid item xs={3}>
            {"وقت القيد"}
          </Grid>
          <Grid item xs={3}>
            {"00:00:00"}
          </Grid>
        </Grid>

        <Grid item container className={cls.titLin2}>
          <Grid item xs={3}>
            {" البيان"}
          </Grid>
          <Grid item xs={3}>
            {iInfo?.notes}
          </Grid>
          {/* <Grid item xs={3} >{Rept.auto.user}</Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );

  // return (

  // <Grid item container justifyContent='center' spacing={0}>

  //   <Grid item container className="">

  //   </Grid>

  //   <Grid item container className="">
  //  </Grid>

  //   <Grid item container className="">
  //  </Grid>
  // </Grid>

  // </Grid>
  // );
}
export default HeaderA;
