// const KEYS = {
//     restrictions: 'restrictions',
//     restrictionId: 'restrictionId'
// }
import * as yup from "yup";

export const defaultValues = {
  res_id: "",
  barcode: "",
  itemName: "",
  item: { id: "", name: "" },
  unit: "",
  mUnit: { id: "", name: "" },
  units: [],
  avg_price: "",
  val1: "",
  val2: "",
  diff: "",
  difftot: "",
  sub_branch: "",
  sub_center: "",
};

export const subTaswyaValidateSchema = yup.object().shape({
  barcode: yup.string().required("قم بتحديد الصنف"),
  // unit: yup.object().shape({
  //   id: yup.string().required("قم بتحديد الوحدة")
  // }),
  val2: yup.string().required("حدد الكمية الفعلية "),
});
