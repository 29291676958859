import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';

import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';

import {useStylesHed, useStylesBA,
    useStylesBt,} from '../../../../St/comps/UseStyle';


export default function OutstandingInvoiceModal(props){

    const {
        openDwt, 
        handCloseDwt, 
        transition, 
        showChosedInvt,
        invsShowt,
        stElemnt,
        style,
        
    } = props;

    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasBt = useStylesBt();

   return (<Dialog fullScreen open={openDwt} onClose={handCloseDwt}
    TransitionComponent={transition}
    key={transition ? transition.name : ''}>
         
     {/* =================================== start =========================================== */}
    
      <div className={clasBA.continarBASer}>
      <React.Fragment>
      <Grid item container spacing={0} className={clashd.rootHd} style={{position:'fixed',zIndex: 1,background:'#fff'}}>
        <Grid item xs={12}>
        <Chip size="small" className={clashd.ChipHdSale} label={'الفاواتير المعلقة'} color="primary" />
        </Grid>
      </Grid>       
    
      <Container>
      <Grid item container className={clasBt.root} style={{marginTop:30}}>
        { invsShowt.map(item => (
        <Grid lg={2} md={3} sm={4} xs={6} item>
          <Chip label={item.tot_net} color="primary" className={clasBt.Chip} clickable
          onClick={()=>showChosedInvt(true,item.proc_id)}/>
          </Grid>))}
      </Grid>
      </Container>
    
    
    </React.Fragment>
     </div>
    
    
     {/* ====================================== end ======================================== */}
         <React.Fragment>
         <CssBaseline />
         <AppBar position="fixed" color="primary"
          // className={clasBA.appBarBASal}
           style={{...style.bgImg,...stElemnt.all.appBarBA}}
         >
           <Toolbar variant="dense">
    
            <Hidden smUp={['sm']}>
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handCloseDwt}>
              <CloseIcon />
            </IconButton>
          </Hidden>
    
          <Hidden only={['xs']}>
            <Button style={{color:'#fff'}} onClick={handCloseDwt} startIcon={<ReplyIcon />}>الفاتورة</Button>
          </Hidden>
    
             <div className={clasBA.growBA} />
    
           </Toolbar>
         </AppBar>
       </React.Fragment>
    
    </Dialog>
    )
}