import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import ButtonBase from '@material-ui/core/ButtonBase';
import { uri } from "../../help/Api";
import { Container, Typography } from "@material-ui/core";
import Swipeable from '../../Fronts/Main/Swipeable';
import { sto } from "../../Context/InshData";
import * as yup from 'yup'
import toast, { Toaster } from "react-hot-toast";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    direction: 'rtl',
    margin: 10
  },
  textField: {
    //width:80,
    margin: 10,
    marginRight: 5,

  },
  buttons: {
    marginRight: 20,
    marginLeft: 5,
    marginTop: 10,
    direction:'ltr',
  },
  input: {
    display: 'none',
    
  },
  textFields: {
    //width:80,
    marginRight: 20,


  },
  textField3: {
    //width:80,
    direction: 'rtl',
    marginBottom: 5

  },
  GridButton:{
    marginBottom:40,
  }
}));

const ChangeValidateSchema = yup.object().shape({
  password: yup.string().required("ادخل كلمة المرور القديمة"),
  newPassword: yup.string().required("ادخل كلمة المرور الجديدة"),

})

export default function ChangePassword() {
  const clasess = useStyles();

  const [data, setData] = useState({
    id: sto.idi,
    username: sto.usernames,
    password: '',
    newPassword: '',
  });

  const changetf = (e) => {
     setData({ ...data, [e.target.name]: e.target.value })
  }



  const updatPassword = async () => {
    ChangeValidateSchema.validate(data, { abortEarly: false })
      .then(async function (valid) {
        await axios.put(uri+'Users', data)
          .then(res => {
            toast.success('تم تغيير كلمة المرور')
          }).catch(error => { 
            console.log(error);
            toast.error("كلمة المرور القديمة غير صحيحة  ");
          })
      }).catch(e=>{
        toast.error(e.errors[0])
      })
    }

  useEffect(()=>{
    console.log('use', sto)
  })

  return (
    <div className={clasess.root} style={{ margin: 10 }}>
      <Container maxWidth="xs">
        <Toaster />
      <Grid container xs={12} index={0}>
        <b>
          تغيير كلمة المرور
        </b>
        <Grid item container >

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField type="text" fullWidth variant="outlined"
              size="small" id="nameA" label="اسم المستخدم" className={clasess.textField}
              name="nameA" value={data.username} InputLabelProps={{ shrink: true }}
              disabled={true} />
          </Grid>


         
          <Grid item lg={8} md={10} sm={10} xs={10}>
            <TextField type="password" fullWidth variant="outlined"
              size="small" id="password" label="كلمة المرور القديمة" className={clasess.textField}
              name="password" value={data.password} InputLabelProps={{ shrink: true }}
              onChange={changetf} />
          </Grid>

          <Grid item lg={8} md={10} sm={10} xs={10}>
            <TextField type="password" fullWidth variant="outlined"
              size="small" id="newPassword" label="كلمة المرور الجديدة" className={clasess.textField}
              name="newPassword" value={data.newPassword} InputLabelProps={{ shrink: true }}
              onChange={changetf} />
          </Grid>
          
          <Typography component="div"> <Swipeable /> </Typography>
        </Grid>

        <Grid className={clasess.GridButton} item lg={1} md={1} sm={1} xs={2}>
          <Button className={clasess.buttons} variant="contained" color="primary" fontSize="large" size="medium"
            onClick={updatPassword} startIcon={<SaveIcon/>}>حـفـظ</Button>
      </Grid>

      </Grid>
      </Container>


    </div>
  );

};
