import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core';
import {useStylesTF,useStylesTD} from '../../../St/comps/UseStyle';
import { useEffect } from 'react';

export default function OmlatForm(props){
    const { 
      object,
      data,
      onChange,
      } = props;
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    useEffect(()=>{
      console.log('dad', object)
    })
    const onChangeData = (prop) => (event) =>{

      onChange(event, {name: prop, value: event.target.value});
    } 

    return(
        <React.Fragment>

        <Grid item lg={3} md={3} sm={4} xs={6}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"name"} label={"الاسم عربي"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"name"} 
            value={object.name} 
            onChange={onChangeData('name')}
            />
        </Grid> 
        <Grid item lg={3} md={3} sm={4} xs={6}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"NameEn"} label={"العملة لاتيني"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"NameEn"} 
            value={object.NameEn? object?.NameEn: ''} 
            onChange={onChangeData('NameEn')}
            />
        </Grid> 
        <Grid item lg={2} md={2} sm={4} xs={6}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"symbol"} label={"رمز العملة"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"symbol"} 
            value={object.symbol? object.symbol: ''} 
            onChange={onChangeData('symbol')}
            />
        </Grid> 
        <Grid item lg={2} md={2} sm={4} xs={6}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"exchange"} label={"سعر التحويل"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"exchange"} 
            value={object.exchange? object.exchange: ''} 
            onChange={onChangeData('exchange')}
            />
        </Grid> 
        <Grid item lg={2} md={2} sm={4} xs={6}>
          <Autocomplete // classes={clasAo}
            value={object.Country}
            onChange={(event, newValue) => { 
                onChange(null, {name:'Country', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={data?.auto?.Countries}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            disabled={object.type=='main'}
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"الدولة"} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid> 
        
        <Grid item lg={3} md={3} sm={4} xs={6}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"FakaName"} label={"اسم الفكة"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"FakaName"} 
            value={object.FakaName? object.FakaName: ''} 
            onChange={onChangeData('FakaName')}
            />
        </Grid> 
        <Grid item lg={3} md={3} sm={4} xs={6}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"FakaNameEn"} label={"الفكة لاتيني"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"FakaNameEn"} 
            value={object.FakaNameEn? object.FakaNameEn: ''} 
            onChange={onChangeData('FakaNameEn')}
            />
        </Grid> 
        <Grid item lg={2} md={2} sm={4} xs={6}>
          <Autocomplete // classes={clasAo}
            value={object.IS_Active}
            onChange={(event, newValue) => { 
                onChange(null, {name:'IS_Active', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={data?.auto?.stateOmlat}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            disabled={object.type=='main'}
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"الحالة"} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid> 
        <Grid item lg={2} md={2} sm={4} xs={6}>
          <Autocomplete // classes={clasAo}
            value={object.oneEqualto}
            onChange={(event, newValue) => { 
                onChange(null, {name:'oneEqualto', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={data?.auto?.equalOmlat}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            disabled={object.type=='main'}
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"كل 1="} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid> 
        <Grid item lg={2} md={2} sm={4} xs={6}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"TotalFaka"} label={"المكافئ"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"TotalFaka"} 
            value={object.TotalFaka} 
            onChange={onChangeData('TotalFaka')}
            />
        </Grid> 
        <Grid item lg={4} md={4} sm={12} xs={12}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"omla_desc"} label={"وصف"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"omla_desc"} 
            value={object.omla_desc} 
            onChange={onChangeData('omla_desc')}
            />
        </Grid> 

        <Grid>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox fullWidth checked={object.IS_Default} 
            onChange={(e)=>onChange(null, {name:'IS_Default', value: e.target.checked}) }
            name="IS_Default" />}
            size="small"
            color="primary"
            fontSize="small"    
            label="العملة الإفتراضية"
          />
          <FormControlLabel
            control={<Checkbox fullWidth checked={object.is_main} 
            onChange={(e)=>onChange(null, {name:'is_main', value: e.target.checked}) }
            name="is_main" />}
            size="small"
            color="primary"
            fontSize="small"    
            label="العملة المحلية / الرئيسية"
          />
        </FormGroup>
        </Grid>
      </React.Fragment>
    )
    
}