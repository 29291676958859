import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStylesTF, useStylesTD } from "../../../St/comps/UseStyle";
import { useEffect } from "react";

export default function SalesPersonForm(props) {
  const { object, data, onChange } = props;
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  // useEffect(()=>{
  //   console.log('dad', object)
  // })
  const onChangeData = (prop) => (event) => {
    onChange(event, { name: prop, value: event.target.value });
  };

  return (
    <React.Fragment>
      <Grid item lg={3} md={3} sm={4} xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"name"}
          label={"اسم المندوب"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"name"}
          value={object.name}
          onChange={onChangeData("name")}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={4} xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"comm"}
          label={"العمولة"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="number"
          name={"comm"}
          value={object.comm}
          onChange={onChangeData("comm")}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={4} xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"email"}
          label={"البريد الإلكتروني "}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"email"}
          value={object.email}
          onChange={onChangeData("email")}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={4} xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"tel"}
          label={"رقم الهاتف"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"tel"}
          value={object.tel}
          onChange={onChangeData("tel")}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={4} xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"mobile"}
          label={"رقم الجوال"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"mobile"}
          value={object.mobile}
          onChange={onChangeData("mobile")}
        />
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"notes"}
          label={"ملاحظات"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"notes"}
          value={object.notes}
          onChange={onChangeData("notes")}
        />
      </Grid>
    </React.Fragment>
  );
}
