import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import {
  useStylesTF,
  useStylesTD,
  useStylesSetting,
} from "../../../St/comps/UseStyle";
import { useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import Swipeable from "../../../Fronts/Main/Swipeable";
import axios from "axios";
import toast from "react-hot-toast";
import { uri } from "../../../help/Api";

export default function ZatcaForm(props) {
  const { object, data, onChange } = props;

  const clasTF = useStylesTF();

  const [submiting, setSubmiting] = useState(false);

  const onChangeData = (propName) => (e) => {
    onChange(null, { name: [propName], value: e.target.value });
  };
  const classesStt = useStylesSetting();

  return (
    <React.Fragment>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            size="small"
            id="Email"
            label="Email"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="Email"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="Email"
            value={object.Email}
            onChange={onChangeData("Email")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="VatNum"
            label="VatNum"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="VatNum"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="VatNum"
            value={object.VatNum}
            onChange={onChangeData("VatNum")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            size="small"
            id="BranchName`"
            label="BranchName"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="BranchName"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="BranchName"
            value={object.BranchName}
            onChange={onChangeData("BranchName")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="TaxPayerName`"
            label="TaxPayerName"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="TaxPayerName"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="TaxPayerName"
            value={object.TaxPayerName}
            onChange={onChangeData("TaxPayerName")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            size="small"
            id="InvoiceTypeCode`"
            label="InvoiceTypeCode"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="InvoiceTypeCode"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="InvoiceTypeCode"
            value={object.InvoiceTypeCode}
            onChange={onChangeData("InvoiceTypeCode")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="Address`"
            label="Address"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="Address"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="Address"
            value={object.Address}
            onChange={onChangeData("Address")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="Category"
            label="Category"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="Category"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="Category"
            value={object.Category}
            onChange={onChangeData("Category")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            size="small"
            id="CRN`"
            label="CRN"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="CRN"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="CRN"
            value={object.CRN}
            onChange={onChangeData("CRN")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            size="small"
            id="AdditionalStreetAddress`"
            label="AdditionalStreetAddress"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder=""
            type="text"
            InputLabelProps={{ shrink: true }}
            name="AdditionalStreetAddress"
            value={object.AdditionalStreetAddress}
            onChange={onChangeData("AdditionalStreetAddress")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            size="small"
            id="BuildingNumber`"
            label="BuildingNumber"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="BuildingNumber"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="BuildingNumber"
            value={object.BuildingNumber}
            onChange={onChangeData("BuildingNumber")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            size="small"
            id="CityName`"
            label="CityName"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder=""
            type="text"
            InputLabelProps={{ shrink: true }}
            name="CityName"
            value={object.CityName}
            onChange={onChangeData("CityName")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            size="small"
            id="StreetName`"
            label="StreetName"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="StreetName"
            type="text"
            InputLabelProps={{ shrink: true }}
            name="StreetName"
            value={object.StreetName}
            onChange={onChangeData("StreetName")}
          />
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            size="small"
            id="PostalCode`"
            label="PostalCode"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder=""
            type="text"
            InputLabelProps={{ shrink: true }}
            name="PostalCode"
            value={object.PostalCode}
            onChange={onChangeData("PostalCode")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            size="small"
            id="OTP`"
            label="OTP"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder=""
            type="text"
            InputLabelProps={{ shrink: true }}
            name="OTP"
            value={object.OTP}
            onChange={onChangeData("OTP")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={object?.IsProductionMode == 1}
                  onChange={(e) =>
                    onChange(null, {
                      name: "IsProductionMode",
                      value: e.target.checked ? 1 : 0,
                    })
                  }
                  name="IsProductionMode"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label="Is Production Mode (otherwise Developer Mode Is Active)"
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Grid container item lg={12} md={12} xs={12}></Grid>
    </React.Fragment>
  );
}
