import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";

import Header from "./Header";
import LoadingPage from "../../../services/LoadingPage";
import Body from "./Body";
// import Footer from "./Footer";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import { sto } from "../../../Context/InshData";
import Footer from "./Footer";

function OnePage(props) {
  const { found, chunk } = props;
  return (
    <Fragment>
      <Header found={found} />
      <Body data={chunk ?? []} />
      <Footer />
    </Fragment>
  );
}
function TaxSaleContainer(props) {
  const loc = useLocation();
  const [found, setFound] = useState({});
  const [loading, setLoading] = useState(true);
  const [chunks, setChunks] = useState(_.chunk(loc.state.SalePurchVat, 13));
  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },

      autos: {},
    };
    console.log(tb);
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        console.log(res);
        setFound(res.data?.Found);
      })
      .catch((error) => {
        console.log("catch");
      });
  };

  useEffect(() => {
    setTimeout(async () => {
      await getInfo();
      setLoading(false);
      // onUnmountComplete();
    }, 0);
  }, []);

  if (!found || loading) return <LoadingPage />;

  return (
    <Fragment>
      {chunks.map((page, i) => {
        return <OnePage {...{ found, chunk: page }} />;
      })}
    </Fragment>
  );
}
export default TaxSaleContainer;
