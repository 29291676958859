
function setLS(k, v) {
  window.localStorage.setItem(k, JSON.stringify(v));
}

function getLS(k,ob) {
 return JSON.parse(window.localStorage.getItem(k)) || ob;
//  return JSON.parse(window.localStorage.getItem(k)) || {};
}

export {setLS,getLS}
