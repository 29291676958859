import React, { useState,useEffect } from 'react';
import axios from 'axios';
import {uri, }  from '../../../../help/Api';
import {Rept, sto}  from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {useRptAF} from '../../../../St/Reviews/UseStyle';

 function FooterA() {
  const cls = useRptAF();
  const [state, setState] = useState({});
  const getInfo = async () => {
  axios.get(uri+`Foundation?branch=${sto.branchi}`).then( res => { setState(res.data); }).catch(error => { });
}

useEffect(() => { 
  getInfo();
}, []);

    return (
      <Grid item container className={cls.rootFotr}
      
      style={{textAlign:'center',direction:'rtl',
      // top: 'auto',
      // bottom: 10,
      // background: '#fff',
// position:'fixed'
// marginTop:'1rem',
// border:'solid'
      
      }}>
 
      <Grid xs={12}> <Typography className={cls.line}></Typography> </Grid>
      <Grid xs={12}> <Typography className={cls.titleF}>{state.Address}</Typography> </Grid>
       
      <Grid item container className={cls.subF} xs={12}>
        <Grid xs={1} className={cls.infoF}><Typography>هاتف:</Typography></Grid>
        <Grid xs={3} className={cls.infoF}><Typography>{state.Tel}</Typography></Grid>
        <Grid xs={1} className={cls.infoF}><Typography>مبايل:</Typography></Grid>
        <Grid xs={3} className={cls.infoF}><Typography>{state.Mobile}</Typography></Grid>
        <Grid xs={1} className={cls.infoF}><Typography>فاكس:</Typography></Grid>
        <Grid xs={3} className={cls.infoF}><Typography>{state.Fax}</Typography></Grid>
      </Grid>

      </Grid>        
    );
  }
export default FooterA;


