import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import {
  colors,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { insh } from "../../../../Context/InshSt";
import { LineWeight } from "@material-ui/icons";

export function Totals({ data }) {
  const cls = useRptAF();
  const styf = { fontWeight: "1200" };

  return (
    <Grid
      container
      lg={12}
      md={12}
      className={cls.rootBdydown}
      style={{ paddingBottom: 10, backgroundColor: "#fff" }}
    >
           <Table>
            <TableHead>
              <TableRow
                key={"002"}
                style={{ backgroundColor: insh.colors.cust, color: "#fff" }}
              >
                <TableCell
                 className={[cls.chipMM, cls.chipMT]}
                 // align="center"
                >
                <div className={[cls.chipMM, cls.chipMT]}> {"الإجماليات "}</div> {" "}
                </TableCell>

                <TableCell style={{ color: "#fff" }} align="center">
               <div style={styf} > {" مدين"}</div>{" "}

                  {data
                    ?.reduce((a, v) => (a = a + Number(v.OBX_Dept)), 0)
                    ?.toFixed(2)}
                </TableCell>
                <TableCell style={{ color: "#fff" }} align="center">
                 <div > {"دائن"}</div>{" "}
                  {data
                    ?.reduce((a, v) => (a = a + Number(v.OBX_Credit)), 0)
                    ?.toFixed(2)}
                </TableCell>
                <TableCell style={{ color: "#fff" }} align="center">
                 <Grid style={styf}> {"حركة مدين "}</Grid> 
                   <div></div>{data
                    ?.reduce((a, v) => (a = a + Number(v.dept)), 0)
                    ?.toFixed(2)}
                </TableCell>
                <TableCell style={{ color: "#fff" }} align="center">
                 <div style={styf}> {"حركة دائن"}</div>{""}
                 <div>
                  {data
                    ?.reduce((a, v) => (a = a + Number(v.credit)), 0)
                    ?.toFixed(2)}</div>
                </TableCell>
                <TableCell style={{ color: "#fff" }} align="center">
                  <div style={styf}>{"الرصيد"}</div>
                  {(Number(
                    data
                      ?.reduce((a, v) => (a = a + Number(v.end_Dept)), 0)
                      ?.toFixed(2)
                  ) +
                    Number(
                      data
                        ?.reduce((a, v) => (a = a + Number(v.end_Credit)), 0)
                        ?.toFixed(2)
                    )).toFixed(2)}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
    </Grid>
  );
}
function BodyA({ data, isFullPage, isLast }) {
  const cls = useRptAF();

  const loc = useLocation();

  const kind = useState(loc?.state?.kind_print);

  useEffect(() => {
    //console.log(loc.state);
  }, []);
  const cellS = {
    padding: "5px 0.5px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    border: "solid 0.1px",
    fontFamily: "Amiri-Regular",
    
  };
  var columns = [
    { title: " رقم الحساب", field: "Code", cellStyle: { ...cellS, width: "70px" }},
    { title: "اسم العميل", field: "aname", cellStyle: { ...cellS, width: "250px" ,maxBodyWidth:"250px"} },
    { title: "مدين", field: "OBX_Dept" ,cellStyle: { ...cellS, width: "70px" }},
    { title: "دائن", field: "OBX_Credit" ,cellStyle: { ...cellS, width: "70px" }},
    { title: "حركة مدين", field: "dept" ,cellStyle: { ...cellS, width: "90px" },},
    { title: "حركةدائن", field: "credit" ,cellStyle: { ...cellS, width: "90px" }},
    { title: "الرصيد", field: "balance" ,cellStyle: { ...cellS, width: "100px" }},
    { title: "الحالة", field: "state" ,cellStyle: { ...cellS, width: "70px" }},
  ];

  
  return (
    <Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid item xs={12}
          style={{
            maxHeight: isFullPage ? "60rem" : "41.5rem",
            minHeight: isFullPage ? "60rem" : "41.5rem",

          }}>
          <MaterialTable
            style={{ boxShadow: "none" }}
            //style={{direction:'ltr'}}
            options={{

              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 4,
              paginationType: "stepped",
              showFirstLastPageButtons: false,
              maxBodyWidth: "20.5rem",
              paging: false,
              headerStyle: {
                ...stElemnt.AF.header,
                backgroundColor: colors.grey[50],
              },
             // cellStyle:cellS,
              // maxBodyHeight: '28.5rem',
              // minBodyHeight: '28.5rem',
            }}
            localization={{
              body: {

                emptyDataSourceMessage: (
                  <h5 style={{ color: "GrayText" }}>
                    لا توجد ارصدة بعد في هذا الحساب
                  </h5>
                ),
              },
             
            }}
           
            columns={columns}
            data={data}
          />
          
           {isLast  && <Totals data={data ?? []} />}
        </Grid>
      </Grid>
   
      <Grid container lg={12} md={12} className={cls.rootBdydown}>
        <Grid container xs={12} className={cls.rootinfAcc}>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المدير العام"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المدير المالي"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المراجع"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المحاسب"}
          </Grid>
          <Grid item xs={4} className={[cls.infAcc]}>
            {"المستخدم"}
          </Grid>
        </Grid>
        <Grid container xs={12} className={[cls.rootinfAcc, cls.downinfAcc]}>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={4} className={[cls.infAcc]}>
            {""}
          </Grid>
        </Grid>
      </Grid>
      
      {/* totTasdeed:totTasdeed.toFixed(2),
      totDaen:totDaen.toFixed(2),
      // totMdeen:totMdeen.toFixed(2) */}
    </Grid>
  );
}

export default BodyA;
