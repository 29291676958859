import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import {
  useInvAF,
  useInvMain,
  useRptAF,
} from "../../../../St/Reviews/UseStyle";
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { colors, Container, Divider, Typography } from "@material-ui/core";
import MainRBalanceTable from "../../../../comps/Accounts/BackEnd/MainRBalance/MainRBalance-table";
import MainRBalanceTotals from "../../../../comps/Accounts/BackEnd/MainRBalance/MainRBalance-totals";

function BodyA() {
  const cls = useRptAF();
  const clas = useInvAF();
  const clasm = useInvMain();
  const loc = useLocation();
  const [data, setData] = useState(loc?.state?.data);
  useEffect(() => {
    console.log(loc.state);
  }, []);

  return (
    <Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid item xs={12}>
          <MainRBalanceTable data={data} />
          {/* <MaterialTable
            style={{ boxShadow: "none" }}
            //style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              paging: false,
              headerStyle: {
                ...stElemnt.AF.header,
                backgroundColor: colors.grey[100],
              },
              cellStyle: stElemnt.AF.cell,
              // maxBodyHeight: '28.5rem',
              // minBodyHeight: '28.5rem',
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h5 style={{ color: "GrayText" }}>
                    لا توجد ارصدة بعد في هذا الحساب
                  </h5>
                ),
              },
            }}
            columns={columns}
            data={data}
          /> */}
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth={true} style={{ padding: "4px" }}>
            <MainRBalanceTotals
              {...{
                data: data ? data : [],
                isMoragaa: loc?.state?.type.id == 3,
                level: loc?.state?.level.id,
              }}
            />
          </Container>
        </Grid>
        <Grid
          item
          container
          style={{ direction: "rtl", backgroundColor: colors.grey[200] }}
        >
          <Grid item xs={7}>
            {""}
          </Grid>
          <Grid item xs={3}>
            {}
          </Grid>
          <Grid item xs={3}>
            {}
          </Grid>
          <Grid item xs={3}>
            {}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BodyA;
