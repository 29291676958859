import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ReplyIcon from '@material-ui/icons/Reply';
import { uri } from '../../../../help/Api';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';

import {useStylesHed, useStylesBA, useStylesUp} from '../../../../St/comps/UseStyle';

import {TableIcons as tableIcons} from "../../../../Elemnts/TableIcons";
import MaterialTable from "material-table";
import {Mtb} from '../../../../St/comps/vstyle';
import { Search } from "@material-ui/icons";
import useFetchURL from '../../../../components/Hooks/useFetchURL';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect } from 'react';



export default function StoreTransferSearchModal(props){

    const {
        open, 
        onClose,
        onRowClick,
        type,
    } = props;

    const {data, fetching, fetchURL} =  useFetchURL({...{
        url: uri+`StoreTransfer?type=${type}`,
        dependencyArray:[open]
    }});

    useEffect(()=>{
      fetchURL()
    },[])
    const clasup = useStylesUp();
    const clashd = useStylesHed();
    const clasBA = useStylesBA();

      const cellS = {
        padding: '6.9px 0px',
        textAlign: '-webkit-center',
        margin: '0px',
        whiteSpace: 'nowrap',
        width: '0px',
        color: '#000',
        // border:'solid 1px red',
        marginLeft: 2, marginRight: 2,
    
      }
      const headerS = {
        height: '15px',
        color: '#fff',
        textAlign: '-webkit-center',
        margin: '0px',
        whiteSpace: 'nowrap',
        width: '0px',
        // backgroundColor: '#20369f',
        backgroundColor: '#808080  ',
        padding: '7px 1px',
        marginLeft: 1, marginRight: 1,
      }

    var columnsRest = [
        {title: " الأمر", field: "id", hidden: true,},
        {title: "رقم الأمر", field: "no", headerStyle: headerS,cellStyle: cellS,},
        {title: "التاريخ", field: "date",headerStyle: headerS,cellStyle: cellS,},
        {title: "المخزن ", field: "safe_fromName",headerStyle: headerS,cellStyle: cellS,},
        {title: "المخزن المحول إلية ", field: "safe_toName",headerStyle: headerS,cellStyle: cellS,},
        {title: "البيان", field: "notes",headerStyle: headerS,cellStyle: cellS,},
    ]

   return ( 
   <Dialog fullScreen open={open} onClose={onClose}>
        {
            fetching? <CircularProgress />:
            <>
            <div className={clasBA.continarBASer}>
            <React.Fragment>
            <Grid item container spacing={0} className={clashd.rootHd}>
            
              <Grid item xs={12}>
              <Chip size="small" className={clashd.ChipHdSale} label={'بـحـث فـي التحويل المخزني'} color="primary" />
              </Grid>
              
            </Grid>
                   
                  <CssBaseline />
            <Container maxWidth="xl" className={clasup.AboverootupSer} >
                    
              {/* S front H */}    
            <Grid item container spacing={1} className={[clasup.rootup, clasup.rootupSer]}>
          
              </Grid>
              <Grid item container className={clasup.rootup}>
              <Grid item  md={12} sm={12} xs={12} >
                        <MaterialTable style={{boxShadow:'none'}}
                        //style={{direction:'ltr'}}
                      options={{ 
                        showTitle:false,
                        toolbar: false,
                        sorting: false,
                        selection: false,
                        // ToolBar Search
                        searchFieldAlignment:"left",
                        searchFieldVariant:"outlined",
                        // ToolBar header
                        showSelectAllCheckbox:false,
                        showTextRowsSelected:false, 
                        pageSize:5,
                        paginationType:"stepped",
                        showFirstLastPageButtons:false,
                        
                        paging:false,
            
                        maxBodyHeight: Mtb.minmaxBHInvs,
                        minBodyHeight: Mtb.minmaxBHInvs,
                        // padding: 'dense',
                        // rowStyle: stElemnt.InvsR,
                        // headerStyle: stElemnt.InvsH,
                       }}
                      localization={{
                        body:{ emptyDataSourceMessage:<h3 style={{color:'#000',}} >لا يوجد تحويلات بعد</h3> ,},
                        toolbar: { searchPlaceholder: "بـحـث", },
                      }}
                      
                      columns={columnsRest}
                      data={data}
                      
                      onRowClick={async (event, rowData) => {  
                        onRowClick(rowData);
                       event.stopPropagation();
                     }}
            
                         />
                        </Grid> 
            
              </Grid>
              <br/> <br/>
            
                  </Container>
                </React.Fragment>
             </div>
            
            
             {/* ====================================== end ======================================== */}
                 <React.Fragment>
                 <CssBaseline />
                 <AppBar position="fixed" color="primary"
                  // className={clasBA.appBarBASal}
                 >
                   <Toolbar variant="dense">
                     
                    <Hidden smUp={['sm']}>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={onClose}>
                      <ReplyIcon />
                    </IconButton>
                  </Hidden>
            
                  <Hidden only={['xs']}>
                    <Button style={{color:'#fff'}} onClick={onClose} startIcon={<ReplyIcon />}>واجهة {type==1? "التحويل":"الاستلام"} المخزني</Button>
                  </Hidden>
            
                     <div className={clasBA.growBA} />
            
                   </Toolbar>
                 </AppBar>
               </React.Fragment>
                </>
        }   
    </Dialog>
    )
}