import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import { Card, CardContent, colors, Container, Divider, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import BalanceCard from "../../../../components/BalanceCard";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    root: {
        // height: '80px',
        fontFamily: "Amiri-Regular",
        // borderBottomWidth: "5px",
        // borderBottomStyle: "solid",
        color: "#000",
        paddingTop: "0.5rem"
    },
    rootBodys: {
        fontFamily: "Amiri-Regular",
        backgroundColor: colors.grey[300],
        borderBottomColor: colors.grey[700],
        borderBottomWidth: "5px",
        borderBottomStyle: "solid",
        color: "#000",
        direction:"rtl",
    },
    content: {
        alignItems: "center",
        //display: "flex",
        fontFamily: "Amiri-Regular",
    },
    title: {
        fontWeight: 700,
        textAlign: "center",
        fontFamily: "Amiri-Regular",
    },
}));

function TotalMezan({ data }) {

    const cls = useRptAF();
    const { t } = useTranslation(["forms"]);


    const classes = useStyles();
    return (
        <Card className={clsx(classes.root)}>
            <Grid item container className={classes.rootBodys}
            
           >
                <CardContent className={classes.content}>
                    <Grid container xs={12} /*className={cls.rootchip}*/ style={{
                        fontFamily: "Amiri-Regular",
                        direction: "rtl"
                    }}>
                        <Grid container xs={4} style={{ fontWeight: "bold" }}>
                            <Grid xs={2} style={{ fontWeight: "bold" }}>
                                <Typography
                                    className={classes.title}
                                    variant="body1"
                                >
                                    {t("prev-balance")}
                                </Typography>
                            </Grid>
                            <Grid xs={10} style={{ fontWeight: "bold" }}>
                                <Container
                                    style={{
                                        justifyContent: "center",
                                    }}
                                    maxWidth={false}
                                >
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {" "}
                                        {t("dept") + ": "}
                                        {data.reduce((a, v) => (a = a + v.OB_DR_AMT_L + v.val_open_dept), 0).toFixed(2)}{" "}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {" "}
                                        {t("credit") + ": "}
                                        {data.reduce((a, v) => (a = a + v.OB_CR_AMT_L + v.val_open_credit), 0).toFixed(2)}{" "}
                                    </Typography>
                                </Container>
                            </Grid>
                        </Grid>
                        <Grid container xs={4} style={{ fontWeight: "bold" }}>
                            <Grid xs={2} style={{ fontWeight: "bold" }}>
                                <Typography
                                    className={classes.title}
                                    variant="body1"
                                >
                                    {t("acc-move")}
                                </Typography>
                            </Grid>

                            <Grid xs={10} style={{ fontWeight: "bold" }}>
                                <Container
                                    style={{
                                        // display:'flex',
                                        // margin:0,
                                        justifyContent: "center",
                                    }}
                                    maxWidth={false}
                                >
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {" "}
                                        {t("dept") + ": "}
                                        {data.reduce((a, v) => (a = a + v.DR_AMT_L), 0).toFixed(2)}{" "}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {" "}
                                        {t("credit") + ": "}
                                        {data.reduce((a, v) => (a = a + v.CR_AMT_L), 0).toFixed(2)}{" "}
                                    </Typography>
                                </Container>
                            </Grid>
                        </Grid>

                        <Grid container xs={4} style={{ fontWeight: "bold" }}>
                            <Grid xs={2} style={{ fontWeight: "bold" }}>
                                <Typography
                                    className={classes.title}
                                    variant="body1"
                                >
                                    {t("acc-balance")}
                                </Typography>
                            </Grid>
                            <Grid xs={10} style={{ fontWeight: "bold" }}>
                                <Container
                                    style={{
                                        justifyContent: "center",
                                    }}
                                    maxWidth={false}
                                >
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {" "}
                                        {t("dept") + ": "}
                                        {data.reduce((a, v) => (a = a + v.End_DR_AMT_L), 0).toFixed(2)}{" "}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1" style={{ fontFamily: "Amiri-Regular" }}>
                                        {" "}
                                        {t("credit") + ": "}
                                        {data.reduce((a, v) => (a = a + v.End_CR_AMT_L), 0).toFixed(2)}{" "}
                                    </Typography>
                                </Container>
                            </Grid>

                        </Grid>
                    </Grid>
                </CardContent>
            </Grid>
        </Card>

    );
}

export default TotalMezan;
