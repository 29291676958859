import React, { useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  useStylesHed,
  useStylesTD,
  useStylesAuto,
  useStylesUp,
  useStylesTF,
  useStylesLbl,
} from "../../../../../St/comps/UseStyle";

import { invoicePayTypes } from "../../../../../services/invoice-service";
import { useEffect } from "react";
import { Search } from "@material-ui/icons";
import { sto ,insh} from "../../../../../Context/InshData";
import { getLS } from "../../../../../utils/useLS";

export default function SearchInvForms(props) {
  const {
    searchPrms,
    fetchURL,
    fetching,
    onChangeAutoData,
    onChangeData,
    gets,
    customers,
    banks,
    branches,
    users,
  } = props;

  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const clasAo = useStylesAuto();
  const claslbl = useStylesLbl();
  const [valueUser, setValueUser] = useState(insh.Auto);

  const [valueBranch, setValueBranch] = useState(insh.Auto);
  const [getsS, setGetsS] = useState(insh.tbname);

  const locSto = getLS("sto", {});
  function GetEmpFromOptions(id, options) {
    const index = options?.findIndex((op) => op.emp == id);
    return options[index];
  }
  function GetEmpBranchOptions(id, options) {
    const index = options?.findIndex((op) => op.id == id);
    return options[index];
  }
  const loadUser = async () => {
    let locR = getLS("DataRem", {});
    setValueUser(GetEmpFromOptions(locR.empi, users));
   
    setValueBranch(GetEmpBranchOptions(sto.branchi, branches));
    setGetsS(locSto);

  }

  useEffect(async () => {
    loadUser();
  }, []);
  return (
    <Grid
      item
      container
      spacing={1}
      className={[clasup.rootup, clasup.rootupSer]}
    >
      <Grid item lg={2} md={2} sm={3} xs={4}>
        <TextField
          variant="outlined"
          size="small"
          id="bill_no`"
          label="رقم الفاتورة"
          // className={clasTF.rootSer}
          InputProps={{ className: clasTF.input }}
          placeholder="رقم الفاتورة"
          type="text"
          InputLabelProps={{ shrink: true }}
          name="bill_no"
          value={searchPrms.bill_no}
          onChange={onChangeData("bill_no")}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={3} xs={4}>
        <TextField
          variant="outlined"
          size="small"
          id="searchInvsx`"
          label="رقم المرجع"
          // className={clasTF.rootSer}
          InputProps={{ className: clasTF.input }}
          placeholder="رقم المرجع"
          type="text"
          InputLabelProps={{ shrink: true }}
          name="refno"
          value={searchPrms.refno}
          onChange={onChangeData("refno")}
        />
      </Grid>

      <Grid item lg={2} md={2} sm={2} xs={4}>
        <Autocomplete
          classes={clasAo}
          value={searchPrms.inv_type}
          onChange={(event, newValue) => {
            onChangeAutoData("inv_type", newValue);
          }}
          id="controllable-states-demo"
          options={gets.typesFltr}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          style={{ marginTop: 1 }}
          disableClearable
          renderInput={(params) => (
            <TextField
              id="typeinvs"
              {...params}
              label={"نوع الفاتورة"}
              variant="outlined"
              size="small"
              InputLabelProps={{ className: claslbl.clor }}
            />
          )}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={4}>
        <Autocomplete
          classes={clasAo}
          value={searchPrms.pay_type}
          onChange={(event, newValue) => {
            onChangeAutoData("pay_type", newValue);
          }}
          id="controllable-states-demo2"
          options={invoicePayTypes}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          style={{ marginTop: 1 }}
          disableClearable
          disabled={searchPrms?.inv_type?.id != 2}
          renderInput={(params) => (
            <TextField
              id="payType"
              {...params}
              label={"نوع الدفع"}
              variant="outlined"
              size="small"
              InputLabelProps={{ className: claslbl.clor }}
            />
          )}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={3} xs={4}>
        <Autocomplete
          classes={clasAo}
          value={searchPrms.bank}
          onChange={(event, newValue) => {
            onChangeAutoData("bank", newValue);
            // changAutoBankPMD(newValue);
          }}
          id="controllable-states-dem"
          size="small"
          options={banks}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          style={{marginTop:1}}
          disableClearable
          disabled={searchPrms?.pay_type?.id != 2}
          renderInput={(params) => (
            <TextField
              id="valueBankPMD"
              {...params}
              label={"البنك"}
              variant="outlined"
              size="small"
              InputLabelProps={{ className: claslbl.clor }}
            />
          )}
        />
      </Grid>
      {sto.User_typei == 1 ? 
      (
        <Grid item lg={2} md={2} sm={3} xs={4}>
          <Autocomplete
            classes={clasAo}
            value={searchPrms.sales_emp}
            onChange={(event, newValue) => {
              // changAutoCustomers(newValue);
              onChangeAutoData("sales_emp", newValue);
            }}
            id="controllable-states-demo"
            size="small"
            options={users}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{ marginTop:1 }}

            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCustomers"
                {...params}
                label={"المستخدم"}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
                // InputLabelProps={{shrink: true}}
              />
            )}
          />
        </Grid>
      )

      :
      (
        <Grid item lg={2} md={2} sm={3} xs={4}>
          <Autocomplete
            classes={clasAo}
            value={valueUser}
            onChange={(event, newValue) => {
              // changAutoCustomers(newValue);
              onChangeAutoData("sales_emp", newValue);
            }}
            id="controllable-states-demo"
            size="small"
            options={users}
            disabled={true}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{ marginTop:1 }}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCustomers"
                {...params}
                label={"المستخدم"}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
                // InputLabelProps={{shrink: true}}
              />
            )}
          />
        </Grid>
      )
    }
      {sto.User_typei == 1 ? 
      
      (
        <Grid item lg={2} md={2} sm={3} xs={4}>
          <Autocomplete
            classes={clasAo}
            value={searchPrms.branch}
            onChange={(event, newValue) => {
              // changAutoCustomers(newValue);
              onChangeAutoData("branch", newValue);
            }}
            id="controllable-states-demo45f"
            size="small"
            options={branches}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}

            disableClearable
            renderInput={(params) => (
              <TextField
                id="branchessl"
                {...params}
                label={"الفرع"}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
                // InputLabelProps={{shrink: true}}
              />
            )}
          />
        </Grid>
      )
      :
           
      (
        <Grid item lg={2} md={2} sm={3} xs={4}>
          <Autocomplete
            classes={clasAo}
            value={valueBranch}
            onChange={(event, newValue) => {
              // changAutoCustomers(newValue);
              onChangeAutoData("branch", newValue);
            }}
            id="controllable-states-demo45f"
            size="small"
            options={branches}
            disabled={true}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{ marginTop:1 }}

            disableClearable
            renderInput={(params) => (
              <TextField
                id="branchessl"
                {...params}
                label={"الفرع"}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
                // InputLabelProps={{shrink: true}}
              />
            )}
          />
        </Grid>
      )
      }
      <Grid item lg={2} md={2} sm={3} xs={4}>
        <Autocomplete
          classes={clasAo}
          value={searchPrms.customer}
          onChange={(event, newValue) => {
            // changAutoCustomers(newValue);
            onChangeAutoData("customer", newValue);
          }}
          id="controllable-states-demo"
          size="small"
          options={customers}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          style={{ marginTop:1 }}
          disableClearable
          renderInput={(params) => (
            <TextField
              id="valueCustomers"
              {...params}
              label={"العميل"}
              variant="outlined"
              size="small"
              InputLabelProps={{ className: claslbl.clor }}
              // InputLabelProps={{shrink: true}}
            />
          )}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={3} xs={4}>
        <TextField
          variant="outlined"
          size="small"
          id="cust_mobile"
          label="هاتف العميل"
          // className={clasTF.rootSer}
          style={{marginTop:1}}
          InputProps={{ className: clasTF.input }}
          placeholder="هاتف العميل"
          type="text"
          InputLabelProps={{ shrink: true }}
          name="cust_mobile"
          value={searchPrms.cust_mobile}
          onChange={onChangeData("cust_mobile")}
        />
      </Grid>
     < Grid container item lg={2} md={2} sm={3} xs={5}>
     <Grid item lg={12} md={12} sm={12} xs={12}>
     <TextField
          fullWidth
          variant="outlined"
          size="small"
          id="dateFrom"
          label="مـن تـاريـخ"
          className={clasTF.root}
          InputProps={{ className: clasTD.input }}
          type="date"
          format="YYYY-MM-DD"
          InputLabelProps={{ shrink: true }}
          name="dateFrom"
          value={searchPrms.dateFrom}
          onChange={onChangeData("dateFrom")}
        />
       
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
      <TextField
          fullWidth
          variant="outlined"
          size="small"
          id="timeFrom"
          label="الوقت "
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
         
        style={{marginTop:1}}
          type="time"
          format="HH:mm"
          name="timeFrom"
          value={searchPrms.timeFrom}
          onChange={onChangeData("timeFrom")}
        />
        </Grid>

      </Grid>
     
      < Grid container item lg={2} md={2} sm={3} xs={5}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id="dateTo"
          label="إلـى تـاريـخ"
          className={clasTF.root}
          InputProps={{ className: clasTD.input }}
          type="date"
          format="YYYY-MM-DD"
          InputLabelProps={{ shrink: true }}
          name="dateTo"
          value={searchPrms.dateTo}
          onChange={onChangeData("dateTo")}
        />
       
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
      <TextField
          fullWidth
          variant="outlined"
          size="small"
          id="timeTo"
          label="إلى الوقت  "
          className={clasTF.rootSale}
          style={{marginTop:1}}
          InputProps={{ className: clasTD.input }}
          
          type="time"
          format="HH:mm"
          name="timeTo"
          value={searchPrms.timeTo}
          onChange={onChangeData("timeTo")}
        />
        </Grid>
      </Grid>

      <Grid item lg={2} md={3} sm={4} xs={2}>
        {/* <TextField
            fullWidth
            variant="outlined"
            size="medium"
            id="searchInvsx`"
            label="بـحـث"
            className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="بـحـث"
            type="search"
            InputLabelProps={{ shrink: true }}
            name="searchInvs"
            value={searchedInvShow}
            onChange={invsShowSearch}
          /> */}
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          disabled={fetching}
          onClick={() => {
            fetchURL();
          }}
        >
          {"بحث"}
          <Search style={{ paddingInline: 2 }} />
        </IconButton>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={1}></Grid>
    </Grid>
  );
}
