import React,{Fragment} from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
} from 'chart.js'

import {Bar, Doughnut, Line, Pie, PolarArea } from 'react-chartjs-2';
import {faker} from '@faker-js/faker';
import { useEffect } from 'react';
import { useState } from 'react';
import {getLatestSevenDayDate} from '../../../../services/SalesChartSrvice';
import moment from 'moment';
import { colors } from '@material-ui/core';

ChartJS.register(
  RadialLinearScale,
  ArcElement, Tooltip, Legend
)

export const options = {
  indexAxis: 'y',
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: window.innerWidth>700?'right':'bottom',
      align: window.innerWidth>700?'center':'start',
      direction: 'rtl',
      display: true,
      labels: {
                font: {
                      // size: 14
                    }
            }
    }
  },
};

export default function MostSoledChart(props){

  const {data: mData} = props;
  const [data, setDate] = useState({
    labels:[...mData.map(i=>i.item_name + '  '+i.qty)], 
      datasets: [
    {
      data: mData.map(i=>i.qty),
      
      backgroundColor: [
        'rgba(255, 99, 132, 0.3)',
        'rgba(54, 162, 235, 0.3)',
        'rgba(255, 206, 86, 0.3)',
        'rgba(75, 192, 192, 0.3)',
        'rgba(153, 102, 255, 0.3)',
        'rgba(255, 159, 64, 0.3)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
  });

    return(
      <Doughnut data={data} options={options}/>
    )
}
