import React,{useEffect, useState} from 'react';
import BackdropSc from '../../../services/BackdropSc';
import axios from 'axios';
import {insh,sto,permiCuCo,premiScr} from '../../../Context/InshData';
import {insh as inshst} from '../../../Context/InshSt';
import {txtA} from '../../../Label/Lbl';
import {uri}  from '../../../help/Api';
import Card from '@material-ui/core/Card';
import {getLS}  from '../../../utils/useLS';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import Grid from '@material-ui/core/Grid';
import dataF from '../../../Server/dataF.json';
import Typography from '@material-ui/core/Typography';

import { useHistory } from 'react-router-dom';
// import PurchInvRet from '../../../comps/Purchases/BackEnd/Tab1/PurchInvRet';

// cuG
import {useStyleI} from '../st/UseStyle';
// csG
import {style} from '../st/cStyle'

export default function Icons() {
  const classes = useStyleI();
  let his = useHistory();
  const timer = React.useRef();

  const [prmiScr, setPermiScr] = useState(insh.prmiScr);
  const [showSc, setShowSc] = useState(true);

  // const inshRiv={Rw:false,RwR:false};
  // const [riv, setRiv] = useState(inshRiv);
  // const getUPrmi = async () => {
  //   const arr = [{v:false},{v:false}];
  //   let i=0;
  //   for (let m = 45; m < 48; m++)
  //     {
  //       if (m===46) continue;
  //       // alert(uri+`UserPermission?id=${sto.idi}&&f=${dataF[m].id}`)
  //       await axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[m].id}`).then(r => {
  //         if(r.data.user_id !== null) arr[i++].v=false; else arr[i++].v=true;
  //       }).catch(error => { })
  //     }
  //       setRiv({Rw:arr[0].v,RwR:arr[1].v});

  //       /* costWithVat */
  //       axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[3].id}`).then(res => {
  //         if(res.data.costWithVat===1) { setPermiScr({...prmiScr, CWVat:res.data.costWithVat, CWVtxt:'السعر يشمل الضريبه',  }); }
  //         else if(res.data.costWithVat===0){ setPermiScr({...prmiScr, CWVat:res.data.costWithVat, CWVtxt:'السعر لا يشمل الضريبه', }); }
  //       }).catch(error => { });
  // }

  // const getUPrmi = async () => 
  // {
  //   // alert('iformidI= '+dataF[45].id+' , iformidR= '+dataF[47].id+' , iformidC= '+dataF[3].id)
  //   const tb = { PermissionsScreen:true, wait:true,
  //     parms:{
  //       iuserid:sto.idi, iformidI:dataF[45].id,iformidR:dataF[47].id,
  //     },
  //     autos:{},
  //   }

  //   console.log(tb)
  //   await axios.post(uri+'Res',tb).then(res => {
  //     setPermiScr(res.data.permisScr);
  //     setShowSc(res.data.wait.state)
  //   }).catch(error => { })
  // }

  const getUPrmi = () => {
    const loc = getLS('premiScr',{});
    setPermiScr(loc);
  }

  const loader = () => 
  { timer.current = window.setTimeout(() => { setShowSc(false); }, 600); }
  useEffect( () => {
    loader();
    getUPrmi();
    return () => { clearTimeout(timer.current); };
  }, []);


  
  //sa
  // function TransitionUp(props) { return <Slide {...props} direction="up" />; }
  // const [transition, setTransition] = React.useState(undefined);


  // const [isOpenPurchW, setIsOpenPurchW] = useState(false);
  // const [isOpenPurchO, setIsOpenPurchO] = useState(false);

  // const clickOPurch = (Transition) => {
  //   setTransition(() => Transition);
  //   prmiScr.CWVat===1 ? setIsOpenPurchW(!isOpenPurchW) : setIsOpenPurchO(!isOpenPurchO);       

  //   //  setIsOpenPurch(!isOpenPurch); 
  //   }; // E sa

  // //rsa
  // const [isOpenPurchWRet, setIsOpenPurchWRet] = useState(false);
  // const [isOpenPurchORet, setIsOpenPurchORet] = useState(false);
  // const clickOPurchRet = (Transition) => {
  //   setTransition(() => Transition);
  //   prmiScr.CWVat===1 ? setIsOpenPurchWRet(!isOpenPurchWRet) : setIsOpenPurchORet(!isOpenPurchORet); 

  //   // setIsOpenPurchRet(!isOpenPurchRet); 
  // }; // E rsa


  const clickInv = (val) => {

    // setTransition(() => Transition);
    // prmiScr.CWVat===1 ? setIsOpenSalW(!isOpenSalW) : setIsOpenSalO(!isOpenSalO); 
    // return(<Invoces />);
    // his.push({ pathname: '/SalesInvO', })

      val?his.push({
        pathname: '/Invoces',
        // search: '?update=true',  /*query string*/
        state: { idata:insh.IdataInvs, stor:{invsType:2},
          setting:{titleHed:txtA.invName.purch,cusType:2,cusN:txtA.cusNM,
            priceNM:txtA.namePricePurch,priceTotNM:txtA.nameTotPurch, 
            PrTy_InPr:insh.IdataPa.ProcType_InvProc,
            iformid:dataF[45].id,
            routI_R_RS:'Inv_R_RS_ToP',routIS:'Inv_Sub_ToP/',/*routR:'',routRS:'',*/  },
            style:inshst.purch
        },
      })
      :his.push({
        pathname: '/Invoces',
        // search: '?update=true',  /*query string*/
          state: {idata:insh.IdataInvs, stor:{invsType:3},
          setting:{titleHed:txtA.invName.purchRet,cusType:2,cusN:txtA.cusNM,
            priceNM:txtA.namePricePurch,priceTotNM:txtA.nameTotPurch, 
            PrTy_InPr:insh.IdataPaR.ProcType_InvProc,
            iformid:dataF[47].id,
            routI_R_RS:'Inv_R_RS_ToP',routIS:'Inv_Sub_ToP/',/*routR:'',routRS:'',*/  },
            style:inshst.ret
        },
      })
    


    }; // E sa


  return (
    showSc?<BackdropSc/>:
<Card className={classes.root}>
<Grid container className={classes.container} spacing={1}>

<Grid item lg={12} md={12} sm={12} xs={12} >
<CardActionArea disabled={!prmiScr.purch}  onClick={() => clickInv(true)} className={classes.action} style={style.backColor.parsh}>

{/* <div>{riv.Rw+''}</div> */}
<CardContent className={classes.content} >
   {/* <Typography component="h5" variant="h5"> */}
   {/* <Typography variant="subtitle1" color="textSecondary">  */}
  <Typography>فاتورة المشتريات</Typography>
   {/* </Typography>  */}
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <ListAltIcon className={classes.icon} />
</CardMedia>
</CardActionArea>
</Grid>

<Grid item lg={12} md={12} sm={12} xs={12} >
<CardActionArea disabled={!prmiScr.purchRet} className={classes.action} 
onClick={() => clickInv(false)}
style={style.backColor.RetParsh}>

<CardContent className={classes.content}>
  <Typography>مرتجع المشتريات</Typography>
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <LowPriorityIcon className={classes.icon} />
</CardMedia>
</CardActionArea>
</Grid>

{/* <Grid item lg={6} md={12} sm={12} xs={6}>
<CardActionArea className={classes.action}>

<CardContent className={classes.content}>
  -------------
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <ListAltIcon className={classes.icon} />
</CardMedia>
</CardActionArea>
</Grid>

<Grid item lg={6} md={12} sm={12} xs={6}>
<CardActionArea className={classes.action}>

<CardContent className={classes.content}>
 ---------------
</CardContent>
<CardMedia
className={classes.cover}
title=""
>
 <LowPriorityIcon className={classes.icon} />
</CardMedia>
</CardActionArea>
</Grid>*/}

</Grid> 

{/* <PurchInvW isUpOpened={isOpenPurchW} transitionUp={transition} handCloseUp={() => setIsOpenPurchW(false)}
 CWVtxt={prmiScr.CWVtxt}/>
<PurchInvO isUpOpened={isOpenPurchO} transitionUp={transition} handCloseUp={() => setIsOpenPurchO(false)}
CWVtxt={prmiScr.CWVtxt}/>

<PurchInvWRet isUpOpened={isOpenPurchWRet} transitionUp={transition} handCloseUp={() => setIsOpenPurchWRet(false)}
 CWVtxt={prmiScr.CWVtxt}/>
<PurchInvORet isUpOpened={isOpenPurchORet} transitionUp={transition} handCloseUp={() => setIsOpenPurchORet(false)}
CWVtxt={prmiScr.CWVtxt}/> */}


</Card>





  );
}


    // <Card className={classes.root} >
    //   <CardActionArea className={classes.root}>

    //     <CardContent className={classes.content}>
    //       {/* <Typography component="h5" variant="h5">
    //       {/* <Typography variant="subtitle1" color="textSecondary"> */}
    //       مرتجع المبيعات
    //       {/* </Typography> */}
    //     </CardContent>
    //   <CardMedia
    //     className={classes.cover}
    //     title=""
    //   >
    //      <LocalMallIcon style={iconPurch} />
    //   </CardMedia>
    //   </CardActionArea>
      
    // </Card>





