import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

export default function SalesmanSalesTables(props) {
    const { data, isPurches } = props;


    const stylH = {
        // borderWidth: 1,
        border: "solid 0.6px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 16,
        color: "#fff",
        fontWeight:"bold"
    }
    const stylRo = {
        BorderBottom: "solid 0.5px",
        //borderStyle: "solid",
        borderColor: "gray",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 14,
        color: "#000"
    }

    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    return (
        <TableContainer component={Paper} >
            <Table
                //className={classes.table} 
                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor:  colors.green[300],
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                            border: "solid 0.6px"
                        }}
                    >
                       
                        <TableCell style={stylH} align="center">
                        {t("نوع الحركة")}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                        {t("التاريخ")}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                        {t("رقم الفاتورة")}
                        </TableCell>
                       
                        <TableCell style={stylH} align="center">
                        {t("المندوب")}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                        {t("قيمة الفاتورة بعد الخصم قبل الضريبة")}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                        {t("نسبة العمولة")}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                        {t("مبلغ العمولة")}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                        {t("متوصط التكلفة ")}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                        {t("مبلغ الضريبة")}
                        </TableCell>
                         <TableCell style={stylH} align="center">
                         {t("الربح")}
                         </TableCell>
                         <TableCell style={stylH} align="center">
                         {t("الصافي")}
                         </TableCell>
                      
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, i) => (
                        <TableRow key={row.col_bill_no}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                fontWeight: 800,
                                fontFamily: "Amiri-Regular",
                                fontSize: 14,
                                border: "solid 0.6px"
                            }}>
                            <TableCell style={stylRo} align="center">{row.col_trans_type}</TableCell>
                            <TableCell style={stylRo} align="center">{row.col_date}</TableCell>
                            <TableCell style={stylRo} align="center">{row.col_bill_no}</TableCell>
                            <TableCell style={stylRo} align="center">{row.col_sales_name}</TableCell>
                            <TableCell style={stylRo} align="center">{row.col_tot_net}</TableCell>
                            <TableCell style={stylRo} align="center">{row.col_commission}</TableCell>
                            <TableCell style={stylRo} align="center">{row.col_Amount_collected}</TableCell>
                            <TableCell style={stylRo} align="center">{row.col_CostAvg}</TableCell>
                            <TableCell style={stylRo} align="center">{row.col_amountOfVat}</TableCell>
                            <TableCell style={stylRo} align="center">{row.col_profit}</TableCell>
                            <TableCell style={stylRo} align="center">{row.col_commNet}</TableCell>


                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
