import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { mt } from "../../../../St/comps/cStyle";
import { reFormatDate } from "../../../../utils/utils";
import { insh } from "../../../../Context/InshSt";
import TotalsS from "./TotalsS";
import { colors, Container, makeStyles, Table, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

export function Totals({ data }) {
  const styf = { fontWeight: "1200" };
  const cls = useRptAF();
  const totals = Rept.tot;

  const styleE={
      border: "solid 1px ",
      borderColor: "black",
      textAlign: "center",
      padding: 2,
      fontSize: "0.6rem",
      color: "red",
      fontWeight: "bold",
  };
  const styleTo={
      border: "solid 1px ",
      borderColor: "black",
      textAlign: "center",
      fontSize: "0.8rem",
      fontWeight: "bold",
  };
  return (
      <Grid item container className={cls.rootBdy}>
    <Grid item container className={cls.rootBdyup}>
      <Grid container lg={12} md={12} className={cls.rootBdydown}>
      <Grid container xs={12} /*className={cls.rootchip}*/>
        <Grid item xs={2} style={styleE}>
        {"إجمالي مبيعات"}
        </Grid>
        <Grid item xs={2} style={styleE}>
        {"إجمالي مرتجع"}
        </Grid>
        <Grid item xs={2} style={styleE}>
        {"قبل الضريبة"}
        </Grid>
        <Grid item xs={2} style={styleE}>
        {"إجمالي الضريبة"}
        </Grid>
        <Grid item xs={2} style={styleE}>
        {"شامل الضريبة"}
        </Grid>
        <Grid item xs={2} style={styleE}>
        {"إجمالي الصافي"}
        </Grid>
      </Grid>
      <Grid container xs={12} /*className={cls.rootchip}*/>
        <Grid item xs={2} style={styleTo}>
          {totals.tot_sales.toFixed(2)}
        </Grid>
        <Grid item xs={2}  style={styleTo}>
          {totals.tot_return.toFixed(2)}
        </Grid>
        <Grid item xs={2}  style={styleTo}>
          {totals.betfore_vat.toFixed(2)}
        </Grid>
        <Grid item xs={2}  style={styleTo}>
          {totals.vatTotal.toFixed(2)}
        </Grid>
        <Grid item xs={2}  style={styleTo}>
          {totals.netAmount.toFixed(2)}
        </Grid>
        <Grid item xs={2}  style={styleTo}>
          {totals.netAmount.toFixed(2)}
        </Grid>
      </Grid>
    </Grid>
    </Grid>
    </Grid>
  );
}

function BodyS({ data, isFullPage, isLast }) {
  const cls = useRptAF();
  const cellS = {

    padding: "0px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    border: "solid 0.1px",
    fontSize: "0.55rem",
    width: "150px",
    fontFamily: "Amiri-Regular",
    fontWeight:"bold",
  };
  var columns = [
    { title: "الصنف", field: "symbol" },
    { title: "الكمية", field: "val1" },
    { title: "السعر", field: "exchange_price" },
    { title: "الخصم", field: "discount" },
    { title: "الضريبة", field: "taxval" },
    { title: "شامل الضريبة", field: "amount" },
    { title: "العميل", field: "customer_name" ,cellStyle:cellS},
    { title: "التفاصيل", field: "trans_name_ar" },
    { title: "طريقة الدفع", field: "name" },
  ];

  const newData = data.map((row, i) => {
    return {
      ...row,
      exchange_price: row.exchange_price.toFixed(2),
      taxval:row.taxval.toFixed(2),
      discount: row.discount.toFixed(2),
      amount:row.amount.toFixed(2),
    };
  });
  return (
    <Grid item container style={{fontFamily: "Amiri-Regular"}}>
      <Grid item container style={{ direction: "rtl",paddingTop:"0.7rem"}}>
        <Grid
          item
          xs={12}
         
        >
          <MaterialTable
            style={{ boxShadow: "none" }}
            // style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              headerStyle: {
                ...stElemnt.AF.header,
                backgroundColor: colors.grey[100],
                fontWeight: "bold",
                paddingTop: "2px",
                paddingBottom:"2px",
                border: "solid 0px",
                border: "solid 0.1px",
               
                
              },
              paging: false,
              cellStyle: {
                ...stElemnt.AF.cell,
                padding: "0px 1px",
                // fontSize: 8,
              },
            
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h5 style={{ color: "GrayText" }}>
                    لا توجد ارصدة بعد في هذا الحساب
                  </h5>
                ),
              },
            }}
            columns={columns}
            data={newData}
          />
          {isLast && <Totals data={data ?? []} />}
        </Grid>
      </Grid>
   
      
    </Grid>
  );
}

export default BodyS;
