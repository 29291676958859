import React from 'react';
import useFetchURL from '../../../../components/Hooks/useFetchURL';
import { uri } from '../../../../help/Api';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import TrunItemsAvgForm from './TrunItemsAvg-form';
import toast from 'react-hot-toast';
import { useEffect } from 'react';

 const defaultValues = {
    branch: {id:-1, name:'الكل'},  
    safe:{id:-1, name:'الكل'},
    curr: {id:-1, name:'الكل'},
  }

export default function TrunItemsAvg() {

  let his = useHistory();
const [rptParams, setRptParams] = React.useState(defaultValues);

  const {data, fetching, fetchURL} = useFetchURL({...{
    url: uri+`RptSafes/trun-items-avg?branch=${rptParams.branch.id}
    &&safe=${rptParams.safe.id}
    &&item_No=${rptParams.curr.id}`,
    dependencyArray:[]
  }});


  const fetchData=()=>{
    if(rptParams.curr.id==-1)
      toast.error('يجب اختيار الصنف');
    else
      fetchURL();
  }
  const onChangeData =(propName)=>(event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };

  const printHandle = ()=>{
    const info ={...data, dateFrom: rptParams.dateFrom,
      dateTo: rptParams.dateTo}
    his.push({ pathname: '/', 
    state:info,});
    window.location.reload(false);
  }

  return (
    <TrunItemsAvgForm 
    {...{
      data:data? data: [],
      onChangeData,
      printHandle,
      rptParams,
      setRptParams,
      fetchURL,
      fetching,
    }}/>
  );




};