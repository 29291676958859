import moment from "moment/moment";
import * as yup from "yup";
import { date } from "yup/lib/locale";
import { sto } from "../Context/InshData";

export const twreedValidateSchema = yup.object().shape({
  acc: yup.string().required("قم بتحديد الحساب"),
});

export const subDefaultValues = {
  res_id: "",
  barcode: "",
  symbol: "",
  unit: "",
  mUnit: { id: "", name: "" },
  units: [],
  price: "",
  quan: "",
  total: "",
  sub_branch: "",
  sub_center: "",
};

export const twreedDefault = {
  no: "",
  date: new moment(new Date()).format("YYYY-MM-DD"),
  safe: {},
  center: -1,
  refno: "",
  notes: "",
  res_id: "",
  acc: { id: "", name: "" },
  branch: sto.branchi,
  omla: "",
  rows: [],
};

export function isEmptyFilters(filters) {
  return filters.group.id == 0 && filters.filter.id == 0 && !filters.all;
}

export function getReFormatingGtys(data) {
  const newData = data.map((row, i) => {
    return {
      ...row,
      id: i,
      item: { id: row.currency_from, symbol: row.Name_Item },
      val2: 0,
      diff: 0 - row.Qtys,
    };
  });
  return newData;
}

export function formatTwreed(data) {
  var clone = Object.assign({}, data);
  delete clone.rows;
  const twreed = {
    ...clone,
    acc: data.acc.id,
    center: data.center.id ? data.center.id : "-1",
    omla: data.omla.id,
    safe: data.safe.id,
    branch: sto.branchi,
  };

  const _Data = data.rows.map((r) => {
    return {
      item: r.id,
      unit: r.mUnit?.id,
      price: r.price,
      quan: r.quan,
      totquan: r.quan,
      sum: r.total,
    };
  });
  return { twreed, _Data };
}
