import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import React  from "react";
import { cellS, headerB } from "../../../St/comps/cStyle";

import {useStylesContainers,} from '../../../St/comps/UseStyle';
function getItemFromOptions(id, options){
  if(id)
    return options.filter(g=>g.id==id)[0];
  return '';
}
const SafesTable =(props)=>{


    const { data:options, safes, onShow} = props;
  const clsConts = useStylesContainers();
    const columns = [
    {title: "الرقم", field: "id", },
    {title: "اسم المخزن", field: "name", },
    {title: "الفرع", field: "branchName", },
    {title: "حالة المخزن ", field: "status", },
    {title: "الوصف", field: "notes", },
  ];

    function onRowClickHandle(data){
      
      console.log(data);
        onShow({...data, 
          branch: getItemFromOptions(data.branch, options.auto.Branches),
          status: data.status != -1?  getItemFromOptions(data.status, options.auto.States): options.auto.States[0],
          }, "edit"); 

    }
    return(
        
 <Grid item container lg={12} md={12} className={[clsConts.rootdown,]}>
    <Grid item container xs={12} className={[clsConts.subDown,clsConts.subDownC,]}>
      <Grid item xs={12}>
           <MaterialTable style={{boxShadow:'none'}}

                title=''
              // variant="outlined"
              options={{ 
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
                paging: false,
           
                headerStyle: headerB,
                cellStyle: cellS,
              maxBodyHeight: '65vh',
              minBodyHeight: '65vh',
             }}

             localization={{

              body:{
                emptyDataSourceMessage:<h3 
                style={{ marginTop:'20vh', border:'solid 0px', boxShadow:'none'}}
                ></h3> } }}
              columns={columns}
             data={safes}
             
             onRowClick={(event, rowData) => {
                onRowClickHandle(rowData);
              // event.stopPropagation();
              event.preventDefault();
            }}/>
      </Grid>    
    </Grid>
    </Grid>
    )
}

export default SafesTable;