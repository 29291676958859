import React, { Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  Button,
} from "@material-ui/core";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import { useEffect } from "react";
import { sto } from "../../../../Context/InshData";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function ItemInfoDialog(props) {
  const { infoOpen, setInfoOpen, barcode, itemName } = props;
  const classes = useStyles();

  return (
    <Dialog open={infoOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>{itemName}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ItemSafesTable barcode={barcode} />
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button onClick={() => setInfoOpen(false)}>إغلاق </Button>
      </DialogActions>
    </Dialog>
  );
}

function ItemSafesTable(props) {
  const { barcode } = props;
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `TaswyaBasicData/countCurrES?barcode=${barcode}&&branch=${sto.branchi}`,
      // mapFunction: getReFormatingGtys,
      dependencyArray: [],
    },
  });
  useEffect(() => {
    console.log("the data is", data);
  });
  if (!data || fetching) return <></>;
  return (
    <Fragment>
      <Grid container xs={12}>
        <Grid item xs={6} style={{ backgroundColor: "#aaa" }}>
          الكمية
        </Grid>
        <Grid item xs={6} style={{ backgroundColor: "#aaa" }}>
          {" "}
          المخزن
        </Grid>
        {data?.map((d) => {
          return (
            <Grid
              xs={12}
              container
              item
              style={{ backgroundColor: `${d.Qtys > 0 ? "#00ff00" : "#fff"}` }}
            >
              <Grid item xs={6}>
                {d.Qtys}
              </Grid>
              <Grid item xs={6}>
                {" "}
                {d.name}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
    // <MaterialTable
    //   style={{ boxShadow: "none" }}
    //   title="بحث عن الفواتير"
    //   // variant="outlined"
    //   options={{
    //     pageSize: 7,
    //     //showTitle:false,
    //     toolbar: false,
    //     sorting: false,
    //     showTitle: false,
    //     paging: false,

    //     headerStyle: headerS,
    //     cellStyle: cellS,
    //     maxBodyHeight: "10vh",
    //     minBodyHeight: "20vh",
    //   }}
    //   localization={{
    //     body: {
    //       emptyDataSourceMessage: (
    //         <h3
    //           style={{
    //             marginTop: "20vh",
    //             border: "solid 0px",
    //             boxShadow: "none",
    //           }}
    //         ></h3>
    //       ),
    //     },
    //   }}
    //   columns={columns}
    //   data={data}
    //   onRowClick={(event, rowData) => {
    //     event.stopPropagation();
    //   }}
    // />
  );
}
