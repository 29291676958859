import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    useStylesUp, useStylesHed, useStylesAuto,useStylesAutoPurch,useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,useStylesAutoSafe
} from '../../../../St/Reports/UseStyle';

export default function ItemsMoveForm(props) {
    const {
        AutoOptions,
        rptParams,
        onChangeData,
        onChangeAutoData,
    } = props;

    const { t } = useTranslation(["forms"]);

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();
    const clasASafe = useStylesAutoSafe();



    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasTD = useStylesTD();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    return (
        <React.Fragment>
            <Grid container style={{ padding: 5 }} spacing={1}>

                <Grid item lg={2} md={2} sm={3} xs={6}  style={{paddingBottom:"0.5rem"}}>
                    <TextField fullWidth variant="outlined" size="small" id="dateFrom" label="من تاريخ"
                         className={clasTF.rootSafe}InputProps={{ className: claslbl.clrSafe }}
                        type="date" format="YYYY-MM-DD"
                        name="dateFrom" value={rptParams.dateFrom} onChange={onChangeData('dateFrom')}
                    /></Grid>
              
                <Grid item lg={2} md={2} sm={3} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <TextField fullWidth variant="outlined" size="small" id="dateTo" label="إلى تاريخ"
                         className={clasTF.rootSafe} InputProps={{className:claslbl.clrSafe}}
                        type="date" format="YYYY-MM-DD"
                        name="dateTo" value={rptParams.dateTo} onChange={onChangeData('dateTo')}
                    /></Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete
                      classes={clasASafe}
                      value={rptParams.curr}
                      onChange={(event, newValue) => {
                        onChangeAutoData("curr", newValue);
                        // console.log(newValue);
                      }}
                      id="controllable-states-demo"
                      size="small"
                      options={AutoOptions.items}
                      getOptionLabel={(option) =>
                        typeof option === "string"
                          ? option
                          : option?.i?.toString()
                      }
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"باركود"}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className:claslbl.clrSafe}}
                        />
                      )}
                    />
                   
                  </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasASafe}
                        value={rptParams.curr}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('curr', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={AutoOptions.items}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="الصنف" variant="outlined" size="small"
                            InputLabelProps={{className:claslbl.clrSafe }} />}
                    />


                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasASafe}
                        value={rptParams.safe}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('safe', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={AutoOptions.Safes}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="المخزن" variant="outlined" size="small"
                            InputLabelProps={{ className:claslbl.clrSafe } } />}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasASafe}
                        value={rptParams.branch}
                        onChange={(event, newValue) => {
                            //   setBranch(newValue);
                            onChangeAutoData("branch", newValue);

                        }}
                        id="combo-box-demo"
                        size="small"
                        options={AutoOptions.Branches}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }

                        style={{}}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("branch")}
                                variant="outlined"
                                InputLabelProps={{ className:claslbl.clrSafe } }
                            />
                        )}
                        
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
