import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Chip, colors } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { selectTypes } from "./accountingRPT-services";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  chip: {
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "10px",
  },
}));

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const SelectTypesFilter = (props) => {
  const { rptParams, setRptParams } = props;
  const { t } = useTranslation(["forms"]);
  const classes = useStyles();

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        padding: "5px",
        margin: "0px",
      }}
      component="ul"
    >
      {selectTypes.map((x) => {
        return (
          <ListItem key={x.id}>
            {x.id === rptParams.selectType.id ? (
              <Chip
                className={classes.chip}
                label={t(x.label)}
                icon={<Done />}
                style={{ backgroundColor: colors.indigo[300], color: "#fff" }}
                clickable
              />
            ) : (
              <Chip
                className={classes.chip}
                label={t(x.label)}
                variant="outlined"
                color={"primary"}
                onClick={() => {
                  setRptParams({ ...rptParams, selectType: x });
                }}
              />
            )}
          </ListItem>
        );
      })}
    </Container>
  );
};

export default SelectTypesFilter;
