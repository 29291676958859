import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import Button from '../../../../componentsT/controls/Button';
import { Button, Container, IconButton } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { Filter } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import TaswyaFiltersDialog from "./Fiters-dialog";
import {
  dfaultfilters,
  formatTaswya,
  taswyaValidateSchema,
} from "../../../../services/taswya-servece";
import AccountSearchModal from "../../../Accounts/BackEnd/accounts-search-modal";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
  },

  textField3: {
    // margin: 10,
    // margin: theme.spacing(1),
    width: "100%",
    marginBottom: 5,
    marginTop: 5,
  },
  textField: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "navy",
      fontSize: "0.8rem",
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: "navy",
      fontSize: "0.7rem",
      lineHeight: "0.5rem",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 10,
      paddingBottom: 10,
      textAlign: "center",
      fontSize: "1rem",
    },
  },

  root: {
    direction: "rtl",
    marginBottom: theme.spacing(1),
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
    border: "solid 1px #009688",
    padding: 5,
  },
}));

export default function ReMakeStoreForm(props) {
  const {
    object: data,
    newId,
    filters,
    setFilters,
    formType,
    safes,
    centers,
    groups,
    GetByFilters,
  } = props;
  const { t } = useTranslation(["forms"]);

  const clasess = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [openSearchMdl, setOpenSearchMdl] = useState(false);

  const onFilterByClick = () => {
    setIsOpen(true);
  };

  const onChangeData = (prop) => (event) => {
    props.onChange(event, { name: prop, value: event.target.value });
    // setData({ ...data, [prop]: event.target.value })
  };

  return (
    <container className={clasess.main}>
      <Grid
        item
        className={clasess.root}
        container
        spacing={1}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label={t("no")}
            name="no"
            value={formType == "edit" ? data.no : newId}
            InputLabelProps={{ shrink: true }}
            onChange={onChangeData("no")}
            disabled={true}
          />
        </Grid>

        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <form noValidate>
            <TextField
              fullWidth
              variant="outlined"
              id="date"
              label={t("date")}
              type="date"
              size="small"
              value={data.date}
              onChange={onChangeData("date")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="refno"
            label={t("refno")}
            name="refno"
            value={data.refno}
            onChange={onChangeData("refno")}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={data.safe ? data.safe : {}}
            onChange={(event, newValue) => {
              props.onChange(newValue, { name: "safe", value: newValue });
            }}
            id="combo-box-demo1"
            size="small"
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            options={safes}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("safe")}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>

        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={data.center ? data.center : ""}
            onChange={(event, newValue) => {
              props.onChange(newValue, { name: "center", value: newValue });
              // setData({...data, center: newValue.id})
            }}
            id="controllable-states-2"
            size="small"
            options={centers || []}
            getOptionLabel={(Option) => (Option ? Option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("center")}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Button
            className={clasess.buttonss}
            style={{ direction: "ltr", backgroundColor: "blue" }}
            variant="contained"
            color="primary"
            fontSize="sm"
            size="medium"
            onClick={onFilterByClick}
            startIcon={<Filter />}
          >
            {t("fetch-by")}{" "}
          </Button>
        </Grid>
        {/* <Grid className={clasess.textField} item lg={1} md={1} sm={4} xs={2}>
          <Button className={clasess.buttonss}   style={{direction:"ltr",backgroundColor:'blue'}}   variant="contained" color="primary" fontSize="large" size="medium"
              onClick={()=>{setFilters({...dfaultfilters, all: true})}} >{'جلب الكل'}    </Button>
        </Grid> */}
        {/* <Grid item container spacing={1} style={{backgroundColor:'#ffffdd',
            }} lg={12} md={12} sm={12} xs={12} > */}

        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label={t("account-code")}
            name="acc"
            value={data.acc?.id}
            InputLabelProps={{ shrink: true }}
            disabled={true}
          />
        </Grid>

        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label={t("account")}
            name="acc_name"
            value={data.acc?.name}
            InputLabelProps={{ shrink: true }}
            disabled={true}
          />
        </Grid>
        <Grid className={clasess.textField} item lg={1} md={1} sm={1} xs={1}>
          <IconButton
            className={clasess.textField3}
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => {
              setOpenSearchMdl(true);
            }}
          >
            <SearchIcon />
          </IconButton>
        </Grid>

        <Grid className={clasess.textField} item lg={1} md={1} sm={2} xs={3}>
          <Button
            className={clasess.buttonss}
            style={{ direction: "ltr", backgroundColor: "blue" }}
            variant="contained"
            color="primary"
            fontSize="small"
            size="medium"
            onClick={() => {
              GetByFilters({ ...dfaultfilters, all: true });
            }}
          >
            {t("fetch-all")}{" "}
          </Button>
        </Grid>
        {/* </Grid> */}

        <Grid className={clasess.textField} item lg={3} md={3} sm={3} xs={8}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label={t("notes")}
            name="notes"
            value={data.notes}
            InputLabelProps={{ shrink: true }}
            onChange={onChangeData("notes")}
          />
        </Grid>
      </Grid>

      <TaswyaFiltersDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        filters={filters}
        GetByFilters={GetByFilters}
        setFilters={setFilters}
        groups={groups}
      />
      <AccountSearchModal
        {...{
          open: openSearchMdl,
          handleClose: () => {
            setOpenSearchMdl(false);
          },
          onSelectAccount: (account) => {
            const value = {
              id: account.barcode,
              name: account.symbol,
            };
            props.onChange(null, { name: "acc", value: value });

            setOpenSearchMdl(false);
          },
        }}
      />
      {/* <Typography component="div" direction='ltr'> <Swipeable /> </Typography> */}
    </container>
  );
}
