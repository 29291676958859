import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
import { get } from "@awesome-cordova-plugins/core/decorators/common";
import { invoicePayTypes, salesmanViewTypes } from "../../../../../services/invoice-service";
import { sto } from "../../../../../Context/InshData";
import { invoiceTypes, salesmanCommTypes } from "../../../../../services/saleAnalysis-services";
export default function SalesmanSalesForm(props) {
    const {
        AutoOptions,
        rptParams,
        onChangeData,
        onChangeAutoData,
        isPurches,
 
    } = props;

    const { t } = useTranslation(["forms"]);

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();


    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasTD = useStylesTD();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    return (
        <React.Fragment>
            <Grid container style={{ padding: 5 }} spacing={1}>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.branch}
                        onChange={(event, newValue) => {
                            //   setBranch(newValue);
                            onChangeAutoData("branch", newValue);

                        }}
                        id="combo-box-demo"
                        size="small"
                        options={AutoOptions.Branches}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }

                        style={{}}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("branch")}
                                variant="outlined"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}

                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.salesMan}
                        onChange={(event, newValue) => {
                            onChangeAutoData("salesMan", newValue);
                            // console.log(newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={AutoOptions.salesmen}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                id="salesmen"
                                {...params}
                                label={t("delegate")}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}
                    />

                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small" id="dateFrom" label="من تاريخ"
                        className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                        type="date" format="YYYY-MM-DD"
                        name="dateFrom" value={rptParams.dateFrom} onChange={onChangeData('dateFrom')}
                    /></Grid>
                {rptParams.selectTime.id == 2 && (
                    <Grid item lg={2} md={2} sm={3} xs={6}>
                        <TextField fullWidth variant="outlined" size="small" id="timeFrom" label="الوقت "
                            className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                            type="time"
                            format="HH:mm"
                            name="timeFrom" value={rptParams.timeFrom} onChange={onChangeData('timeFrom')}
                        /></Grid>
                )}
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small" id="dateTo" label="إلى تاريخ"
                        className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
                        InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                        type="date" format="YYYY-MM-DD"
                        name="dateTo" value={rptParams.dateTo} onChange={onChangeData('dateTo')}
                    /></Grid>

                {rptParams.selectTime.id == 2 && (
                    <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                        <TextField fullWidth variant="outlined" size="small" id="timeTo" label="الوقت "
                            className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
                            InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                            type="time"
                            format="HH:mm"
                            name="timeTo" value={rptParams.timeTo} onChange={onChangeData('timeTo')}
                        /></Grid>
                )}

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.omla_type}
                        onChange={(event, newValue) => {
                            onChangeAutoData("omla_type", newValue);
                            // console.log(newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={salesmanCommTypes}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                id="typeinvs"
                                {...params}
                                label={"نوع العمولة"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}
                    />

                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.inv_type}
                        onChange={(event, newValue) => {
                            onChangeAutoData("inv_type", newValue);
                            // console.log(newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={invoiceTypes}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                id="typeinvs"
                                {...params}
                                label={"نوع الفاتورة"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}
                    />

                </Grid>

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.pay_type}
                        onChange={(event, newValue) => {
                            onChangeAutoData("pay_type", newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={invoicePayTypes}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                id="pay_type"
                                {...params}
                                label={"نوع الدفع"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}
                    />

                </Grid>
                {rptParams.pay_type.id == 2 && (
                    <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                        <Autocomplete
                            classes={isPurches ? calsPurch : clasAo}
                            value={rptParams.bank}
                            onChange={(event, newValue) => {
                                onChangeAutoData("bank", newValue);
                                // console.log(newValue);
                            }}
                            id="controllable-states-demo"
                            size="small"
                            options={AutoOptions.banks}
                            // disabled={rptParams?.pay_type?.id != 2}
                            getOptionLabel={(option) =>
                                typeof option === "string" ? option : option.name
                            }
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    id="bank"
                                    {...params}
                                    label={"الحساب"}
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                                />
                            )}
                        />

                    </Grid>
                )}
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    fullWidth
                                    checked={rptParams.CommWithVat}
                                    onChange={(e) =>
                                        onChangeAutoData("CommWithVat", e.target.checked)
                                    }
                                    name="chkVat"
                                />
                            }
                            size="small"
                            color="primary"
                            fontSize="small"
                            label={t("عمولة مع ضريبة")}
                        />
                    </FormGroup>
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    fullWidth
                                    checked={rptParams.CommWithProfit}
                                    onChange={(e) =>
                                        onChangeAutoData("CommWithProfit", e.target.checked)
                                    }
                                    name="CommWithProfit"
                                />
                            }
                            size="small"
                            color="green"
                            fontSize="small"
                            
                            label={t("عمولة مع الربح")}
                            style={{fontWeight:"bold",fontSize:"1rem"}}

                        />
                    </FormGroup>
                </Grid>
                {/* <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.pay_type}
                        onChange={(event, newValue) => {
                            onChangeAutoData("view_type", newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={salesmanViewTypes}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                id="pay_type"
                                {...params}
                                label={"نوع العرض"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}
                    />

                </Grid> */}

            </Grid>
        </React.Fragment>
    );
}
