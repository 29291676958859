import axios from 'axios'
import { uri } from '../help/Api'

export const ACCOUNTINDEX_URL =uri+'Accounts_Index';

export function list(){
    return axios.get(ACCOUNTINDEX_URL)
}

export function create(data){

    return axios.post(ACCOUNTINDEX_URL, data);
}

export function update(data){
    return axios.put(ACCOUNTINDEX_URL, data)
}
export function remove(id){
    return axios.delete(ACCOUNTINDEX_URL+`/${id}`)
}

export default{ list, create, update, remove}