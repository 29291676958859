import React from "react";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import API from "../../../../api/UsersCURD";
import User from "./user";
import { useEffect } from "react";
import { insh, sto } from "../../../../Context/InshData";
import LoadingPage from "../../../../services/LoadingPage";

export default function UserCon() {
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url: API.URL + `?userId=${sto.idi}`,
    },
  });

  // const {object:data, fetching: fetchinData, fetch:fetchURL} =  useFetchObjectByPost(url, tb);
  useEffect(() => {
    console.log("uers is", data);
  });

  if (fetching || !data?.length > 0) return <LoadingPage />;

  return (
    <User
      {...{
        data,
        fetchURL,
      }}
    />
  );
}
