import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import { cellS, headerB, headerS } from "../../../../St/comps/cStyle";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function CostCenterRptTable(props) {
  const { data } = props;
  const { t } = useTranslation(["forms"]);
  var columns = [
    { title: "الرصيد", field: "balance" },
    { title: "مدين", field: "dept" },
    { title: "دائن", field: "credit" },
    { title: "رقم المستند", field: "doc_no" },
    { title: "تاريخ المستند", field: "date" },
    { title: "رقم المرجع", field: "id" },
    { title: "البيان", field: "notes" },
  ];
  const data2 = data.map((row,i) => {
    return {
      ...row,
      balance: row.balance.toFixed(2),
      dept: row.dept.toFixed(2),
      credit: row.credit.toFixed(2),
    };
  });


  return (
    <MaterialTable
      style={{ boxShadow: "none" }}
      // variant="outlined"
      options={{
        pageSize: 7,
        //showTitle:false,
        toolbar: false,
        sorting: false,
        showTitle: false,
        paging: false,

        headerStyle: headerB,
        cellStyle: cellS,
        maxBodyHeight: "54vh",
        minBodyHeight: "55vh",
      }}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <h3
              style={{
                marginTop: "20vh",
                border: "solid 0px",
                boxShadow: "none",
              }}
            ></h3>
          ),
        },
      }}
      columns={columns}
      data={data2}
      onRowClick={(event, rowData) => {
        event.stopPropagation();
      }}
    />
    
  );
}
