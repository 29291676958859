import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  levelOptions,
  typeCostOptions,
} from "../../../../services/accMasn-services";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

export default function MainRBalanceForm(props) {
  const { rptParams, onChangeData, AutoOptions, onChangeAutoData } = props;
  const { t } = useTranslation(["forms"]);

  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="datef"
            label={t("date-from")}
            type="date"
            format="YYYY-MM-DD"
            name="datef"
            value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="dateT"
            label={t("date-to")}
            type="date"
            format="YYYY-MM-DD"
            name="datet"
            value={rptParams.dateTo}
            onChange={onChangeData("dateTo")}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.center}
            onChange={(event, newValue) => {
              onChangeAutoData("center", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.center}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("center")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.branch}
            onChange={(event, newValue) => {
              onChangeAutoData("branch", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Branches}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("branch")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.level}
            onChange={(event, newValue) => {
              onChangeAutoData("level", newValue);
            }}
            id="combo-box-demo6"
            size="small"
            options={levelOptions}
            getOptionLabel={(option) => option.label}
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("level")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        {rptParams.type.finalAcc != -1 && (
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <Autocomplete
              value={rptParams.type_cost}
              onChange={(event, newValue) => {
                onChangeAutoData("type_cost", newValue);
              }}
              id="combo-box-demo"
              size="small"
              options={typeCostOptions}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              style={{}}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={"طريقة التقييم "}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.chkPrev}
                  onChange={(e) =>
                    onChangeAutoData("chkPrev", e.target.checked)
                  }
                  name="chkPrev"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label={t("prev-balance")}
            />
          </FormGroup>
        </Grid>
        {/* <Grid item lg={2} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.chk_withvat}
                  onChange={(e) =>
                    onChangeAutoData("chk_withvat", e.target.checked)
                  }
                  name="chkVat"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label="إلغاء الأرصدة الصفرية"
            />
          </FormGroup>
        </Grid> */}
      

        {rptParams.type.finalAcc != -1 && (
          <Grid item lg={4} md={4} sm={5} xs={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    fullWidth
                    checked={rptParams.no_stock}
                    onChange={(e) =>
                      onChangeAutoData("no_stock", e.target.checked)
                    }
                    name="no_stock"
                  />
                }
                size="small"
                color="primary"
                fontSize="small"
                label={"استثناء قيمة المخزون(ارباح وخسائر)"}
              />
            </FormGroup>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
