import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function SalesCloseTable(props) {
  const { data, title } = props;


  
  const { t } = useTranslation(["forms"]);
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table 
      //className={classes.table} 
      size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{
              backgroundColor:colors.green[300],
              color: "#fff",
              textAlign: "-webkit-center",
              margin: "0px",
              whiteSpace: "nowrap",
              width: "0px",
              padding: "15px 0px",
              textAlign:"right"
            }}
          >
             <TableCell style={{ color: "#fff" ,textAlign:"right",fontWeight:"bold"}} align="center">
              {"No"}
            </TableCell>
            <TableCell style={{ color: "#fff" ,textAlign:"right",fontWeight:"bold"}} align="center">
              {"Opration"}
            </TableCell>
            <TableCell style={{ color: "#fff" ,textAlign:"right",fontWeight:"bold"}} align="center">
              {"Total"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,i) => (
            <TableRow key={row.name}
            style={{ padding: "5px 0px",
                textAlign: "-webkit-center",
                margin: "0px",
                whiteSpace: "nowrap",
                textAlign:"right"}}>
              <TableCell   style={{textAlign:"right",fontWeight:"bold"}} align="center">{i}</TableCell>
              <TableCell   style={{textAlign:"right",fontWeight:"bold"}} align="center">{row.name}</TableCell>
              <TableCell  style={{textAlign:"right",fontWeight:"bold"}} align="center" >{row.TotalFloat?.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
