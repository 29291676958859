import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

import Container from "@material-ui/core/Container";
import { useStylesContainers, useStylesHed } from "../../../St/comps/UseStyle";
import SalesPersonForm from "./SalesPerson-form";
import SalesPersonTable from "./SalesPerson-table";
import { Toaster } from "react-hot-toast";
import { insh } from "../../../Context/InshData";
import SalesPersonAppBar from "./SalesPerson-appBar";
import basicDataAPI from "../../../api/basicData";
import useFormDataCRUD from "../../../components/Hooks/useEDSCURD";
import { useAPI } from "../../../api/useAPI";

export default function SalesPerson(props) {
  const { data, fetchURL } = props;
  const { createFun, updateFun, removeFun } = useAPI("Salesmen");
  const defaultValues = {
    ...insh.salesman,
  };

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    createFun,
    updateFun,
    removeFun,
    undefined,
    defaultValues,
    null,
    fetchURL
  );
  useEffect(() => {
    console.log("secound item", object);
  });

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();

  const styheader = {
    textAlign: "center",
    backgroundColor: "#fff",
    height: "100vh",
    direction: "rtl",
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Container maxWidth="xl">
        <Toaster />

        <Typography component="div" style={styheader}>
          <Grid item container>
            <Grid item xs={12}>
              <Chip
                size="small"
                className={clashd.ChipHdSale}
                label={" المنــاديب"}
                color="primary"
              />
            </Grid>
            <Grid container xs={12}>
              <Grid
                item
                container
                lg={12}
                md={12}
                className={[clsConts.rootup]}
              >
                <Grid
                  item
                  container
                  spacing={1}
                  xs={12}
                  className={[clsConts.subUp, clsConts.subUpC]}
                >
                  <SalesPersonForm
                    {...{
                      onChange,
                      submit,
                      update,
                      remove,
                      submitting,
                      errorMessage,
                      onShow,
                      onEnd,
                      object,
                      formType,
                    }}
                  />
                </Grid>
              </Grid>
              <SalesPersonTable {...{ ...props, onShow, data: data }} />
            </Grid>
          </Grid>
          <SalesPersonAppBar
            {...{
              ...props,
              onChange,
              defaultValues,
              submit,
              update,
              remove,
              submitting,
              errorMessage,
              onShow,
              onEnd,
              object,
              formType,
            }}
          />
        </Typography>
      </Container>
    </React.Fragment>
  );
}
