import React, {useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';
import PrintIcon from '@material-ui/icons/Print';
import { useHistory } from 'react-router-dom';
import {useStylesBA  ,useStylesCAB,} from '../../../St/comps/UseStyle';

import { stElemnt } from '../../../St/comps/cStyle';
import toast from 'react-hot-toast';
import {insh as inshst} from '../../../Context/InshSt';

import DeleteConfirmDialog from '../../../components/DeleteConfirmDialog';

export default function SafesAppBar(props){
    const {
        submitting,
        submit,
        update,
        remove,
        object,
        formType,
        onShow,
        defaultValues,
    } = props;

  let his = useHistory();
  const clasBA = useStylesBA();
  const clasCAB = useStylesCAB();

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false })

  const [style, setStyle] = useState(inshst.bascData.all);

  
const onSubmitHandle =(data)=>{
    if(data.name ==''){
      toast.error("ادخل اسم المخزن");
    }
    else if(data.Safe_Emps.length == 0){
      toast.error("حدد الموظف المسؤول عن المخزن");
    }else {
      const Safe_Emps = data.Safe_Emps.map(se=>{
        return {emp_id: se.id, safe_id: 0}
      });
       const tb = {
        safes:{...data,
        branch: data?.branch?.id,
        status: data.status.id},
        Safe_Emps: Safe_Emps,
        tbstate:{ Safes:true, parms:{tname:"Safes"} }
      }
      delete tb.safes.Safe_Emps;
        if(formType=='edit')
            update(tb)
        else
            submit(tb)
    }

}

const backHome = () => { his.push({ pathname: '/', }); /*console.log('stooping')*/ }
const back = () => his.goBack(); 


return (
      <React.Fragment>
        <DeleteConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
          />
      <CssBaseline />
      <AppBar position="fixed" color="primary"
      style={{...style.bgImg,...stElemnt.all.appBarBA,direction:'ltr'}}
      >
        <Toolbar className={clasBA.ToolbarBA} variant="dense">

        <Hidden smUp={['sm']}>
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={()=>backHome()}>
            <CloseIcon />
          </IconButton>
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={()=>back()}>
            <ReplyIcon />
          </IconButton>
          <IconButton edge="start" color="inherit" aria-label="open drawer" 
              onClick={""}>
            <PrintIcon />
          </IconButton>
        </Hidden>

        <Hidden only={['xs']}>
          <Button style={{color:'#fff'}} onClick={()=>backHome()} startIcon={<CloseIcon />} >خروج</Button>
          <Button style={{color:'#fff'}} onClick={()=>back()} startIcon={<ReplyIcon />} >رجوع</Button>
        </Hidden>

          <div className={clasBA.growBA} />

      
    <Hidden smUp={['sm']}>

        {/* ico left  */}
        <div className={clasCAB.wrapperCAB}>
          <Fab size="medium" color="primary"
          onClick={()=>onSubmitHandle(object)}
          style={{...style.iconBtn,...stElemnt.all.iconBtn}}
          >{<SaveIcon />}
          </Fab>
          {submitting && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
        </div>
      
        <div className={clasCAB.wrapperCAB}>
          <Fab size="medium" color="primary" 
          onClick={()=>{
            onShow(defaultValues, 'new');
            toast('تم تهيئة النموذج لإدخال بيانات جديدة ')
        }}
          style={{...style.iconBtn,...stElemnt.all.iconBtn}} 
          >{<AddIcon />}
          </Fab>
          {submitting && <CircularProgress size={56} className={clasCAB.fabProgressCAB} />}
        </div>
      </Hidden>
      {formType == 'edit'?
      <div className={clasCAB.wrapperCAB}>
        <Button variant="contained" color="primary" size="large"
          startIcon={<DeleteIcon />}
          style={{...style.iconBtn,...stElemnt.all.iconBtn}}
          disabled={submitting} 
          onClick={(e)=>
            setConfirmDialog({
                      isOpen: true,
                      onConfirm: () => { remove(object.id); }
                  })
          }
          
          >حـذف</Button>
        {submitting && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
      </div>:null}

      <Hidden only={['xs']}>
      <div className={clasCAB.wrapperCAB}>
        <Button variant="contained" color="primary" size="large"
          style={{...style.iconBtn,...stElemnt.all.iconBtn}}
          disabled={submitting} 
          onClick={()=>onSubmitHandle(object)}
            startIcon={<SaveIcon />}> {'حـفـظ'} </Button>
        {submitting && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
      </div>

      <div className={clasCAB.wrapperCAB}>
        <Button variant="contained" color="primary" size="large"
          style={{...style.iconBtn,...stElemnt.all.iconBtn}}
          onClick={()=>{
            onShow(defaultValues, 'new');
            toast.success('تم تهيئة النموذج لإدخال بيانات جديدة ')
        }}
            startIcon={<AddIcon />}> {'جـديـد'} </Button>
      </div>

      </Hidden>

        </Toolbar>
      </AppBar>
    </React.Fragment>

 );
 
}