import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import {
  useStylesTF,
  useStylesSetting,
} from "../../../St/comps/UseStyle";
import { useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import Swipeable from "../../../Fronts/Main/Swipeable";
import axios from "axios";
import toast from "react-hot-toast";
import { uri } from "../../../help/Api";
import moment from "moment";

export default function SubscriptionRenewForm(props) {
  const { object, data, onChange } = props;

  const clasTF = useStylesTF();

  const [countOfMonths, setCounOfMonths] = useState(0);
  const [fetching, setFetching]= useState(false);

  const checkKey = async (event)=>{
    event.preventDefault();

    setFetching(true);

    await axios.get(uri+`Foundation/checkKey?key=${object.key}`).then(response=>{

      onChange(null, {name:"isPossible", value: true});
    }).catch(error=>{
      toast.error("YOur Key is Incorrect!");
    })

    setFetching(false);
  }


  const onChangeData = (propName) => (e) => {
    
    onChange(null, { name: [propName], value: e.target.value });
  };
  const classesStt = useStylesSetting();

  return (
    <React.Fragment>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField  fullWidth variant="outlined" size="small" id="StartWorkDate" 
                      label="تاريخ بداية العقد الأول"
                      type="date" format="YYYY-MM-DD"
                      name="StartWorkDate" value={object.StartWorkDate} 
                      onChange={onChangeData("StartWorkDate")}/>
          
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            id="mizan_start_with`"
            label="key"
            // className={clasTF.rootSer}
            InputProps={{ className: clasTF.input }}
            placeholder="key"
            type="password"
            
            InputLabelProps={{ shrink: true }}
            name="mizan_start_with"
            value={object.key}
            onChange={onChangeData("key")}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
        <Button
                      variant="contained"
                      className={classesStt.btn}
                      color="primary"
                      fontSize="large"
                      disabled={fetching || object?.key==""}
                      onClick={
                        checkKey}
                    >
                      {"Check Key"}
                    </Button>
        </Grid>
        
      </Grid>
     
      <Grid container item lg={12} md={12} sm={12} xs={12}>
      <Grid item lg={6} md={6} sm={6} xs={6}>
          
          <TextField
            variant="outlined"
            size="small"
            id="countOfMonths"
            label="عدد الشهور"
            // className={clasTF.rootSer}
            disabled={true}
            InputProps={{ className: clasTF.input }}
            placeholder="عدد الشهور"
            type="mumber"
            InputLabelProps={{ shrink: true }}
            name="countOfMonths"
            value={countOfMonths}
            onChange={(e)=>{
              onChange(null, {name: "StopWorkingDate", value: moment(object.StopWorkingDate).add(e.target.value, "months").format("YYYY-MM-DD")});
              setCounOfMonths(e.target.value);
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField  fullWidth variant="outlined" size="small" id="StopWorkingDate" 
                        label="تاريخ نهاية العقد الأول"
                        type="date" format="YYYY-MM-DD"
                        disabled={!object?.isPossible}
                        name="StopWorkingDate" value={object?.StopWorkingDate} 
                        onChange={onChangeData("StopWorkingDate")}/>
        </Grid>
      </Grid>

      <Grid container item lg={12} md={12} xs={12}></Grid>
    </React.Fragment>
  );
}
