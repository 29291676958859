import React, { Suspense, useEffect } from "react";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

import "./i18n";

import { createTheme } from "@material-ui/core/styles";

import { MuiThemeProvider } from "@material-ui/core/styles";

import GlobalContext from "./Context/GlobalContext";
import useFetchURL from "./components/Hooks/useFetchURL";
import { sto } from "./Context/InshData";
import { uri } from "./help/Api";
import useFetchObjectByPost from "./components/Hooks/useFetchObjectByPost";

import { useState } from "react";
import { getLS } from "./utils/useLS";
import Routes from "./POS/Routes";
import MainRoutes from "./MainRoutes";
import UsersPermissionsCont from "./comps/users/UserPermissions/UserPermissionsCont";
import { NotFound } from "./POS/views";
import { Alert, AlertTitle } from "@material-ui/lab";
import SubScriptionEndingAlert from "./subscriptionAlert";

const tb = {
  groups: true,
  GetFoundation: true,
  empState: true,
  isExpired: true,
  parms: { iempid: sto.empi, branch: sto.branchi},
  autos: { groups: true, },
};

// import Reviews from './Foateer/Sales/Eqfal/ReviewEqfalEveryDay';
const theme = createTheme({
  typography: {
    fontFamily: [
      // 'Tajawal',
      "Noto Kufi Arabic",
      "Tajawal",

      // 'Almarai',
    ].join(","),

    fontSize: ["12"].join(","),
  },
});

function NestedRoute() {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Router>
        <Route exact path={`${path}/pos`} component={UsersPermissionsCont} />
      </Router>
    </Switch>
  );
}
function App() {
  const url = uri + "Res";
  const { object, fetching } = useFetchObjectByPost(url, tb);
  let pScr = getLS("premiScr", {});
  const [salePointOnly, setSalePointOnly] = useState(
    pScr?.salePointOnly ?? false
  );
  const [showAlert, setShowAlert] = useState(document.visibilityState=='visible');
  useEffect(() => {
    console.log("the ", object);
  });
  if (
    !fetching &&
    object &&
    object.hasOwnProperty("empState") &&
    object?.empState == false
  )
    return (
      <React.Fragment>
        <NotFound />{" "}
      </React.Fragment>
    );
  return (
    <React.Fragment>
      <GlobalContext.Provider
        value={{
          costCenters: object.costcenters,
          groups: object.groups,
          safes: [],
          omlat: object.omlat,
        }}
      >
        <CssBaseline />
        <Suspense fallback={null}>
          <MuiThemeProvider theme={theme}>
            <SubScriptionEndingAlert startDate={object?.Found?.StartWorkDate?? null}/>     
            <Router>{salePointOnly ? <Routes /> : <MainRoutes />}</Router>
          </MuiThemeProvider>
        </Suspense>
      </GlobalContext.Provider>
    </React.Fragment>
  );
}

export default App;
