import moment from "moment";

export const monthLabels=
[
    'يناير',
    'فبراير',
    'مارس',
    'ابريل ',
    'مايو',
    'يونيو',
    'يوليو',
    'اغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
]

export const getLatestSevenDayDate =()=>{
      var goBackDays = 7;
      var today = new Date();
      var daysSorted = [];
      for(var i = 0; i < goBackDays; i++) {
        var newDate = moment(new Date(today)).format('MMM Do');
        today.setDate(today.getDate() - 1);
        daysSorted.push(newDate);
      }
      return daysSorted.reverse();
}


export function nFormatter (num, digits){
    var si =[
        {value: 1, symbol:""},
        {value: 1E3, symbol:"K"},
        {value: 1E6, symbol:"M"},
        {value: 1E9, symbol:"G"},
        {value: 1E12, symbol:"T"},
        {value: 1E15, symbol:"P"},
        {value: 1E18, symbol:"E"}
    ];

    var rx= /\.0+$|(\.[0-9]*[1-9])0+$/
    var i;

    // for negative value is work 
    for(i = si.length-1; i>0; i--){
        if(Math.abs(num)>= si[i].value){
            break;
        }
    }
    if(i>0) i --;
    return (num/si[i].value).toFixed(digits)
    .replace(rx, "$1")+ si[i].symbol;
}