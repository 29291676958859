import React from "react";
import axios from "axios";
import { useStyles } from "./StoreTransferSub-style";
import { CircularProgress, Grid } from "@material-ui/core";
import StoreTransferSubForm from "./StoreTransferSub-form";
import StoreTransferSubTable from "./StoreTransferSub-table";
import { useState } from "react";
import { uri } from "../../../../../help/Api";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { defaultValues } from "../../../../../services/sub-taswya-service";
import {
  dfaultfilters,
  getReFormatingGtys,
} from "../../../../../services/taswya-servece";
import StoreTransferLoader from "./StoreTransferSub-loader";
import { Fragment } from "react";
import { useEffect } from "react";

export default function StoreTransferSub(props) {
  const { onChange, object } = props;
  const rows = props.object.rows;


  
  const { data, fetching: fetchingGtys } = useFetchURL({
    ...{
      url:
        uri +
        `TaswyaBasicData/TaswyaGtys?typeQtys=${dfaultfilters.filter.id}
      &&safe=${object.safe_from.id}
      &&branch=${dfaultfilters.branch}
      &&group_id=${dfaultfilters.group.id}`,
      mapFunction: getReFormatingGtys,
      dependencyArray: [object.safe_from],
    },
  });

  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const [currentRow, setCurrentRow] = useState(defaultValues);

  useEffect(() => {
    setCurrentRow(defaultValues);
  }, [object]);
  const addRow = (row) => {
    row.id = Date.now();
    onChange(null, { name: "rows", value: [...rows, row] });
    setCurrentRow(defaultValues);
  };

  const deleteRow = (id) => {
    onChange(null, {
      name: "rows",
      value: rows.filter((row) => row.id !== id),
    });
  };

  const selectRow = (row) => {
    setEditing(true);
    setCurrentRow({ ...row });
  };

  const updateRow = (id, updateRow) => {
    setEditing(false);
    onChange(null, {
      name: "rows",
      value: rows.map((row) => (row.id === id ? updateRow : row)),
    });
    setCurrentRow(defaultValues);
  };
  const cancelUpdate = () => {
    setCurrentRow(defaultValues);
    setEditing(false);
  };

  return (
    <container className={classes.main}>
      <Grid item className={classes.root} container disabeld>
        {fetchingGtys ? (
          <StoreTransferLoader />
        ) : (
          <Fragment>
            <StoreTransferSubForm
              {...{
                addRow,
                editing,
                setEditing,
                currentRow,
                setCurrentRow,
                updateRow,
                cancelUpdate,
                data,
              }}
            />

            <StoreTransferSubTable
              {...{
                rows,
                selectRow,
                deleteRow,
                loading: false,
              }}
            />
          </Fragment>
        )}
      </Grid>
    </container>
  );
}
