
export const getInveTypeIndex = (payTypeId)=>{

    let inveTypeIndex = 0;
  
    switch(payTypeId){
      case 0:
        inveTypeIndex = 1;
        break;
      case 1:
        inveTypeIndex = 0;
        break;
      case 2:
        inveTypeIndex = 1;
        break;
      case 3:
        inveTypeIndex =1;
        break;
      case 4:
        inveTypeIndex = 1;
        break;
      default: 
        inveTypeIndex = 1;
        break;
    }
  
    return inveTypeIndex;
  
  }