import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Menu,
  MenuList,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  colors,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Folder } from "@material-ui/icons";

import { BeatLoader } from "react-spinners";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import { uri } from "../../../help/Api";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: "relative",
  },
  content: {
    padding: 0,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const SafesQuantityView = (props) => {
  const { className, data } = props;

  return (
    <List>
      {data.map((product, i) => (
        <ListItem
          divider={i < data.length - 1}
          key={product.id}
          style={{ padding: 5 }}
        >
          <ListItemAvatar>
            <Avatar
              style={{
                height: 30,
                width: 30,
                backgroundColor: `${
                  product.Qtys <= 0
                    ? colors.red[900]
                    : product.Qtys <= 10
                    ? colors.amber[500]
                    : colors.green[500]
                }`,
              }}
            >
              <Folder
                style={{
                  height: 15,
                  width: 15,
                }}
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            style={{ textAlign: "right" }}
            primary={product.name}
            // secondary={product.Qtys <= 0 ? `إنتهى من المخزون ` : ""}
          />
          <IconButton
            edge="end"
            size="small"
            style={{
              backgroundColor: `${
                product.Qtys <= 0
                  ? colors.red[50]
                  : product.Qtys <= 10
                  ? colors.yellow[50]
                  : colors.green[50]
              }`,
            }}
          >
            {product.Qtys}
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
};

const SafesQuantity = (props) => {
  const classes = useStyles();
  const { Safes, className } = props;
  const [safe, setSafe] = useState(props?.Safes[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { data, fetching } = useFetchURL({
    ...{
      url:
        uri +
        `TaswyaBasicData/lowestCountInSafe?safe=${safe.id}
        &&branch=${-1}`,
      dependencyArray: [safe],
    },
  });

  useEffect(() => {
    console.log("the dta llldfskdfk", data);
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (sfe) => {
    setSafe(sfe);
    setAnchorEl(null);
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <Button
            size="small"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="text"
          >
            {safe?.name} <ArrowDropDownIcon />
          </Button>
        }
        title="المخزون الأقل كمية"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ padding: 10 }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Safes.map((s) => (
          <MenuList
            key={s.id}
            style={{ paddingInline: 10 }}
            onClick={() => handleSelect(s)}
          >
            {s.name}
          </MenuList>
        ))}
      </Menu>
      <Divider />
      <CardContent className={classes.content}>
        <BeatLoader loading={fetching} color={colors.red[900]} />
        {!fetching && <SafesQuantityView data={data ?? []} />}
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text">
          Overview <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};
export default SafesQuantity;
