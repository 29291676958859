import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useStyles } from "./account-index-style";
import "rc-tree-select/assets/index.css";
import TreeSelect from "rc-tree-select";
import rename from "deep-rename-keys";
import {
  FinalAccOptions,
  getFinalOption,
  removeSubAccounts,
} from "../../../../services/accountIndex-services";
import OmlatList from "./account-index-omlats";
import { useTranslation } from "react-i18next";

export default function AccountsIndexForm(props) {
  const { newCode, setNewCode, data, onChange, object, formType, omlat } =
    props;

  const { t } = useTranslation(["forms"]);
  const mCode = formType == "edit" ? object.Code : newCode;
  const clasess = useStyles();
  const mData = rename(removeSubAccounts(data), (key) => {
    if (key === "Code") return "value";
  });

  const onSelect = (selectedKeys, info) => {
    let code = info.props.maxCode;

    if (formType != "edit") setNewCode(parseInt(code) + 1);
    onChange(null, { name: "ParentCode", value: selectedKeys });
  };

  return (
    <Grid item container>
      <Grid
        item
        container
        style={{
          paddingTop: "20px",
          backgroundColor: "#ffffff",
          color: "#000000",
        }}
        spacing={1}
        className={[clasess.upsub, clasess.upsubR]}
      >
        <Grid lg={6} md={6}>
          <Grid item className={clasess.textField} lg={10} md={10}>
            <TextField
              className={clasess.textField}
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="Code"
              label={t("account-code")}
              name="Code"
              value={formType == "edit" ? object.Code : newCode}
              InputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid item className={clasess.textField} lg={10} md={10}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="AName"
              label={t("account-name")}
              name="AName"
              value={object.AName}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                onChange(null, { name: "AName", value: e.target.value });
              }}
            />
          </Grid>

          <Grid item style={{ marginTop: 20 }} lg={10} md={10}>
            <FormControl>
              <FormLabel id="demo-row-radio-tree-group-label">
                {" "}
                {t("account-parent")}{" "}
              </FormLabel>
              <TreeSelect
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                style={{ marginTop: 10, width: 300, direction: "ltr" }}
                transitionName="rc-tree-select-dropdown-slide-up"
                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                dropdownStyle={{
                  direction: "ltr",
                  maxHeight: 200,
                  overflow: "auto",
                  zIndex: 1500,
                }}
                showSearch
                allowClear
                treeLine
                disabled={formType == "edit"}
                value={object.ParentCode}
                treeData={mData}
                treeNodeFilterProp="label"
                filterTreeNode={false}
                onSelect={onSelect}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: 20 }} lg={10} md={10}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                {" "}
                {t("account-type")}
              </FormLabel>
              <RadioGroup
                row
                onChange={(e) => {
                  onChange(null, {
                    name: "Type",
                    value: parseInt(e.target.value),
                  });
                }}
                value={object.Type}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={t("main")}
                />
                <FormControlLabel
                  value={2}
                  disabled={!(mCode.toString().length > 3)}
                  control={<Radio />}
                  label={t("subsidiary")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Box
            component="div"
            sx={{ width: "100%", display: object.Type == 1 ? "none" : "block" }}
          >
            <Grid
              lg={12}
              md={12}
              style={{ marginTop: "40px" }}
              spacing={2}
              item
              container
            >
              <Grid item lg={2} md={2} sm={0} xs={0} container></Grid>
              <Grid item lg={10} md={10} sm={12} xs={12} container>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={clasess.textField}
                >
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label3">
                      {" "}
                      {t("account-nature")}
                    </FormLabel>
                    <RadioGroup
                      row
                      onChange={(e) => {
                        onChange(null, {
                          name: "Nature",
                          value: parseInt(e.target.value),
                        });
                        console.log(object);
                      }}
                      value={object.Nature}
                      aria-labelledby="demo-row-radio-buttons-group-label3"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label={t("dept")}
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label={t("credit")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={clasess.textField}
                >
                  <Autocomplete
                    // className={clasess.textField}
                    value={getFinalOption(object.FinalAcc)}
                    onChange={(event, newValue) => {
                      onChange(null, { name: "FinalAcc", value: newValue.id });
                    }}
                    id="controllable-states-demo4"
                    size="small"
                    options={FinalAccOptions}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("account-year-end")}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item style={{ marginTop: 20 }} lg={12} md={12}>
                  <OmlatList
                    {...{
                      onChange,
                      object,
                      formType,
                      omlat,
                    }}
                  />
                </Grid>
              </Grid>

              {/* <Grid item lg={4} md={4} sm={6} xs={6}>
                            <Autocomplete
                                    className={clasess.textField}
                                //   value={rptParams.branch}
                                //   onChange={(event, newValue) => {
                                //     setRptParams({ ...rptParams, branch: newValue, branchId: newValue.id });
                                //     }}
                                id="controllable-states-demo" size="small"
                                //   options={optionsBranches}
                                // loading={fetchingBranches}
                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="الفرع" variant="outlined" size="small" 
                                />}
                            />
                    </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                            <Autocomplete
                                    // className={clasess.textField}
                                //   value={rptParams.branch}
                                //   onChange={(event, newValue) => {
                                //     setRptParams({ ...rptParams, branch: newValue, branchId: newValue.id });
                                //     }}
                                id="controllable-states-demo" size="small"
                                //   options={optionsBranches}
                                // loading={fetchingBranches}
                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="مركز التكلفة" variant="outlined" size="small" 
                                />}
                            />
                    </Grid> */}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
