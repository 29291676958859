import React, { useEffect ,useState} from "react";
import EReceiptVoucherForm from "./ERV-form";
import {
  Avatar,
  Chip,
  colors,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import ExternalFormSub from "../components/External-form-sub";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { useStylesHed } from "../../../../../St/comps/UseStyle";
import toast from "react-hot-toast";
import useFormDataCRUD from "../../../../../components/Hooks/useEDSCURD";
import EReceiptAPI from "../../../../../api/EReceipt";
import {
  ERVdefaultValues,
  getEndDept,
  ReFormatingData,
} from "../../../../../services/EReceiptVoucher-service";
import axios from "axios";
import { sto } from "../../../../../Context/InshData";
import EReceiptVoucherAppBar from "./EReceiptVoucher-AppBar";
import { getAccountOmlat } from "../../../../../services/omlat-services";
import { useTranslation } from "react-i18next";
import { getLS } from "../../../../../utils/useLS";
import { KeyboardReturnOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  avatar: {
    backgroundColor: colors.grey[600],
    color: "#fff",
    height: 46,
    width: 46,
    marginTop: -5,
  },
  icon: {
    height: 32,
    width: 32,
  },
  card: {
    backgroundColor: colors.grey[100],
    height: "100px",
    color: colors.grey[900],
    // borderColor: colors.grey[700],
    borderWidth: "0px",
    borderStyle: "solid",
    fontSize: "26px",
    // color:colors.green[900]
  },
}));

export default function EReceiptVoucher(props) {
  const { AutoOptions, prmis, fetchURL, isDialog, item, onClose } = props;

  const StockOmlat = getAccountOmlat(
    AutoOptions.Stocks[0].acc_omlat,
    AutoOptions.omlats
  );
  const BankOmlat = getAccountOmlat(
    AutoOptions.Banks[0].acc_omlat,
    AutoOptions.omlats
  );

  const [valueUsers, setValueUsers] = useState([]);
  let locR = getLS("DataRem", {});
  let empId=locR.empi;
 
  function GetEmpFromOptions(id, options) {
    const index = options?.findIndex((op) => op.emp == id);
    return options[index];
  }
  const defaultV = isDialog
    ? ReFormatingData(item, AutoOptions)
    : 
   
    {
        ...ERVdefaultValues,
        id: AutoOptions.other.lastid,
        emp:GetEmpFromOptions(locR.empi, AutoOptions.Users),
        stock: AutoOptions.Stocks[0],
        bank: AutoOptions.Banks[0],
        cust_omlat: [],
        cust_omla: { id: "-1", exchange: 1 },
        stock_omlat: StockOmlat,
        stock_omla: StockOmlat[0],
        bank_omlat: BankOmlat,
        bank_omla: BankOmlat[0],
      };

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
    getRow
  } = useFormDataCRUD(
    EReceiptAPI.create,
    EReceiptAPI.update,
    EReceiptAPI.remove,
    undefined,
    defaultV,
    null,
    () => {
      if (isDialog) onClose(true);
      else fetchURL();
    },
    isDialog ? "edit" : "new",
    EReceiptAPI.GetRow
  );

  // useEffect(()=>{
   
  //   getUserDefault();
  // })

  const { t } = useTranslation(["translation", "forms"]);
  const changAutoCustomers = async (newValue) => {
    let end_dept1 = "0.0";
    await axios
      .get(
        uri +
          `AccountReports/rptABalanceSum?branch=${-1}
                &&costCenter=${-1}
                &&acc_code=${newValue.acc_no.toString()}`
      )
      .then((res) => {
        end_dept1 = res.data.end_Dept - res.data.end_Credit;
      })
      .catch((res) => {
        toast.error("لم يتم جلب مديونية العميل");
        console.log("erro", res.error);
      });
    const accOmlat = getAccountOmlat(newValue.acc_omlat, AutoOptions.omlats);
    let nObject = {
      ...object,
      customer: newValue,
      end_Dept: end_dept1,
      notes: "دفعة من حساب " + newValue.name,
      cust_omlat: accOmlat,
      cust_omla: accOmlat[0],
    };

    onShow(nObject, formType);
  };

  const changAutoStocks = async (newValue) => {
    const accOmlat = getAccountOmlat(newValue.acc_omlat, AutoOptions.omlats);
    let nObject = {
      ...object,
      stock: newValue,
      stock_omlat: accOmlat,
      stock_omla: accOmlat[0],
    };
    onShow(nObject, formType);
  };

  const changAutoBanks = async (newValue) => {
    const accOmlat = getAccountOmlat(newValue.acc_omlat, AutoOptions.omlats);
    let nObject = {
      ...object,
      bank: newValue,
      bank_omlat: accOmlat,
      bank_omla: accOmlat[0],
    };
    onShow(nObject, formType);
  };

  const classes = useStyles();
  const clashd = useStylesHed();

  return (
    <>
      <div className={classes.root} style={{ direction: "rtl" }}>
        <Grid item container className={clashd.rootHd}>
          <Grid item xs={12}>
            <Chip
              size="medium"
              className={clashd.ChipHdAccount}
              style={{ fontSize: "16px" }}
              label={t("translation:ext-receipt")}
              color="primary"
            />
          </Grid>
        </Grid>
        <Typography
          component="div"
          style={{ direction: "rtl", marginTop: "20px" }}
        >
          <Grid container justifyContent="center" spacing={3}>
            <Grid item lg={9} md={8} sm={12}>
              <Container
                component={Paper}
                maxWidth={false}
                style={{
                  padding: "30px",
                  // height:'400px',

                  borderTopColor: colors.grey[700],
                  borderTopWidth: "2px",
                  borderTopStyle: "solid",
                }}
              >
                {/* <SelectTypesFilter {...{rptParams, setRptParams }}/> */}
                <EReceiptVoucherForm
                  {...{
                    ...props,
                    object,
                    formType,
                    changAutoCustomers,
                    changAutoBanks,
                    changAutoStocks,
                    onChange,
                    onShow,
                  }}
                />
                {/* <CurrencySalesProfitForm {...{AutoOptions, rptParams, onChangeData, onChangeAutoData}} /> */}
              </Container>
            </Grid>
            <Grid item lg={3} md={4} sm={12}>
              <TotalCard
                {...{
                  className: classes.card,
                  title: t("forms:account-balance-to-date"),
                  value: getEndDept(object.end_Dept),
                }}
              />
              <br />
              <Container
                component={Paper}
                maxWidth={true}
                style={{
                  padding: "20px",
                  borderBottomWidth: "0px",
                }}
              >
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      style={{ paddingTop: "8px" }}
                      variant="h4"
                      component="h4"
                    >
                      {t("forms:check-data")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.avatar}>
                      <AttachMoneyIcon className={classes.icon} />
                    </Avatar>
                  </Grid>
                </Grid>
                <Divider />
                <br />

                <ExternalFormSub
                  {...{
                    ...props,
                    object,
                    changAutoCustomers,
                    onChange,
                  }}
                />

                <br />
              </Container> 
            </Grid>
          </Grid>
        </Typography>
      </div>
      <EReceiptVoucherAppBar
        {...{
          URL: EReceiptAPI.URL,
          prmis,
          ...props,
          onChange,
          submit,
          update,
          defaultV,
          remove,
          submitting,
          errorMessage,
          onShow,
          onEnd,
          object,
          formType,
          getRow,
          AutoOptions,
        }}
      />
    </>
  );
}
