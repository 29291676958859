import React, { useState, useEffect } from "react";
import { uri } from "../../../../help/Api";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Chip } from "@material-ui/core";
import ReMakeStoreForm from "./ReMakeStore-form";
import SubTaswya from "./taswya-sub/sub-taswya";
import { Toaster } from "react-hot-toast";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import {
  getReFormatingGtys,
  dfaultfilters,
  isEmptyFilters,
} from "../../../../services/taswya-servece";
import Footer from "./footer";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
    direction: "rtl",
  },
  chip: {
    paddingLeft: "10px",
    marginLeft: "10px",
  },
}));

export default function ReMakeStore(props) {
  const clasess = useStyles();
  const { formType, object } = props;
  const [filters, setFilters] = useState(dfaultfilters);

  // const { data, fetching: fetchingGtys } = useFetchURL({
  //   ...{
  //     url:
  //       uri +
  //       `TaswyaBasicData/TaswyaGtys?typeQtys=${filters.filter.id}
  //     &&safe=${filters.safe}
  //     &&branch=${filters.branch}
  //     &&group_id=${filters.group.id}`,
  //     mapFunction: getReFormatingGtys,
  //     dependencyArray: [filters],
  //   },
  // });

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const GetByFilters = async (filter) => {
    setFetching(true);
    await axios
      .get(
        uri +
          `TaswyaBasicData/TaswyaGtys?typeQtys=${filter.filter.id}
    &&safe=${filter.safe}
    &&branch=${filter.branch}
    &&group_id=${filter.group.id}`
      )
      .then((res) => {
        setData(getReFormatingGtys(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
    setFilters(filter);
    setFetching(false);
  };

  let his = useHistory();
  const backHome = () => {
    his.push({ pathname: "/" });
    window.location.reload(false);
  };

  useEffect(() => {
    console.log("the filters is", filters);
    if (formType == "edit") {
      if (isEmptyFilters(filters))
        props.onChange(null, { name: "rows", value: object?.rows });
      else
        props.onChange(null, {
          name: "rows",
          value: [...object?.rows, ...data],
        });
    } else {
      if (isEmptyFilters(filters))
        props.onChange(null, { name: "rows", value: [] });
      else props.onChange(null, { name: "rows", value: data });
    }
  }, [data, filters]);

  return (
    <div className={clasess.main}>
      <Toaster position="top-center" reverseOrder={false} />
      <ReMakeStoreForm
        {...{
          ...props,
          GetByFilters,
          filters,
          setFilters,
        }}
      />

      {filters.all && (
        <Chip
          className={clasess.chip}
          label={"الكل"}
          variant="outlined"
          color="primary"
          onDelete={() => setFilters({ ...filters, all: false })}
        />
      )}
      {filters.group.name != "" && (
        <Chip
          className={clasess.chip}
          label={filters.group.name}
          variant="outlined"
          color="primary"
          onDelete={() =>
            setFilters({ ...filters, group: { id: 0, name: "" } })
          }
        />
      )}
      {filters.filter.name != "" && (
        <Chip
          className={clasess.chip}
          label={filters.filter.name}
          variant="outlined"
          color="primary"
          onDelete={() =>
            setFilters({ ...filters, filter: { id: 0, name: "" } })
          }
        />
      )}
      <SubTaswya {...{ ...props, fetching }} />
      {/* {fetching ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "100%", height: 50, paddingTop: 20 }}>
            <ScaleLoader />
          </Box>
        </Box>
      ) : (
      )} */}

      <Footer {...props} />

      {/* <Typography component="div" direction='ltr'> <Swipeable /> </Typography> */}
    </div>
  );
}
