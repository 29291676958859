import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";

import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import PrintIcon from "@material-ui/icons/Print";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import {
  useStylesContainers,
  useStylesHed,
  useStylesBA,
  useStylesCAB,
} from "../../../../St/comps/UseStyle";
import UserForm from "./user-form";
import UserTable from "./user-table";
import { stElemnt } from "../../../../St/comps/cStyle";
import toast, { Toaster } from "react-hot-toast";
import { insh } from "../../../../Context/InshData";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import API from "../../../../api/UsersCURD";
import * as yup from "yup";

const ValidateSchema = yup.object().shape({
  name: yup.string().required("ادخل اسم المستخدم"),
  password: yup.string().required("ادخل كلمة المرور"),
  safe: yup.string().required("يجب اختيار المخزن الافتراضي"),
  stock: yup.string().required("يجب اختيار الصندوق الافتراضي"),
});
export default function User(props) {
  const { data, fetchURL } = props;

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    API.create,
    API.update,
    API.remove,
    undefined,
    { ...data[0], nPassword: "", nName: data[0].name },
    null,
    fetchURL
  );

  let his = useHistory();

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clasCAB = useStylesCAB();

  const [style, setStyle] = useState({});

  const onSubmitHandle = (data) => {
    console.log("the datals d", data);
    if (data.name != "") {
      let nData = {
        ...data,
        safe: data.safe ? data.safe?.id : "",
        stock: data.stock ? data.stock?.id : "",
        password: data.nPassword,
        name: data.nName,
        userName: data.nName,
        User_type: data.userType,
      };
      ValidateSchema.validate(nData, { abortEarly: false })
        .then(async () => {
          if (formType == "edit") {
            // handlePrint(nData, nData);
            await update(nData).then((res) => {});
          } else {
            toast.success("عملية الاضافة موقفة حتي يتم اضافة واجهة الموظفين");
            // await submit(nData).then((res)=>{
            //   console.log("data data is", res.data);
            //   // if(isPrint)
            //   //     handlePrint(res.data, nData);
            //   // toast.success('تم إضافة السند')
            // });
          }
        })
        .catch((err) => {
          toast.error(err.errors[0]);
        });
      // if(formType=='edit')
      //     update(newData)
      // else
      //     submit(newData)
    } else toast.error("ادخل اسم العملة");
  };

  const backHome = () => {
    his.push({ pathname: "/" }); /*console.log('stooping')*/
  };
  const back = () => his.goBack();

  const styheader = {
    textAlign: "center",
    backgroundColor: "#fff",
    height: "100vh",
    direction: "rtl",
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Container maxWidth="xl">
        <Toaster />
        <Typography component="div" style={styheader}>
          <Grid item container>
            <Grid item xs={12}>
              <Chip
                size="small"
                // className={clashd.ChipHdRet}
                label={" الـمستخـدمين "}
                color="primary"
              />
            </Grid>
            <Grid container xs={12}>
              <Grid
                item
                container
                lg={12}
                md={12}
                className={[clsConts.rootup]}
              >
                <Grid
                  item
                  container
                  spacing={1}
                  xs={12}
                  className={[clsConts.subUp, clsConts.subUpC]}
                >
                  <UserForm
                    {...{
                      onChange,
                      submit,
                      update,
                      remove,
                      submitting,
                      errorMessage,
                      onShow,
                      onEnd,
                      object,
                      formType,
                      data,
                    }}
                  />
                </Grid>
              </Grid>
              <UserTable
                {...{
                  onChange,
                  submit,
                  update,
                  remove,
                  submitting,
                  errorMessage,
                  onShow,
                  onEnd,
                  object,
                  formType,
                  users: props.data,
                }}
              />
            </Grid>
          </Grid>

          <React.Fragment>
            <CssBaseline />
            <AppBar
              position="fixed"
              color="primary"
              style={{
                ...style.bgImg,
                ...stElemnt.all.appBarBA,
                direction: "ltr",
              }}
            >
              <Toolbar className={clasBA.ToolbarBA} variant="dense">
                <Hidden smUp={["sm"]}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => backHome()}
                  >
                    <CloseIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => back()}
                  >
                    <ReplyIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={""}
                  >
                    <PrintIcon />
                  </IconButton>
                </Hidden>

                <Hidden only={["xs"]}>
                  <Button
                    style={{ color: "#fff" }}
                    onClick={() => backHome()}
                    startIcon={<CloseIcon />}
                  >
                    خروج
                  </Button>
                  <Button
                    style={{ color: "#fff" }}
                    onClick={() => back()}
                    startIcon={<ReplyIcon />}
                  >
                    رجوع
                  </Button>
                </Hidden>

                <div className={clasBA.growBA} />

                <Hidden smUp={["sm"]}>
                  {/* ico left  */}
                  <div className={clasCAB.wrapperCAB}>
                    <Fab
                      size="medium"
                      color="primary"
                      onClick={() => onSubmitHandle(object)}
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    >
                      {<SaveIcon />}
                    </Fab>
                    {submitting && (
                      <CircularProgress
                        size={56}
                        className={clasCAB.fabProgressCAB}
                      />
                    )}
                  </div>

                  <div className={clasCAB.wrapperCAB}>
                    <Fab
                      size="medium"
                      color="primary"
                      onClick={() =>
                        onShow(
                          {
                            ...data[0],
                            nPassword: "",
                            UserType: 1,
                            nName: data[0].name,
                          },
                          "new"
                        )
                      }
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    >
                      {<AddIcon />}
                    </Fab>
                    {submitting && (
                      <CircularProgress
                        size={56}
                        className={clasCAB.fabProgressCAB}
                      />
                    )}
                  </div>
                </Hidden>
                {formType == "edit" ? (
                  <div className={clasCAB.wrapperCAB}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<DeleteIcon />}
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      disabled={submitting}
                      onClick={(e) => remove(object.id)}
                    >
                      حـذف
                    </Button>
                    {submitting && (
                      <CircularProgress
                        size={24}
                        className={clasCAB.buttonProgressCAB}
                      />
                    )}
                  </div>
                ) : null}

                <Hidden only={["xs"]}>
                  <div className={clasCAB.wrapperCAB}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      disabled={submitting}
                      onClick={() => onSubmitHandle(object)}
                      startIcon={<SaveIcon />}
                    >
                      {" "}
                      {formType == "edit" ? "حـفـظ التعديلات" : "حـفـظ"}{" "}
                    </Button>
                    {submitting && (
                      <CircularProgress
                        size={24}
                        className={clasCAB.buttonProgressCAB}
                      />
                    )}
                  </div>

                  <div className={clasCAB.wrapperCAB}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      onClick={() =>
                        onShow(
                          {
                            ...data[0],
                            nPassword: "",
                            UserType: 1,
                            nName: data[0].name,
                          },
                          "new"
                        )
                      }
                      startIcon={<AddIcon />}
                    >
                      {" "}
                      {"جـديـد"}{" "}
                    </Button>
                  </div>
                </Hidden>
              </Toolbar>
            </AppBar>
          </React.Fragment>
        </Typography>
      </Container>
    </React.Fragment>
  );
}
