import React, { useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HomeIcon from "@material-ui/icons/Home";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import { useStylesSpDial } from "../../../St/Reviews/UseStyle";

import RGL, { WidthProvider } from "react-grid-layout";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";

const ReactGridLayout = WidthProvider(RGL);
const originalLayout = getFromLS("layout") || [];
/**
 * This layout demonstrates how to sync to localstorage.
 */
class LocalStorageLayout extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    cols: 1,
    rowHeight: 30,
    onLayoutChange: function () {},
  };

  constructor(props) {
    super(props);

    this.state = {
      layout: JSON.parse(JSON.stringify(originalLayout)),
    };

    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.resetLayout = this.resetLayout.bind(this);
  }

  resetLayout() {
    this.setState({
      layout: [],
    });
  }

  onLayoutChange(layout) {
    /*eslint no-console: 0*/
    saveToLS("layout", layout);
    this.setState({ layout });
    this.props.onLayoutChange(layout); // updates status display
  }

  render() {
    return (
      <div>
        <button onClick={this.resetLayout}>Reset Layout</button>
        <ReactGridLayout
          {...this.props}
          layout={this.state.layout}
          onLayoutChange={this.onLayoutChange}
        >
          {/* <div
            key="1"
            style={{ backgroundColor: "#00ff00" }}
            data-grid={{ w: 2, h: 3, x: 0, y: 0 }}
          > */}
            <Header
              locInv={this?.props?.locInv}
              found={this?.props?.found}
              sta={this?.props?.sta}
            />
          {/* </div> */}
          <div
            style={{ backgroundColor: "#00ff00" }}
            key="2"
            data-grid={{ w: 2, h: 3, x: 2, y: 0 }}
          >
            <span className="text">
              <Body data={this?.props?.chunk ?? []} />
            </span>
          </div>
          <div
            style={{ backgroundColor: "#00ff00" }}
            key="3"
            data-grid={{ w: 2, h: 3, x: 4, y: 0 }}
          >
            <span className="text">
              <Footer
                found={this?.props?.found}
                InvForFatora={this?.props?.inv}
                data={this?.props?.sta ?? {}}
              />
            </span>
          </div>
          <div
            key="4"
            style={{ border: "2px" }}
            data-grid={{ w: 2, h: 3, x: 6, y: 0 }}
          >
            <span className="text">4</span>
          </div>
          <div key="5" data-grid={{ w: 2, h: 3, x: 8, y: 0 }}>
            <span className="text">5</span>
          </div>
        </ReactGridLayout>
      </div>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-2")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-2",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}
function BillPageBuilder(props) {
  const { locInv, found, sta, inv, chunk } = props;
  return (
    <LocalStorageLayout
      locInv={locInv}
      found={found}
      sta={sta}
      inv={inv}
      chunk={chunk ?? []}
    />
  );
}

export default BillPageBuilder;
