
import TextField from '@material-ui/core/TextField';

// import {cellS,headerS} from '../st/cStyle'


// let nevEve='always';

// export var columnsInv = [
//   // {title: "id", field: "id", hidden: true},
//   {title: "id", field: "currency_from",headerStyle: headerS,cellStyle: cellS, hidden: true},
//   {title: "رقم الباركود", field: "barcode", editable:'never', headerStyle: headerS,cellStyle: cellS,},
//   {title: "الصنف", field: "symbol", editable:'never', headerStyle: headerS,cellStyle: cellS,},
//   {title: "الوحدة", field: "unitame",  editable:'never', headerStyle: headerS,cellStyle: cellS,},
//   // {title: "الكمية", field: "val1", type: 'numeric', headerStyle: headerS,cellStyle: cellS,},
//   { title: 'الكمية', field: 'val1', headerStyle: headerS,cellStyle: cellS, editComponent: props => (
//       <TextField type="text" variant="outlined" size="small"
//       value={props.value} onChange={e => props.onChange(e.target.value)} /> )},
//   {title: "العبوة", field: "val", editable:'never', headerStyle: headerS,cellStyle: cellS,},
//   {title: "السعر", field: "exchange_price", type: 'numeric',editable:nevEve, headerStyle: headerS,cellStyle: cellS,
//     editComponent: props => (<TextField type="text" variant="outlined" size="small"
//     value={props.value} onChange={e => props.onChange(e.target.value)} /> )},
//   {title: "الإجمالي", field: "totJust", editable:'never',headerStyle: headerS,cellStyle: cellS,},
//   {title: "قيمة الخصم", field: "discount",type: 'numeric',  editable:'never', headerStyle: headerS,cellStyle: cellS,},
//   {title: "الخصم%", field: "DiscPers", type: 'numeric',editable:nevEve, headerStyle: headerS,cellStyle: cellS,
//     editComponent: props => ( <TextField type="text" variant="outlined" size="small"
//     value={props.value} onChange={e => props.onChange(e.target.value)} /> )},
//   {title: "الإجمالي بعد الخصم", field: "totAfterDisc", editable:'never', headerStyle: headerS,cellStyle: cellS,},
//   {title: "القيمة المضافة%", field: "taxperc", editable:'never', headerStyle: headerS,cellStyle: cellS,},
//   {title: "إجمالي القيمة المضافة", field: "taxval", editable:'never', headerStyle: headerS,cellStyle: cellS,},
//   {title: "الصافي", field: "new_exchange_price", editable:'never', headerStyle: headerS,cellStyle: cellS,},
//   {title: "معلومات", field: "", hidden: true,headerStyle: headerS,cellStyle: cellS,
//     editComponent: props => ( <TextField type="text" variant="outlined" size="small"
//     value={props.value} onChange={e => props.onChange(e.target.value)} /> )},
//   {title: "البيان", field: "notes", headerStyle: headerS,cellStyle: cellS,
//     editComponent: props => ( <TextField type="text" variant="outlined" size="small"
//     value={props.value} onChange={e => props.onChange(e.target.value)} /> )},
//   {title: "سعر البيع", field: "sale_price", headerStyle: headerS,cellStyle: cellS,
//     editComponent: props => ( <TextField type="text" variant="outlined" size="small"
//     value={props.value} onChange={e => props.onChange(e.target.value)} /> )
//   }
// ]




var col = {
  Branches:[
    {title: "", field: "id", hidden: true},
    {title: "اسم الفرع", field: "name", },
    {title: "تليفون", field: "Tel", },
    {title: "مومايل", field: "Mobile", },
    {title: "فاكس", field: "Fax", },
    {title: "بريد الكتروني", field: "Email", },
  ],
  Units : [
    {title: "الرقم", field: "id", },
    {title: "الوحدة", field: "name",},
  ],
  items : [
    {title: "الرقم", field: "id", },
    {title: "الصنف", field: "symbol",},
    {title: "الباركود", field: "barcode",},
    {title: "المجموعة", field: "groupName",},
    {title: "الشراء", field: "purch_price",},
    {title: "البيع", field: "sale_price",},
    {title: "الموقع", field: "Location",},
  ],
  groups : [
    {title: "الرقم", field: "id", hidden: true },
    {title: "كود المجموعة", field: "group_id",},
    {title: "مجموعة الصنف", field: "name",},
  ],
  tax_groups : [
    // {title: "الرقم", field: "id", },
    // {title: "رقم الصنف", field: "nameAR", },
    // {title: "الصنف", field: "id", },
    {title: "الرقم", field: "id", },
    {title: "اسم الضريبة", field: "nameAR", },
    {title: "نسبة الضريبة", field: "value", },
  ],
  Safes : [
    {title: "", field: "id", hidden: true },
    {title: "", field: "branch", hidden: true },
    {title: "اسم المخزن", field: "name", },
    {title: "الفرع", field: "branchName", },
  ],
  Stocks : [
    {title: "", field: "id", hidden: true },
    {title: "", field: "branch", hidden: true },
    {title: "اسم الصندوق", field: "name", },
    {title: "الفرع", field: "branchName", },
  ],
  Banks : [
    {title: "", field: "id", hidden: true},
    {title: "اسم البنك", field: "name", },
    {title: "الدولة", field: "CountryName", },
    {title: "المدينة", field: "CityName", },
    {title: "المنظقة", field: "AreaName", },
    {title: "تليفون", field: "tel", },
    {title: "موبايل", field: "mobile", },
    {title: "رقم الحساب", field: "acc_no", },
  ],
  costcenter : [
    {title: "الرقم", field: "id", },
    {title: "مركز التكلفة", field: "Name",},
  ],

  omlat : [
    {title: "الرقم", field: "id", },
    {title: "اسم العملة", field: "name", },
    {title: "الاسم لاتيني", field: "NameEn", },
    {title: "رمز العمله", field: "symbol", },
    {title: "سعر التحويل", field: "exchange", },
    {title: "الحالة", field: "IS_Active", },
    {title: "العمله الافتراضية", field: "is_main", },
    //{title: "العمله المحلية", field: "IS_Default", },
    {title: "الدوله", field: "Country", },
    {title: "الفكه عربي", field: "FakaName", },
    {title: "الفكه لاتيني", field: "FakaNameEn", },
    {title: "كل 1 =", field: "oneEqualto", },
    {title: "المكافئ", field: "TotalFaka", },
    {title: "الوصف", field: "omla_desc", },
  ],
  AccountBooks : [
    {title: "الرقم", field: "ID", },
    {title: "اسم الدفتر", field: "Name",},
  ],
  Acts : [
    {title: "", field: "id", hidden: true},
    {title: "اسم المجموعة", field: "name", },
    {title: "نوع المجموعة", field: "nameType", },
    {title: "", field: "type", hidden: true},
  ],
  Countries : [
    {title: "الرقم", field: "id", },
    {title: "اسم الدولة", field: "name", },
    // {title: "الجنسية", field: "name",},
  ],
  Cities : [
    {title: "الرقم", field: "id", },
    {title: "اسم المدينة", field: "name", },
    // {title: "اسم المدينة", field: "country",},
    // {title: "اسم المدينة", field: "country",},
  ],
  Areas : [
    {title: "الرقم", field: "id", },
    {title: "اسم المنطقة", field: "name", },
    // {title: "اسم المدينة", field: "city",},
    // {title: "اسم المنطقة", field: "nameA",},
  ],
  Managements : [
    {title: "الرقم", field: "id", hidden: true },
    {title: "إسم الإدارة", field: "name",},
  ],
  Departments : [
    {title: "الرقم", field: "id", hidden: true },
    {title: "إسم القسم", field: "name",},
    {title: "إسم الإدارة التابع لها القسم", field: "manag_id",},
  ],
  jobs : [
    {title: "الرقم", field: "id", hidden: true },
    {title: "الوظيفة", field: "name",},
  ],
  CardTypes : [
    {title: "الرقم", field: "id", hidden: true },
    {title: "اسم الفيزا", field: "nameA",},
  ],
} 

  export {col};