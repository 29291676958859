import React, { useEffect } from 'react';
// import axios from 'axios';
import {Rept}  from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import {useRptAF} from '../../../../St/Reviews/UseStyle';
import MaterialTable from "material-table";
import {stElemnt} from '../../../../St/Reviews/cStyle';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

function BodyA() {
  const cls = useRptAF();
  const location = useLocation();

useEffect(() => { /*getInfo(); */ }, []);


return (
<Grid item container className={cls.rootBdy}>


      <Grid item container className={[cls.title,cls.titleS]}>

      <Grid item   xs={12}>
      <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1,fontSize:17}}> <b>  ضريبة القيمة المضافة للمبيعات</b></Typography>
      </Grid>
      <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b>  المبيعات</b></Typography>
        </Grid>
        <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b>  المردودات</b></Typography>
        </Grid>
        <Grid item   xs={3}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b> ض القيمة المضافة  </b></Typography>
        </Grid>
        <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b> التسوية الضريبية</b></Typography>
        </Grid>
        <Grid item   xs={3}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b>  الضريبة المستحقة</b></Typography>
        </Grid>



        <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b> {location.state.SaleVal.toFixed(2)}</b>
        </Typography>
        </Grid>
        <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b>  {location.state.RT_SaleeVal.toFixed(2)}</b></Typography>
        </Grid>
        <Grid item   xs={3}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b> {location.state.SaleTax.toFixed(2)} </b></Typography>
        </Grid>
        <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b>  {location.state.RT_SaleTax.toFixed(2)}</b></Typography>
        </Grid>
        <Grid item   xs={3}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b>   {(location.state.SaleTax - location.state.RT_SaleTax).toFixed(2)}</b></Typography>
        </Grid>

      </Grid>


      <Grid item container className={[cls.title,cls.titleS]}>

      <Grid item   xs={12}>
      <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1,fontSize:17}}> <b>  ضريبة القيمة المضافة للمشتريات</b></Typography>
      </Grid>
      <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b>  المشتريات</b></Typography>
        </Grid>
        <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b>  المردودات</b></Typography>
        </Grid>
        <Grid item   xs={3}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b> ض القيمة المضافة  </b></Typography>
        </Grid>
        <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b> التسوية الضريبية</b></Typography>
        </Grid>
        <Grid item   xs={3}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b>  الضريبة المستحقة</b></Typography>
        </Grid>



        <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b>  {location.state.PurchVal.toFixed(2)}</b></Typography>
        </Grid>
        <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b>  {location.state.RT_PurchVal.toFixed(2)}</b></Typography>
        </Grid>
        <Grid item   xs={3}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b> {location.state.PurchTax.toFixed(2)} </b></Typography>
        </Grid>
        <Grid item   xs={2}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}>
        <b>  {location.state.RT_PurchTax.toFixed(2)}</b></Typography>
        </Grid>
        <Grid item   xs={3}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b>   {(location.state.PurchTax - location.state.RT_PurchTax).toFixed(2)}</b></Typography>
        </Grid>

      </Grid>
   
      <Grid item container className={[cls.title,cls.titleS]}>

      <Grid item   xs={12}>
      <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1,fontSize:17}}> <b>  تفاصيل الضريبة </b></Typography>
      </Grid>
      <Grid item   xs={4}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b>  ضريبة المبيعات</b></Typography>
        </Grid>

        <Grid item   xs={4}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b>   ضريبة المشتريات  </b></Typography>
        </Grid>

        <Grid item   xs={4}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> <b>  الضريبة المستحقة</b></Typography>
        </Grid>



        <Grid item   xs={4}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b> {( location.state.SaleTax - location.state.RT_SaleTax).toFixed(2)}</b></Typography>
        </Grid>

        <Grid item   xs={4}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b> {( location.state.PurchTax - location.state.RT_PurchTax ).toFixed(2)}</b></Typography>
        </Grid>

  
        <Grid item   xs={4}>
        <Typography      style={{textAlign:'center',backgroundColor:'Silver',color:'blue',paddingTop:5,paddingBottom:5,margin:1}}> 
        <b>  {( (location.state.SaleTax+ location.state.RT_PurchTax) - (location.state.PurchTax + location.state.RT_SaleTax)).toFixed(2)}</b></Typography>
        </Grid>

      </Grid>

</Grid>
   );

  }

export default BodyA;
