import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, LinearProgress, makeStyles, Paper, TableContainer, Typography } from '@material-ui/core';
import PaymentTransactionRPTForm from './paymentTransactionRPT-form';
import { useHistory } from 'react-router-dom';

import ScannerIcon from '@material-ui/icons/Scanner';
import KeyboardReturnSharpIcon from '@material-ui/icons/KeyboardReturnSharp';
import PrintIcon from '@material-ui/icons/Print';
import { useStylesSpDial, useStylesUiElemment } from '../../../St/Reports/UseStyle';
import moment from 'moment';
import { insh as inshst } from '../../../Context/InshSt';
import { SAdefaultValues } from './paymentTransactionRPT-services';

import PaymentTransactionRPTTable from './paymentTransactionRPT-table';
import { URL } from '../../../api/customer';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import { TableChart } from '@material-ui/icons';
import { CSVLink } from 'react-csv';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#faf1f1',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },

  headerTitle: {
    backgroundColor: '#ffffff',
    textAlign: 'center',
    fontSize: 20,
    width: '100%'
  },

  blCard: {
    backgroundColor: '#fff',
    borderColor: '#81007f'
  },

}));


export default function PaymentTransactionRPT(props) {
  const { AutoOptions } = props;
  const [rptParams, setRptParams] = React.useState({ ...SAdefaultValues, account: AutoOptions?.Accindex[0], });
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const fetchDataClick = async () => {
    setFetching(true);
    const accCode = rptParams.account?.id;
    let type = rptParams.type?.id;
    if (type == 0 && accCode != -1) {
      if (accCode.toString().toLowerCase().startsWith("123"))
        type = 1;
      else type = 2;
    }
    await axios.get(URL + `/lastPay?type=${type}
        &&branch=${rptParams.branch.id}
        &&omla=${rptParams.omla?.id}
        &&dateFrom=${rptParams.dateFrom}
        &&dateTo=${rptParams.dateTo}
        &&acc_code=${rptParams.account?.id}`).then(res => {
      setData(res.data);
      console.log("the data is", res.data)
    }).catch(err => {
      console.log(err);
      setFetching(false);
    });
    setFetching(false);
  }
  const [style, setStyle] = useState(inshst.custm);
  useEffect(() => {
    console.log(" the balance ", data)
  }, [])
  const his = useHistory();
  const toBack = () => { his.push({ pathname: '/', }); };
  const printHandle = () => {
    const info = {
      data: data?.AccountBalance,
      tot: data?.totals,
      from: 'الحساب',
      title: 'كشف  ',
      auto: {},
      account: rptParams.account,
      fDate: rptParams.dateFrom, tDate: rptParams.dateTo,
      nDate: moment(new Date()).format('YYYY-MM-DD'),
      ElmntShow: true,
      //totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
    }
    localStorage.setItem('iInfos', JSON.stringify(info));
    his.push({ pathname: '/ReviewAccount', });
    window.location.reload(false);
  }
  let type_cust = rptParams.type?.id;
  let label_name = "";
  let excel_name = "";

  if (type_cust == 0) {
    label_name = "العميل/المورد";
    excel_name = "paymentTransactionRPT"
  } else if (type_cust == 1) {
    label_name = "اسم العميل";
    excel_name = "CustomersPaymentTransactionRPT"
  } else {
    label_name = "اسم المورد";
    excel_name = "SuppliersPaymentTransactionRPT"
  }
  const excelHeaders = [
    { label: "رقم الحساب", key: "acc_no" },
    { label: label_name, key: "name" },
    { label: "الهاتف", key: "tel" },
    { label: "الجوال", key: "mobile" },
    { label: "قيمة اخر سداد ", key: "AmtVale" },
    { label: "تاريخ اخر سداد ", key: "Doc_date" },
    { label: "نوع المستند ", key: "docName" },
    { label: "الرصيد", key: "Blance" },
    { label: "الحالة", key: "CaseBlance" },
  ];

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  }
  return (
    <div
      className={classes.root}>
      <Grid container item xs={12}>
        <Typography size="small" className={classes.headerTitle} >
          حركة اخر سداد للعملاء / الموردين
        </Typography>
      </Grid>
      <Toaster />
      <Typography component="div" style={{ direction: 'rtl' }} >
        <Container component={Paper} maxWidth={false}
          style={{
            padding: '10px',
            paddingBottom: '0px',
            borderBottomColor: inshst.colors.cust,
            borderBottomWidth: '10px',
            borderBottomStyle: 'solid'
          }}
        >

          <PaymentTransactionRPTForm {...{ AutoOptions, rptParams, setRptParams, onChangeData, onChangeAutoData, printHandle }} />
          <Grid container justifyContent="center" lg={12} md={12} sm={12} xs={12} spacing={1} >
            <Grid item >
              <Button disabled={fetching} variant="contained"

                startIcon={<ScannerIcon />} size="large"
                style={{ ...style.iconBtn, borderRadius: 0 }}
                className={[clasui.button,]}
                onClick={fetchDataClick}>عـــرض النتيجة </Button>
            </Grid>
            <Grid item >
              {data && !fetching && data && (
                <CSVLink
                  separator={";"}
                  filename={excel_name}
                  data={data}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    size="large"
                    style={{ ...style.iconBtn, borderRadius: 0 }}
                    className={[clasui.button]}
                  >
                    {"تصدير اكسل"}{" "}
                  </Button>
                </CSVLink>
              )}
            </Grid>
            <Grid item >
              <Button variant="contained" startIcon={<PrintIcon />} color="primary" size="large"
                className={[clasui.button, clasSp.clSec]}
                style={{ ...style.iconBtn, borderRadius: 0 }}
                disabled={true}
                onClick={printHandle}> طباعة</Button>
            </Grid>
            <Grid item >
              <Button variant="contained" startIcon={<KeyboardReturnSharpIcon />} color="secondary" size="large"
                className={[clasui.button, clasSp.clcan]}
                style={{ ...style.iconBtn, borderRadius: 0 }}
                onClick={toBack}>خروج</Button>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth={true} style={{ padding: '5px' }}>
          {/* <Grid container spacing={0} justifyContent="center" style={{paddingTop:'10px'}}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    <BalanceCard {...{title:'تـسديد', 
                      dept: data?.totals?.totBlncDept,
                      className: classes.blCard,
                      credit: data?.totals?.totBlncCredit,
                    }}/>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    <BalanceCard {...{title:'الدين المتبقي', 
                      className: classes.blCard,
                      dept: data?.totals?.totDept,
                      credit: data?.totals?.totCredit,
                    }}/>
                  </Grid>
                  
                </Grid> */}
        </Container>
        <Container component={Paper} maxWidth={false}
          style={{
            padding: '10px',
            // paddingTop:'5px', 
          }}
        >
          <Grid container >
            {
              fetching ? <TableContainer component={Paper} >
                {'يرجى الانتظار قليلا حتى يتم جلب البيانات ...'}
                <LinearProgress />

              </TableContainer>
                :
                <PaymentTransactionRPTTable {...{ data: data ? data : [], fetchDataClick, title: label_name }} />
            }


          </Grid>
        </Container>


      </Typography>

    </div>
  );




};