import axios from "axios";
import { uri } from "../help/Api";

export const RefreshBillSettings = async () => {
  await axios
    .get(uri + `SettInvsApp`)
    .then((res) => {
      let data = {
        id: 1,
        dsplyColmDiscount: res.data.dsplyColmDiscount,
        dsplyColmExclVAT: res.data.dsplyColmExclVAT,
        dsplyColmSerialnumb: res.data.dsplyColmSerialnumb,
        dsplyHeadFootr: res.data.dsplyHeadFootr,
        dsplyHedrSalesOrUser: res.data.dsplyHedrSalesOrUser,
        dsplyHedrdir: res.data.dsplyHedrdir,
        colmNoA: res.data.colmNoA,
        colmProductDescA: res.data.colmProductDescA,
        colmUnitA: res.data.colmUnitA,
        colmQtyA: res.data.colmQtyA,
        colmUnitPriceA: res.data.colmUnitPriceA,
        colmAmountA: res.data.colmAmountA,
        colmDiscountA: res.data.colmDiscountA,
        colmValueExclVatA: res.data.colmValueExclVatA,
        colmVatValueA: res.data.colmVatValueA,
        colmValueIncludeVatA: res.data.colmValueIncludeVatA,
        colmNoE: res.data.colmNoE,
        colmProductDescE: res.data.colmProductDescE,
        colmUnitE: res.data.colmUnitE,
        colmQtyE: res.data.colmQtyE,
        colmUnitPriceE: res.data.colmUnitPriceE,
        colmAmountE: res.data.colmAmountE,
        colmDiscountE: res.data.colmDiscountE,
        colmValueExclVatE: res.data.colmValueExclVatE,
        colmVatValueE: res.data.colmVatValueE,
        colmValueIncludeVatE: res.data.colmValueIncludeVatE,
        // tot
        sumcolmTax: res.data.sumcolmTax,
        sumcolmDiscount: res.data.sumcolmDiscount,
        sumcolmNetAmount: res.data.sumcolmNetAmount,
        sumcolmTotal: res.data.sumcolmTotal,
        sasInvHeadr: res.data.sasInvHeadr,
        sasInvFootr: res.data.sasInvFootr,
        amountWithVatA: res.data.amountWithVatA,
        amountWithVatE: res.data.amountWithVatE,
        totdiscountA: res.data.totdiscountA,
        totdiscountE: res.data.totdiscountE,
        amountWithVat2A: res.data.amountWithVat2A,
        amountWithVat2E: res.data.amountWithVat2E,
        amountOfVatA: res.data.amountOfVatA,
        amountOfVatE: res.data.amountOfVatE,
        tot_netA: res.data.tot_netA,
        tot_netE: res.data.tot_netE,
        showColDHd: res.data.showColDHd,
        ncol: res.data.ncol,
        titleFatoraA: res.data.titleFatoraA,
        titleFatoraE: res.data.titleFatoraE,
      };

      localStorage.setItem("billSTGs", JSON.stringify(res.data));
      return data;
    })
    .catch((error) => {});
};

export const GetA4Setings = () => {
  const setting = JSON.parse(localStorage.getItem("billSTGs") ?? null);
  const colmProductDescE = JSON.parse(setting?.colmProductDescE ?? null);
  const unit = JSON.parse(setting?.colmUnitE ?? null);
  const price = JSON.parse(setting?.colmUnitPriceE ?? null);
  const qty = JSON.parse(setting?.colmQtyE ?? null);
  const discount = JSON.parse(setting?.colmDiscountE ?? null);
  const tax = JSON.parse(setting?.colmVatValueE ?? null);
  const colors = JSON.parse(setting?.colors ?? null);
  const cloudInvoiceSettings = JSON.parse(
    setting?.cloudInvoiceSettings ?? null
  );
  // console.log("the settings is", colmProductDescE);
  let columns = {
    colmNo: {
      nameAr: setting?.colmNoA,
      nameEn: setting?.colmNoE,
      isVisible: true,
    },
    barcode: {
      nameAr: "كود",
      nameEn: "code",
      fieldName: "barcode",
      isVisible: setting?.dsplyColmSerialnumb ?? false,
    },
    colmProductDesc: {
      nameAr: setting?.colmProductDescA,
      nameEn: colmProductDescE?.text ?? "Item name",
      fieldName: colmProductDescE?.fieldName ?? "symbol",
      isVisible: colmProductDescE?.visible ?? true,
    },
    colmUnit: {
      nameAr: setting?.colmUnitA,
      nameEn: unit?.text ?? "Unit ",
      fieldName: unit?.fieldName ?? "unit_name",
      isVisible: unit?.visible ?? true,
    },
    colmQty: {
      nameAr: setting?.colmQtyA,
      nameEn: qty?.text ?? "qty",
      fieldName: qty?.fieldName ?? "qty",
      isVisible: qty?.visible ?? true,
    },
    colmUnitPrice: {
      nameAr: setting?.colmUnitPriceA,
      nameEn: price?.text ?? "price",
      fieldName: price?.fieldName ?? "unit_price",
      isVisible: price?.visible ?? true,
    },
    colmDiscount: {
      nameAr: setting?.colmDiscountA,
      nameEn: discount?.text ?? "Disc",
      fieldName: discount?.fieldName ?? "discount",
      isVisible: discount?.visible ?? true,
    },
    colmTax: {
      nameAr: "الضريبة",
      nameEn: tax?.text ?? "Tax",
      fieldName: tax?.fieldName,
      isVisible: tax?.visible ?? true,
    },
  };
  let headerSettings = {
    isVisible: !setting?.dsplyHeadFootr,
    height: setting?.sasInvHeadr,
  };
  return {
    columns,
    headerSettings,
    watermark: {
      text: cloudInvoiceSettings?.watermarkText,
      opacity: cloudInvoiceSettings?.watermarkOpacity,
    },
    backgroundColor: colors?.backGroundColor,
  };
};

export const FieldsTableNameA4Invoice = [
  "barcode",
  "symbol",
  "discount",
  "new_exchange_price",
  "new_taxperc",
  "new_taxval",
  "exchange_price",
  "expense",
  "id",
  "item_lenght",
  "item_thickness",
  "item_width",
  "minus",
  "new_val",
  "new_val1",
  "notes",
  "tab3val",
  "tab3val1",
  "tax",
  "tax_additional_address_no_cust",
  "tax_additional_address_no_found",
  "taxperc",
  "taxval",
  "totBill",
  "tot_NewBill",
  "tot_net",
  "tot_net_curr",
  "tot_purch",
  "tot_sale",
  "trans_closed",
  "unit_name",
  "unit",
  "user_name",
  "val",
  "val1",
  "vat_type",
  "vat_type_name",
];

export const titleOptions = [
  { id: 1, label: "إعدادات عامة" },
  // { id: 2, label: "فاتورة A4" },
  // { id: 3, label: "القوالب" },
  { id: 4, label: "إعداد أعمدة الجدول في الفاتورة الكبير" },
];

export const defalutPrintOPS = [
  { id: 1, label: "Orax Print" },
  // { id: 2, label: "" },
];

export const opacities = [
  "0.1",
  "0.2",
  "0.3",
  "0.4",
  "0.5",
  "0.6",
  "0.7",
  "0.8",
  "0.9",
  "1",
];
