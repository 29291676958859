
import React, {useState,useEffect} from 'react';
// import  Swipeable  from '../Main/Swipeable';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CardActionArea from '@material-ui/core/CardActionArea';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { useHistory, useLocation } from 'react-router-dom';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShopIcon from '@material-ui/icons/Shop';
import GroupIcon from '@material-ui/icons/Group';
import RateReviewIcon from '@material-ui/icons/RateReview';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';


import {elIcon} from '../../Context/Local';

import Image from '../../assets/img/oraxsoft.png';
// cuG
import {useStyleDC,useStylesSpDial} from '../Menues/st/UseStyle';
// csG
import {style} from '../Menues/st/cStyle'
import { useTranslation } from 'react-i18next';


const styles = {
  paperContainer: {
      backgroundImage: `url(${Image})`
  }
};


export default function DeptCards() {
  const cls = useStyleDC();
  const {t} = useTranslation();

  const clasSp = useStylesSpDial();
  let his = useHistory();
  const loca = useLocation();

  
  const [state, setState] = useState({
    local:{grid:[{tab:[]}],tabs:[],style:{}},
  });

  const Icon = [
    {icon:LocalMallIcon},
    {icon:ShoppingBasketIcon},
    {icon:ShopIcon},
    {icon:GroupIcon},
    {icon:RateReviewIcon},
    {icon:PlaylistAddCheckIcon},
    {icon:PeopleAltIcon},
    {icon:SettingsApplicationsIcon},
  ]

  const [ElIcon, setElIcon] = useState();
  const [value, setValue] = useState(0);
  const tabChange = (event, newValue) => setValue(newValue); 

  const back = () => his.replace({pathname:'/'});
  


  const choisIco = (off) => {
    // alert(off)

    setElIcon(Icon[off].icon)
    
  }

  const ContLeft_ = () => {
    return(
      <Grid container item>
      <Hidden smDown={['sm']}>
        <Grid xs={12} item>
        <Button variant="contained" color="secondary" size="large" onClick={back}
       className={cls.button} startIcon={<CloseIcon />}>{t('close')}</Button>
        </Grid>
      </Hidden>

        <Grid lg={12} md={12} sm={6} xs={12} item>
        <Card className={cls.rootCaedL}>
          <CardMedia className={cls.logo} style={styles.paperContainer} >
          </CardMedia>
        </Card>
        </Grid>

        <Grid lg={12} md={12} sm={6} xs={12} item>
        <Card className={cls.rootCaedL}>
            <CardMedia className={cls.mediaL} title="">

            <ElIcon className={cls.iconCardL} style={state.local.style.color}/>

            </CardMedia>
            <CardContent className={cls.contentL} style={state.local.style.color}>
              <Typography variant="h5">{t(state.local.leftitle)} </Typography>
            </CardContent>
          </Card>
        </Grid>
        
      </Grid>
    )}

    


  const loadhis = async () => 
  {
    // alert('DeptCards')
    setState(loca.state)
    // console.log('loca.state'); console.log(loca.state);
    choisIco(loca.state.local.of);
  }
  
  useEffect(() => { loadhis(); }, []);

  
  const goScreen = async (path,insh) => 
  {
  console.log('----insh data -----')
  console.log(insh)
  console.log('----path is-----')
  console.log(path)
     his.push({
     pathname: `/${path}`,
     state:insh
  })
}


  const header_ = () => { return (<Typography className={cls.title} variant="h4"
  style={state.local.style.color}
  >
    {t('dept-screen-title')+" " +t(state.local.title)}</Typography>)}

  const tabs_ = () => {
    return(
      <Paper className={cls.paper}>
      <Tabs value={value} onChange={tabChange} variant="scrollable" 
            className={cls.tabs}
           indicatorColor="primary" textColor="primary" >
        { state.local.tabs.map(item =>
           (<Tab label={t('page')+' '+item.nmbr} className={cls.tab} disabled={item.disb} />))}
      </Tabs>
    </Paper>
    )
  }

  const Cards_ = () =>{
    return(
      <Grid item container 
      className={cls.rootCard}
      >
        <Grid item container 
      style={{...state.local.style.bgImg,justifyContent: 'center',}}
          // style={{backgroundImage: 'linear-gradient(160deg, #5fb562 , #319835)'}}
          // #94d197
          // className={cls.subCard}
          // style={{backgroundImage: 'linear-gradient(160deg, #f0597c, #b84193)'}}
          // style={{backgroundImage: 'linear-gradient(160deg, #d06161, #f0597c)'}}
          >
        
        {  state.local.grid[value].tab.map((item,i) => (
          <Grid item lg={4} md={4} sm={4} xs={6} 
            // style={{background:'green'}}
          
          >
            <Card //className={cls.rootCaedR} 
            style={
              // item.disb ? {background:'#fff0'} : state.local.style.bgDis
              // ,
              state.local.style.stGrid
            } 
            // style={ {background:'#fff0' } 

            // style={{background:'green'}}
            // style={state.local.style.stGrid}
            >
              {/* <p style={{color:'#fff'}}>{!item.disb+''}</p> */}
            <CardActionArea className={cls.area} disabled={!item.disb} 
            onClick={()=>goScreen(item.path,item.insh)}
              // style={{background:'orange'}}
              // style={{color:'#fff'}}
              

            >
              <CardMedia className={cls.media} title="" //style={style.color.whi}
              >
                {/* <BallotIcon className={cls.iconCard}/> */}
                <ListAltIcon className={cls.iconCard}/>
                {/* <WebIcon className={cls.iconCard}/> */}
              </CardMedia>
            <CardContent className={cls.content} //style={{background:'#fff0'}}
            
            >
              <Typography variant="h6" className={cls.text}>{t(item.name)} </Typography>
            </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        ))
      }

      </Grid>
    </Grid>
    )
  }
  
const FlottingBtn_ = () => {
    return( <div className={clasSp.root}>
  <SpeedDial ariaLabel="SpeedDial openIcon example" 
    className={clasSp.speedDial} hidden={false}
    icon={<SpeedDialIcon onClick={back} />}   
     open={true} startIcon={<CloseIcon />} >
  </SpeedDial>
</div> ) }


return (
    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="xl" className={cls.container}>
    <Typography component="div" style={{ backgroundColor: '#fff', height: '98vh' , border:'solid  green'}}>

      <Grid container item>
      <Hidden smDown={['sm']}>
        <Grid item lg={3} md={3} className={cls.rootContL} style={{borderColor:'green'}}>
         <ContLeft_ /> 
        </Grid>
      </Hidden>
      <Grid item lg={9} md={9} sm={12} xs={12}>
      <Grid item xs={12} className={cls.rootHeadr}>{header_()}</Grid> 
      <Grid item xs={12} className={cls.tabs}>{tabs_()}</Grid>
       
      <Grid item container >
       {/* <Grid item lg={2} md={4} sm={4} xs={4}> */}
         <Cards_ />
         {/* </Grid> */}
      </Grid>
      </Grid>
      {/* <Hidden mdUp={['md']}>
      <Grid item sm={6} xs={3}> {ContLeft_()} </Grid>
      </Hidden> */}
      </Grid>

      <Hidden mdUp={['md']}> <FlottingBtn_ /> </Hidden>
    </Typography>
    </Container>

    <Typography component="div" 
        style={{ marginTop:'2vh', backgroundColor: '#f6f7f9', fontFamily:'Tajawal' }}>
       {/* <Swipeable/> */}
     </Typography>

  </React.Fragment>
  );
}

