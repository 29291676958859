import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../services/LoadingPage";
import VatReports from "./VatReports";
import { useHistory, useLocation } from "react-router-dom";
const defaultAutos = {
  Safes: [],
};

export default function VatReportSalesCon() {
  const locat = useLocation();
  const isPurches=locat.state.systemId==0?true:false;
  const tb = {
    wait: true,
    Safes: true,
    Branches: true,
    Users: true,
    costcenters: true,
    stor: sto,
    parms: {
      safe: -1,
    },
  };
  const t = locat.state.systemId==0?2:1;
  const url = uri + "Auto" + `/custm?t=${t}`;
  const { data: CustomerOptions, fetching: fetchCust } = useFetchURL({
    ...{ url, dependencyArray: [] },
  });
  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
  );

  if (
    fetchCust ||
    fetchAll ||
    !AutoOptions?.Safes ||
    CustomerOptions?.length == 0

  )
    return <LoadingPage />;

  return (
    <VatReports
      {...{
        AutoOptions: AutoOptions?.Safes ? AutoOptions : defaultAutos,
        CustomerOptions: CustomerOptions?.length > 0 ? CustomerOptions : [],
        isPurches,
      }}
    />
  );
}
