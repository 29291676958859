import React,{useEffect, useState} from 'react';
import axios from 'axios';
import {uri}  from '../../../help/Api';
import {sto} from '../../../Context/InshData';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import dataF from '../../../Server/dataF.json';
import Typography from '@material-ui/core/Typography';


// cuG
import {useStyleI} from '../st/UseStyle';
// csG

let nl=true;
export default function Icons() {
  const classes = useStyleI();
  const timer = React.useRef();

  const [riv, setRiv] = useState({Rw:nl});
  const [rivR, setRivR] = useState({Rw:nl});
  
  useEffect( async() => {
    // alert(uri+`UserPermission?id=${sto.idi}&&f=${dataF[46].id}`)
      await axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[46].id}`).then(res => {
        setRiv({...riv, Rw:!res.data.IS_Preview});
      }).catch(error => {  }) 
      await axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[48].id}`).then(res => {
        setRivR({...rivR, Rw:!res.data.IS_Preview});
      }).catch(error => { })
  }, []);

  
  return (

<Card className={classes.root}>
<Grid container className={classes.container} spacing={1}>




</Grid> 


    </Card>





  );
}


    // <Card className={classes.root} >
    //   <CardActionArea className={classes.root}>

    //     <CardContent className={classes.content}>
    //       {/* <Typography component="h5" variant="h5">
    //       {/* <Typography variant="subtitle1" color="textSecondary"> */}
    //       مرتجع المبيعات
    //       {/* </Typography> */}
    //     </CardContent>
    //   <CardMedia
    //     className={classes.cover}
    //     title=""
    //   >
    //      <LocalMallIcon style={iconSal} />
    //   </CardMedia>
    //   </CardActionArea>
      
    // </Card>





