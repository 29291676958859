import React,{useEffect, useState} from 'react';
import BackdropSc from '../../../services/BackdropSc';
import axios from 'axios';
import {insh,sto,permiCuCo,premiScr} from '../../../Context/InshData';
import {insh as inshst} from '../../../Context/InshSt';
import {txtA} from '../../../Label/Lbl';
import {uri}  from '../../../help/Api';
import Card from '@material-ui/core/Card';
import {getLS}  from '../../../utils/useLS';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import Grid from '@material-ui/core/Grid';
import dataF from '../../../Server/dataF.json';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
// cuG
import {useStyleI} from '../st/UseStyle';
// csG
import {style} from '../st/cStyle'


export default function Icons() {
  const classes = useStyleI();
  let his = useHistory();
  const timer = React.useRef();

  const [prmiScr, setPermiScr] = useState(insh.prmiScr);
  const [showSc, setShowSc] = useState(true);


  // const inshRiv={Rw:false};
  // const [riv, setRiv] = useState(inshRiv);
  // const getUPrmi = async () => {
  //   const arr = [{v:false}];
  //   let i=0,m=49;
  //       await axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[m].id}`).then(r => {
  //         if(r.data.user_id !== null) arr[i].v=false; else arr[i].v=true;
  //       }).catch(error => { })

  //        setRiv({Rw:arr[0].v});

  //       /* costWithVat */
  //       axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[3].id}`).then(res => {
  //         if(res.data.costWithVat===1) { setPermiScr({...prmiScr, CWVat:res.data.costWithVat, CWVtxt:'السعر يشمل الضريبه',  }); }
  //         else if(res.data.costWithVat===0){ setPermiScr({...prmiScr, CWVat:res.data.costWithVat, CWVtxt:'السعر لا يشمل الضريبه', }); }
  //       }).catch(error => { });
  // }

  // const getUPrmi = async () => 
  // {
  //   const tb={PermissionsScreen:true, wait:true,
  //     parms:{
  //       iuserid:sto.idi, iformidI:dataF[49].id,
  //     },
  //     autos:{},
  //   }
  //   console.log(tb)
  //   await axios.post(uri+'Res',tb).then(res => {
  //     setPermiScr(res.data.permisScr);
  //     setShowSc(res.data.wait.state)
  //   }).catch(error => { })
  // }

  const getUPrmi = () => {
    const loc = getLS('premiScr',{});
    setPermiScr(loc);
  }

  const loader = () => 
  { timer.current = window.setTimeout(() => { setShowSc(false); }, 600); }
  useEffect( () => {
    loader();
    getUPrmi();
    return () => { clearTimeout(timer.current); };
  }, []);


    const clickInv = (val) => {

        his.push({
          pathname: '/Invoces',
          // search: '?update=true',  /*query string*/
          
            state: {idata:insh.IdataInvs, stor:{invsType:4 ,},
            setting:{ titleHed:txtA.invName.safe,cusType:2,cusN:txtA.cusNM,
            priceNM:txtA.namePricePurch,priceTotNM:txtA.nameTotPurch, 
            PrTy_InPr:insh.IdataSf.ProcType_InvProc,
            iformid:dataF[49].id,
            routI_R_RS:'Inv_R_RS_ToSf',routIS:'Inv_Sub_ToSf/',/*routR:'',routRS:'',*/ },
            style:inshst.safe
          },
        })
      
  
      }; // E sa

  return (
    showSc?<BackdropSc/>:
  <Card className={classes.root}>
  <Grid container className={classes.container} spacing={1}>

  <Grid item lg={12} md={12} sm={12} xs={12} >
  <CardActionArea disabled={!prmiScr.safe}  onClick={() => clickInv(true)} 
  className={classes.action} style={style.backColor.safe}>

  {/* <div>{riv.Rw+''}</div> */}
  <CardContent className={classes.content} >
    {/* <Typography component="h5" variant="h5"> */}
    {/* <Typography variant="subtitle1" color="textSecondary">  */}
    <Typography>مخزون أول المدة</Typography>
    {/* </Typography>  */}
  </CardContent>
  <CardMedia
  className={classes.cover}
  title=""
  >
  <ListAltIcon className={classes.icon} />
  </CardMedia>
  </CardActionArea>
  </Grid>

  <Grid item lg={12} md={12} sm={12} xs={12} >
  <CardActionArea disabled={!prmiScr.safJrd}
  onClick={() => clickInv(false)}
  className={classes.action} style={style.backColor.safe}>

  <CardContent className={classes.content}>
    <Typography>جــرد الــمــخــزن</Typography>
  </CardContent>
  <CardMedia
  className={classes.cover}
  title=""
  >
  <ViewHeadlineIcon className={classes.icon} />
  </CardMedia>
  </CardActionArea>
  </Grid> 


  {/* <Grid item lg={6} md={12} sm={12} xs={6}>
  <CardActionArea className={classes.action}>

  <CardContent className={classes.content}>
    -------------
  </CardContent>
  <CardMedia
  className={classes.cover}
  title=""
  >
  <ListAltIcon className={classes.icon} />
  </CardMedia>
  </CardActionArea>
  </Grid>

  <Grid item lg={6} md={12} sm={12} xs={6}>
  <CardActionArea className={classes.action}>

  <CardContent className={classes.content}>
  ---------------
  </CardContent>
  <CardMedia
  className={classes.cover}
  title=""
  >
  <LowPriorityIcon className={classes.icon} />
  </CardMedia>
  </CardActionArea>
  </Grid>*/}

  </Grid> 


  </Card>


  );
}


    // <Card className={classes.root} >
    //   <CardActionArea className={classes.root}>

    //     <CardContent className={classes.content}>
    //       {/* <Typography component="h5" variant="h5">
    //       {/* <Typography variant="subtitle1" color="textSecondary"> */}
    //       مرتجع المبيعات
    //       {/* </Typography> */}
    //     </CardContent>
    //   <CardMedia
    //     className={classes.cover}
    //     title=""
    //   >
    //      <LocalMallIcon style={iconSafes} />
    //   </CardMedia>
    //   </CardActionArea>
      
    // </Card>





