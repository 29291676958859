import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import { sto } from "../../../Context/InshData";
import { uri } from "../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { useLocation } from "react-router-dom";
import DeptPeriod from "./DeptPeriod";
import LoadingPage from "../../../services/LoadingPage";

const defaultAutos = {
    Branches: [],
};

export default function DeptPeriodConn() {
  const tb = {
    wait: true,
    Branches:true,
    AccountsIndex:true,
    ActsCust:true,
    ActsSupp:true,
    Customers:true,
    Suppliers:true,
    stor:sto,
    parms: {
       branch: sto.branchi ,
    },
  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
  );

  if (
    fetchAll ||
    !AutoOptions?.Branches 

  )
    return <LoadingPage />;

  return (
    <DeptPeriod
      {...{
        AutoOptions: AutoOptions?.Branches ? AutoOptions : defaultAutos,
      }}
    />
  );
}
