// import React, { useState, useRef } from "react";
// import ReactToPrint from "react-to-print";
// import { useHistory } from "react-router-dom";
// import Typography from "@material-ui/core/Typography";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import Grid from "@material-ui/core/Grid";
// import SpeedDial from "@material-ui/lab/SpeedDial";
// import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
// import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
// import PrintIcon from "@material-ui/icons/Print";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import HomeIcon from "@material-ui/icons/Home";
// import Header from "./Header";
// import Body from "./Body";
// import Footer from "./Footer";
// import { useStylesSpDial } from "../../../St/Reviews/UseStyle";
// import ShareIcon from "@material-ui/icons/Share";
// import ArrowDownward from "@material-ui/icons/ArrowDownward";

// import jsPDF from "jspdf";
// import { callAddFont } from "../../amiri-font";

// class ComponentToPrint extends React.Component {
//   state = {
//     pwca: true,
//   };

//   constructor() {
//     super();
//   }

//   render() {
//     const locInv = JSON.parse(localStorage.getItem("premiApp")) || {};

//     const styleCont = {
//       // backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
//       backgroundColor: "#fff",
//     };
//     const styleTyg = {
//       backgroundColor: "#fff",
//       color: "#000",
//       padding: "0.2mm",
//     };

//     return (
//       <Grid item container justifyContent="center" style={styleCont}>
//         <Grid
//           item
//           style={{
//             width: `${locInv.cachirWidth}mm`,
//             backgroundColor: "#fff",
//           }}
//         >
//           <CssBaseline />
//           <Typography component="div" style={styleTyg}>
//             {/* <Header /> */}
//             <Body />
//             <Footer />
//           </Typography>
//         </Grid>
//       </Grid>
//     );
//   }
// }

// const ResturantCasher = () => {
//   const componentRef = useRef();
//   const reportDownloadRef = useRef(null);
//   const [loading, setLoading] = useState(false);
//   const clasSp = useStylesSpDial();
//   const [open, setOpen] = useState(true);
//   const handleOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(true);
//   };

//   let his = useHistory();

//   const back = (path) => {
//     path ? his.push({ pathname: "/" }) : his.goBack();
//   };

//   const shareData = async () => {
//     jsPDF.API.events.push(["addFonts", callAddFont]);
//     setLoading(true);
//     var doc = new jsPDF("l", "pt", "letter");
//     doc.setFont("Amiri-Regular", "normal");
//     let mfile;
//     await doc.html(reportDownloadRef.current, {
//       async callback(doc) {
//         var blob = await doc.output("blob");
//         mfile = new File([blob], "docc.pdf", { type: blob.type });
//       },
//     });

//     if (navigator.share && navigator.canShare({ files: [mfile] })) {
//       await navigator.share({
//         title: "hello to orax app",
//         text: "welcome this is text",
//         url: "orax.app/",
//         files: [mfile],
//       });
//     } else {
//       alert("cant share files");
//     }
//     setLoading(false);
//   };

//   const handleGeneratePdf = () => {
//     jsPDF.API.events.push(["addFonts", callAddFont]);
//     var doc = new jsPDF("l", "pt", "letter");
//     doc.setFont("Amiri-Regular", "normal");
//     doc.html(reportDownloadRef.current, {
//       async callback(doc) {
//         setLoading(true);
//         await doc.save("documen`t");
//         setLoading(false);
//       },
//     });
//   };

//   const actions = [
//     {
//       icon: (
//         <ReactToPrint
//           trigger={() => <PrintIcon className={clasSp.ico} />}
//           content={() => componentRef.current}
//         />
//       ),
//       name: "طباعـة",
//     },
//     {
//       icon: (
//         <ArrowDownward className={clasSp.ico} onClick={handleGeneratePdf} />
//       ),
//       name: "تنزيل",
//     },
//     {
//       icon: <ShareIcon className={clasSp.ico} onClick={shareData} />,
//       name: "مشاركة",
//     },
//     {
//       icon: (
//         <ArrowForwardIcon className={clasSp.ico} onClick={() => back(false)} />
//       ),
//       name: "رجـوع",
//     },
//   ];
//   return (
//     <div
//       ref={reportDownloadRef}
//       className={clasSp.root}
//       dir={"ltr"}
//       lang={"ar"}
//       style={{
//         fontFamily: "Amiri-Regular",
//         fontStyle: "normal",
//         letterSpacing: "normal",
//       }}
//     >
//       <ComponentToPrint ref={componentRef} />
//       <SpeedDial
//         ariaLabel="SpeedDial openIcon example"
//         className={clasSp.speedDial}
//         hidden={false}
//         icon={
//           <SpeedDialIcon openIcon={<HomeIcon />} onClick={() => back(true)} />
//         }
//         onClose={handleClose}
//         onOpen={handleOpen}
//         open={open}
//       >
//         {actions.map((action, i) => (
//           <SpeedDialAction
//             key={i}
//             disabled={loading}
//             icon={action.icon}
//             tooltipTitle={action.name}
//             onClick={handleClose}
//           />
//         ))}
//       </SpeedDial>
//     </div>
//   );
// };

// export default ResturantCasher;



import React, { useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HomeIcon from "@material-ui/icons/Home";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import { useStylesSpDial } from "../../../St/Reviews/UseStyle";
import ShareIcon from "@material-ui/icons/Share";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import jsPDF from "jspdf";
import { callAddFont } from "../../amiri-font";
import axios from "axios";
import { uri } from "../../../help/Api";
import moment from "moment";
import { sto } from "../../../Context/InshData";

class ComponentToPrint extends React.Component {
  state = {
    pwca: true,
  };

  constructor() {
    super();
  }

  render() {
    const locInv = JSON.parse(localStorage.getItem("premiApp")) || {};

    const styleCont = {
      // backgroundImage: 'linear-gradient(60deg, #4e9e84 , #2688ba)',
      backgroundColor: "#fff",
    };
    const styleTyg = {
      backgroundColor: "#fff",
      color: "#000",
      padding: "0.2mm",
    };

    return (
      <Grid item container justifyContent="center" style={styleCont}>
        <Grid
          item
          style={{
            width: `${locInv.cachirWidth}mm`,
            backgroundColor: "#fff",
          }}
        >
          <CssBaseline />
          <Typography component="div" style={styleTyg}>
            {/* <Header /> */}
            <Body />
            <Footer />
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const ResturantCasher = () => {
  const componentRef = useRef();
  const reportDownloadRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const clasSp = useStylesSpDial();
  const [open, setOpen] = useState(true);
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};
  const permApp = JSON.parse(localStorage.getItem("premiApp")) || {};
  
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(true);
  };

  let his = useHistory();

  const back = (path) => {
    path ? his.push({ pathname: "/" }) : his.goBack();
  };

  const shareData = async () => {
    jsPDF.API.events.push(["addFonts", callAddFont]);
    setLoading(true);
    var doc = new jsPDF("l", "pt", "letter");
    doc.setFont("Amiri-Regular", "normal");
    let mfile;
    await doc.html(reportDownloadRef.current, {
      async callback(doc) {
        var blob = await doc.output("blob");
        mfile = new File([blob], "docc.pdf", { type: blob.type });
      },
    });

    if (navigator.share && navigator.canShare({ files: [mfile] })) {
      await navigator.share({
        title: "hello to orax app",
        text: "welcome this is text",
        url: "orax.app/",
        files: [mfile],
      });
    } else {
      alert("cant share files");
    }
    setLoading(false);
  };

  const handleGeneratePdf = () => {
    jsPDF.API.events.push(["addFonts", callAddFont]);
    var doc = new jsPDF("l", "pt", "letter");
    doc.setFont("Amiri-Regular", "normal");
    
    doc.html(reportDownloadRef.current, {
      async callback(doc) {
        setLoading(true);
        await doc.save("documen`t");
        setLoading(false);
      },
    });
  };

  const createHeaders = (keys)=>{
    var result = [];
    for (var i=0; i< keys.length; i+=1){
      result.push({
        id:keys[i],
        name:keys[i],
        prompt: keys[i],
        width: 65,
        align: "center",
        padding: 0
      })
    }
    return result;
  }

  const handleGeneratePdfA = async() => {
    jsPDF.API.events.push(["addFonts", callAddFont]);
    var doc = new jsPDF();
    doc.setFont("Amiri-Regular", "normal");
    doc.setFontSize(10);
    var y = 0;

    // let tb = { GetFoundation: true, parms: { iFoundid: 1 }, autos: {} };
    // await axios
    // .post(`${uri}Res`, tb)
    // .then(async (res) => {
    //   const info =res.data.Found;
    //   const imgData = `data:image/jpeg;base64,${info.Logo}`;
    //   doc.addImage(imgData,"JPEG",75,y,50,35); y+=40;
    //   doc.text(info.nameE, 105, y,null,null, "center");y+=5;
    //   doc.text("Phone No:"+info.Mobile, 105, y, null,null, "center");y+=5;
    //   doc.text("VAT# " + info.tax_no, 105, y, null,null, "center");y+=5;
     
    //   // doc.print();
    // })
    // .catch((error) => {});
    const tb = {
      Rpt_Inv: true,
      GetFoundation: true,
      SettInvsApp: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
        iFoundid: 1,
        branch: sto.branchi
      },
      autos: {},
    };
    //  console.log('Rpt__tb'); console.log(tb)
    //  alert(`${uri}Res`)
    await axios
      .post(`${uri}Res`, tb)
      .then(async (res) => {
        // console.log('res.data.RptInv'); console.log(res.data);
        const info = res.data.RptInv;
        const  tot = res.data.infoBill;
        const  StngIA = res.data.SettInvsApp;
        const foundInfo =res.data.Found;
        const imgData = `data:image/jpeg;base64,${foundInfo.Logo}`;
        doc.addImage(imgData,"JPEG",80,y,50,35); y+=35;
        doc.text(foundInfo.nameE, 105, y,null,null, "center");y+=5;
        doc.text("Phone No:"+foundInfo.Mobile, 105, y, null,null, "center");y+=5;
        doc.text("VAT# " + foundInfo.tax_no, 105, y, null,null, "center");y+=5;

        doc.text(locInv.invN +
          " " +
          tot.inv_type_name +
          " " +
          tot.pay_type_name, 105, y, null,null, "center");y+=7;
        doc.setFontSize(14);
        doc.text(tot.id.toString(), 105, y, null, null,"center");y+=7;
        doc.setFontSize(10);
        doc.text(moment(tot.date).format("h:mm A YYYY/MM/DD").toString(), 150, y, null, null, "right");y+=3;
        doc.line(60,y, 150, y);
        const headers = createHeaders([
          "id",
          "symbol",
          "val1",
          "unit_name",
          "new_exchange_price",
          "tot_net_curr"
        ])
        info?.map((item, i) => {
          y+=7;
          doc.text(item.tot_net_curr.toString(),150, y,null, null, "right");
          doc.text(item.val1.toString()+" * "+ item.nameEN.toString(), 60, y);
          y+=4;
          doc.text(item.symbol,60, y);
          // y+=2;
          // doc.line(60, y, 150, y);
      });
      y+=5;
      doc.line(60, y, 150, y);
      doc.setFontSize(12);
      var subTotal = (tot.tot_net - tot.amountOfVat + tot.discount).toFixed(2);
      y+=5;
      doc.text(subTotal.toString(),150, y,null, null, "right");
      doc.text("Sub Total: المجموع", 60, y);
      var amVat = Number(tot.amountOfVat).toFixed(2);
      y+=6;
      doc.text(amVat.toString(),150, y,null, null, "right");
      doc.text("VAT  ضريبة القيمة المضافة", 60, y);
      y+=5;
      doc.line(60, y, 150, y);
      y+=6;
      doc.text(tot.tot_net.toString(),150, y,null, null, "right");
      doc.text("Orand Total الإجمالي", 60, y);
      y+=10;
      doc.setFontSize(10);
      doc.text(foundInfo.Address.toString(),105, y,null, null, "center");
        
      })
      .catch((error) => {});

      await axios.get(uri + `InvForFatora?proc_id=${locInv.proc_id}`)
          .then(async (res) => {
            y+=5;
            const imgF = `data:image/jpeg;base64,${res.data.qr_image}`;
            doc.addImage(imgF,"JPEG",80,y,50,50);
            await doc.autoPrint();
            await doc.save("restaurant invoice");

          })
          .catch((error) => {});
    // doc.html(reportDownloadRef.current, {
    //   async callback(doc) {
    //     setLoading(true);
    //     await doc.save("documen`t");
    //     setLoading(false);
    //   },
    // });
  };

  const actions = [
    {
      icon: (
        <ReactToPrint
          trigger={() => <PrintIcon className={clasSp.ico} />}
          content={() => componentRef.current}
        />
      ),
      name: "طباعـة",
    },
    {
      icon: (
        <ArrowDownward className={clasSp.ico} onClick={handleGeneratePdfA} />
      ),
      name: "تنزيل",
    },
    {
      icon: <ShareIcon className={clasSp.ico} onClick={shareData} />,
      name: "مشاركة",
    },
    {
      icon: (
        <ArrowForwardIcon className={clasSp.ico} onClick={() => back(false)} />
      ),
      name: "رجـوع",
    },
  ];
  return (
    <div
      ref={reportDownloadRef}
      className={clasSp.root}
      dir={"ltr"}
      lang={"ar"}
      style={{
        fontFamily: "Amiri-Regular",
        fontStyle: "normal",
        letterSpacing: "normal",
      }}
    >
      <ComponentToPrint ref={componentRef} />
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial}
        hidden={false}
        icon={
          <SpeedDialIcon openIcon={<HomeIcon />} onClick={() => back(true)} />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action, i) => (
          <SpeedDialAction
            key={i}
            disabled={loading}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default ResturantCasher;
