import React, { useState, useEffect } from "react";

import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Slide,
  Typography,
} from "@material-ui/core";

import { uri } from "../../../../help/Api";
import TotalCard from "../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesBA,
  useStylesCAB,
  useStylesContainers,
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import { stElemnt } from "../../../../St/comps/cStyle";

import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";

import clsx from "clsx";
import { insh as inshst } from "../../../../Context/InshSt";

import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { insh, sto } from "../../../../Context/InshData";

import deepRenameKeys from "deep-rename-keys";
import SafesTranferHead from "./SafesTransferHead";
import SafesTransfersForm from "./SafesTransfersForm";
import SafesTransferTables from "./SafesTransferTable";
import toast from "react-hot-toast";
import { selectTransferType } from "../../../../services/saleAnalysis-services";
import SafesTransferFilter from "./SafesTransferFilter";
import { perc } from "react-grid-layout/build/utils";
import SalesTransferBar from "./SafesTransferBar";
import { useRptAF } from "../../../../St/Reviews/UseStyle";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  purchTotalCard: {
    backgroundColor: colors.blue[50],
    borderColor: colors.blue[300],
  },
}));

export default function SafesTransfer(props) {
  const { AutoOptions, isEstelam } = props;

  const typeTransfer = [
    { id: 0, name: "كميات فقك" },
    { id: 1, name: "سعر الشراء" },
    { id: 2, name: "سعر البيع" },
  ];
  const defaultTransvalue = {
    transfer_id: '',
    no: '',
    create_branch: sto.branchi,
    ref_no: '',
    safe_from: AutoOptions.Safes[0].id,
    refno: '',
    branch: sto.branchi,
    date: moment(new Date()).format('YYYY-MM-DD'),
    time: moment(new Date()).format('HH:mm'),
    center: -1,
    notes: '',
    type: 1,
    typeCost: typeTransfer[0].id,
    estlam: selectTransferType[0].id,
  };
  const transsubvalue = {
    transfer_id: '',
    id: '',
    symbol: '',
    barcode: '',
    perc: 1,
    sub_branch: sto.branchi,
    sub_center: -1,
    user_emp: sto.empi,
    transfered_val: null,
    val1: 0,
    value: 0,
    price: 0,
    total: sto.branchi,
    unitName: '',
  };
  const defaultvalueAutos = {
    safe_from: { id: AutoOptions.Safes[0].id, name: AutoOptions.Safes[0].name },
    safe_to: { id: -1, name: '' },
    branch: { id: AutoOptions.Branches[0].id, name: AutoOptions.Branches[0].name },
    center: { id: -1, name: 'الكل' },

    typeCost: typeTransfer[0],
  };
  const defaultvalueAutosSub = {
    curr: AutoOptions.items[0],
    // units:{unit :-1 ,unitName:"الوحدة", perc:1, sale:0, purch:0},
    units: { unit: -1, unitName: "الوحدة", perc: 1, sale: 0, purch: 0 },
  };
  const codevalues = {
    id: 0,
    code: '',
  };
  const [rptParams, setRptParams] = React.useState({
    ...defaultTransvalue, no: AutoOptions.other.lastid,
    branch: AutoOptions.Branches[0].id, safe_from: AutoOptions.Safes[0].id
  });
  const [valueAutos, setValueAutos] = useState(defaultvalueAutos);
  const [valueAutosSub, setValueAutosSub] = useState(defaultvalueAutosSub);
  const [unitsOptions, setUnitsOptions] = useState([]);

  const [paramsSubList, setParamsSubList] = useState([]);
  const [selectItems, setSelectItems] = useState(true);



  const [rptParamsSub, setRptParamsSub] = useState(transsubvalue);
  const [rptCode, setRptCode] = useState(codevalues);


  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [sum, setSum] = useState(false);

  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const clasCAB = useStylesCAB();

  const buttonClassnameNew = clsx({ [clasCAB.buttonSuccessCAB]: success.new });
  const buttonClassnameSave = clsx({
    [clasCAB.buttonSuccessCAB]: success.save,
  });
  const buttonClassnameDelete = clsx({
    [clasCAB.buttonSuccessCAB]: success.delete,
  });

  const his = useHistory();


  //   const printHandle = () => {
  //     const info = {
  //       columns: columns,
  //       // // rout:viewPrms,
  //       title: "كشف جرد المخازن بالوحدات",
  //       data: data?.gardUnits,
  //      safe:rptParams.safe?.name,
  //      branch:rptParams.branch?.name,
  //      group:rptParams.group?.name,
  //       tot:data?.totals,
  //       nDate: moment(new Date()).format("YYYY-MM-DD"),
  //       ElmntShow: false,
  //     };
  //     //console.log(info)
  //     localStorage.setItem("iInfos", JSON.stringify(info));
  //     his.push({ pathname: "/ReviewJardUnits" });
  //     window.location.reload(false);
  //   };


  const [uiElement, setUiElement] = useState({});



  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const onChangeBarcodeSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    getBarcodeMulti(rptParamsSub.barcode);
  };
  const getBarcodeMulti = async (barcode) => {

    let item = null;
    let unitF = null;

    if (barcode != null) {
      await axios
        .get(
          uri +
          `RptSafes/CurrCodesUnitsSub?currCode=${barcode}
        &&emp=${sto.empi}`
        )
        .then((res) => {

          item = res.data?.barcodes[0];
          unitF = res.data?.unitsSub[0];

          setUnitsOptions(res.data?.unitsSub);
        })
        .catch((err) => {
          toast.error("لا يوجد صنف لهذا الباركود");
          console.log(err);
        });
      if (item != null) {
        setSelectItems(false);
        await barcodeadd(item, unitF);
      } else {
        toast.error("لا يوجد صنف لهذا الباركود");
      }
    }

  };
  const barcodeadd = (dataCode, unitF) => {

    let percc = dataCode.perc;

    setRptParamsSub({
      ...rptParamsSub,
      val1: 1,
      id: dataCode.curr,
      barcode: dataCode.barcode,
      symbol: dataCode.symbol,
      balance: 1,
      perc: dataCode.perc,
      price: rptParams.typeCost.id == 1 ? parseFloat(dataCode.purch) : parseFloat(dataCode.sale),
      total: ((percc * 1)).toFixed(2),
      value: ((dataCode.perc * 1)).toFixed(2),
      unit: dataCode.unit,
      unitName: dataCode.unitName,
    });
    let res = { unitsSub: {}, itemSub: {} };


    unitsOptions.map((u) => {
      if (u.unit == dataCode.unit) res.unitsSub = u;
    });

    AutoOptions.items.map((x) => {
      if (x.id == dataCode.curr) res.itemSub = x;
    });

    setValueAutosSub({
      curr: res.itemSub,
      units: unitF,
    });


  };

  const onSubmit = (e) => {
    e.preventDefault();

    var oldData1 = paramsSubList.filter((obj) => obj.barcode == rptParamsSub.barcode && obj.unit == rptParamsSub.unit);
    console.log("the oldadata is", oldData1);


    if (oldData1.length != 0) {

      var oldData = paramsSubList.filter((obj) => obj.barcode == rptParamsSub.barcode && obj.unit == rptParamsSub.unit);

      let val1 = (parseFloat(oldData[0].val1) + parseFloat(1)).toFixed(2);
      let value = (parseFloat(oldData[0].value) + parseFloat(rptParamsSub.value)).toFixed(2);

      let tot = (parseFloat(oldData[0].total) + parseFloat(rptParamsSub.total)).toFixed(2);



      const dataUpdate = paramsSubList.filter((obj) => obj.barcode != rptParamsSub.barcode || obj.unit != rptParamsSub.unit);
      const musadata = [...dataUpdate, {
        ...oldData[0], val1: val1, value: value,
        total: tot,
      }];
      setParamsSubList([...musadata]);
      setSelectItems(true);
      setRptParamsSub(transsubvalue);
      //  handSums(musadata); 
    } else {

      setParamsSubList([...paramsSubList, rptParamsSub]);
      setSelectItems(true);
      setRptParamsSub(transsubvalue);
    }

  };

  const onChangeDataSub = (propName) => (event) => {
    setRptParamsSub({ ...rptParamsSub, [propName]: event.target.value });
  };

   const onChangeDataSubQ = (propName) => (event) => {
    setRptParamsSub({ ...rptParamsSub, [propName]: event.target.value,value: event.target.value });
  };

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setValueAutos({ ...valueAutos, [propName]: newValue });
    setRptParams({ ...rptParams, [propName]: newValue.id });
  };
  const onChangeAutoDataSubunit = (propName, newValue) => {
    setValueAutosSub({ ...valueAutosSub, [propName]: newValue });
    setRptParamsSub({ ...rptParamsSub, unit: newValue.unit, perc: newValue.perc, price: rptParams.typeCost.id == 1 ? newValue.sale : newValue.purch });

  };

  const onChangeAutoDataSubCurr = (propName, newValue) => {
    setValueAutosSub({ ...valueAutosSub, [propName]: newValue });
    setRptParamsSub({ ...rptParamsSub, id: newValue.id, symbol: newValue.name, barcode: newValue.barcode });
    getBarcodeMulti(newValue.barcode);
  };

  const onChangeSymbol = async (newValue) => {
    setValueAutosSub({ ...valueAutosSub, curr: newValue });
    setRptParamsSub({ ...rptParamsSub, id: newValue.id, symbol: newValue.name, barcode: newValue.barcode });
    await changPayment(newValue.barcode);
    // getBarcodeMulti();
  };
  const changPayment = (barcode) => () => {
    setRptCode({ ...rptCode, code: barcode });

  };

  const backHome = () => {
    his.push({ pathname: "/" });
  };
  const back = () => {
    his.goBack();

  };
  const cls = useRptAF();


  const [prevent, setPrevent] = useState(insh.prevents);

  /* s PMD */
  const [openPMD, setOpenPMD] = useState(false);
  const [barcodeDialogOpen, setBarcodeDialogOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openMulti, setOpenMulti] = useState(false);

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const [transition, setTransition] = React.useState(undefined);

  const [showPic, setShowPic] = useState(false);
  const ButtonLoading = (prop) => setLoading({ ...loading, [prop]: true });
  const ButtonWitting = (prop) => {
    reloadGets(prop);

  };
  const handNewItem = () => {
    reloadGets();
    //const defaultTG = AutoOptions.tax_groups?.filter((t) => t.isDefault == true);


    setParamsSubList([]);
    setRptParamsSub(transsubvalue);
    unitsOptions([]);
    setSelectItems(false);
    setValueAutosSub(defaultvalueAutosSub);
    setValueAutos(defaultvalueAutos);
  };
  const timer = React.useRef();

  const ButtonSuccess = (prop, timr) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });
      if (prop != "save") handNewItem();
      setPrevent(true);
    }, timr);
  };

  const handCloseDw = async () => {
    newToI(1);
    await setOpenPMD(false);
  };
  const newToI = (timr) => {
    ButtonLoading("new");
    ButtonSuccess("new", timr);
  };
  const [setting, setSetting] = useState({
    screen: "",
    tb: { parms: {}, autos: {} },
  });

  const handOpenDw = (Transition) => {
    setTransition(() => Transition);
    setOpenPMD(true);
  };

  /*
  const addToBS = () => {
    console.log("thre group is ", items);
    let action = will();
    if (action.txt == "") {
      ButtonLoading("save");
      let item_Comps= dataList?.map(x=>{
        console.log("thre group is ", x);
        return {item_id: items?.id, 
          mat_id: x?.item?.id,
          mat_unit: x?.unit?.id,
          mat_quan: x?.quan,
          price: x?.price,
          mat_quantot: x?.quan_tot,
        }
      })
    const currData = {
      itemData: items,
      subBarcodes: multiCodeList,
      subUnits: currUnitList,
      item_Comps: item_Comps,
    };
      console.log("the curre data is ", currData);

      prevent
        ? axios
          .post(uri + "Currencies", currData)
          .then((res) => {
            if (res.data) {
              alert("رقم الباردكود موجود مسبقا");
              ButtonSuccess("save", 500);
            } else {
              usto(items);
              ButtonWitting("save");
              setCurrUnitList([]);
              setMultiCodeList([]);
              ButtonSuccess("save", 1000);
              handNewItem();

            }
          })
          .catch((res) => {
            alert("تم إضافة الصنف هذا مسبقا");
            ButtonSuccess("save", 1000);

          })
        :
        axios
          .put(uri + "Currencies", currData)
          .then((res) => {
            ButtonWitting("save");
            setCurrUnitList([]);
            ButtonSuccess("save", 1000);
            handNewItem();
          })
          .catch((res) => {
            alert("لم يتم  تعديل هذا الصنف");
            ButtonSuccess("save", 1000);
            handNewItem();
          });
    } else {
      alert(action.msg);
    }
  };
*/
  const reloadGets = async () => {
    const tb = {
      wait: true,
      other: true,
      // Items: true,
      parms: {
        iempid: sto.empi,
        tname: "Transfer",
        // takei: 1,
      },
    };

    await axios
      .post(uri + "GetD", tb)
      .then((res) => {

        setRptParams({
          ...defaultTransvalue,
          no: res.data.other.lastid,
          branch: AutoOptions.Branches[0].id,
          safe_from: AutoOptions.Safes[0].id,

        });

      })
      .catch((error) => {
      });
  };

  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {"أمر تحويل مخزني"}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: colors.blue[50],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <SafesTransferFilter {...{ rptParams, setRptParams }} />

          <SafesTranferHead
            {...{
              AutoOptions,
              rptParams,
              onChangeData,
              onChangeAutoData,
              valueAutos,
              typeTransfer,
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >

          </Grid>
        </Container>
          {/* <br /> */}
        <Container maxWidth={false} style={{ paddingLeft: "10px" ,paddingRight:"10px",paddingTop:"15px"}}
        >
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}>
            <SafesTransfersForm
              {...{
                AutoOptions,
                rptParamsSub,
                onChangeDataSub,
                onChangeAutoDataSubunit,
                valueAutosSub,
                selectItems,
                unitsOptions,
                onChangeBarcodeSearch,
                onChangeAutoDataSubCurr,
                onSubmit,
                onChangeDataSubQ
              }}
            />

          </Grid>
        </Container>
        {/* <br /> */}
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.blue[50]} />
              {!fetching && (
                <SafesTransferTables
                  {...{
                    paramsSubList,
                    rptParams,
                    valueAutosSub,
                    setValueAutosSub,
                    unitsOptions,

                  }}
                />
              )}
            </Grid>
            {paramsSubList.length>0&&(
                         <Grid
                         container
                         lg={12}
                         md={12}
                         className={cls.rootBdydown}
                         style={{ paddingBottom: 5, backgroundColor: colors.blue[50], fontFamily: "Amiri-Regular" }}
                       >
                         <Grid container xs={12} /*className={cls.rootchip}*/ style={{
                           paddingBottom: 5,
                           backgroundColor: colors.blue[50], fontFamily: "Amiri-Regular",
                           border: "solid 1px",
                           padding: "0.3rem 0.1rem 0.3rem 0.1rem",
                           borderRadius: "0px 0px 5px 0px",
                           marginTop:"1rem"
                         }}>
           
                           <Grid item xs={2} className={[cls.chipVMU]}>
                             {"إجمالي عدد الأصناف"}
                           </Grid>
                           <Grid item xs={2} className={[cls.chipVMU]}>
                             {paramsSubList.length}
                           </Grid>
                           <Grid item xs={2} className={[cls.chipVMU]}>
                             {"إجمالي الكميات"}
                           </Grid>
                           <Grid item xs={2} className={[cls.chipVMU]}>
                             {paramsSubList
                         .reduce((a, v) => (a = a + parseFloat(v.val1)), 0)
                         .toFixed(2)}
                           </Grid>
                           <Grid item xs={2} className={[cls.chipVMU]}>
                             {"إجمالي المبلغ"}
                           </Grid>
                           <Grid item xs={2} className={[cls.chipVMU]}>
                             {paramsSubList
                         .reduce((a, v) => (a = a + parseFloat(v.total) ), 0)
                         .toFixed(2)}
                           </Grid>
                         </Grid>
           
                       </Grid>)}
          </Grid>

        </Container>
      </Typography>

      <SalesTransferBar  {...{
        rptParams,
        setBarcodeDialogOpen,
        buttonClassnameNew,
        buttonClassnameSave,
        buttonClassnameDelete,
        clasCAB,
        newToI,
        setting,
        stElemnt,
        loading,
        clasBA,
        backHome,
        back,
        uiElement,
        handOpenDw,
        TransitionUp,
      }} />
    </div>
  );
}
