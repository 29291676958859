import moment from "moment/moment"
import * as yup from 'yup';
import { date } from "yup/lib/locale";
import { sto } from "../Context/InshData";

export const taswyaValidateSchema = yup.object().shape({
  acc: yup.string().required("قم بتحديد الحساب"),
  safe: yup.string().required("قم بتحديد المخزن"),
})


export const secondFiltersOptions=[
    {id: 1, name:'الاصناف ما عدا السالبة'},
    {id: 2, name:'الأصناف ما عدا الصفرية'},
    {id: 3, name:'الاصناف ما عدا السالبة والصفرية'},
    {id: 4, name:'الاصناف السالبة فقط'},
    {id: 5, name:'الاصناف الصفرية فقط'},
    {id: 6, name:'الأصناف السالبة والصفرية فقط'}
]
export const dfaultfilters={
  group:{id:0, name:''},
  filter:{id:0, name:''},
  safe: -1,
  branch:sto.branchi,
  all: false,
  empty: true,
}
export const defaultTaswyaData ={
    no: '',
    date: new moment(new Date()).format('YYYY-MM-DD'),
    safe: {},
    center: -1,
    refno:'',
    notes: '',
    res_id: '',
    acc:{id:'', name:'',},
    branch: sto.branchi,
    rows:[],
}

export function isEmptyFilters(filters){
    return (filters.group.id == 0 && filters.filter.id ==0 && !filters.all) 
}

export function getReFormatingGtys(data){

    const newData = data.map((row, i)=> {
            return {...row, id:i,
                 item:{id: row.currency_from, symbol: row.Name_Item}, 
                val2:0, 
            diff: (0 - row.Qtys),} 
        })
    return newData
}


export function formatTaswya(data){

    var clone = Object.assign({}, data);
    delete clone.rows; 
    const taswya ={...clone, 
        acc: data.acc.id, 
        center: data.center.id? data.center.id : '-1',
        safe: data.safe.id,
        branch: sto.branchi,
    }

    const _Data = data.rows.map(r=>{
        return {item: r.currency_from,
                unit: r.unit,
                avg_price: r.avgCost,
                val1: r.Qtys,
                val2: r.val2,
                diff:r.val2 - r.Qtys,}
    })
    return { taswya:taswya, _Data: _Data }
}