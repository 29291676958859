import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { useState } from "react";

import { useStylesTD, useStylesTF } from "../../St/comps/UseStyle";

export default function VatCalc({ open, onClose }) {
  const [vat, setVat] = useState(0);
  const [tapkh, setTapkh] = useState(100);
  const [total, setTotal] = useState(100);
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(total);
      }}
      id="ringtone-menu"
      maxWidth={"xs"}
      // {...others}
    >
      <DialogContent
        style={{
          textAlign: "center",
          paddingTop: "20px",
          paddingLeft: "45px",
          // border: "solid 1px black",
          height: 200,
        }}
        id="alert-dialog-slide-description"
      >
        <Container justifyContent="center">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id={"username"}
            label={"قيمة الضريبة"}
            className={clasTF.rootSale}
            InputProps={{ className: clasTD.input }}
            type="number"
            name={"username"}
            value={vat}
            onChange={(e) => {
              setVat(e.target.value);
              setTotal(
                Number(Number(tapkh) / 100) * Number(e.target.value) +
                  Number(e.target.value) +
                  Number(tapkh)
              );
            }}
          />
        </Container>
        <Container
          justifyContent="center"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id={"tapkh"}
            label={"نسبة التبغ"}
            className={clasTF.rootSale}
            InputProps={{ className: clasTD.input }}
            type="number"
            name={"tapkh"}
            value={tapkh}
            disabled={true}
            onChange={(e) => {
              setTapkh(e.target.value);
              setTotal(
                (Number(e.target.value) / 100) * Number(vat) +
                  Number(vat) +
                  Number(e.target.value)
              );
            }}
          />
        </Container>
        <Container justifyContent="center">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id={"total"}
            label={"نسبة الضريبة مع رسوم التبغ"}
            className={clasTF.rootSale}
            InputProps={{ className: clasTD.input }}
            type="number"
            name={"total"}
            value={total}
            disabled={true}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onClose(total);
          }}
        >
          تم
        </Button>
      </DialogActions>
    </Dialog>
  );
}
