import React from "react";
import { useStyles } from "./sub-StoreExchange-style";
import { Box, Grid, LinearProgress, Typography } from "@material-ui/core";
import SubStoreExchangeForm from "./sub-StoreExchange-form";
import SubStoreExchangeTable from "./sub-StoreExchange-table";
import { useState } from "react";
import { subDefaultValues } from "../../../../../services/twreed-servece";

export default function SubStoreExchange(props) {
  const { onChange, fetching, object } = props;
  const rows = props.object.rows;

  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const [currentRow, setCurrentRow] = useState(subDefaultValues);

  const addRow = (row) => {
    row.localId = Date.now();
    onChange(null, { name: "rows", value: [...rows, row] });
    setCurrentRow(subDefaultValues);
  };

  const deleteRow = (id) => {
    onChange(null, {
      name: "rows",
      value: rows.filter((row) => row.localId !== id),
    });
  };

  const selectRow = (row) => {
    setEditing(true);
    setCurrentRow({ ...row });
  };

  const updateRow = (id, updateRow) => {
    setEditing(false);
    console.log("the update row is", updateRow);
    onChange(null, {
      name: "rows",
      value: rows.map((row) => (row.localId == id ? updateRow : row)),
    });
    setCurrentRow(subDefaultValues);
  };
  const cancelUpdate = () => {
    setCurrentRow(subDefaultValues);
    setEditing(false);
  };

  return (
    <container className={classes.main}>
      <Grid item className={classes.root} container>
        <>
          <SubStoreExchangeForm
            {...{
              addRow,

              editing,
              setEditing,
              currentRow,
              setCurrentRow,
              updateRow,
              cancelUpdate,
              data: props?.Currencies,
            }}
          />
          <SubStoreExchangeTable
            {...{
              rows,
              object,
              selectRow,
              deleteRow,
              loading: false,
            }}
          />
        </>
      </Grid>
    </container>
  );
}
