import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import LoadingPage from "../../../../../services/LoadingPage";
import SalesmanReport from "./Salesmans";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
const defaultAutos = {
    salesmen: [],
};
export default function SalesmansCon() {
  const tb = {
    wait: true,
    salesmen: true,
    Branches: true,
    Banks: true,
    stor:sto,
    parms: { branch: sto.branchi},
  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "GetD",
    tb,
  );

  if (fetchAll || !AutoOptions?.salesmen ) return <LoadingPage />;

  return (
    <SalesmanReport
      {...{
        
        AutoOptions: AutoOptions?.salesmen ? AutoOptions : defaultAutos,
        isPurches: false,
      }}
    />
  );
}

