import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Button, TextField } from "@material-ui/core";
import CheckboxItem, { useStylesAcc } from "./CheckBoxComponent";
import { Autocomplete } from "@material-ui/lab";
import { GetItemFromOptions } from "../../../services/global-services";

const applyDiscountOpeions = [
  {id:0, name:"null"},
  {id:1, name:" الخصم نسبه فقط"},
  {id:2, name:"الخصم مبلغ فقط"},
  {id:3, name:" الخصم نسبه  ومبلغ معا"},
  
];
export default function DiscountPermissions(props) {
  const { onChange, object, update, sub } = props;
  const applyDisc = GetItemFromOptions(object?.apply_discount, applyDiscountOpeions);
  // useEffect(()=>{
  //   console.log("the apply discount is", applyDisc)
  //   console.log("the apply discount is", GetItemFromOptions(object?.apply_discount, applyDiscountOpeions))
  // })
  const classes = useStylesAcc();

  return (
    <Grid item container spacing={1}>
    
          <Grid xs={6}>
          
          <CheckboxItem
                value={object["Disc_saleOnItem"] ?? null}
                name={"Disc_saleOnItem"}
                label={"الخصم على مستوى الصنف"}
                onChange={onChange}
              />
            </Grid>
          <Grid xs={6}>
            <CheckboxItem
                value={object["Disc_saleOnBills"] ?? null}
                name={"Disc_saleOnBills"}
                label={"الخصم على مستوى الفاتوره "}
                onChange={onChange}
              />
              
            </Grid>
            <Grid item xs={6} lg={4} md={4} sm={4}>
        <Autocomplete
          value={applyDisc}
          onChange={(event, newValue) => {
            onChange(null, {
              name: "apply_discount",
              value: newValue?.id,
            });
          }}
          id="apply_discount"
          size="small"
          options={applyDiscountOpeions}
          //getOptionLabel={(option) => option.name}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          style={{}}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              label="discount type"
              variant="outlined"
            />
          )}
        />
          </Grid>
          <Grid item xs={6} lg={4} md={4} sm={4}>
            <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="SaleDiscPers"
                  label="نسبة الخصم المسموح Max %"
                  // className={classes.textField}
                  name="SaleDiscPers"
                  value={object?.SaleDiscPers}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    onChange(null, {
                      name: "SaleDiscPers",
                      value: e.target.value,
                      
                    });
                  }}
                />
          </Grid>
          <Grid item xs={6} lg={4} md={4} sm={4}>
            <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="SaleDiscValue"
                  label="مبلغ الخصـم المسموح  Max "
                  // className={classes.textField}
                  name="SaleDiscValue"
                  value={object?.SaleDiscValue}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    onChange(null, {
                      name: "SaleDiscValue",
                      value: e.target.value,
                      
                    });
                  }}
                />
          </Grid>
  </Grid>
  );
}
