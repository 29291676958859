import MaterialTable from "material-table";
import React from "react";
import { useTranslation } from "react-i18next";
import {TableIcons as tableIcons} from "../../../../Elemnts/TableIcons";

const ItemsTable=(props)=>{

    const {t} = useTranslation(['forms'])
      const {
        classesInfo, 
        BefrowDataCurrencie,
        cuRows,
        permiCuCo,
        setting,
        Mtb,
        stElemnt,    
    } = props;

     var columnsCurr = [
      {title: t('sale-price'), field: "sale_price", hidden:
      (setting.PrTy_InPr=="01" || setting.PrTy_InPr=="11")
     },
      {title: t('purch-price'), field: "purch_price", hidden: 
      permiCuCo.HPP && 
      (setting.PrTy_InPr=="12" || setting.PrTy_InPr=="02")
     },
    
      {title: t('product-group'), field: "nameG",},
      {title: t('barcode'), field: "barcode",},
      {title: t('item-name'), field: "symbol",},
    ]


    return (
         <MaterialTable className={classesInfo.noScroll} style={{boxShadow:'none'}}
                // variant="outlined"
                  options={{ 
                  // pageSize: 20,
                  showTitle:false,
                  toolbar: false,
                  sorting: false,
                  paging: false,
                  //  tableLayout: 'fixed',
                  headerStyle: {...stElemnt.CurrH,...stElemnt.CH},
                  cellStyle: stElemnt.CurR,
    
                   maxBodyHeight: Mtb.minmaxBHCurr,
                   minBodyHeight: Mtb.minmaxBHCurr,
                 }}
                 localization={{ body:{
               
                    emptyDataSourceMessage:<h2
                    style={{
                      overflowY: 'none', /* Hide vertical scrollbar */
                      overflowX: 'none', /* Hide horizontal scrollbar */
                      scrollbarWidth: 'none',
                      scrollbarWidth: 'thin',
                      scrollbarWidth: 'auto',
                    }}
                    ></h2> },
                  }}
    
                 columns={columnsCurr}
                 data={cuRows}
                 icons={tableIcons}
                 
                 onRowClick={(event, rowData) => {

                  BefrowDataCurrencie(rowData.id, rowData.units);
                  event.stopPropagation();
                }}
                />
    )
}

export default ItemsTable;