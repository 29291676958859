import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ScannerIcon from "@material-ui/icons/Scanner";

import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
import { Button, colors, Container, Paper } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";

export default function ListItemsForm(props) {
    const { t } = useTranslation(["forms", "translation"]);

    const {
        AutoOptions,
        rptParams,
        onChangeData,
        onChangeAutoData,
        fetching,
        clasui,
        viewHandle,
        style,
        his,
        setRptParams,
    } = props;

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();
    const onChangeSearch2 = (propName) => (event) => {
        event.preventDefault();
        setRptParams({ ...rptParams, [propName]: event.target.value });
        event.stopPropagation();
        viewHandle();
    };
    const onChangeSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        viewHandle();
    };
    return (
        <React.Fragment>

            <Grid container style={{ padding: 5 }} spacing={1}>

                <Grid item lg={3} md={3} sm={3} xs={6} >
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="id"
                        label={t("forms:barcode")}
                        style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
                        name="barcode"
                        value={rptParams.barcode}
                        InputProps={{
                            onKeyDown: (event) => {
                                if (event.key === 'Enter') {
                                    onChangeSearch(event);
                                }
                            },                       
                        }}
                        onChange={onChangeData("barcode")} />
                </Grid>

                <Grid item lg={3} md={3} sm={3} xs={6} >
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="symbol"
                        label={t("forms:product-name-ar")}
                        style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
                        name="symbol"
                        value={rptParams.symbol}
                        InputProps={{
                            onKeyDown: (event) => {
                                if (event.key === 'Enter') {
                                    onChangeSearch(event);
                                }
                            },                       
                        }}
                        onChange={onChangeData("symbol")}
                    />
                </Grid>


                <Grid item lg={4} md={4} sm={4} xs={8} >
                    <Autocomplete
                        value={rptParams.group}
                        labelPlacement=""
                        onChange={(event, newValue) => {
                            onChangeAutoData("group", newValue)
                        }}
                        id="controllable-states-demo"
                        size="small"
                        style={{ backgroundColor: colors.red[50], marginBlock: 5 }}
                        options={AutoOptions.groups}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("forms:product-group")}
                                variant="outlined"
                                size="small"
                            />
                        )}
                    />
                </Grid>



                <Grid item lg={2} md={2} sm={2} xs={4} >

                    <Container
                        maxWidth={false}
                        style={{
                            padding: "5px",
                        }}
                    >

                        <Grid item>

                            <Button
                                disabled={fetching}
                                variant="contained"
                                startIcon={<ScannerIcon />}
                                size="large"
                                style={{ ...style.iconBtnB, borderRadius: 0 }}
                                className={[clasui.buttonB]}
                                onClick={() => viewHandle()}
                            >
                                {t("forms:search")}
                            </Button>
                        </Grid>

                    </Container>
                </Grid>
            </Grid>

        </React.Fragment >
    );

}