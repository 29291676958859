import { Chip, colors, Container, Grid, Paper } from "@material-ui/core";
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { CardActionArea, Button } from "@material-ui/core";
import theminable from "../../../assets/img/thimanabl.png";
import { green } from "@material-ui/core/colors";
import { useEffect } from "react";
import { useState } from "react";
import { BeatLoader } from "react-spinners";

function TemplateCard(props) {
  const { item, onClick, count } = props;
  const [loading, setLoading] = useState(false);
  return (
    <div
      sx={{ maxWidth: 150 }}
      style={{
        padding: `${count == 0 ? "3px" : "0px "}`,
        backgroundColor: "#fff",
        color: "#000",
        direction: "rtl",
        border: `solid ${count == 0 ? "1px #ccc" : "6px " + colors.green[500]}`,
      }}
    >
      <CardActionArea
        onClick={(e) => {
          onClick(item);
          // e.preventDefault();
        }}
      >
        <Grid xs={12} container justifyContent="center">
          <BeatLoader loading={loading} color={colors.green[300]} />
        </Grid>
        {item.img != null ? (
          <CardMedia
            component="img"
            // height="400"
            // width="150"
            style={{ padding: 1 }}
            image={item.img}
            // src={`data:image/jpeg;base64,${item.img}`}
            alt="green iguana"
          />
        ) : (
          <CardMedia
            component="img"
            height="200"
            style={{ padding: 2 }}
            image={theminable}
            alt="green iguana"
          />
        )}

        <CardContent
          style={{
            paddingTop: "1px",
            marging: 0,
            padding: 2,
            minHeight: 55,
            maxHeight: 55,
          }}
        >
          <Typography style={{ padding: 1, fontSize: 16, fontWeight: "900" }}>
            {item.name}
          </Typography>
          {/* {item.nameEN != "" && (
            <Typography variant="subtitle2" component="div">
              {item.nameEN}
            </Typography>
          )} */}
          <Grid
            container
            style={{
              position: "absolute",
            }}
            justify="space-between"
          >
            <Grid item>
              {" "}
              <Typography
                style={{
                  color: "#fff",
                  borderRadius: 10,
                  paddingInline: 2,
                  backgroundColor: colors.grey[500],
                }}
                //   variant="outlined"
                size="small"
              >
                {/* {item.sale_price} */}
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              {count > 0 && (
                <Typography
                  style={{
                    color: "#fff",
                    borderRadius: 0,
                    paddingInline: 10,
                    fontSize: 18,
                    backgroundColor: colors.green[400],
                  }}
                >
                  {"✔"}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>

        {/* <CardActionArea>
          <Button
            style={{
              color: "#fff",
              borderRadius: 0,
              paddingInline: 10,
              fontSize: 12,
              backgroundColor: colors.green[400],
            }}
            onClick={() => onClick(item)}
          >
            {"8"}
          </Button>
        </CardActionArea> */}
      </CardActionArea>
    </div>
  );
}

export default TemplateCard;
