import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
// import {locSto} from '../../../Context/Locstor';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  root: { maxWidth: 200, borderRadius:7 }, card:{ height: 200, },
  root1: { flexGrow: 1, },
});

export default function FavCard({prop, onclick = () => {}}) {
  const classes = useStyles();
console.log('prop'); console.log(prop); console.log('prop')
// {
//   "id": 6330, "group_id": 5, "favorite": 1,
//   "symbol": "رضاعه مقبضين شفاط 02121 وسط",
//   "img": null, "purch_price": 16666, "type": 1, "sale_price": 16676,
//   "barcode": "113539", "unit": 5, "nameG": "رضاعات"
// }
  return (
    <Grid container justifyContent="center" className={classes.root1} spacing={2}>

      {
        prop.map(item => (

<Grid item md={3} >

        <Card className={classes.root} onClick={()=>onclick(item.id,item.barcode)}>
            <CardActionArea className={classes.card}>
              <CardMedia component="div" alt="" image={'null'} title="" />
              <StarIcon />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2">{item.symbol}</Typography>
                <Typography variant="body2" color="textSecondary" component="p">{item.sale_price}</Typography>
              </CardContent>
            </CardActionArea>
            {/* <CardActions> <Button size="small" color="primary">Share</Button>
              <Button size="small" color="primary">Learn More</Button> </CardActions> */}
          </Card>

          </Grid>
  
        ))
      }
      </Grid>

  );
}
