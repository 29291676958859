import React, { useState, useEffect } from "react";
import { Rept, sto } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import axios from "axios";
import { uri } from "../../../../help/Api";
import { useHeader, useBody } from "../../../Invs/bill-template02/style";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";

function HeaderEmp({ info }) {

    const cls = useRptAF();
    const styf = { fontWeight: "1200" };
    const stysub = { fontWeight: "bold" };
    const styfC = { fontWeight: "500" };

    const calssBody = useBody();
    const classHeader = useHeader();
    const clasm = useInvMain();
    const clas = useInvAF();

    return (
        <Grid item container
            justifyContent="center"
            style={{
                paddingTop: "1rem", fontWeight: "bold", fontFamily: "Amiri-Regular",
                // Width: "20.45m"
            }}>
            <Grid
                item
                container

                style={{ fontWeight: "bold", border: "solid 1px", borderRadius: "5px", fontFamily: "Amiri-Regular", marginLeft: "0cm" }}
            >
                <Grid item xs={5} className={classHeader.leftHeaderen}>
                    <div style={styf}>{info.Disc1_header_en}</div>
                    <div style={stysub}>{info.Disc2_header_en}</div>
                    <div style={styfC}>{info.Disc3_header_en}</div>{" "}
                </Grid>


                <Grid item xs={2} className={clasm.mincntr}>
                    <div>
                        <ButtonBase>
                            {" "}
                            <img
                                src={`data:image/jpeg;base64,${info.Logo}`}
                                className={clas.Hedlogo}
                                alt={info.nameA}
                            />{" "}
                        </ButtonBase>
                    </div>
                </Grid>


                <Grid item xs={5} className={classHeader.rightHeaderar}>
                    <div style={styf}>{info.Disc1_header_ar}</div>{" "}
                    <div style={stysub}>{info.Disc2_header_ar}</div>
                    <div style={styfC}>{info.Disc3_header_ar}</div>{" "}
                </Grid>
            </Grid>
            <Grid item container style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                <Grid item xs={12} style={{
                    fontFamily: "Amiri-Regular", border: "solid 2px", paddingTop: "0.5rem", paddingBottom: "0.5rem",
                    borderRadius: "5px", textAlign: "center",
                }}
                >
                    <div style={styf}>
                        {Rept.title}
                    </div>
                </Grid>
            </Grid>
            <Grid item container style={{
                fontFamily: "Amiri-Regular", padding: "0.3rem", border: "solid 2px",
                borderRadius: "5px", direction: "rtl",
            }}
            >
                <Grid item xs={12} container>
                    <Grid item xs={3} style={{ textAlign: "right" }}>
                        {Rept.fromH}/{" "} {Rept.fromname}
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right" }}>
                        {"الفرع"}/{" "} {Rept.branch}
                    </Grid>
                    <Grid item xs={3}>
                        <div style={styfC}>{"من تاريخ"}{"    /"} {Rept.fDate}</div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={styfC}> {"إلى تاريخ"}{"     /"} {Rept.tDate}</div>
                    </Grid>
                    <Grid item xs={3}>
                        {"تاريخ الطباعة"} {"    /"} {Rept.nDate}
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    );
}
export default HeaderEmp;