import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  colors,
  Container,
} from "@material-ui/core";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80px",
    backgroundColor: colors.green[50],
    borderColor: colors.green[300],
    borderWidth: 0,
    borderRightWidth: i18next.language == "ar" ? "8px" : "0px",
    borderLeftWidth: i18next.language == "ar" ? "0px" : "8px",
    borderStyle: "solid",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
}));

const TotalCard = (props) => {
  const { className, title, value, ...rest } = props;

  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Typography
          className={classes.title}
          color={colors.green[500]}
          gutterBottom
          variant="body1"
        >
          {title}
        </Typography>
        <Container
          style={{
            display: "flex",
            margin: 0,
            justifyContent: "center",
          }}
          maxWidth={false}
        >
          <Typography variant="h5"> {value} </Typography>
        </Container>
      </CardContent>
    </Card>
  );
};

TotalCard.propTypes = {
  className: PropTypes.string,
};

export default TotalCard;
