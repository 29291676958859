import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Button, colors, useMediaQuery } from "@material-ui/core";

import { Sidebar, Topbar } from "./components";
import { useFullscreen, useToggle } from "react-use";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 46,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 50,
    },
    backgroundColor: colors.grey[50],
  },
  shiftContent: {
    paddingRight: 240,
  },
  content: {
    height: "100%",
  },
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;
  const ref = useRef(null);
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(ref, show, { onClose: () => toggle() });
  return (
    <div
      ref={ref}
      className={clsx({
        [classes.root]: true,
        // [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar
        toggle={toggle}
        onSidebarOpen={handleSidebarOpen}
        isFullscreen={isFullscreen}
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant={"temporary"}
      />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
