import React, { useEffect } from "react";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import GroupAPI, { defaultDG } from "../../../../api/Group";
import Groups from "./groups";
import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import LoadingPage from "../../../../services/LoadingPage";

export default function GroupsCon() {
  const tb = {
    wait: true,
    // Stocks: true,
    Employees: true,
    // States: true,
    // Groups: true,
    parms: { tree: "sub" },
    autos: { Employees: true },
  };
  const url = uri + "Res";

  const {
    object: data,
    fetching,
    fetch: fetchURL1,
  } = useFetchObjectByPost(url, tb);

  useEffect(() => {
    console.log("data  is", data);
    console.log("data  is", groups);
  });

  const {
    data: groups,
    fetching: fetchinGroups,
    fetchURL,
  } = useFetchURL({
    ...{
      url: uri + "Groups",
    },
  });

  if (fetching || !data || !groups) return <LoadingPage />;

  //   if(fetchinGroups)
  //     return <BackdropScI />
  return (
    <Groups
      {...{
        groups: groups ?? [],
        Employees: data?.auto?.Employees ?? [],
        fetchURL,
      }}
    />
  );
}
