import moment from "moment/moment";
import * as yup from "yup";
import _ from "lodash";
export const initFormData = {
  Code: "",
  AName: "",
  ParentCode: "",
  Nature: 1,
  Type: 1,
  FinalAcc: -1,
  Acc_branch: null,
  default_center: null,
  omlat: [],
};
export const FinalAccOptions = [
  { id: 1, name: "ميزانية" },
  { id: 2, name: "ارباح وخسائر" },
];

export function getFinalOption(acc) {
  if (acc == 1 || acc == 2) return FinalAccOptions[acc - 1];
  return null;
}
export const convertToTreeData = (pData) => {
  const res = [];
  for (let account of pData) {
    if (account.ParentCode === "") {
      res.push(account);
    } else {
      const parent = _.find(pData, { Code: parseInt(account?.ParentCode) });
      if (!parent?.hasOwnProperty("children"))
        _.assign(parent, { children: [] });
      parent?.children.push(account);
    }
    _.assign(account, { label: account.Code + " - " + account.AName });
  }
  return res;
};

export function removeSubAccounts(data) {
  var res = [];
  for (let account of data) {
    if (account.Type === 1) {
      let acc = {
        Code: account.Code,
        label: account.label,
        maxCode:
          account.Code.toString().length >= 3
            ? account.Code.toString() + "0000"
            : account.Code.toString() + "0",
      };

      if (account.hasOwnProperty("children")) {
        var res1 = removeSubAccounts(account.children);
        acc.children = res1;
        acc.maxCode = _.maxBy(account.children, "Code").Code;
      }
      res.push(acc);
    }
  }
  return res;
}

export const accountIndexValidateSchema = yup.object().shape({
  AName: yup.string().required("ادخل اسم الحساب"),
  FinalAcc: yup.number().when("Type", {
    is: 2,
    then: yup
      .number()
      .required(-1, "قم بتحديد حساب نهاية العام")
      .positive()
      .integer(),
  }),
});
