import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  colors,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: { backgroundColor: colors.grey[200] },
  chartContainer: {
    height: 35,
    position: "relative",
  },
  actions: {
    justifyContent: "flex-start",
  },
}));
function OmlaLine(props) {
  const { omla, onChange, checked } = props;
  const classes = useStyles();
  return (
    <div className={classes.chartContainer}>
      <Grid container justify="space-between">
        <Grid item spacing={1}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={checked}
                  onChange={(e) => onChange(omla, e.target.checked)}
                  name="IS_Default"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label={omla.name + "   " + omla.symbol + ""}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  );
}
const OmlatList = (props) => {
  const { object, omlat: data, onChange, ...rest } = props;
  const { t } = useTranslation(["forms"]);
  const classes = useStyles();
  // const [selectedOmlat, setSelectedOmlat] = useState(object.omlat);
  const omlats = object?.omlat ? object.omlat : [];
  const addOmla = (om) => {
    let nOm = {
      omla_id: om.id,
    };
    onChange(null, { name: "omlat", value: [...omlats, nOm] });
  };

  const deleteOmla = (id) => {
    onChange(null, {
      name: "omlat",
      value: omlats?.filter((om) => om.omla_id !== id),
    });
  };
  const onChangeChecked = (om, isChecked) => {
    if (isChecked) addOmla(om);
    else deleteOmla(om.id);
  };
  const isChecked = (id) => {
    const index = omlats?.findIndex((om) => om.omla_id == id);

    return index != -1;
  };

  return (
    <Card {...rest} className={clsx(classes.root)}>
      <CardHeader title={t("account-omlat")} />
      <Divider />
      <CardContent>
        {data?.map((om) => {
          return (
            <OmlaLine
              omla={om}
              checked={isChecked(om.id)}
              onChange={onChangeChecked}
            />
          );
        })}
      </CardContent>
    </Card>
  );
};

OmlatList.propTypes = {
  className: PropTypes.string,
};

export default OmlatList;
