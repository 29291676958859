import React, {useEffect, useState, Fragment } from 'react';
// import {premis} from '../../Context/InshData';
import { getLS } from '../../utils/useLS';
import { Consts as cnst } from '../../Context/Consts';
import CardAccount from './Accounts/CardN';
import CardCustomer from './Customers/CardN';
import CardSafe from './Safes/CardN';
import CardSale from './Sales/CardN';
import CardSetting from './Settings/CardN';
import CardUser from './Iusers/CardN';
import CardbascData from './bascDatas/CardN';
import CardParsh from './parshs/CardN';
// p
import CardAccountP from './Accounts/CardP';
import CardCustomerP from './Customers/CardP';
import CardSafeP from './Safes/CardP';
import CardSaleP from './Sales/CardP';
import CardSettingP from './Settings/CardP';
import CardUserP from './Iusers/CardP';
import CardbascDataP from './bascDatas/CardP';
import CardParshP from './parshs/CardP';
import Grid from '@material-ui/core/Grid';
    // xs, sm, md, lg, and 
import {useStylesMenusCont} from '../../St/Fronts/UseStyle';
import {useTranslation}  from 'react-i18next';


export default function MenusContainer() {
  const clas = useStylesMenusCont();
  const {t} = useTranslation();

  const [state, setState] = useState({ sett: null });

  const getSetting =(loc) => {
    setState({...state, sett: loc.menuMin }); 
  }
    
    useEffect(() => { let loc = getLS(cnst.loc.pA,{}); getSetting(loc); },[]);

  return (
    <Fragment>
      {state.sett
      ?<Grid className={clas.root} container item spacing={4}>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardSale title={t('sales-title')}/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardParsh title={t('purchases-title')}/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardSafe title={t('stores-title')}/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardCustomer title={t('customers-and-suppliers-title')}/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardAccount title={t('accounts-title')}/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardbascData title={t('basic-information-title')}/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardUser title={t('users-and-permissions-title')}/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardSetting title={t('system-settings-title')}/> </Grid>
      {/* <Grid item lg={12} md={12} sm={12} xs={12}> <MMoSale/> </Grid> */}       
      </Grid>
      :<Grid className={clas.root} container item spacing={4}>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardSaleP/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardParshP/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardSafeP/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardCustomerP/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardAccountP/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardbascDataP/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardUserP/> </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}> <CardSettingP/> </Grid>
      {/* <Grid item lg={12} md={12} sm={12} xs={12}> <MMoSaleP/> </Grid> */}       
      </Grid>
        }
    </Fragment>

  );
}
