import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { uri } from "../../../../../help/Api";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Swipeable from "../../../../../Fronts/Main/Swipeable";
// import Page from '../../../Page';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useHistory } from "react-router-dom";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  table: {
    minWidth: 650,
  },
  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));

const sty = {
  fontSize: "1.4rem",
  marginLeft: "-6px",
  marginRight: "-6px",
};
const styTitle = {
  textAlign: "-webkit-center",
  // backgroundColor:'red'
};

let dateFromMA_G = null;
let dateToMA_G = null;
// for select
let prmvType = -1;
let prmvBranch = -1;
let prmvCustomers = -1;

const Body = () => {
  const classes = useStyles();

  // for plaseholder auto
  const [dateFromMA, setDateFromMA] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [dateToMA, setDateToMA] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [viewPrm, setViewPrm] = useState(
    `View_Sales_Tafseele?sdate=${dateFromMA}&&edate=${dateToMA}`
  ); // اجمالي قبل الضريبة

  const inshAutoOp = [{ id: -1, name: "" }];
  const inshAutoVa = { id: -1, name: "الكل" };
  // ================= For Auto ======================
  //Type
  const [optionsType, setOptionsType] = React.useState(inshAutoOp);
  const [valueType, setValueType] = React.useState(inshAutoVa);
  //Customers
  const [optionsCustomers, setOptionsCustomers] = React.useState(inshAutoOp);
  const [valueCustomer, setValueCustomer] = React.useState(inshAutoVa);
  //Branch
  const [optionsBranch, setOptionsBranch] = React.useState(inshAutoOp);
  const [valueBranch, setValueBranch] = React.useState(inshAutoVa);

  const [totWithTaxval, seTtotWithTaxval] = useState(0); // اجمالي مع الضريبة
  const [totRet, seTtotRet] = useState(0); // اجمالي المرتجع
  const [totSafe, seTtotSafe] = useState(0); // اجمالي الصافي
  const [totTaxval, seTtotTaxval] = useState(0); // اجمالي الضريبة

  const [totBefourTaxval, seTtotBefourTaxval] = useState(0); // اجمالي قبل الضريبة

  let sumTotWithTaxval = 0,
    sumTotRet = 0,
    sumTotTaxvalWithSal = 0,
    sumTotTaxvalWithSalRet = 0;
  //sumTotAmount=0,sumTotAmountRet=0,sumTotTaxval=0,sumTotDiscount=0,sumTotDiscountRet=0,sumTotWithAmount=0,sumTotWithAmountRet=0, sumToto_sales=0,sumToto_salesWFix=0,count_Inv_Sal=0;

  const getAllSumTot = async (the_length) => {
    console.log("idata");
    console.log(idata);
    for (let i = 0; i < the_length; i++) {
      // sumTotWithTaxval+=idata[i].exchange_price;

      // مبيعات
      if (idata[i].ProcType_InvProc === "02") {
        sumTotWithTaxval += idata[i].amount;
        // اجمالي الضريبة المبيعات
        sumTotTaxvalWithSal += idata[i].taxval;
      }
      if (idata[i].ProcType_InvProc === "12") {
        // مرتجع مبيعات
        sumTotRet += idata[i].amount;
        // اجمالي الضريبة مرتجع المبيعات
        sumTotTaxvalWithSalRet += idata[i].taxval;
      }
      // sumTotTaxval+=idata[i].taxval;
    }

    seTtotWithTaxval(sumTotWithTaxval); // اجمالي مع الضريبة
    seTtotRet(sumTotRet); // اجمالي المرتجع
    seTtotSafe(sumTotWithTaxval - sumTotRet); // اجمالي الصافي
    seTtotTaxval(sumTotTaxvalWithSal - sumTotTaxvalWithSalRet); // اجمالي الضريبة
    seTtotBefourTaxval(
      sumTotWithTaxval -
        sumTotRet -
        (sumTotTaxvalWithSal - sumTotTaxvalWithSalRet)
    ); // اجمالي قبل الضريبة
  };
  const GetDataWhenChangeDate = async () => {
    // alert('sds')
    // alert(`View_Sales_Tafseele?sdate=${dateFromMA_G}&&edate=${dateToMA_G}&&dtype=${prmvType}&&branch=${prmvBranch}&&customers=${prmvCustomers}`)
    setViewPrm(
      `View_Sales_Tafseele?sdate=${dateFromMA_G}&&edate=${dateToMA_G}&&dtype=${prmvType}&&branch=${prmvBranch}&&customers=${prmvCustomers}`
    );
    await axios
      .get(
        uri +
          `View_Sales_Tafseele?sdate=${dateFromMA_G}&&edate=${dateToMA_G}&&dtype=${prmvType}&&branch=${prmvBranch}&&customers=${prmvCustomers}`
      )
      .then((res) => {
        setData(res.data);
        idata = res.data;
        console.log("the data is", res.data);
        getAllSumTot(res.data.length);
      })
      .catch((error) => {});
  };

  const reAccessDate = () => {
    dateToMA_G = dateToMA;
    dateFromMA_G = dateFromMA;
    GetDataWhenChangeDate();
  };

  const changAutoType = (newValue) => {
    setValueType(newValue);
    prmvType = newValue.id;
    if (newValue.id === -1) prmvType = -1;
    reAccessDate();
  };

  const changAutoCustomer = (newValue) => {
    setValueCustomer(newValue);
    if (newValue.id === -1) prmvCustomers = -1;
    else prmvCustomers = newValue.acc_no;
    if (newValue.id === -1) prmvCustomers = -1;
    reAccessDate();
  };

  const changAutoBranch = (newValue) => {
    setValueBranch(newValue);
    prmvBranch = newValue.id;
    if (newValue.id === -1) prmvBranch = -1;
    reAccessDate();
  };

  const getAutoType = async (i) => {
    axios
      .get(uri + "Auto" + "/type")
      .then((res) => {
        setOptionsType(res.data);
        setValueType(res.data[i]);
      })
      .catch((error) => {});
  };

  const getAutoCustomers = async (i) => {
    axios
      .get(uri + "Auto" + "/custm?t=1")
      .then((res) => {
        setOptionsCustomers(res.data);
        setValueCustomer(res.data[i]);
      })
      .catch((error) => {});
  };

  const getAutoBranch = async (i) => {
    await axios
      .get(uri + "Auto" + "/branch")
      .then((res) => {
        setOptionsBranch(res.data);
        setValueBranch(res.data[i]);
      })
      .catch((error) => {});
  };

  const [data, setData] = React.useState([]);
  let idata = null;
  useEffect(() => {
    const GetData = async () => {
      // for Table

      setViewPrm(`View_Sales_Tafseele?sdate=${dateFromMA}&&edate=${dateToMA}`);

      await axios
        .get(uri + viewPrm)
        .then((res) => {
          setData(res.data);
          idata = res.data;
          getAllSumTot(res.data.length);
        })
        .catch((error) => {});

      getAutoType(2);
      getAutoCustomers(0);
      getAutoBranch(0);
    };

    GetData();
  }, []);

  // Date T
  // From
  const dateChangeFrom = (e) => {
    let getdateFrom = moment(e.target.value).format("YYYY-MM-DD");
    setDateFromMA(getdateFrom);
    dateFromMA_G = getdateFrom;
    dateToMA_G = dateToMA;

    GetDataWhenChangeDate();
  };
  //To
  const dateChangeTo = (e) => {
    let getdateTo = moment(e.target.value).format("YYYY-MM-DD");
    setDateToMA(getdateTo);
    dateFromMA_G = dateFromMA;
    dateToMA_G = getdateTo;

    GetDataWhenChangeDate();
  };

  var columns = [
    { title: "الصنف", field: "symbol" },
    { title: "الكمية", field: "val1" },
    { title: "السعر", field: "exchange_price" },
    { title: "الخصم", field: "discount" },
    { title: "الضريبة", field: "taxval" },
    { title: "الصافي مع الضريبة", field: "amount" },
    { title: "العميل", field: "customer_name" },
    { title: "التفاصيل", field: "trans_name_ar" },
    { title: "طريقة الدفع", field: "name" },
  ];

  let his = useHistory();
  const toPosh = () => {
    const info = {
      columns: columns,
      rout: uri + viewPrm,
      data: data,
      tot: {
        totBefourTaxval: totBefourTaxval.toFixed(2),
        totTaxval: totTaxval.toFixed(2),
        totWithTaxval: totWithTaxval.toFixed(2),
      },
      from: "طريقة الدفع",
      title: "تقرير تفصيلي للمبيعات",
      // auto:valueAutos,
      auto: valueType.name,
      fDate: dateFromMA,
      tDate: dateToMA,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      ElmntShow: false,
    };
    //console.log(info)
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/ReviewTafsel" });
    window.location.reload(false);
  };

  return (
    <div className={classes.root} title="---">
      <Typography
        component="div"
        style={{ backgroundColor: "#eee", direction: "rtl" }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid
              container
              component={Paper}
              style={{ marginTop: 10 }}
              spacing={1}
            >
              <Grid item sm={2} xs={6}>
                <TextField
                  /*type="number"*/ fullWidth
                  variant="outlined"
                  size="small"
                  id="date"
                  label="من تاريخ"
                  // className={clasTF.root} InputProps={{ className: clasTF.input }}
                  type="date"
                  format="YYYY-MM-DD"
                  name="date"
                  value={dateFromMA}
                  onChange={dateChangeFrom}
                />
              </Grid>

              <Grid item sm={2} xs={6}>
                <TextField
                  /*type="number"*/ fullWidth
                  variant="outlined"
                  size="small"
                  id="date"
                  label="إلى تاريخ"
                  // className={clasTF.root} InputProps={{ className: clasTF.input }}
                  type="date"
                  format="YYYY-MM-DD"
                  name="date"
                  value={dateToMA}
                  onChange={dateChangeTo}
                />
              </Grid>

              <Grid item sm={2} xs={6}>
                <Autocomplete
                  value={valueType}
                  onChange={(event, newValue) => {
                    changAutoType(newValue);
                  }}
                  id="combo-box-demo"
                  size="small"
                  options={optionsType}
                  //getOptionLabel={(option) => option.name}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  style={{}}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="طريقة الدفع"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item sm={2} xs={6}>
                <Autocomplete
                  value={valueCustomer}
                  onChange={(event, newValue) => {
                    changAutoCustomer(newValue);
                  }}
                  id="combo-box-demo"
                  size="small"
                  options={optionsCustomers}
                  //getOptionLabel={(option) => option.name}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  style={{}}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="العميل"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item sm={2} xs={6}>
                <Autocomplete
                  value={valueBranch}
                  onChange={(event, newValue) => {
                    changAutoBranch(newValue);
                  }}
                  id="combo-box-demo"
                  size="small"
                  options={optionsBranch}
                  //getOptionLabel={(option) => option.name}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  style={{}}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="الفرع"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <br />
        <br />
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">الصنف</TableCell>
                    <TableCell align="center">الكمية</TableCell>
                    <TableCell align="center">السعر</TableCell>
                    <TableCell align="center">الخصم</TableCell>
                    <TableCell align="center">الضريبة</TableCell>
                    <TableCell align="center">الصافي مع الضريبة</TableCell>
                    <TableCell align="center">العميل</TableCell>
                    <TableCell align="center">التفاصيل</TableCell>
                    <TableCell align="center">طريقة الدفع</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.symbol}</TableCell>
                      <TableCell align="center">{row.val1}</TableCell>
                      <TableCell align="center">{row.exchange_price}</TableCell>
                      <TableCell align="center">{row.discount}</TableCell>
                      <TableCell align="center">{row.taxval}</TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">{row.customer_name}</TableCell>
                      <TableCell align="center">{row.trans_name_ar}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Container>

        <br />
        <br />

        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid container component={Paper}>
              <Grid
                item
                // className={classes.iitem}
                xs={6}
                sm={2}
                className={classes.iitemS}
                style={styTitle}
              >
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="p"
                      style={sty}
                    >
                      الإجمالي مع الضريبة
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>

              <Grid
                item
                // className={classes.iitem}
                xs={6}
                sm={2}
                className={classes.iitemS}
              >
                {" "}
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="p"
                      style={sty}
                    >
                      {totWithTaxval.toFixed(2)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>

              <Grid
                item
                // className={classes.iitem}
                xs={6}
                sm={2}
                className={classes.iitemS}
                style={styTitle}
              >
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="p"
                      style={sty}
                    >
                      إجمالي المرتجع
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>

              <Grid
                item
                // className={classes.iitem}
                xs={6}
                sm={2}
                className={classes.iitemS}
              >
                {" "}
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="p"
                      style={sty}
                    >
                      {totRet.toFixed(2)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>

              <Grid
                item
                // className={classes.iitem}
                xs={6}
                sm={2}
                className={classes.iitemS}
                style={styTitle}
              >
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="p"
                      style={sty}
                    >
                      الإجمالي قبل الضريبة
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>

              <Grid
                item
                // className={classes.iitem}
                xs={6}
                sm={2}
                className={classes.iitemS}
              >
                {" "}
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="p"
                      style={sty}
                    >
                      {totBefourTaxval.toFixed(2)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid container component={Paper}>
              <Grid
                item
                // className={classes.iitem}
                xs={6}
                sm={3}
                className={classes.iitemS}
                style={styTitle}
              >
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="p"
                      style={sty}
                    >
                      إجمالي الضريبة
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>

              <Grid
                item
                // className={classes.iitem}
                xs={6}
                sm={3}
                className={classes.iitemS}
              >
                {" "}
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="p"
                      style={sty}
                    >
                      {totTaxval.toFixed(2)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>

              <Grid
                item
                // className={classes.iitem}
                xs={6}
                sm={3}
                className={classes.iitemS}
                style={styTitle}
              >
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="p"
                      style={sty}
                    >
                      إجمالي الصافي
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>

              <Grid
                item
                // className={classes.iitem}
                xs={6}
                sm={3}
                className={classes.iitemS}
              >
                {" "}
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="p"
                      style={sty}
                    >
                      {totSafe.toFixed(2)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <br />
      </Typography>

      <div style={{ textAlign: "center  " }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={toPosh}
          startIcon={<PrintIcon />}
        >
          {" "}
          معاينة{" "}
        </Button>
      </div>

      <br />
      <br />

      <Typography component="div">
        {" "}
        <Swipeable />{" "}
      </Typography>
    </div>
  );
};

export default Body;
