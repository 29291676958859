import React, { Fragment, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ScannerIcon from "@material-ui/icons/Scanner";

import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
import { AppBar, Button, colors, Container, CssBaseline, Hidden, Paper, Toolbar } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import CircularProgress from "@material-ui/core/CircularProgress";
import { insh } from "../../../../../Context/InshSt";

export default function ItemsAppBar(props) {
    const { t } = useTranslation(["forms", "translation"]);

    const {
            items,
            setBarcodeDialogOpen,
            buttonClassnameNew,
            buttonClassnameSave,
            buttonClassnameDelete,
            clasCAB,
            newToI,
            setting,
            deleteToI,
            addToBS,
            stElemnt,
            loading,
            clasui,
            clasBA,
            isDialog,
            backHome,
            back,
            setOpenSearch,
            handNewItem,
            uiElement,
            success,
            handOpenDw,
            TransitionUp,
    } = props;
    const clasup = useStylesUp();
    const [styles, setStyles] = useState(insh.bascData);

    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();


    const FrontBtnAction = () => {
        return (
            <Fragment direction="rtl"  >

                <Hidden only={["xs"]}>

                    {uiElement.DeleIcon === true ? (

                        <div className={clasCAB.wrapperCAB}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={buttonClassnameDelete}
                                startIcon={<DeleteIcon />}
                                style={{ backgroundColor: "#bb2b2b", ...stElemnt.all.iconBtn }}
                                disabled={loading.delete}
                                onClick={(e) => deleteToI(e, items.id, setting.screen)}
                            >
                                {t("forms:delete")}
                            </Button>
                            {loading.delete && (
                                <CircularProgress
                                    size={24}
                                    className={clasCAB.buttonProgressCAB}
                                />
                            )}
                        </div>
                    ) : null}
                    {uiElement.DeleIcon === true ? (
                        <div className={clasCAB.wrapperCAB}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={buttonClassnameDelete}
                                style={{ backgroundColor: "#bb2b2b", ...stElemnt.all.iconBtn }}
                                disabled={loading.delete}
                                onClick={(e) => {
                                    setBarcodeDialogOpen(true);
                                }}
                            >
                                {t("forms:barcode")}
                            </Button>
                        </div>
                    ) : null}

                    <div className={clasCAB.wrapperCAB}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{ backgroundColor: "#bb2b2b", ...stElemnt.all.iconBtn }}
                            className={buttonClassnameSave}
                            disabled={loading.save}
                            onClick={addToBS}
                            startIcon={<SaveIcon />}
                        >
                            {" "}
                            {t("forms:save")}{" "}
                        </Button>
                        {loading.save && (
                            <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                        )}
                    </div>

                    <div className={clasCAB.wrapperCAB}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{ backgroundColor: "#bb2b2b", ...stElemnt.all.iconBtn }}
                            className={buttonClassnameNew}
                            disabled={loading.new}
                            onClick={() => newToI(1000)}
                            startIcon={<AddIcon />}
                        >
                            {" "}
                            {t("forms:new")}{" "}
                        </Button>
                        {loading.new && (
                            <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                        )}
                    </div>
                </Hidden>
            </Fragment>
        );
    };
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar
                position="fixed"
                color="primary"
                style={{ backgroundImage: "linear-gradient(160deg, #ff3e3b, #cd5c5c)", ...stElemnt.all.appBarBA,
                    direction: "ltr" 
                    }}

            >
                <Toolbar className={clasBA.ToolbarBA} variant="dense">
                    <Hidden smUp={["sm"]}>

                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={backHome}
                        >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => back()}
                        >
                            <ReplyIcon />
                        </IconButton>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={(e) => {
                               // setOpenSearch(true);
                                handOpenDw(TransitionUp)
                            }}                        >
                            <SearchIcon />
                        </IconButton>
                    </Hidden>
                        <div className={clasBA.growBA} />

                    <Hidden smUp={["sm"]}>
                        {uiElement.DeleIcon === true ? (
                            <div style={{ alignContent: "right" }}>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    aria-label="delete"
                                    onClick={(e) => deleteToI(e, items.id, setting.screen)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {loading.delete && (
                                    <CircularProgress
                                        size={24}
                                        className={clasCAB.buttonProgressCAB}
                                    />
                                )}
                            </div>
                        ) : null}


                        <div style={{ alignContent: "right", edge: "end" }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="new"

                                disabled={loading.new}
                                onClick={(e) => newToI(1000)}
                            >
                                <AddIcon />
                            </IconButton>
                            {loading.new && (
                                <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                            )}
                        </div>
                        <div style={{ alignContent: "right", edge: "end" }}>

                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="save"
                                disabled={loading.save}
                                onClick={(e) => addToBS()}
                            >
                                <SaveIcon />
                            </IconButton>
                            {loading.save && (
                                <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
                            )}
                        </div>
                    </Hidden>
                    <Hidden only={["xs"]}>
                        {/* {!isDialog && (
                           
                        )} */}
                        <Button
                            style={{ color: "#fff" }}
                            onClick={backHome}
                            startIcon={<CloseIcon />}
                        >
                            {t("forms:exit")}
                        </Button>
                        <Button
                            style={{ color: "#fff" }}
                            onClick={() => back()}
                            startIcon={<ReplyIcon />}
                        >
                            {t("forms:back")}
                        </Button>
                    </Hidden>


                    <Hidden smDown={["sm"]}>

                        <FrontBtnAction />
                    </Hidden>
                </Toolbar>
            </AppBar>
        </React.Fragment >
    );

}