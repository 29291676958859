import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { colors, Divider, Grid, Typography } from "@material-ui/core";

import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  LatestSales,
} from "./components";
import YearSales from "./components/YearSales/YearSales";
import moment from "moment";
import useFetchURL from "../../components/Hooks/useFetchURL";
import { uri } from "../../help/Api";
import DetailsList from "./components/DetailsList/DetailsList";
import Swipeable from "../../Fronts/Main/Swipeable";
import MostSoled from "./components/MostSoldProducts/MostSoled";
import { getLS } from "../../utils/useLS";
import DashboardForm from "./Dashboard-form";
import { useState } from "react";
import { sto } from "../../Context/InshData";
import SafesQuantity from "./components/SafesQuantity";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    direction: "rtl",
  },
  backGreen: {
    backgroundColor: colors.green[50],
  },
  backRed: {
    backgroundColor: colors.red[50],
  },
}));

const defalutParams = {
  dateFrom: moment(new Date()).format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  branch: { id: -1, name: "الكل" },
  safe: { id: -1, name: "الكل" },
};

const Dashboard = (props) => {
  const classes = useStyles();
  let today = new Date();
  let yasterday = moment(today.setDate(today.getDate())).format("YYYY-MM-DD");
  const [rptParams, setRptParams] = useState(defalutParams);

  const { data: allData, fetching } = useFetchURL({
    ...{
      url:
        uri +
        `RptSales/dashboard?branch=${rptParams.branch.id}
    &&dateFrom=${rptParams.dateFrom}&&dateTo=${rptParams.dateTo}`,
      dependencyArray: [rptParams],
    },
  });

  let pScr = getLS("premiScr", {});

  useEffect(() => {
    console.log("the data is", allData);
  });
  if (!pScr.acc) return <div>لا يوجد صلاحية للوصول</div>;

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DashboardForm
              {...{
                rptParams,
                setRptParams,
                Branches: props.Branches,
                Safes: props.Safes,
              }}
            />
          </Grid>
          <Grid container item spacing={1} lg={8} sm={12} md={8} xs={12}>
            <Grid item lg={6} sm={6} md={6} xs={12}>
              <Budget
                {...{
                  className: classes.backGreen,
                  title: "المبيعات ",
                  total: allData ? allData.sales?.total.toFixed(2) : 0.0,
                  fetching,
                  curr: props.curr,
                }}
              />
              <Divider style={{ padding: 5 }} />
              <TotalUsers
                {...{ invCount: allData?.sales ? allData.sales.counts : 0 }}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12}>
              <Budget
                {...{
                  className: classes.backRed,
                  color: colors.red[600],
                  title: "المشتريات ",
                  total: allData ? allData.purches?.total?.toFixed(2) : 0.0,
                  fetching,
                  curr: props.curr,
                }}
              />
              <Divider style={{ padding: 5 }} />
              <Budget
                {...{
                  // className: classes.back1,
                  purchIcon: true,
                  color: colors.blueGrey[300],
                  title: "المصروفات ",
                  total: allData ? allData.Expenses : 0.0,
                  fetching,
                  curr: props.curr,
                }}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12}>
              <TasksProgress
                {...{
                  value: allData.Revenues,
                }}
              />
              <Divider style={{ margin: 5 }} />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12}>
              <TotalProfit
                {...{ value: allData?.Profit, fetching, curr: props.curr }}
              />
              <Divider style={{ margin: 5 }} />
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <MostSoled
                {...{
                  data: allData ? allData.mostFiveSoldProducts : {},
                  fetching,
                }}
              />
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <LatestSales
                {...{
                  data: allData ? allData.LatestSevenDaysSale : {},
                  fetching,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <YearSales
                {...{
                  data: allData ? allData.YearMonthsSales : {},
                  fetching,
                }}
              />
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <SafesQuantity Safes={props.Safes} />
            <br />
            <DetailsList
              {...{
                data: allData ? allData.sales : {},
                fetching,
                curr: props.curr,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
