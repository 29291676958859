import React, { useState, useEffect } from "react";

import { selectDateOrTime, selectTypes } from "../../../../../services/saleAnalysis-services";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import SelectTypesFilter from "./saleAnalysis-typeFilter";
import SaleAnalysisForm from "./saleAnaysis-form";
import SaleAnalysisTable from "./saleAnalysis-table";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import DailyReportFilter from "../EveryDay/DailyReportFilter";
import { sto } from "../../../../../Context/InshData";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  purchTotalCard: {
    backgroundColor: colors.blue[50],
    borderColor: colors.blue[300],
  },
}));
function toFixedValues(data) {
  const newData = data?.saleAnalysis?.map((row, i) => {
    return {
      ...row,
      qty: row.qty?.toFixed(2),
      totNet: row.totNet?.toFixed(2),
      minus: row.minus?.toFixed(2),
      vat: row.vat?.toFixed(2),
      cost: row.cost?.toFixed(2),
      Profit: row.Profit?.toFixed(2),
    };
  });

  return { ...data, saleAnalysis: newData };
}
export default function SaleAnalysis(props) {
  const { AutoOptions, CustomerOptions, branch, setBranch, isPurches } = props;
  let idSafe = AutoOptions.Safes[0].id;
  let nameSafe = AutoOptions.Safes[0].name;

  let iduser = AutoOptions.Users[0].id;
  let nameuser = AutoOptions.Users[0].name;

  let idstock = AutoOptions.Stocks[0].id;
  let namestock = AutoOptions.Stocks[0].name;


  let idBranch = AutoOptions.Branches[0].id;
  let nameBranch = AutoOptions.Branches[0].name;
  const SAdefaultValues = {
    selectType: selectTypes[0],
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    timeFrom: moment(new Date()).format('HH:mm'),
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
    timeTo: moment(new Date()).format('HH:mm'),
    selectTime: selectDateOrTime[0],
    safe: { id: sto.User_typei == 1 ? -1 : idSafe, name: sto.User_typei == 1 ? "الكل" : nameSafe },
    cust: { id: -1, name: "الكل" },
    user: { id: sto.User_typei == 1 ? -1 : iduser, name: sto.User_typei == 1 ? "الكل" : nameuser },
    stock: { id: sto.User_typei == 1 ? -1 : idstock, name: sto.User_typei == 1 ? "الكل" : namestock },
    sales_man: { id: -1, name: "الكل" },
    group: { id: -1, name: "الكل" },
    branch: { id: sto.User_typei == 1 ? -1 : idBranch, name: sto.User_typei == 1 ? "الكل" : nameBranch },
    chk_withvat: false,
    type_cost: 1,
  };
  const [rptParams, setRptParams] = React.useState(SAdefaultValues);
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `RptSales/SalesAnalysis?type_Select=${rptParams.selectType.name}
        &&branch=${branch?.id}
    &&dateFrom=${rptParams.selectTime.id == 1 ? rptParams.dateFrom : rptParams.dateFrom + ' ' + rptParams.timeFrom}
    &&dateTo=${rptParams.selectTime.id == 1 ? rptParams.dateTo : rptParams.dateTo + ' ' + rptParams.timeTo}
    &&stock=${rptParams.stock.id}
    &&cust=${rptParams.cust.id}
    &&safe=${rptParams.safe.id}
    &&sales_man=${rptParams.sales_man.id}
    &&groups=${rptParams.group.id}
    &&user=${rptParams.user.id}
    &&chk_withvat=${rptParams.chk_withvat}
    &&type_cost=${rptParams.type_cost}
    &&isPurch=${isPurches}
    &&date_timeA=${rptParams.selectTime.name}`,
      mapFunction: toFixedValues,
      dependencyArray: [],
    },
  });

  const his = useHistory();
  const printHandle = () => {
    if (data.lenght == 0) {
      alert("لاتوجد بيانات");
  } else {
      const info = {
        data: data?.saleAnalysis,
        tot: data?.totals,
        title: isPurches
          ? t("translation:purch-analysis-rpt")
          : t("translation:sale-analysis-rpt"),
        auto: {},
        fDate: rptParams.dateFrom,
        tDate: rptParams.dateTo,
        ElmntShow: true,
        safe: rptParams.safe.name,
        stock: rptParams.stock.name,
        branch: branch?.name,
        cust: rptParams.cust.name,
        sales_man: rptParams.sales_man.name,
        username: rptParams.user.name,
        group: rptParams.group.name,
        labelname: t(rptParams.selectType.label),
        //totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
      };
      localStorage.setItem("iInfos", JSON.stringify(info));
      his.push({ pathname: "/AnalysisPrint" });
      window.location.reload(true);
    }
  };

  const printHandle2 = () => {
    const info = {
      ...rptParams,
      data: data?.saleAnalysis,
      totals: data?.totals,
      title: isPurches ? "مشتريات" : "مبيعات",

    };

    his.push({ pathname: "/AnalysisPrint", state: info });

    window.location.reload(false);
  };
  let excel_name = isPurches ? "PurchasesAnalysisReports" : "SalessAnalysisReports";
  const excelHeaders = [
    { label: t(rptParams.selectType.label), key: "txtType", },
    { label: t("invoices"), key: "cnt", },
    { label: t("net-quantity"), key: "qty", },
    { label: t("net-total"), key: "totNet", },
    { label: t("net-discount"), key: "minus", },
    { label: t("net-tax"), key: "vat", },
    { label: t("net-cost"), key: "cost", },
    { label: t("net-profit"), key: "Profit", },
  ];

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {isPurches
            ? t("translation:purch-analysis-rpt")
            : t("translation:sale-analysis-rpt")}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: isPurches ? colors.blue[300] : colors.green[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <DailyReportFilter {...{ rptParams, setRptParams, isPurches }} />
            </Grid>
            <Grid item lg={9} md={9} sm={9} xs={12}>
              <SelectTypesFilter {...{ rptParams, setRptParams, isPurches }} />
            </Grid>
          </Grid>
          <SaleAnalysisForm
            {...{
              AutoOptions,
              CustomerOptions,
              rptParams,
              onChangeData,
              onChangeAutoData,
              printHandle,
              branch,
              setBranch,
              isPurches,
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Hidden smUp={["sm"]}>
              <Grid item>
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<ScannerIcon />}
                  size="large"
                  style={
                    (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                      { borderRadius: 0 })
                  }
                  className={[clasui.button]}
                  onClick={() => fetchURL()}
                >
                  {t("forms:show")}
                </Button>
              </Grid>
            </Hidden>
            <Hidden only={["xs"]}>
              <Grid item>
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<ScannerIcon />}
                  size="large"
                  style={
                    (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                      { borderRadius: 0 })
                  }
                  className={[clasui.button]}
                  onClick={() => fetchURL()}
                >
                  {t("forms:view")}
                </Button>
              </Grid>
            </Hidden>
            <Hidden smUp={["sm"]}>
            <Grid item >
              {data && !fetching && data.saleAnalysis && (
                <CSVLink
                  separator={";"}
                  filename={excel_name}
                  data={data.saleAnalysis}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    size="large"
                    color="primary"
                    style={{ borderRadius: 0 }}
                    className={[clasui.button]}
                  >
                    {"اكسل"}
                  </Button>
                </CSVLink>
              )}
            </Grid>
            </Hidden>
            <Hidden only={["xs"]}>
            <Grid item >
              {data && !fetching && data.saleAnalysis && (
                <CSVLink
                  separator={";"}
                  filename={excel_name}
                  data={data.saleAnalysis}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    size="large"
                    color="primary"
                    style={{ borderRadius: 0 }}
                    className={[clasui.button]}
                  >
                    {"تصدير اكسل"}{" "}
                  </Button>
                </CSVLink>
              )}
            </Grid>
            </Hidden>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={
                  (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                    { borderRadius: 0 })
                }
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={
                  (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                    { borderRadius: 0 })
                }
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: t("forms:net-quantity"),
                  value: data?.totals?.qtyTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: t("forms:net-total"),
                  value: data?.totals?.totNetTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: t("forms:net-discount"),
                  value: data?.totals?.minusTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: t("forms:net-cost"),
                  value: data?.totals?.costTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: t("forms:net-cost"),
                  value: data?.totals?.vatTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: t("forms:net-profit"),
                  value: data?.totals?.profitTotal.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.green[300]} />
              {!fetching && (
                <SaleAnalysisTable
                  {...{
                    data: data?.saleAnalysis ? data.saleAnalysis : [],
                    title: rptParams.selectType.label,
                    isPurches,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
