import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { uri } from "../../../../../help/Api";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

export default function ItemsListTable(props) {
    const { data, getItemsData } = props;



    const stylH = {
        // borderWidth: 1,
        border: "solid 0.6px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 14,
        color: "#fff"
    }
    const stylRo = {
        BorderBottom: "solid 0.5px",
        //borderStyle: "solid",
        borderColor: "gray",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 12,
        color: "#000"
    }
   
    const [fetching, setFetching] = useState(false);
    const [sum, setSum] = useState(false);
    const onRowClick = async (rowData) => {
        setFetching(true);
        await axios
            .get(
                uri +
                `RptSafes/itemUnitSrearch?curr_id=${rowData.id}`
            )
            .then((res) => {
                setFetching(false);

                getItemsData(rowData,res.data?.subUnits,res.data?.subBarcodes,res.data?.itemComps);
            })
            .catch((err) => {
                setFetching(false);
            });
    };

    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    return (
        <TableContainer component={Paper} >
            <Table
                //className={classes.table} 
                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: "#f18584",
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                            border: "solid 0.6px"
                        }}
                    >
                        <TableCell style={stylH} align="center">
                            {"الرقم"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الصنف"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الباركود"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"المجموعة"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الشراء"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"البيع"}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.symbol}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                fontWeight: 800,
                                fontFamily: "Amiri-Regular",
                                fontSize: 15,
                                border: "solid 0.6px"
                            }}>
                            <TableCell style={stylRo} align="center"  onClick={() => { onRowClick(row); }}>{row.id}</TableCell>
                            <TableCell style={stylRo} align="center"  onClick={() => { onRowClick(row); }}>{row.symbol}</TableCell>
                            <TableCell style={stylRo} align="right"  onClick={() => { onRowClick(row); }}>{row.barcode}</TableCell>
                            <TableCell style={stylRo} align="center"  onClick={() => { onRowClick(row); }}>{row.groupName}</TableCell>
                            <TableCell style={stylRo} align="center"  onClick={() => { onRowClick(row); }}>{row.purch_price}</TableCell>
                            <TableCell style={stylRo} align="center"  onClick={() => { onRowClick(row); }}>{row.sale_price}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
