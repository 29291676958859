import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStylesTF } from "../../../../../../St/comps/UseStyle";
import AccountSelect from "../../../../../../components/controls/accounts-select";
import { useEffect } from "react";
import { Button, colors } from "@material-ui/core";
import GlobleOmlatForm from "../../../../../../components/controls/OmlatForm";
import SaveeIcon from "@material-ui/icons/Save";
import { subMultiReceiptSchema } from "../../../../../../services/multi-receipt-services";
import toast from "react-hot-toast";
import { getAccountOmlat } from "../../../../../../services/omlat-services";
import { useTranslation } from "react-i18next";

export default function MultiFormSub(props) {
  const {
    accounts,
    editing,
    cancelUpdate,
    currentRow,
    setCurrentRow,
    addRow,
    updateRow,
    AutoOptions,
  } = props;
  const { t } = useTranslation(["forms"]);

  const clasTF = useStylesTF();
  useEffect(() => {
    console.log("the effect ", accounts);
  });

  const submit = () => {
    subMultiReceiptSchema
      .validate(currentRow, { abortEarly: false })
      .then(function (valid) {
        if (editing) updateRow(currentRow.id, currentRow);
        else addRow(currentRow);
      })
      .catch((error) => {
        toast.error(error.errors[0]);
      });
  };

  const changeAccountHandle = async (newValue) => {
    const accOmlat = getAccountOmlat(newValue.acc_omlat, AutoOptions.omlats);
    let nObject = {
      ...currentRow,
      account: newValue,
      acc_no: newValue?.id,
      name: newValue?.name,
      acc_omlat: accOmlat,
      acc_omla: accOmlat[0],
    };
    setCurrentRow(nObject);
  };

  return (
    <React.Fragment>
      <Grid container style={{ padding: 10 }} spacing={2}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <AccountSelect
            accounts={accounts}
            account={currentRow.account}
            onSelect={(newValue) => {
              changeAccountHandle(newValue);
            }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={6}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="val"
            label={t("amount")}
            className={clasTF.rootEDS}
            style={{ backgroundColor: colors.grey[50] }}
            InputProps={{ className: clasTF.input }}
            name="value"
            disabled={false}
            value={currentRow.value}
            onChange={(e) => {
              const ex_value = currentRow.acc_omla?.exchange;
              let nObject = {
                ...currentRow,
                value: e.target.value,
                credit: e.target.value * ex_value,
              };
              setCurrentRow(nObject);
            }}
          />
        </Grid>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <GlobleOmlatForm
            {...{
              omlat: currentRow.acc_omlat,
              omla: currentRow.acc_omla,
              amount: currentRow.value,
              disabled: false,
              onExchangeChange: (value) => {
                console.log("the new value is", value);
                let newcurrentRow = {
                  ...currentRow,
                  acc_omla: { ...currentRow.acc_omla, exchange: value },
                  credit: currentRow.value * value,
                };
                setCurrentRow(newcurrentRow);
              },
              onChange: (newValue) => {
                console.log("the new value is", newValue);
                let newcurrentRow = {
                  ...currentRow,
                  acc_omla: newValue,
                  credit: currentRow.value * newValue.exchange,
                };
                setCurrentRow(newcurrentRow);
              },
            }}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={4}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="refno"
            label={t("ref-no")}
            onChange={(e) =>
              setCurrentRow({ ...currentRow, refno: e.target.value })
            }
            name="refno"
            value={currentRow.refno}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={8} xs={8}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="note"
            label={t("note")}
            className={clasTF.rootEDS}
            onChange={(e) =>
              setCurrentRow({ ...currentRow, notes: e.target.value })
            }
            name="note"
            value={currentRow.note}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={3} xs={6}>
          <Button
            style={{ direction: "ltr", backgroundColor: colors.grey[500] }}
            variant="contained"
            color="primary"
            fontSize="large"
            size="medium"
            onClick={submit}
            startIcon={<SaveeIcon />}
          >
            {editing ? t("save-update") : t("download")}{" "}
          </Button>
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={4}>
          {editing && (
            <Button
              style={{ direction: "ltr", backgroundColor: colors.red[400] }}
              variant="contained"
              color="primary"
              fontSize="large"
              size="medium"
              onClick={() => {
                cancelUpdate();
              }}
            >
              {t("cancel")}{" "}
            </Button>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
