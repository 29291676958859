


import React,{Fragment} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
// import Switch from '@material-ui/core/Switch';
// import WifiIcon from '@material-ui/icons/Wifi';
// import LineStyleIcon from '@material-ui/icons/LineStyle';
// import BluetoothIcon from '@material-ui/icons/Bluetooth';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import  Swipeable  from '../../Fronts/Main/Swipeable';
import PermisionsApp from './PermisionsApp';
// import Main from './Main';

import {useStylesSetting,} from '../../St/comps/UseStyle';
// cuL

export default function Settings() {
  const classesStt = useStylesSetting();

  return (
    <Fragment>
    <CssBaseline />

      <PermisionsApp /> 
       
    <Typography component="div">
               <Swipeable />
             </Typography>
  </Fragment>
  );
}


