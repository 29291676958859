import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function CurrencySalesProfitTable(props) {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation(["forms"]);
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{ backgroundColor: colors.indigo[300], color: "#fff" }}
          >
            <TableCell style={{ color: "#fff" }} align="center">
              {t("item")}
            </TableCell>
            {/* <TableCell style={{color:'#fff'}} align="center">الكمية المباعة</TableCell> */}
            <TableCell style={{ color: "#fff" }} align="center">
              {t("quantity-sold")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("total-sales-value")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("total-sales-cost")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {" "}
              {t("profit")}
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.txtType}</TableCell>
              {/* <TableCell align="center">{row.cnt}</TableCell> */}
              <TableCell align="center">{row.qty}</TableCell>
              <TableCell align="center">{row.totNet}</TableCell>
              <TableCell align="center">{row.cost}</TableCell>
              <TableCell align="center">{row.Profit}</TableCell>
              <TableCell align="center">{}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
