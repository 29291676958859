import React from "react";
import { uri } from "../../../help/Api";
import useFetchObjectByPost from "../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import BackdropScI from "../../../services/BackdropScI";
import Stocks from "./stocks";

export default function StocksCon(){

    const tb = {wait:true, Stocks:true,Employees:true,States:true,Branches:true, parms:{tree:"sub",},
                 autos:{Employees:true,Branches:true,},}
    const url = uri+'Res';

    const {object:data, fetching: fetchinData, fetch:fetchURL} =  useFetchObjectByPost(url, tb);

    useEffect(()=>{
        console.log('data  is', data )
    })

      if(fetchinData || !data?.auto )
        return <BackdropScI />
    return (
        <Stocks
        {...{
            data,
            fetchinData,
            fetchURL,
        }}/>
    )
}

   