import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import LatestSalesChart from "./LatestSales-chart";

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 200,
    position: "relative",
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const LatestSales = (props) => {
  const { className, data, fetching, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Typography
          style={{ paddingBottom: "5px" }}
          component="div"
          variant="h6"
        >
          مبيعات آخر 7 ايام
        </Typography>
        <Divider />
        <div className={classes.chartContainer}>
          {!data || fetching ? (
            <CircularProgress />
          ) : (
            <LatestSalesChart
              {...{
                data: data.length > 0 ? data : [{ total: 0 }],
              }}
            />
          )}
        </div>
      </CardContent>
      <Divider />
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string,
};

export default LatestSales;
