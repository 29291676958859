import 'date-fns';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {uri} from '../../../../help/Api';
import {sto} from '../../../../Context/InshData';

import { lighten,makeStyles } from '@material-ui/core/styles';
import dataF from '../../../../Server/dataF.json';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import CssBaseline from '@material-ui/core/CssBaseline';
import Filter9PlusIcon from '@material-ui/icons/Filter9Plus';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import AddCustomers from '../../../Customers/BackEnd/Tab1/AddCustomers';
// dilog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';


//s
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// Table 
import { forwardRef } from 'react';
// table modul
import InputAdornment  from '@material-ui/core/InputAdornment';
import Toolbar from '@material-ui/core/Toolbar';
import useTable from "../../../../componentsT/useTable";
//componentsT/useTable";

import Controls from "../../../../componentsT/controls/Controls";
import { Search } from "@material-ui/icons";
// End
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
// import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
// import Alert from '@material-ui/lab/Alert';

import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';

import Slide from '@material-ui/core/Slide';
import SaveIcon from '@material-ui/icons/Save';
import PostAddIcon from '@material-ui/icons/PostAdd';

import PrintIcon from '@material-ui/icons/Print';
import AddBoxIcon from '@material-ui/icons/AddBox';

// table serch inv
import PropTypes from 'prop-types';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
// groube
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { resolve } from 'source-map-resolve';


// Table
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Filter9PlusIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

let iindex=0
let thisindex=0
let indexlength=0
// let index_SUM=0
// let numbClick=-1
let postORput=''
let putjust=''
let postjust=''
let deletejust=''
let proc_id_Input=''

// defult select id
let customersDId=null
let stockDId=null
let safesDId=null
let unitsDId=null
let numbAddeshnal=null;
// in put select id
let cust_id_GPut=null
// let cust_name_GPut=null
let stock_GPut=null
let safe_GPut=null
let inv_type_GPut=null
// let inv_typeName_GPut=null
let salesman_GPut=null
let center_GPut=-1

// defult name id
// let customersDName='hi'
// let stockDName='hi'
// let safesDName='hi'
// let unitsDName='hi'

// Sum data table

let total_just_IS_Tot  //_ مجموع إجمالي المشتريات
let netsub_IS_Tot  //_ مجموع اجمالي الصافي او المدفوع
let discount_IS_Tot=0  //_ مجموع قيمة الخصم
let amountAfterDisc_IS_Tot  //_ مجموع إجمالي بعد الخصم
let amountBeforDisc_IS_Tot  //_ مجموع إجمالي قبل الخصم
let amountWithVat_IS_Tot  //_ مجموع إجنالي القيمة المضافة

//_Inv
let safe_G=null  //_ المخزن
let stock_G=null  //_ الندوق
let sales_emp_G= 1  //_ مندوب المبيعات
let tot_purch_G=0  //_ مجموع إجمالي المشتريات
let tot_sale_G= 0 //_ مجموع إجمالي المبيعات
let tot_net_G=0  //_ مجموع اجمالي الصافي او المدفوع
let tot_net_G_Show=0  //_ مجموع اجمالي الصافي او المدفوع
let minus_G= 0  //_ مجموع قيمة الخصم
let inv_type_G=2  //_ نوع الفاتورة اجل ام نقدي
let inv_type_G_N='نقدية'  //_ نوع الفاتورة اجل ام نقدي
let salesman_G= -1  //_ مندوب المبيعات
let tax_G= null  //_ الضريبه اي
let pay_type_G=1  //_ نوع البيع اي
let bank_G= -1  //_ حساب البنك
let bank_GPut= null  //_ حساب البنك
let refno_G= ""  //_ معلومات اي
let notes_G_I='' // ملاحظات
// let discAmount_G  //_ مجموع قيمة الخصم
let amountAfterDisc_G=0  //_ مجموع إجمالي بعد الخصم
let amountAfterDisc_G_Show=0  //_ مجموع إجمالي بعد الخصم
let amountWithVat_G=0 //_ مجموع إجمالي بعد الخصم
let amountOfVat_G_Show=0  //_ مجموع إجنالي القيمة المضافة
let amountOfVat_G=0  //_ مجموع إجنالي القيمة المضافة
let netAmount_G=0  //_ مجموع إجمالي الصافي
// let netAmount_G_Card=0
let center_G = -1  //_ مركز التكلفه اي
let cash_part_G=0  //_ الدفع اي
let agel_part_G= 0 //_ المتبقي
let bank2_G= -1  //_ البنك 2 اي
let emp_G= -1  //_ الموضف اي
let cust_name_G='عميل عام'
// let emp_G_N=null

// == الباقي ==
let cust_id_G=207 //_ قيد المراجعه اي1
let disc_G= 0 //_ قيد المراجعه اي2
let bank_part_G= 0 //_ قيد المراجعه اي4
let check_refno_G= "" //_ قيد المراجعه اي5
let network_part_G= 0 //_ قيد المراجعه اي6
let inv_post_lock_G= 1 //_ قيد المراجعه اي7
let used_payed_window_G= 1 //_ قيد المراجعه اي8
let bill_swith_no_G= 0 //_ قيد المراجعه اي9

// == الافتراضي ==
let branch_G= sto.branchi //_ فاتورة مبيعات اي
let proc_type_G_I= 1 //_ قيد المراجعه اي10
let purch_rest_id_G= 0 //_ قيد المراجعه اي11
let sale_rest_id_G= 0 //_ قيد المراجعه اي12
let IS_Buy_G= false //_ تم البيع اي
let IS_Deleted_G= false //_ تم الحذف اي
let switch_amount_IS= 0 //_ قيد المراجعه اي13
let tot_disc_switch_G= 0 //_ قيد المراجعه اي14
let paid_G= 0 //_ قيد المراجعه اي15
let Disc_saleOnItem_G= 1 //_ قيد المراجعه اي16
let emp_part_G= 0 //_ قيد المراجعه اي17
let ProcType_InvProc_G= "Q" //_ قيد المراجعه اي18
let amountBefordisc_G= 0 //_ قيد المراجعه اي19
let post_G= 0 //_ قيد المراجعه اي20
let BKproc_id_G= '!B1!C-1!T-1' //_ قيد المراجعه اي21

// == تلقائي ==

let id_G_I=1 //_ ال id
// let date_G= "2021-01-18T04:40:27.227" //_ تاريخ الفاتوره
// let create_date_G= "1/18/2021 4:40:27 AM" //_ تاريخ اضافة الفاتورة
// let update_date_G= "1/18/2021 4:40:27 AM" //_ تاريخ تعديل الفاتورة

// == علاقة ==
//_ id اي
let create_user_G=1 //_ id المستخدم اضافة الفاتورة
let update_user_G=1 //_ id المستخدم تعديل الفاتورة
// == اخرى ==
let length_I=null // InvView



//==============================================================================
//_Inv_Sub Declear To Lit Let
// السعر
let exchange_price_IS=0
// let all_exchange_price_IS=0
// let new_exchange_price_IS=0
//_  الكمية
let val_IS=1
// let val1_IS=1
// let new_val_IS=1
// let new_val1_IS=1
// let val_IS_D=1
//_ القيمة المضافة
let taxperc_IS=null
let new_taxperc_IS=null
//_ إجمالي قيمة الممضافة 
let taxval_IS=0
let new_taxval_IS=0
//_ قيمة الخصم
let discount_IS=0
let new_discount_IS=0
//_ خصم %
let DiscPers_IS=0
let new_DiscPers_IS=0
//_ الباركود
let barcode_IS=0
//_ الملاحضة
let notes_IS=''
// الوحدة
let unit_IS_name=''
let unit_IS_id=null
//_ id الصنف
let currency_from_IS=0

//_ == الباقي ==
let sub_center_IS=-1
let sub_branch_IS=sto.branchi
let sub_post_IS=0
let switch_amount2_IS= 0
let expire_date_IS=0
let new_expire_date_IS=0
let expense_IS=null
let srl_IS=null
//_ == الافتراضي ==
let proc_type_IS=2
let sub_BKproc_id_IS="!B1!C-1!T"
// let sub_BKproc_name_IS=""
// let sub_BKproc_id_IS_id_Val=""

//_ == تلقائي ==
//  let id_IS

//_ == علاقة ==
let proc_id_IS_C=0
let inv_Name='تقرير سند قبض';





//==============================================================================
//_Restrictions
let length_R=1
let Center_R=-1 //_ مركز التكلفة
let book_id_R=null // 
let notes_R='' //_
//_ == الافتراضي ==
let branch_R= sto.branchi //_ مشتريات
let IS_Deleted_R= false //_ تم الحذف
let state_R= 1 //_ الحالة
let type_R=7 //_ النوع
//_ == الباقي ==
//_ == تلقائي ==
let id_R=1 //_ ال Id
let doc_no_R=1 //_ قيد المراجعة
// let date_R ="" //_ تاريخ الاضافة
//_ == علاقة ==

//==============================================================================
//_Restrictions_Sub
let Center_RS= -1 //_ مركز التكلفة
let notes_RS1= 'فاتورة بيع' //_
let notes_RS2= 'فاتورة بيع' //_
let notes_RS3= 'فاتورة بيع' //_
let notes_RS4= 'فاتورة بيع' //_
let notes_RS5= 'فاتورة بيع' //_
//_ == الافتراضي ==
let branch_RS= sto.branchi //_ مشتريات
let ref_no_RS //_ رقم المرتجع
//_ == الباقي ==
// let dept_RS1=0
let dept_RS2=0
let dept_RS3=0
// let dept_RS4=0
let dept_RS5=0


let credit_RS1=0
// let credit_RS2=0
// let credit_RS3=0
let credit_RS4=0
// let credit_RS5=0

//_ == تلقائي ==
//_ == علاقة ==
let res_id_RS=null
let acc_no_RS1=1230001
let acc_no_RS2=4110001
let acc_no_RS3=4110005
let acc_no_RS4=1210001
let acc_no_RS5=1230001


//=====================================================================
let dataInv_Sub=[];
let data_Sum=[];
// let dataRestrictions_Sub1=[];
// let dataRestrictions_Sub2=[];
// let dataRestrictions_Sub3=[];
// let dataRestrictions_Sub4=[];
// let dataRestrictions_Sub5=[];
// let dataRestrictions=[];
let dataToR_Sub = [];


//const [customersDNameput, setcustomersDNameput] = useState();
// const [typeDNameput, setTypeDNameput] = useState(); 
let customersDNameput_RS=null;
let typeDNameput_RS=null;
let btnsave='حفظ',btnprint='معاينه';
let nevEve='always';


// const api = axios.create({
//   baseURL: `http://reqres.in/ap`
// })
// // End Table 



const useStyles = makeStyles((theme) => ({
  // serch
  root: {
    flexGrow: 1,
    // for Alert
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },

  paper: {
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperRadio: {
     paddingTop: theme.spacing(1),
     paddingBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  // end
  appBar: {
    position: 'fixed',
    bottom: '0',
    top:'unset'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
    root: {
    flexGrow: 1,
    display:'flex',
    textAlign:'center',
    textAlign:'-webkit-center',
    marginBottom: '20%',
  },
  paper: {
     padding: theme.spacing(1),
    // margin: theme.spacing(1),
    marginLeft:'1%',
    marginRight:'1%',

    display:'flex',
    textAlign: 'center',
    borderRadius: '5px',
    color: theme.palette.text.secondary,
    boxShadow: '0 0 20px 0px #0041b02b, 0px 1px 0px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 0%)'
  },
  paperTxtUp: {
    padding: theme.spacing(1),
   // margin: theme.spacing(1),
   marginLeft:'1%',
   marginRight:'1%',

   display:'flex',
   textAlign: 'center',
   borderRadius: '5px',
   color: theme.palette.text.secondary,
   marginBottom:'1%',
  // boxShadow: '0 0 20px 0px #0041b02b, 0px 1px 0px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 0%)'
 },
 paperTxtDown: {
  padding: theme.spacing(1),
 // margin: theme.spacing(1),
 marginLeft:'1%',
 marginRight:'1%',

 display:'flex',
 textAlign: 'center',
 borderRadius: '5px',
 color: theme.palette.text.secondary,
 marginBottom:60,
// boxShadow: '0 0 20px 0px #0041b02b, 0px 1px 0px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 0%)'
},
  paperT: {
    direction:"rtl"
  },
  iitem:{
    marginLeft:'1%',
    marginRight:'1%',
  },
  iitemL:{
    marginTop:'1%',
  },
  iitemS:{
    height:'20px'
  },
  iitemP:{
    padding: '0px',
    boxShadow: '0 0 20px 0px #0041b000, 0px 1px 0px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 0%);'
  },
  iitemC:{
    
    // margin: theme.spacing(1),
    marginLeft:'1%',
    marginRight:'1%',
    marginBottom: '12%',
    display:'flex',
    textAlign: 'center',
    borderRadius: '5px',
    color: theme.palette.text.secondary,
    boxShadow: '0 0 20px 0px #0041b000, 0px 1px 0px 0px rgb(0 0 0 / 0%), 0px 1px 0px 0px rgb(0 0 0 / 0%);'
  },
  pageContent: {
    margin: theme.spacing(1),
    // padding: theme.spacing(1)
},
searchInput: {
    width: '100%'
},
codsntainer: {
  width: '100%'
},
// card
media: {
  height: 140,
},
appBarDS: {
  position: 'fixed',
  bottom: '0px',
  top: 'unset',
 backgroundColor: '#dfdfdf',
},
titleDS: {
  marginLeft: theme.spacing(2),
  flex: 1,
},
marginDS:{
  color: 'navy',
  left: '25%',
  bottom: '25px',
},
table: {
  // minWidth: 650,
  minWidth: 750,
},
tableRoot: {
  height: '65%',
  // border:'solid',
  marginBottom:10,
  },


}));
const radsty = {
  border: 'solid 1px #c9c9c9',
    paddingLeft: '1%',
    borderRadius: '4px',
    // width: '62%',
    width: '99%',
}

const radstygrd = {
  padding: '0px'
}
const stybtn = {
  padding: '7px 7px',
}


//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ Do ^

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const TransitionDS = React.forwardRef(function TransitionDS(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});




// ============ start for table up export serch 

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'notes', numeric: true, disablePadding: false, label: 'البيان' },
  { id: 'Val', numeric: true, disablePadding: false, label: 'القيمة' },
  { id: 'date', numeric: true, disablePadding: false, label: 'تاريخ السند' },
  { id: 'id', numeric: false, disablePadding: false, label: 'رقم السند' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

return (
  <TableHead>
    <TableRow>
      {/* <TableCell padding="checkbox">
         <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          inputProps={{ 'aria-label': 'select all desserts' }}
        /> 
      </TableCell> */}
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          // align={headCell.numeric ? 'right' : 'left'}
          align={'center'}
          padding={headCell.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                {order === 'desc' ? '' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
}

EnhancedTableHead.propTypes = {
classes: PropTypes.object.isRequired,
numSelected: PropTypes.number.isRequired,
onRequestSort: PropTypes.func.isRequired,
onSelectAllClick: PropTypes.func.isRequired,
order: PropTypes.oneOf(['asc', 'desc']).isRequired,
orderBy: PropTypes.string.isRequired,
rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar>
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
         سندات القبض المتعدده
        </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// ============ End for table up export serch 


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let lastIdInvLet=null;
let lastidr=null;
let todaydate = '2017-05-24'


export default function MultiQbd(){

  const classes = useStyles();
  const [openDS, setOpenDS] = React.useState(false);

  let nl=null;
  const [riv, setRiv] = useState({Ne:nl,Sa:nl,De:nl,Se:nl,Pr:nl,Up:nl});

// const btnser = {background: 'lightgray'}

  const [lblAuto, setLblAuto] = React.useState('الصندوق');


const [multiQ, setMultiQ] = useState({Val: 0,Type: 1,safe_bank_id: null,sales_emp: -1,check_no: null,
check_date: null,//moment(new Date()).format('YYYY-MM-DD'),
branch: sto.branchi,center: -1, date: moment(new Date()).format('YYYY-MM-DD'), omla: -1,omlaval: 1,taxperc: null,taxval: 0,notes:null});

// const [multiR, setMultiR] = useState({"Val": 0,"Type": 1,"safe_bank_id": null,"sales_emp": -1,"check_no": null,
// "check_date": null,"branch": null,"center": -1,"omla": -1,"omlaval": 1,"taxperc": 0,"taxval": 0,"notes":null});

const [multiR, setMultiR] = useState({});




  // for custs
  const [isOpenCusts, setIsOpenCusts] = useState(false);
  const handleOpenCusts = () => { setIsOpenCusts(!isOpenCusts); }; // E OM


  const locSham = JSON.parse(window.localStorage.getItem('dataShamel')) || {};
  tax_G=locSham.chose

// ============ start for table serch 

const [order, setOrder] = React.useState('desc');
const [orderBy, setOrderBy] = React.useState('id');
const [selected, setSelected] = React.useState([]);
const [page, setPage] = React.useState(0);
const [dense, setDense] = React.useState(false);
const [rowsPerPage, setRowsPerPage] = React.useState(10);

// const [customersDNameput, setcustomersDNameput] = useState();
// const [typeDNameput, setTypeDNameput] = useState(); 

const [dataInvSearsh, setDataInvSearsh] = useState([]);
const GetDataTS = async () => {
  // await axios.get(uri+`View_Inv_SearshIn`).then(res => { 
    // console.log(uri+'MulitiQV'+`?sdate=${dateToSInvOM}&&edate=${dateFromSInvOM}`)
    await axios.get(uri+'MulitiQV'+`?sdate=${dateToSInvOM}&&edate=${dateFromSInvOM}`).then(res => { 
    
    setDataInvSearsh(res.data);
  }).catch(error => { })
};  

//const [todaydate, setTodaydate] = React.useState('');
useEffect( async() => {
  GetDataTS(); 

 
  const locSham = JSON.parse(window.localStorage.getItem('dataShamel')) || {};
  tax_G=locSham.chose

  taxperc_IS=locSham.chose
  new_taxperc_IS=locSham.chose

  setMultiQ({...multiQ, Type:parseInt(valuechk)});

  axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[72].id}`).then(res => {
    setRiv({...riv, Ne:res.data.IS_New,Sa:res.data.IS_Save,De:res.data.IS_Delete,Se:res.data.IS_Search,Pr:res.data.IS_Print,Up:res.data.IS_Update});
    if(res.data.IS_Update===false){nevEve='never'} 
   }).catch(error => {  })

   // (new Date()).format('YYYY-MM-DD')
 
   // value={todaydate} 
   // moment()
  //  defaultValue="2017-05-24"
// console.log('moment(new Date()).format(YYYY-MM-DD)')

todaydate = moment(new Date()).format('YYYY-MM-DD')

// console.log(todaydate)

}, []);

const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

let inv_put_id
const getrowsDataCurrencies = async (event,rest_id, id) => {

  // console.log('-----------------------')
  // console.log(event+' , '+rest_id+' , '+ id)
  putjust='put';
  postORput='put'; 
  deletejust='delete';
  proc_id_Input='put'; 
  inv_put_id=rest_id   

  todateInv(id);
  loadLastInvId(id);

  handleCloseDS();
  handleRowAdd();

  getidSelectInPut_ForName();

 

  setRiv({...riv,Sa:false });


};

// == date ==
// const handleDateChange = (date) => {
  
//   setDateInv(date);
  

//  // onChange={(e) => {setMultiQ({...multiQ, notes:e.target.value });}}
// };

// datetime p
const [dateInv, setDateInv] = React.useState(new Date());

const todateInv = async (id) => {

  let leng=0;

      await axios.get(uri+'View_Inv_Searsh_In'+`/`+'02').then(res => {
      for (let index = 0; index < res.data.length; index++) {
        if (res.data[index].ProcType_InvProc === "02") {
          leng++
          // alert(leng);
        }
      }
    // alert(JSON.stringify(leng +' = leng  | ' + id +' = id' , null, 2));

      if(leng === 1){
        // alert('in if');
        setDateInv(dataInvSearsh[id].date);
      }
      else if(leng > 1){ 
        // alert('in else');
        setDateInv(dataInvSearsh[id-1].date);
      }
      }).catch(error => { })

    // alert(dataInvSearsh[id-1].date);
    // setDateInv(dataInvSearsh[id-1].date);
};


// for show id inv in text box
const [lastIdInv, setLastIdInv] = useState();  
const GetLastInvId = async () => { 
  
  await axios.get(uri+'MultiQs'+`/count`).then(res => {
   lastIdInvLet=res.data-1;
    setLastIdInv(res.data); 
    }).catch(error => { })
};  

const loadLastInvId = async (id) => { 
  setLastIdInv(id);
};  

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataInvSearsh.length - page * rowsPerPage);

  React.useEffect(() => { 

  

    const GetData = async () => {  

     await axios.get(uri+'accounts-index/getForSearch').then(res => { setDataCurrencies(res.data); }).catch(error => { })
   await axios.get(uri+'Types').then(res => { setOptionsType(res.data); }).catch(error => { })
    await axios.get(uri+'Safes').then(res => { setOptionsSafes(res.data); }).catch(error => { })
    await axios.get(uri+'Customers'+'/type?type=1').then(res => { setOptionsCustomers(res.data); }).catch(error => { })
    await axios.get(uri+'users').then(res => { setOptionsSalesmen(res.data); }).catch(error => { })
  await axios.get(uri+'Costcenter').then(res => { setOptionsCostcenter(res.data); }).catch(error => { })
  await axios.get(uri+'Stock'+'?id='+sto.empi+'&&branch='+sto.branchi)
  .then(res => { setOptionsStock(res.data); }).catch(error => { })
  await axios.get(uri+'bank').then(res => { setOptionsBank(res.data); }).catch(error => { })
  await axios.get(uri+'Units').then(res => { setOptionsUnits(res.data); }).catch(error => { })
  //await axios.get(uri+'Inv').then(res => { setOptionsInv(res.data); }).catch(error => { })
  await axios.get(uri+'Restrictions').then(res => { setOptionsRestrictions(res.data); }).catch(error => { })
    await axios.get(uri+'accounts-index/getForSearch').then(res => { setRecords(res.data); }).catch(err => { })
    // await axios.get(uri+'Costcenter').then(res => { setRecords(res.data); }).catch(err => { })


    };  
    GetData();  
   GetLastInvId();

  }, []);

 




  // >>>>>>>>>>> Dilog from tabele C
  // ============================== table  for currencies
const [dataCurrencies, setDataCurrencies] = useState([]);  

const [records, setRecords] = useState([])
const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })

  // >>>>>>>>>>> table
const [dataInv1, setOptionsInv] = useState([]);  

const [dataRestrictions, setOptionsRestrictions] = useState([]);  
const [dataRestrictionsView, setRestrictionsView] = useState([]);  
const [dataInvViewId, setInvViewId] = useState([]);  
const [dataInvViewProcId, setInvViewProcId] = useState([]);  
const [dataInvRows, setInvRows] = useState([]);  

  // >>>>>>>>>>> Select
  //Type 
  const [optionsType, setOptionsType] = React.useState([]);
  const [valueType, setValueType] = React.useState([]);
  
  //Safes 
  const [optionsSafes, setOptionsSafes] = React.useState([]);
  const [valueSafes, setValueSafes] = React.useState([]);
  //Customers
  const [optionsCustomers, setOptionsCustomers] = React.useState([]);
  const [valueCustomers, setValueCustomers] = React.useState([]);
  //Salesmen
  const [optionsSalesmen, setOptionsSalesmen] = React.useState([]);
  const [valueSalesmen, setValueSalesmen] = React.useState([]);
  //Costcenter
  const [optionsCostcenter, setOptionsCostcenter] = React.useState([]);
  const [valueCostcenter, setValueCostcenter] = React.useState([]);
  //Stock
  const [optionsStock, setOptionsStock] = React.useState([]);
  const [valueStock, setValueStock] = React.useState([]);
  const [sandDate, setSandDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  //Bank
  const [optionsBank, setOptionsBank] = React.useState([]);
  const [valueBank, setValueBank] = React.useState([]);
  //Units
  const [optionsUnits, setOptionsUnits] = React.useState([]);
  const [valueUnits, setValueUnits] = React.useState([]);

 //>>>>>>>>>>>>>>> Text
 const [valueNotes, setValueNotes] = React.useState([]);




  
// ================================================ dilog
const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
   getDemo();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  

  //func
const getDemo = () => {

  // console.log('==============')
// console.log(dataInv)
if(dataInv1.length > 1){
  id_G_I=dataInv1[0].id;
  // console.log(id_G_I)
}

}


  // get info data from row
  const [netAmount_G_Card, setnetAmount_G_Card] = useState(0);
  const [agel_part_G_Card, setagel_part_G_Card] = useState(0);
  const [chsh_part_G_Fild, setchsh_part_G_Fild] = useState(0);



const handleCangeChsh_part = e =>{
  let allSumWChange=0

  setchsh_part_G_Fild(e.target.value);
  if(valueType.id === 1){ 
    allSumWChange = chsh_part_G_Fild - netAmount_G_Card
    setagel_part_G_Card(chsh_part_G_Fild - netAmount_G_Card)
  }
}


// start
  const getAllNetWithNaqde= () =>{
    let allSum=0
    // alert(thisindex);
   //_ مجموع إجمالي الصافي عند النقدي
    for (let a = 0; a<thisindex; a++)
    { allSum+=dataToSum[a].netsubS }

     setnetAmount_G_Card(allSum)

   //_ مجموع إجمالي المتبقي عند الاجل
     if(valueType.id === 1){ setagel_part_G_Card(allSum) }

  }


// == end


    const  getrowDataCurrencies = async (index) => {
      // alert(index);
       iindex=index-1
      // await getIdLevelPost();
      await getidFirstSelect();
      await getIdSelect();
      await handleClose();
      if(customersDId === 1 && stockDId === 1 && unitsDId === 1 && safesDId === 1){
        postjust='post'; 
        postORput='post'; 
        await   handleClose();

        await  handleRowAdd();
        //await getAllNetWithNaqde();
      } 
    };  

    const  getrowDataCurrenciesDouble = async (index) => {  

alert('لقد قمت بإجراء خاطئ وذلك بالنقر ضغطتين متتالتين سيقوم النظام بالعودة للصفحة الرئيسية');
      refreshPage();
    };  

    
// ========================  fillltring

const headCells = [
 
  
  { id: 'barcode', label: 'كود الحساب', minWidth: 170 },
  { id: 'symbol', label: 'اسم الحساب', minWidth: 170 },
  // { id: 'Load', label: 'تنزيل', disableSorting: true },
];

const {
  TblContainer,
  TblHead,
  recordsAfterPagingAndSorting
} = useTable(records, headCells, filterFn);

const handleSearch = e => {

  let target = e.target;
  setFilterFn({
      fn: items => {
          if (target.value == "")
              return items;
          else
              return items.filter(
                x =>   x.barcode.toString().toLowerCase().includes(target.value)  || x.symbol.toLowerCase().includes(target.value) 
                )
      } })
  
    }

// ================================================ End dilog

// ================================================================ Inv
// inv /inv
const [Inv, setInv] = useState([{

//_ المخزن
safe: safe_G,
//_ الندوق
stock: stock_G,
//_ قيد المراجعه اي1
cust_id: cust_id_G,
//_ مندوب المبيعات
sales_emp: sales_emp_G,
//_ مندوب المبيعات
salesman: salesman_G,
customer_name:cust_name_G,
//_ نوع الفاتورة اجل ام نقدي
inv_type: inv_type_G,
//_ نوع البيع اي
pay_type: pay_type_G,
//_ حساب البنك
bank: bank_G,
//_ البنك 2 اي
bank2: bank2_G,
//_ مركز التكلفه اي
center:center_G,
//_ ملاحضات اي
notes: notes_G_I,
//_ معلومات اي
refno: refno_G,
//_ الضريبه اي
tax: tax_G,
//_ مجموع إجمالي المشتريات
tot_purch:tot_purch_G,
//_ مجموع إجمالي المبيعات
tot_sale: tot_sale_G,
//_ مجموع اجمالي الصافي او المدفوع
tot_net:tot_net_G,
//_ مجموع إجمالي بعد الخصم
amountAfterDisc:amountAfterDisc_G,
//_ مجموع إجمالي بعد الخصم
//amountWithVat:  amountWithVat_G,
// مؤقت
amountWithVat:amountAfterDisc_G,
//_ مجموع إجنالي القيمة المضافة
amountOfVat:amountOfVat_G,
//_ مجموع إجمالي الصافي
netAmount:netAmount_G,
amountBefordisc:amountBefordisc_G,
//_ مجموع قيمة الخصم
minus:minus_G,
//_ مجموع قيمة الخصم
//discAmount: discAmount_G,
discAmount:minus_G,

// == الافتراضي ==
//_ فاتورة مبيعات اي
branch: branch_G,
//_ قيد المراجعه اي10
proc_type: proc_type_G_I,
//_ قيد المراجعه اي15
paid: paid_G,
//_ قيد المراجعه اي18
ProcType_InvProc: ProcType_InvProc_G,
//_ قيد المراجعه اي20
post: post_G,
//_ تم البيع اي
IS_Buy: IS_Buy_G,
  //_ تم الحذف اي
IS_Deleted: IS_Deleted_G,
//_ قيد المراجعه اي13
switch_amount: switch_amount_IS,
//_ قيد المراجعه اي14
tot_disc_switch: tot_disc_switch_G,
//_ قيد المراجعه اي16
Disc_saleOnItem: Disc_saleOnItem_G,
//_ قيد المراجعه اي21
BKproc_id: BKproc_id_G,
//_ قيد المراجعه اي11
purch_rest_id: purch_rest_id_G,
//_ قيد المراجعه اي12
sale_rest_id: sale_rest_id_G,
//_ قيد المراجعه اي17
emp_part: emp_part_G,

// == الباقي ==
//_ الدفع اي
cash_part: cash_part_G,
//_ قيد المراجعه اي2
disc: disc_G,
//_ قيد المراجعه اي3
agel_part: agel_part_G,
//_ قيد المراجعه اي4
bank_part: bank_part_G,
//_ الموضف اي
emp: emp_G,
//_ قيد المراجعه اي5
check_refno: check_refno_G,
//_ قيد المراجعه اي6
network_part: network_part_G,
//_ قيد المراجعه اي7
inv_post_lock: inv_post_lock_G,
//_ قيد المراجعه اي8
used_payed_window: used_payed_window_G,
//_ قيد المراجعه اي9
bill_swith_no: bill_swith_no_G,

// == تلقائي ==
//_ ال id
   id: id_G_I,
//_ تاريخ الفاتوره
  // >> date: date_G,
//_ تاريخ اضافة الفاتورة
  // >> create_date: create_date_G,
//_ تاريخ تعديل الفاتورة
  // >> update_date: update_date_G,

// == علاقة ==
//_ id اي
  // proc_id: proc_id_IS_C,
//_ id المستخدم اضافة الفاتورة
  create_user: create_user_G,
//_ id المستخدم تعديل الفاتورة
  update_user: update_user_G,
  

}]); 


let newInv
const AddInv = () => {
  const newClicksInv = [...Inv];
  newInv = {...newClicksInv};
  setInv(newClicksInv);

  //_ المخزن
  newInv.safe=safe_G
//newInv.safe= valueSafes.id
//_ الندوق
newInv.stock=stock_G
//newInv.stock= valueStock.id
//_ قيد المراجعه اي1
newInv.cust_id=cust_id_G
//newInv.cust_id= valueCustomers.id
//_ مندوب المبيعات
newInv.sales_emp=sales_emp_G
//newInv.sales_emp= valueSalesmen.id
//_ مندوب المبيعات
newInv.salesman=salesman_G
//newInv.salesman=valueSalesmen.id
newInv.customer_name=cust_name_G
//_ نوع الفاتورة اجل ام نقدي
newInv.inv_type=inv_type_G
//_ نوع البيع اي
newInv.pay_type=pay_type_G
//newInv.pay_type= valueType.id
//_ حساب البنك
newInv.bank=bank_G
//newInv.bank= valueBank.acc_no
//_ البنك 2 اي
newInv.bank2=bank2_G
//newInv.bank2= valueBank.acc_no
//_ مركز التكلفه اي
newInv.center=center_G
//newInv.center=valueCostcenter.id
//_ ملاحضات اي
newInv.notes=notes_G_I
//newInv.notes=newClicksInv.notes
//_ معلومات اي
newInv.refno=refno_G
//newInv.refno=newClicksInv.refno
//_ الضريبه اي
newInv.tax=tax_G
//newInv.tax=newClicksInv.tax
//_ مجموع إجمالي المشتريات
newInv.tot_purch=tot_purch_G
//_ مجموع اجمالي الصافي او المدفوع
newInv.tot_net=tot_net_G
//_ مجموع إجمالي بعد الخصم
newInv.amountAfterDisc=amountAfterDisc_G
//_ مجموع إجمالي بعد الخصم
//amountWithVat=  amountWithVat_G
// مؤقت
newInv.amountWithVat=amountAfterDisc_G
//_ مجموع إجنالي القيمة المضافة
newInv.amountOfVat=amountOfVat_G
//_ مجموع إجمالي الصافي
newInv.netAmount=netAmount_G
//_ مجموع قيمة الخصم
newInv.minus=minus_G
//_ مجموع قيمة الخصم
//discAmount= discAmount_G
newInv.discAmount=minus_G
// == الافتراضي ==
//_ فاتورة مبيعات اي
newInv.branch=branch_G
//_ قيد المراجعه اي10
newInv.proc_type=proc_type_G_I
//_ مجموع إجمالي المبيعات
newInv.tot_sale=tot_sale_G
//_ قيد المراجعه اي15
newInv.paid=paid_G
//_ قيد المراجعه اي19
newInv.amountBefordisc=amountBefordisc_G
//_ قيد المراجعه اي18
newInv.ProcType_InvProc=ProcType_InvProc_G
//_ قيد المراجعه اي20
newInv.post=post_G
//_ تم البيع اي
newInv.IS_Buy=IS_Buy_G
  //_ تم الحذف اي
newInv.IS_Deleted=IS_Deleted_G
//_ قيد المراجعه اي13
newInv.switch_amount=switch_amount_IS
//_ قيد المراجعه اي14
newInv.tot_disc_switch=tot_disc_switch_G
//_ قيد المراجعه اي16
newInv.Disc_saleOnItem=Disc_saleOnItem_G
//_ قيد المراجعه اي21
newInv.BKproc_id=BKproc_id_G
//_ قيد المراجعه اي12
newInv.sale_rest_id=sale_rest_id_G
//_ قيد المراجعه اي17
newInv.emp_part=emp_part_G

// == الباقي ==
//_ الدفع اي
newInv.cash_part=cash_part_G
//_ قيد المراجعه اي2
newInv.disc=disc_G
//_ قيد المراجعه اي3
newInv.agel_part=agel_part_G
//_ قيد المراجعه اي4
newInv.bank_part=bank_part_G
//_ الموضف اي
newInv.emp=emp_G
//_ قيد المراجعه اي5
newInv.check_refno=check_refno_G
//_ قيد المراجعه اي6
newInv.network_part=network_part_G
//_ قيد المراجعه اي7
newInv.inv_post_lock=inv_post_lock_G
//_ قيد المراجعه اي8
newInv.used_payed_window=used_payed_window_G
//_ قيد المراجعه اي9
newInv.bill_swith_no=bill_swith_no_G

// == تلقائي ==
//_ ال id
// newInv.proc_id=proc_id_IS_C

//_ تاريخ الفاتوره
  // >> date= date_G
//_ تاريخ اضافة الفاتورة
  // >> create_date= create_date_G
//_ تاريخ تعديل الفاتورة
  // >> update_date= update_date_G

// == علاقة ==
newInv.id=length_I
//_ id من جدول R
newInv.purch_rest_id=purch_rest_id_G
newInv.sale_rest_id=length_R

//_ id المستخدم اضافة الفاتورة
newInv.create_user=create_user_G
//_ id المستخدم تعديل الفاتورة
newInv.update_user=update_user_G
  
// alert('newInv.tot_net')
// alert(newInv.tot_net)

  };


//==================================================================== Inv_Sub
// inv_ٍSub /sub
const [Inv_Sub, setInv_Sub] = useState([]); 
let dataToInv_Sub = [...Inv_Sub];

// post inv_ٍSub /psub
const AddInv_Sub = () => {  
  // e.preventDefault();



  dataInv_Sub = {
      //_ السعر
      exchange_price:exchange_price_IS,
      all_exchange_price:exchange_price_IS,
      new_exchange_price:exchange_price_IS,
      //_ علاقة
      proc_id: proc_id_IS_C,
      //_  الكمية
      val:val_IS,
      val1:val_IS,
      new_val:val_IS,
      new_val1:val_IS,
      //_ القيمة المضافة
      taxperc:taxperc_IS,
      new_taxperc: new_taxperc_IS,
      //_ إجمالي قيمة الممضافة 
      taxval:taxval_IS,
      new_taxval:new_taxval_IS,
      //_ قيمة الخصم
      discount:discount_IS,
      new_discount:new_discount_IS,
      //_ خصم %
      DiscPers:DiscPers_IS,
      new_DiscPers: new_DiscPers_IS,
      //_ الباركود
      barcode:barcode_IS,
      //_ الملاحضة
      notes:notes_IS,
      // الوحدة
      unit: unit_IS_id,
      //_ id الصنف
      currency_from:currency_from_IS,
      //_ إسم الصنف
      //symbol:symbol_G,

      //_ الباقي
      sub_center: sub_center_IS,
      sub_branch: sub_branch_IS,
      sub_post: sub_post_IS,
      expire_date:expire_date_IS,
      new_expire_date:new_expire_date_IS,
      expense:expense_IS,
      switch_amount:switch_amount_IS,
      switch_amount2:switch_amount2_IS,
      srl:srl_IS,
      //_ الافتراضي
      proc_type: proc_type_IS,
      sub_BKproc_id: sub_BKproc_id_IS,

  };



  dataToInv_Sub.push(dataInv_Sub);
  setInv_Sub(dataToInv_Sub);


}



// pub inv_ٍSub /bsub
const UpdateInv_Sub = (e) => {  
  // e.preventDefault(); 
  setInv_Sub([...dataToInv_Sub]);

    dataInv_Sub = {
            //_ السعر
            exchange_price:exchange_price_IS,
            all_exchange_price:exchange_price_IS,
            new_exchange_price:exchange_price_IS,
            //_  الكمية
            val:val_IS,
            val1:val_IS,
            new_val:val_IS,
            new_val1:val_IS,
            //_ القيمة المضافة
            taxperc:taxperc_IS,
            new_taxperc: new_taxperc_IS,
            //_ إجمالي قيمة الممضافة 
            taxval:taxval_IS,
            new_taxval:new_taxval_IS,
            //_ قيمة الخصم
            discount:discount_IS,
            new_discount:new_discount_IS,
            //_ خصم %
            DiscPers:DiscPers_IS,
            new_DiscPers: new_DiscPers_IS,
            //_ الباركود
            barcode:barcode_IS,
            //_ الملاحضة
            notes:notes_IS,
            // الوحدة
            unit: unit_IS_id,
            //_ id الصنف
            currency_from:currency_from_IS,
            //_ إسم الصنف
            //symbol:symbol_G,
            //_ علاقة
            proc_id: proc_id_IS_C,
            //_ الباقي
            sub_center: sub_center_IS,
            sub_branch: sub_branch_IS,
            sub_post: sub_post_IS,
            expire_date:expire_date_IS,
            new_expire_date:new_expire_date_IS,
            expense:expense_IS,
            switch_amount:switch_amount_IS,
            switch_amount2:switch_amount2_IS,
            srl:srl_IS,
            //_ الافتراضي
            proc_type: proc_type_IS,
            sub_BKproc_id: sub_BKproc_id_IS,
  };


  // console.log('dataToInv_Sub in update ');
  // console.log(dataToInv_Sub);


}

//======================================================================= Restrictions

// Restrictions /Restrictions
const [Restrictions, setRestrictions] = useState([{ 

Center: Center_R,  //_ مركز التكلفة
book_id: book_id_R,
notes: notes_R ,  //_
//_ == الافتراضي ==
branch: branch_R ,  //_ مشتريات
IS_Deleted: IS_Deleted_R ,  //_ تم الحذف
state: state_R ,  //_ الحالة
type: type_R ,  //_ النوع
//_ == الباقي ==
//_ == تلقائي ==
id: id_R , //_ ال Id
doc_no: doc_no_R , //_ قيد المراجعة
// date: date_R ,  //_ تاريخ الاضافة
//_ == علاقة ==

}]); 

let newRestrictions
const AddRestrictions = () => {
  const newClicks = [...Restrictions];
  newRestrictions = { ...newClicks };
  setRestrictions(newClicks);


newRestrictions.Center= Center_R //_ مركز التكلفة
newRestrictions.notes= notes_R //_
//_ == الافتراضي ==
newRestrictions.branch=branch_R   //_ مشتريات
newRestrictions.IS_Deleted=IS_Deleted_R   //_ تم الحذف
newRestrictions.state=state_R   //_ الحالة
newRestrictions.type=type_R   //_ النوع
//_ == الباقي ==
newRestrictions.book_id=book_id_R
//_ == تلقائي ==
newRestrictions.id=id_R //_ ال Id
newRestrictions.doc_no=doc_no_R //_ قيد المراجعة
// date: date_R ,  //_ تاريخ الاضافة
//_ == علاقة ==

    // console.log('newVote 2')
};




//======================================================================= Restrictions_Sub1

// Restrictions_Sub1 /Restrictions_Sub1
const [Restrictions_Sub1, setRestrictions_Sub1] = useState([{

  //_Restrictions_Sub
  Center: Center_RS,//_ مركز التكلفة
  notes: notes_RS1,  //_
//_ == الافتراضي ==
  branch: branch_RS,  //_ مشتريات
  ref_no: ref_no_RS,  //_ رقم المرتجع
//_ == الباقي ==
  //dept: dept_RS1, 
  //credit: tot_purch_G,
   
  dept: tot_net_G, 
  credit: credit_RS1, 
  
//_ == تلقائي ==
//_ == علاقة ==
  res_id: res_id_RS, 
  acc_no: acc_no_RS1, 

}]); 

let newRestrictions_Sub1
const AddRestrictions_Sub1 = () => {
const newClicks = [...Restrictions_Sub1];
newRestrictions_Sub1 = { ...newClicks };
setRestrictions_Sub1(newClicks);
// console.log(tot_net_G)

newRestrictions_Sub1.Center= Center_RS //_ مركز التكلفة
newRestrictions_Sub1.notes= notes_RS1 //_
//_ == الافتراضي ==
newRestrictions_Sub1.branch= branch_RS //_ مشتريات
newRestrictions_Sub1.ref_no= ref_no_RS  //_ رقم المرتجع
//_ == الباقي ==
//newRestrictions_Sub1.dept= dept_RS1
// newRestrictions_Sub1.credit= tot_net_G

newRestrictions_Sub1.dept= tot_net_G
newRestrictions_Sub1.credit= credit_RS1
//_ == تلقائي ==
//_ == علاقة ==
newRestrictions_Sub1.res_id= res_id_RS 
newRestrictions_Sub1.acc_no= acc_no_RS1

// console.log('newVote 1')
//  console.log(newRestrictions_Sub1)
};


//======================================================================= Restrictions_Sub2

const [Restrictions_Sub2, setRestrictions_Sub2] = useState([{

//_Restrictions_Sub
  Center: Center_RS,//_ مركز التكلفة
  notes: notes_RS2,  //_
//_ == الافتراضي ==
  branch: branch_RS,  //_ مشتريات
  ref_no: ref_no_RS,  //_ رقم المرتجع
//_ == الباقي ==
 // dept: amountOfVat_G, 
  //credit: credit_RS2, 

  dept: dept_RS2, 
  credit: amountAfterDisc_G, 
//_ == تلقائي ==
//_ == علاقة ==
  res_id: res_id_RS, 
  acc_no: acc_no_RS2, 
  }],); 

  let newRestrictions_Sub2
const AddRestrictions_Sub2 = () => {
  const newClicks = [...Restrictions_Sub2];
  newRestrictions_Sub2 = { ...newClicks };
  setRestrictions_Sub2(newClicks);
  // console.log(amountAfterDisc_G)

  newRestrictions_Sub2.Center= Center_RS //_ مركز التكلفة
  newRestrictions_Sub2.notes= notes_RS2 //_
  //_ == الافتراضي ==
  newRestrictions_Sub2.branch= branch_RS //_ مشتريات
  newRestrictions_Sub2.ref_no= ref_no_RS  //_ رقم المرتجع
  //_ == الباقي ==
  //newRestrictions_Sub2.dept= amountOfVat_G
  //newRestrictions_Sub2.credit= credit_RS2
  newRestrictions_Sub2.dept= dept_RS2
  newRestrictions_Sub2.credit= amountAfterDisc_G
  //_ == تلقائي ==
  //_ == علاقة ==
  newRestrictions_Sub2.res_id= res_id_RS 
  newRestrictions_Sub2.acc_no= acc_no_RS2

    // console.log('newVote 2')
    // console.log(newRestrictions_Sub2)
  };

//======================================================================= END FUNC TIBLE



//======================================================================= Restrictions_Sub3

const [Restrictions_Sub3, setRestrictions_Sub3] = useState([{

//_Restrictions_Sub
  Center: Center_RS,//_ مركز التكلفة
  notes: notes_RS3,  //_
//_ == الافتراضي ==
  branch: branch_RS,  //_ مشتريات
  ref_no: ref_no_RS,  //_ رقم المرتجع
//_ == الباقي ==
 // dept: tot_net_G, 
 // credit: credit_RS2, 

  dept: dept_RS3, 
  credit: amountOfVat_G, 
//_ == تلقائي ==
//_ == علاقة ==
  res_id: res_id_RS, 
  acc_no: acc_no_RS3  , 
  }],); 

  let newRestrictions_Sub3
const AddRestrictions_Sub3 = () => {
  const newClicks = [...Restrictions_Sub3];
  newRestrictions_Sub3 = { ...newClicks };
  setRestrictions_Sub3(newClicks);
  // console.log(amountOfVat_G)

  newRestrictions_Sub3.Center= Center_RS //_ مركز التكلفة
  newRestrictions_Sub3.notes= notes_RS3 //_
  //_ == الافتراضي ==
  newRestrictions_Sub3.branch= branch_RS //_ مشتريات
  newRestrictions_Sub3.ref_no= ref_no_RS  //_ رقم المرتجع
  //_ == الباقي ==
  newRestrictions_Sub3.dept= dept_RS3
  newRestrictions_Sub3.credit= amountOfVat_G
  //_ == تلقائي ==
  //_ == علاقة ==
  newRestrictions_Sub3.res_id= res_id_RS 
  newRestrictions_Sub3.acc_no= acc_no_RS3

    // console.log('newVote 2')
    // console.log(newRestrictions_Sub2)
  };

//======================================================================= END FUNC TIBLE 3


//======================================================================= Restrictions_Sub4

const [Restrictions_Sub4, setRestrictions_Sub4] = useState([{

  //_Restrictions_Sub
  Center: Center_RS,//_ مركز التكلفة
  notes: notes_RS4,  //_
//_ == الافتراضي ==
  branch: branch_RS,  //_ مشتريات
  ref_no: ref_no_RS,  //_ رقم المرتجع
//_ == الباقي ==
  //dept: dept_RS4, 
  //credit: tot_net_G, 
  dept: tot_net_G, 
  credit: credit_RS4, 
//_ == تلقائي ==
//_ == علاقة ==
  res_id: res_id_RS, 
  acc_no: acc_no_RS4, 

}]); 

let newRestrictions_Sub4
const AddRestrictions_Sub4 = () => {
const newClicks = [...Restrictions_Sub4];
newRestrictions_Sub4 = { ...newClicks };
setRestrictions_Sub4(newClicks);
// console.log(tot_net_G)

newRestrictions_Sub4.Center= Center_RS //_ مركز التكلفة
newRestrictions_Sub4.notes= notes_RS4 //_
//_ == الافتراضي ==
newRestrictions_Sub4.branch= branch_RS //_ مشتريات
newRestrictions_Sub4.ref_no= ref_no_RS  //_ رقم المرتجع
//_ == الباقي ==
newRestrictions_Sub4.dept= tot_net_G
newRestrictions_Sub4.credit= credit_RS4
//_ == تلقائي ==
//_ == علاقة ==
newRestrictions_Sub4.res_id= res_id_RS 
newRestrictions_Sub4.acc_no= acc_no_RS4

// console.log('newVote 1')
//  console.log(newRestrictions_Sub1)
};

//======================================================================= END FUNC TIBLE 4



//======================================================================= Restrictions_Sub5

const [Restrictions_Sub5, setRestrictions_Sub5] = useState([{

//_Restrictions_Sub
  Center: Center_RS,//_ مركز التكلفة
  notes: notes_RS5,  //_
//_ == الافتراضي ==
  branch: branch_RS,  //_ مشتريات
  ref_no: ref_no_RS,  //_ رقم المرتجع
//_ == الباقي ==
  dept: dept_RS5, 
  credit: tot_net_G, 
//_ == تلقائي ==
//_ == علاقة ==
  res_id: res_id_RS, 
  acc_no: acc_no_RS5, 
  }],); 

  let newRestrictions_Sub5
const AddRestrictions_Sub5 = () => {
  const newClicks = [...Restrictions_Sub5];
  newRestrictions_Sub5 = { ...newClicks };
  setRestrictions_Sub5(newClicks);
  // console.log(tot_net_G)
                     
  newRestrictions_Sub5.Center= Center_RS //_ مركز التكلفة
  newRestrictions_Sub5.notes= notes_RS5 //_
  //_ == الافتراضي ==
  newRestrictions_Sub5.branch= branch_RS //_ مشتريات
  newRestrictions_Sub5.ref_no= ref_no_RS  //_ رقم المرتجع
  //_ == الباقي ==
  newRestrictions_Sub5.dept= dept_RS5
  newRestrictions_Sub5.credit= tot_net_G
  //_ == تلقائي ==
  //_ == علاقة ==
  newRestrictions_Sub5.res_id= res_id_RS 
  newRestrictions_Sub5.acc_no= acc_no_RS5

    // console.log('newVote 2')
    // console.log(newRestrictions_Sub2)
  };

//======================================================================= END FUNC TIBLE 5

// inv /inv
const [InvText, setInvText] = useState([{ 
  //_ ملاحضات اي
  notes: notes_G_I,
  //_ معلومات اي
  refno: refno_G,
  }]); 

const onChangeInv = (e) => {    
  e.persist();      
  setInvText({...Inv, [e.target.name]: e.target.value});    
}  

//============== END insert To Inv ==============



 const getIdLevelPut = async () => {


     res_id_RS=data_R.id
   //  length_R=data_I.purch_rest_id
     length_R=data_I.sale_rest_id
     
  // get last id_proc from Inv to Inv_Sub
  // proc_id_IS_C=data_I.proc_id

  // sub_BKproc_id_IS="!B1!C-1!T"+proc_id_IS_C

  create_user_G=sto.empi
  update_user_G=sto.empi

 }


//================================================================= Post tow Main =================
const PostToMain = async (e) => {
 await e.preventDefault();  

multiR.Center = multiQ.center;
// newRestrictions
await axios.post(uri+'RestrictionsMQ', multiR)
.then(res => {
  // console.log('+++++ newRestrictions +++++')
  // console.log(res.data)
  lastidr=res.data.id
  // res_id_RS=res.data.id
})
.catch(error => {
  // console.log("---- no post newRestrictions ----") 
})


for (let m = 0; m < dataMS.length; m++) {
  multiQ.Val+=dataMS[m].credit;
}
// Inv

if(multiQ.taxperc===null || multiQ.taxperc===NaN){multiQ.taxperc=0};
// console.log('multiQ')
// console.log(multiQ)
  await  axios.post(uri+'MultiQs', multiQ)
  .then(res => {
    proc_id_IS_C=res.data.id;
    customersDNameput_RS=res.data.customer_name;
    // console.log('+++++ Yes newInv +++++')
    // console.log(res.data)
  })
  .catch(error => {
    // console.log("---- no post newInv ----")
  })
//  await UpdateInv_Sub();




// await getFirstIdLevelInvPost();


 
 await PostToSub(e);
};
//================================================================= End Post To Main =====================



//================================================================= Post tow All =================
const PostToSub = async (e) => {

// ================================== sum func process
 await e.preventDefault();

await GetLastInvId();
await Restrictions_SubMQ();
 
//alert('dataToInv'); 


// console.log('^^^ dataToInv_Sub in post ^^^')


 for (let i = 0; i < dataMS.length; i++) 
    { dataMS[i].sand_id=proc_id_IS_C; dataMS[i].dept=0; }
// Inv_Sub
await axios.post(uri+'MultiQbdSub', dataMS)
.then(res => {
  //  console.log('+++ Yes Inv_Sub +++'); console.log(res.data)
 })
.catch(error => { 
  // console.log("--- no post Inv_Sub ---")
 })

// newRestrictions_Sub1
await axios.post(uri+'Restrictions_SubMQ', dataToR_Sub)
.then(res => { 
  // console.log('+++ Yes newRestrictions_Sub1 +++'); console.log(res.data)
 })
.catch(error => { 
  // console.log("--- no post newRestrictions_Sub1 ---") 
 })


  GetLastInvId();
// await Delete_Sub_Sum(); 
await toClearAllselectGetNew();
// await toClearAllselectNameInPutGetNew();
await ClearTot();
// await refreshPage();
setSnack_S_Text('تم حفظ السند')
handleClickSnack_S();
};
//================================================================= End Post =====================

//----------------------------------------------------- PUT

const fhs = () => {
  console.log(multiQ)
 }

//================================================================= Put tow Main =================
const PutToMain = async (e) => {
  await e.preventDefault();  
   await getIdLevelPut();
   // ================================== sum value

     await UpdateInv_Sub();
indexlength = dataToInv_Sub.length;

   await getSumInv();
 

 // ================================== sum func Call
//  await getidFirstSelect();
await getidSelectInPut_ForName();
await getNameSelectDefultInPut()
  await getIdSelect();

 await AddNots();
//  await AddNotsFor_RS();
 await AddNotsFor_RS_Put();
 
await AddRestrictions();
 await AddInv();

 // data go but select 

newInv.cust_id=cust_id_GPut;
newInv.stock=stock_GPut;
newInv.safe=safe_GPut;
newInv.inv_type=inv_type_GPut;
newInv.salesman=salesman_GPut;
newInv.center=center_GPut;

// for notes
newInv.inv_type=inv_type_GPut;


 // Inv
//  console.log('newInv <<<< but')
//  console.log(newInv)
//  console.log('data_I.id')
//  console.log(data_I.id)
//  console.log('data_I.sale_rest_id')
//  console.log(data_I.sale_rest_id)

 
 
//  console.log('newRestrictions shooooooo')
//  console.log(newRestrictions)
await AddRestrictions();
  
alert(data_R.reshd_id);
    // in api -1
    await  axios.put(uri+'Restrictions'+'/'+data_R.reshd_id, newRestrictions)
    .then(res => {
      // console.log('+++++ Yes _put Restrictions +++++')
      // console.log(res.data)
    })
    .catch(error => {
      // console.log("---- no put Restrictions ----")
    })


   // in api -1
    await  axios.put(uri+'InvForSalesJust'+'/'+data_I.sale_rest_id, newInv)
    .then(res => {
      // console.log('+++++ Yes _put newInv +++++')
      // console.log(res.data)
    })
    .catch(error => {
      // console.log("---- no put newInv ----")
    })

  await PutToSub(e);
 };
 //================================================================= End put To Main =====================
 
 
 //================================================================= Put tow All =================
 const PutToSub = async (e) => { 
 // ================================== sum func process
  await e.preventDefault();  
 
  AddNotsFor_RS_Put();

 // ================================== sum func Call
await UpdateInv_Sub();
await AddRestrictions_Sub1();
await AddRestrictions_Sub2();
await AddRestrictions_Sub3();
await AddRestrictions_Sub4();
await AddRestrictions_Sub5();
 
// console.log(' Inv_Sub <<<< but ')
// console.log(dataToInv_Sub)
// console.log(' newRestrictions_Sub1 <<<< but')
// console.log(newRestrictions_Sub1)
// console.log(' newRestrictions_Sub2 <<<< but')
// console.log(newRestrictions_Sub2)
// console.log('^^^ newRestrictions_Sub3 in post ^^^')
// console.log(newRestrictions_Sub3)
// console.log('^^^ newRestrictions_Sub4 in post ^^^')
// console.log(newRestrictions_Sub4)
// console.log('^^^ newRestrictions_Sub5 in post ^^^')
// console.log(newRestrictions_Sub5)
 
 // Inv_Sub
  await axios.post(uri+'Inv_Sub', dataToInv_Sub)
  .then(res => { 
    // console.log('+++ Yes _put Inv_Sub +++'); console.log(res.data) 
  })
  .catch(error => { 
    // console.log("--- no put Inv_Sub ---")
   })

 // newRestrictions_Sub1
 await axios.post(uri+'Restrictions_Sub', newRestrictions_Sub1)
 .then(res => {
    // console.log('+++ Yes _put newRestrictions_Sub1 +++'); console.log(res.data)
   })
 .catch(error => {
    // console.log("--- no Put newRestrictions_Sub1 ---")
   })

 // newRestrictions_Sub2
 await axios.post(uri+'Restrictions_Sub', newRestrictions_Sub2)
 .then(res => {
    // console.log('+++ Yes _put  newRestrictions_Sub2 +++'); console.log(res.data) 
  })
 .catch(error => { 
  //  console.log("--- no put newRestrictions_Sub2 ---") 
  }) 
 

  // newRestrictions_Sub3
  await axios.post(uri+'Restrictions_Sub', newRestrictions_Sub3)
  .then(res => { 
    // console.log('+++ Yes _put newRestrictions_Sub3 +++'); console.log(res.data) 
  })
  .catch(error => { 
    // console.log("--- no Put newRestrictions_Sub3 ---")
   })
 
  
  if(inv_type_GPut === 2){
  
  // newRestrictions_Sub4
  await axios.post(uri+'Restrictions_Sub', newRestrictions_Sub4)
  .then(res => {
    // console.log('+++ Yes _put  newRestrictions_Sub4 +++'); console.log(res.data) 
    })
  .catch(error => { 
    // console.log("--- no put newRestrictions_Sub4 ---")
   }) 
 
  
  // newRestrictions_Sub5
  await axios.post(uri+'Restrictions_Sub', newRestrictions_Sub5)
  .then(res => { 
    // console.log('+++ Yes _put newRestrictions_Sub5 +++'); console.log(res.data) 
  })
  .catch(error => {
    // console.log("--- no Put newRestrictions_Sub5 ---")
     })
    }
    else{

    }


   // return 0 value
   tot_purch_G=0 //_ مجموع إجمالي المشتريات
   tot_net_G=0 //_ مجموع اجمالي الصافي او المدفوع
   minus_G=0 //_ مجموع قيمة الخصم
   amountAfterDisc_G=0 //_ مجموع إجمالي بعد الخصم
   amountOfVat_G=0 //_ مجموع إجنالي القيمة المضافة
   netAmount_G=0 //_ مجموع إجمالي الصافي

 GetLastInvId();

 await Delete_Sub_Sum(); 
 await toClearAllselectNameInPutGetNew();
await getidFirstSelect();
await getNameFirstSelect()


 setSnack_S_Text('تم التعديل بنجاح')
 handleClickSnack_S();
 };
 //================================================================= End Put =====================
 
 // ------------------------------------------------ DELETE

 let history = useHistory();

 const toPrint = async(e) => {

  //undefined = الصندوق العالمي
  
  if( stockDName===undefined && valueStock.name!==undefined){
    const dataInv = { id:lastIdInv,ptip:ProcType_InvProc_G,invN:inv_Name,SndoqN:valueStock.name };
     const serializedinv = JSON.stringify(dataInv); 
     console.log(serializedinv);
  await localStorage.setItem('datainv', serializedinv); 
  await toDB(e);
  const timer = setTimeout(() => {
  history.push({ pathname: '/ReviewMQ', });
  }, 2000);
  return () => {clearTimeout(timer);}
  }
  else if(valueStock.name==undefined&& stockDName!==undefined){

    const dataInv = { id:lastIdInv,ptip:ProcType_InvProc_G,invN:inv_Name,SndoqN:stockDName };
     const serializedinv = JSON.stringify(dataInv); 
     console.log(serializedinv);

    //  console.log('serializedinv')
    //  console.log(serializedinv)
     await localStorage.setItem('datainv', serializedinv); 
     //await toDB(e);
     const timer = setTimeout(() => {
    history.push({ pathname: '/ReviewMQ', })
    }, 2000);
    return () => {clearTimeout(timer);}
  }
  else {
  // alert(JSON.stringify('عذرا لم تقم بإختيار فاتورة', null, 10));
  // handleClickSnack_I();
  handleClickSnack_W();
  }

  }

  
const toDB = async(e) => {
 

  // console.log(putjust +' - '+ postORput );
  // if(postjust === 'post' && putjust === ''){
  if(valueStock.name!==undefined){

    // console.log(valueStock.name)
    let stateAdd = true;
   if(dataMS.length > 0)
   {
    // alert(parseInt(dataMS[0].dept))
    for (let m = 0; m < dataMS.length; m++) {

      if(isNaN(parseFloat(dataMS[m].dept)) || isNaN(parseInt(dataMS[m].dept)) || parseInt(dataMS[m].dept) == 0)
      {
        handleClickSnack_D();
        stateAdd=false;
        return;
      }
      // else if(dataMS.length == m+1)
      // {
      //   await PostToMain(e);
      // }
    }
    if (stateAdd == true ) await PostToMain(e);
   }
   else { alert('قم بإضافة سند من "كود الحساب"') }

    // await PostToMain(e);
  }
  // else if(putjust === 'put' && postjust === '' || putjust === 'put' && postjust === 'post'){
  //  await PutToMain(e);
  // }
else {
  // alert(JSON.stringify('عذرا لم تقم بإختيار فاتورة', null, 10));
  // handleClickSnack_I();
  handleClickSnack_W();
}

  postjust='';
  putjust='';
  proc_id_Input='';

  axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[72].id}`).then(res => {
    setRiv({...riv, Ne:res.data.IS_New,Sa:res.data.IS_Save,De:res.data.IS_Delete,Se:res.data.IS_Search,Pr:res.data.IS_Print,Up:res.data.IS_Update});
    }).catch(error => {  })

}

// >>>>>>>>>>>>>>>>>>>>> Table 

var columnsInv = [
  {title: "id", field: "id", hidden: true},
  {title: "م", field: "numbAddeshnal",editable:'never',
  headerStyle: { padding:'0px',margin:'0px',}},
  {title: "كود الحساب", field: "acc_no",editable:'never',
  headerStyle: { padding:'0px',}},
  {title: "اسم الحساب", field: "acc_Nmae", editable:'never',
  headerStyle: { padding:'0px',} },
  {title: "القيمه الدائنة", field: "dept", type: 'numeric',
  headerStyle: { padding:'0px',} },
  {title: "القيمة (محلي)", field: "credit",editable:'never',
  headerStyle: { padding:'0px',} },
  {title: "العملة", field: "omla",hidden: true,
  headerStyle: { padding:'0px',}},
  {title: "التعادل", field: "omlaval",hidden: true,
  headerStyle: { padding:'0px',} },
  {title: "رقم المرجع", field: "refno",
  headerStyle: { padding:'0px',}, cellStyle:{ }},
  {title: "البيان", field: "notes",
  headerStyle: { padding:'0px',} },
  // {title: "حذف", field: "notes", cellStyle:{ }},
]




const [data, setData] = useState([]); //table data
const [dataMS, setDataMS] = useState([]); //table data


//for error handling
const [iserror, setIserror] = useState(false)
const [errorMessages, setErrorMessages] = useState([])

const [totamountAfterDiscshow, setTotamountAfterDiscshow] = useState(0);
const [totamountOfVatshow, setTotamountOfVatshow] = useState(0);
const [tot_netShow, setTot_netShow] = useState(0);
// const [totamountAfterDisc, setTotamountAfterDisc] = useState();

const hRowAdd = (newData, resolve) => {

  amountAfterDisc_G_Show=0; amountOfVat_G_Show=0; tot_net_G_Show=0;

  //validation
  let errorList = []


  // minus=0
  numbAddeshnal=numbAddeshnal+1
  //_inv_Sub
  newData={
//sale_price
 
    // ====================== Show in Table =========================
    //_ السعر
 
    numbAddeshnal:numbAddeshnal,
    acc_no: dataCurrencies[iindex].barcode,
    acc_Nmae: dataCurrencies[iindex].symbol,
    dept: null,
    credit: null,
    omla: -1,
    omlaval: 1,
    refno: "",
    notes: "",
    branch: sto.branchi,

  }
  
  if(errorList.length < 1){ //no error

    let dataToAdd = [...dataMS];
    dataToAdd.push(newData);
    setDataMS(dataToAdd);

    setErrorMessages([])
    setIserror(false)

  }else{
    setErrorMessages(errorList)
    setIserror(true)
    resolve()
  }

}




const [data_I, setData_I] = React.useState([]);
const [data_I_S, setData_I_S] = React.useState([]);
const [data_R, setData_R] = React.useState([]);
const [data_R_S, setData_R_S] = React.useState([]);



const hRowsAdd = async (newData, resolve) => {

amountAfterDisc_G_Show=0; amountOfVat_G_Show=0; tot_net_G_Show=0;

  await Delete_Sub_Sum();

  let errorList = []

  let procid_I=null
  let leng_I_S=null
  let this_Curr= null
  let this_acc_Name= null

  
  await axios.get(uri+'Restrictions'+'/'+inv_put_id).then(res => { setData_R(res.data); setMultiR(res.data); }).catch(error => { })
  await axios.get(uri+'SndForMulitiQJust'+'/'+inv_put_id).then(res => {
    procid_I=res.data.id; 
     setData_I(...data_I, res.data); 
     setMultiQ(res.data);
    // ق م ض
    setMultiQ({...multiQ, taxperc:res.data.taxperc, taxval:res.data.taxval });
    }).catch(error => { })
  await axios.get(uri+'Restrictions_Sub'+'/'+inv_put_id).then(res => {  setData_R_S(res.data);  }).catch(error => { })
 

  // alert(uri+'MultiQbdSub'+'/'+procid_I)

  await axios.get(uri+'MultiQbdSub'+'/'+procid_I).then(res => {
    // setData_I_S([]);
    // console.log('inv_put_id + === ' + procid_I); 
     setData_I_S(res.data);
    //  setDataMS(res.data);
    //  console.log('__res.data__');
    //  console.log(res.data);
     leng_I_S = res.data.length
    
  
    let dataToPut = [...dataMS];


    for (let index = 0; index < leng_I_S; index++) {
   
          // dataCurrencies
    for (let m = 0; m < dataCurrencies.length; m++) {
      if(dataCurrencies[m].barcode === res.data[index].acc_no){
        this_acc_Name = m
      } }

      amountAfterDisc_G_Show=0; amountOfVat_G_Show=0; tot_net_G_Show=0;

      //validation
      let errorList = []
    
    
      // minus=0
      numbAddeshnal=numbAddeshnal+1
      //_inv_Sub
      newData={
    //sale_price
     
        // ====================== Show in Table =========================
        //_ السعر
     
        numbAddeshnal:numbAddeshnal,
        // acc_no: dataCurrencies[iindex].barcode,
        acc_no:  res.data[index].acc_no,
       
        acc_Nmae: dataCurrencies[this_acc_Name].symbol,
        dept: res.data[index].credit,
        credit: res.data[index].credit,
        omla: res.data[index].omla,
        omlaval: res.data[index].omlaval,
        refno: res.data[index].refno,
        notes: res.data[index].notes,
        branch: sto.branchi,
      }
      
      if(errorList.length < 1){ //no error
    
        dataToPut.push(newData);
    
      //dataUpdateSub[index] = newData;
      //setInv_Sub([...dataUpdateSub]);
      //console.log(dataUpdateSub);
    
        setErrorMessages([])
        setIserror(false)
    //     api.post("/users", newData)
    //     .then(res => {
    
    
    //     })
    //     .catch(error => {
    
    // //      resolve()
    //     })
      }else{
        setErrorMessages(errorList)
        setIserror(true)
        resolve()
      }
    
    
      // AddInv_Sub();
      // Add_Sub_Sum();
    //   thisindex++
    
    // // to show Below
    //  amountAfterDisc_G_Show += amountAfterDisc_IS_Tot //
    //  amountOfVat_G_Show += amountWithVat_IS_Tot  //
      // tot_net_G_Show += netsub_IS_Tot  //
     
    //  setTotamountAfterDiscshow(amountAfterDisc_G_Show.toFixed(2));
    //  setTotamountOfVatshow(amountOfVat_G_Show.toFixed(2));
    //  setTot_netShow(tot_net_G_Show.toFixed(2));

} // end for


for (let i = 0; i < dataToPut.length; i++) {
  // amountAfterDisc_G_Show+=dataDelete[i].amountAfterDisc
  // amountOfVat_G_Show+=dataDelete[i].amountWithVat
  tot_net_G_Show+=dataToPut[i].credit
}

 
//  setTotamountAfterDiscshow(amountAfterDisc_G_Show.toFixed(2));
//  setTotamountOfVatshow(amountOfVat_G_Show.toFixed(2));
 setTot_netShow(tot_net_G_Show.toFixed(2));

setDataMS(dataToPut);

}).catch(error => { }) // end catch Inv_Sub


} // end hRowsAdd

const clearAllShowBelow = () => {
  amountAfterDisc_G_Show=0; amountOfVat_G_Show=0; tot_net_G_Show=0;
  setTotamountAfterDiscshow(0); setTotamountOfVatshow(0); setTot_netShow(0);
}

const handleRowAdd = () => {

  if(postORput==='post'){
    hRowAdd();
  }
  else if(postORput==='put') {
    // console.log('put')
    // console.log('put')
   hRowsAdd();
  }

}

const [sumdata, setsumData] = useState([]); 
let dataToSum = [...sumdata];

// post Sub Sum /bsub
 const Add_Sub_Sum = () => {

  data_Sum = {
    total_justS:total_just_IS_Tot,
    netsubS:netsub_IS_Tot,
    discountS:discount_IS_Tot,
    amountAfterDiscS:amountAfterDisc_IS_Tot,
    amountBeforDiscS:amountBeforDisc_IS_Tot,
    amountWithVatS:amountWithVat_IS_Tot,
  }

  dataToSum.push(data_Sum);
  setsumData(dataToSum);

  // console.log('dataToSum')
  // console.log(dataToSum)
   };


// put Sub Sum /bsub
const Update_Sub_Sum = () => {  
  // e.preventDefault(); 

  data_Sum = {
    total_justS:total_just_IS_Tot,
    netsubS:netsub_IS_Tot,
    discountS:discount_IS_Tot,
    amountAfterDiscS:amountAfterDisc_IS_Tot,
    amountBeforDiscS:amountBeforDisc_IS_Tot,
    amountWithVatS:amountWithVat_IS_Tot,
  }
  setsumData([...dataToSum]);

}

// delete Sub Sum /bsub
 const Delete_Sub_Sum = () =>{
  // console.log('s d')
  setDataMS([])
  // console.log('b')
  // console.log(dataToSum)
  thisindex=0
  dataToSum=[]

  setInv_Sub([])
  // dataToInv_Sub=[]
  // dataInv_Sub=[]
  // setsumData(dataToSum);
  // console.log('A')
  // console.log(dataToSum)
 }

 const toClearAllselectGetNew = () =>{
  setValueSalesmen([]);
  setValueCostcenter([]);
  setValueSafes([]);
  setValueCustomers([]);
  setValueStock([]);
  setValueType([]);
  setStockDName([])
 }

 const toClearAllselectIdInPutGetNew = () =>{
  // setCustomersDefId([]);
  // setStockDefId([]);
  // setSafesDefId([]);
  // setTypeDefId([]);
  setSalesmanDefId(-1);
  setCostcenterDefId(-1);
  salesman_G=salesmanDefId;
  center_G=costcenterDefId;
 }

 const toClearAllselectNameInPutGetNew = () =>{
  // setTypeDName([]);
  // setcustomersDName([]);
  // setSafesDName([]);
  setSalesmanDName([]);
  setCostcenterDName([]);
  setStockDName([])
 }



 const toClearGetBtnNew = () =>{

  if(data.length>=1){
    alert('قم بحفظ الفاتورة او العودة للصفحة الرئيسية')

  }
  else{
    setValueSalesmen([]);
    setValueCostcenter([]);
    setValueSafes([]);
    setValueCustomers([]);
    setValueStock([]);
    setValueType([]);

    getNameFirstSelect();
    getidFirstSelect();
    // alert('ولا تضهر شيء')
  }

 }


 // delete Sub Sum /bsub
 const toClearGetNew = () =>{
  postjust = '';
  putjust = '';
  deletejust='';
  setData([]);
  thisindex=0
  dataToSum=[]
  setInv_Sub([]);
  setInv([]);
  setRestrictions([]);
  setRestrictions_Sub1([]);
  setRestrictions_Sub2([]);
  setsumData(dataToSum);

  setValueSalesmen([]);
  setValueCostcenter([]);
  setValueSafes([]);
  setValueCustomers([]);
  setValueStock([]);
  setValueType([]);
  GetLastInvId();

  getidFirstSelect();
  getNameFirstSelect();
  // getIdSelectAfterClickNew();

 }







 const getthisindex = () => {
  //  alert(valueType.id) 
 }

 const clearSumInvBeforSum = () => {
  alert(
    'bd'+' '+
    tot_purch_G+' '+
    tot_net_G+' '+
    minus_G+' '+
    amountAfterDisc_G+' '+
    amountOfVat_G+' '+
    netAmount_G
  );
 tot_purch_G=0
 tot_net_G=0
 minus_G=0
 amountAfterDisc_G=0
 amountOfVat_G=0
 netAmount_G=0

 alert(
  'Ad'+' '+
  tot_purch_G+' '+
  tot_net_G+' '+
  minus_G+' '+
  amountAfterDisc_G+' '+
  amountOfVat_G+' '+
  netAmount_G
);

 }

const getSumInv = () => { 
  
  //clearSumInvBeforSum();
  // getthisindex();

  for (let a = 0; a<indexlength; a++){
   //_Inv Add
 //_ مجموع إجمالي المشتريات
 // tot_purch_G+=dataToSum[a].total_justS
   //_ مجموع إجمالي المبيعات
   tot_sale_G+=dataToSum[a].total_justS
  //_ مجموع اجمالي الصافي او المدفوع
  tot_net_G+=dataToSum[a].netsubS
  //_ مجموع قيمة الخصم
  minus_G+=dataToSum[a].discountS
  //_ مجموع إجمالي بعد الخصم
  amountAfterDisc_G+=dataToSum[a].amountAfterDiscS
  //_ مجموع إجمالي بعد الخصم في المبيعات
  amountBefordisc_G+=dataToSum[a].amountBeforDiscS
  //_ مجموع إجمالي القيمة المضافة
  amountOfVat_G+=dataToSum[a].amountWithVatS
  //_ مجموع إجمالي الصافي
  netAmount_G+=dataToSum[a].netsubS
  //amountWithVatS
  } // end for


  // code from samer developer
  if(valueType.id === undefined || valueType.id === 2){
    cash_part_G= netAmount_G
    agel_part_G= 0
  }

  if( valueType.id === 1){
     cash_part_G= 0
    agel_part_G= netAmount_G
  }

  };



const handleRowUpdate = (newData, oldData, resolve) => {
  //validation 
  // getidFirstSelect(); 
     getIdSelect();
     amountAfterDisc_G_Show=0; amountOfVat_G_Show=0; tot_net_G_Show=0;

    let errorList = []

  
   if(newData.omlaval===null){newData.omlaval=1}
  newData={

    numbAddeshnal:newData.numbAddeshnal,
    acc_no:newData.acc_no,
    acc_Nmae:newData.acc_Nmae,
    // credit:newData.dept*newData.omlaval*1,
    omlaval:newData.omlaval,
    // ق دائنه
    dept:newData.dept,
    // ق محلية
    credit:newData.dept*newData.omlaval,
    omla:newData.omla,
    refno:newData.refno,
    notes:newData.notes,
    branch: sto.branchi,
   
  }


  // discount
  const dataUpdate = [...dataMS];
  const index = oldData.tableData.id;
  dataUpdate[index] = newData;
  setDataMS([...dataUpdate]);


  // dataInv_Sub
  resolve()
  setIserror(false)
  setErrorMessages([])

   if(errorList.length < 1){
  
  }else{
    setErrorMessages(errorList)
    setIserror(true)
    resolve()

  }
  
for (let i = 0; i < dataUpdate.length; i++) {
//   amountAfterDisc_G_Show+=dataUpdate[i].amountAfterDisc
//   amountOfVat_G_Show+=dataUpdate[i].amountWithVat
  tot_net_G_Show+=dataUpdate[i].credit
}


setTot_netShow(tot_net_G_Show.toFixed(2));

 //getAllNetWithNaqde();

 // ق م ض

setMultiQ({...multiQ, taxval:(multiQ.taxperc * tot_net_G_Show.toFixed(2))/100 });

}


  const [customersDefId, setCustomersDefId] = React.useState(212);
  const [stockDefId, setStockDefId] = React.useState();
  const [safesDefId, setSafesDefId] = React.useState();
  const [unitsDefId, setUnitsDefId] = React.useState();
  const [typeDefId, setTypeDefId] = React.useState();
  // to put just
  const [salesmanDefId, setSalesmanDefId] = React.useState();
  const [costcenterDefId, setCostcenterDefId] = React.useState();

  
const [customersDName, setcustomersDName] = useState();
const [typeDName, setTypeDName] = useState();
const [safesDName, setSafesDName] = useState();
const [stockDName, setStockDName] = useState();
// to put just
//let shrinkbool = false
// shrinkbool bool = false
const [salesmanDName, setSalesmanDName] = useState();
const [costcenterDName, setCostcenterDName] = useState();



const handNewSand = () => {
 toClearAllselectGetNew();
 ClearTot();
}
  

useEffect(() => {

  getNameFirstSelect();
}, []);


 function getNameFirstSelect() {
 
   axios.get(uri+'Customers').then(res => { setcustomersDName(res.data[0].name);}).catch(error => {})
   axios.get(uri+'Types').then(res => { setTypeDName(res.data[1].name);}).catch(error => {})

   axios.get(uri+'Safes').then(res => { 

    if (valueSafes.id === undefined) {
      for(let i=0; i<res.data.length;i++){ 
        if(res.data[i].IS_Default === true && res.data[i].IS_Deleted === false){
         setSafesDName(res.data[i].name);
        }
      }
      //  if(safesDId==null) {}
      //   else if(res.data.status == '500')  {}
    }
    }).catch(error => {})
   axios.get(uri+'Stock'+'?id='+sto.empi+'&&branch='+sto.branchi).then(res => { 


    if (valueStock.id === undefined) {
      for(let i=0; i<res.data.length;i++){ 
        if(res.data[i].IS_Default === true && res.data[i].IS_Deleted === false){
          //setStockDName(res.data[i].name);
        }
      }
      //  if(stockDId==null) {}
      //   else if(res.data.status == '500')  {}
    }
    }).catch(error => {})

}

const [snacAllSelectDef, setSnacAllSelectDef] = useState('');



  async function getidFirstSelect() {
    let snacST='',snacSF='',snacUN='',snacCU='';

    //Customers
      await axios.get(uri+'Customers').then(res => { setCustomersDefId(res.data);
        cust_id_G= res.data[0].id
        cust_name_G=res.data[0].name
        customersDId=1
        // snacST='لا يمكنك الاختيار قم بالتحقق من وجود موردين';
        // alert(JSON.stringify('تم م = '+ cust_id_G , null, 2));
      }).catch(error => {
        snacCU='لا يمكنك الاختيار قم بالتحقق من وجود موردين';
        // alert(JSON.stringify('لا يمكنك الاختيار قم بالتحقق من وجود موردين', null, 2));
       })
      
     //Stock
       await axios.get(uri+'Stock'+'?id='+sto.empi+'&&branch='+sto.branchi).then(res => { setStockDefId(res.data);
     
       if (valueStock.id === undefined) {
        for(let i=0; i<res.data.length;i++){ 
          if(res.data[i].IS_Default === true && res.data[i].IS_Deleted === false){
            stock_G = res.data[i].id
            stockDId=1
          }
        }
         if(stockDId==null)
         {
          // alert(JSON.stringify('لا يمكنك التنزيل قم بإختيار صندوق إفتراضي من النظام', null, 2));
           snacST='لا يمكنك التنزيل قم بإختيار صندوق إفتراضي من النظام';
          }
          else if(res.data.status == '500') 
          {
           snacST='لا يمكنك التنزيل قم بإختيار صندوق إفتراضي من النظام';
          //  alert(JSON.stringify('لا يمكنك التنزيل قم بإختيار صندوق إفتراضي من النظام', null, 2));
          }
      }
        //  alert(JSON.stringify(' ص تم = '+ stock_G , null, 2));
      }).catch(error => {
        snacST='لا يمكنك الاختيار قم بالتحقق من وجود صناديق';
        //alert(JSON.stringify('لا يمكنك الاختيار قم بالتحقق من وجود صناديق', null, 2));
       })
    
      // Safes
       await axios.get(uri+'Safes').then(res => { setSafesDefId(res.data);
     
        if (valueSafes.id === undefined) {
         for(let i=0; i<res.data.length;i++){ 
           if(res.data[i].IS_Default === true && res.data[i].IS_Deleted === false){
            safe_G = res.data[i].id
            safesDId=1
           }
         }
          if(safesDId==null)
          {
            snacSF='لا يمكنك التنزيل قم بإختيار مخزن إفتراضي من النظام';
            //alert(JSON.stringify('لا يمكنك التنزيل قم بإختيار مخزن إفتراضي من النظام', null, 2));
          }
           else if(res.data.status == '500') 
           {
            snacSF='لا يمكنك التنزيل قم بإختيار مخزن إفتراضي من النظام';
             //alert(JSON.stringify('لا يمكنك التنزيل قم بإختيار مخزن إفتراضي من النظام', null, 2));
            }
       }
          // alert(JSON.stringify(' م تم = '+ safe_G , null, 2));
       }).catch(error => {
        snacSF='لا يمكنك الاختيار قم بالتحقق من وجود مخازن';
         //alert(JSON.stringify('لا يمكنك الاختيار قم بالتحقق من وجود مخازن', null, 2));
        })
    

        // Units
        await axios.get(uri+'Units').then(res => { setUnitsDefId(res.data);
     
          if (valueUnits.id === undefined) {
           for(let i=0; i<res.data.length;i++){ 
             if(res.data[i].IS_Default === true){
              unit_IS_id = res.data[i].id
              unit_IS_name = res.data[i].name
              unitsDId=1
             }
           }
            if(unitsDId==null)
            {
              snacUN='لا يمكنك التنزيل قم بإختيار وحدة إفتراضي من النظام';
              //alert(JSON.stringify('لا يمكنك التنزيل قم بإختيار وحدة إفتراضي من النظام', null, 2));
            }
             else if(res.data.status == '500') 
             {
              snacUN='لا يمكنك التنزيل قم بإختيار وحدة إفتراضي من النظام';
               //alert(JSON.stringify('لا يمكنك التنزيل قم بإختيار وحدة إفتراضي من النظام', null, 2));
             }
         }
            // alert(JSON.stringify(' م تم = '+ safe_G , null, 2));
         }).catch(error => {
          snacUN='لا يمكنك الاختيار قم بالتحقق من وجود وحدات';
           //alert(JSON.stringify('لا يمكنك الاختيار قم بالتحقق من وجود وحدات', null, 2));
          })


        // Types_Inv
        await axios.get(uri+'Types').then(res => { setTypeDefId(res.data);
     
          if (valueType.id === undefined) {
           for(let i=0; i<res.data.length;i++){ 
             if(res.data[i].id === 2){
              inv_type_G= res.data[i].id; 
              inv_type_G_N= res.data[i].name
              pay_type_G=1
 
             }
           }

         }
         }).catch(error => { })

      

        getIdSelect();

      if(snacST!=='' || snacSF!=='' || snacUN !=='' || snacCU !=='')
        {
        setSnacAllSelectDef(snacST+' '+snacSF+' '+snacUN+' '+snacCU);
        handleClickAllSDefSelect();
        }
      }

     // get Id Select In Put

  async function getidSelectInPut_ForName() {

    await axios.get(uri+'SndForMulitiQJust'+'/'+inv_put_id).then(res => {
  
      //Customers
      setCustomersDefId(res.data.cust_id);
      cust_id_G= res.data.cust_id
      cust_id_GPut= res.data.cust_id

      //Stock
      setStockDefId(res.data.safe_bank_id);
      stock_G = res.data.safe_bank_id
      stock_GPut = res.data.safe_bank_id
      bank_G = res.data.safe_bank_id
      bank_GPut=res.data.safe_bank_id
      // Safes
      setSafesDefId(res.data.safe);
      safe_G = res.data.safe
      safe_GPut = res.data.safe
      // Inv_Type
      setTypeDefId(res.data.inv_type);
      inv_type_G= res.data.inv_type
      inv_type_GPut= res.data.inv_type
      // salesman
      setSalesmanDefId(res.data.sales_emp);
      salesman_G= res.data.sales_emp
      salesman_GPut= res.data.sales_emp
      // center
      setCostcenterDefId(res.data.center);
      center_G=res.data.center
      center_GPut=res.data.center

    }).catch(error => { })
    
       // getIdSelect();
       getNameSelectDefultInPut();
}


// const restNameSelectDefultInPut = () =>{
//   setcustomersDName()

// }

function getNameSelectDefultInPut() {

  axios.get(uri+'Types').then(res => { 
    if (valueSafes.id === undefined) {
      for(let i=0; i<res.data.length;i++){ 
        if(res.data[i].id === inv_type_G){
          setTypeDName(res.data[i].name);
          valueType(res.data[i]);
          typeDNameput_RS=res.data[i].name;

        } } } }).catch(error => {})

  axios.get(uri+'Customers').then(res => { 
    if (valueSafes.id === undefined) {
      for(let i=0; i<res.data.length;i++){ 
        if(res.data[i].id === cust_id_G){
          setcustomersDName(res.data[i].name);
          valueCustomers(res.data[i]);
          customersDNameput_RS=res.data[i].name;
        } } } }).catch(error => {})

  axios.get(uri+'Safes').then(res => { 
   if (valueSafes.id === undefined) {
     for(let i=0; i<res.data.length;i++){ 
       if(res.data[i].id === safe_G){
        setSafesDName(res.data[i].name);
        valueSafes(res.data[i]);
       } } } }).catch(error => {})

  axios.get(uri+'Stock'+'?id='+sto.empi+'&&branch='+sto.branchi).then(res => {
   if (valueStock.id === undefined) {
     for(let i=0; i<res.data.length;i++){ 
       if(res.data[i].id === stock_GPut ){
         setStockDName(res.data[i].name);
         valueStock(res.data[i]);
        //  console.log('res.data[i].name')
        //  console.log(res.data[i].name)
       } } } }).catch(error => {})

    axios.get(uri+'Bank').then(res => {
    if (valueBank.id === undefined) {
      for(let i=0; i<res.data.length;i++){ 
        if(res.data[i].id === bank_GPut ){
          setStockDName(res.data[i].name);
          valueBank(res.data[i]);
          // console.log('res.data[i].name')
          // console.log(res.data[i].name)
        } } } }).catch(error => {})

    axios.get(uri+'salesmen').then(res => { 
    if (valueSalesmen.id === undefined) {
      for(let i=0; i<res.data.length;i++){ 
        if(res.data[i].id === salesman_G ){
          setSalesmanDName(res.data[i].name);
          valueSalesmen(res.data[i]);
        } } } }).catch(error => {})

    axios.get(uri+'costcenter').then(res => { 
      if (valueCostcenter.id === undefined) {
        for(let i=0; i<res.data.length;i++){ 
          if(res.data[i].id === center_G ){
            setCostcenterDName(res.data[i].Name);
            valueCostcenter(res.data[i]);
          } } } }).catch(error => {})

         // shrinkbool= true
}

  



  const getIdSelect = () => {
    // To Inv
    //_ Select
    if(valueCostcenter.id !== undefined){
    center_G=valueCostcenter.id;
     Center_R=valueCostcenter.id;
     center_GPut= valueCostcenter.id;
  }
  
  if(valueCostcenter.id !== undefined){ Center_RS=valueCostcenter.id;}

  if(valueSafes.id !== undefined){ safe_G=valueSafes.id; safe_GPut = valueSafes.id; safesDId=1 }
  if(valueStock.id !== undefined){ stock_G=valueStock.id; stock_GPut=valueStock.id; stockDId=1 }
  if(valueCustomers.id !== undefined){  cust_id_G = valueCustomers.id; acc_no_RS1 = valueCustomers.acc_no; acc_no_RS5 = valueCustomers.acc_no; cust_id_GPut = valueCustomers.id; cust_name_G= valueCustomers.name; customersDNameput_RS=valueCustomers.name; customersDId=1 }
  if(valueUnits.id !== undefined){ unit_IS_id = valueUnits.id; unit_IS_name= valueUnits.name; unitsDId=1 }

  
  if(valueSalesmen.id !== undefined)
  { sales_emp_G= valueSalesmen.id;
    salesman_GPut = valueSalesmen.id;
    emp_G= valueSalesmen.id;
  }
  if(valueSalesmen.id !== undefined){  salesman_G=valueSalesmen.id; }
  // if(valueType.id !== undefined){ pay_type_G= valueType.id }
  if(valueBank.id !== undefined){ bank_G= valueBank.acc_no }
  if(valueBank.id !== undefined){ bank2_G= valueBank.acc_no}
  if(valueType.id !== undefined)
  { 
    inv_type_G= valueType.id; inv_type_GPut = valueType.id; inv_type_G_N= valueType.name;
    typeDNameput_RS=valueType.name;
    if(inv_type_G===1){pay_type_G=-1}
    else if(inv_type_G===2){pay_type_G=1}
  }
    }



  const AddNots =  async () => {
    notes_G_I=' فاتورة مبيعات '+' '+inv_type_G_N+' رقم: '+length_I+' خاصة ب: ' + cust_name_G;
    notes_R=' فاتورة مبيعات '+' '+inv_type_G_N+' رقم: '+length_I+' خاصة ب: ' + cust_name_G;
  }

  const AddNotsFor_RS =  async () => {
    notes_RS1=' فاتورة مبيعات '+' '+inv_type_G_N+' رقم: '+length_I+' خاصة ب: ' + cust_name_G;
    notes_RS2=' فاتورة مبيعات '+' '+inv_type_G_N+' رقم: '+length_I+' خاصة ب: ' + cust_name_G; 
    notes_RS3= 'ض. القيمة المضافة'+' فاتورة مبيعات '+' '+inv_type_G_N+' رقم: '+length_I+' خاصة ب: ' + cust_name_G;
    notes_RS4='دفع نقدي '+' لفاتورة مبيعات '+' '+inv_type_G_N+' رقم: '+length_I+' خاصة ب: ' + cust_name_G; 
    notes_RS5='دفع نقدي '+' لفاتورة مبيعات '+' '+inv_type_G_N+' رقم: '+length_I+' خاصة ب: ' + cust_name_G; 
  }

  const AddNotsFor_RS_Put =  async () => {

    notes_RS1=' فاتورة مبيعات '+' '+typeDNameput_RS+' رقم: '+data_I.sale_rest_id+' خاصة ب: ' + customersDNameput_RS;
    notes_RS2=' فاتورة مبيعات '+' '+typeDNameput_RS+' رقم: '+data_I.sale_rest_id+' خاصة ب: ' + customersDNameput_RS; 
    notes_RS3= 'ض. القيمة المضافة'+' فاتورة مبيعات '+' '+typeDNameput_RS+' رقم: '+data_I.sale_rest_id+' خاصة ب: ' + customersDNameput_RS;
    notes_RS4='دفع نقدي '+' لفاتورة مبيعات '+' '+typeDNameput_RS+' رقم: '+data_I.sale_rest_id+' خاصة ب: ' + customersDNameput_RS; 
    notes_RS5='دفع نقدي '+' لفاتورة مبيعات '+' '+typeDNameput_RS+' رقم: '+data_I.sale_rest_id+' خاصة ب: ' + customersDNameput_RS; 
  }

  const stytab = {
    maxHeight: 'calc(100% - 20px)',
    margin: '4px'

}

const ClearTot = () => {  

  // setMultiR
  
  // setMultiQ({notes:"",check_no:""})
  setMultiQ({
    Val: 0,Type: 1,safe_bank_id: null,sales_emp: -1,check_no: null,
check_date: null,date:moment(new Date()).format('YYYY-MM-DD'),
branch: sto.branchi,center: -1,omla: -1,omlaval: 1,taxperc: 0,taxval: 0,notes:null
  })
  setDataMS([]);
  dataToR_Sub=[];
  // setNotes("");

  setValuechk('1');
  getInvType='1';
  setTot_netShow(0)

  setAlsheek(false)
  setLblAuto('الصندوق');
  axios.get(uri+'Stock'+'?id='+sto.empi+'&&branch='+sto.branchi).then(res => { setOptionsStock(res.data); }).catch(error => { })
  setAlsheek(false);
  // setMultiQ({...multiQ, check_date:null,check_no:"" });
}


const fahs = () => {

// console.log(multiQ)

}

const Restrictions_SubMQ = () => {

dataToR_Sub = [];

let hiiii

let depttot=0,credittot=0;

if(valueStock.name!==undefined) {

for (let m = 0; m < dataMS.length; m++) {
  depttot+=dataMS[m].credit+0
}

for (let m = 0; m < dataCurrencies.length; m++) {

  if(dataCurrencies[m].symbol===valueStock.name)
  {
    const dataMRS = { 
      "res_id": lastidr,
      "dept": depttot,
      "credit": 0,
      "acc_no": dataCurrencies[m].barcode,
      "notes": "سند قبض رقم: "+lastIdInvLet,
      "branch": sto.branchi,
      "omla": -1,
      "omlaval": 1,
      "center": -1,
      "ref_no": null,
      "RestDate": null
     }
       dataToR_Sub.push(dataMRS);
  }

}

if(multiQ.taxval!==0)
{
    credittot+=multiQ.taxval 
    const dataMRS = { 
      "res_id": lastidr,
      "dept": 0,
      "credit": credittot,
      "acc_no": 4110005,
      "notes": "سند قبض رقم: "+lastIdInvLet,
      "branch": sto.branchi,
      "omla": null,
      "omlaval": null,
      "center": -1,
      "ref_no": null,
      "RestDate": null
     }
       dataToR_Sub.push(dataMRS);
}


  for (let m = 0; m < dataMS.length; m++) {

    const dataMRS = { 

      "res_id": lastidr,
      "dept": 0,
      "credit": dataMS[m].credit+0,
      "acc_no": dataMS[m].acc_no,
      "notes": "سند قبض رقم: "+lastIdInvLet,
      "branch": sto.branchi,
      "omla": -1,
      "omlaval": 1,
      "center": -1,
      "ref_no": null,
      "RestDate": null

     }

    dataToR_Sub.push(dataMRS);



  }

} 

}
  

const handleRowDelete = (oldData, resolve) => {
  
  amountAfterDisc_G_Show=0; amountOfVat_G_Show=0; tot_net_G_Show=0;


  const dataDelete = [...dataMS];
  const index = oldData.tableData.id;
  dataDelete.splice(index, 1);

  for (let m = 1; m < dataDelete.length; m++) {
    dataDelete[m].numbAddeshnal=m+1;
  }

  setDataMS([...dataDelete]);

  resolve()
    thisindex--

 for (let i = 0; i < dataDelete.length; i++) {
  // amountAfterDisc_G_Show+=dataDelete[i].amountAfterDisc
  // amountOfVat_G_Show+=dataDelete[i].amountWithVat
  tot_net_G_Show+=dataDelete[i].credit
}


 setTot_netShow(tot_net_G_Show.toFixed(2));

  // ق م ض
// console.log('tot_netShow')
// console.log(multiQ.taxperc +' * '+ tot_net_G_Show.toFixed(2))

setMultiQ({...multiQ, taxval:(multiQ.taxperc * tot_net_G_Show.toFixed(2))/100 });
}




// >>>>>>>>>>>>>>>>>>>>> End Table

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ Do ^
    useEffect(() => {
      handleClickOpen();
    }, []);
  // const [open, setOpen] = React.useState(false);



  // const handleClickOpen = () => {
  //   setOpen(true);
  // };


  function refreshPage() {
    window.location.reload(false);
  }
  
  const backhome = () => history.push({ pathname: '/', });
  

  const handleClickOpenDS = () => {
    GetDataTS();
    setOpenDS(true);
    Delete_Sub_Sum();
    // dateChangeFrom(e);
    // dateChangeTo();
    setValuechk('1')
    invDateGetData();
    toClearGetNew();
  };

  const handleCloseDS = () => {
    setOpenDS(false);
  };


//================================================================= Delete tow All Inv =================



 const deleteToInv = async (e) => {

  await e.preventDefault();  
 


  //deleteRestrictions
  await axios.delete(uri+'Restrictions'+'/'+data_R.reshd_id)
  .then(res => {
    //  console.log('+++ Yes _DElete newRestrictions +++'); console.log(res.data) 
    })
  .catch(error => {
    //  console.log("--- no DElete newRestrictions ---")
     })

  //deleteInv
  await axios.delete(uri+'SndForMulitiQJust'+'/'+data_I.rest_id)
  .then(res => {
    //  console.log('+++ Yes _DElete Inv +++'); console.log(res.data)
     })
  .catch(error => { 
    // console.log("--- no DElete Inv ---") 
  })

 // deleteInv_Sub
 for (let index = 0; index < data_I_S.length; index++) {
  await axios.delete(uri+'MultiQbdSub'+'/'+data_I_S[index].reshd_id)
  .then(res => {
    //  console.log('+++ Yes _DElete Inv_Sub +++'); console.log(res.data)
     })
  .catch(error => { 
    // console.log("--- no DElete Inv_Sub ---") 
  })
 }

 for (let index = 0; index < data_R_S.length; index++) {
   // deleteRestrictions_Subs
   await axios.delete(uri+'Restrictions_Sub'+'/'+data_R_S[index].reshd_id)
   .then(res => { 
    //  console.log('+++ Yes _DElete  newRestrictions_Sub1 +++'); console.log(res.data);
    toClearGetNew(); })
   .catch(error => { 
    // console.log("--- no DElete newRestrictions_Sub1 ---") 
    }) 

 }

 GetLastInvId();

 // await Delete_Sub_Sum(); 
 await toClearAllselectGetNew();
 // await toClearAllselectNameInPutGetNew();
 await toClearAllselectNameInPutGetNew();

 await ClearTot();

 Delete_Sub_Sum();

   // return 0 value
   tot_purch_G=0 //_ مجموع إجمالي المشتريات
   tot_net_G=0 //_ مجموع اجمالي الصافي او المدفوع
   minus_G=0 //_ مجموع قيمة الخصم
   amountAfterDisc_G=0 //_ مجموع إجمالي بعد الخصم
   amountOfVat_G=0 //_ مجموع إجنالي القيمة المضافة
   netAmount_G=0 //_ مجموع إجمالي الصافي
   handleClickSnack_E_FDI();

  setRiv({...riv,Sa:true });

 };

 //================================================================= End Delete =====================
 
  const ClickDeleteInv = (e) => {
    deleteToInv(e);
    postjust='';
    putjust='';
    proc_id_Input='';
    deletejust='';
  };

// ==================================


  // Date T
  //To
  let invdateT = null
  const [dateToSInvOM, setDateToSInvOM] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const dateChangeTo = (e) => { 
   // setValuechk(1);
    // let getInvdateTo = moment(dateTo).format('YYYY-MM-DD');
    setDateToSInvOM(e.target.value);
    invdateT = e.target.value;
    invDateGetData();
  };
  // From
  let invdateF = null
  const [dateFromSInvOM, setDateFromSInvOM] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const dateChangeFrom = (e) => {

  //  try{
  //   setMultiQ({...multiQ, check_date:moment(e.target.value).format('YYYY-MM-DD') });
  //  }catch{}
   // setValuechk(1);
    setDateFromSInvOM(e.target.value);
  // let getInvdateFrom = moment(dateFrom).format('YYYY-MM-DD');
  setDateFromSInvOM(e.target.value);
  invdateF = e.target.value;
  invDateGetData();
  };


  
  const invDateGetData = async () => {

  //  alert(getInvType + ' = '+ dateToSInvOM +' = '+ dateFromSInvOM);



    // All
      if(dateToSInvOM !== null && dateFromSInvOM !== null && invdateF === null && invdateT === null && valuechk === '1'){
      //  alert(dateToSInvOM +' = '+ dateFromSInvOM +' - '+ ' - ' + invdateF + ' - ' + invdateT +' - '+ value)
      // await axios.get(uri+'MulitiQV?'+`sdate=${dateToSInvOM}&&edate=${dateFromSInvOM}`)
      await axios.get(uri+'MulitiQV'+`?sdate=${dateToSInvOM}&&edate=${dateFromSInvOM}`)
    
      .then(res => {  setDataInvSearsh(res.data);
        //  console.log(res.data);
        }).catch(error => { })
     }


     if(dateToSInvOM !== null && invdateF !== null && valuechk === '1'){
      // alert(dateToSInvOM +' = '+ dateFromSInvOM +' in - F '+ invdateF + ' - ' + invdateT +' - '+ value)
     await axios.get(uri+'MulitiQV'+`?sdate=${invdateF}&&edate=${dateToSInvOM}`)
     .then(res => {  setDataInvSearsh(res.data); 
      // console.log(res.data);
    }).catch(error => { })
    }

    if(invdateT !== null && dateFromSInvOM !== null && valuechk === '1'){
      // alert(dateToSInvOM +' = '+ dateFromSInvOM +' in - T  '+ invdateF + ' - ' + invdateT +' - '+ value)
     await axios.get(uri+'MulitiQV'+`?sdate=${dateFromSInvOM}&&edate=${invdateT}`)
     .then(res => {  setDataInvSearsh(res.data); 
      // console.log(res.data);
    }).catch(error => { })
    }

   
    
    if(dateToSInvOM !== null && dateFromSInvOM !== null && getInvType === '2'){
      // alert(dateToSInvOM +' = '+ dateFromSInvOM +' in - F '+ invdateF + ' - ' + invdateT +' - '+ getInvType)
     await axios.get(uri+'MulitiQV'+`?sdate=${dateFromSInvOM}&&edate=${dateToSInvOM}&&itype=${getInvType}`)
     .then(res => {  setDataInvSearsh(res.data); 
      // console.log(res.data);
    }).catch(error => { })
    }

    if(dateToSInvOM !== null && dateFromSInvOM !== null && getInvType === '1'){
      // alert(dateToSInvOM +' = '+ dateFromSInvOM +' in - T  '+ invdateF + ' - ' + invdateT +' - '+ getInvType)
     await axios.get(uri+'MulitiQV'+`?sdate=${dateFromSInvOM}&&edate=${dateToSInvOM}&&itype=${getInvType}`)
     .then(res => {  setDataInvSearsh(res.data);
      //  console.log(res.data);
      }).catch(error => {})
    }

    if(dateToSInvOM !== null && dateFromSInvOM !== null && getInvType === 'female'){
      // alert(dateToSInvOM +' = '+ dateFromSInvOM +' in - T  '+ invdateF + ' - ' + invdateT +' - '+ getInvType)
     await axios.get(uri+'MulitiQV'+`?sdate=${dateFromSInvOM}&&edate=${dateToSInvOM}`)
     .then(res => {  setDataInvSearsh(res.data); 
      // console.log(res.data);
    }).catch(error => {})
    }

  
  }


  //const idate = moment(date).format('YYYY-MM-DD');


  // Groube
  let getInvType=null
  const [valuechk, setValuechk] = React.useState('1');

  // for sheck
  const [alsheek, setAlsheek] = React.useState(false);

  const invTypeChange = (event) => {
    console.log(event.target.value)
try {
  setValueStock([]);
  // setMultiQ({...multiQ ,safe_bank_id:null});
  if(event.target.value==='1') 
  {
    setLblAuto('الصندوق');

     axios.get(uri+'Stock'+'?id='+sto.empi+'&&branch='+sto.branchi)
     .then(res => { setOptionsStock(res.data); }).catch(error => { })
     setAlsheek(false);
     setMultiQ({...multiQ, check_date:null,check_no:"",Type:parseInt(event.target.value),safe_bank_id:null });
  }
  else 
  {
    setLblAuto('البنك');
    axios.get(uri+'bank').then(res => { setOptionsStock(res.data); }).catch(error => { })
    setAlsheek(true);
    setMultiQ({...multiQ, check_date:moment(new Date()).format('YYYY-MM-DD'),
      Type:parseInt(event.target.value),safe_bank_id:null });

  }
  setValuechk(event.target.value);
  getInvType=event.target.value

  invDateGetData();
} catch (error) { } };



// F OP Alert 
const [openSnack_S, setOpenSnack_S] = useState(false); // F Alert S 
const [snack_S_Text, setSnack_S_Text] = useState('تم حفظ الفاتورة'); // F Alert S 

const [openSnack_W, setOpenSnack_W] = useState(false); // F Alert S 
const [openSnack_D, setOpenSnack_D] = useState(false); // F Alert S 
const [openSnack_W_FD, setOpenSnack_W_FD] = useState(false); // F Alert S 

const [openSnack_I, setOpenSnack_I] = useState(false); // F Alert S

const [openSnack_E, setOpenSnack_E] = useState(false); // F Alert S 
const [openSnack_E_FDI, setOpenSnack_E_FDI] = useState(false); // F Alert S 

const handleClickSnack_S = () => { setOpenSnack_S(true); };

const handleClickSnack_W = () => { setOpenSnack_W(true); };
const handleClickSnack_W_FD = () => { setOpenSnack_W_FD(true); };

const handleClickSnack_D = () => { setOpenSnack_D(true); };

const handleClickSnack_I = () => { setOpenSnack_I(true); };

const handleClickSnack_E = () => { setOpenSnack_E(true); };
const handleClickSnack_E_FDI = () => { setOpenSnack_E_FDI(true); };

// F CL Alert All
const handleCloseSnack = (event, reasonSnack) => {
  if (reasonSnack === 'clickaway') {  return; } 
  setOpenSnack_S(false); 
  setOpenSnack_W(false); setOpenSnack_W_FD(false);
  setOpenSnack_I(false); 
  setOpenSnack_E(false); setOpenSnack_E_FDI(false); 
  setOpenSnack_D(false);
};

// E Alert

// snack for select 
const [openAllSDefSelect, setOpenAllSDefSelect] = React.useState(false);
const handleClickAllSDefSelect = () => { setOpenAllSDefSelect(true); };

const handleCloseAllSDefSelect = (event, reason) => {
  if (reason === 'clickaway') { return; } setOpenAllSDefSelect(false);};

  return (
    <div className={classes.root}>


        <AppBar className={classes.appBar}>
          <Toolbar>
            {/* <IconButton  variant="contained" edge="start" color="coral" onClick={handleCloseUp} aria-label="close">
              <CloseIcon />
            </IconButton> */}
            <IconButton  variant="contained" edge="start" color="inherit" onClick={backhome} aria-label="home">
              <HomeIcon />
            </IconButton>


      <Button variant="contained" edge="start" style={{backgroundColor:"#293b9e",marginRight:2}} color="primary" fontSize="large"
      className={classes.button} disabled={!riv.Se} startIcon={<SearchIcon />}
      onClick={handleClickOpenDS}>{'السندات'}</Button>
      
      <Button variant="contained" edge="start" style={{backgroundColor:"#293b9e"}} color="primary" fontSize="large"
      className={classes.button} disabled={!riv.Se} startIcon={<PostAddIcon />}
      onClick={handNewSand}>{'جديد'}</Button>

            <Typography variant="h6"  className={classes.title}>

            </Typography>
    
               <Button
               style={{backgroundColor:"#293b9e"}}
        variant="contained"
        color="primary"
        fontSize="large"
        className={classes.button}
        startIcon={<DeleteIcon />}
        // onClick={ClickDeleteInv}
        onClick={(e) => { 

          if (deletejust==='')
          {
           handleClickSnack_W_FD();
          }

          // else if (window.confirm('اضغط موافق للحذف'))
          else if (window.confirm('لا يمكن التراجع بعد تأكيد الحذف؟ إذا كنت تريد التراجع قم بالضغط على Cancel'))
           {
            ClickDeleteInv(e)
           }
          
          } 
          
          }
      >
       حذف
      </Button>


  

      <Button variant="contained" color="primary" fontSize="large"
      className={classes.button} disabled={!riv.Pr} startIcon={<PrintIcon />}
      onClick={toPrint} >{btnprint}</Button>

<Button variant="contained" style={{backgroundColor:"#293b9e", marginLeft:'0.3%'}} color="primary" fontSize="large"
      className={classes.button} disabled={!riv.Sa} startIcon={<SaveIcon />}
      onClick={toDB}>{btnsave}</Button>

<Button variant="contained" style={{backgroundColor:"#293b9e", marginLeft:'0.3%'}} color="primary" fontSize="large"
      className={classes.button} disabled={!riv.Sa} startIcon={<SaveIcon />}
      onClick={fhs}>{'fhs'}</Button>

      <Snackbar open={openSnack_S} autoHideDuration={2000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="success">{snack_S_Text}</Alert>
      </Snackbar>
      <Snackbar  open={openSnack_W} autoHideDuration={2000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="warning">يجب إدخال صندوق</Alert>
      </Snackbar>
      <Snackbar  open={openSnack_D} autoHideDuration={2000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="warning">تأكد من إدخال القيم الدائنة</Alert>
      </Snackbar>
      <Snackbar  open={openSnack_W_FD} autoHideDuration={2000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="warning">لا يوجد سند لحذفه</Alert>
      </Snackbar>  
      <Snackbar open={openSnack_I} autoHideDuration={3000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="info">يجب إدخال حسابات للسند</Alert>
      </Snackbar>
      <Snackbar open={openSnack_E} autoHideDuration={2000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="error">تم إضافة السند</Alert>
      </Snackbar>
      <Snackbar open={openSnack_E_FDI} autoHideDuration={2000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="error">تم حذف السند</Alert>
      </Snackbar>

      <Snackbar anchorOrigin={{ vertical: 'top',horizontal: 'center', }}
        open={openAllSDefSelect}
        // autoHideDuration={6000}
        onClose={handleCloseAllSDefSelect}
        message={snacAllSelectDef}
        action={
          <React.Fragment>
            <Button color="inherit" size="small" onClick={handleCloseAllSDefSelect}>
              حسناً
            </Button>
          </React.Fragment>
        }
      />

    

          </Toolbar>
        </AppBar>
        <List>

        <React.Fragment>
      <CssBaseline />
      

        <Container style={{textAlign:'center',marginTop: '-8px',marginBottom: '3%' }} spacing={0}>
        <Chip size="small" style={{ borderRadius: '0px 0px 10px 10px'  }} label="سند قبض متعدد" color="primary" />
        </Container>

      <Container maxWidth="xl" spacing={1}>
        <Typography component="div" style={{   }}>

        <Grid container  spacing={1}>

  <Grid item md={2} sm={4} xs={6} >
  <Autocomplete
  value={valueSalesmen}
  onChange={(event, newValue) => { setValueSalesmen(newValue); setMultiQ({...multiQ, sales_emp:newValue.emp });}}
  id="combo-box-demo"  size="small"
  options={optionsSalesmen} 
  //getOptionLabel={(option) => option.name}
  getOptionLabel={(option) => (typeof option === 'string' ? option : option.username)}
  style={{  }}
  disableClearable
  renderInput={(params) => <TextField {...params} InputLabelProps={{shrink: true}} placeholder={salesmanDName}  label="المندوب" variant="outlined" />}/>
 </Grid>

 <Grid item md={2} sm={4} xs={6}>
 <Autocomplete
  value={valueCostcenter}
  onChange={(event, newValue) => { setValueCostcenter(newValue);  setMultiQ({...multiQ, center:newValue.id });}}
  id="combo-box-demo" size="small"
  options={optionsCostcenter} 
  getOptionLabel={(option) => (typeof option === 'string' ? option : option.Name)}
  // getOptionLabel={(option) => option.Name}
  style={{  }}
  disableClearable
  renderInput={(params) => <TextField {...params} InputLabelProps={{shrink: true}} placeholder={costcenterDName}   label="مركز التكلفة" variant="outlined" />}   
/>
</Grid>

<Grid item md={1} sm={2} xs={3}>
<TextField fullWidth variant="outlined" size="small" id="username" label="رقم السند"
name="username"  disabled value={lastIdInv} InputLabelProps={{shrink: true}} />
</Grid>

<Grid item md={1} sm={2} xs={4}>
  <TextField  fullWidth variant="outlined" size="small" id="datef" 
                    label="التاريخ"
                    type="date" format="YYYY-MM-DD"
                    InputLabelProps={{ shrink: true }}
                    name="datef" value={multiQ.date} 
                    onChange={(e)=>setMultiQ({...multiQ, date:e.target.value})}/>

</Grid>



<Grid item md={2} sm={4} xs={5} style={radstygrd}>
{/* <FormControl component="fieldset" variant="outlined"> */}
      <RadioGroup fullWidth row size="small" variant="outlined" aria-label="position" name="position" style={radsty}
      value={valuechk} onChange={invTypeChange}>
        <FormControlLabel value="1" control={<Radio color="primary" />} label="نقدي" />
        <FormControlLabel value="2" control={<Radio color="primary" />} label="شيك" />
      </RadioGroup>
    {/* </FormControl> */}
</Grid>

<Grid item md={1} sm={4} xs={3}>
<TextField variant="outlined" size="small"
 id="username" label="رقم الشيك" 
type="number"
onChange={(e) => {setMultiQ({...multiQ, check_no:parseInt(e.target.value) });}}
name="check_no" autoComplete="username" disabled={!alsheek}
 textAlign="center" value={multiQ.check_no}
 InputLabelProps={{shrink: true}}/>
</Grid>



<Grid item md={3} sm={4} xs={9}>
<TextField fullWidth variant="outlined" size="small" id="date" label="تاريخ إستحقاق الشيك"  type="date"
// format="MM/dd/yyyy"
format="YYYY-MM-DD"
  // value={moment(new Date()).format('YYYY-MM-DD')} 
  defaultValue={todaydate}
  // value={todaydate} 
disabled={!alsheek}
 value={multiQ.check_date} 

// onChange={dateChangeFrom}
onChange={(e) => {setMultiQ({...multiQ, check_date:e.target.value });}}
 InputLabelProps={{ shrink: true }} />
</Grid>

<Grid item md={6} sm={5} xs={12} >
<TextField variant="outlined" size="small"
fullWidth id="username" label="البيان"
name="notes" autoComplete={false}
 textAlign="center" value={multiQ.notes}
 onChange={(e) => {setMultiQ({...multiQ, notes:e.target.value });}}
 InputLabelProps={{shrink: true}}/>
</Grid>


<Grid item md={3} sm={4} xs={7} >
<Autocomplete value={valueStock}
  onChange={(event, newValue) => {GetLastInvId(); setValueStock(newValue); setMultiQ({...multiQ, safe_bank_id:newValue.id });  }}
  id="combo-box-demo" size="small"
  options={optionsStock} 
  getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
  // getOptionLabel={(option) => option.name}
  style={{  }}
  disableClearable
  renderInput={(params) => <TextField {...params} InputLabelProps={{shrink: true}} placeholder={stockDName} label={lblAuto} variant="outlined" />}   
/>
</Grid>

<Grid item md={3} sm={3} xs={5} >
<Button fullWidth style={stybtn}  variant="outlined"  color="secondary"
        fontSize="large" size="large"
        className={classes.button} disabled={!riv.Ne}
        startIcon={<AddBoxIcon />} onClick={handleClickOpen('paper')} >
        كود الحساب
      </Button>
      </Grid>


 
        </Grid>

        </Typography>
      </Container>
    </React.Fragment>

<br/>
  
        <Grid container >
          

<Grid item xs={12} sm={12} >
<Paper className={classes.paper}
//  className={classes.paperT}
>

<Grid  xs={12} sm={12}>
            <MaterialTable
              title="سند قبض متعدد"
              options={{
                // showTitle: false,
                // search: false,
                toolbar: false,
           }}

           ocalization={{
            // pagination: {
            //     labelDisplayedRows: '{from}-{to} of {count}'
            // },
            // toolbar: {
            //     nRowsSelected: '{0} row(s) selected'
            // },
            header: {
                actions: 'إجراء'
            },
            body: {
                emptyDataSourceMessage: 'بيانات فارغة',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            }
        }}

              columns={columnsInv}
              data={dataMS}
              
              icons={tableIcons}

              editable={{

                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                      handleRowUpdate(newData, oldData, resolve);
                  }),


                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    handleRowDelete(oldData, resolve)
                  }),

                  onRowAdd: (newData) => 
                  new Promise((resolve) => {
                    
                  })


              }}
            /></Grid>

</Paper>
</Grid> 

<Grid item xs={12} sm={12}>
<Paper className={classes.paperTxtUp} >

<Grid item className={classes.iitem} xs={ 12} sm={2}>
<TextField variant="outlined" margin="dense" 
fullWidth id="username" label="%"
type="number"
placeholder="0"
name="username" autoComplete="username" disabled
 textAlign="center" value={multiQ.taxval}
 InputLabelProps={{shrink: true}}
            /></Grid>
  <Grid item> % </Grid>        
 <Grid item className={classes.iitem} xs={ 12} sm={3}>
<TextField variant="outlined" margin="dense"  
//disabled={true}
fullWidth id="taxperc" label="القيمة المظافة"
name="taxperc"
// autoComplete="taxperc" 
type="number"
placeholder="0"
onChange={(e) => {setMultiQ({...multiQ, taxperc:parseInt(e.target.value), taxval:(e.target.value * tot_netShow)/100 });}}
 textAlign="center" value={multiQ.taxperc}
 InputLabelProps={{shrink: true}}
            /></Grid>

            
<Grid item className={classes.iitem} xs={12} sm={7}>
<TextField variant="outlined" margin="dense" 
fullWidth id="username" label="الإجمالي"
name="username" autoComplete="username" disabled
 textAlign="center" value={tot_netShow}
InputLabelProps={{shrink: true}}
            /></Grid>

</Paper>
</Grid> 

<Grid item xs={12} sm={12}>
<Paper className={classes.paperTxtDown} >

<Grid item className={classes.iitem} xs={12} sm={12}>
<TextField variant="outlined" margin="dense" 
fullWidth id="username" label="تفقيط"
name="username" autoComplete="username" disabled
 textAlign="center" value={tot_netShow}
 InputLabelProps={{shrink: true}}
            /></Grid>


</Paper>
</Grid> 

<Grid item xs={12} sm={12}>

      <Dialog
        open={open}
        maxWidth={{maxWidth:'100%'}}
        minHeight={{minHeight: '80%'}}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
       
      >
        <DialogTitle id="scroll-dialog-title">تنزيل كود واسم الحساب</DialogTitle>
        <DialogContent dividers={scroll === 'paper'} >

        <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        label="بحث شامل"
                        size="small"
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={(e)=>handleSearch(e)}
                    />
                </Toolbar>

                <TableContainer className={classes.container}>
                <Table size="small" styles={{height: '250px'}}>
                    <TblHead className={classes.container} >

                    </TblHead>
                    <TableBody className={classes.container} >
                        
                        {recordsAfterPagingAndSorting().map((item,index) =>
                                (<TableRow
                                  hover 
                                  onClick={() => { getrowDataCurrencies(item.id) }}
                                  //onDoubleClick={() => getrowDataCurrenciesDouble()}
                                key={item.barcode}>
                                   
                                    <TableCell>{item.barcode}</TableCell>
                                    <TableCell>{item.symbol}</TableCell>
                                </TableRow>))}

                    </TableBody>



                    </Table>
                </TableContainer>
            </Paper>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            إلغاء
          </Button>
        </DialogActions>
      </Dialog>
</Grid>






</Grid>


        </List>
    


      <Dialog fullScreen open={openDS} onClose={handleCloseDS} TransitionDSComponent={TransitionDS}>
 
        <AppBar className={classes.appBarDS}>
          <Toolbar>
          <IconButton edge="start" color="red" onClick={handleCloseDS} aria-label="close">
              <CloseIcon />
            </IconButton>
            


            <Typography variant="h6" className={classes.titleDS}>
             
            </Typography>

          </Toolbar>
        </AppBar>
     
      
        <List>


    <Grid container >
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Paper   className={classes.paper}>
        <Grid container >
        <Grid item  xs={6} sm={6}>
        <Paper className={classes.paper}>

        <TextField /*type="number"*/ fullWidth variant="outlined" size="small" id="date" label="إلى تاريخ"
  // className={clasTF.root} InputProps={{ className: clasTF.input }}
  type="date" format="YYYY-MM-DD"
  name="date" value={dateToSInvOM} onChange={dateChangeTo}
  />

        </Paper>
      
         </Grid>
         <Grid item xs={6} sm={6}>
         <Paper className={classes.paper}>

         <TextField /*type="number"*/ fullWidth variant="outlined" size="small" id="date" label="من تاريخ"
  // className={clasTF.root} InputProps={{ className: clasTF.input }}
  type="date"
   format="YYYY-MM-DD"
  name="date" value={dateFromSInvOM} onChange={dateChangeFrom}/>

        </Paper>
        </Grid>

</Grid>
        </Paper>
      </Grid>
 
    </Grid>
        </List>

        <List>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className={classes.tableRoot}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={dataInvSearsh.length}
            />
            <TableBody>
              {stableSort(dataInvSearsh, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
               //   const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => getrowsDataCurrencies(event, row.rest_id, row.id)}
                      onDoubleClick={() => getrowDataCurrenciesDouble()}
                      role="checkbox"
                      tabIndex={1}
                      key={row.id}
                    >
                      <TableCell align="center" Width='1%'>{row.notes}</TableCell>
                      <TableCell align="center" Width='1%'>{row.Val}</TableCell>
                      <TableCell align="center" Width='1%'>{moment(row.date).format('YYYY-MM-DD')}</TableCell>
                      <TableCell align="center" Width='1%'>{row.id}</TableCell>

                     

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={dataInvSearsh.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
        />


        </List>
      </Dialog>
 
 

    </div>
    

  );

}
