import React,{useEffect, useState} from 'react';
import BackdropSc from '../../../services/BackdropSc';
import axios from 'axios';
import {uri}  from '../../../help/Api';
import {insh,sto,permiCuCo,premiScr} from '../../../Context/InshData';
import {insh as inshst} from '../../../Context/InshSt';
import {txtA} from '../../../Label/Lbl';
import {getLS}  from '../../../utils/useLS';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import Typography from '@material-ui/core/Typography';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import dataF from '../../../Server/dataF.json';

// cuG
import {useStyleI} from '../st/UseStyle';
// csG
import {style} from '../st/cStyle'

export default function Icons() {
  const classes = useStyleI();
  let his = useHistory();
  const timer = React.useRef();

  const [prmiScr, setPermiScr] = useState(premiScr);
  const [showSc, setShowSc] = useState(true);

  // const inshRiv={Rw:false,RwR:false};
  // const [riv, setRiv] = useState(inshRiv);
  // const getUPrmi = async () => 
  // {
  //   const arr = [{v:false},{v:false}];
  //   let i=0;
  //   for (let m = 46; m < 49; m++)
  //     {
  //       if (m===47) continue;
  //       // alert(uri+`UserPermission?id=${sto.idi}&&f=${dataF[m].id}`)
  //       // alert(m)
  //       await axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[m].id}`).then(r => {
  //         if(r.data.user_id !== null) arr[i++].v=false; else arr[i++].v=true;

  //         console.log('r.data.user_id')
  //         console.log(r.data.user_id)
  //         console.log('r.data.user_id')

  //       }).catch(error => { })
  //     }
  //       setRiv({Rw:arr[0].v,RwR:arr[1].v});

  //       // console.log('arr'); console.log(arr);
  //       // console.log('riv'); console.log(riv);

  //       /* costWithVat */
  //       // alert(uri+`UserPermission?id=${sto.idi}&&f=${dataF[3].id}`)
  //       axios.get(uri+`UserPermission?id=${sto.idi}&&f=${dataF[3].id}`).then(res => {
  //         if(res.data.costWithVat===1) { setPermiScr({...prmiScr, CWVat:res.data.costWithVat, CWVtxt:'السعر يشمل الضريبه',  }); }
  //         else if(res.data.costWithVat===0){ setPermiScr({...prmiScr, CWVat:res.data.costWithVat, CWVtxt:'السعر لا يشمل الضريبه', }); }
  //       }).catch(error => { });
  // }

  // const getUPrmi = async () => {
  //   // alert('iformidI= '+dataF[46].id+' , iformidR= '+dataF[48].id+' , iformidCuCo= '+dataF[3].id)
  //   const tb = { PermissionsScreen:true, wait:true,
  //     parms: {
  //       iuserid:sto.idi, iformidI:dataF[46].id,iformidR:dataF[48].id,
  //     },
  //     autos:{},
  // }
  //   console.log(tb)
  //   // await axios.post('http://localhost:8184/api/'+'Res',tb).then(res => {
  //   await axios.post(uri+'Res',tb).then(res => {
  //     console.log('data'); console.log(res.data);
  //     setPermiScr(res.data.permisScr);
  //     setShowSc(res.data.wait.state);
  //   }).catch(error => { })
  // }

  const getUPrmi = () => {
    const loc = getLS('premiScr',{});
    setPermiScr(loc);
  }

  const loader = () => 
  { timer.current = window.setTimeout(() => { setShowSc(false); }, 600); }
  useEffect( () => {
    loader();
    getUPrmi();
    return () => { clearTimeout(timer.current); };
  }, []);


  //sa

  const clickInv = (val) => {
    // setTransition(() => Transition);
    // prmiScr.CWVat===1 ? setIsOpenSalW(!isOpenSalW) : setIsOpenSalO(!isOpenSalO); 
    
    // return(<Invoces />);
    // his.push({ pathname: '/SalesInvO', })

    // if(permiCuCo.CWVat==true)
    // {
      // alert('يشمل')
      val?his.push({
        pathname: '/',
        // search: '?update=true',  /*query string*/
        
        state: {idata:insh.IdataInvs, stor:{invsType:0},
          setting:{ titleHed:txtA.invName.sale,cusType:1,cusN:txtA.cusNA,
            priceNM:txtA.namePriceSal,priceTotNM:txtA.nameTotSal, 
            PrTy_InPr:insh.IdataSa.ProcType_InvProc,
            iformid:dataF[46].id,
            routI_R_RS:'Inv_R_RS_ToS',routIS:'Inv_Sub_ToS/',/*routR:'',routRS:'',*/ },
            style:inshst.sal
        },
      })
      :his.push({
        pathname: '/Invoces',
        // search: '?update=true',  /*query string*/

        state: {idata:insh.IdataInvs, stor:{invsType:1},
          setting:{ titleHed:txtA.invName.saleRet,cusType:1,cusN:txtA.cusNA,
            priceNM:txtA.namePriceSal,priceTotNM:txtA.nameTotSal, 
            PrTy_InPr:insh.IdataSaR.ProcType_InvProc,
            iformid:dataF[48].id,
            routI_R_RS:'Inv_R_RS_ToS',routIS:'Inv_Sub_ToS/',/*routR:'',routRS:'',*/ },
            style:inshst.ret
        },
      })

    }; // E sa

  // //rsa
  // const [isOpenSalWRet, setIsOpenSalWRet] = useState(false);
  // const [isOpenSalORet, setIsOpenSalORet] = useState(false);
  // // const clickOSalRet = (Transition) => {
  // //   setTransition(() => Transition);
  // //   prmiScr.CWVat===1 ? setIsOpenSalWRet(!isOpenSalWRet) : setIsOpenSalORet(!isOpenSalORet); 
  // //   // setIsOpenSalRet(!isOpenSalRet); 
  // // }; // E rsa



return (
   showSc?<BackdropSc/>:

  <Card className={classes.root}>
  <Grid container className={classes.container} spacing={1}>

  <Grid item lg={12} md={12} sm={12} xs={12}>
  {/* <div style={{color:'green'}} >{riv.Rw+''}</div> */}
  <CardActionArea disabled={!prmiScr.sal} onClick={() => clickInv(true)} className={classes.action} style={style.backColor.sal}>

  {/* <div style={{color:'green'}} >{riv.Rw+''}</div> */}
  <CardContent className={classes.content} >
    {/* <Typography component="h5" variant="h5"> */}
    {/* <Typography variant="subtitle1" color="textSecondary">  */}
    <Typography>فاتورة المبيعات</Typography>
    {/* </Typography>  */}
  </CardContent>
  <CardMedia
  className={classes.cover}
  title=""
  >
  <ListAltIcon className={classes.icon} />
  </CardMedia>
  </CardActionArea>
  </Grid>
  
  <Grid item lg={12} md={12} sm={12} xs={12} >
  <CardActionArea disabled={!prmiScr.salRet}
  onClick={() => clickInv(false)}
  className={classes.action} style={style.backColor.RetSal}>

  <CardContent className={classes.content}>
    <Typography>مرتجع المبيعات</Typography>
  </CardContent>
  <CardMedia
  className={classes.cover}
  title=""
  >
  <LowPriorityIcon className={classes.icon} />
  </CardMedia>
  </CardActionArea>
  </Grid> 
  </Grid> 

  {/* <SalesInvW isUpOpened={isOpenSalW} transitionUp={transition} handCloseUp={() => setIsOpenSalW(false)}
  CWVtxt={prmiScr.CWVtxt}/> */}

  {/* <SalesInvO isUpOpened={isOpenSalO} transitionUp={transition} handCloseUp={() => setIsOpenSalO(false)}
  CWVtxt={prmiScr.CWVtxt}
  /> */}

  {/* <SalesInvWRet isUpOpened={isOpenSalWRet} transitionUp={transition} handCloseUp={() => setIsOpenSalWRet(false)}
  CWVtxt={prmiScr.CWVtxt}/>
  <SalesInvORet isUpOpened={isOpenSalORet} transitionUp={transition} handCloseUp={() => setIsOpenSalORet(false)}
  CWVtxt={prmiScr.CWVtxt}/> */}

  {/* <SalesInvRet isUpOpened={isOpenSalRet} transitionUp={transition} handCloseUp={() => setIsOpenSalRet(false)}/> */}

  </Card>

  );
}


    // <Card className={classes.root} >
    //   <CardActionArea className={classes.root}>

    //     <CardContent className={classes.content}>
    //       {/* <Typography component="h5" variant="h5">
    //       {/* <Typography variant="subtitle1" color="textSecondary"> */}
    //       مرتجع المبيعات
    //       {/* </Typography> */}
    //     </CardContent>
    //   <CardMedia
    //     className={classes.cover}
    //     title=""
    //   >
    //      <LocalMallIcon style={iconSal} />
    //   </CardMedia>
    //   </CardActionArea>
      
    // </Card>





