import React, { useState, useEffect } from "react";
import { txtA } from "../../../../Label/Lbl";
import axios from "axios";
import { uri } from "../../../../help/Api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import {
  useInvAF,
  useInvCa,
  useInvMain,
} from "../../../../St/Reviews/UseStyle";
import { sto } from "../../../../Context/InshData";

function Header() {
  const clasm = useInvMain();
  const clas = useInvCa();
  //  const clasH = useInvHeadrCa();

  const [info, setInfo] = useState({});
  const getInfo = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid item container className={[clasm.HedrootSKh, clas.Hedroot]}>
      <Grid item xs={12} className={clasm.mincntr}>
        <Typography>
          <ButtonBase>
            {" "}
            <img
              src={`data:image/jpeg;base64,${info.Logo}`}
              className={clas.Hedlogo}
              alt={info.nameA}
            />{" "}
          </ButtonBase>
        </Typography>
      </Grid>

      <Grid item xs={12} className={clasm.mincntr}>
        <Typography className={[clas.contnt]}>{info.nameA}</Typography>
        <Typography>{info.nameE}</Typography>
      </Grid>

      {/*  */}

      {/* <Grid item xs={5} className={[clasm.minrit]}>
        <Typography className={clas.contnt}>{info.Mobile}</Typography>
       </Grid> */}

      {/* <Grid item xs={6} className={[clasm.mincntr]}>
        <Typography className={[clas.contnt,clas.colr]} >{' : ' + txtA.taxphone+''+txtA.found}</Typography>
       </Grid> */}
      {/* <Grid item xs={12} className={[clasm.mincntr]}>
        <Typography className={clas.contntSub}>{info.Address}</Typography> 
       </Grid>

       <Grid item xs={12} className={clasm.mincntr}>
        <Typography className={[clas.contnt,clas.colr]}>{txtA.taxNumr+''+txtA.found}</Typography>
       </Grid>   
       <Grid item xs={12} className={clasm.mincntr}>
        <Typography className={clas.contnt}>{info.tax_no}</Typography>
       </Grid>      */}
    </Grid>
  );
}
export default Header;
